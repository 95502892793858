import { get } from 'lodash'
import PropTypes from 'prop-types'
import { usePopupProperties } from '~map-creator/core/App/BaseMap/hooks/usePopupProperties'
import { useCurrentWizardStep } from '~map-creator/core/states/map'
import { useTitlePropertyKey } from '~map-creator/core/states/properties'
import { useStepIndexes } from '~map-creator/core/states/ui'
import { useSelectedProperty } from '../../../hooks/useSelectedProperty'
import { View } from './view'

const Popup = ({ map }) => {
  const currentWizardStep = useCurrentWizardStep()
  const titlePropertyKey = useTitlePropertyKey()
  const stepIndexes = useStepIndexes()
  const { selectedProperty } = useSelectedProperty(
    map,
    currentWizardStep >= stepIndexes.INCLUDE_PROPERTIES
  )
  const propertyKeyValuePairs = usePopupProperties({
    property: selectedProperty,
    onlyIncluded: currentWizardStep > stepIndexes.INCLUDE_PROPERTIES
  })

  if (!selectedProperty) return null

  const { lngLat, feature } = selectedProperty

  const isTitleActive = currentWizardStep > stepIndexes.INCLUDE_PROPERTIES

  const titlePropertyValue = get(feature, ['properties', titlePropertyKey])

  return (
    <View
      isTitleActive={isTitleActive}
      lngLat={lngLat}
      titlePropertyValue={titlePropertyValue}
      propertyKeyValuePairs={propertyKeyValuePairs}
    />
  )
}

Popup.propTypes = {
  selectedProperty: PropTypes.string,
  map: PropTypes.object
}

export { Popup }
export default Popup
