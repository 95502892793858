import { Box, Paper, Stack, SvgIcon, Typography } from '@mui/material'
import Calendar from '@untitled-ui/icons-react/build/esm/Calendar'
import { dayjs } from 'utils'

const UserProfileInfo = (props) => {
  const { loading = false, user } = props
  const { firstName, lastName, avatar, createdAt } = user || {}

  return (
    <Stack
      data-testid='UserProfileInfo'
      direction={{
        xs: 'column',
        md: 'row'
      }}
      gap={2}
      textAlign={{ xs: 'center', md: 'left' }}
      alignItems='center'
    >
      <Box
        component={Paper}
        variant='outlined'
        position='relative'
        width={100}
        height={100}
        borderRadius={999}
        overflow='hidden'
        sx={{
          backgroundImage: `url(${avatar})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100px 100px'
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 2,
          alignItems: { xs: 'center', md: 'flex-start' }
        }}
      >
        <Typography
          color='text.secondary'
          variant='overline'
        >
          USER PROFILE
        </Typography>
        <Typography
          data-testid='UserProfileInfo-Name'
          variant='h4'
          component='h1'
        >
          {[firstName, lastName].join(' ')}
        </Typography>
        <Box
          display='flex'
          alignItems='center'
          gap={1}
        >
          <SvgIcon>
            <Calendar />
          </SvgIcon>
          <Typography variant='body1'>
            Joined {dayjs(createdAt).format('MMMM YYYY')}
          </Typography>
        </Box>
      </Box>
    </Stack>
  )
}

export { UserProfileInfo }
