import { UKBoundaryNames, USBoundaryNames } from "./countries";

export interface BoundaryNames {
  singular: string;
  plural: string;
}

export interface BoundaryNameLookUp {
  [key: string]: BoundaryNames;
}

const OtherBoundaryNames: BoundaryNameLookUp = {
  "MS_zz-countries": {
    singular: "Country",
    plural: "Countries"
  }
};

/**
 * 
 * Returns either the full lookup object or a specified boundary name 
 * 
 * @param boundaryName optional arg, column name from geocoder for given boundary
 * @returns either the fuill object or the boundary names for a given boundary
 */
function getFriendlyBoundaryName(boundaryName?: string): BoundaryNameLookUp | BoundaryNames {
  const friendlyBoundaryNameLookUp: BoundaryNameLookUp = {
    ...USBoundaryNames,
    ...UKBoundaryNames,
    ...OtherBoundaryNames
  };

  if (!boundaryName) return friendlyBoundaryNameLookUp
  // asserting the boundary name is correct or throw an error
  return friendlyBoundaryNameLookUp[boundaryName]!
}

export { getFriendlyBoundaryName }
