import { createErrorClass } from '~utils/functions/createErrorClass'

const CustomError = createErrorClass('Upload Error')

function UploadError({ message }) {
  return new CustomError({ message })
}

UploadError.CsvInvalid = () =>
  new CustomError({
    message:
      'The provided data doesn’t appear to be a valid CSV (comma separated value) file. If you think this error is invalid please contact support@mapstack.io.'
  })

export { UploadError }
