import { LoadingButton } from '@mui/lab'
import { Box, Button, SvgIcon } from '@mui/material'
import Send03 from '@untitled-ui/icons-react/build/esm/Send03'
import PropTypes from 'prop-types'

const Footer = (props) => {
  const {
    currentStep,
    lastStep,
    isSubmitting,
    disabledBack,
    disabledNext,
    noBack,
    noNext,
    onBackClick,
    onNextClick,
    onSubmitClick
  } = props

  const isSubmit = currentStep === lastStep
  const disabledSubmit = disabledNext

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        {noBack ? null : (
          <Button
            color='inherit'
            data-testid='ButtonBack'
            onClick={onBackClick}
            disabled={disabledBack}
          >
            Back
          </Button>
        )}
      </Box>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {noNext ? null : !isSubmit ? (
          <Button
            data-testid='ButtonNext'
            id='ButtonNext'
            onClick={onNextClick}
            disabled={disabledNext}
          >
            Next
          </Button>
        ) : (
          <LoadingButton
            data-testid='ButtonSubmit'
            onClick={onSubmitClick}
            endIcon={
              <SvgIcon>
                <Send03 />
              </SvgIcon>
            }
            loading={isSubmitting}
            loadingPosition='end'
            variant='contained'
            disabled={disabledSubmit}
          >
            Submit
          </LoadingButton>
        )}
      </Box>
    </Box>
  )
}

const FooterPropTypes = {
  currentStep: PropTypes.number,
  lastStep: PropTypes.number,
  disabledBack: PropTypes.bool,
  disabledNext: PropTypes.bool,
  onBackClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onSubmitClick: PropTypes.func
}

Footer.propTypes = FooterPropTypes

export { Footer }
