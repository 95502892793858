import React from 'react'
import PropTypes from 'prop-types'
import { styled, Box } from '@mui/material'

const Notch = styled(Box)({
  cursor: 'row-resize',
  visibility: 'visible',
  paddingTop: '12px',
  paddingBottom: '8px'
})

const Pull = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'notchWidth'
})(({ notchWidth = 20 }) => ({
  width: `var(--notch-width, ${notchWidth}dvw)`,
  height: 4,
  borderRadius: 99,
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  position: 'absolute',
  top: '8px',
  left: '50%',
  transform: 'translateX(-50%)'
}))

const MobilePanelNotch = (props) => {
  const { notchWidth, ...rest } = props
  return (
    <Notch {...rest}>
      <Pull
        {...{ notchWidth }}
        data-testid='mobile-panel-notch-pull'
      />
    </Notch>
  )
}

MobilePanelNotch.propTypes = {
  /**
   * Custom notch width of the notch.
   */
  notchWidth: PropTypes.number
}

export { MobilePanelNotch }
