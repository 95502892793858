import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { IncludeProperties } from '~map-creator/core/App/CreateMap/Steps'
import {
  updatePropertyVisibility,
  useTitlePropertyKey
} from '~map-creator/core/states/properties'
import {
  setCoverageAreaTag,
  setFeatureTypeTag
} from '~map-creator/core/states/ui'

const DEFAULT_COVERAGE_AREA = 'earth'
const DEFAULT_FEATURE_TYPE = 'country'

const IncludePropertiesCsv = (props) => {
  const dispatch = useDispatch()
  const titlePropertyKey = useTitlePropertyKey()

  const setDefaultTags = useCallback(() => {
    dispatch(
      setCoverageAreaTag({
        id: `coverageArea:${DEFAULT_COVERAGE_AREA}`,
        name: DEFAULT_COVERAGE_AREA,
        description: '',
        count: 0,
        type: 'coverageArea'
      })
    )

    dispatch(
      setFeatureTypeTag({
        id: `featureType:${DEFAULT_FEATURE_TYPE}`,
        name: DEFAULT_FEATURE_TYPE,
        description: '',
        count: 0,
        type: 'featureType'
      })
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(updatePropertyVisibility({ key: titlePropertyKey, value: true }))
    setDefaultTags()
  }, [titlePropertyKey, dispatch, setDefaultTags])

  return (
    <IncludeProperties
      {...props}
      titlePropertyKey={titlePropertyKey}
    />
  )
}

export default IncludePropertiesCsv
export { IncludePropertiesCsv }
