import { to } from '@react-spring/web'
import { clamp } from 'lodash'

const DEFAULT_HEIGHT_BORDER_RADIUS = 14
const FULL_HEIGHT_BORDER_RADIUS = 0

/**
 * Interpolate Values.
 * Generating css variables when the spring values change.
 */
const useInterpolation = ({ spring }) => {
  const interpolateBorderRadius = to(
    [spring.y, spring.maxHeight],
    (y, maxHeight) => {
      return `${Math.round(
        clamp(
          maxHeight - y,
          FULL_HEIGHT_BORDER_RADIUS,
          DEFAULT_HEIGHT_BORDER_RADIUS
        )
      )}px`
    }
  )

  const interpolateModalHeight = to(
    [spring.y, spring.minThreshold, spring.maxThreshold],
    (y, minThreshold, maxThreshold) =>
      `${clamp(y, minThreshold, maxThreshold)}px`
  )

  const interpolateModalY = to(
    [spring.y, spring.minThreshold, spring.maxThreshold],
    (y, minThreshold, maxThreshold) => {
      if (y < minThreshold) {
        return `${minThreshold - y}px`
      }
      if (y > maxThreshold) {
        return `${maxThreshold - y}px`
      }
      return '0px'
    }
  )

  const interpolateOverdragGap = to(
    [spring.y, spring.maxThreshold],
    (y, maxThreshold) => {
      if (y >= maxThreshold) {
        return Math.ceil(y - maxThreshold)
      }
      return 0
    }
  )

  return {
    '--mobile-panel-overdrag-gap': interpolateOverdragGap,
    '--mobile-panel-modal-translate-y': interpolateModalY,
    '--mobile-panel-modal-border-radius': interpolateBorderRadius,
    '--mobile-panel-modal-height': interpolateModalHeight
  }
}

export { useInterpolation }
