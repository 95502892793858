import {
  autoformatArrow,
  autoformatLegal,
  autoformatLegalHtml,
  autoformatMath,
  autoformatPunctuation,
  autoformatSmartQuotes
} from '@udecode/plate-autoformat'

import { autoformatBlocks } from './autoformatBlocks.js'
import { autoformatMarks } from './autoformatMarks.js'
import { autoformatLists } from './autoformatLists.js'
import { autoformatIndentLists } from './autoformatIndentList.js'

export const autoformatRules = [
  ...autoformatBlocks,
  ...autoformatMarks,
  ...autoformatSmartQuotes,
  ...autoformatPunctuation,
  ...autoformatLegal,
  ...autoformatLegalHtml,
  ...autoformatArrow,
  ...autoformatMath,
  ...autoformatLists,
  ...autoformatIndentLists
]
