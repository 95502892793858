import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getAuthUserMap } from '~map-viewer/api/getAuthUserMap'
import { setLike } from '~map-viewer/states/like'
import { setIsOwner, useMapStatus } from '~map-viewer/states/map'

const useInitAuthUserMap = (mapId) => {
  const dispatch = useDispatch()
  const mapStatus = useMapStatus()

  const saveAuthUserMapToStore = useCallback(
    async (map) => {
      dispatch(setLike(map.liked))
      dispatch(setIsOwner(map.owned))
    },
    [dispatch]
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const map = await getAuthUserMap(mapId)
        map && saveAuthUserMapToStore(map)
      } catch (errors) {
        console.warn(errors)
      }
    }

    fetchData()
  }, [mapId, saveAuthUserMapToStore, mapStatus])
}

export { useInitAuthUserMap }
