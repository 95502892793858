import { useDispatch } from 'react-redux'
import { likeMutation, unlikeMutation } from '~map-viewer/states/like'
import { useMap } from '~map-viewer/states/map'

const useSocialAction = () => {
  const dispatch = useDispatch()
  const map = useMap()

  const like = () => {
    dispatch(likeMutation(map.id))
  }

  const unlike = () => {
    dispatch(unlikeMutation(map.id))
  }

  return { like, unlike }
}

export { useSocialAction }
export default useSocialAction
