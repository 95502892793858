import React from 'react'
import { Layer, Source } from 'react-map-gl/maplibre'
import {
  BASE_MAP_LAYER_ID,
  PROMOTED_COLUMN_ID
} from '~map-viewer/assets/constant/LayerConstant'

export const View = (props) => {
  const { tiles, layerStyle, lineLayerStyle, filterBorderStyle, type, source } =
    props

  return (
    <Source
      id={source}
      type={type}
      tiles={tiles}
      promoteId={PROMOTED_COLUMN_ID}
      volatile
    >
      <Layer
        id='default'
        beforeId={BASE_MAP_LAYER_ID}
        source={source}
        source-layer='default'
        {...layerStyle}
      />
      <Layer
        id={`${source}-filter-border`}
        source={source}
        source-layer='default'
        {...filterBorderStyle}
      />
      <Layer
        id={`${source}-line`}
        source={source}
        source-layer='default'
        {...lineLayerStyle}
      />
    </Source>
  )
}
