import type { FC } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import ButtonBase from '@mui/material/ButtonBase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface MultiSelectProps {
  label: string
  onClick?: () => void
}

export const PricingButton: FC<MultiSelectProps> = (props) => {
  const { label, onClick = () => {} } = props
  return (
    <>
      <Box
        component='li'
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <ButtonBase
          disableRipple
          onClick={onClick}
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            px: '16px',
            py: '8px',
            textAlign: 'left',
            '&:hover': {
              backgroundColor: 'action.hover'
            }
          }}
        >
          <Typography
            component='span'
            variant='subtitle1'
          >
            {label}
          </Typography>
        </ButtonBase>
      </Box>
    </>
  )
}

PricingButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func
}
