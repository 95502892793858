import { Badge } from '@mui/material'
import { FilterFunnel01 } from '@untitled-ui/icons-react/build/esm'
import { FC } from 'react'

import {
  IconListItem,
  IconListItemProps
} from '~ui-components/components/molecules/IconListItem'

export type MapFilterButtonProps = IconListItemProps & {
  active: boolean
  resultsCount?: string
}

export const MapFilterButton: FC<MapFilterButtonProps> = ({
  active,
  resultsCount,
  ...props
}) => {
  return (
    <Badge
      color='error'
      badgeContent={resultsCount}
      sx={{ '.MuiBadge-badge': { top: 12, right: 12 } }}
    >
      <IconListItem
        primary='Filters'
        icon={FilterFunnel01}
        iconProps={
          active
            ? {
                sx: {
                  color: (theme) => theme.palette.secondary.main
                }
              }
            : undefined
        }
        primaryTypographyProps={
          active
            ? {
                sx: {
                  color: (theme) => theme.palette.secondary.main
                }
              }
            : undefined
        }
        {...props}
      />
    </Badge>
  )
}
