import React from 'react'
import { Alert } from '@mui/material'

const UserProfileSettingsTab = () => {
  return (
    <Alert
      variant='filled'
      severity='info'
    >
      Coming soon!
    </Alert>
  )
}

export { UserProfileSettingsTab }
export default UserProfileSettingsTab
