import { Button, Card } from '@mui/material'
import DotsGrid from '@untitled-ui/icons-react/build/esm/DotsGrid'
import { isEmpty } from 'lodash'

import { EmptySlotFallback } from '~ui-components/components/molecules/EmptySlotFallback'
import {
  WorkspaceListStack,
  WorkspaceListStackProps
} from '~ui-components/components/molecules/WorkspaceLists/WorkspaceListStack'
import {
  WorkspacesTabTools,
  WorkspacesTabToolsProps
} from './WorkspacesTabTools'
import { FC } from 'react'

type WorkspacesTabProps = WorkspaceListStackProps & WorkspacesTabToolsProps

const WorkspacesTab: FC<WorkspacesTabProps> = ({
  canCreateWorkspace = false,
  memberships = [],
  onCreateWorkspaceClick = () => {}
}) => {
  return (
    <Card>
      <WorkspacesTabTools
        canCreateWorkspace={canCreateWorkspace}
        onCreateWorkspaceClick={onCreateWorkspaceClick}
      />

      {!isEmpty(memberships) ? (
        <WorkspaceListStack memberships={memberships} />
      ) : (
        <EmptySlotFallback
          icon={DotsGrid}
          text='This account has no workspaces!'
          button={
            canCreateWorkspace ? (
              <Button
                data-testid='CreateWorkspaceButtonInEmptySlot'
                onClick={onCreateWorkspaceClick}
              >
                Create a workspace
              </Button>
            ) : null
          }
        />
      )}
    </Card>
  )
}

export { WorkspacesTab }
