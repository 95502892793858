import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { SearchBarInput as Input } from 'ui-components'
import { getSearchQuery, throttle } from 'utils'
import {
  SearchMapsVariables,
  useSearchMaps
} from '~search-box/queries/useSearchMaps'

import { SIZE } from '~search-box/constants/search'
import { Feed_Types } from '~search-box/types/__generated/gql/graphql'

import { SearchModal } from './SearchModal'

const Search = (props) => {
  const router = useRouter()

  const [open, setOpen] = useState(false)
  const [searchVariables, setSearchVariables] = useState<SearchMapsVariables>({
    query: '',
    from: 0, // there's no pagination in the header search
    size: SIZE,
    feedType: Feed_Types.All
  })
  const { refetch, data } = useSearchMaps(searchVariables)
  const results = useMemo(() => data?.items || [], [data])

  const gotoSearchResults = () => {
    const { query: keywords } = searchVariables

    if (!keywords) return

    router.push(getSearchQuery({ keywords: keywords }))
  }

  const handleChange = (value) => {
    setSearchVariables((prev) => ({ ...prev, query: value }))
    throttle(refetch, 200)
  }

  const handleSearchClick = () => gotoSearchResults()

  const handleSubmit = () => gotoSearchResults()

  const handleClick = () => {
    setOpen(true)
  }

  const handleCLose = () => {
    setOpen(false)
  }

  return (
    <>
      <Input
        {...props}
        value={searchVariables.query}
        results={results}
        onChange={handleChange}
        onSearchClick={handleSearchClick}
        onSubmit={handleSubmit}
        onClick={handleClick}
      />

      <SearchModal
        open={open}
        onClose={handleCLose}
      />
    </>
  )
}

export { Search }
