import { FC, useState } from 'react'

import {
  Avatar,
  Box,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses
} from '@mui/material'
import { Mail01, User01 } from '@untitled-ui/icons-react'

import { LoadingButton } from '@mui/lab'
import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'
import { User } from '~ui-components/types/__generated/gql/graphql'

export type UsersSearchListProps = {
  input: string
  isInputValid: boolean
  user?: User
  onAddEmailClick: (email: string) => Promise<void>
}

export const UsersSearchList: FC<UsersSearchListProps> = ({
  input,
  isInputValid,
  user,
  onAddEmailClick
}) => {
  const [loading, setLoading] = useState(false)

  const handleClick = async (email: string) => {
    try {
      setLoading(true)
      await onAddEmailClick(email)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <Stack spacing={1}>
      <Typography
        color='text.secondary'
        variant='overline'
      >
        {isInputValid ? 'Select person' : 'Keep typing to invite email'}
      </Typography>

      <Scrollbar>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none'
            }
          }}
        >
          <TableBody>
            {user ? (
              <TableRow>
                <TableCell sx={{ px: 0 }}>
                  <Stack
                    alignItems='center'
                    direction='row'
                    spacing={1}
                  >
                    <Avatar
                      src={user.avatar ?? undefined}
                      sx={{
                        height: 40,
                        width: 40
                      }}
                    >
                      <SvgIcon>
                        <User01 />
                      </SvgIcon>
                    </Avatar>
                    <Box>
                      <Typography variant='subtitle2'>
                        {user.firstName} {user.lastName}
                      </Typography>
                      <Typography
                        color='text.secondary'
                        variant='body2'
                      >
                        {user.email}
                      </Typography>
                    </Box>
                  </Stack>
                </TableCell>

                <TableCell
                  sx={{ px: 0 }}
                  align='right'
                >
                  <LoadingButton
                    variant='contained'
                    loading={loading}
                    onClick={() => handleClick(user.email)}
                  >
                    Add
                  </LoadingButton>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell sx={{ px: 0 }}>
                  <Stack
                    alignItems='center'
                    direction='row'
                    spacing={1}
                  >
                    <SvgIcon>
                      <Mail01 />
                    </SvgIcon>
                    <Typography variant='subtitle2'>{input}</Typography>
                  </Stack>
                </TableCell>
                {isInputValid ? (
                  <TableCell
                    sx={{ px: 0 }}
                    align='right'
                  >
                    <LoadingButton
                      variant='contained'
                      loading={loading}
                      onClick={() => handleClick(input)}
                    >
                      Add
                    </LoadingButton>
                  </TableCell>
                ) : null}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Scrollbar>
    </Stack>
  )
}
