import Head from 'next/head'
import React from 'react'

type OembedHeaderProps = {
  baseUrl?: string
  mapId?: string
  title?: string
  embedKey?: string
}

export const OembedHeader = (props: OembedHeaderProps) => {
  const { baseUrl, title, embedKey, mapId } = props

  let mapUrl = `${baseUrl}/map/${mapId}/embed`
  if (embedKey) mapUrl += `?embedKey=${embedKey}`

  return (
    <Head>
      <link
        rel='alternate'
        type='application/json+oembed'
        href={`${baseUrl}/oembed?url=${mapUrl}&format=json`}
        title={title}
      />
      <link
        rel='alternate'
        type='text/xml+oembed'
        href={`${baseUrl}/oembed?url=${mapUrl}&format=xml`}
        title={title}
      />
    </Head>
  )
}
