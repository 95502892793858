import { Box } from '@mui/material'
import Container from '@mui/material/Container'
import { Fragment } from 'react'
import Footer from '~ui-components/components/organisms/Footer'
import PostTemplate from '~ui-components/components/pages/PostId'
import type { Post } from '~ui-components/components/pages/PostId/types/blog'

const Main = (props) => {
  return (
    <Box
      component='main'
      {...props}
      sx={{
        pt: 'env(safe-area-inset-top)'
      }}
    />
  )
}

type BlogProps = {
  header?: React.ComponentType
  post: Post
}

const Post = (props: BlogProps) => {
  const { header: Header = () => null, post } = props
  return (
    <Fragment>
      <Header />
      <Main position='relative'>
        <Container maxWidth='lg'></Container>
        <PostTemplate post={post} />
      </Main>
      <Footer />
    </Fragment>
  )
}

export { Post }
export default Post
