import { Box, Paper, SvgIcon, Typography } from '@mui/material'
import Map01 from '@untitled-ui/icons-react/build/esm/Map01'

const EmptyMapResultFallback = (props) => {
  const { sx, ...rest } = props
  return (
    <Box
      component={Paper}
      data-testid='MapSearchNoResult'
      variant='outlined'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      minHeight={300}
      sx={[{ borderStyle: 'dashed' }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}
    >
      <SvgIcon
        fontSize='large'
        sx={{ opacity: 0.4 }}
      >
        <Map01 />
      </SvgIcon>
      <Box
        component={Typography}
        variant='h6'
        fontWeight={700}
        mt={2}
        sx={{ opacity: 0.4 }}
      >
        No maps found
      </Box>
    </Box>
  )
}

export { EmptyMapResultFallback }
export default EmptyMapResultFallback
