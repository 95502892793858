import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { VISUALISATION } from 'utils'
import {
  useDefaultCategoryPropertyKeys,
  useDefaultQuantityPropertyKey
} from '~map-creator/core/states/geojson'
import {
  setActiveVisualisationKey,
  setConfigurations,
  useCategoryKeys,
  useConfigurations,
  useIncludedKeys,
  useQuantityPropertiesKeys
} from '~map-creator/core/states/properties'
import { View } from './view'
const { CATEGORY, QUANTITY, NONE } = VISUALISATION

const SelectVisualisations = ({ onValidated }) => {
  const dispatch = useDispatch()

  const configurations = useConfigurations()
  const includedKeys = useIncludedKeys()
  const quantityProperties = useDefaultQuantityPropertyKey()
  const categoryProperties = useDefaultCategoryPropertyKeys()

  const selectedQuantityProperties = useQuantityPropertiesKeys()
  const selectedCategoryProperties = useCategoryKeys()

  useEffect(() => {
    onValidated(
      selectedQuantityProperties.length || selectedCategoryProperties.length
    )
  }, [selectedQuantityProperties, selectedCategoryProperties, onValidated])

  const quantityPropertiesOptions = useMemo(() => {
    return quantityProperties
      .filter((k) => includedKeys.includes(k))
      .filter((k) => configurations[k].type !== 'date')
      .map((k) => ({
        label: configurations[k].name,
        value: k
      }))
  }, [configurations, quantityProperties, includedKeys])

  const categoryPropertyOptions = useMemo(() => {
    return categoryProperties
      .filter((k) => includedKeys.includes(k))
      .map((k) => ({
        label: configurations[k].name,
        value: k
      }))
  }, [categoryProperties, configurations, includedKeys])

  const updateQuantityVisualisation = (keys) => {
    const newConfig = _.cloneDeep(configurations)
    quantityPropertiesOptions.forEach(({ value }) => {
      const isVisualisation = keys.includes(value)
      newConfig[value].visualisation = isVisualisation ? QUANTITY : NONE
    })

    dispatch(setActiveVisualisationKey(keys[0]))
    dispatch(setConfigurations(newConfig))
  }

  const updateCategoryVisualisation = (keys) => {
    const newConfig = _.cloneDeep(configurations)
    categoryPropertyOptions.forEach(({ value }) => {
      const isVisualisation = keys.includes(value)
      newConfig[value].visualisation = isVisualisation ? CATEGORY : NONE
    })
    dispatch(setActiveVisualisationKey(keys[0]))
    dispatch(setConfigurations(newConfig))
  }

  const handleQuantityChange = (keys) => {
    updateQuantityVisualisation(keys)
  }

  const handleCategoryChange = (keys) => {
    updateCategoryVisualisation(keys)
  }

  return (
    <View
      selectQuantityProps={{
        selected: selectedQuantityProperties,
        options: quantityPropertiesOptions,
        onChange: handleQuantityChange
      }}
      selectCategoryProps={{
        selected: selectedCategoryProperties,
        options: categoryPropertyOptions,
        onChange: handleCategoryChange
      }}
    />
  )
}

SelectVisualisations.propTypes = {
  onValidated: PropTypes.func
}

SelectVisualisations.defaultProps = {
  onValidated: () => false
}

export { SelectVisualisations }
export default SelectVisualisations
