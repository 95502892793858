import { CssBaseline, ThemeProvider } from '@mui/material'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { LicenseInfo } from '@mui/x-data-grid-pro'

import { FC, ReactNode } from 'react'
import { RTL } from '~ui-components/components/atoms/RTL'
import { createTheme } from '~ui-components/theme'
import { ThemeConfig } from '~ui-components/types'

LicenseInfo.setLicenseKey(
  '54b15a57982fcfd357f997b69b2e34f7T1JERVI6Mzk0NzQsRVhQSVJZPTE2Nzg2MTEzNDAwMDAsS0VZVkVSU0lPTj0x'
)

/**
 * Will support CSS Variable Provider in the future.
 */
const UIProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const settings: ThemeConfig = {
    colorPreset: 'green',
    contrast: 'high',
    direction: 'ltr',
    paletteMode: 'light',
    responsiveFontSizes: true
  }

  const theme = createTheme(settings)

  return (
    <HelmetProvider>
      <DndProvider backend={HTML5Backend}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <Helmet>
              <meta
                name='color-scheme'
                content={settings.paletteMode}
              />
              <meta
                name='theme-color'
                content={theme.palette.neutral[900]}
              />
            </Helmet>
            <RTL direction={settings.direction}>
              <CssBaseline />
              {children}
            </RTL>
          </ThemeProvider>
        </LocalizationProvider>
      </DndProvider>
    </HelmetProvider>
  )
}

export { UIProvider }
export default UIProvider
