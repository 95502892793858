import { useMemo } from 'react'
import { getMapThumbnailLink } from 'utils'
import { mapstack32pxPng } from '~ui-components/assets/emails/logos'
import { Box, Image, Item } from '~ui-components/emails/components/atoms'
import { Email } from '~ui-components/emails/components/molecules'
import {
  Footer,
  MapstackInfoCopy
} from '~ui-components/emails/components/organisms'
import { assetUrlOf } from '~ui-components/emails/helpers/assetUrlOf'
import {
  InvitationIntro,
  MapInfo,
  MapLink
} from '../../organisms/MapGuestPanels'
import { get } from 'lodash'

export const MapGuestInvitation = ({ map, user, inviteLink }) => {
  const { firstName, lastName } = user
  const username = useMemo(
    () => `${firstName} ${lastName}`,
    [firstName, lastName]
  )
  const mapName = get(map, 'name', '')
  const thumbnailUrl = getMapThumbnailLink(map)
  return (
    <Email
      title={`Action required: ${username}  invited you to view their map on Mapstack`}
    >
      <Item
        pt={48}
        align='left'
      >
        <Image
          src={assetUrlOf(mapstack32pxPng)}
          alt='mapstack logo'
          width='auto'
          height={32}
          borderRadius='rounded'
        />
      </Item>
      <Item pt={32}>
        <InvitationIntro
          username={username}
          mapName={mapName}
        />
      </Item>
      <Item pt={32}>
        <Box
          fullWidth
          border='solid 1px'
          borderColor='rgba(0, 0, 0, 0.2)'
          borderRadius={4}
        >
          <Item>
            <MapLink
              mapName={mapName}
              owner={user}
              inviteLink={inviteLink}
            />
          </Item>
          <Item>
            <div
              style={{
                height: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.2)'
              }}
            />
          </Item>
          <Item>
            <MapInfo
              inviteLink={inviteLink}
              mapName={mapName}
              thumbnailUrl={thumbnailUrl}
            />
          </Item>
        </Box>
      </Item>
      <Item pt={32}>
        <MapstackInfoCopy />
      </Item>
      <Item
        pt={56}
        pb={56}
      >
        <Footer />
      </Item>
    </Email>
  )
}
