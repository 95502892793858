import { MapMetadataOrigin } from 'ui-components'
import { useMap } from '~map-viewer/states/map'

function Origin() {
  const { parentMap } = useMap()
  return (
    <MapMetadataOrigin
      hasParentMap={!!parentMap}
      parentMap={parentMap}
    />
  )
}

export { Origin }
