import {
  createExitBreakPlugin,
  ELEMENT_PARAGRAPH,
  KEYS_HEADING
} from '@udecode/plate'

export default () =>
  createExitBreakPlugin({
    options: {
      rules: [
        {
          hotkey: 'mod+enter'
        },
        {
          hotkey: 'mod+shift+enter',
          before: true
        },
        {
          hotkey: 'enter',
          query: {
            start: true,
            end: true,
            allow: [...KEYS_HEADING, ELEMENT_PARAGRAPH]
          },
          relative: true,
          level: 1
        }
      ]
    }
  })
