import { Stack, SxProps } from '@mui/material'
import React from 'react'
import { MapMetadataOrigin } from '.'
import { MapMetadataCreatedAt } from './MapMetadataCreatedAt'
import { MapMetadataLicense } from './MapMetadataLicense'
import { MapMetadataProfile } from './MapMetadataProfile'
import { MapMetadataRemixes } from './MapMetadataRemixes'
import { MapMetadataSource } from './MapMetadataSource'
import { MapMetadataViews } from './MapMetadataViews'

interface MapMetadataProps {
  hasParentMap: boolean
  sx?: SxProps
  slots?: {
    Profile?: typeof MapMetadataProfile
    Views?: typeof MapMetadataViews
    Remixes?: typeof MapMetadataRemixes
    CreatedAt?: typeof MapMetadataCreatedAt
    License?: typeof MapMetadataLicense
    Source?: typeof MapMetadataSource
    Origin?: typeof MapMetadataOrigin
  }
  slotProps: {
    Profile: React.ComponentProps<typeof MapMetadataProfile>
    Views: React.ComponentProps<typeof MapMetadataViews>
    Remixes: React.ComponentProps<typeof MapMetadataRemixes>
    CreatedAt: React.ComponentProps<typeof MapMetadataCreatedAt>
    License: React.ComponentProps<typeof MapMetadataLicense>
    Source: React.ComponentProps<typeof MapMetadataSource>
    Origin: React.ComponentProps<typeof MapMetadataOrigin>
  }
}

const defaultSlots = {
  Profile: MapMetadataProfile,
  Views: MapMetadataViews,
  Remixes: MapMetadataRemixes,
  CreatedAt: MapMetadataCreatedAt,
  License: MapMetadataLicense,
  Source: MapMetadataSource,
  Origin: MapMetadataOrigin
}

const MapMetadata = (props: MapMetadataProps) => {
  const { hasParentMap = false, sx, slots, slotProps } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  } as const

  return (
    <Stack
      gap={1.5}
      direction='column'
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Stack
        direction='row'
        gap={1.5}
        flexWrap='wrap'
      >
        <Slots.Profile {...slotProps?.Profile} />
        <Slots.Views {...slotProps?.Views} />
        <Slots.Remixes {...slotProps?.Remixes} />
        <Slots.CreatedAt {...slotProps?.CreatedAt} />
        <Slots.License {...slotProps?.License} />
        <Slots.Source {...slotProps?.Source} />
      </Stack>
      {hasParentMap ? (
        <Stack direction='row'>
          <Slots.Origin {...slotProps?.Origin} />
        </Stack>
      ) : null}
    </Stack>
  )
}

export { MapMetadataCreatedAt } from './MapMetadataCreatedAt'
export { MapMetadataLicense } from './MapMetadataLicense'
export { MapMetadataOrigin } from './MapMetadataOrigin'
export { MapMetadataProfile } from './MapMetadataProfile'
export { MapMetadataRemixes } from './MapMetadataRemixes'
export { MapMetadataSource } from './MapMetadataSource'
export { MapMetadataViews } from './MapMetadataViews'
export { MapMetadata }
