import { CalendarDate } from '@untitled-ui/icons-react'
import { relativeFromNow } from 'utils'
import { IconLabel } from '~ui-components/components/molecules/IconLabel'

interface MapMetadataCreatedAtProps {
  createdAt: string
}

function MapMetadataCreatedAt(props: MapMetadataCreatedAtProps) {
  const { createdAt } = props
  return (
    <IconLabel
      icon={CalendarDate}
      primaryText={relativeFromNow(createdAt)}
    />
  )
}

export { MapMetadataCreatedAt }
