import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Button,
  Dialog,
  DialogProps,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { MarkerPin01 as MarkerPin } from '@untitled-ui/icons-react'

import { Strong } from '~ui-components/components/atoms/Strong'

interface GeocoderDialogProps extends DialogProps {
  limits: number
  onPrimaryClick: () => void
  onSecondaryClick: () => void
  PrimaryActionProps?: React.ComponentProps<typeof Button>
  SecondaryActionProps?: React.ComponentProps<typeof LoadingButton>
}

function GeocoderDialog(props: GeocoderDialogProps) {
  const {
    limits,
    open,
    onClose,
    onPrimaryClick,
    onSecondaryClick,
    PrimaryActionProps,
    SecondaryActionProps,
    ...rest
  } = props

  return (
    <Dialog
      maxWidth='sm'
      open={open}
      onClose={onClose}
      {...rest}
      sx={(theme) => ({
        p: 3,
        [theme.breakpoints.down(528)]: {
          p: 0
        }
      })}
    >
      <Paper
        component={Stack}
        spacing={2}
        elevation={12}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar
          sx={{
            backgroundColor: 'success.lightest',
            color: 'success.main',
            width: 60,
            height: 60
          }}
        >
          <SvgIcon fontSize='large'>
            <MarkerPin />
          </SvgIcon>
        </Avatar>

        <Typography variant='h5'>Maximize Your Mapping!</Typography>

        <Typography
          align='center'
          color='text.secondary'
          variant='body2'
        >
          You've mapped {limits} addresses and hit the limit for anonymous
          users. But there's good news! Sign in now, and you can map the rest of
          your addresses for <Strong>free</Strong>.
        </Typography>

        <Button
          fullWidth
          size='large'
          variant='contained'
          onClick={onPrimaryClick}
          {...PrimaryActionProps}
        >
          Sign in & map more for free
        </Button>

        <LoadingButton
          fullWidth
          size='large'
          variant='text'
          color='secondary'
          onClick={onSecondaryClick}
          {...SecondaryActionProps}
        >
          Continue with {limits} addresses only
        </LoadingButton>
      </Paper>
    </Dialog>
  )
}

export { GeocoderDialog }
