import { useEffect } from 'react'
import { useWindowSize } from 'ui-components'
import { useFitBounds, useMap } from '~map-viewer/states/map'

export const useSetFitBounds = (mapGl) => {
  const map = useMap()
  const { width, height } = useWindowSize()
  const fitBounds = useFitBounds()

  const handleFitbounds = (fitBounds) => {
    const [minLng, minLat, maxLng, maxLat] = fitBounds

    mapGl.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat]
      ],
      {
        duration: 1500
      }
    )
  }

  useEffect(() => {
    const setup = () => {
      if (!mapGl || !width || !height) return

      const hasFitBounds = fitBounds.length

      if (hasFitBounds) {
        handleFitbounds(fitBounds)
        return
      }

      if (map?.bbox) {
        handleFitbounds(map.bbox)
      }
    }
    setup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapGl, map, fitBounds, width, height])
}
