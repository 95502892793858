import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import DateIcon from '@untitled-ui/icons-react/build/cjs/CalendarCheck01'
import DotPointsIcon from '@untitled-ui/icons-react/build/cjs/Dotpoints02'
import HashIcon from '@untitled-ui/icons-react/build/cjs/Hash02'
import type { FC } from 'react'
import { Fragment, useEffect, useState } from 'react'
import { isDateType, isNumericType, isStringType } from 'utils'
import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'
import FilterDateInput from '~ui-components/components/molecules/FilterDateInput'
import FilterNumericInput from '~ui-components/components/molecules/FilterNumericInput'
import FilterStringInput from '~ui-components/components/molecules/FilterStringInput'

interface Property {
  id: string
  name: string
  type: string
  value: Range | Category | Date
  selectedValue: number[] | string[] | Date
}

interface Range {
  minRange: number
  maxRange: number
}

interface Category {
  options: string[]
}

interface Date {
  minDate: string
  maxDate: string
}

interface FilterChangeArg {
  id: string
  name: string
  type: string
  value: Range | string[] | Date | number[]
}

interface PropertiesListAllProps {
  propertiesValue?: Property[]
  onFilterChange?: (arg: FilterChangeArg) => void
  onFilterRemove?: (id: string) => void
  onFiltersActiveChange?: (active: number) => void
}

const PropertiesListAll: FC<PropertiesListAllProps> = (props) => {
  const {
    propertiesValue = [],
    onFilterChange = () => {},
    onFilterRemove = () => {},
    onFiltersActiveChange = () => {}
  } = props

  //Active Rows to keep track of the rows that are expanded
  //initiall value base on the property.selectedValue
  const [activeRows, setActiveRows] = useState<boolean[]>(
    propertiesValue.map((property) => property.selectedValue !== undefined)
  )

  useEffect(() => {
    onFiltersActiveChange(activeRows.filter((row) => row).length)
  }, [activeRows])

  return (
    <div>
      <Scrollbar>
        <Table>
          <TableBody>
            {propertiesValue.map((property, i) => {
              return (
                <Fragment key={i}>
                  <TableRow
                    hover
                    key={`table-row-${i}`}
                    sx={{
                      cursor: 'pointer'
                    }}
                  >
                    <TableCell
                      onClick={() => {
                        if (activeRows[i]) onFilterRemove?.(property.id)

                        setActiveRows((prev) => {
                          const next = [...prev]
                          next[i] = !next[i]
                          return next
                        })
                      }}
                      padding='checkbox'
                      sx={{
                        ...(activeRows[i] && {
                          position: 'relative',
                          width: '100%',
                          '&:after': {
                            position: 'absolute',
                            content: '" "',
                            top: 0,
                            left: 0,
                            backgroundColor: 'primary.main',
                            width: 3,
                            height: 'calc(100% + 1px)'
                          }
                        })
                      }}
                    >
                      <Box>
                        <FormControlLabel
                          control={<Checkbox checked={activeRows[i]} />}
                          label={property.name}
                        />
                        <IconButton sx={{ float: 'right' }}>
                          <SvgIcon>
                            {isNumericType(property.type) && <HashIcon />}
                            {isStringType(property.type) && <DotPointsIcon />}
                            {isDateType(property.type) && <DateIcon />}
                          </SvgIcon>
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                  {activeRows[i] && (
                    <TableRow>
                      <TableCell
                        colSpan={7}
                        sx={{
                          p: 0,
                          position: 'relative',
                          '&:after': {
                            position: 'absolute',
                            content: '" "',
                            top: 0,
                            left: 0,
                            backgroundColor: 'primary.main',
                            width: 3,
                            height: 'calc(100% + 1px)'
                          }
                        }}
                      >
                        <CardContent>
                          {isNumericType(property.type) && (
                            <FilterNumericInput
                              defaultProperty={property.value as Range}
                              selectedValue={property.selectedValue as number[]}
                              onApplyFilter={(obj, value) => {
                                const filter = {
                                  id: property.id,
                                  name: property.name,
                                  type: property.type,
                                  value
                                }
                                onFilterChange && onFilterChange(filter)
                              }}
                            />
                          )}
                          {isStringType(property.type) && (
                            <FilterStringInput
                              defaultProperty={property.value as Category}
                              selectedValue={property.selectedValue as string[]}
                              onApplyFilter={(value) => {
                                const filter = {
                                  id: property.id,
                                  name: property.name,
                                  type: property.type,
                                  value
                                }
                                onFilterChange && onFilterChange(filter)
                              }}
                            />
                          )}
                          {isDateType(property.type) && (
                            <FilterDateInput
                              defaultProperty={property.value as Date}
                              onApplyFilter={(type, value) => {
                                const filter = {
                                  id: property.id,
                                  name: property.name,
                                  type: property.type,
                                  value
                                }
                                onFilterChange && onFilterChange(filter)
                              }}
                            />
                          )}
                        </CardContent>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  )
}

export { PropertiesListAll }
export default PropertiesListAll
