import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  Typography
} from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

let timeout
const UnauthenticatedActionTooltip = (props) => {
  const {
    disablePortal = false,
    primary = '',
    secondary = '',
    anchorEl = null,
    onClose = () => {},
    onSignInClick = () => {}
  } = props

  const open = Boolean(anchorEl)

  useEffect(() => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      onClose()
    }, 3000)
    return () => clearTimeout(timeout)
  }, [anchorEl])

  const handleMouseEnter = () => {
    clearTimeout(timeout)
  }

  const handleMouseLeave = () => {
    timeout = setTimeout(() => {
      onClose()
    }, 2000)
  }

  return (
    <Popper
      // ::note:: useful for testing
      disablePortal={disablePortal}
      componentsProps={{}}
      open={open}
      anchorEl={anchorEl}
      placement='left'
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 16]
          }
        }
      ]}
      sx={{
        zIndex: 'unauthenticatedActionTooltip'
      }}
      transition
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClose}>
          <Box data-testid='UnauthenticatedActionTooltip'>
            <Fade
              {...TransitionProps}
              timeout={200}
            >
              <Box
                position='relative'
                p={2}
                component={Paper}
                borderRadius={2}
                variant='elevation'
                elevation={4}
              >
                {!isEmpty(primary) ? (
                  <Box
                    data-testid='UnauthenticatedActionTooltip-Primary'
                    variant='h5'
                    component={Typography}
                    fontWeight='bold'
                    letterSpacing='tight'
                  >
                    {primary}
                  </Box>
                ) : null}

                {!isEmpty(secondary) ? (
                  <Box
                    data-testid='UnauthenticatedActionTooltip-Secondary'
                    mt={1}
                    variant='subtitle2'
                    component={Typography}
                    fontWeight='medium'
                    color='text.secondary'
                  >
                    {secondary}
                  </Box>
                ) : null}

                <Button
                  data-testid='UnauthenticatedActionTooltip-SignIn'
                  variant='outlined'
                  onClick={onSignInClick}
                  sx={{ mt: 2 }}
                >
                  Sign in
                </Button>
              </Box>
            </Fade>
          </Box>
        </ClickAwayListener>
      )}
    </Popper>
  )
}

UnauthenticatedActionTooltip.propTypes = {
  disablePortal: PropTypes.bool,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  onSignInClick: PropTypes.func
}

export { UnauthenticatedActionTooltip }
export default UnauthenticatedActionTooltip
