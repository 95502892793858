import { createSlice } from '@reduxjs/toolkit'
import { isEmpty, union, without } from 'lodash'
import { useSelector } from 'react-redux'
import {
  VISUALISATION,
  getAllGeojsonFeatureProperties,
  getTypeOfEachProperties
} from 'utils'
import mapApi from '~map-creator/core/services/map'

const { CATEGORY, QUANTITY } = VISUALISATION

const initialState = {
  configurations: {},
  includedKeys: [],
  activeVisualisationKey: '',
  uniquePropertyKey: '',
  includePropertyError: null
}

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
    setConfigurations: (state, action) => {
      if (isEmpty(action.payload)) return state
      state.configurations = action.payload
    },
    updatePropertyName: (state, action) => {
      const { key, value } = action.payload
      const nextConfigurations = { ...state.configurations }
      nextConfigurations[key].name = value
      state.configurations = nextConfigurations
    },
    updatePropertyType: (state, action) => {
      const { key, value } = action.payload
      const nextConfigurations = { ...state.configurations }
      nextConfigurations[key].type = value
      state.configurations = nextConfigurations
    },
    updatePropertyVisibility: (state, action) => {
      const { key, value } = action.payload
      const keys = [...state.includedKeys]
      state.includedKeys = value ? union(keys, [key]) : without(keys, key)
    },
    updateTitlePropertyKey: (state, action) => {
      const value = action.payload
      let nextConfigurations = { ...state.configurations }
      nextConfigurations = Object.fromEntries(
        Object.keys(nextConfigurations).map((k) => {
          if (k === value)
            return [k, { ...nextConfigurations[k], isTitle: true }]
          return [k, { ...nextConfigurations[k], isTitle: false }]
        })
      )
      state.configurations = nextConfigurations
    },
    setActiveVisualisationKey: (state, action) => {
      state.activeVisualisationKey = action.payload
    },
    setUniquePropertyKey: (state, action) => {
      const newUniquePropertyKey =
        action.payload || initialState.uniquePropertyKey

      state.uniquePropertyKey = newUniquePropertyKey
    },
    updateDefaultVisualisationPropertyKey: (state, action) => {
      const prevConfigurations = state.configurations
      const newConfigurations = Object.keys(prevConfigurations).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...prevConfigurations[key],
            isDefaultVisualisation: key === action.payload
          }
        }),
        {}
      )
      state.configurations = newConfigurations
    },
    setIncludedKeys: (state, action) => {
      state.includedKeys = action.payload
    },
    setIncludePropertyError: (state, action) => {
      state.includePropertyError = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      mapApi.endpoints.getMapById.matchFulfilled,
      (state, action) => {
        const map = action.payload
        const includedKeys = map.properties.map((m) => m.name)
        state.includedKeys = includedKeys

        const propertiesType = getTypeOfEachProperties(
          getAllGeojsonFeatureProperties(map.geojson)
        )

        const configurations = map.properties.reduce((pre, cur) => {
          cur.type = propertiesType[cur.name].type
          return { ...pre, [cur.name]: cur }
        }, {})

        if (!isEmpty(configurations)) state.configurations = configurations
      }
    )
  }
})

export const {
  reset,
  setConfigurations,
  updatePropertyName,
  updatePropertyVisibility,
  updateTitlePropertyKey,
  updatePropertyType,
  setActiveVisualisationKey,
  setUniquePropertyKey,
  updateDefaultVisualisationPropertyKey,
  setIncludedKeys,
  setIncludePropertyError
} = propertiesSlice.actions

const useConfigurations = () =>
  useSelector((state) => state.properties.configurations)

const useIncludedKeys = () =>
  useSelector((state) => state.properties.includedKeys)

const useActiveVisualisationKey = () =>
  useSelector((state) => state.properties.activeVisualisationKey)

const useQuantityPropertiesKeys = () => {
  const configurations = useConfigurations()
  const includedProperties = useIncludedKeys()
  return includedProperties.filter(
    (k) => configurations[k].visualisation === QUANTITY
  )
}

const useCategoryKeys = () => {
  const configurations = useConfigurations()
  const includedProperties = useIncludedKeys()
  return includedProperties.filter(
    (k) => configurations[k].visualisation === CATEGORY
  )
}

const useMapViewOptions = () => {
  const configurations = useConfigurations()
  const includedKeys = useIncludedKeys()
  const visualisationOptions = includedKeys
    .filter((k) => {
      const { visualisation } = configurations[k]
      return [QUANTITY, CATEGORY].includes(visualisation)
    })
    .map((k) => ({ label: configurations[k].name, value: k }))
  return visualisationOptions
}

const useTitlePropertyKey = () => {
  const configurations = useConfigurations()
  return Object.keys(configurations || {}).find(
    (k) => configurations[k].isTitle
  )
}

const useDefaultVisualisationPropertyKey = () => {
  const configurations = useConfigurations()
  return Object.keys(configurations || {}).find(
    (k) => configurations[k].isDefaultVisualisation
  )
}

const useUniquePropertyKey = () =>
  useSelector((state) => state.properties.uniquePropertyKey)

const useIncludePropertyError = () =>
  useSelector((state) => state.properties.includePropertyError)

export {
  useActiveVisualisationKey,
  useCategoryKeys,
  useConfigurations,
  useDefaultVisualisationPropertyKey,
  useIncludePropertyError,
  useIncludedKeys,
  useMapViewOptions,
  useQuantityPropertiesKeys,
  useTitlePropertyKey,
  useUniquePropertyKey
}
export default propertiesSlice.reducer
