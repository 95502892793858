import { Stack } from '@mui/material'
import { PlusCircle } from '@untitled-ui/icons-react'

import { MapCreateForm } from '~ui-components/components/molecules/MapCreateForm'
import { Sidebar } from '~ui-components/components/molecules/Sidebar'

type Slots = {
  MapCreateForm: typeof MapCreateForm
}

interface MapCreateDrawerProps {
  open: boolean
  onClose?: () => void
  slots?: Slots
  slotProps?: {
    MapCreateForm?: any
  }
}

const defaultSlots = {
  MapCreateForm
}

function MapCreateDrawer(props: MapCreateDrawerProps) {
  const { open, onClose, slots, slotProps } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Sidebar
      anchor='left'
      open={open}
      title='Create a map'
      icon={PlusCircle}
      onClose={onClose}
      slotProps={{
        backdrop: {
          invisible: false
        }
      }}
    >
      <Stack
        p={2}
        spacing={4}
      >
        <Slots.MapCreateForm {...slotProps?.MapCreateForm} />
      </Stack>
    </Sidebar>
  )
}

export { MapCreateDrawer }
