import { WorkspacesListMenu as View } from 'ui-components'
import { useAuthCurrentWorkspace, useAuthWorkspaces } from 'user-auth'

export function Workspaces() {
  const workspaces = useAuthWorkspaces()
  const currentWorkspace = useAuthCurrentWorkspace()

  if (!workspaces) return null

  return (
    <View
      workspaces={workspaces}
      currentWorkspace={currentWorkspace}
    />
  )
}
