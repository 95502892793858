import { Link, LinkProps, useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import type { FC } from 'react'
import { forwardRef } from 'react'
import { getMapLink } from 'utils'
import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'

const LinkItem = forwardRef<unknown, LinkProps>((props, _) => {
  const { ...rest } = props

  return (
    <Box>
      <Link
        variant='inherit'
        underline='none'
        color='inherit'
        {...rest}
      />
    </Box>
  )
})

LinkItem.displayName = 'LinkItem'

type SearchResultsProps = {
  results?: any
  onClose?: () => void
}

export const SearchResults: FC<SearchResultsProps> = (props) => {
  const { results = [], onClose = () => {} } = props
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box sx={{ pb: '10px' }}>
      <Scrollbar sx={{ maxHeight: isBrowser ? 390 : 250 }}>
        <Container
          maxWidth='sm'
          sx={{ padding: '0 !important' }}
        >
          {results.length > 0 && (
            <Card sx={{ borderRadius: 0 }}>
              <CardHeader
                sx={{ pl: 2, pt: 0, pb: 1 }}
                title='Maps'
              />
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Table>
                  <TableBody>
                    {results.map((result: any, index: number) => {
                      return (
                        <TableRow
                          hover
                          key={`Feature-${index}`}
                          sx={{
                            cursor: 'pointer',
                            '&:last-child td, &:last-child th': { border: 0 }
                          }}
                        >
                          <TableCell sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
                            <ListItem
                              key={result?.id}
                              onClick={onClose}
                              divider={false}
                              disablePadding
                              component={LinkItem}
                              href={getMapLink({
                                mapId: result.id,
                                mapName: result.name
                              })}
                            >
                              <ListItemText
                                sx={{ m: 0 }}
                                primary={result.name}
                              />
                            </ListItem>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Card>
          )}
        </Container>
      </Scrollbar>
    </Box>
  )
}
