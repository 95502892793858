import { createPluginFactory } from '@udecode/plate'
import { saveImages } from './saveImages'

const KEY_ASSETS = 'local'

const withLocalUpload = (editor, plugin) => {
  const local = {
    saveImages: (files) => saveImages(editor, files)
  }
  editor.local = local
  return editor
}

export default createPluginFactory({
  key: KEY_ASSETS,
  withOverrides: withLocalUpload
})
