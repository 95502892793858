import { useClipboard } from 'utils'
import { useFilters } from '~map-filter-view/states/filter'
import { useCurrentBounds, useMap } from '~map-viewer/states/map'

import { useSelectedFeature } from 'map-feature-info'
import { useVisualisationValue } from '~map-viewer/states/visualisation'

const useShare = () => {
  const map = useMap()

  const visualisation = useVisualisationValue()
  const filters = useFilters()
  const currentBounds = useCurrentBounds()
  const selectedFeature = useSelectedFeature()
  const [, onCopyToClipboard] = useClipboard()

  const getShareLink = () => {
    const {
      location: { origin }
    } = window
    const payload = {
      fitBounds: currentBounds,
      filters,
      visualisation,
      selectedFeature
    }
    const hashed = Buffer.from(JSON.stringify(payload)).toString('base64')
    const link = `${origin}/s/${map.id}/${hashed}`
    return link
  }

  const showNativeShare = async (link) => {
    try {
      await navigator.share({
        url: link
      })
    } catch (error) {
      console.error('Look like there is an error here: ', error)
    }
  }

  const showNoneNativeShare = (link) => {
    onCopyToClipboard(link)
  }

  const share = () => {
    const link = getShareLink()
    const isNativeShareSupported = navigator.share
    if (!isNativeShareSupported) {
      showNoneNativeShare(link)
    } else {
      showNativeShare(link)
    }
  }

  return { share }
}

export { useShare }
export default useShare
