import { IconButton, Stack, SvgIcon, Tooltip, Typography } from '@mui/material'
import { isValidElement } from 'react'

const IconButtonTooltip = (props) => {
  const {
    primary,
    title,
    icon: Icon,
    iconProps,
    typographyProps,
    ...rest
  } = props

  return (
    <Tooltip title={title}>
      <Stack alignItems='center'>
        <IconButton
          {...rest}
          color='white'
        >
          {isValidElement(Icon) ? (
            Icon
          ) : (
            <SvgIcon {...iconProps}>
              <Icon />
            </SvgIcon>
          )}
        </IconButton>

        {primary && (
          <Typography
            variant='overline'
            textAlign='center'
            color='text.secondary'
            textTransform='capitalize'
            letterSpacing='0px'
            {...typographyProps}
          >
            {primary}
          </Typography>
        )}
      </Stack>
    </Tooltip>
  )
}

export { IconButtonTooltip }
export default IconButtonTooltip
