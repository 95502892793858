import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { Provider } from 'react-redux'
import { createStore } from './store'

const StateProvider = (props) => {
  const { initialState, children } = props
  const store = useMemo(() => createStore(initialState), [])
  return <Provider store={store}>{children}</Provider>
}

StateProvider.propTypes = {
  initialState: PropTypes.object,
  children: PropTypes.node
}

export { StateProvider }
export default StateProvider
