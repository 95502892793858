/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IncomingMessage } from 'http'
import { isEmpty } from 'lodash'
import { NextRequest } from 'next/server'
import type { NextApiRequest } from 'next/types'
import { isValidUrl } from '../isValidUrl/index.js'

const resolveRequestUrl = (options?: {
  req?: NextRequest | NextApiRequest | IncomingMessage
}): URL | null => {
  const { req } = options || {}
  const headers = req?.headers as any
  const protocol = headers['x-forwarded-proto']
  const host = headers['x-forwarded-host']
  const pathname = headers['x-invoke-path']
  const unsafeQuery = headers['x-invoke-query'] || '{}' // default to empty object
  const jsonQuery = JSON.parse(decodeURIComponent(unsafeQuery))
  const paramsString = new URLSearchParams(jsonQuery).toString()

  const queryString = isEmpty(paramsString) ? '' : `?${paramsString}`

  const url = `${protocol}://${host}${pathname}${queryString}`

  return isValidUrl(url) ? new URL(url) : null
}

export { resolveRequestUrl }
