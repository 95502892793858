import { Box, Button, TextField } from '@mui/material'
import { Fragment } from 'react'
import { FormHeader } from '../FormHeader'

interface EmailFormProps {
  slots?: {
    Form?: React.ElementType
    EmailInput?: React.ElementType
    Button?: React.ElementType
  }
  slotProps?: {
    Form?: any
    EmailInput?: any
    Button?: any
  }
}

function EmailForm(props: EmailFormProps) {
  const { slots, slotProps } = props

  const Slots = {
    Form: Fragment,
    EmailInput: TextField,
    Button: Button,
    ...slots
  }

  return (
    <>
      <FormHeader
        title='Continue with email'
        subtitle='We’ll check if you have an account, and help create one if you don’t.'
      />

      <Box
        display='flex'
        component={Slots.Form}
        {...slotProps?.Form}
      >
        <Slots.EmailInput
          label='Email'
          {...slotProps?.EmailInput}
        />

        <Slots.Button
          variant='contained'
          type='submit'
          size='large'
          sx={{
            borderRadius: 99
          }}
          {...slotProps?.Button}
        >
          Continue
        </Slots.Button>
      </Box>
    </>
  )
}

export { EmailForm }
