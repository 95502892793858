import { Box } from '@mui/material'
import { PlateElement } from '@udecode/plate-common'
import clsx from 'clsx'

import useIsFirstElement from '~ui-components/components/molecules/TextEditor/hooks/useIsFirstElement'

const ListElement = (props) => {
  const { className, children, variant = 'ul', ...rest } = props

  const isFirstElement = useIsFirstElement(props)

  return (
    <PlateElement
      asChild
      className={clsx(className)}
      {...rest}
    >
      <Box
        component={variant}
        sx={[
          {
            my: 1,
            pl: 0,
            '> li': {
              my: 1
            }
          },
          isFirstElement && {
            mt: 0
          },
          variant === 'ul' && {
            listStyleType: 'disc',

            ul: {
              listStyleType: 'circle',
              mb: 0
            },

            'ul ul': {
              listStyleType: 'square',
              mb: 0
            }
          },
          variant === 'ol' && {
            listStyleType: 'decimal'
          }
        ]}
      >
        {children}
      </Box>
    </PlateElement>
  )
}

ListElement.displayName = 'ListElement'

export { ListElement }
export default ListElement
