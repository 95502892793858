import { Status } from '~ui-components/utilities/getReadmeAssetsUrl'

const retryUploadImage = async (editor, id) => {
  const { createFileUpload } = editor.cloud

  const file = editor.cloud.uploadStore.get.upload(id).file

  editor.cloud.uploadStore.set.upload(id, {
    progress: 0,
    status: Status.Uploading
  })

  try {
    const fileUpload = createFileUpload(file)

    editor.cloud.uploadEvents.set(id, {
      abort: fileUpload.abort
    })

    const asset = await fileUpload.upload({
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        editor.cloud.uploadStore.set.upload(id, { progress })
      }
    })

    editor.cloud.uploadStore.set.upload(id, {
      path: asset.path,
      status: Status.Uploaded
    })
  } catch (error) {
    console.warn(error)

    editor.cloud.uploadStore.set.upload(id, {
      status: Status.Failed
    })
  }
}

export { retryUploadImage }
