import { get } from 'idb-keyval'
import { submitCreateMap, useWebWorker } from 'map-creator'
import { ShareButton as Button, PreventNavigateAway } from 'ui-components'
import { getMapLink } from 'utils'

import { MapStatus } from '~map-viewer/constants/MapStatus'
import { setSubmitMap } from '~map-viewer/functions/setSubmitMap'
import { useShare } from '~map-viewer/hooks/useShare'
import { useMap, useMapStatus } from '~map-viewer/states/map'

const ShareButton = (props) => {
  const map = useMap()
  const mapStatus = useMapStatus()
  const worker = useWebWorker()

  const { share } = useShare()

  // Prevent confirm navigate away dialog if the path is the same as the map path
  const handleRouteChangeStart = async (nextPath, abort) => {
    const mapLink = getMapLink({ mapId: map.id, mapName: map.name })

    const isStillMapUrl = nextPath.startsWith(mapLink)

    // Abort route change if it is the same the map path
    abort(isStillMapUrl)
  }

  const handleShare = () => {
    share()
  }

  // Retry only work with map-creator-csv/geojson
  const handleRetry = async () => {
    const mapInput = createMapInput(map)
    const { id, dataId } = mapInput

    worker.background(async () => {
      try {
        await setSubmitMap(id, MapStatus.PENDING)
        const geojson = await get(dataId)
        const json = await get(`${dataId}:json`)

        const dataIdentifiers = await get(`${dataId}:data-identifiers`)

        await submitCreateMap(
          { dataId, geojson, json, dataIdentifiers },
          mapInput
        )

        await setSubmitMap(id, MapStatus.READY)
      } catch (error) {
        await setSubmitMap(id, MapStatus.FAILED)
        throw error
      }
    })
  }

  return (
    <>
      <PreventNavigateAway
        enable={mapStatus === MapStatus.PENDING}
        message='The map is still being uploaded, your work will be lost if you navigate away.'
        onRouteChangeStart={handleRouteChangeStart}
      />

      {mapStatus !== MapStatus.IDLE ? (
        <Button
          {...props}
          status={mapStatus}
          onShare={handleShare}
          onRetry={handleRetry}
        />
      ) : null}
    </>
  )
}

const createMapInput = (map) => {
  return {
    id: map.id,
    dataId: map.dataId,
    workspaceId: map.workspace.id,
    tagIds: map.tagIds,

    name: map.name,
    ...(map.description ? { description: map.description } : {}), // Add description if it exists
    bbox: map.bbox,
    centroid: map.centroid,
    properties: map.properties,

    license: map.license,
    source: map.source,

    views: map.views || 1,
    likes: map.likes || 0,
    remixes: map.remixes || 0,
    type: map.type
  }
}

export { ShareButton }
export default ShareButton
