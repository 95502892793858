import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import ToggleChipInput from '~ui-components/components/molecules/ToggleChipInput'

const OTHER_CHIP = { label: 'Other...', value: 'Other' }

const DefineMapArea = (props) => {
  const {
    name,
    isLoading,
    suggestedNames = [],
    onNameChange,
    onInputChange,
    inputOptions,
    inputLoading
  } = props

  const [customNameMode, setCustomNameMode] = useState(false)
  const [selectedName, setSelectedName] = useState(
    name || (suggestedNames.length && suggestedNames[0].value)
  )
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (selectedName) onNameChange(selectedName)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (name) {
      const isNameInSuggestions = suggestedNames.some((o) => o.value === name)
      if (!isNameInSuggestions) {
        setCustomNameMode(true)
        setInputValue(name)
      }
    } else if (suggestedNames.length) {
      setSelectedName(suggestedNames[0].value)
      onNameChange(suggestedNames[0].value)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, suggestedNames])

  const handleChipChange = (value) => {
    if (value === OTHER_CHIP.value) return setCustomNameMode(true)

    setSelectedName(value)
    onNameChange(value)
  }

  const handleAutocompleteChange = (_e, value) => {
    if (!value) onNameChange('')
    else onNameChange(value.value)
  }

  const handleInputChange = (e) => {
    if (e === null) return

    const value = e.target.value || e.target.textContent
    setInputValue(value)
    onInputChange(value)
  }

  if (isLoading) return <CircularProgress />

  return (
    <Box data-testid='CreateMap-DefineMapArea'>
      {customNameMode ? (
        <Autocomplete
          sx={{
            '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
              pr: '15px !important'
            }
          }}
          disableClearable
          freeSolo
          data-testid='CreateMap-DefineMapArea-TextArea-CustomInput'
          filterOptions={(options) => options}
          getOptionLabel={(option) => option.label}
          onChange={handleAutocompleteChange}
          options={inputOptions}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              label='Coverage Area'
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault()
                  e.stopPropagation()
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: inputLoading ? (
                  <CircularProgress size={20} />
                ) : null
              }}
            />
          )}
        />
      ) : (
        <ToggleChipInput
          data={suggestedNames.concat(OTHER_CHIP)}
          value={selectedName}
          onChange={handleChipChange}
        />
      )}
    </Box>
  )
}

const DefineMapAreaPropTypes = {
  name: PropTypes.string,
  isLoading: PropTypes.bool,
  suggestedNames: PropTypes.array,
  onNameChange: PropTypes.func,
  onInputChange: PropTypes.func,
  inputOptions: PropTypes.arrayOf(PropTypes.string),
  inputLoading: PropTypes.bool
}

DefineMapArea.propTypes = DefineMapAreaPropTypes

export { DefineMapArea, DefineMapAreaPropTypes }
export default DefineMapArea
