import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import type { ChangeEvent, FC } from 'react'
import { useEffect, useState } from 'react'
import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'

interface FilterStringInputProps {
  defaultProperty: {
    options: string[]
  }
  selectedValue: string[]
  onApplyFilter: (arg: string[]) => void
}

const FilterStringInput: FC<FilterStringInputProps> = (props) => {
  const { defaultProperty, selectedValue = [], onApplyFilter } = props
  const { options = [] } = defaultProperty

  const [checkedValues, setCheckedValues] = useState<string[]>(selectedValue)

  useEffect(() => {
    onApplyFilter(checkedValues)
  }, [checkedValues])

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    option: string
  ) => {
    if (event.target.checked) {
      setCheckedValues([...checkedValues, option])
    } else {
      setCheckedValues(checkedValues.filter((value) => value !== option))
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.50',
        borderColor: 'divider',
        borderRadius: 1,
        borderStyle: 'solid',
        borderWidth: 1
      }}
    >
      <Scrollbar sx={{ maxHeight: 200 }}>
        <FormGroup
          sx={{
            py: 1,
            px: 1.5
          }}
        >
          {options.map((option) => {
            const isChecked = checkedValues.includes(option)

            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => handleCheckboxChange(e, option)}
                  />
                }
                key={option}
                label={option}
                value={option}
              />
            )
          })}
        </FormGroup>
      </Scrollbar>
    </Box>
  )
}

export { FilterStringInput }
export default FilterStringInput
