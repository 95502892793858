import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useLazyGetMapByIdQuery } from '~map-creator/core/services/map'

const useExistingMapData = () => {
  const {
    query: { mapId }
  } = useRouter()

  const [existingMap, setExistingMap] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [getMapById] = useLazyGetMapByIdQuery()

  useEffect(() => {
    async function start() {
      try {
        setLoading(true)
        const { data: map } = await getMapById(mapId)
        setExistingMap(map)
      } catch (e) {
        setIsError(true)
        setExistingMap(null)
      } finally {
        setLoading(false)
      }
    }

    if (mapId) start()
  }, [mapId, getMapById])

  return { isError, loading, existingMap }
}

export { useExistingMapData }
