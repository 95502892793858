import createMapTrans from './Website_graphics_1-8.png'
import businessLarge from './covers/business-2-4x4-large.png'
import createWorkspace from './create-workspace.png'
import mailSampleImage from './email-sample-image.jpg'
import error401 from './error-401.png'
import error404 from './error-404.png'
import integrations from './homepage/integrations/map-integrations.jpg'
import addPlanner from './homepage/map-templates/Website-Ad-Planner.jpg'
import analysis from './homepage/map-templates/Website-Analysis.jpg'
import election from './homepage/map-templates/Website-Election.jpg'
import mapJourney from './homepage/map-templates/Website-Map-Journey.jpg'
import territory from './homepage/map-templates/Website-Territory.jpg'
import boundaryLayer from './layer-types/boundary.png'
import heatMapLayer from './layer-types/heatmap.png'
import h3Layer from './layer-types/hexagon.png'
import markerLayer from './layer-types/markers.png'
import lostGif from './lost.gif'
import placeHolder from './place-holder.png'

export {
  addPlanner,
  analysis,
  boundaryLayer,
  businessLarge,
  createMapTrans,
  createWorkspace,
  election,
  error401,
  error404,
  h3Layer,
  heatMapLayer,
  integrations,
  lostGif,
  mailSampleImage,
  mapJourney,
  markerLayer,
  placeHolder,
  territory
}
