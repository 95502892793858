import { Stack } from '@mui/material'
import { InfoCircle } from '@untitled-ui/icons-react'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'

import getProperties from './getProperties'

import { PropertyList } from '~ui-components/components/molecules/PropertyList'
import { PropertyListItem } from '~ui-components/components/molecules/PropertyListItem'
import { Sidebar } from '~ui-components/components/molecules/Sidebar'

interface MapFeatureInfoProps {
  isEmbed?: boolean
  open: boolean
  title: string
  feature: any
  onClose?: () => void
  onCloseEnd?: () => void
}

function MapFeatureInfo(props: MapFeatureInfoProps) {
  const {
    open,
    title = '',
    feature = {},
    onClose,
    onCloseEnd,
    isEmbed = false
  } = props

  const properties = useProperties(feature?.properties, title)
  const featureTitle = useFeatureTitle(feature?.properties, title)

  const handleClose = () => {
    onClose?.()
  }

  return (
    <Sidebar
      isEmbed={isEmbed}
      closeAfterTransition
      icon={InfoCircle}
      anchor='left'
      title='Location details'
      open={open}
      onClose={handleClose}
      onTransitionExited={onCloseEnd}
      ModalProps={{
        disablePortal: true,
        hideBackdrop: true,
        sx: {
          top: 'unset',
          right: 'unset',
          bottom: 'unset',
          left: 'unset'
        }
      }}
    >
      <Stack p={2}>
        <PropertyList
          sx={{
            border: 1,
            borderColor: 'divider',
            borderRadius: 1
          }}
        >
          {featureTitle && (
            <PropertyListItem
              key='property:title'
              divider={isEmpty(properties)}
              label={title}
              value={featureTitle}
            />
          )}
          {properties.map((property, index) => (
            <PropertyListItem
              key={`property:${index}`}
              divider={index < properties.length - 1}
              label={property.name}
              value={property.value}
            />
          ))}
        </PropertyList>
      </Stack>
    </Sidebar>
  )
}

const useProperties = (properties, title) => {
  return useMemo(() => getProperties(properties, title), [properties, title])
}

const useFeatureTitle = (properties, title) => {
  return useMemo(
    () => properties?.find((property) => property.name === title)?.value,
    [properties, title]
  )
}

export { MapFeatureInfo }
export default MapFeatureInfo
