import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

const SelectInput = (props) => {
  const { label, options, value, testId, onChange, disabled, ...rest } = props

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <FormControl {...rest}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        notched
        disabled={disabled}
        value={value}
        label={label}
        inputProps={{
          'data-testid': testId,
          sx: {
            typography: 'body2'
          }
        }}
        onChange={handleChange}
      >
        {options.map((option) => {
          return (
            <MenuItem
              key={option.id}
              value={option.value}
              {...option.props}
            >
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

SelectInput.defaultProps = {
  options: [],
  onChange: () => {},
  label: 'Label',
  testId: 'SelectInput',
  disabled: false
}

const SelectOptionType = {
  id: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.any
}

const SelectInputPropTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape(SelectOptionType)),
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  sx: PropTypes.object
}

SelectInput.propTypes = SelectInputPropTypes

export { SelectInput, SelectInputPropTypes, SelectOptionType }
export default SelectInput
