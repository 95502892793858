import { generateJenks } from "../../get-class-breaks/jenks"
import { DEFAULT_JENKS_CLASS_BREAKS } from '../../constants/index';


function parseGeojson(geojsonFeatures, jenks, aggregateOnBoundary, aggregateProperty) {

  // edge case of single category
  if (jenks.length < 2) {
    const singleCategory = geojsonFeatures.map(feature => feature.properties[aggregateOnBoundary]);
    return [singleCategory];
  }

  const isOverCategoryLimit = jenks.length >= DEFAULT_JENKS_CLASS_BREAKS
  const categoryNumber = isOverCategoryLimit ? jenks.length - 1 : jenks.length
  const categorized: string[][] = new Array(categoryNumber).fill(null).map(() => []);

  // edge case of under cat limit
  if (!isOverCategoryLimit) {
    geojsonFeatures.forEach(feature => {
      const properties = feature.properties;
      const dataValue = properties[aggregateProperty];
      const boundaryId = properties[aggregateOnBoundary];

      for (let i = 0; i < jenks.length; i++) {
        if (dataValue === jenks[i]) {
          categorized[i]?.push(boundaryId)
        }
      }
    })
    return categorized
  }

  geojsonFeatures.forEach(feature => {
    const properties = feature.properties;
    const dataValue = properties[aggregateProperty];
    const boundaryId = properties[aggregateOnBoundary];

    // Check if the dataValue is valid and find its category
    if (dataValue !== undefined && dataValue !== null) {
      let categoryFound = false;
      for (let i = 0; i < jenks.length - 1; i++) {
        if (dataValue == jenks[i + 1] || (dataValue >= jenks[i] && dataValue < jenks[i + 1]) && categorized[i]) {
          categorized[i]?.push(boundaryId);
          categoryFound = true;
          break;
        }
      }
      // Handle the case where dataValue is exactly the last jenk's value
      if (!categoryFound && dataValue === jenks[jenks.length - 1]) {
        categorized[jenks.length - 2]?.push(boundaryId);
      }
    }
  });

  return categorized;
}


// example map flag should ALWAYS be false when not in ../../example/index.js
function generateH3Classes(geojsonFeatures, aggregateOnBoundary, aggregateProperty) {
  const propertyArray = geojsonFeatures.map(feature => {
    const possValue = feature.properties![aggregateProperty] ? parseFloat(`${feature.properties![aggregateProperty]}`.replace(/^0+/, '')) : 0
    return possValue
  })
  const jenksBreaks = generateJenks(propertyArray)
  const boundaryClassBreaks = parseGeojson(geojsonFeatures, jenksBreaks, aggregateOnBoundary, aggregateProperty)

  return {
    boundaryClassBreaks: boundaryClassBreaks,
    jenksBreaks: jenksBreaks
  }
}

export { generateH3Classes }
