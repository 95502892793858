import { random } from 'lodash'

const randomAvatar = (id) => {
  return [
    'https://source.boringavatar.com',
    '/beam',
    '/120',
    `/${id ?? random(0, 100, false)}`,
    '?square=true&colors=264653,2a9d8f,e9c46a,f4a261,e76f51'
  ].join('')
}

export { randomAvatar }
export default randomAvatar
