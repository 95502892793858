import { get } from 'idb-keyval'
import { useEffect, useState } from 'react'

import { useGeojsonFileId } from '~map-creator/core/states/geojson'

const useGeojsonData = (isDeactivated = false) => {
  const [data, setData] = useState(null)
  const geojsonFileId = useGeojsonFileId()

  useEffect(() => {
    async function fetchData() {
      if (!geojsonFileId) return

      try {
        const geojsonObject = await get(`${geojsonFileId}:geojson`)
        setData(geojsonObject)
      } catch (error) {
        setData(null)
      }
    }

    if (isDeactivated) setData(null)
    else fetchData()
  }, [geojsonFileId, isDeactivated])

  return data
}

export { useGeojsonData }
