import { Link, Typography } from '@mui/material'
import NextLink from 'next/link'
import { getMapLink } from 'utils'

interface MapMetadataOriginProps {
  hasParentMap: boolean
  parentMap: {
    id: string
    name: string
    workspace: {
      name: string
    }
    creator: {
      firstName: string
      lastName: string
    }
  }
}

function MapMetadataOrigin(props: MapMetadataOriginProps) {
  const { hasParentMap, parentMap } = props

  if (!hasParentMap) return null

  const { id, name, creator, workspace } = parentMap
  const fullName = [creator?.firstName, creator?.lastName].join(' ')
  const workspaceName = workspace?.name

  return (
    <Typography
      sx={{
        fontWeight: 'medium',
        fontSize: 14,
        userSelect: 'none',
        color: 'text.secondary'
      }}
      component='div'
    >
      Remixed from{' '}
      <Link
        component={NextLink}
        color='secondary'
        href={getMapLink({ mapId: id, mapName: name })}
        shallow={false}
        passHref
      >
        {fullName}/{workspaceName}
      </Link>
    </Typography>
  )
}

export { MapMetadataOrigin }
