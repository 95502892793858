import { formatFeaturePropertyValue } from 'utils'
import { useDefaultPropertiesKeys } from '~map-creator/core/states/geojson'
import {
  useConfigurations,
  useIncludedKeys,
  useTitlePropertyKey
} from '~map-creator/core/states/properties'

const usePopupProperties = ({ property, onlyIncluded }) => {
  const configurations = useConfigurations()
  const includedKeys = useIncludedKeys()
  const titlePropertyKey = useTitlePropertyKey()
  const defaultPropertiesKeys = useDefaultPropertiesKeys()

  if (!property) return null

  const { feature } = property

  const popupProperties = Object.fromEntries(
    defaultPropertiesKeys
      .filter((k) =>
        !onlyIncluded
          ? k !== titlePropertyKey
          : includedKeys.includes(k) && k !== titlePropertyKey
      )
      .map((k) => [
        configurations[k].name,
        {
          isIncluded: includedKeys.includes(k),
          value: formatFeaturePropertyValue({
            value: feature.properties[k],
            type: configurations[k].type
          })
        }
      ])
  )

  return popupProperties
}

export { usePopupProperties }
