export type AddressGeocoderVariant = {
  type: 'redirect'
  payload: {
    id: string
  }
}

export type ReadyVariant = {
  type: 'ready'
}

export function isAddressGeocoderVariant(
  data: any
): data is AddressGeocoderVariant {
  return data['type'] === 'redirect' && 'id' in data.payload
}

export function isReadyVariant(data: any): data is ReadyVariant {
  return data['type'] === 'ready'
}
