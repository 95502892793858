import { Box, Container, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

import { PostContent } from '~ui-components/components/molecules/PostContent'
import { content } from './content'

const post = {
  title: 'Pricing',
  content
}

const Pricing = (props) => {
  const { header: Header = () => null } = props

  return (
    <Fragment>
      <Header />

      <Box
        component='main'
        sx={{
          mt: 8,
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth='xl'>
          <Stack spacing={3}>
            <Typography variant='h3'>{post.title}</Typography>
          </Stack>

          <PostContent
            sx={{ py: 4 }}
            content={post.content}
          />
        </Container>
      </Box>
    </Fragment>
  )
}

Pricing.propTypes = {
  header: PropTypes.any
}

export { Pricing }
export default Pricing
