/* eslint-disable react-hooks/rules-of-hooks */
import { useCurrentWizardStep } from '~map-creator/core/states/map'
import { useStepIndexes } from '~map-creator/core/states/ui'

export const getMapAreaLineStyle = () => {
  const currentWizardStep = useCurrentWizardStep()
  const stepIndexes = useStepIndexes()
  const { DEFINE_MAP_AREA } = stepIndexes

  const showDefineMapArea = currentWizardStep === DEFINE_MAP_AREA

  return {
    visibility: showDefineMapArea ? 'visible' : 'none',
    style: {
      type: 'line',
      paint: {
        'line-color': '#21FA90',
        'line-width': 3
      }
    }
  }
}
