import { PlateElement } from '@udecode/plate-common'
import clsx from 'clsx'
import React from 'react'
import Blockquote from '~ui-components/components/molecules/TextEditor/components/HTML/Blockquote'

const BlockquoteElement = React.forwardRef((props, ref) => {
  const { className, children, ...rest } = props
  return (
    <PlateElement
      asChild
      ref={ref}
      className={clsx(className)}
      {...rest}
    >
      <Blockquote>{children}</Blockquote>
    </PlateElement>
  )
})

BlockquoteElement.displayName = 'BlockquoteElement'

export { BlockquoteElement }
export default BlockquoteElement
