import { LoadingButton } from '@mui/lab'
import { FC, useState } from 'react'

type CopyButtonProps = {
  onClick: () => void
}

export const ClipboardCopyButton: FC<CopyButtonProps> = (props) => {
  const [copied, setCopied] = useState(false)

  const { onClick } = props

  const handleOnClick = () => {
    setCopied(true)
    onClick()
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <LoadingButton
      size='large'
      variant='contained'
      color={copied ? 'inherit' : 'primary'}
      onClick={handleOnClick}
    >
      {copied ? 'Copied' : 'Copy'}
    </LoadingButton>
  )
}
