import { Feature, GeometryObject } from 'geojson'
import { MapDataGrid as View } from 'ui-components'
import { useTableResults } from '~map-table/hooks/useTableResults'

type MapPropertyType =
  | 'date'
  | 'integer'
  | 'float'
  | 'number'
  | 'text'
  | 'object'
  | 'boolean'

type MapPropertyVisualisationType = 'none' | 'quantity' | 'category'

interface MapProperty {
  name: string
  type: MapPropertyType
  visualisation: MapPropertyVisualisationType
  isAggregate: boolean
  isPercentage: boolean
  isDefaultVisualisation: boolean
  isTitle: boolean
  classBreaks: string[]
}

interface filter {
  id: string
  name: string
  type: 'text' | 'number' | 'date'
  value: any
}

type Props = {
  shouldUseCollapse: boolean
  filters: filter[]
  filterResults: Feature<GeometryObject>[]
  properties: MapProperty[]
  jsonFeatures: Feature<GeometryObject>[]
  onClose: () => void
  onFilterChipClick: () => void
}

const App = (props: Props) => {
  const {
    filters,
    shouldUseCollapse,
    filterResults,
    properties,
    onClose = () => {},
    jsonFeatures,
    onFilterChipClick
  } = props

  const { rows, columns } = useTableResults({
    filterResults,
    properties,
    jsonFeatures
  })

  return (
    <View
      {...props}
      rows={rows}
      columns={columns}
      onClose={onClose}
      shouldShowMapFilterChips={filters.length > 0}
      MapFilterChipsProps={{
        shouldUseCollapse,
        filters,
        onClick: onFilterChipClick
      }}
    />
  )
}

export { App }
