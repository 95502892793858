import React from 'react'
import { Box } from '@mui/material'

const Strong = (props) => {
  return (
    <Box
      {...props}
      as='strong'
      fontWeight='bold'
    />
  )
}

export { Strong }
export default Strong
