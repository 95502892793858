export const LAYER_FILL_COLORS = ['#DE3C4B']
export const LAYER_FILL_HIGHLIGHT_COLORS = ['#21FA90']
export const LAYER_FILL_SELECTION_COLORS = ['#FFFF00']
export const LAYER_FILL_OPACITY = [0.7]
export const LAYER_OUTLINE_COLORS = ['#666']
export const LAYER_OUTLINE_SELECTION_COLORS = ['#FFFF00']
export const QUANTITY_SELECTION_MAP_COLORS = [
  '#feebe2',
  '#fcc5c0',
  '#fa9fb5',
  '#f768a1',
  '#df65b0',
  '#dd3497',
  '#ae017e',
  '#7a0177'
]
export const CATEGORY_SELECTION_MAP_COLORS = [
  '#a6cee3',
  '#1f78b4',
  '#b2df8a',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6',
  '#6a3d9a'
]
