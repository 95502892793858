import PropTypes from 'prop-types'
import { Popup as MapGlPopup } from 'react-map-gl/maplibre'
import {
  Typography,
  Box,
  lighten,
  styled,
  useMediaQuery,
  useTheme,
  Stack
} from '@mui/material'

const StyledPopup = styled(MapGlPopup)(({ theme: t }) => ({
  '& .maplibregl-popup-content': {
    backgroundColor: lighten(t.palette.background.paper, 0.1),
    borderRadius: '8px',
    minWidth: '200px',
    maxWidth: '100%'
  },
  '& .maplibregl-popup-tip': {
    borderTopColor: `${lighten(t.palette.background.paper, 0.1)} !important`,
    borderBottomColor: `${lighten(t.palette.background.paper, 0.1)} !important`
  },
  '&.maplibregl-popup-anchor-left .maplibregl-popup-tip': {
    borderRightColor: `${lighten(t.palette.background.paper, 0.1)} !important`,
    borderTopColor: 'transparent !important',
    borderBottomColor: 'transparent !important'
  },
  '&.maplibregl-popup-anchor-right .maplibregl-popup-tip': {
    borderLeftColor: `${lighten(t.palette.background.paper, 0.1)} !important`,
    borderTopColor: 'transparent !important',
    borderBottomColor: 'transparent !important'
  },

  '& .maplibregl-popup-close-button': {
    display: 'none'
  }
}))

const Popup = ({
  lngLat,
  titlePropertyValue,
  isTitleActive,
  propertyKeyValuePairs
}) => {
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <StyledPopup
      key={lngLat.lng}
      longitude={Number(lngLat.lng)}
      latitude={Number(lngLat.lat)}
      anchor='bottom'
      tipSize={isBrowser ? 16 : 6}
      sx={{
        '& .maplibregl-popup-content': { padding: isBrowser ? '12px' : '6px' }
      }}
    >
      <Box sx={{ '& :last-child': { mb: '0 !important' } }}>
        {!!titlePropertyValue && (
          <Typography
            variant='h6'
            fontSize={isBrowser ? undefined : '70%'}
            fontWeight='600'
            mb={isBrowser ? 2 : 0.5}
            px={0.5}
            {...(isTitleActive ? { p: 0.5, border: `2px solid #21FA90` } : {})}
          >
            {titlePropertyValue}
          </Typography>
        )}

        {Object.keys(propertyKeyValuePairs).map((key) => (
          <Typography
            component={Stack}
            direction='row'
            key={key}
            variant='body2'
            fontSize={isBrowser ? undefined : '70%'}
            mb={isBrowser ? 1 : 0.25}
            gap={1}
            justifyContent='space-between'
            px={0.5}
            sx={{ opacity: propertyKeyValuePairs[key].isIncluded ? 1 : 0.3 }}
          >
            <Box>{key}:</Box>
            <Box sx={{ textAlign: 'right' }}>
              {propertyKeyValuePairs[key].value}
            </Box>
          </Typography>
        ))}
      </Box>
    </StyledPopup>
  )
}

const PopupPropTypes = {
  lngLat: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  titlePropertyValue: PropTypes.any,
  isTitleActive: PropTypes.bool,
  propertyKeyValuePairs: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      isIncluded: PropTypes.bool,
      value: PropTypes.any
    })
  })
}
Popup.propTypes = PopupPropTypes

export { Popup, PopupPropTypes }
export default Popup
