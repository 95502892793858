import { Box, Container, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import Layout from '~ui-components/components/organisms/AppLayout'

const Explore: FC<{
  slots: {
    Header: FC
    Search: FC
    PrimaryTags: FC
    SecondaryTags: FC
    PopularMaps: FC
    Footer: FC
  }
}> = ({
  slots: { Header, Search, PrimaryTags, SecondaryTags, PopularMaps, Footer }
}) => {
  return (
    <Layout
      slots={{
        Header,
        Main: (
          <Main
            slots={{
              Search,
              PrimaryTags,
              SecondaryTags,
              PopularMaps: PopularMaps
            }}
          />
        ),
        Footer
      }}
    />
  )
}

export { Explore }

const Main: FC<{
  slots: {
    Search: FC
    PrimaryTags: FC
    SecondaryTags: FC
    PopularMaps: FC
  }
}> = ({ slots: { Search, PrimaryTags, SecondaryTags, PopularMaps } }) => {
  return (
    <Stack
      mt='calc(55px + env(safe-area-inset-top))'
      gap={6}
      mb={6}
    >
      <Box>
        <Typography
          textAlign='center'
          variant='h4'
          mb={3}
        >
          Welcome to Mapstack community
        </Typography>
        <Typography
          textAlign='center'
          variant='subtitle1'
          mb={3}
        >
          Explore thousands of free, open-source maps to kickstart your journey
          of discovery and mapping inspiration.
        </Typography>
        <Container maxWidth='md'>
          <Search />
        </Container>
      </Box>
      <Box>
        <Typography
          textAlign='center'
          variant='h4'
          mb={3}
        >
          Explore the best of Mapstack
        </Typography>
        <PrimaryTags />
      </Box>

      <Box>
        <Typography
          textAlign='center'
          color='text.secondary'
          variant='h5'
          mb={3}
        >
          Discover Interests
        </Typography>
        <SecondaryTags />
      </Box>

      <Box>
        <Typography
          textAlign='center'
          color='text.secondary'
          variant='h5'
          mb={3}
        >
          Explore popular maps
        </Typography>
        <PopularMaps />
      </Box>
    </Stack>
  )
}
