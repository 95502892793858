import { useState } from 'react'

import { MapSettings } from '~map-creator/core/App/CreateMap/Steps'
import { SelectDefaultView } from '~map-creator/core/App/CreateMap/Steps/SelectDefaultView'
import { IncludePropertiesEditMap } from '~map-creator/map-editor/App/EditMap/Steps'

const useSteps = () => {
  const [isPropertySettingsValidated, setIsPropertySettingsValidated] =
    useState(false)
  const [isIncludedPropertiesValid, setIsIncludedPropertiesValid] =
    useState(false)
  const [isDefaultViewValid, setIsDefaultViewValid] = useState(false)

  const includePropertiesStep = {
    title: "Let's make the data user friendly",
    subtitle: 'Only include information and provide easy to understand names.',
    content: (
      <IncludePropertiesEditMap onValidated={setIsIncludedPropertiesValid} />
    ),
    noBack: true,
    disabledNext: !isIncludedPropertiesValid,
    showStepCounter: true
  }

  const selectDefaultViewStep = {
    title: 'Which map view should be the default?',
    subtitle: 'Examples: Population or Total sales.',
    content: <SelectDefaultView onValidated={setIsDefaultViewValid} />,
    disabledBack: false,
    disabledNext: !isDefaultViewValid,
    showStepCounter: true
  }

  const mapSettingsStep = {
    title: 'Name your map',
    subtitle:
      'A good title is descriptive and easy to understand (e.g US County Socioeconomic Map).',
    content: <MapSettings onValidated={setIsPropertySettingsValidated} />,
    disabledBack: false,
    disabledNext: !isPropertySettingsValidated,
    showStepCounter: true
  }

  return [includePropertiesStep, selectDefaultViewStep, mapSettingsStep]
}

export { useSteps }
