import React from 'react'

type Props = {
  disableLog?: boolean
  fallback?: any
  onError?: (error: Error, errorInfo: React.ErrorInfo) => void
  children: React.ReactNode
}

type State = {
  error: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    // Define a state variable to track whether is an error or not
    this.state = { error: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can use your own error logging service here
    if (!this.props.disableLog) console.log({ error, errorInfo })
  }

  render() {
    if (this.state.error) {
      const Fallback = this.props.fallback
      if (React.isValidElement(Fallback)) {
        return <>{Fallback}</>
      }

      return <Fallback />
    }

    // Return children components in case of no error
    return <>{this.props.children}</>
  }
}

export { ErrorBoundary }
