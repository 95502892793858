import { GEOJSON_ERRORS } from '../../assets/constants'
import { validateBbox } from '../isBbox'
import { isFeature } from '../isFeature'
import { isJsonObject } from '../../helpers/isJsonObject'

/**
 * Determines if an object is a valid Feature Collection
 * @method isValidFeatureCollection
 * @param featureCollection {Object}
 * @return {Boolean}
 */
export const isValidFeatureCollection = (featureCollection) => {
  if (!isJsonObject(featureCollection)) {
    return [
      [
        GEOJSON_ERRORS.NOT_JSON_OBJECT.key,
        GEOJSON_ERRORS.NOT_JSON_OBJECT.description
      ]
    ]
  }

  let errors = []

  if ('bbox' in featureCollection) {
    const e = validateBbox(featureCollection.bbox)
    if (e.length > 0) {
      errors = e
    }
  }

  if ('type' in featureCollection) {
    // type must be "FeatureCollection"
    if (featureCollection.type !== 'FeatureCollection') {
      errors.push([
        GEOJSON_ERRORS.MUST_BE_TYPE_FEATURE_COLLECTION.key,
        GEOJSON_ERRORS.MUST_BE_TYPE_FEATURE_COLLECTION.description
      ])
    }
  } else {
    errors.push([
      GEOJSON_ERRORS.MISSING_MEMBER_TYPE.key,
      GEOJSON_ERRORS.MISSING_MEMBER_TYPE.description
    ])
  }

  if ('features' in featureCollection) {
    if (
      Array.isArray(featureCollection.features) &&
      featureCollection.features.length
    ) {
      featureCollection.features.forEach((val, index) => {
        const e = isFeature(val)
        if (e.length > 0) {
          // modify the err msg from 'isPosition' to note the element number
          e[0][1] = 'at ' + index + ': '.concat(e[0][1])
          // build a list of invalid positions
          errors = errors.concat(e)
        }
      })
    } else {
      // "Features" must be an array and not empty
      errors.push([
        GEOJSON_ERRORS.FEATURE_MUST_BE_ARRAY.key,
        GEOJSON_ERRORS.FEATURE_MUST_BE_ARRAY.description
      ])
    }
  } else {
    // must have a member with the name "Features"
    errors.push([
      GEOJSON_ERRORS.MISSING_MEMBER_FEATURES.key,
      GEOJSON_ERRORS.MISSING_MEMBER_FEATURES.description
    ])
  }

  return errors
}
