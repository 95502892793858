import List, { ListProps } from '@mui/material/List'
import type { FC, ReactNode } from 'react'

interface PropertyListProps extends ListProps {
  children: ReactNode
}

export const PropertyList: FC<PropertyListProps> = (props) => {
  const { children, ...rest } = props

  return (
    <List
      disablePadding
      {...rest}
    >
      {children}
    </List>
  )
}
