import { NavAvatar as View } from 'ui-components'
import { useAuth, useAuthCurrentWorkspace } from 'user-auth'

import { CommunityItem } from '../CommunityItem'
import { LogoutButton } from './LogoutButton'
import { UserProfile } from './UserProfile'
import { Workspace } from './Workspace'
import { Workspaces } from './Workspaces'

export function NavAvatar() {
  const { authenticated } = useAuth()
  const currentWorkspace = useAuthCurrentWorkspace()

  if (!authenticated) return

  return (
    <View
      currentWorkspace={currentWorkspace}
      slots={{
        Workspaces,
        Workspace,
        CommunityItem,
        UserProfile,
        LogoutButton
      }}
    />
  )
}
