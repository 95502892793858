import { ELEMENT_LI, ELEMENT_OL, ELEMENT_UL } from '@udecode/plate'

import { formatList, preFormat } from './autoformatUtils.js'

export const autoformatLists = [
  {
    mode: 'block',
    type: ELEMENT_LI,
    match: ['* ', '- '],
    preFormat,
    format: (editor) => formatList(editor, ELEMENT_UL)
  },
  {
    mode: 'block',
    type: ELEMENT_LI,
    match: ['1. ', '1) '],
    preFormat,
    format: (editor) => formatList(editor, ELEMENT_OL)
  }
]
