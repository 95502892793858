import axios from 'axios'
import validator from 'data-validator'
import { reduce } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Errors } from 'utils'
import { v4 as uuidv4 } from 'uuid'
import { DataUpload as CoreDataUpload } from '~map-creator/core/App/CreateMap/Steps/DataUpload'
import { uploadFileToS3 } from '~map-creator/core/api'
import {
  setMissRows,
  useDefaultPropertyConfigs
} from '~map-creator/core/states/geojson'
import {
  setConfigurations,
  updateTitlePropertyKey
} from '~map-creator/core/states/properties'
import {
  createAugmentedCSV,
  getCSVFileUploadUrl
} from '~map-creator/map-creator-csv/api'

const getGeojsonFromCsvFile = async (file) => {
  const { dataId, uploadUrl, formFieldsJson } = await getCSVFileUploadUrl()
  await uploadFileToS3({
    url: uploadUrl,
    file,
    formData: JSON.parse(formFieldsJson)
  })
  const { fileUrl, missRows, titleColumnName } = await createAugmentedCSV({
    dataId
  })

  const geojson = await fetchGeojson(fileUrl)
  return { geojson, missRows, titleColumnName }
}

const fetchGeojson = async (url) => {
  const response = await axios.get(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
  const geojson = response.data
  return geojson
}

const DataUploadCSV = (props) => {
  const { onValidated, ...rest } = props
  const dispatch = useDispatch()
  const title = useRef('')
  const defaultConfigs = useDefaultPropertyConfigs()

  useEffect(() => {
    dispatch(setConfigurations(defaultConfigs))
  }, [defaultConfigs, dispatch])

  const handleFileValidate = async (file, callback) => {
    const isValid = await validator.csv.valid(file)

    if (!isValid) {
      const { message, name } = Errors.UploadError.CsvInvalid()
      const csvError = [{ message, path: [name] }]
      const errors = mapCsvErrors(csvError)
      callback({ errors })
      return null
    }

    try {
      const { geojson, missRows, titleColumnName } =
        await getGeojsonFromCsvFile(file)

      dispatch(setMissRows(missRows))
      title.current = titleColumnName

      const id = uuidv4()
      const geojsonFile = new File([new Blob([JSON.stringify(geojson)])], id, {
        type: 'application/json'
      })
      return geojsonFile
    } catch ({ errors }) {
      const mappedErrors = mapCsvErrors(errors)
      callback({ errors: mappedErrors })
    }
    return null
  }

  const handleInputValidated = () => {
    dispatch(updateTitlePropertyKey(title.current))
    onValidated()
  }
  return (
    <CoreDataUpload
      onValidate={handleFileValidate}
      onValidated={handleInputValidated}
      {...rest}
    />
  )
}

const mapCsvErrors = (errors) => {
  return reduce(
    errors,
    function (result, value) {
      const {
        message: description,
        path: [key]
      } = value
      result[key] = {
        description,
        key,
        title: 'CSV Invalid',
        type: 'error',
        errors: [description]
      }
      return result
    },
    {}
  )
}

DataUploadCSV.propTypes = {
  limitSizeAsMb: PropTypes.number,
  onValidated: PropTypes.func
}

DataUploadCSV.defaultProps = {
  limitSizeAsMb: 50,
  acceptTypes: '.csv'
}

export default DataUploadCSV
export { DataUploadCSV }
