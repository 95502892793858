import { Button, SvgIcon } from '@mui/material'
import Settings01 from '@untitled-ui/icons-react/build/esm/Settings01'

const SettingButton = (props) => {
  return (
    <Button
      disableElevation
      variant='contained'
      color='inherit'
      startIcon={
        <SvgIcon sx={{ fontSize: '1.25em !important' }}>
          <Settings01 />
        </SvgIcon>
      }
      {...props}
    >
      {props.children || 'Settings'}
    </Button>
  )
}

export { SettingButton }
export default SettingButton
