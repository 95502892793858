/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useRouter } from 'next/router'
import { useOverlayScrollbars } from 'overlayscrollbars-react'

import {
  Box,
  GlobalStyles,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import * as BSL from 'body-scroll-lock'
import React, { useEffect, useRef } from 'react'
import useResizeObserver from 'use-resize-observer'
import { PreventPullToRefresh } from '~ui-components/components/atoms/PreventPullToRefresh'
import { MapLoadErrorDialog } from '~ui-components/components/molecules/MapLoadErrorDialog'
import { MapNotFoundDialog } from '~ui-components/components/molecules/MapNotFoundDialog'
import { MapActions } from '~ui-components/components/organisms/MapActions/Default'
import { MapDataGrid } from '~ui-components/components/organisms/MapDataGrid'
import { MapDescription } from '~ui-components/components/organisms/MapDescription'
import { MapFeatureInfo } from '~ui-components/components/organisms/MapFeatureInfo'
import { MapFilter } from '~ui-components/components/organisms/MapFilter'
import { MapHeader } from '~ui-components/components/organisms/MapHeader'
import { MapNav } from '~ui-components/components/organisms/MapNav'
import { MapSearchModal } from '~ui-components/components/organisms/MapSearchModal'
import { MapVisualisation } from '~ui-components/components/organisms/MapVisualisation'
import {
  MapFilterChips,
  MapFilterChipsProps,
  MapVisualisationChips,
  MapVisualisationChipsProps
} from '~ui-components/components/organisms/MapVisualisationChips'

type MapError = 'MapNotFound' | 'MapUnknownError' | 'MapLoadError'

interface MapGlProps {
  width?: number
  height?: number
}

interface Slots {
  MapGl: React.FC<MapGlProps>
  MapNav: typeof MapNav
  MapTable: typeof MapDataGrid
  MapFilter: typeof MapFilter
  MapActions: typeof MapActions
  MapVisualisation: typeof MapVisualisation
  MapVisualisationChips: typeof MapVisualisationChips
  MapFilterChips: typeof MapFilterChips
  MapFeatureInfo: typeof MapFeatureInfo
  MapDescription: typeof MapDescription
  MapSearchModal: typeof MapSearchModal
  MapHeader: typeof MapHeader
}

interface SlotProps {
  MapGl?: MapGlProps
  MapNav?: React.ComponentProps<typeof MapNav>
  MapTable?: React.ComponentProps<typeof MapDataGrid>
  MapFilter?: React.ComponentProps<typeof MapFilter>
  MapActions?: React.ComponentProps<typeof MapActions>
  MapVisualisation?: React.ComponentProps<typeof MapVisualisation>
  MapVisualisationChips?: MapVisualisationChipsProps
  MapFilterChips?: MapFilterChipsProps
  MapFeatureInfo?: React.ComponentProps<typeof MapFeatureInfo>
  MapDescription?: React.ComponentProps<typeof MapDescription>
  MapSearchModal?: React.ComponentProps<typeof MapSearchModal>
  MapHeader?: React.ComponentProps<typeof MapHeader>
}

interface MapViewerProps {
  ready?: boolean
  error?: MapError
  slots?: Slots
  slotProps?: SlotProps
  visibility?: any
  isFullscreenActive?: boolean
}

const defaultSlots: Slots = {
  MapGl: () => null,
  MapNav,
  MapTable: MapDataGrid,
  MapFilter,
  MapActions,
  MapVisualisation,
  MapVisualisationChips,
  MapFilterChips,
  MapFeatureInfo,
  MapDescription,
  MapSearchModal,
  MapHeader
}

function MapViewer(props: MapViewerProps) {
  const {
    ready = false,
    error = '',
    slots = {},
    slotProps = {},
    visibility = {},
    isFullscreenActive = false
  } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const { mobile } = useResponsive()

  useEffect(() => {
    if ('virtualKeyboard' in navigator) {
      ;(navigator as any).virtualKeyboard.overlaysContent = true
    }
  }, [])

  const { lockScroll, unlockScroll } = useMapViewerScrollLock()

  const visibilityOf = (name) => {
    if (!visibility[name]) return styles.none
    return {}
  }

  const { ref: mapContainerRef, width, height } = useResizeObserver()

  return (
    <>
      {Overrides}

      <PreventPullToRefresh>
        <Box
          id='PageWrapper'
          onTouchStart={(e) => {
            // Stop propagation to PageWrapper event, which would unlock scroll
            e.stopPropagation()

            if (process.env.NODE_ENV !== 'development') lockScroll()
          }}
          onMouseEnter={(e) => {
            // Touchstart could invoke MouseEnter event, and changing further body style during fullscreen lead to flickering behavior
            if (mobile || isFullscreenActive) return

            if (process.env.NODE_ENV !== 'development') lockScroll()
          }}
          onMouseLeave={(e) => {
            if (mobile || isFullscreenActive) return

            if (process.env.NODE_ENV !== 'development') unlockScroll()
          }}
        >
          <Slots.MapNav
            sx={[visibilityOf('MapNav')]}
            {...slotProps?.MapNav}
          />

          <Box
            id='MapViewportContainer'
            sx={{
              bgcolor: `var(--mui-palette-Skeleton-bg) !important`
            }}
            onTouchStart={(e) => {
              // Stop propagation to PageWrapper event, which would unlock scroll
              e.stopPropagation()
              lockScroll()
            }}
            onMouseEnter={(e) => {
              // Touchstart could invoke MouseEnter event, and changing further body style during fullscreen lead to flickering behavior
              if (mobile || isFullscreenActive) return
              lockScroll()
            }}
            onMouseLeave={(e) => {
              if (mobile || isFullscreenActive) return
              unlockScroll()
            }}
          >
            <Box
              component='main'
              sx={[
                styles.main,
                (theme) =>
                  isFullscreenActive
                    ? {
                        width: '100dvw',
                        height: '100dvh',
                        maxHeight: '100dvh',
                        [theme.breakpoints.down('mobile')]: {
                          width: '100dvw',
                          height: '100dvh'
                        }
                      }
                    : {}
              ]}
              ref={mapContainerRef}
            >
              <Slots.MapGl
                {...slotProps?.MapGl}
                width={width}
                height={height}
              />

              <Box
                sx={[
                  styles.visualisationChipsContainer,
                  visibility['MapVisualisationChips'] ? {} : styles.none
                ]}
              >
                <Stack
                  alignItems='center'
                  direction='row'
                  flexWrap='wrap'
                  gap={1}
                >
                  <Slots.MapVisualisationChips
                    {...slotProps.MapVisualisationChips!}
                  />
                  <Slots.MapFilterChips {...slotProps.MapFilterChips!} />
                </Stack>
              </Box>

              {mobile ? (
                <>
                  <Box sx={[styles.visualisation]}>
                    <Box sx={[visibilityOf('MapVisualisation')]}>
                      <Slots.MapVisualisation
                        variant='mobile'
                        {...slotProps?.MapVisualisation}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={[
                      styles.visualisation,
                      visibilityOf('MapVisualisation')
                    ]}
                  >
                    <Slots.MapVisualisation
                      variant='desktop'
                      {...slotProps?.MapVisualisation}
                    />
                  </Box>
                </>
              )}

              <Slots.MapSearchModal
                open={!!visibility.MapSearchModal}
                {...slotProps?.MapSearchModal}
              />

              <Slots.MapSearchModal
                open={!!visibility.MapSearchModal}
                {...slotProps?.MapSearchModal}
              />

              <Box sx={[styles.actions, visibilityOf('MapActions')]}>
                <Slots.MapActions
                  spacing={mobile ? 'sm' : 'md'}
                  {...slotProps?.MapActions}
                />
              </Box>

              {ready ? (
                <>
                  <Box sx={[styles.filter, visibilityOf('MapFilter')]}>
                    <Slots.MapFilter {...(slotProps?.MapFilter as any)} />
                  </Box>

                  {visibility.MapTable && (
                    <Slots.MapTable {...slotProps?.MapTable!} />
                  )}

                  <Slots.MapFeatureInfo
                    {...(slotProps?.MapFeatureInfo as any)}
                  />
                </>
              ) : null}
            </Box>
          </Box>

          <Stack
            direction='column'
            gap={2}
            sx={[
              (theme) => ({
                maxWidth: 'var(--map-content-max-width)',
                mx: 'auto',
                p: 3,
                [theme.breakpoints.down('mobile')]: {
                  p: 1
                }
              })
            ]}
          >
            <Slots.MapHeader {...slotProps?.MapHeader} />
            <Slots.MapDescription {...slotProps?.MapDescription} />
          </Stack>

          <Error error={error} />
        </Box>
      </PreventPullToRefresh>
    </>
  )
}

function Error({ error }) {
  if (error === 'MapNotFound') return <MapNotFoundDialog open />
  if (error === 'MapUnknownError') return <MapLoadErrorDialog open />
  if (error === 'MapLoadError') return <MapLoadErrorDialog open />
  return null
}

function useResponsive() {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('mobile'))
  return { mobile }
}

function useMapViewerScrollLock() {
  const { asPath } = useRouter()
  const { mobile } = useResponsive()
  const [initBodyOverlayScrollbars, getBodyOverlayScrollbarsInstance] =
    useOverlayScrollbars({
      defer: true,
      options: {
        overflow: { x: 'hidden' },
        scrollbars: {
          theme: 'os-theme-dark',
          autoHide: mobile ? 'scroll' : 'never' // on mobile auto hide when not scrolling
        }
      }
    })

  const prevOverflowRef = useRef<string>()

  // URL change (from switching visualisation) cause body to lose styles, so we need to restore overflow style
  useEffect(() => {
    // Must wait a bit for body to be re-rendered before setting overflow
    setTimeout(() => {
      document.body.style['overflow'] = prevOverflowRef.current || ''
    }, 50)
  }, [asPath])

  useEffect(() => {
    initBodyOverlayScrollbars({
      target: document.body,
      cancel: {
        body: false
      }
    })
  }, [])

  const lockScroll = () => {
    BSL.disableBodyScroll(document.querySelector('#MapViewportContainer'))
    prevOverflowRef.current = 'hidden'
    const bodyOsInstance = getBodyOverlayScrollbarsInstance()
    if (bodyOsInstance && !bodyOsInstance.state().destroyed)
      bodyOsInstance.destroy()
  }

  const unlockScroll = () => {
    BSL.enableBodyScroll(document.querySelector('#MapViewportContainer'))
    prevOverflowRef.current = undefined
    initBodyOverlayScrollbars({
      target: document.body,
      cancel: {
        body: false
      }
    })
  }

  return { lockScroll, unlockScroll }
}

export const styles = {
  global: (theme) => ({
    ':root': {
      '--map-search-min-width': '700px',
      '--map-header-height': '80px',
      '--map-content-max-width': '1754px'
    },
    'html, body': {
      position: 'relative',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      scrollbarWidth: 'none'
    },
    '.os-scrollbar-vertical .os-scrollbar-handle, .os-scrollbar-vertical:hover .os-scrollbar-handle':
      {
        width: '10px'
      },
    [theme.breakpoints.down('mobile')]: {
      ':root': {
        '--map-search-min-width': '360px',
        '--map-header-height': '58px'
      },
      '.os-scrollbar-vertical .os-scrollbar-handle, .os-scrollbar-vertical:hover .os-scrollbar-handle':
        {
          width: '6px'
        }
    }
  }),

  main: (theme) => ({
    position: 'relative',
    width: `calc(100dvw - (2 * ${theme.spacing(3)}))`,
    height: '80dvh',
    mx: 'auto',
    [theme.breakpoints.down('mobile')]: {
      width: `calc(100dvw - (2 * ${theme.spacing(1)}))`,
      height: '70dvh'
    }
  }),

  visualisationChipsContainer: (theme) => ({
    zIndex: 'visualisationChip',
    position: 'absolute',
    top: 0,
    width: '100%',
    my: '12px',
    ml: '12px',
    [theme.breakpoints.down('mobile')]: {
      mb: 1,
      width: 'calc(100% - 16px)',
      height: 'auto',
      minWidth: 'unset'
    }
  }),

  visualisation: (theme) => ({
    zIndex: 2,
    position: 'absolute',
    width: '800px',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    mb: 2,
    [theme.breakpoints.down('mobile')]: {
      mb: 1,
      mx: 'auto',
      width: 'calc(100% - 16px)',
      height: 'auto',
      minWidth: 'unset'
    }
  }),

  filter: (theme) => ({
    zIndex: 3333333,
    position: 'absolute',
    width: '800px',
    bottom: 0,
    mb: 2,
    [theme.breakpoints.down('mobile')]: {
      mb: 1,
      mx: 'auto',
      width: 'calc(100% - 16px)',
      minWidth: 'unset'
    }
  }),

  actions: (theme) => ({
    position: 'absolute',
    width: 'fit-content',
    height: 'auto',
    top: '50%',
    right: 0,
    mr: 2,
    zIndex: (theme) => theme.zIndex.modal + 2,
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('mobile')]: {
      transform: 'translateY(-75%)',
      mt: 1,
      mr: 1
    }
  }),

  filterHeader: (theme) => ({
    zIndex: 'mapFilterHeader',
    position: 'absolute',
    top: '51px',
    display: 'flex',

    [theme.breakpoints.down('mobile')]: {
      top: '51px',
      left: '8px',
      transform: 'none',
      width: 'calc(100% - 16px)'
    }
  }),

  whenFilterExpanded: () => ({
    justifyContent: 'center',
    transform: 'translateX(-50%)',
    width: 'calc(98% - 440px)',
    left: 'calc(50% - 210px)',
    top: '16px'
  }),

  whenFilterCollapsed: () => ({
    width: '98%',
    left: '14px',
    top: '51px'
  }),

  none: {
    display: 'none'
  },

  block: {
    display: 'block'
  }
}

const Overrides = <GlobalStyles styles={styles.global} />

export { MapViewer }
export default MapViewer
