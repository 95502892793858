import { Box, Button, SvgIcon } from '@mui/material'
import PropTypes from 'prop-types'
import Facebook from '~ui-components/assets/svg/Facebook'
import Google from '~ui-components/assets/svg/Google'

const AuthMethods = (props) => {
  const { disabledAuthMethods, onGoogleMethodClick, ...rest } = props
  return (
    <Box
      {...rest}
      display='flex'
      flexDirection='column'
      gap={2}
    >
      <Button
        disabled={disabledAuthMethods}
        data-testid='GoogleMethodButton'
        variant='contained'
        fullWidth
        startIcon={
          <SvgIcon>
            <Google />
          </SvgIcon>
        }
        onClick={onGoogleMethodClick}
      >
        Continue with Google
      </Button>
      <Button
        disabled={disabledAuthMethods}
        data-testid='FacebookMethodButton'
        variant='contained'
        fullWidth
        startIcon={
          <SvgIcon>
            <Facebook />
          </SvgIcon>
        }
        onClick={props.onFacebookMethodClick}
      >
        Continue with Facebook
      </Button>
    </Box>
  )
}

const AuthMethodsPropTypes = {
  disabledAuthMethods: PropTypes.bool,
  onGoogleMethodClick: PropTypes.func
}

AuthMethods.propTypes = AuthMethodsPropTypes

export { AuthMethods, AuthMethodsPropTypes }
