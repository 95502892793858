import React, { useState } from 'react'
import { MapRemixDialog } from '~ui-components/components/molecules/MapRemixDialog'
import { MapRemixDrawer } from '~ui-components/components/molecules/MapRemixDrawer'

interface MapRemixProps {
  remixable: boolean
  slots?: {
    MapRemixDrawer?: typeof MapRemixDrawer
    MapRemixDialog?: typeof MapRemixDialog
  }
  slotProps?: {
    MapRemixDrawer?: React.ComponentProps<typeof MapRemixDrawer>
    MapRemixDialog?: React.ComponentProps<typeof MapRemixDialog>
  }
}

const defaultSlots = {
  MapRemixDrawer,
  MapRemixDialog
}

function MapRemix(props: MapRemixProps) {
  const { remixable, slots = {}, slotProps = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      {remixable ? (
        <>
          <Slots.MapRemixDrawer
            {...slotProps.MapRemixDrawer}
            open={open}
            onClose={handleClose}
          />
        </>
      ) : null}

      <Slots.MapRemixDialog
        {...(slotProps.MapRemixDialog as React.ComponentProps<
          typeof MapRemixDialog
        >)}
      />
    </>
  )
}

export { MapRemix }
