import { useRouter } from 'next/router'
import { SettingButton as Button } from 'ui-components'
import { getEditMapLink } from 'utils'
import { MapStatus } from '~map-viewer/constants/MapStatus'
import { useMap, useMapStatus } from '~map-viewer/states/map'

const SettingButton = (props) => {
  const router = useRouter()
  const map = useMap()
  const status = useMapStatus()

  const handleClick = () => {
    const { id, workspace } = map
    const isMapSaved = status === MapStatus.READY

    const isLeavingPageConfirmed = isMapSaved
      ? true
      : window.confirm(
          "Map hasn't been saved yet. Do you really want to leave this page?"
        )

    if (workspace && id && isLeavingPageConfirmed) {
      router.push(
        getEditMapLink({
          workspaceId: workspace.id,
          workspaceName: workspace.name,
          mapId: id
        })
      )
    }
  }

  return (
    <Button
      {...props}
      onClick={handleClick}
    />
  )
}

export { SettingButton }
export default SettingButton
