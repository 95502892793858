import { styled } from '@mui/material'
import {
  Caption as CaptionPrimitive,
  CaptionTextarea as CaptionTextareaPrimitive
} from '@udecode/plate-caption'

const Caption = styled(CaptionPrimitive, {
  shouldForwardProp: (prop) => prop !== 'align'
})(({ theme }) =>
  theme.unstable_sx({
    mt: 1,
    mr: 'auto',
    width: '100% !important',
    typography: 'body1'
  })
)

const CaptionTextarea = styled(CaptionTextareaPrimitive)(({ theme }) =>
  theme.unstable_sx({
    p: 0,
    width: '100%',
    height: 'auto !important',
    resize: 'none',
    border: 'none',
    bgcolor: 'inherit',
    font: 'inherit',
    fontSize: 14,
    lineHeight: 1,
    color: 'text.secondary',
    '&:focus': {
      outline: 'none'
    }
  })
)

export { Caption, CaptionTextarea }
