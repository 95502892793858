import { Box } from '@mui/material'
import { forwardRef } from 'react'

const variants = {
  h1: 'h2',
  h2: 'h3',
  h3: 'h4'
}

const styles = {
  h1: {
    fontSize: '1.875em'
  },
  h2: {
    fontSize: '1.5em'
  },
  h3: {
    fontSize: '1.25em'
  }
}

const Heading = forwardRef((props, ref) => {
  const { sx, variant, disabledGutterTop, children, ...rest } = props

  return (
    <Box
      ref={ref}
      component={variants[variant]}
      sx={[
        {
          my: 2,
          '&:first-of-type': {
            mt: 0
          }
        },
        disabledGutterTop && {
          mt: 0
        },
        {
          '> a': {
            fontSize: 'inherit'
          }
        },
        styles[variant],
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...rest}
    >
      {children}
    </Box>
  )
})

Heading.displayName = 'Heading'

export { Heading }
export default Heading
