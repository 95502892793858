import { lightMap } from 'map-style'
import { Map } from 'react-map-gl/maplibre'

import { MapAttribute } from 'ui-components'

import { Layers } from './layers'
import MapCursor from './MapCursor'

export const View = (props) => {
  const {
    viewState,
    loading,
    onMapInstance,
    onClick,
    checkIfDataExistLocally
  } = props

  return (
    <Map
      {...viewState}
      reuseMaps
      ref={(ref) => ref && onMapInstance(ref.getMap())}
      mapStyle={lightMap}
      interactiveLayerIds={['default']}
      onClick={onClick}
      attributionControl={false}
      dragRotate={false}
      pitchWithRotate={false}
      onZoomEnd={checkIfDataExistLocally}
    >
      {/* Load Source & Layer after fetched Meta Data */}
      {!loading && <Layers />}
      {!loading && <MapAttribute />}
      {!loading && <MapCursor />}
    </Map>
  )
}
