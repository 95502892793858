import { useState } from 'react'
import { DeleteButton as Button } from 'ui-components'
import { useIsMapDeleting } from '~map-viewer/states/map'
import { DeleteMapDialog } from './DeleteMapDialog'

const DeleteButton = (props) => {
  const deleting = useIsMapDeleting()
  const [open, setOpen] = useState(false)

  const handleClick = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <DeleteMapDialog
        open={open}
        onClose={handleClose}
      />

      <Button
        {...props}
        loading={deleting}
        onClick={handleClick}
      />
    </>
  )
}

export { DeleteButton }
export default DeleteButton
