import { useFeatureIdentify } from 'map-feature-info'
import { useDispatch } from 'react-redux'
import { useLayerFeatures } from '~map-viewer/App/hooks/useLayerFeatures'
import { setFitBounds, useMap, useTitleProperty } from '~map-viewer/states/map'
import { closeSearch, openFeatureInfo } from '~map-viewer/states/ui'

import { App as MapSearchView } from 'map-search-view'

const MapSearchModal = (props) => {
  const dispatch = useDispatch()
  const { identify } = useFeatureIdentify()

  const map = useMap()
  const layersFeatures = useLayerFeatures()

  const title = useTitleProperty()

  const handleFeatureItemClick = (bounds, feature) => {
    dispatch(setFitBounds(bounds))
    if (!feature) return
    identify(feature)
    dispatch(openFeatureInfo())
  }

  const handleLocationItemClick = (bounds) => {
    dispatch(
      setFitBounds([
        Number(bounds[2]),
        Number(bounds[0]),
        Number(bounds[3]),
        Number(bounds[1])
      ])
    )
    dispatch(closeSearch())
  }

  const handleClose = () => {
    dispatch(closeSearch())
  }

  return (
    <MapSearchView
      {...props}
      map={map}
      layersFeatures={layersFeatures}
      title={title.name}
      onClose={handleClose}
      onFeatureItemClick={handleFeatureItemClick}
      onLocationItemClick={handleLocationItemClick}
    />
  )
}

export { MapSearchModal }
