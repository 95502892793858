import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { IncludeProperties } from '~map-creator/core/App/CreateMap/Steps'
import {
  updatePropertyVisibility,
  useTitlePropertyKey
} from '~map-creator/core/states/properties'

const IncludePropertiesEditMap = (props) => {
  const dispatch = useDispatch()
  const titlePropertyKey = useTitlePropertyKey()

  useEffect(() => {
    if (!titlePropertyKey) return
    dispatch(updatePropertyVisibility({ key: titlePropertyKey, value: true }))
  }, [titlePropertyKey, dispatch])

  return (
    <IncludeProperties
      {...props}
      titlePropertyKey={titlePropertyKey}
    />
  )
}

export default IncludePropertiesEditMap
export { IncludePropertiesEditMap }
