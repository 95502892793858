import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { DotsHorizontal, User01 } from '@untitled-ui/icons-react'
import dayjs from 'dayjs'
import NextLink from 'next/link'
import { FC, useState } from 'react'
import { getMapLink, getUserLink } from 'utils'
import { Dialog, DialogProps } from '~ui-components/components/molecules'
import { EmptyMemberListFallback } from '~ui-components/components/molecules/EmptyMemberListFallback'
import { useDialog } from '~ui-components/hooks/useDialog'
import { usePopover } from '~ui-components/hooks/usePopover'
import {
  InvitedUser,
  Member
} from '~ui-components/types/__generated/gql/graphql'

export type GuestListProps = {
  onRemoveGuestConfirm: (userId: string) => Promise<void>
  onUpgradeToMemberConfirm: (userId: string) => Promise<void>
  canRemoveGuest: boolean
  canUpgradeToMember: boolean
  isRemovingGuest: boolean
  guests: Member[]
  invitedUsers: InvitedUser[]
}

export const GuestList: FC<GuestListProps> = ({
  onRemoveGuestConfirm,
  onUpgradeToMemberConfirm,
  isRemovingGuest,
  canRemoveGuest,
  canUpgradeToMember,
  guests,
  invitedUsers
}) => {
  const [upgradingMember, setUpgradingMember] = useState<Member | InvitedUser>()
  const removeGuestDialog = useDialog<Member | InvitedUser>()

  if (!guests.length && !invitedUsers.length)
    return (
      <Box mt={3}>
        <EmptyMemberListFallback />
      </Box>
    )

  return (
    <Box mt={3}>
      {removeGuestDialog.open && (
        <RemoveGuestDialog
          loading={isRemovingGuest}
          open={removeGuestDialog.open}
          onClose={removeGuestDialog.handleClose}
          onConfirm={async () => {
            if (removeGuestDialog.data) {
              await onRemoveGuestConfirm(
                isMember(removeGuestDialog.data)
                  ? removeGuestDialog.data.user.id
                  : removeGuestDialog.data.userId
              )
              removeGuestDialog.handleClose()
            }
          }}
        />
      )}

      <Table sx={{ minWidth: 400 }}>
        <TableHead>
          <TableRow>
            <TableCell>Member</TableCell>
            <TableCell>Access</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {[...guests, ...invitedUsers]
            .sort((i) => dayjs(i.createdAt).unix())
            .map((item) => (
              <TableRow key={isMember(item) ? item.user.id : item.id}>
                <TableCell>
                  <Stack
                    alignItems='center'
                    direction='row'
                    spacing={1}
                  >
                    <Avatar
                      src={isMember(item) ? item.user.avatar! : undefined}
                      sx={{ height: 40, width: 40 }}
                    >
                      <SvgIcon>
                        <User01 />
                      </SvgIcon>
                    </Avatar>
                    <div>
                      {isMember(item) ? (
                        <Typography
                          component={NextLink}
                          href={getUserLink({
                            firstName: item.user.firstName!,
                            lastName: item.user.lastName!,
                            userId: item.user.id
                          })}
                          variant='subtitle2'
                          sx={{
                            color: 'text.primary',
                            textDecoration: 'none',
                            ':hover': {
                              textDecoration: 'underline'
                            }
                          }}
                        >
                          {item.user.firstName} {item.user.lastName}
                        </Typography>
                      ) : (
                        <Typography
                          variant='subtitle2'
                          sx={{ color: 'text.primary' }}
                        >
                          {item.email} <b>(PENDING)</b>
                        </Typography>
                      )}
                      <Typography
                        color='text.secondary'
                        variant='body2'
                      >
                        {isMember(item) ? item.user.email : item.email}
                      </Typography>
                    </div>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Select
                    displayEmpty
                    value=''
                    sx={{ minWidth: 200 }}
                    renderValue={() => (
                      <>{item.mapGuestAccessCollection.total} maps</>
                    )}
                  >
                    {item.mapGuestAccessCollection.items.map((access) => (
                      <MenuItem
                        key={access.id}
                        value={access.id}
                        onClick={() =>
                          window.open(
                            getMapLink({
                              mapId: access.map.id,
                              mapName: access.map.name
                            }),
                            '_blank'
                          )
                        }
                      >
                        {access.map.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                {(canRemoveGuest || canUpgradeToMember) && (
                  <TableCell align='right'>
                    <GuestMenu
                      loading={
                        upgradingMember
                          ? isMember(upgradingMember)
                            ? upgradingMember.user.id ===
                              (item['user']?.['id'] || item.userId)
                            : upgradingMember.userId ===
                              (item['user']?.['id'] || item.userId)
                          : false
                      }
                      canRemoveGuest={canRemoveGuest}
                      canUpgradeToMember={canUpgradeToMember}
                      onRemoveGuestClick={() =>
                        removeGuestDialog.handleOpen(item)
                      }
                      onUpgradeToMemberClick={async () => {
                        setUpgradingMember(item)
                        await onUpgradeToMemberConfirm(
                          isMember(item) ? item.user.id : item.userId
                        ).catch(console.error)
                        setUpgradingMember(undefined)
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  )
}

type GuestMenuProps = {
  loading
  canRemoveGuest: boolean
  canUpgradeToMember: boolean
  onRemoveGuestClick: () => void
  onUpgradeToMemberClick: () => void
}

const GuestMenu: FC<GuestMenuProps> = ({
  loading,
  canRemoveGuest,
  canUpgradeToMember,
  onRemoveGuestClick,
  onUpgradeToMemberClick
}) => {
  const popover = usePopover<HTMLButtonElement>()

  return (
    <>
      <IconButton
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <SvgIcon>
            <DotsHorizontal />
          </SvgIcon>
        )}
      </IconButton>
      <Menu
        disableScrollLock
        MenuListProps={{ dense: true }}
        anchorEl={popover.anchorRef.current}
        open={popover.open && !loading}
        onClose={popover.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        {canRemoveGuest && (
          <MenuItem
            onClick={() => {
              onRemoveGuestClick()
              popover.handleClose()
            }}
          >
            <ListItemText>Remove from workspace</ListItemText>
          </MenuItem>
        )}
        {canUpgradeToMember && (
          <MenuItem
            onClick={() => {
              onUpgradeToMemberClick()
              popover.handleClose()
            }}
          >
            <ListItemText>Upgrade to member</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

const isMember = (obj: Member | InvitedUser): obj is Member => 'user' in obj

const RemoveGuestDialog: FC<DialogProps> = ({ loading, ...rest }) => {
  return (
    <Dialog
      loading={loading}
      title='Are you absolutely sure?'
      confirmText='Remove'
      confirmColor='error'
      {...rest}
    >
      This action will remove the user as a guest of this workspace.
    </Dialog>
  )
}
const UpgradeToMemberDialog: FC<DialogProps> = ({ loading, ...rest }) => {
  return (
    <Dialog
      loading={loading}
      title='Are you absolutely sure?'
      confirmText='Remove'
      confirmColor='error'
      {...rest}
    >
      This action will upgrade the user to be a member of this workspace.
    </Dialog>
  )
}
