import { useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Feature, GeometryObject } from 'geojson'
import typography from '@mui/material/Typography'
import type { FC } from 'react'
import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'

export type OsmResult = {
  display_name: string
  boundingbox: string[]
}

export type JsonResult = Feature<GeometryObject>

type MapSearchModalResultsProps = {
  jsonResults?: JsonResult[]
  osmResults?: OsmResult[]
  title?: string
  open?: boolean
  onFeatureItemClick?: (e: React.MouseEvent, result: JsonResult) => void
  onLocationItemClick?: (e: React.MouseEvent, item: OsmResult) => void
}

export const MapSearchModalResults: FC<MapSearchModalResultsProps> = (
  props
) => {
  const {
    jsonResults = [],
    osmResults = [],
    title = '',
    open = false,
    onFeatureItemClick = () => {},
    onLocationItemClick = () => {}
  } = props
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  if (!open) return null

  return (
    <Box>
      <Scrollbar sx={{ maxHeight: isBrowser ? 500 : 300 }}>
        <Container
          maxWidth='sm'
          sx={{ padding: '0 !important' }}
        >
          {jsonResults.length > 0 && (
            <Card sx={{ borderRadius: 0, boxShadow: 0 }}>
              <CardHeader
                sx={{ pl: 2, pt: 2, pb: 1 }}
                title='Data lookup results'
              />
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Table>
                  <TableBody>
                    {jsonResults.map((result: JsonResult, index: number) => {
                      return (
                        <TableRow
                          hover
                          key={`Feature-${index}`}
                          onClick={(e) => onFeatureItemClick(e, result)}
                          sx={{
                            cursor: 'pointer',
                            '&:last-child td, &:last-child th': { border: 0 }
                          }}
                        >
                          <TableCell sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
                            <ListItem
                              divider={false}
                              disablePadding
                              key={'3Feature-' + index}
                            >
                              <ListItemText
                                sx={{ m: 0 }}
                                primary={
                                  result.properties
                                    ? result.properties[title]
                                    : ''
                                }
                                secondary={
                                  <Box>
                                    {Object.keys(result.properties || {}).map(
                                      (key, idx, arr) => (
                                        <div
                                          key={key}
                                          style={{ display: 'inline-flex' }}
                                        >
                                          <div>
                                            {key + ': '}
                                            <strong
                                              data-testid={
                                                result.properties?.[key]
                                              }
                                            >
                                              {result.properties
                                                ? result.properties[key]
                                                : ''}
                                            </strong>
                                          </div>
                                          {idx < arr.length - 1 && (
                                            <span
                                              style={{
                                                padding: '0px 4px 0px 5px'
                                              }}
                                            >
                                              ●
                                            </span>
                                          )}
                                        </div>
                                      )
                                    )}{' '}
                                  </Box>
                                }
                              />
                            </ListItem>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Card>
          )}
          {osmResults.length > 0 && (
            <>
              {jsonResults.length > 0 && <Divider />}
              <Card sx={{ boxShadow: 0 }}>
                <CardHeader
                  sx={{ pl: 2, pt: 1, pb: 1 }}
                  title='Address lookup results'
                />
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Table>
                    <TableBody>
                      {osmResults.map((item: OsmResult, index: number) => {
                        return (
                          <TableRow
                            hover
                            onClick={(e) => onLocationItemClick(e, item)}
                            key={`location-${index}`}
                            sx={{
                              cursor: 'pointer',
                              '&:last-child td, &:last-child th': { border: 0 }
                            }}
                          >
                            <TableCell sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
                              <ListItem
                                divider={false}
                                disablePadding
                                key={'location-item-' + index}
                              >
                                <ListItemText
                                  sx={{ m: 0 }}
                                  primary={item.display_name}
                                />
                              </ListItem>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Card>
            </>
          )}
        </Container>
      </Scrollbar>
    </Box>
  )
}
