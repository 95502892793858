import { TextField } from '@mui/material'

const MapNameInput = (props) => {
  const { value, onChange, ...rest } = props

  return (
    <TextField
      {...rest}
      label='Name'
      data-testid='MapName'
      autoFocus
      required
      fullWidth
      margin='none'
      value={value}
      onChange={onChange}
    />
  )
}

export { MapNameInput }
export default MapNameInput
