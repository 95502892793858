import { set } from 'idb-keyval'
import { setSubmitMapAction } from '~map-viewer/contexts/map-creator'
import { mapViewerStore } from '~map-viewer/contexts/store'

const setSubmitMap = async (mapId, mapStatus) => {
  mapViewerStore.dispatch(setSubmitMapAction({ mapId, mapStatus }))
  await set(`${mapId}:map-status`, mapStatus)
}

export { setSubmitMap }
