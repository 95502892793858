import removePrefix from '~ui-components/utilities/removePrefix'

const pathOf = (fileName) =>
  [
    '/public',
    '/assets',
    '/emails',
    '/socials',
    `/${removePrefix(fileName, './')}`
  ].join('')

const mapstack32pxPng = pathOf('./icon-mapstack-32px.png')

const mapstack100pxPng = pathOf('./icon-mapstack-100px.png')

const roundedFacebookPng = pathOf('./icon-rounded-facebook.png')

const roundedTwitterPng = pathOf('./icon-rounded-twitter.png')

const roundedInstagramPng = pathOf('./icon-rounded-instagram.png')

const roundedLinkedinPng = pathOf('./icon-rounded-linkedin.png')

const roundedYoutubePng = pathOf('./icon-rounded-youtube.png')

export {
  mapstack100pxPng,
  mapstack32pxPng,
  roundedFacebookPng,
  roundedInstagramPng,
  roundedLinkedinPng,
  roundedTwitterPng,
  roundedYoutubePng
}
