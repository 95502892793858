import { Popup as PopupComponent } from './Component'
import PropTypes from 'prop-types'

const Popup = ({ map }) => {
  return <PopupComponent map={map} />
}

Popup.propTypes = {
  map: PropTypes.object
}

class PopupPlugin {
  pluginStore

  getPluginName() {
    return 'Popup@0.1.0'
  }

  getDependencies() {
    return []
  }

  init(pluginStore) {
    this.pluginStore = pluginStore
  }

  setMap(map) {
    if (this.pluginStore) {
      this.pluginStore.executeFunction('Renderer.add', 'popup', () => (
        <Popup map={map} />
      ))
    }
  }

  activate() {}

  deactivate() {}
}

export default PopupPlugin
