import { Stack } from '@mui/material'
import Hexagon from '@untitled-ui/icons-react/build/esm/Hexagon01'
import Boundary from '@untitled-ui/icons-react/build/esm/Map01'
import Marker from '@untitled-ui/icons-react/build/esm/MarkerPin01'
import { Fragment } from 'react'
import { Sidebar } from '~ui-components/components/molecules/Sidebar'

const defaultSlots = {
  Form: Fragment
}

const icons = {
  '': null,
  'Marker map': Marker,
  'Hexagon map': Hexagon,
  'Boundaries map': Boundary
}

const MapSettingsDrawer = (props) => {
  const {
    title = '',
    sx,
    open,
    slots = {},
    slotProps = {},
    isEmbed = false,
    onClose,
    ...rest
  } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const IconComponent = icons[title] || Fragment

  return (
    <Sidebar
      isEmbed={isEmbed}
      closeAfterTransition
      anchor='left'
      title={title}
      icon={<IconComponent />}
      open={open}
      onClose={onClose}
      onTransitionExited={() => {}}
      ModalProps={{
        disablePortal: true,
        hideBackdrop: true,
        sx: {
          top: 'unset',
          right: 'unset',
          bottom: 'unset',
          left: 'unset'
        }
      }}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: 'var(--sidebar-width, 390px)'
        }
      }}
      {...rest}
    >
      <Stack
        p={2}
        spacing={3}
      >
        <Slots.Form {...slotProps.Form} />
      </Stack>
    </Sidebar>
  )
}

export { MapSettingsDrawer }
