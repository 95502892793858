import { Settings01 } from '@untitled-ui/icons-react'
import {
  IconListItem,
  IconListItemProps
} from '~ui-components/components/molecules/IconListItem'

function MapDataSettingsButton(props: IconListItemProps) {
  return (
    <IconListItem
      primary='Settings'
      title='Settings'
      icon={Settings01}
      {...props}
    />
  )
}

export { MapDataSettingsButton }
