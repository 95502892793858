import {
  ListItemIcon,
  ListItemText,
  MenuList,
  Modal,
  MenuItem as MuiMenuItem,
  Paper,
  SvgIcon,
  Typography
} from '@mui/material'
import AlertTriangle from '@untitled-ui/icons-react/build/esm/AlertTriangle'
import XClose from '@untitled-ui/icons-react/build/esm/XClose'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

const MenuItem = (props) => {
  const { label, icon, shortcut, ...rest } = props
  return (
    <MuiMenuItem {...rest}>
      <ListItemIcon>
        <SvgIcon fontSize='small'>{icon}</SvgIcon>
      </ListItemIcon>
      <ListItemText>{label}</ListItemText>
      {!isEmpty(shortcut) ? (
        <Typography
          variant='body2'
          color='text.secondary'
        >
          {shortcut}
        </Typography>
      ) : null}
    </MuiMenuItem>
  )
}

const MapActionMenuMore = (props) => {
  const { open, onClose = () => {}, onReport = () => {} } = props

  const handleReportClick = () => {
    onReport()
    onClose()
  }

  const handleCloseClick = () => {
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Paper
        sx={{
          outline: 'none',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 320
        }}
      >
        <MenuList dense>
          <MenuItem
            data-testid='MapActionMore-Report'
            label='Report'
            icon={<AlertTriangle />}
            onClick={handleReportClick}
          />
          <MenuItem
            data-testid='MapActionMore-Close'
            label='Close'
            icon={<XClose />}
            onClick={handleCloseClick}
          />
        </MenuList>
      </Paper>
    </Modal>
  )
}

MapActionMenuMore.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onReport: PropTypes.func
}

export { MapActionMenuMore }
export default MapActionMenuMore
