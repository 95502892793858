import { useDispatch } from 'react-redux'

import { setSource, useSource } from '~map-creator/core/states/map'

import View from './view'

const MapDataSourceInput = () => {
  const dispatch = useDispatch()
  const source = useSource()
  const handleSourceChange = (val) => {
    dispatch(setSource({ ...source, name: val }))
  }

  const handleSourceUrlChange = (val) => {
    dispatch(setSource({ ...source, url: val }))
  }

  return (
    <View
      dataSourceInputValue={source.name}
      dataSourceUrlInputValue={source.url}
      onSourceInputChange={handleSourceChange}
      onSourceUrlInputChange={handleSourceUrlChange}
    />
  )
}

export default MapDataSourceInput
export { MapDataSourceInput }
