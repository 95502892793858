import { isSymbol } from 'lodash'
import { UnsupportedTypeError } from '~utils/errors/UnsupportedTypeError'
import { isBoolean } from '~utils/functions/isBoolean'
import { isDate } from '~utils/functions/isDate'
import { isFloat } from '~utils/functions/isFloat'
import { isISO8601Date } from '~utils/functions/isISO8601Date'
import { isInteger } from '~utils/functions/isInteger'
import { isObject } from '~utils/functions/isObject'
import { isString } from '~utils/functions/isString'

/**
 * GeojsonProperties require this format to render:
 * @example
 * Object<{
 *   name: string
 *   type: string
 * }>
 */

/**
 * text    -> string
 * date    -> date
 * number  -> integer
 * decimal -> float
 */

const typeConversion = (value, key) => {
  let type = ''

  switch (true) {
    case isSymbol(value):
      throw UnsupportedTypeError.UnknownType()

    case isInteger(value):
      type = 'integer'
      break

    case isFloat(value):
      type = 'float'
      break

    case isBoolean(value):
      type = 'boolean'
      break

    case isString(value):
      switch (true) {
        case isDate(value):
        case isISO8601Date(value):
          type = 'date'
          break

        default:
          type = 'text'
      }

      break

    case isObject(value):
      type = 'object'
      break

    default:
      throw UnsupportedTypeError.UnknownType()
  }

  return {
    name: key,
    type
  }
}

export { typeConversion }
