import { graphierQL } from 'utils'
import {
  GetFileUploadUrlQuery,
  GetFileUploadUrlQueryVariables,
  Json_Extensions
} from '~map-creator/types/__generated/gql/graphql'

const getFileUploadUrl = async (geojsonId) => {
  const respond = await graphierQL<
    GetFileUploadUrlQuery,
    GetFileUploadUrlQueryVariables
  >({
    query: getFileUploadUrlQuery,
    variables: {
      id: geojsonId,
      extension: Json_Extensions.Geojson
    }
  })
  return respond.getFileUploadUrl
}

const getFileUploadUrlQuery = /* GraphQL */ `
  query GetFileUploadUrl($id: ID!, $extension: JSON_EXTENSIONS!) {
    getFileUploadUrl(id: $id, extension: $extension) {
      uploadUrl
      formFieldsJson
      fileUrl
    }
  }
`

export { getFileUploadUrl, getFileUploadUrlQuery }
export default getFileUploadUrl
