import geostats from 'geostats';
import _ from 'lodash';
import { Jenks } from '../../types';
import { DEFAULT_JENKS_CLASS_BREAKS } from '../../constants';

function roundToFixed(val: number, decimalPlaces: number, up: boolean = false) {
  const num = parseFloat(`${val}`.replace(/^0+/, ''))
  const factor = Math.pow(10, decimalPlaces);
  const roundedNum = (up) ? (Math.ceil(num * factor) / factor).toFixed(decimalPlaces) : (Math.floor(num * factor) / factor).toFixed(decimalPlaces)
  return roundedNum.toString()
}

function generateJenks(propertyArray: any[]): Jenks {

  // handling edge case of length == 1
  if (propertyArray.length < 2) {
    return propertyArray
  }

  // quick fix for removing leading 0's
  const series = new geostats(propertyArray)
  const uniqueCount = _.cloneDeep(series).getClassUniqueValues()
  const isOverClassBreakLimit = uniqueCount.length > DEFAULT_JENKS_CLASS_BREAKS
  let jenks: Jenks = []

  if (isOverClassBreakLimit) {
    jenks = series.getClassJenks(DEFAULT_JENKS_CLASS_BREAKS)

    // find min diff between different class breaks

    let min_val = jenks[jenks.length]! - jenks[0]!
    for (let i = 0; i < jenks.length - 1; i++) {
      let diff = jenks[i + 1]! - jenks[i]!
      if (diff < min_val) min_val = diff
    }

    // round to something appropriate for min diff
    const decimalPlaces = min_val.toString().split('.')[1]?.length || 0;
    const roundedArray = jenks.map(value => {
      const roundUp = (value == jenks.slice(-1)[0])
      return parseFloat(roundToFixed(value, decimalPlaces, roundUp));
    });

    return roundedArray;

  } else {
    jenks = uniqueCount.sort((a: number, b: number) => a - b)
  }
  return jenks
}


export { generateJenks };

