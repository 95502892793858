import React from 'react'
import { styled, FormLabel, Box, TextField } from '@mui/material'

const LabelTextField = styled((props) => {
  const { label, helperText, ...rest } = props
  return (
    <Box>
      <FormLabel
        component='div'
        sx={{ typography: 'body2' }}
      >
        {label}{' '}
        <Box
          component='span'
          fontStyle='italic'
        >
          {helperText}
        </Box>
      </FormLabel>
      <TextField
        sx={{ mt: 1.5 }}
        autoFocus
        required
        fullWidth
        label={null}
        margin='none'
        {...rest}
      />
    </Box>
  )
})({})

export { LabelTextField }
