import { useDispatch } from 'react-redux'
import { useUniquePropertiesKeys } from '~map-creator/core/states/geojson'
import {
  replaceLastWizardStep,
  useCurrentWizardStep
} from '~map-creator/core/states/map'
import { setUniquePropertyKey } from '~map-creator/core/states/properties'

export const useSkippableIdentifier = (isActivated = false) => {
  const dispatch = useDispatch()

  const currentWizardStep = useCurrentWizardStep()
  const uniqueProperties = useUniquePropertiesKeys()

  if (isActivated && uniqueProperties.length <= 1) {
    const nextStep = currentWizardStep + 1
    dispatch(replaceLastWizardStep(nextStep))
    if (uniqueProperties.length === 1) {
      dispatch(setUniquePropertyKey(uniqueProperties[0]))
    }
  }
}
