import { Alert, Box, styled } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { FileInput } from '~ui-components/components/molecules/FileInput'

const Alerts = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
})

const DataUpload = (props) => {
  const {
    AfterUploadBoxSlot,
    acceptTypes,
    alerts,
    onFileValidate,
    onFileChange,
    onFileDelete
  } = props

  return (
    <Box
      className='DataUpload-root'
      data-testid='DataUpload'
    >
      <FileInput
        multiple={false}
        accept={acceptTypes}
        onValidate={onFileValidate}
        onChange={onFileChange}
        onDelete={onFileDelete}
      />
      {AfterUploadBoxSlot ? (
        <Box
          data-testid='AfterUploadBoxSlot'
          mt={2}
        >
          {AfterUploadBoxSlot}
        </Box>
      ) : null}
      {alerts.length ? <AlertList alerts={alerts} /> : null}
    </Box>
  )
}

const DataUploadPropTypes = {
  AfterUploadBoxSlot: PropTypes.node,
  acceptTypes: PropTypes.string.isRequired,
  alerts: PropTypes.array,
  hasNoNumericProperties: PropTypes.bool,
  onFileValidate: PropTypes.func,
  onFileChange: PropTypes.func,
  onFileDelete: PropTypes.func,
  onClose: PropTypes.func
}

DataUpload.propTypes = DataUploadPropTypes

export { DataUpload, DataUploadPropTypes }

const AlertList = (props) => {
  const { alerts } = props

  if (isEmpty(alerts)) return null

  return (
    <Alerts
      mt={2}
      rowGap={1}
      data-testid='DataUpload-Alerts'
    >
      {alerts.map((alert) => (
        <Alert
          key={alert.id}
          severity={alert.type || 'error'}
          variant='outlined'
        >
          <div
            data-testid={`alert-${alert.id}`}
            dangerouslySetInnerHTML={{ __html: alert.message }}
          />
        </Alert>
      ))}
    </Alerts>
  )
}
