import PropTypes from 'prop-types'
import { Layer, Source } from 'react-map-gl/maplibre'
import {
  BASE_MAP_LAYER_ID,
  PROMOTED_COLUMN_ID
} from '~map-viewer/assets/constant/LayerConstant'

const View = (props) => {
  const {
    data,
    layerStyle,
    lineLayerStyle,
    filterBorderStyle,
    type,
    layout,
    source
  } = props

  return (
    <Source
      id={source}
      type={type}
      data={data}
      promoteId={PROMOTED_COLUMN_ID}
    >
      <Layer
        id='default'
        beforeId={BASE_MAP_LAYER_ID}
        layout={layout}
        {...layerStyle}
      />
      <Layer
        layout={layout}
        {...filterBorderStyle}
      />
      <Layer
        layout={layout}
        {...lineLayerStyle}
      />
    </Source>
  )
}

View.propTypes = {
  data: PropTypes.object,
  layerStyle: PropTypes.object,
  lineLayerStyle: PropTypes.object,
  filterBorderStyle: PropTypes.object,
  type: PropTypes.string,
  layout: PropTypes.object,
  source: PropTypes.string
}

export { View }
