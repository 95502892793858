import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api'
import { GraphQLOptionsV6 } from '@aws-amplify/api-graphql'
import { fetchAuthSession } from 'aws-amplify/auth'
import { client } from '../client'

type Options<A = object> = Omit<
  GraphQLOptionsV6<GraphQLQuery<A>, string>,
  'variables'
> & {
  useToken?: boolean
  variables?: A
}

export const graphierQL = async <R = unknown, A = Record<string, unknown>>(
  options: Options<A>
): Promise<GraphQLResult<R>['data']> => {
  const { useToken = false, ...restOptions } = options

  if (useToken) {
    const token = (await fetchAuthSession()).tokens?.idToken?.toString()
    restOptions.authMode = 'userPool'
    restOptions.authToken = token
  }

  const request = client.graphql<GraphQLQuery<R>>(
    restOptions as any
  ) as unknown as Promise<GraphQLResult<R>>

  return request.then((result) => result.data)
}
