import { MapName as View } from 'ui-components'
import { useMap } from '~map-viewer/states/map'

const MapName = (props) => {
  const { name } = useMap()
  return (
    <View
      {...props}
      name={name}
    />
  )
}

export { MapName }
export default MapName
