import { Box } from '@mui/material'
import { hideVisually } from 'polished'
import { useRef } from 'react'

import { useEditorState } from '@udecode/plate-core'

import Action from '~ui-components/components/molecules/TextEditor/components/Action'

const AddImage = (props) => {
  const editor = useEditorState()

  const inputRef = useRef(null)

  const clearInput = () => {
    inputRef.current.value = null
  }

  const addImages = (files) => {
    if (!editor?.cloud?.uploadImages) {
      editor.local.saveImages(files)
      return
    }

    editor.cloud.uploadImages(files)
  }

  const handleClick = () => {
    if (!inputRef.current) return
    inputRef.current.click()
  }

  return (
    <>
      <Action
        variant='image'
        onClick={handleClick}
        {...props}
      />

      <Box
        ref={inputRef}
        component='input'
        type='file'
        multiple
        accept='image/*'
        sx={[hideVisually()]}
        onChange={(event) => {
          const files = [...event.target.files]
          addImages(files)
          clearInput()
        }}
      />
    </>
  )
}

export { AddImage }
