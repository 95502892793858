import React from 'react'
import { useDispatch } from 'react-redux'
import { throttle } from 'utils'
import { useLazySearchTagsQuery } from '~map-creator/core/services/tag'
import {
  setInputTagsInvalid,
  setInputTagsValue as setInputValue,
  setMapTags as setValue,
  useInputTags,
  useMapTags
} from '~map-creator/core/states/ui'

import View from './view'

const MapTagsInput = () => {
  const dispatch = useDispatch()
  const { value: inputValue, options } = useInputTags()
  const value = useMapTags()
  const [searchTags, { isFetching }] = useLazySearchTagsQuery()

  const handleInputChange = (_, inputValue) => {
    dispatch(setInputValue(inputValue))
    throttle(() => {
      searchTags({
        query: inputValue,
        from: value.length
      })
    }, 250)
  }

  const handleChange = (_, value) => {
    dispatch(setValue(value))
  }

  const handleInvalidInput = (_, { invalid }) => {
    dispatch(setInputTagsInvalid(invalid))
  }

  return (
    <View
      loading={isFetching}
      value={value}
      inputValue={inputValue}
      options={options}
      onInputChange={handleInputChange}
      onChange={handleChange}
      onInvalidInput={handleInvalidInput}
    />
  )
}

export { MapTagsInput }
export default MapTagsInput
