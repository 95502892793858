import { Button, SvgIcon } from '@mui/material'
import ListOrdered from '~ui-components/assets/svg/ListOrdered'
import Quote from '~ui-components/assets/svg/Quote'

import AlignCenter from '@untitled-ui/icons-react/build/esm/AlignCenter'
import AlignJustify from '@untitled-ui/icons-react/build/esm/AlignJustify'
import AlignLeft from '@untitled-ui/icons-react/build/esm/AlignLeft'
import AlignRight from '@untitled-ui/icons-react/build/esm/AlignRight'
import Bold01 from '@untitled-ui/icons-react/build/esm/Bold01'
import Divider from '@untitled-ui/icons-react/build/esm/Divider'
import FaceSmile from '@untitled-ui/icons-react/build/esm/FaceSmile'
import FileAttachment01 from '@untitled-ui/icons-react/build/esm/FileAttachment01'
import ImagePlus from '@untitled-ui/icons-react/build/esm/ImagePlus'
import Italic01 from '@untitled-ui/icons-react/build/esm/Italic01'
import Link02 from '@untitled-ui/icons-react/build/esm/Link02'
import List from '@untitled-ui/icons-react/build/esm/List'
import Strikethrough01 from '@untitled-ui/icons-react/build/esm/Strikethrough01'
import Underline01 from '@untitled-ui/icons-react/build/esm/Underline01'

const variants = {
  bold: <Bold01 />,
  italic: <Italic01 />,
  unorderedList: <List />,
  orderedList: <ListOrdered />,
  underline: <Underline01 />,
  strikethrough: <Strikethrough01 />,
  link: <Link02 />,
  alignLeft: <AlignLeft />,
  alignCenter: <AlignCenter />,
  alignRight: <AlignRight />,
  alignJustify: <AlignJustify />,
  emoji: <FaceSmile />,
  quote: <Quote />,
  horizontalLine: <Divider />,
  image: <ImagePlus />,
  mediaEmbed: <FileAttachment01 />
}

const Action = (props) => {
  const { variant, toggled, ...rest } = props
  return (
    <Button
      disableElevation
      variant='contained'
      color={toggled ? 'primary' : 'inherit'}
      size='small'
      sx={{
        m: 0,
        p: 0,
        aspectRatio: 1,
        display: 'flex',
        minWidth: 28,
        borderRadius: 1
      }}
      {...rest}
    >
      <SvgIcon sx={{ fontSize: '1.2em' }}>{variants[variant]}</SvgIcon>
    </Button>
  )
}

export { Action }
export default Action
