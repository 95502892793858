import md5 from 'blueimp-md5'
import _ from 'lodash'

export const hashMD5 = (val) => {
  try {
    if (_.isEmpty(val)) return
    const hash = md5(JSON.stringify(val))
    return hash
  } catch (error) {
    throw new Error(`Error hash to MD5: ${error.message}`)
  }
}
