import { Alert, Portal, Snackbar } from '@mui/material'
import PropTypes from 'prop-types'

const SnackAlert = (props) => {
  const {
    autoHideDuration = 5000,
    severity = 'info',
    variant = 'filled',
    open,
    onClose,
    snackBarProps = {},
    alertProps = {},
    disablePortal = false,
    children,
    ...rest
  } = props
  return (
    <Portal disablePortal={disablePortal}>
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        {...snackBarProps}
        {...rest}
      >
        <Alert
          onClose={onClose}
          variant={variant}
          severity={severity}
          {...alertProps}
        >
          {children}
        </Alert>
      </Snackbar>
    </Portal>
  )
}

SnackAlert.propTypes = {
  /**
   * Severity of the snack alert component
   */
  severity: PropTypes.oneOf(['success', 'warning', 'info', 'error']),
  /**
   * Variant of the snack alert component
   */
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  /**
   * State of the snack alert component, show if set to true
   */
  open: PropTypes.bool,
  /**
   * Callback that invoke after snack alert closed
   */
  onClose: PropTypes.func,
  snackBarProps: PropTypes.object,
  alertProps: PropTypes.object,
  disablePortal: PropTypes.bool,
  /**
   * The content of the component
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export { SnackAlert }
export default SnackAlert
