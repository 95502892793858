import { Paper, PaperProps, useMediaQuery, useTheme } from '@mui/material'

type MapNameProps = PaperProps & {
  name?: string
}

const PointMapName = (props: MapNameProps) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1024))
  const { sx, name } = props

  if (isSmallScreen) return

  return (
    <Paper
      id='MapTitle'
      square
      component='header'
      variant='outlined'
      sx={[
        {
          display: 'flex',
          flexDirection: 'row',
          p: 1,
          pl: 2,
          pr: 2,
          backgroundColor: '#fff',
          fontSize: '20px',
          border: '1px solid #ccc',
          borderRadius: '5px'
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {name}
    </Paper>
  )
}

export { PointMapName }
export default PointMapName
