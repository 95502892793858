import StateProvider from '~map-creator/map-editor/states/provider'

import { BaseMap } from '~map-creator/core/App/BaseMap'
import EditMap from './EditMap'
import View from './view'

const Layout = () => {
  return (
    <View
      slots={{
        Map: BaseMap,
        Form: EditMap
      }}
    />
  )
}

const MapEditorApp = () => {
  return (
    <StateProvider>
      <Layout />
    </StateProvider>
  )
}

export { MapEditorApp }
