import { GEOJSON_ERRORS } from '../../assets/constants'
import { validateBbox } from '../isBbox'
import { isPolygonCoor } from '../isPolygonCoor'
import { isJsonObject } from '../../helpers/isJsonObject'

/**
 * Determines if an object is a valid Polygon
 * @method isPolygon
 * @param polygon {Object}
 * @return {Boolean}
 */
export const isPolygon = (polygon) => {
  if (!isJsonObject(polygon)) {
    return [
      [
        GEOJSON_ERRORS.NOT_JSON_OBJECT.key,
        GEOJSON_ERRORS.NOT_JSON_OBJECT.description
      ]
    ]
  }

  let errors = []

  if ('bbox' in polygon) {
    const e = validateBbox(polygon.bbox)
    if (e.length > 0) {
      errors = e
    }
  }

  if ('type' in polygon) {
    if (polygon.type !== 'Polygon') {
      // type must be "Polygon"
      errors.push([
        GEOJSON_ERRORS.MUST_BE_TYPE_POLYGON.key,
        GEOJSON_ERRORS.MUST_BE_TYPE_POLYGON.description
      ])
    }
  } else {
    errors.push([
      GEOJSON_ERRORS.MISSING_MEMBER_TYPE.key,
      GEOJSON_ERRORS.MISSING_MEMBER_TYPE.description
    ])
  }

  if ('coordinates' in polygon) {
    const e = isPolygonCoor(polygon.coordinates)
    if (e.length) {
      errors = errors.concat(e)
    }
  } else {
    // must have a member with the name "coordinates"
    errors.push([
      GEOJSON_ERRORS.MISSING_MEMBER_COORDINATE.key,
      GEOJSON_ERRORS.MISSING_MEMBER_COORDINATE.description
    ])
  }

  return errors
}
