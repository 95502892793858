import { ButtonProps, Fab, SvgIcon } from '@mui/material'
import { Globe04, Lock01 } from '@untitled-ui/icons-react'

type ShareMapButtonProps = ButtonProps & {
  isPublic: boolean
}

const ShareMapButton = (props: ShareMapButtonProps) => {
  const { isPublic, variant = 'contained', onClick = () => {}, ...rest } = props

  return (
    <Fab
      sx={{ height: 40 }}
      color='primary'
      variant='extended'
      data-testid='ShareMapButton'
      onClick={onClick}
      {...rest}
    >
      <SvgIcon sx={{ mr: 1 }}>{isPublic ? <Globe04 /> : <Lock01 />}</SvgIcon>
      Share
    </Fab>
  )
}

export { ShareMapButton }
