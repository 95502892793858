import http from 'axios'

const uploadFileToS3 = async ({ url, formData, file }) => {
  const form = new FormData()
  form.append('Content-Type', file.type)
  Object.entries(formData).forEach(([k, v]) => form.append(k, v))
  form.append('file', file) // must be the last one

  try {
    await http.post(url, form)
    return true
  } catch (error) {
    return false
  }
}

export { uploadFileToS3 }
export default uploadFileToS3
