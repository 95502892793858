import { Chip, Stack } from '@mui/material'
import PropTypes from 'prop-types'

const PropertiesList = (props) => {
  const {
    size = 'small',
    selectedProperty = 'quantity',
    properties = [],
    ...rest
  } = props

  return (
    <Stack
      direction='row'
      rowGap={1.25}
      columnGap={1.25}
      flexWrap='wrap'
      {...rest}
    >
      {properties
        .filter((property) => property.visualisation === selectedProperty)
        .map((property) => (
          <Chip
            variant='filled'
            size={size}
            key={property?.name}
            label={property?.name}
            color={property?.isDefaultVisualisation ? 'primary' : 'default'}
          />
        ))}
    </Stack>
  )
}

PropertiesList.propTypes = {
  selectedProperty: PropTypes.string,
  properties: PropTypes.array
}

export { PropertiesList }
export default PropertiesList
