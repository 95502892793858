import { FocusTrap } from '@mui/base'
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Stack,
  SvgIcon,
  alpha,
  createFilterOptions,
  useAutocomplete
} from '@mui/material'
import { concat, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'
import useResizeObserver from 'use-resize-observer'

import AlertCircle from '@untitled-ui/icons-react/build/esm/AlertCircle'
import XClose from '@untitled-ui/icons-react/build/esm/XClose'
import { sanitizeNameForTag } from 'utils'
import EmphasizeText from '~ui-components/components/atoms/EmphasizeText'

const Tag = (props) => {
  const { label, onDelete, ...rest } = props

  return (
    <Box
      data-testid='Tag'
      component='div'
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        height: '24px',
        lineHeight: '22px',
        mt: '6px',
        ml: '6px',
        padding: '0 4px 0 10px',
        color: 'text.primary',
        border: `1px solid ${theme.palette.neutral[200]}`,
        borderRadius: 1,
        boxSizing: 'content-box',
        outline: 0,
        overflow: 'hidden',

        '& span': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        },

        '& svg': {
          fontSize: '12px',
          cursor: 'pointer',
          padding: '4px'
        }
      })}
      {...rest}
    >
      <Box component='span'>{label}</Box>
      <IconButton
        sx={{
          marginLeft: '4px',
          width: '18px',
          height: '18px'
        }}
        onClick={onDelete}
      >
        <SvgIcon sx={{ fontSize: '22px !important' }}>
          <XClose />
        </SvgIcon>
      </IconButton>
    </Box>
  )
}

const HelperTexts = ({ messages }) => {
  return (
    <Stack
      component='ul'
      direction='column'
      sx={{
        m: 0,
        p: 0,
        pt: 1,
        px: 3,
        color: (theme) => theme.palette.text.primary
      }}
    >
      {messages.map((message, index) => {
        return (
          <Box
            key={`Error-${index}`}
            component='li'
            sx={{
              fontSize: 12
            }}
          >
            {message}
          </Box>
        )
      })}
    </Stack>
  )
}

const AutocompleteTag = (props) => {
  const {
    limit = 5, // Only allows 5 tag items
    sx,
    color = 'primary',
    loading = false,
    options = [],
    value: valueProp = [],
    inputValue: inputValueProp = '',
    placeholder = 'e.g. population, economics, sales',
    onInputChange: onInputChangeProp,
    onCreateOption: onCreateOptionProp,
    onRemoveOption: onRemoveOptionProp,
    onSelectOption: onSelectOptionProp,
    onChange: onChangeProp,
    onInvalidInput: onInvalidInputProp,
    ...rest
  } = props

  const [{ open, errors, focusOption, memoOptions }, setState] = useImmer({
    open: false,
    errors: [],
    focusOption: {},
    memoOptions: []
  })

  // Combined between created options and the options from the user
  const combinedOptions = useMemo(
    () => concat(options, memoOptions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options.length, memoOptions.length]
  )

  // Validation
  useEffect(() => {
    const errors = []

    if (valueProp.length > limit) {
      errors.push(`Please enter no more than ${limit} tags.`)
    }

    if (onInvalidInputProp) {
      onInvalidInputProp(
        { type: 'validate' },
        {
          invalid: errors.length > 0,
          messages: errors
        }
      )
    }

    setState((draft) => {
      draft.errors = errors
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueProp.length])

  // Effect: only show dropdown when there is inputValue
  useEffect(() => {
    setState((draft) => {
      draft.open = !isEmpty(inputValueProp)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueProp])

  const onCreateOption = (event, unsafeName) => {
    const name = sanitizeNameForTag(unsafeName)
    const id = `topic:${name}`

    const existOption = options.find((option) => option.id === id)

    if (existOption) {
      onCreateOptionProp && onCreateOptionProp(event, existOption, 'exist')

      if (onChangeProp) {
        const nextValue = concat(valueProp, existOption)
        onChangeProp(event, nextValue)
      }
      return
    }

    const newOption = {
      id,
      name,
      description: '',
      type: 'topic',
      count: 0
    }

    onCreateOptionProp && onCreateOptionProp(event, newOption, 'new')

    if (onChangeProp) {
      // Not update the value if it is an existed value
      const isExistValue = valueProp.find((item) => item.id === newOption.id)
      if (isExistValue) return

      const isMemoOption = memoOptions.find((item) => item.id === newOption.id)
      if (!isMemoOption) {
        setState((draft) => {
          draft.memoOptions.push(newOption)
        })
      }

      const nextValue = concat(valueProp, newOption)
      onChangeProp(event, nextValue)
    }
  }

  // Invoke onRemoveOption on call
  // and invoke onChange with selected option being removed
  // and mark the selected option as inputValue
  const onRemoveOption = (event, option) => {
    event.preventDefault()
    onInputChangeProp && onInputChangeProp(event, option.name)
    onRemoveOptionProp && onRemoveOptionProp(event, option)

    if (onChangeProp) {
      const nextValue = valueProp.filter((item) => item.id !== option.id)
      onChangeProp(event, nextValue)
    }
  }

  // Invoke onSelectOption on call
  // and invoke onChange with new value being added
  const onSelectOption = (event, option) => {
    onSelectOptionProp && onSelectOptionProp(event, option)

    if (onChangeProp) {
      const nextValue = concat(valueProp, option)
      onChangeProp(event, nextValue)
    }
  }

  const handleChange = (event, _, reason, details) => {
    switch (reason) {
      case 'createOption':
        onCreateOption(event, details.option)
        break

      case 'removeOption':
        onRemoveOption(event, details.option)
        break

      case 'selectOption':
        onSelectOption(event, details.option)
        break
    }

    setState((draft) => {
      draft.focusOption = {}
    })
  }

  const forceInputFocus = () => {
    const input = document.getElementById('AutocompleteTag')
    if (input) setTimeout(() => input.focus(), 10)
  }

  const handleInputChange = (event, value) => {
    onInputChangeProp && onInputChangeProp(event, value)
    if (!value) forceInputFocus()
  }

  const handleKeyDown = (event) => {
    // Ignore when the inputValue is empty and user pressed space
    if (event.code === 'Space' && isEmpty(event.target.value)) {
      event.preventDefault()
      forceInputFocus()
      return event
    }

    // Invoke onCreateOption when the input is not empty and user pressed space
    // after added then we clear the inputValue
    if (event.code === 'Space' && !isEmpty(event.target.value)) {
      event.preventDefault()
      onCreateOption({ type: event.type }, event.target.value)
      onInputChangeProp && onInputChangeProp({ type: 'clear' }, '')
      forceInputFocus()
    }
  }

  const {
    anchorEl,
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value: autocompleteValue,
    inputValue: autocompleteInputValue,
    focused,
    setAnchorEl
  } = useAutocomplete({
    open,
    // allows multiple tags
    multiple: true,
    // user can create their own tag
    freeSolo: true,
    // remove the selected option from the dropdown list
    filterSelectedOptions: true,
    disableCloseOnSelect: true,
    filterOptions: createFilterOptions({
      matchFrom: 'any',
      trim: true
    }),
    options: combinedOptions,
    getOptionLabel: (option) => option.name,
    value: valueProp,
    inputValue: inputValueProp,
    onChange: handleChange,
    onInputChange: handleInputChange
  })

  // Update the dropdown list width when the window resized
  const { width } = useResizeObserver({ ref: anchorEl })

  const showHint =
    // check if there is input value
    !isEmpty(autocompleteInputValue) &&
    // check if there is no tag being focused
    isEmpty(focusOption) &&
    // check if there is option to be shown
    groupedOptions[0]?.name.startsWith(autocompleteInputValue)

  const hasError = errors.length > 0

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setState((draft) => {
            draft.open = false
          })
        }}
      >
        <Box
          sx={[
            {
              position: 'relative',
              color: (t) => t.palette.text.primary
            },
            ...(Array.isArray(sx) ? sx : [sx])
          ]}
          {...rest}
        >
          <Box component='div'>
            <Stack direction='column'>
              <Box
                component='label'
                sx={{
                  color: 'text.primary',
                  mb: 0.5,
                  lineHeight: 1.5,
                  display: 'block',
                  fontSize: 16,
                  letterSpacing: 'tight',
                  fontWeight: 'bold'
                }}
                {...getInputLabelProps()}
              >
                Tags
              </Box>
              <Box
                component='div'
                sx={{
                  color: 'text.secondary',
                  mb: 0.5,
                  fontSize: 12,
                  fontWeight: 'normal'
                }}
              >
                Add some tags that will help this map be categorized. Add up to{' '}
                {limit} tags to describe what your map is about.
              </Box>
            </Stack>

            <FocusTrap
              open={open}
              disableAutoFocus
            >
              <Box
                component='div'
                ref={setAnchorEl}
                data-focused={focused}
                {...getRootProps()}
                sx={[
                  (theme) => ({
                    position: 'relative',
                    padding: '1px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.divider,
                    borderRadius: 1,
                    display: 'flex',
                    flexWrap: 'wrap',
                    outline: 0,
                    outlineWidth: 0,
                    fontSize: 12,
                    '&:hover': {
                      borderColor:
                        theme.palette[hasError ? 'error' : color].main
                    },
                    '&[data-focused=true]': {
                      borderColor:
                        theme.palette[hasError ? 'error' : color].main,
                      boxShadow:
                        '0 0 0 4px ' +
                        alpha(
                          theme.palette[hasError ? 'error' : color].main,
                          0.25
                        )
                    }
                  })
                ]}
                onBlur={() => {
                  setState((draft) => {
                    draft.focusOption = {}
                  })
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    position: 'relative',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'baseline'
                  }}
                >
                  {autocompleteValue.map((option, index) => (
                    <Tag
                      key={option?.name}
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))}

                  <Box
                    component='label'
                    htmlFor='AutocompleteTag'
                    sx={{
                      position: 'relative',
                      flex: 1,
                      display: 'flex'
                    }}
                  >
                    <Box
                      component='input'
                      sx={[
                        {
                          width: 0,
                          height: '38px',
                          minWidth: '30px',
                          color: 'text.primary',
                          px: 1,
                          py: 0.5,
                          flexGrow: 1,
                          border: 0,
                          margin: 0,
                          outline: 0,
                          fontSize: 'inherit',
                          letterSpacing: 'inherit',
                          backgroundColor: 'transparent'
                        },
                        !isEmpty(autocompleteValue) && {
                          '&::-webkit-input-placeholder': {
                            color: 'transparent'
                          }
                        }
                      ]}
                      {...getInputProps()}
                      id='AutocompleteTag'
                      data-testid='AutocompleteTag'
                      value={focusOption?.name || autocompleteInputValue}
                      placeholder={placeholder}
                      onKeyDown={handleKeyDown}
                    />

                    {showHint ? (
                      <Box
                        component='div'
                        sx={{
                          position: 'absolute',
                          transform: 'translateY(-50%)',
                          top: '50%',
                          left: '8px',
                          color: 'text.disabled',
                          fontSize: 'inherit',
                          letterSpacing: 'inherit'
                        }}
                      >
                        {groupedOptions[0]?.name}
                      </Box>
                    ) : null}
                  </Box>

                  <Popper
                    disablePortal
                    open={open && !loading}
                    anchorEl={anchorEl}
                    sx={{
                      zIndex: 'modal'
                    }}
                  >
                    <Paper
                      variant='outlined'
                      sx={{
                        maxHeight: '250px',
                        overflow: 'auto',
                        borderRadius: 2,
                        width
                      }}
                    >
                      {/*
                       * Show the message when there is no option
                       */}

                      {groupedOptions.length <= 0 ? (
                        <Box
                          sx={{
                            p: 1,
                            fontSize: 12,
                            fontWeight: 'medium'
                          }}
                        >
                          We couldn&apos;t find the tag you are looking for.
                        </Box>
                      ) : (
                        <Box
                          component='ul'
                          sx={{
                            m: 0,
                            p: 1,
                            width: '100%',
                            height: '100%',
                            listStyle: 'none',
                            display: 'grid',
                            gridTemplateColumns:
                              'repeat(auto-fill, minmax(256px, 1fr))',
                            gap: 1,
                            '&:focus': {
                              outline: 'none',
                              boxShadow: 'none',
                              borderColor: 'transparent'
                            }
                          }}
                          {...getListboxProps()}
                        >
                          {groupedOptions.map((option, index) => (
                            <Stack
                              key={option.id}
                              {...getOptionProps({ option, index })}
                              component='li'
                              tabIndex={0}
                              sx={(theme) => ({
                                p: 1,
                                borderRadius: 1,
                                '&:hover': {
                                  backgroundColor: theme.palette.grey[50],

                                  '.ListOption-TagName': {
                                    textDecoration: 'underline'
                                  }
                                },
                                '&:focus': {
                                  outline: 'none',
                                  borderColor: 'transparent',
                                  backgroundColor: theme.palette.grey[50]
                                }
                              })}
                              onFocus={() => {
                                setState((draft) => {
                                  draft.focusOption = option
                                })
                              }}
                              onKeyDown={(event) => {
                                if (event.code === 'Enter') {
                                  event.preventDefault()
                                  event.stopPropagation()

                                  onSelectOption({ type: event.type }, option)

                                  onInputChangeProp &&
                                    onInputChangeProp({ type: 'clear' }, '')

                                  setState((draft) => {
                                    draft.focusOption = {}
                                  })
                                  forceInputFocus()
                                }
                              }}
                            >
                              <Stack
                                direction='row'
                                alignItems='center'
                              >
                                <Box
                                  className='ListOption-TagName'
                                  component='div'
                                  sx={(theme) => ({
                                    px: 1,
                                    mr: 1,
                                    borderRadius: 4,
                                    fontSize: 12,
                                    lineHeight: 1.84615385,
                                    whiteSpace: 'nowrap',
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    verticalAlign: 'middle',
                                    backgroundColor: theme.palette.grey[200]
                                  })}
                                >
                                  <EmphasizeText
                                    highlight={autocompleteInputValue}
                                  >
                                    {option?.name}
                                  </EmphasizeText>
                                </Box>
                                <Stack
                                  className='ListOption-TagCount'
                                  component='div'
                                  direction='row'
                                  alignItems='center'
                                  sx={{ fontSize: 11 }}
                                >
                                  {option?.count}
                                </Stack>
                              </Stack>
                              <Box
                                className='ListOption-TagDescription'
                                component='div'
                                sx={{
                                  mt: 1,
                                  fontSize: 11
                                }}
                              >
                                <EmphasizeText
                                  highlight={autocompleteInputValue}
                                >
                                  {option?.description}
                                </EmphasizeText>
                              </Box>
                            </Stack>
                          ))}
                        </Box>
                      )}
                    </Paper>
                  </Popper>

                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      right: 0,
                      transformOrigin: 'center',
                      transform: 'translate(-8px, -50%)'
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        color={color}
                        size='18px'
                        sx={{ display: 'block' }}
                      />
                    ) : errors.length > 0 ? (
                      <SvgIcon color='error'>
                        <AlertCircle />
                      </SvgIcon>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </FocusTrap>
          </Box>

          <HelperTexts messages={errors} />
        </Box>
      </ClickAwayListener>
    </>
  )
}

AutocompleteTag.propTypes = {
  loading: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.any,
  inputValue: PropTypes.any,
  defaultValue: PropTypes.any,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  onCreateOption: PropTypes.func,
  onRemoveOption: PropTypes.func,
  onSelectOption: PropTypes.func,
  onInvalidInput: PropTypes.func
}

export { AutocompleteTag }
export default AutocompleteTag
