import {
  cloneDeep,
  forEach,
  isEmpty,
  isEqual,
  map,
  some,
  toLower
} from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { v1 as uuidV1 } from 'uuid'
import {
  INCLUDE_PROPERTIES_ERRORS,
  MAX_PROPERTIES_ALLOWED,
  PROPERTY_TYPE_OPTIONS
} from '~map-creator/core/assets/constants'
import { useDefaultPropertyConfigs } from '~map-creator/core/states/geojson'
import {
  setIncludePropertyError,
  updatePropertyName,
  updatePropertyType,
  updatePropertyVisibility,
  useConfigurations,
  useIncludePropertyError,
  useIncludedKeys
} from '~map-creator/core/states/properties'
import { View } from './view'

const IncludeProperties = (props) => {
  const { maxPropertiesAllowed, onValidated, titlePropertyKey } = props
  const error = useIncludePropertyError()

  const dispatch = useDispatch()
  const configurations = useConfigurations()
  const includedProperties = useIncludedKeys()
  const defaultConfigs = useDefaultPropertyConfigs()

  const handleError = useCallback(
    (error) => {
      dispatch(setIncludePropertyError(error))
    },
    [dispatch]
  )

  /** Validate properties */
  useEffect(() => {
    const propertyNames = map(configurations, (o) => toLower(o.name))
    const isContainEmptyPropertyName = some(propertyNames, isEmpty)
    const duplicatePropertyNames = propertyNames.filter(
      (item, index) => propertyNames.indexOf(item) != index
    )

    if (!isEmpty(duplicatePropertyNames)) {
      handleError(INCLUDE_PROPERTIES_ERRORS.DUPLICATE_PROPERTY_NAME)
    }

    if (isContainEmptyPropertyName) {
      handleError(INCLUDE_PROPERTIES_ERRORS.EMPTY_PROPERTY_NAME)
    }

    const isValid =
      (!isEmpty(includedProperties) && isEmpty(error)) ||
      isEqual(error, INCLUDE_PROPERTIES_ERRORS.MAX_PROPERTIES_REACHED)

    onValidated(isValid)
  }, [configurations, includedProperties, error, onValidated, handleError])

  const handleUpdatePropertyName = (key, value) => {
    handleError(null)
    dispatch(updatePropertyName({ key, value }))
  }

  const handlePropertyTypeChange = (key, type) => {
    dispatch(updatePropertyType({ key, value: type }))
  }

  const handlePropertyVisibilityChange = (key, visible) => {
    handleError(null)
    const isAllowedPropertiesReached =
      includedProperties.length === maxPropertiesAllowed

    if (visible && isAllowedPropertiesReached) {
      handleError(INCLUDE_PROPERTIES_ERRORS.MAX_PROPERTIES_REACHED)
      return
    }

    dispatch(updatePropertyVisibility({ key, value: visible }))
  }

  const modifiedConfigurations = cloneDeep(configurations)
  const defaultPropertyOptions = useMemo(() => {
    const propertyOptions = {}
    forEach(defaultConfigs, (value, key) => {
      const { options } = value
      propertyOptions[key] = options.map((option) => {
        return {
          id: uuidV1(),
          ...PROPERTY_TYPE_OPTIONS[option]
        }
      })
    })
    return propertyOptions
  }, [defaultConfigs])

  if (titlePropertyKey) delete modifiedConfigurations[titlePropertyKey]

  return (
    <View
      error={error}
      defaultPropertyOptions={defaultPropertyOptions}
      configurations={modifiedConfigurations}
      includedProperties={includedProperties}
      onPropertyNameChange={handleUpdatePropertyName}
      onPropertyVisibilityChange={handlePropertyVisibilityChange}
      onPropertyTypeChange={handlePropertyTypeChange}
    />
  )
}

IncludeProperties.propTypes = {
  maxPropertiesAllowed: PropTypes.number,
  onValidated: PropTypes.func,
  titlePropertyKey: PropTypes.string
}

IncludeProperties.defaultProps = {
  maxPropertiesAllowed: MAX_PROPERTIES_ALLOWED,
  onValidated: () => false
}

export { IncludeProperties }
export default IncludeProperties
