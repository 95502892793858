import { Stack, TextField } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { isValidUrl } from 'utils'

const MapDataSourceInput = (props) => {
  const {
    dataSourceInputValue = '',
    dataSourceUrlInputValue = '',
    onSourceInputChange,
    onSourceUrlInputChange
  } = props

  const [urlError, setUrlError] = React.useState(false)

  const handleSourceChange = (event) => {
    onSourceInputChange(event.target.value)
  }

  const handleSourceUrlChange = (event) => {
    onSourceUrlInputChange(event.target.value)
  }

  const validateInputUrl = (event) => {
    const value = event.target.value
    const invalid = !isEmpty(value) && !isValidUrl(value)
    setUrlError(invalid)
  }

  return (
    <Stack
      direction='column'
      gap={2}
    >
      <TextField
        label='Data Source'
        placeholder='Enter name of data source'
        value={dataSourceInputValue}
        onChange={handleSourceChange}
      />
      <TextField
        label='Data Source Website'
        error={urlError}
        placeholder='Enter a valid URL'
        value={dataSourceUrlInputValue}
        onChange={handleSourceUrlChange}
        onBlur={validateInputUrl}
        helperText={urlError ? 'Invalid URL' : ''}
      />
    </Stack>
  )
}

const SelectInputPropTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func
}

MapDataSourceInput.propTypes = SelectInputPropTypes

export { MapDataSourceInput }
export default MapDataSourceInput
