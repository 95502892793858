import { Box, Chip, Stack, SvgIcon, Tooltip } from '@mui/material'
import { isEmpty, sortBy } from 'lodash'
import NextLink from 'next/link'
import PropTypes from 'prop-types'
import { useId, useMemo } from 'react'

import DotsHorizontal from '@untitled-ui/icons-react/build/esm/DotsHorizontal'
import { getSearchQuery } from 'utils'

const sortOrder = ['coverageArea', 'featureType', 'topic']
const sortLast = sortOrder.length

const TagsList = (props) => {
  const {
    fallback: Fallback = () => null,
    limit = false,
    size = 'small',
    tags: tagsProp = [],
    tagHref,
    sx,
    onItemClick = () => {},
    onMoreClick = () => {},
    ...rest
  } = props

  const id = useId()
  const tags = useTags(tagsProp)

  if (isEmpty(tags)) {
    return <>{typeof Fallback === 'function' ? <Fallback /> : Fallback}</>
  }

  return (
    <Box
      component={Stack}
      direction='row'
      rowGap={1}
      columnGap={1}
      flexWrap='wrap'
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}
    >
      {!limit ? (
        <>
          {tags.map((tag) => (
            <Tag
              key={`${id}-${tag?.id}`}
              tag={tag}
              href={
                tagHref ? tagHref(tag) : getSearchQuery({ tags: [tag?.id] })
              }
              size={size}
              onClick={onItemClick}
            />
          ))}
        </>
      ) : (
        <>
          {Number.isInteger(limit) ? (
            <>
              {tags.slice(0, limit).map((tag) => {
                return (
                  <Tag
                    key={`${id}-${tag?.id}`}
                    tag={tag}
                    href={
                      tagHref
                        ? tagHref(tag)
                        : getSearchQuery({ tags: [tag?.id] })
                    }
                    size={size}
                    onClick={onItemClick}
                  />
                )
              })}

              {tags.length - limit > 0 ? (
                <>
                  <Chip
                    key='Chip-More'
                    variant='default'
                    size={size}
                    label={
                      <SvgIcon sx={{ fontSize: 'inherit' }}>
                        <DotsHorizontal />
                      </SvgIcon>
                    }
                    color='default'
                    sx={{
                      '& .MuiChip-label': {
                        fontWeight: 'semibold'
                      }
                    }}
                    onClick={onMoreClick}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </>
      )}
    </Box>
  )
}

const TagsListPropTypes = {
  limit: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  size: PropTypes.oneOf(['small', 'medium']),
  tags: PropTypes.array,
  tagHref: PropTypes.func,
  onItemClick: PropTypes.func,
  onMoreClick: PropTypes.func
}

TagsList.propTypes = TagsListPropTypes

const getColorName = (type) => {
  switch (type) {
    case 'coverageArea':
      return 'primary'
    case 'featureType':
      return 'warning'
    case 'topic':
      return 'info'
  }
}

const getTagChipTitle = (type) => {
  switch (type) {
    case 'coverageArea':
      return 'Coverage Area'
    case 'featureType':
      return 'Feature Type'
    case 'topic':
      return 'Topic'
  }
}

const useTags = (tags) => {
  return useMemo(() => {
    return sortBy(tags, (tag) =>
      tag?.type ? sortOrder.indexOf(tag?.type) : sortLast
    ).filter(Boolean)
  }, [tags])
}

const Tag = (props) => {
  const { tag, href, size, onClick, ...rest } = props
  return (
    <Tooltip
      title={getTagChipTitle(tag.type)}
      {...rest}
    >
      <Chip
        href={href}
        component={NextLink}
        variant='default'
        size={size}
        label={tag?.name}
        color={getColorName(tag?.type)}
        onClick={(event) => onClick(event, tag)}
      />
    </Tooltip>
  )
}

export { TagsList, TagsListPropTypes, getColorName }
export default TagsList
