import { useAuth } from '~user-auth/contexts/auth'
import { Workspace } from '~user-auth/types/__generated/gql/graphql'

export type AccountWorkspace = Pick<
  Workspace,
  'id' | 'description' | 'createdAt' | 'name' | 'logo' | 'memberCollection'
>

export const useAuthWorkspaces = ({
  excludeDefaultWorkspace
}: {
  excludeDefaultWorkspace?: boolean
} = {}): AccountWorkspace[] => {
  const { user } = useAuth()

  const workspaces =
    user?.membershipCollection?.items
      ?.map((item) => item.workspace)
      ?.filter((w) =>
        excludeDefaultWorkspace ? w.id !== user.defaultWorkspaceId : true
      ) || []

  return workspaces as AccountWorkspace[]
}
