import { Notification } from '~ui-components/components/molecules/Notification'

const ComingSoonSnack = (props) => {
  const { open, onClose, ...rest } = props
  return (
    <Notification
      data-testid='ComingSoonSnack'
      variant='filled'
      severity='info'
      open={open}
      onClose={onClose}
      {...rest}
    >
      Coming soon!
    </Notification>
  )
}

export { ComingSoonSnack }
export default ComingSoonSnack
