import React from 'react'
import PropTypes from 'prop-types'
import { Pagination } from '@mui/material'

const OpenSearchPagination = (props) => {
  const { from, size, total, onChange } = props

  const page = Math.floor((from + size) / size)
  const count = size && total ? Math.ceil(total / size) : 0

  const handleChange = (event, page) => {
    const nextFrom = (page - 1) * size
    onChange(event, nextFrom)
  }

  return (
    <Pagination
      shape='circular'
      size='small'
      count={count}
      page={page}
      onChange={handleChange}
    />
  )
}

OpenSearchPagination.propTypes = {
  from: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func
}

export { OpenSearchPagination }
export default OpenSearchPagination
