import { Box, IconButton, SvgIcon } from '@mui/material'
import Menu01 from '@untitled-ui/icons-react/build/esm/Menu01'
import PropTypes from 'prop-types'

const MenuButton = (props) => {
  const { sx, ...rest } = props

  return (
    <Box
      component={IconButton}
      sx={[
        {
          borderRadius: '99px',
          width: '32px',
          height: '32px'
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...rest}
    >
      <SvgIcon>
        <Menu01 />
      </SvgIcon>
    </Box>
  )
}

MenuButton.propTypes = {
  onClick: PropTypes.func
}

export { MenuButton }
export default MenuButton
