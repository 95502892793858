import _ from 'lodash'
import { GEOJSON_ERRORS } from '../assets/constants'

export const sanitizeGeojsonFile = (geojson, fileName, errors) => {
  const clonedGeojson = _.cloneDeep(geojson)
  const numberOfFeatures = clonedGeojson?.features?.length

  const hasErrorType =
    !_.isEmpty(errors) &&
    _.valuesIn(errors).every((value) => _.isEqual(value.type, 'error'))

  const missingGeometries = _.get(
    errors,
    [GEOJSON_ERRORS.MISSING_MEMBER_GEOMETRY.key, 'errors'],
    []
  )
  const numberOfMissingGeometry = _.size(missingGeometries)

  if (hasErrorType || numberOfMissingGeometry >= numberOfFeatures) {
    return { data: null, file: null }
  }

  if (numberOfMissingGeometry > 0) {
    //Remove features with empty geometry
    clonedGeojson.features = clonedGeojson.features.filter(
      (feature) => !_.isEmpty(feature.geometry)
    )
  }

  //Create new blob contains cleaned up features
  const blob = new File([JSON.stringify(clonedGeojson)], fileName, {
    type: 'application/geo+json'
  })

  return { data: clonedGeojson, file: blob }
}
