import _ from 'lodash'
import {
  CATEGORY_SELECTION_MAP_COLORS,
  LAYER_OUTLINE_COLORS
} from '../../assets/Colors'
import { getFilterPayload } from '../filterStyle'

const getCategoryColorRampsAndValue = (jenks) => {
  const values: Array<any> = []
  const colors = CATEGORY_SELECTION_MAP_COLORS
  for (let i = 0; i < jenks.length; i++) {
    values.push(jenks[i])
    values.push(colors[i])
  }
  values.push(LAYER_OUTLINE_COLORS[0])
  return values
}

const getCategoryStyle = (property, jenks, filterArray?) => {
  return {
    type: 'fill',
    paint: {
      'fill-color': [
        'match',
        ['get', property],
        ...getCategoryColorRampsAndValue(jenks)
      ],
      'fill-outline-color': LAYER_OUTLINE_COLORS[0]
    },
    ...getFilterPayload(filterArray)
  }
}

const getCategoryColorRamps = (jenks) => {
  const colors = CATEGORY_SELECTION_MAP_COLORS

  return _.take(colors, jenks.length)
}

export {
  getCategoryColorRamps,
  getCategoryColorRampsAndValue,
  getCategoryStyle
}
