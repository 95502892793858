import { Box, DrawerProps, Paper, SxProps } from '@mui/material'
import { FilterFunnel01 } from '@untitled-ui/icons-react'

import { Sidebar } from '~ui-components/components/molecules/Sidebar'

export interface MapFilterProps {
  isEmbed?: boolean
  filters: any[]
  filterResults: any[]
  sx?: SxProps
  open: boolean
  onClose: () => void
  slots: Record<string, any>
  slotProps: Record<string, any>
  drawerProps?: DrawerProps
}

const defaultSlots = {
  Selector: () => null
}

const MapFilter = (props: MapFilterProps) => {
  const {
    sx = {},
    isEmbed = false,
    open,
    slots = {},
    slotProps = {},
    onClose,
    filters = [],
    filterResults = [],
    drawerProps,
    ...rest
  } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }
  return (
    <Sidebar
      isEmbed={isEmbed}
      closeAfterTransition
      anchor='right'
      icon={FilterFunnel01}
      title={`Filters ${
        filterResults.length > 0 ? `(${filterResults.length})` : ''
      }`}
      open={open}
      onClose={onClose as any}
      ModalProps={{
        disablePortal: true,
        hideBackdrop: true,
        sx: {
          top: 'unset',
          right: 'unset',
          bottom: 'unset',
          left: 'unset'
        }
      }}
      PaperProps={{
        elevation: 24,
        sx: {
          maxWidth: '100%',
          width: 390,
          overflow: 'hidden',
          left: 'var(--sidebar-offset-left)'
        }
      }}
      {...drawerProps}
    >
      <Box
        component={Paper}
        variant='outlined'
        sx={[
          (theme) => ({
            display: 'flex',
            flexDirection: 'column',
            minHeight: '158px',
            border: 0
          }),
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
        {...rest}
      >
        <Box
          flex={1}
          sx={[
            (theme) => ({
              p: 1,
              [theme.breakpoints.down('mobile')]: {
                p: 1
              }
            })
          ]}
        >
          <Slots.Selector {...slotProps?.Selector} />
        </Box>
      </Box>
    </Sidebar>
  )
}

export { MapFilter }
