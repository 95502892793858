import {
  createTheme as createMuiTheme,
  responsiveFontSizes
} from '@mui/material/styles'
import { ThemeConfig } from '~ui-components/types'
import { createBaseTheme } from './base'
import { createDarkTheme } from './dark'
import { createLightTheme } from './light'

const createTheme = (config: ThemeConfig) => {
  let theme = createMuiTheme(
    // Base options available for both dark and light palette modes
    createBaseTheme({
      direction: config.direction
    }),
    // Options based on selected palette mode, color preset and contrast
    config.paletteMode === 'dark'
      ? createDarkTheme({
          colorPreset: config.colorPreset,
          contrast: config.contrast
        })
      : createLightTheme({
          colorPreset: config.colorPreset,
          contrast: config.contrast
        })
  )
  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}

export { createTheme }
export default createTheme
