import { isEmpty } from 'lodash'
import { WorkspaceSaveToInput as Input } from 'ui-components'
import { useAuthWorkspaces } from 'user-auth'
import { Workspace } from '~map-viewer/types/__generated/gql/graphql'
import { Field } from '../Form'

const WorkspaceSaveToInput = () => {
  const workspaces = useAuthWorkspaces()

  return (
    <Field
      name='workspace'
      defaultValue={(workspaces[0] as Workspace) ?? null}
      validators={{
        onMount: () => {
          return isEmpty(workspaces) ? 'There is no workspaces' : undefined
        },
        onChange: ({ value }) => {
          return value ? undefined : 'Please select a workspace'
        }
      }}
    >
      {(field) => {
        return (
          <Input
            // Hide the input if there is only one workspace or none
            hidden={workspaces.length <= 1}
            // We disable the input when there is only one item or no item
            // and when it is submitting
            disabled={workspaces.length <= 1 || field.form.state.isSubmitting}
            value={field.state.value}
            options={workspaces as Array<Workspace>}
            onChange={(event, value) => field.handleChange(value as any)}
          />
        )
      }}
    </Field>
  )
}

export { WorkspaceSaveToInput }
