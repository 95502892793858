import React from 'react'
import PropTypes from 'prop-types'
import Row from '../Row'
import Column from '../Column'

const Item = (props) => {
  const {
    p,
    pt,
    pb,
    pl,
    pr,
    border,
    borderRadius,
    borderColor,
    backgroundColor,
    style,
    align,
    valign,
    children
  } = props

  const columnProps = {
    p,
    pt,
    pr,
    pb,
    pl,
    border,
    borderColor,
    borderRadius,
    backgroundColor,
    style,
    align,
    valign
  }

  return (
    <Row>
      <Column {...columnProps}>{children}</Column>
    </Row>
  )
}

export { Item }
export default Item

Item.propTypes = {
  p: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  border: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  style: PropTypes.object,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  valign: PropTypes.oneOf(['top', 'middle', 'bottom']),
  children: PropTypes.node
}
