import { get } from 'idb-keyval'
import fetch from 'map-data-fetch'
import { v4 as uuidv4 } from 'uuid'
import { useMap } from '~map-viewer/states/map'

const useDownloadGeojson = () => {
  const map = useMap()

  const fetchGeojson = async () => {
    const dataId = map.dataId
    const geojson = await fetch(`public/${dataId}`, 'geojson')
    geojson.features.forEach((feature) => {
      feature.properties.msuuid ??= uuidv4()
    })
    return geojson
  }

  const downloadGeojson = async () => {
    try {
      const geojson = map?.dataId ? await get(map.dataId) : await fetchGeojson()
      const filename = map.name + '.geojson'
      createDownloadLink(filename, JSON.stringify(geojson))
    } catch (error) {
      console.error(error)
    }
  }

  return { downloadGeojson }
}

const createDownloadLink = (name, data) => {
  if (typeof document === 'undefined') return
  // eslint-disable-next-line no-undef
  const blob = new Blob([data], { type: 'application/json' })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = name
  link.href = url
  link.click()
  window.URL.revokeObjectURL(url)
}

export { createDownloadLink, useDownloadGeojson }
export default useDownloadGeojson
