import { pick } from 'lodash'
import { useDispatch } from '~map-feature-info/states'
import {
  clearSelectedFeature,
  setSelectedFeature
} from '~map-feature-info/states/map'

const useFeatureIdentify = () => {
  const dispatch = useDispatch()

  const identify = (feature) => {
    if (!feature) return
    const value = pick(feature, 'properties', 'id', 'geometry')
    dispatch(setSelectedFeature(value))
  }

  const clear = () => {
    dispatch(clearSelectedFeature())
  }

  return { identify, clear }
}

export { useFeatureIdentify }
