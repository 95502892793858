import { Autocomplete, Box, Chip, TextField } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { getKeyCode } from 'utils'

const KEY_BACKSPACE = 8
const KEY_ENTER = 13
const KEY_SPACE = 32
const KEY_COMMA = 188
/* USE FOR MOBILE BROWSER CHAR CODE DETECTION FROM TEXT VALUE */
const KEY_COMMA_DERIVED_FROM_STRING = 44
const KEYS_ALLOWED_TO_REGISTER = [
  KEY_ENTER,
  KEY_SPACE,
  KEY_COMMA,
  KEY_COMMA_DERIVED_FROM_STRING
]

const validateEmail = (email) => {
  if (!isEmail(email)) throw Error('Email format is incorrect')
}

const EmailChipInput = (props) => {
  const {
    placeholder,
    disabled,
    keysToRegister = KEYS_ALLOWED_TO_REGISTER,
    values = [],
    onChange = () => {},
    onValidate = () => {},
    ...rest
  } = props

  const [currentEmail, setCurrentEmail] = useState('')
  const [error, setError] = useState('')

  const addEmail = (unsafeEmail) => {
    const newEmail = unsafeEmail.trim()
    const nextEmails = values.includes(newEmail)
      ? values
      : [...values, newEmail]
    onChange(nextEmails)
    setCurrentEmail('')
    setError('')
  }

  const removeLastEmail = () => {
    const nextEmails = [...values]
    nextEmails.pop()
    onChange(nextEmails)
  }

  const removeEmail = (email) => {
    const nextEmails = [...values].filter((value) => value !== email)
    onChange(nextEmails)
  }

  const handleInputChange = (_, value) => {
    /* DETECT KEY CODE FROM INPUT VALUE, SINCE MOBILE BROWSER INPUT WONT SEND KEYCODE */
    const keyCode = getKeyCode(value.charAt(value.length - 1))
    if (KEYS_ALLOWED_TO_REGISTER.includes(keyCode))
      return addEmail(value.replaceAll(/,| /gi, ''))

    setCurrentEmail(value)
  }

  const handleInputKeyDown = (event) => {
    const currentKey = event.keyCode
    const email = event.target.value
    if (currentKey === KEY_BACKSPACE && isEmpty(email)) {
      removeLastEmail()
      return event
    }
    if (!keysToRegister.includes(currentKey)) {
      return event
    }
    event.preventDefault()
    event.stopPropagation()
    try {
      validateEmail(email)
      onValidate(email)
    } catch (error) {
      setError(error.message)
      return event
    }
    addEmail(email)
  }

  const handleDeleteClick = (email) => {
    removeEmail(email)
  }

  return (
    <Box
      {...rest}
      component={Autocomplete}
      fullWidth
      data-testid='EmilChipInput-Autocomplete'
      sx={{
        '.MuiInputBase-root.MuiOutlinedInput-root': {
          alignItems: 'flex-start',
          justifyContent: 'flex-start'
        }
      }}
      multiple
      options={[]}
      value={values}
      inputValue={currentEmail}
      onInputChange={handleInputChange}
      disableClearable
      freeSolo
      renderTags={(values, getTagProps) =>
        values.map((value, index) => (
          <Chip
            {...getTagProps({ index })}
            variant='default'
            key={index}
            data-testid={`ChipEmail-${value}`}
            label={value}
            onDelete={() => handleDeleteClick(value)}
            disabled={disabled}
          />
        ))
      }
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant='outlined'
            autoFocus
            multiline
            rows={5}
            disabled={disabled}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'EmailChipInput'
            }}
            FormHelperTextProps={{
              'data-testid': 'EmailChipInput-FormHelperText'
            }}
            placeholder={isEmpty(values) ? placeholder : ''}
            error={!isEmpty(error)}
            helperText={error}
            onKeyDown={handleInputKeyDown}
          />
        )
      }}
    />
  )
}

const EmailChipInputPropTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  keysToRegister: PropTypes.array,
  values: PropTypes.array,
  onChange: PropTypes.func,
  onValidate: PropTypes.func
}

EmailChipInput.propTypes = EmailChipInputPropTypes

export { EmailChipInput, EmailChipInputPropTypes }
export default EmailChipInput

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const isEmail = (string) => String(string).match(EMAIL_REGEX)
