import { Divider } from '@mui/material'

const Hr = (props) => {
  const { sx, ...rest } = props
  return (
    <Divider
      {...rest}
      sx={[
        {
          my: 2,
          borderBottomWidth: '3px',
          borderRadius: '99px'
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    />
  )
}

export { Hr }
export default Hr
