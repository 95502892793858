import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { getInviteLink } from 'utils'
import { mapstack32pxPng } from '~ui-components/assets/emails/logos'
import { Box, Image, Item } from '~ui-components/emails/components/atoms'
import { Email } from '~ui-components/emails/components/molecules'
import {
  Footer,
  InvitationCopy,
  MapstackInfoCopy,
  MemberJoinNow,
  MembersList
} from '~ui-components/emails/components/organisms'
import { assetUrlOf } from '~ui-components/emails/helpers/assetUrlOf'

const MemberInvitation = (props) => {
  const { workspace } = props

  const inviteLink = useMemo(
    () =>
      getInviteLink({
        workspaceId: workspace?.id,
        workspaceName: workspace?.name,
        inviteId: workspace?.inviteId
      }),
    [workspace]
  )

  const owner = useMemo(() => {
    if (!workspace) return {}
    return workspace?.memberCollection?.items?.find(
      (member) => member.role === 'owner'
    )?.user
  }, [workspace])

  const members = useMemo(() => {
    if (!workspace) return []
    return (
      workspace?.memberCollection?.items
        .filter((member) => member.role !== 'owner')
        .map((member) => member.user) || []
    )
  }, [workspace])

  return (
    <Email title='Do you want to join my workspace?'>
      <Item
        pt={48}
        align='left'
      >
        <Image
          src={assetUrlOf(mapstack32pxPng)}
          alt='mapstack logo'
          width='auto'
          height={32}
          borderRadius='rounded'
        />
      </Item>
      <Item pt={32}>
        <InvitationCopy
          workspace={workspace}
          owner={owner}
        />
      </Item>
      <Item pt={32}>
        <Box
          fullWidth
          border='solid 1px'
          borderColor='rgba(0, 0, 0, 0.2)'
          borderRadius={4}
        >
          <Item>
            <MemberJoinNow
              inviteLink={inviteLink}
              workspace={workspace}
              owner={owner}
            />
          </Item>
          <Item>
            <div
              style={{
                height: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.2)'
              }}
            />
          </Item>
          <Item>
            <MembersList
              owner={owner}
              members={members}
            />
          </Item>
        </Box>
      </Item>
      <Item pt={32}>
        <MapstackInfoCopy />
      </Item>
      <Item
        pt={56}
        pb={56}
      >
        <Footer />
      </Item>
    </Email>
  )
}

MemberInvitation.propTypes = {
  workspace: PropTypes.object
}

export { MemberInvitation }
export default MemberInvitation
