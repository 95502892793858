import {
  Box,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import InfoCircle from '@untitled-ui/icons-react/build/esm/InfoCircle'

const GestureInstruction = () => {
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  if (isBrowser) return null

  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        px: 1,
        py: 0.5,
        bottom: 8,
        left: 8,
        bgcolor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        gap: 0.5
      })}
    >
      <SvgIcon sx={{ fontSize: '16px' }}>
        <InfoCircle />
      </SvgIcon>
      <Typography
        variant='caption'
        color='inherit'
      >
        Use two fingers drag to pan the map
      </Typography>
    </Box>
  )
}

class GestureInstructionPlugin {
  pluginStore

  getPluginName() {
    return 'GestureInstruction@0.1.0'
  }

  getDependencies() {
    return []
  }

  init(pluginStore) {
    this.pluginStore = pluginStore
  }

  activate() {
    if (typeof window !== 'undefined') {
      this.pluginStore.executeFunction(
        'Renderer.add',
        'gestureInstruction',
        () => <GestureInstruction />
      )
    }
  }

  deactivate() {}
}

export default GestureInstructionPlugin
