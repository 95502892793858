import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  setMapName,
  useCoverageArea,
  useFeatureType,
  useMapName
} from '~map-creator/core/states/map'
import {
  useConfigurations,
  useDefaultVisualisationPropertyKey
} from '~map-creator/core/states/properties'

import { isEmpty } from 'lodash'
import View from './view'

const MapNameInput = () => {
  const dispatch = useDispatch()
  const mapName = useMapName()
  const { name: coverageAreaName } = useCoverageArea()
  const { name: featureTypeName } = useFeatureType()
  const configurations = useConfigurations()
  const defaultVisualisationKey = useDefaultVisualisationPropertyKey()

  useEffect(() => {
    if (!isEmpty(mapName)) return

    const joinedName = [
      coverageAreaName,
      configurations[defaultVisualisationKey]?.name,
      'by',
      featureTypeName,
      'Map'
    ]
      .join(' ')
      .trim()
    dispatch(setMapName(joinedName))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (e) => {
    const value = e?.target?.value || ''
    dispatch(setMapName(value))
  }

  return (
    <View
      value={mapName}
      onChange={handleChange}
    />
  )
}

export { MapNameInput }
export default MapNameInput
