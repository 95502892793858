const safeUrlOf = (unsafeUrl) => {
  return unsafeUrl.replace(/([^:]\/)\/+/g, '$1')
}

const assetUrlOf = (assetPath) => {
  const prefix = process.env.ASSETS_UPLOAD_CLOUDFRONT_URL
  const url = prefix ? [prefix, assetPath] : [assetPath]
  return safeUrlOf(url.join('/'))
}

export { assetUrlOf }
export default assetUrlOf
