/* eslint-disable react-hooks/rules-of-hooks */
import { includes } from 'lodash'
import { useCurrentWizardStep } from '~map-creator/core/states/map'
import { useStepIndexes } from '~map-creator/core/states/ui'

export const getDefaultStyle = () => {
  const currentWizardStep = useCurrentWizardStep()
  const stepIndexes = useStepIndexes()
  const {
    DEFINE_MAP_FEATURE,
    INCLUDE_PROPERTIES,
    UPLOAD_SUCCESS,
    DEFINE_FEATURE_TITLE,
    SELECT_UNIQUE_IDENTIFIER
  } = stepIndexes

  const visibleSteps = [
    UPLOAD_SUCCESS,
    DEFINE_MAP_FEATURE,
    INCLUDE_PROPERTIES,
    DEFINE_FEATURE_TITLE,
    SELECT_UNIQUE_IDENTIFIER
  ]

  const styleVisibleStep = includes(visibleSteps, currentWizardStep)

  return {
    visibility: styleVisibleStep ? 'visible' : 'none',
    style: {
      type: 'line',
      paint: {
        'line-width': 2,
        'line-color': '#42a5f5'
      }
    }
  }
}
