import { geoTypes } from '../geoTypes'
import { GEOJSON_ERRORS } from '../../assets/constants'
import { isJsonObject } from '../../helpers/isJsonObject'

/**
 * Determines if an object is a Geometry Object or not
 * @method isGeometryObject
 * @param geometryObject {Object}
 * @return {Boolean}
 */
export const isGeometryObject = (geometryObject) => {
  if (!isJsonObject(geometryObject)) {
    return [
      [
        GEOJSON_ERRORS.NOT_JSON_OBJECT.key,
        GEOJSON_ERRORS.NOT_JSON_OBJECT.description
      ]
    ]
  }

  const errors = []
  if ('type' in geometryObject) {
    if (geoTypes[geometryObject.type]) {
      return geoTypes[geometryObject.type](geometryObject)
    } else {
      errors.push([
        GEOJSON_ERRORS.NOT_SUPPORTING_TYPE.key,
        GEOJSON_ERRORS.NOT_SUPPORTING_TYPE.description
      ])
    }
  } else {
    errors.push([
      GEOJSON_ERRORS.MISSING_MEMBER_TYPE.key,
      GEOJSON_ERRORS.MISSING_MEMBER_TYPE.description
    ])
  }
  return errors
}
