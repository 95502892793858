import { Box, Button, TextField } from '@mui/material'
import { FormHeader } from '../FormHeader'

interface RegisterFormProps {
  email?: string
  slots?: {
    Form?: React.ElementType
    FirstNameInput?: React.ElementType
    LastNameInput?: React.ElementType
    Button?: React.ElementType
  }
  slotProps?: {
    Form?: any
    FirstNameInput?: any
    LastNameInput?: any
    Button?: any
  }
}

const defaultSlots = {
  Form: 'form',
  FirstNameInput: TextField,
  LastNameInput: TextField,
  Button: Button
}

function RegisterForm(props: RegisterFormProps) {
  const { email, slots, slotProps } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <>
      <FormHeader
        title='Create your account'
        subtitle={`You're creating a new Mapstack account for ${email}`}
      />

      <Box
        component={Slots.Form}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
        {...slotProps?.Form}
      >
        <Slots.FirstNameInput
          suppressHydrationWarning
          label='First name'
          {...slotProps?.FirstNameInput}
        />

        <Slots.LastNameInput
          suppressHydrationWarning
          label='Last name'
          {...slotProps?.LastNameInput}
        />

        <Slots.Button
          variant='contained'
          type='submit'
          size='large'
          sx={{
            borderRadius: 99
          }}
          {...slotProps?.Button}
        >
          Continue
        </Slots.Button>
      </Box>
    </>
  )
}

export { RegisterForm }
