import paddingOf from '~ui-components/utilities/paddingOf'

const Column = (props) => {
  const {
    p,
    pt,
    pb,
    pl,
    pr,
    border,
    borderRadius,
    borderColor,
    backgroundColor,
    style,
    align,
    valign,
    children
  } = props

  return (
    <td
      align={align}
      valign={valign}
      style={{
        border: border || 'none',
        ...(borderColor ? { borderColor } : {}),
        ...(borderRadius ? { borderRadius } : {}),
        ...(backgroundColor ? { backgroundColor } : {}),
        ...paddingOf({ p, pt, pr, pb, pl }),
        ...style
      }}
    >
      {children}
    </td>
  )
}

export { Column }
export default Column
