import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef } from 'react'

const throwFakeErrorToFoolNextRouter = () => {
  // Throwing an actual error class trips the Next.JS 500 Page, this string literal does not.
  // eslint-disable-next-line no-throw-literal
  throw 'Abort route change due to unsaved changes in application. Triggered by usePreventNavigation. Please ignore this error.'
}

/** @returns {Window?} */
const getWindow = () => typeof window !== 'undefined' && window

/** @returns {History.state?} */
const getHistory = () => getWindow()?.history?.state

/**
 * Hook to prevent navigation away from a page.
 * @see https://github.com/vercel/next.js/issues/2476
 * @see https://github.com/vercel/next.js/discussions/32231
 */
const usePreventNavigation = (options) => {
  const { enable, message, onRouteChangeStart } = options

  const router = useRouter()
  const lastHistory = useRef(getHistory())

  useEffect(() => {
    const storeLastHistoryState = () => {
      lastHistory.current = getHistory()
    }

    router.events.on('routeChangeComplete', storeLastHistoryState)

    return () => {
      router.events.off('routeChangeComplete', storeLastHistoryState)
    }
  }, [router])

  /**
   * @experimental HACK - idx is not documented
   * Determines which direction to travel in history.
   */
  const revertTheChangeRouterJustMade = useCallback(() => {
    const state = lastHistory.current

    if (
      state !== null &&
      history.state !== null &&
      state.idx !== history.state.idx
    ) {
      const delta = lastHistory.current.idx < history.state.idx ? -1 : 1
      history.go(delta)
    }
  }, [])

  const killRouterEvent = useCallback(() => {
    router.events.emit('routeChangeError')
    revertTheChangeRouterJustMade()
    throwFakeErrorToFoolNextRouter()
  }, [revertTheChangeRouterJustMade, router])

  useEffect(() => {
    let warned = false

    const routeChangeStart = (route) => {
      let abort = false
      onRouteChangeStart?.(route, (boolean) => (abort = boolean))

      if (abort) return

      if (router.asPath !== route && enable && !warned) {
        warned = true
        if (window.confirm(message)) {
          router.push(route)
          return
        }
        warned = false
        killRouterEvent()
      }
    }

    const beforeUnload = (e) => {
      if (enable && !warned) {
        const event = e ?? getWindow()?.event
        return (event.returnValue = message)
      }
      return null
    }

    router.events.on('routeChangeStart', routeChangeStart)
    getWindow()?.addEventListener('beforeunload', beforeUnload)

    return () => {
      router.events.off('routeChangeStart', routeChangeStart)
      getWindow()?.removeEventListener('beforeunload', beforeUnload)
    }
  }, [message, enable, killRouterEvent, router, onRouteChangeStart])
}

const useWindowBeforeUnload = (beforeUnloadHandler) => {
  useEffect(() => {
    getWindow()?.addEventListener('beforeunload', beforeUnloadHandler)
    return () => {
      getWindow()?.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [beforeUnloadHandler])
}

export { usePreventNavigation, useWindowBeforeUnload }
