import { Button, listItemButtonClasses } from '@mui/material'
import { Star01 } from '@untitled-ui/icons-react'
import { useState } from 'react'
import { formatNumberCompact } from 'utils'

import {
  IconListItem,
  IconListItemProps
} from '~ui-components/components/molecules/IconListItem'
import { UnauthenticatedActionTooltip } from '~ui-components/components/molecules/UnauthenticatedActionTooltip'

type MapStarButtonProps = IconListItemProps & {
  counts: number
  authenticated: boolean
  selected: boolean
  onSelect: () => void
  onUnselect: () => void
  onSignInClick: () => void
}

function MapStarButton(props: MapStarButtonProps) {
  const {
    counts,
    selected,
    authenticated,
    onSelect,
    onUnselect,
    onSignInClick,
    ...rest
  } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    if (!authenticated) {
      setAnchorEl(event.currentTarget)
      return
    }

    if (!selected) onSelect()
    else onUnselect()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <UnauthenticatedActionTooltip
        primary='Want to save this map for later?'
        secondary='Sign in to get started'
        anchorEl={anchorEl}
        onClose={handleClose}
        onSignInClick={onSignInClick}
      />

      <IconListItem
        sx={{
          [`.${listItemButtonClasses.selected}`]: {
            border: (theme) => `solid 1px ${theme.palette.warning.main}`
          }
        }}
        primary={formatNumberCompact(counts)}
        icon={Star01}
        iconProps={
          selected
            ? {
                sx: {
                  color: (theme) => theme.palette.warning.main
                }
              }
            : undefined
        }
        listItemButtonProps={
          selected
            ? ({
                component: Button,
                variant: 'outlined',
                color: 'warning',
                selected: true
              } as any)
            : undefined
        }
        primaryTypographyProps={
          selected
            ? {
                sx: {
                  color: (theme) => theme.palette.warning.main
                }
              }
            : undefined
        }
        onClick={handleClick}
        {...rest}
      />
    </>
  )
}

export { MapStarButton }
