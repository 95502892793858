import { Card, Tab, Tabs } from '@mui/material'
import { FC, SyntheticEvent } from 'react'

const defaultSlots = {
  MembersTabTools: () => null,
  MemberList: () => null,
  GuestList: () => null
}

export enum PEOPLE_TAB {
  MEMBERS = 'members',
  GUESTS = 'guests'
}

const MembersTab: FC<{
  membersCount: number
  guestsCount: number
  currentTab: PEOPLE_TAB
  onTabChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: PEOPLE_TAB
  ) => void
  slots: Record<keyof typeof defaultSlots, FC>
  slotProps?: Record<'membersTabTools' | 'memberList' | 'guestList', any>
}> = ({
  membersCount,
  guestsCount,
  onTabChange,
  currentTab,
  slots,
  slotProps
}) => {
  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Card>
      <Tabs
        indicatorColor='primary'
        onChange={onTabChange}
        scrollButtons='auto'
        sx={{ px: 3 }}
        textColor='primary'
        value={currentTab}
        variant='scrollable'
      >
        <Tab
          label={`Members (${membersCount})`}
          value='members'
        />
        <Tab
          label={`Guests (${guestsCount})`}
          value='guests'
        />
      </Tabs>

      {currentTab === PEOPLE_TAB.MEMBERS && (
        <>
          <Slots.MembersTabTools {...slotProps?.membersTabTools} />
          <Slots.MemberList {...slotProps?.memberList} />
        </>
      )}

      {currentTab === PEOPLE_TAB.GUESTS && (
        <Slots.GuestList {...slotProps?.guestList} />
      )}
    </Card>
  )
}

export { MembersTab }
