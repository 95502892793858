import { Card } from '@mui/material'

const defaultSlots = {
  MapsTabTools: () => null,
  MapList: () => null,
  MapCreateDrawer: () => null
}

const MapsTab = (props) => {
  const { slots = {}, slotProps = {} } = props

  const Slots = { ...defaultSlots, ...slots }

  return (
    <Card>
      <Slots.MapsTabTools {...slotProps?.MapsTabTools} />
      <Slots.MapList {...slotProps?.MapList} />
      <Slots.MapCreateDrawer {...slotProps?.MapCreateDrawer} />
    </Card>
  )
}

export { MapsTab }
export default MapsTab
