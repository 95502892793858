import { VT_SERVER_SOURCE } from '~map-viewer/assets/constant/LayerConstant'
import { useMap } from '~map-viewer/states/map'
import { View } from './view'

const VTServerLayer = (props) => {
  const map = useMap()

  return (
    <View
      {...props}
      tiles={[
        `${process.env.NEXT_PUBLIC_VT_SERVER_URL}/${map.dataId}/{z}/{x}/{y}.pbf`
      ]}
    />
  )
}

VTServerLayer.defaultProps = {
  type: 'vector',
  source: VT_SERVER_SOURCE
}

export { VTServerLayer }
