import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { MapActionFilter as View } from 'ui-components'
import { useFilters } from '~map-filter-view/states/filter'
import {
  openFilter,
  setFilterPreview,
  setFilterSelect
} from '~map-viewer/states/ui'

const Filter = () => {
  const dispatch = useDispatch()

  const filters = useFilters()

  const handleToggle = () =>
    isEmpty(filters)
      ? dispatch(setFilterSelect())
      : dispatch(setFilterPreview())

  return (
    <View
      active={!isEmpty(filters)}
      onToggle={() => handleToggle()}
      onClick={() => {
        dispatch(openFilter())
      }}
    />
  )
}

export { Filter }
export default Filter
