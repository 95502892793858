import React from 'react'
import { DefineFeatureTitle, DefineFeatureTitlePropTypes } from 'ui-components'

const View = (props) => {
  return <DefineFeatureTitle {...props} />
}

View.propTypes = DefineFeatureTitlePropTypes

export { View }
