import StateProvider from '~map-creator/map-creator-geojson/states/provider'

import { BaseMap } from '~map-creator/core/App/BaseMap'
import { CreateMapGeojson } from './CreateMap'
import View from './view'

const Layout = () => {
  return (
    <View
      slots={{
        Map: BaseMap,
        Form: CreateMapGeojson
      }}
    />
  )
}

const MapCreatorGeojsonApp = () => {
  return (
    <StateProvider>
      <Layout />
    </StateProvider>
  )
}

export { MapCreatorGeojsonApp }
