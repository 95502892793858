import { RemixButton as Button } from 'ui-components'
import { useField } from '../Form'

function RemixButton() {
  const { form } = useField({ name: 'submit' as any })

  return (
    <form.Subscribe
      selector={(state) => {
        return [state.canSubmit, state.isFieldsValid, state.isSubmitting]
      }}
    >
      {([canSubmit, isFieldsValid, isSubmitting]) => {
        return (
          <Button
            loading={isSubmitting}
            type='submit'
            disabled={!canSubmit || !isFieldsValid}
          />
        )
      }}
    </form.Subscribe>
  )
}

export { RemixButton }
