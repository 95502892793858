import { ThemeOptions } from '@mui/material'
import { sxConfig } from '../sxConfig'
import { defaultBorderRadius, system } from '../system'
import { createTypography } from '../typography'
import { createComponents } from './components'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    xxl: true
    mobile: true
  }
}

declare module '@mui/material/Skeleton' {
  interface SkeletonPropsVariantOverrides {
    pill: true
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconOwnProps {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  }
}

const lowerOrEqual = (value) => value + 1

const createBaseTheme = (config): ThemeOptions => {
  const { direction = 'ltr' } = config

  return {
    ...system,
    breakpoints: {
      values: {
        // default davias breakpoints
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
        xxl: 1800,
        // custom breakpoints
        mobile: lowerOrEqual(1024)
      }
    },
    typography: createTypography(),
    components: createComponents(),
    direction,
    shape: {
      borderRadius: defaultBorderRadius
    },
    unstable_sxConfig: sxConfig
  }
}

export { createBaseTheme }
