import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import SvgIcon from '@mui/material/SvgIcon'
import SearchMdIcon from '@untitled-ui/icons-react/build/cjs/SearchMd'
import type { FC } from 'react'
import React, { useRef } from 'react'

type MapSearchModalFormProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
}

export const MapSearchModalForm: FC<MapSearchModalFormProps> = (props) => {
  const { onChange = () => {} } = props
  const inputRef = useRef(null)

  const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = event.target.value
    onChange(event, nextValue)
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        pl: 2,
        pr: 2,
        pt: 0,
        pb: 1
      }}
      onSubmit={handleSubmit}
    >
      <OutlinedInput
        autoFocus
        onChange={handleChange}
        inputProps={{ 'data-testid': 'MapSearchModal-FormInput' }}
        inputRef={inputRef}
        autoComplete='off'
        fullWidth
        placeholder='Search...'
        startAdornment={
          <InputAdornment position='start'>
            <SvgIcon>
              <SearchMdIcon />
            </SvgIcon>
          </InputAdornment>
        }
      />
    </Box>
  )
}
