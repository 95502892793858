import { PlateElement } from '@udecode/plate-common'
import { useLink } from '@udecode/plate-link'
import clsx from 'clsx'
import { forwardRef } from 'react'

import { Link } from '@mui/material'

const LinkElement = forwardRef((props, ref) => {
  const { element } = props
  const { className, children, ...rest } = props

  const { props: linkProps } = useLink({ element })

  return (
    <PlateElement
      asChild
      ref={ref}
      className={clsx(className)}
      {...linkProps}
      {...rest}
    >
      <Link
        color='secondary'
        href={element?.url}
        sx={{
          fontSize: 'inherit',
          fontWeight: 'inherit',
          fontStyle: 'inherit'
        }}
      >
        {children}
      </Link>
    </PlateElement>
  )
})
LinkElement.displayName = 'LinkElement'

export { LinkElement }
export default LinkElement
