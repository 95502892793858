import {
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
  SvgIcon,
  SvgIconProps
} from '@mui/material'
import { isValidElement } from 'react'

export type IconListItemProps = ListItemProps & {
  selected?: boolean
  primary?: string
  icon?: any
  iconProps?: SvgIconProps
  listItemButtonProps?: ListItemButtonProps
  primaryTypographyProps?: ListItemTextProps['primaryTypographyProps']
}

function IconListItem(props: IconListItemProps) {
  const {
    sx,
    primary,
    icon: Icon,
    iconProps,
    listItemButtonProps,
    primaryTypographyProps,
    ...rest
  } = props

  return (
    <ListItem
      disablePadding
      disableGutters
      sx={[
        {
          width: 64,
          height: 64,
          maxWidth: 64,
          maxHeight: 64,
          borderRadius: 1,
          overflow: 'hidden'
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...rest}
    >
      <ListItemButton
        sx={[
          {
            p: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'inherit'
          }
        ]}
        {...listItemButtonProps}
      >
        <ListItemIcon sx={{ mr: 0, mb: primary ? 1 : 0 }}>
          {isValidElement(Icon) ? (
            Icon
          ) : (
            <SvgIcon {...iconProps}>
              <Icon />
            </SvgIcon>
          )}
        </ListItemIcon>

        {primary && (
          <ListItemText
            primary={primary}
            sx={{
              p: 0,
              m: 0,
              flex: 'unset'
            }}
            primaryTypographyProps={{
              variant: 'overline',
              color: 'text.secondary',
              textTransform: 'capitalize',
              lineHeight: 1,
              letterSpacing: 0,
              ...primaryTypographyProps
            }}
          ></ListItemText>
        )}
      </ListItemButton>
    </ListItem>
  )
}

export { IconListItem }
