import { createResetNodePlugin, insertBreak } from '@udecode/plate'
import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote'
import {
  ELEMENT_CODE_BLOCK,
  isCodeBlockEmpty,
  isSelectionAtCodeBlockStart,
  unwrapCodeBlock
} from '@udecode/plate-code-block'
import {
  isBlockAboveEmpty,
  isSelectionAtBlockStart
} from '@udecode/plate-common'
import {
  ELEMENT_TODO_LI,
  ELEMENT_OL,
  ELEMENT_UL,
  unwrapList
} from '@udecode/plate-list'
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph'

const resetBlockTypePlugin = {
  options: {
    rules: [
      {
        hotkey: 'Backspace',
        predicate: isBlockAboveEmpty,
        types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
        defaultType: ELEMENT_PARAGRAPH
      },
      {
        hotkey: 'Backspace',
        predicate: isSelectionAtBlockStart,
        types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
        defaultType: ELEMENT_PARAGRAPH
      },
      {
        hotkey: 'Backspace',
        predicate: isSelectionAtCodeBlockStart,
        types: [ELEMENT_CODE_BLOCK],
        defaultType: ELEMENT_PARAGRAPH,
        onReset: unwrapCodeBlock
      },
      {
        hotkey: 'Backspace',
        predicate: isBlockAboveEmpty,
        types: [ELEMENT_UL, ELEMENT_OL],
        onReset: unwrapList
      },
      {
        hotkey: 'Enter',
        predicate: isBlockAboveEmpty,
        types: [ELEMENT_TODO_LI],
        onReset: insertBreak
      },
      {
        hotkey: 'Enter',
        predicate: isCodeBlockEmpty,
        types: [ELEMENT_CODE_BLOCK],
        defaultType: ELEMENT_PARAGRAPH,
        onReset: unwrapCodeBlock
      }
    ]
  }
}

export default () =>
  createResetNodePlugin({
    ...resetBlockTypePlugin
  })
