import { useEffect } from 'react'

const useResizeCanvas = (map, height) => {
  useEffect(() => {
    if (map) {
      map.resize()
    }
  }, [map, height])
}

export { useResizeCanvas }
