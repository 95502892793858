import { createContext } from 'react'
import {
  createDispatchHook,
  createSelectorHook,
  createStoreHook
} from 'react-redux'

export const context = createContext({})
export const useDispatch = createDispatchHook(context as any)
export const useSelector = createSelectorHook(context as any)
export const useStore = createStoreHook(context as any)
