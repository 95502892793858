import PapaParse from 'papaparse'

const promisifyPaparse = (file, options) =>
  new Promise((resolve) => {
    return PapaParse.parse(file, {
      ...options,
      complete: (output) => {
        resolve(output)
      }
    })
  })

const valid = async (file, options) => {
  const output = await promisifyPaparse(file, {
    ...options,
    worker: true
  })
  if (output.errors.length) {
    return false
  }
  return true
}

const csv = {
  valid
}

export { valid }
export default csv
