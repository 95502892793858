import { MapHeader as Layout } from 'ui-components'
import { MapButtons } from './MapButtons'
import { MapMetadata } from './MapMetadata'
import { MapName } from './MapName'
import { TagsList } from './TagsList'

function MapHeader() {
  return (
    <Layout
      slots={{
        MapName,
        MapButtons,
        MapMetadata,
        TagsList
      }}
    />
  )
}

export { MapHeader }
