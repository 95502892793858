import { formatDate } from '../formatDate'
import { isDateType } from '../isDateType'
import { isNumericType } from '../isNumericType'
import { isStringType } from '../isStringType'

const formatNumber = (number) => {
  return Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1
  }).format(number)
}

const addPredicate = (filter) => {
  const template = '$1: '
  return template.replace('$1', filter.name)
}

const addNumericLabel = (filter) => {
  if (!isNumericType(filter.type)) return ''
  const template = '$1 - $2'
  return template
    .replace('$1', formatNumber(filter.value[0]))
    .replace('$2', formatNumber(filter.value[1]))
}

const addStringLabel = (filter) => {
  if (!isStringType(filter.type)) return ''
  const template = '$1'
  return template.replace('$1', filter.value.join(', '))
}

const addDateLabel = (filter) => {
  if (!isDateType(filter.type)) return ''
  const template = '$1 - $2'
  return template
    .replace('$1', formatDate(filter.value.minDate))
    .replace('$2', formatDate(filter.value.maxDate))
}

const getFilterLabel = (filter) => {
  let label = addPredicate(filter)
  label += addNumericLabel(filter)
  label += addStringLabel(filter)
  label += addDateLabel(filter)
  return label
}

export { getFilterLabel }
