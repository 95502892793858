import * as qs from 'qs'
import { slug } from '~utils/functions/slug'

enum USER_PROFILE_TAB {
  MAPS = 'maps',
  WORKSPACES = 'workspaces',
  SETTINGS = 'settings'
}

type FullnameOption = {
  userId: string
  firstName: string
  lastName: string
  tab?: string
  page?: number | string
}

type UsernameOption = {
  userId: string
  username: string
  tab?: string
  page?: number | string
}

type UserParams = FullnameOption | UsernameOption

const hasFullName = (params: UserParams): params is FullnameOption => {
  return 'firstName' in params && 'lastName' in params
}

const noPageTab = (tab) =>
  [USER_PROFILE_TAB.WORKSPACES, USER_PROFILE_TAB.SETTINGS].includes(tab)

const getUserLink = (params: UserParams) => {
  const { userId, tab, page } = params || {}

  const objectParams: Record<string, unknown> = {}

  if (tab) objectParams.tab = tab
  if (page) objectParams.page = page
  if (noPageTab(tab)) delete objectParams.page

  const query = qs.stringify(objectParams)
  const usernameSlug = hasFullName(params)
    ? slug([params.firstName, params.lastName].join(' '))
    : params.username

  return `/user/${userId}/${usernameSlug}` + (query ? `?${query}` : '')
}

export { getUserLink }
