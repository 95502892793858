import { Drawer, IconButton, Stack, SvgIcon, Typography } from '@mui/material'
import { Repeat01, X as XIcon } from '@untitled-ui/icons-react'
import React from 'react'
import { EmptySlotFallback } from '~ui-components/components/molecules/EmptySlotFallback'
import { RemixMaps } from '~ui-components/components/molecules/RemixMaps'
import { RemixPagination } from '~ui-components/components/molecules/RemixPagination'

interface MapRemixesDrawerProps {
  loading?: boolean
  hasMaps: boolean
  open: boolean
  onClose: () => void
  slots?: {
    RemixMaps?: React.ComponentType
    RemixPagination?: React.ComponentType
  }
  slotProps?: {
    RemixMaps?: React.ComponentProps<typeof RemixMaps>
    RemixPagination?: React.ComponentProps<typeof RemixPagination>
  }
}

const defaultSlots = {
  RemixMaps,
  RemixPagination
} as const

function MapRemixesDrawer(props: MapRemixesDrawerProps) {
  const { hasMaps, open, onClose, slots, slotProps } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Drawer
      anchor='right'
      onClose={onClose}
      open={open}
      slotProps={{
        backdrop: {
          invisible: true
        }
      }}
      ModalProps={{
        sx: { zIndex: 1400 }
      }}
      PaperProps={{
        elevation: 24,
        sx: {
          maxWidth: '100%',
          width: 390
        }
      }}
    >
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={3}
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Typography variant='h5'>Remixes</Typography>
        <Stack
          alignItems='center'
          direction='row'
          spacing={0.5}
        >
          <IconButton
            color='inherit'
            onClick={onClose}
          >
            <SvgIcon>
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Stack>

      {hasMaps ? (
        <>
          <Slots.RemixMaps
            {...(slotProps?.RemixMaps as React.ComponentProps<
              typeof RemixMaps
            >)}
          />

          <Stack
            direction='row'
            sx={{ px: 2, py: 2, justifyContent: 'flex-end' }}
          >
            <Slots.RemixPagination
              {...(slotProps?.RemixPagination as React.ComponentProps<
                typeof RemixPagination
              >)}
            />
          </Stack>
        </>
      ) : (
        <EmptySlotFallback
          sx={{ px: 3 }}
          text='There is no remix maps yet!'
          icon={Repeat01}
        />
      )}
    </Drawer>
  )
}

export { MapRemixesDrawer }
