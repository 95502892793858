import { Button, SvgIcon } from '@mui/material'
import FaceSmile from '@untitled-ui/icons-react/build/esm/FaceSmile'
import PropTypes from 'prop-types'

const SignUpButton = (props) => {
  const { variant = 'contained', onClick = () => {}, ...rest } = props

  return (
    <Button
      variant={variant}
      data-testid='SignUpButton'
      size='large'
      onClick={onClick}
      startIcon={
        <SvgIcon>
          <FaceSmile />
        </SvgIcon>
      }
      sx={{
        minWidth: 'fit-content',
        whiteSpace: 'nowrap'
      }}
      {...rest}
    >
      Sign Up
    </Button>
  )
}

SignUpButton.propTypes = {
  onClick: PropTypes.func
}

export { SignUpButton }
export default SignUpButton
