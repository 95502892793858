import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  FormLabel,
  IconButton,
  SvgIcon,
  Typography,
  styled
} from '@mui/material'
import UploadCloud01 from '@untitled-ui/icons-react/build/esm/UploadCloud01'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

const Root = styled(Box)({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
})

const Content = styled(Box)({})

const Input = styled('input')({
  display: 'none'
})

const Dropzone = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'dragging'
})(({ theme, dragging }) => ({
  width: '100%',
  position: 'relative',
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
  minHeight: '30dvh',
  borderRadius: '4px',
  border: '1px dashed',
  borderColor: theme.palette.divider,
  ...(dragging
    ? {
        backgroundColor: theme.palette.divider,
        borderColor: theme.palette.divider
      }
    : {}),
  transition: theme.transitions.create(['background-color', 'borderColor'], {
    duration: theme.transitions.duration.shorter
  }),
  '&:hover': {
    borderColor: theme.palette.divider
  }
}))

const DropContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  justifyContent: 'center',
  alignItems: 'center'
})

const FileInput = (props) => {
  const { label, accept, multiple, onValidate, onChange } = props

  const [loading, setLoading] = useState(false)
  const dragCount = useRef(0)
  const [dragging, setDragging] = useState(false)

  const dropRef = useRef(null)
  const inputRef = useRef(null)
  const uploadIconRef = useRef(null)
  const uploadButtonRef = useRef(null)

  const handleFileInputClick = () => {
    const uploadButton = uploadButtonRef.current
    if (uploadButton) uploadButton.click()
  }

  const handleUploadClick = (event) => {
    event.stopPropagation()
  }

  const handleFilesChange = async (files) => {
    setLoading(true)
    const { file } = await onValidate(files)
    if (file) {
      await onChange(file)
    } else {
      inputRef.current.value = null
    }
    setLoading(false)
  }

  const handleInputChange = async (events) => {
    const files = events.target.files
    await handleFilesChange(files)
  }

  const handleDrop = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    const { files } = event.dataTransfer
    if (files && files.length) {
      await handleFilesChange(files)
      event.dataTransfer.clearData()
      setDragging(false)
      dragCount.current = 0
    }
  }

  const handleDragEnter = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dragCount.current = dragCount.current + 1
    const isFilesDragging =
      event.dataTransfer.items && event.dataTransfer.items.length > 0
    if (isFilesDragging) {
      setDragging(true)
    }
  }

  const handleDragLeave = (event) => {
    event.preventDefault()
    event.stopPropagation()
    dragCount.current = dragCount.current - 1
    if (dragCount.current > 0) return
    setDragging(false)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  useEffect(() => {
    const drop = dropRef.current
    if (drop) {
      drop.addEventListener('dragenter', handleDragEnter, false)
      drop.addEventListener('dragleave', handleDragLeave, false)
      drop.addEventListener('dragover', handleDragOver, false)
      drop.addEventListener('drop', handleDrop, false)
    }

    return () => {
      if (drop) {
        drop.removeEventListener('dragenter', handleDragEnter, false)
        drop.removeEventListener('dragleave', handleDragLeave, false)
        drop.removeEventListener('dragover', handleDragOver, false)
        drop.removeEventListener('drop', handleDrop, false)
      }
    }
  }, [])

  return (
    <Root
      className='FileInput-root'
      data-testid='FileInput'
    >
      {!!label && (
        <FormLabel
          required
          data-testid='FileInput-Label'
          sx={{ typography: 'body2' }}
        >
          {label}
        </FormLabel>
      )}
      <Content mt={2}>
        <Dropzone
          data-testid='FileInput-DropZone'
          dragging={dragging}
          ref={dropRef}
          onClick={handleFileInputClick}
        >
          {loading && (
            <CircularProgress
              id='FileUploadSpinner'
              disableShrink
            />
          )}
          <DropContent sx={{ display: loading ? 'none' : 'flex' }}>
            <IconButton
              component='div'
              disableTouchRipple
            >
              <SvgIcon
                ref={uploadIconRef}
                fontSize='large'
              >
                <UploadCloud01 />
              </SvgIcon>
            </IconButton>
            <Typography variant='body2'>
              Drag and drop to upload file
            </Typography>
            <label htmlFor='FileInput'>
              <Input
                ref={inputRef}
                id='FileInput'
                type='file'
                accept={accept}
                multiple={multiple}
                onChange={handleInputChange}
              />
              <Button
                ref={uploadButtonRef}
                variant='contained'
                component='span'
                color='success'
                disableRipple
                disableTouchRipple
                onClick={handleUploadClick}
              >
                Upload
              </Button>
            </label>
          </DropContent>
        </Dropzone>
      </Content>
    </Root>
  )
}

FileInput.propTypes = {
  label: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onValidate: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func
}

FileInput.defaultProps = {
  label: '',
  accept: 'file/*',
  multiple: false,
  onChange: () => {},
  onDelete: () => {},
  onValidate: async (files) => {
    return { file: files[0] }
  }
}

export { FileInput }
