import { GEOJSON_ERRORS } from '../../assets/constants'

/**
 * Determines if an object is a position or not
 * @method isPosition
 * @param position {Array}
 * @return {Boolean}
 */
export const isPosition = (position) => {
  const errors = []

  // It must be an array
  if (Array.isArray(position)) {
    // and the array must have more than one element
    if (position.length <= 1) {
      // 'Position must be at least two elements'
      errors.push([
        GEOJSON_ERRORS.POSITION_MINIMUM_2_ELEMENTS.key,
        GEOJSON_ERRORS.POSITION_MINIMUM_2_ELEMENTS.description
      ])
    }

    position.forEach((pos, index) => {
      if (typeof pos !== 'number') {
        errors.push([
          GEOJSON_ERRORS.POSITION_MUST_CONTAIN_NUMBER_ONLY.key,
          GEOJSON_ERRORS.POSITION_MUST_CONTAIN_NUMBER_ONLY.description +
            pos +
            ' at index ' +
            index +
            ' is invalid.'
        ])
      }
    })
  } else {
    // 'Position must be an array'
    errors.push([
      GEOJSON_ERRORS.POSITION_MUST_BE_ARRAY.key,
      GEOJSON_ERRORS.POSITION_MUST_BE_ARRAY.description
    ])
  }
  return errors
}
