import { ResizeObserver } from '@juggle/resize-observer'
import { Masonry, useInfiniteLoader } from 'masonic'
import { getMapLink, getMapThumbnailLink, getWorkspaceLink } from 'utils'

import { EmptyMapResultFallback } from '../EmptyMapResultFallback'

import { useMemo, useRef } from 'react'
import usePrevious from '~ui-components/hooks/usePrevious'
import { Card } from '../Card'
import { CardSkeleton } from '../Card/Skeleton'

export const virtualizedMapGridConfig = {
  itemsPerRow: 4
}

globalThis.ResizeObserver = ResizeObserver

function VirtualizedMapGrid(props) {
  const {
    id,
    loading,
    items: initialItems = [],
    hasMoreItems,
    onEndReached
  } = props

  const items =
    loading && !initialItems.length
      ? Array.from({ length: 10 }, () => ({ loading: true }))
      : [
          ...initialItems,
          ...(hasMoreItems
            ? Array.from(
                { length: virtualizedMapGridConfig.itemsPerRow * 2 },
                () => ({ loading: true })
              )
            : [])
        ]

  const itemsCount = items?.length
  const prevItemsCount = usePrevious(itemsCount)

  const removesCount = useRef(0)

  const keyCount = useMemo(() => {
    if (!itemsCount || !prevItemsCount) return removesCount.current
    if (itemsCount < prevItemsCount) {
      removesCount.current += 1
      return removesCount.current
    }

    return removesCount.current
  }, [itemsCount, prevItemsCount])

  const handleEndReached = useInfiniteLoader(onEndReached, {
    isItemLoaded: (index, items) => !!items[index] && !hasMoreItems
  })

  if (!items.length) return <EmptyMapResultFallback />

  const key = [id, keyCount].join(':')

  return (
    <Masonry
      key={key}
      items={items}
      columnGutter={18}
      columnWidth={390}
      overscanBy={5}
      render={MasonicCard}
      onRender={handleEndReached}
    />
  )
}

function MasonicCard(props) {
  const { data: item } = props

  if (item.loading) {
    return <CardSkeleton />
  }

  return (
    <Card
      title={item.name}
      href={getMapLink({
        mapId: item.id,
        mapName: item.name
      })}
      cover={getMapThumbnailLink(item)}
      avatar={{
        image: item.workspace.logo ?? '',
        name: item.workspace.name ?? '',
        href: getWorkspaceLink({
          workspaceId: item.workspace.id,
          workspaceName: item.workspace.name
        }),
        updatedAt: item.updatedAt
      }}
      footer={{
        likes: item.likes ?? 0,
        views: item.views ?? 0
      }}
    />
  )
}

export { VirtualizedMapGrid }
