import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { dayjs } from '~utils/libs/dayjs'

dayjs.extend(LocalizedFormat)

const formatDate = (date) => {
  if (!date) return null
  return dayjs(String(date)).format('LL')
}

export { formatDate }
