import { BottomNavigation, Box, Fab, GlobalStyles } from '@mui/material'
import { FC } from 'react'

import { MapBarProps } from '..'

export const MobileMapBar: FC<MapBarProps> = ({
  slots: Slots,
  authenticated
}) => {
  return (
    <>
      <GlobalStyles
        styles={{
          ':root': {
            '--map-bar-height': '64px',
            '--sidebar-offset-bottom': '64px'
          }
        }}
      />

      {!authenticated && (
        <Box sx={{ top: 8, left: 8, position: 'absolute', zIndex: 3 }}>
          <Fab
            size='small'
            sx={{
              zIndex: 0,
              boxShadow: (theme) => theme.shadows[1],
              backgroundColor: (theme) => theme.palette.common.white
            }}
          >
            <Slots.NavButton />
          </Fab>
        </Box>
      )}

      <BottomNavigation
        component='ul'
        sx={{
          m: 0,
          p: 0,
          height: 'var(--map-bar-height)',
          '& li,span': { flex: 1, maxWidth: 'unset' }
        }}
      >
        <Slots.MapSearchButton />
        <Slots.MapFilterButton />
        <Slots.MapDetailsButton />
        <Slots.MapShareButton />
      </BottomNavigation>
    </>
  )
}
