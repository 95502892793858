import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useMapDimension } from '~map-creator/core/App/BaseMap/hooks/useMapDimension'
import { MAP_SOURCE } from '~map-creator/core/assets/constants'
import { useGeojsonFileId } from '~map-creator/core/states/geojson'
import {
  setPopupSelectedProperty,
  usePopupSelectedProperty
} from '~map-creator/core/states/map'

const useSelectedProperty = (map, isActivated) => {
  const dispatch = useDispatch()
  const geojsonFileId = useGeojsonFileId()
  const popupSelectedProperty = usePopupSelectedProperty()
  const { width, height } = useMapDimension()

  const isSelectPropertyAllowed = !!isActivated && !!geojsonFileId

  const setSelectedProperty = (newSelectedProperty) => {
    dispatch(setPopupSelectedProperty(newSelectedProperty))
  }

  useEffect(() => {
    if (!isSelectPropertyAllowed) {
      setSelectedProperty(null)
      return
    }

    if (!map) return

    if (popupSelectedProperty) return

    const feature = getSelectedFeature(map, width, height)

    if (!feature) return

    const coord = getCoords(feature)
    if (!coord) return

    const lngLat = { lng: coord[0], lat: coord[1] }
    const newSelectedProperty = { lngLat, feature }
    const serializedObj = JSON.parse(JSON.stringify(newSelectedProperty))

    setSelectedProperty(serializedObj)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectPropertyAllowed])

  const handleMapClick = (e) => {
    if (!isSelectPropertyAllowed) return

    const lngLat = e.lngLat
    const feature =
      e.features && e.features.length > 0 && !!e.features[0]
        ? e.features[0]
        : null

    if (!feature || feature.source !== MAP_SOURCE) return

    const serializedObj = JSON.parse(JSON.stringify({ lngLat, feature }))
    setSelectedProperty(serializedObj)
  }

  return { selectedProperty: popupSelectedProperty, handleMapClick }
}

const getSelectedFeature = (map, width, height) => {
  const featureCenteredWithPoint = map
    .queryRenderedFeatures([height / 2, width / 2])
    .filter((f) => f.source === MAP_SOURCE)[0]
  const featureCenteredWithBbox = map
    .queryRenderedFeatures([
      [width / 2, height / 2],
      [width / 2, height / 2]
    ])
    .filter((f) => f.source === MAP_SOURCE)[0]
  const firstFeatureInGeojson = map.querySourceFeatures(MAP_SOURCE, {
    sourceLayer: 'features',
    validate: false
  })[0]

  return (
    featureCenteredWithPoint || featureCenteredWithBbox || firstFeatureInGeojson
  )
}

const getCoords = (feature) => {
  switch (feature.geometry.type) {
    case 'Polygon':
      return feature.geometry.coordinates[0][0]
    case 'MultiPolygon':
      return feature.geometry.coordinates[0][0][0]
    default:
      return null
  }
}

export { useSelectedProperty }
