import { SxProps, ToggleButtonGroup } from '@mui/material'
import { clsx } from 'clsx'
import { find, get, isEmpty, isNull } from 'lodash'
import React from 'react'
import { ToggleChip, ToggleChipProps } from './ToggleChip'

interface ToggleChipData {
  id: string
  label: string
  value: string
  extra: Record<string, unknown>
}

type ToggleChipInputProps = React.ComponentProps<typeof ToggleButtonGroup> &
  ToggleChipProps & {
    data: ToggleChipData[]
    defaultValue?: string | string[]
    value?: string | string[]
    onChange: (value: string | string[], extra: Record<string, unknown>) => void
    multiple?: boolean

    className?: string
    sx?: SxProps
    fullWidth?: boolean
    size?: 'small' | 'medium' | 'large'
  }

const ToggleChipInput = (props: ToggleChipInputProps) => {
  const {
    className,
    sx,
    data,
    defaultValue,
    value,
    fullWidth = false,
    size = 'medium',
    onChange,
    multiple = false,
    ChipContentProps,
    ChipRootProps,
    ...rest
  } = props

  if (isEmpty(data)) return null

  if (multiple && !Array.isArray(value) && !Array.isArray(defaultValue)) {
    throw Error(
      'ToggleChipInput: required value and defaultValue to be array when using multiple'
    )
  }

  const handleChange = (_, value) => {
    // Value could be string or array
    // If multiple is true, value is array
    // If multiple is false, value is string
    if (!isNull(value)) {
      if (!multiple) {
        const selectedItem = find(data, (item) => item.value === value)
        const extra = get(selectedItem, 'extra', {})
        onChange(value, extra)
      } else {
        const values = value
        const extras = values.map((nextValue) => {
          const selectedItem = find(data, (item) => item.value === nextValue)
          const extra = get(selectedItem, 'extra', {})
          return extra
        })
        onChange(values, extras)
      }
    }
  }

  return (
    <ToggleButtonGroup
      {...rest}
      sx={[
        {
          flexWrap: 'wrap',
          gap: 2
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      data-testid='ToggleChipInput-Root'
      className={clsx('ToggleChipInput', className)}
      fullWidth={fullWidth}
      exclusive={!multiple}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
    >
      {data.map((item) => {
        return (
          <ToggleChip
            key={item.id || item.value}
            data-testid={`ToggleChip-${item.label}`}
            size={size}
            label={item.label}
            value={item.value}
            currentValue={value}
            multiple={multiple}
            ChipContentProps={ChipContentProps}
            ChipRootProps={ChipRootProps}
          />
        )
      })}
    </ToggleButtonGroup>
  )
}

export { ToggleChipInput }
export default ToggleChipInput
