import {
  dataExists,
  getFileUploadUrl,
  uploadFileToS3
} from '~map-creator/core/api'

async function uploadGeojson(dataId, geojson) {
  const { uploadUrl, formFieldsJson } = await getFileUploadUrl(dataId)

  const isDataExists = await dataExists(dataId)

  if (isDataExists) return

  const file = new Blob([JSON.stringify(geojson)], {
    type: 'application/json'
  })

  const uploaded = await uploadFileToS3({
    url: uploadUrl,
    formData: JSON.parse(formFieldsJson),
    file
  })

  if (uploaded) return

  throw new Error('Map: fail to upload your map')
}

export { uploadGeojson }
