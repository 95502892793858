import { Button, IconButton, Snackbar, SvgIcon } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import RefreshCcw01 from '@untitled-ui/icons-react/build/esm/RefreshCcw01'
import Share07 from '@untitled-ui/icons-react/build/esm/Share07'
import XClose from '@untitled-ui/icons-react/build/esm/XClose'
import Notification from '~ui-components/components/molecules/Notification'

const getTitle = ({ share }) => {
  if (share) return 'Copied'
  return 'Share'
}

const severity = {
  pending: 'info',
  failed: 'error'
}

const message = {
  pending: 'Sharing is disabled until the map upload is complete.',
  failed:
    'There was a problem uploading your map, your map has not been saved. Try again?'
}

const NotificationActions = (props) => {
  const { status, onRetryClick, onCloseClick } = props

  switch (status) {
    case 'pending': {
      return (
        <IconButton
          id='ButtonCloseMapSave'
          color='inherit'
          size='small'
          onClick={onCloseClick}
          sx={{
            '.MuiSvgIcon-root': {
              fontSize: '1.25rem'
            }
          }}
        >
          <SvgIcon>
            <XClose />
          </SvgIcon>
        </IconButton>
      )
    }

    case 'failed': {
      return (
        <IconButton
          color='inherit'
          size='small'
          onClick={onRetryClick}
          sx={{
            '.MuiSvgIcon-root': {
              fontSize: '1.25rem'
            }
          }}
        >
          <SvgIcon>
            <RefreshCcw01 />
          </SvgIcon>
        </IconButton>
      )
    }

    case 'ready': {
      return (
        <IconButton
          id='ButtonCloseMapSave'
          color='inherit'
          size='small'
          onClick={onCloseClick}
        >
          <SvgIcon
            sx={{
              fontSize: '1.25rem !important'
            }}
          >
            <XClose />
          </SvgIcon>
        </IconButton>
      )
    }

    default: {
      return null
    }
  }
}

const timeout = {
  share: undefined
}

const ShareButton = (props) => {
  const {
    status: statusProp = 'pending',
    onShare = () => {},
    onRetry = () => {},
    ...rest
  } = props

  const [open, setOpen] = useState(false)
  const [share, setShare] = useState(false)
  const [status, setStatus] = useState(statusProp)

  const shareHandler = () => {
    onShare()
    setShare(true)
    if (timeout.share) clearTimeout(timeout.share)
    timeout.share = setTimeout(() => {
      setShare(false)
    }, 1000)
  }

  const handleClick = () => {
    switch (statusProp) {
      case 'failed': {
        setOpen(true)
        break
      }

      case 'pending': {
        setOpen(true)
        break
      }
    }
    shareHandler()
  }

  const handleRetry = () => {
    onRetry()
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    switch (statusProp) {
      case 'ready': {
        setOpen(false)
        return
      }

      case 'failed': {
        setOpen(true)
        break
      }
    }
    setStatus(statusProp)
  }, [statusProp])

  return (
    <>
      <Notification
        component={Snackbar}
        variant='filled'
        severity={severity[status]}
        open={open}
        onClose={handleClose}
        action={
          <NotificationActions
            status={status}
            onRetryClick={handleRetry}
            onCloseClick={handleClose}
          />
        }
      >
        {message[status]}
      </Notification>

      <Button
        {...rest}
        disableElevation
        data-testid='ShareButton'
        variant='contained'
        color='inherit'
        startIcon={
          <SvgIcon sx={{ fontSize: '1.25em !important' }}>
            <Share07 />
          </SvgIcon>
        }
        onClick={handleClick}
      >
        {statusProp === 'ready' ? getTitle({ share }) : 'Share'}
      </Button>
    </>
  )
}

ShareButton.propTypes = {
  status: PropTypes.oneOf(['failed', 'pending', 'ready']),
  onShare: PropTypes.func,
  onRetry: PropTypes.func
}

export { ShareButton }
export default ShareButton
