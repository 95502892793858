const SeperatorHorizontal = () => (
  <svg
    data-v-e087307f=''
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <line
      x1='3'
      x2='21'
      y1='12'
      y2='12'
    ></line>
    <polyline points='8 8 12 4 16 8'></polyline>
    <polyline points='16 16 12 20 8 16'></polyline>
  </svg>
)

export default SeperatorHorizontal
