import { uploadGeojson } from '~map-creator/core/functions'
import { updateMap } from '~map-creator/map-editor/api'

const submitEditMap = async (context, input) => {
  const { dataId, geojson } = context
  await uploadGeojson(dataId, geojson)
  await updateMap(input)
}

export { submitEditMap }
