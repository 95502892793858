import { baseUrl } from 'utils'
import {
  Box,
  Item,
  Link,
  Typography
} from '~ui-components/emails/components/atoms'

const MapstackInfoCopy = () => {
  return (
    <Box>
      <Item>
        <Typography variant='h6'>What is mapstack?</Typography>
      </Item>
      <Item pt={10}>
        <Typography variant='body1'>
          mapstack is a community mapping app, a place where you can share and
          access data as colorful interactive maps - so everyone can better
          understand our world.{' '}
          <Link
            variant='body1'
            href={`${baseUrl()}`}
          >
            Learn more about mapstack
          </Link>
        </Typography>
      </Item>
    </Box>
  )
}

export { MapstackInfoCopy }
export default MapstackInfoCopy
