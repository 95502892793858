import { useEffect } from 'react'

import { useCurrentBounds } from '~map-creator/core/states/map'
import { useMapDimension } from '../useMapDimension'

export const useSetFitBounds = (map) => {
  const currentBounds = useCurrentBounds()
  const { width, height } = useMapDimension()

  const handleFitBounds = (fitBounds) => {
    const [minLng, minLat, maxLng, maxLat] = fitBounds

    map.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat]
      ],
      { duration: 1500 }
    )
  }

  useEffect(() => {
    if (!map) return
    const hasFitBounds =
      Object.values(currentBounds).every(Boolean) && width && height

    if (hasFitBounds) {
      handleFitBounds(Object.values(currentBounds))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBounds, width, height])
}
