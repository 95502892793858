import {
  Box,
  IconButton,
  Modal,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import XIcon from '@untitled-ui/icons-react/build/cjs/X'
import type { FC } from 'react'
import { SearchInput } from '~ui-components/components/molecules/SearchInput'
import { SearchResults } from './SearchResults'

type SearchModalProps = {
  open: boolean
  results?: any
  onClose: () => void
  onSubmit: () => void
  onChange: (value: string) => void
}

const SearchModal: FC<SearchModalProps> = (props) => {
  const { open, onClose, onChange, onSubmit, results } = props

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ pt: 3, alignItems: 'flex-start' }}
    >
      <Paper
        elevation={12}
        sx={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          margin: 3,
          maxWidth: '100%',
          mx: 'auto',
          outline: 'none',
          width: 600
        }}
      >
        <Stack
          alignItems='center'
          direction='row'
          spacing={1}
          sx={{
            px: 2,
            py: 1
          }}
        >
          <Typography
            sx={{ flexGrow: 1 }}
            variant='h6'
          >
            Search
          </Typography>
          <IconButton onClick={onClose}>
            <SvgIcon>
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
        <Box sx={{ p: 2, pt: 0 }}>
          <SearchInput
            onChange={onChange}
            onSubmit={onSubmit}
          />
        </Box>
        <SearchResults
          onClose={onClose}
          results={results}
        />
      </Paper>
    </Modal>
  )
}
export { SearchModal }
