import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import { useRouter } from 'next/router'
import { getMapLink } from 'utils'
import { MapMetadataCreatedAt } from '~ui-components/components/molecules/MapMetadata/MapMetadataCreatedAt'
import { MapMetadataProfile } from '~ui-components/components/molecules/MapMetadata/MapMetadataProfile'
import { MapMetadataViews } from '~ui-components/components/molecules/MapMetadata/MapMetadataViews'
import { Map } from '~ui-components/types/__generated/gql/graphql'

interface RemixMapsProps {
  maps: Map[]
}

const RemixMaps = (props: RemixMapsProps) => {
  const { maps = [] } = props
  const router = useRouter()

  return (
    <Table sx={{ minWidth: 300 }}>
      <TableBody>
        {maps.map((map, index) => {
          return (
            <TableRow
              sx={{
                cursor: 'pointer'
              }}
              hover
              key={map.id}
              onClick={() =>
                router.push(getMapLink({ mapId: map.id, mapName: map.name }))
              }
            >
              <TableCell>
                <Stack
                  direction='column'
                  spacing={2}
                >
                  <Stack
                    alignItems='center'
                    direction='row'
                    spacing={2}
                  >
                    <Typography variant='subtitle2'>{map.name}</Typography>
                  </Stack>

                  <Stack
                    direction='row'
                    gap={2}
                  >
                    {map.workspace && (
                      <MapMetadataProfile workspace={map.workspace} />
                    )}
                    {map.views && <MapMetadataViews views={map.views} />}
                    <MapMetadataCreatedAt createdAt={map.createdAt} />
                  </Stack>
                </Stack>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export { RemixMaps }
