import { MapRemixDrawer as Layout } from 'ui-components'

import { ApplyFiltersInput } from './ApplyFiltersInput'
import { Form } from './Form'
import { IncludePropertiesInput } from './IncludePropertiesInput'
import { RemixButton } from './RemixButton'
import { WorkspaceSaveToInput } from './WorkspaceSaveToInput'

const MapRemixDrawer = (props) => {
  return (
    <Layout
      {...props}
      slots={{
        Form,
        ApplyFiltersInput,
        IncludePropertiesInput,
        WorkspaceSaveToInput,
        RemixButton
      }}
    />
  )
}

export { MapRemixDrawer }
