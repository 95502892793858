import React from 'react'
import PropTypes from 'prop-types'

const Mapstack = ({ width = 45, height = 70 }) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 224 224'
    >
      <path
        fill='#F0B514'
        d='M112 224c-3.7 0-7.2-1-10.5-2.7l-96-52.4a10.6 10.6 0 0 1-4.2-14.5c3-5.2 9.5-7 14.7-4.2l90.3 49.2c3.5 2 7.8 2 11.3 0l90.2-49.2c5.3-2.9 11.8-1 14.7 4.2 2.9 5.2 1 11.7-4.3 14.5l-96 52.4a21.6 21.6 0 0 1-10.3 2.6Z'
      />
      <path
        fill='#39A6F3'
        d='M112 177.2c-3.7 0-7.2-.9-10.5-2.6l-96-52.4a10.6 10.6 0 0 1-4.2-14.5c3-5.2 9.5-7 14.7-4.2l90.3 49.2c3.5 2 7.8 2 11.3 0l90.2-49.2c5.3-2.8 11.8-1 14.7 4.2 2.9 5.2 1 11.7-4.3 14.5l-96 52.4a21.6 21.6 0 0 1-10.3 2.6Z'
      />
      <path
        fill='#17AB6A'
        d='M218 54.9 122.3 2.6c-6.3-3.5-14-3.5-20.4 0L5.9 55a11.1 11.1 0 0 0 0 19.5l100.4 54.8c3.6 2 7.9 2 11.4 0L218 74.4a11.1 11.1 0 0 0 0-19.5Z'
      />
    </svg>
  )
}

Mapstack.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export { Mapstack }
