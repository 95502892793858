import { Stack } from '@mui/material'
import { AuthButton } from '~ui-components/components/molecules/AuthButton'

const defaultSlots = {
  GoogleButton: AuthButton,
  FacebookButton: AuthButton,
  EmailButton: AuthButton
}

export function LoginButtons(props) {
  const { hideEmail = true, slots = {}, slotProps = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Stack
      direction='column'
      gap={3}
    >
      <Slots.GoogleButton
        method='google'
        {...slotProps?.GoogleButton}
      />
      <Slots.FacebookButton
        method='facebook'
        {...slotProps?.FacebookButton}
      />
      {!hideEmail ? (
        <Slots.EmailButton
          method='email'
          {...slotProps?.EmailButton}
        />
      ) : null}
    </Stack>
  )
}
