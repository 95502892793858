import { isEmpty, pickBy } from 'lodash'
import { getNanoId } from 'utils'

const getMapPayload = (id, store) => {
  const mapId = id ?? getNanoId()
  const { geojson, workspace, map, properties, ui } = store.getState()

  const { centroid, bbox } = geojson
  const { workspaceId } = workspace
  const { name, license, source } = map
  const { configurations, includedKeys } = properties
  const { mapTags, coverageArea, featureType } = ui

  const tags = mapTags.concat([coverageArea, featureType])
  const tagIds = tags.map((tag) => tag.id).filter(Boolean)

  const mapProperties = includedKeys
    .reduce((pre, cur) => {
      const { options, classBreaks, ...property } = configurations[cur]
      // classBreaks could be null when editing since thats the type return from AppSync
      if (!isEmpty(classBreaks)) property.classBreaks = classBreaks
      return [...pre, property]
    }, [])
    .filter(Boolean)

  const mapLicense =
    isEmpty(license.name) || isEmpty(license.url) ? {} : { license }

  const inputSource = pickBy(source, (value) => !isEmpty(value))
  const mapSource = isEmpty(inputSource) ? {} : { source: inputSource }

  const dataSource = {
    ...mapLicense,
    ...mapSource
  }

  return {
    id: mapId,
    workspaceId,
    name,
    properties: mapProperties,
    bbox,
    centroid,
    tagIds,
    views: 1,
    likes: 0,
    remixes: 0,
    ...dataSource
  }
}

export { getMapPayload }
