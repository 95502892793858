import { generateFill, generate3DFill } from "./fill";
import { generateLine } from './line'
import { generateLegend } from './legend'

export {
  generateFill,
  generate3DFill,
  generateLegend,
  generateLine
}
