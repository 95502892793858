import React, { useMemo, forwardRef } from 'react'
import { TextField, InputAdornment } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

const KEY_BACKSPACE = 8

const CountTextInput = forwardRef((props, ref) => {
  const { limit = 50, value, error, onChange, inputProps, ...rest } = props

  const [disabled, remaining] = useMemo(() => {
    if (!value) return [false, limit]
    const currentLength = Array.from(value).length
    return [currentLength >= limit, limit - currentLength]
  }, [value, limit])

  const handleChange = (event) => {
    const value = event.target.value
    onChange(event, String(value).substring(0, limit))
    return event
  }

  const handleKeyDown = (event) => {
    const key = event.keyCode
    if (disabled && key !== KEY_BACKSPACE) {
      event.preventDefault()
    }
  }

  const overLimitError =
    remaining <= 0 ? `Input can't have more than ${limit} characters.` : ''

  return (
    <TextField
      {...rest}
      inputProps={{
        ...inputProps,
        maxLength: limit
      }}
      FormHelperTextProps={{
        'data-testid': 'CountTextInput-FormHelperText'
      }}
      fullWidth
      ref={ref}
      variant='outlined'
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      error={!isEmpty(overLimitError || error)}
      helperText={overLimitError || error}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>{remaining}</InputAdornment>
        )
      }}
    />
  )
})

CountTextInput.displayName = 'CountTextInput'

export { CountTextInput }
export default CountTextInput
