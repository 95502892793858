import md5 from 'blueimp-md5'
import geostats from 'geostats'
import jmespath from 'jmespath'
import _ from 'lodash'
import { VISUALISATION } from '../assets/Constants'

const DEFAULT_CLASS_BREAK = 7
const { CATEGORY, QUANTITY } = VISUALISATION

export const getVisualisationPropertyType = (key, features) => {
  const propertyArray = getFeaturePropsByKey(key, features)
  const filteredArray = propertyArray.filter(Boolean)
  return isStringsArray(filteredArray) ? CATEGORY : QUANTITY
}
const isStringsArray = (arr) => arr.every((i) => typeof i === 'string')

const getFeaturePropsByKey = (key, features) => {
  return jmespath.search(features, '[*].properties."' + key + '"')
}

const getNumberFeaturePropsByKey = (key, features) => {
  const propertyArray = getFeaturePropsByKey(key, features)
  return propertyArray.filter(Number.isFinite)
}

export const getStringFeaturePropsByKey = (key, features) => {
  const propertyArray = getFeaturePropsByKey(key, features)
  return _.uniq(propertyArray.filter(Boolean))
}
const isBrowser = () => typeof window !== 'undefined'

const visualisationPayloadCache = {
  setCache: (hash, payload) => {
    const key = `visualisationPayloadCache_${hash}`
    isBrowser() && sessionStorage.setItem(key, JSON.stringify(payload))
  },
  getCache: (hash) => {
    if (!isBrowser()) return

    const key = `visualisationPayloadCache_${hash}`

    if (!sessionStorage.getItem(key)) return null

    return JSON.parse(String(sessionStorage.getItem(key)))
  }
}

export const getPayloadByVisualisationType = (key, features) => {
  let jenks
  const featureProps = getNumberFeaturePropsByKey(key, features)
  if (_.isEmpty(featureProps)) {
    return {
      jenks: [],
      isOverClassBreakLimit: true
    }
  }
  const cacheKey = md5(
    JSON.stringify({
      key,
      featureProps
    })
  )
  if (visualisationPayloadCache.getCache(cacheKey))
    return visualisationPayloadCache.getCache(cacheKey)
  // eslint-disable-next-line new-cap
  const series = new geostats(featureProps)

  // Count Unique Class Break
  const uniqueCount = _.cloneDeep(series).getClassUniqueValues()
  const isOverClassBreakLimit = uniqueCount.length > DEFAULT_CLASS_BREAK

  if (isOverClassBreakLimit) {
    // Equally breaking numbers into {breakNumber=7} classification
    jenks = series.getClassJenks(DEFAULT_CLASS_BREAK)
  } else {
    jenks = uniqueCount.sort((a, b) => a - b)
  }
  const payload = {
    jenks: _.uniq(jenks),
    isOverClassBreakLimit
  }
  visualisationPayloadCache.setCache(cacheKey, payload)

  return payload
}

export const smallestClassBreakGap = (numbers) => {
  const diffs = _.reduce(
    numbers,
    function (result, value, index, collection) {
      if (Number(index) === 0) return result
      result[index] = value - collection[Number(index) - 1]
      return result
    },
    []
  ).slice(1)
  return Math.min(...diffs)
}

export const getRoundingValue = (value) => {
  return Math.pow(10, Math.floor(Math.log10(value)))
}
