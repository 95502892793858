import { IconButton, SvgIcon, useTheme } from '@mui/material'
import FilterFunnel01 from '@untitled-ui/icons-react/build/esm/FilterFunnel01'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const FilterButton = forwardRef((props, ref) => {
  const { toggled, sx = {}, onClick, ...rest } = props
  const theme = useTheme()

  return (
    <IconButton
      {...rest}
      ref={ref}
      sx={[
        {
          minWidth: 'unset',
          width: '32px',
          height: '32px',
          color: toggled ? theme.palette.common.white : theme.palette.grey[500]
        },

        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      onClick={onClick}
    >
      <SvgIcon>
        <FilterFunnel01 />
      </SvgIcon>
    </IconButton>
  )
})

FilterButton.displayName = 'FilterButton'

FilterButton.propTypes = {
  toggled: PropTypes.bool,
  onClick: PropTypes.func
}

export { FilterButton }
export default FilterButton
