import { useRouter } from 'next/router'
import { useState } from 'react'
import { RemixIconButton as Button, MapRemixDialog } from 'ui-components'
import { useFederatedAuth } from 'user-auth'
import { MapRemixDrawer } from './MapRemixDrawer'

interface RemixButtonProps {
  remixable?: boolean
}

function RemixButton(props: RemixButtonProps) {
  const { remixable, ...rest } = props

  const router = useRouter()

  const { gotoSignInPage } = useFederatedAuth()

  const forceOpen = router.query['remix'] === 'true'
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [redirect, setRedirect] = useState<string>()

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }

  const handleDrawerClose = () => {
    if (forceOpen) {
      const url = new URL(window.location.href)
      url.searchParams.delete('remix')
      router.replace(url.toString(), undefined, {
        shallow: true
      })
    }

    setOpenDrawer(false)
  }

  const handleDialogOpen = () => setOpenDialog(true)
  const handleDialogClose = () => setOpenDialog(false)

  const handleSubmit = (event) => {
    const { redirect } = event

    setRedirect(redirect)
    handleDialogOpen()
  }

  const handleActionClick = () => {
    if (!redirect) return
    router.push(redirect)
  }

  // INFO: this is force open the drawer after the user sign in
  // Scenario: user is not signed in, click remix button, sign in, then the drawer will open
  const handleSignInClick = () => {
    const url = new URL(window.location.href)
    url.searchParams.set('remix', 'true')
    gotoSignInPage({ redirect: url.toString() })
  }

  return (
    <>
      <Button
        {...rest}
        remixable={remixable}
        onRemix={handleDrawerOpen}
        onSignInClick={handleSignInClick}
      />

      <MapRemixDrawer
        open={openDrawer || forceOpen}
        onClose={handleDrawerClose}
        slotProps={{
          Form: {
            onSubmit: handleSubmit
          }
        }}
      />

      <MapRemixDialog
        open={openDialog}
        onClose={handleDialogClose}
        onActionClick={handleActionClick}
      />
    </>
  )
}

export { RemixButton }
