import {
  Box,
  IconButton,
  Stack,
  SvgIcon,
  alpha,
  useMediaQuery
} from '@mui/material'
import type { Theme } from '@mui/material/styles/createTheme'
import { Menu01 } from '@untitled-ui/icons-react'
import { Fragment } from 'react'

interface TopNavProps {
  variant?: 'sticky' | 'fixed'
  background?: 'none' | 'glassy'
  topNavHeight?: number | string
  sideNavWidth?: number | string
  onMenuClick?: () => void
  slots?: {
    Content?: React.ElementType
  }
  slotProps?: {
    Content?: any
    MenuButton?: React.ComponentProps<typeof IconButton>
  }
}

const defaultSlots = {
  Content: Fragment
}

function TopNav(props: TopNavProps) {
  const {
    variant = 'sticky',
    background = 'glassy',
    topNavHeight = 64,
    sideNavWidth = 280,
    onMenuClick,
    slots,
    slotProps,
    ...rest
  } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  return (
    <Box
      component='header'
      sx={[
        {
          zIndex: (theme) => theme.zIndex.appBar,
          left: {
            lg: `${sideNavWidth}px`
          },
          top: 0,
          width: {
            lg: `calc(100% - ${sideNavWidth}px)`
          }
        },
        variant === 'sticky' && {
          position: 'sticky'
        },
        variant === 'fixed' && {
          position: 'fixed'
        },
        background === 'glassy' && {
          backdropFilter: 'blur(6px)',
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8)
        }
      ]}
      {...rest}
    >
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={2}
        sx={{
          minHeight: topNavHeight,
          px: 2
        }}
      >
        <Stack
          alignItems='center'
          direction='row'
          spacing={2}
        >
          {!lgUp && (
            <IconButton
              onClick={onMenuClick}
              {...slotProps?.MenuButton}
            >
              <SvgIcon>
                <Menu01 />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>

        <Stack
          alignItems='center'
          direction='row'
          spacing={2}
        >
          <Slots.Content {...slotProps?.Content} />
        </Stack>
      </Stack>
    </Box>
  )
}

export { TopNav }
