import { useSkippableDefaultView } from '~map-creator/core/hooks/useSkippableSteps/defaultView'
import { useSkippableFeatureTitle } from '~map-creator/core/hooks/useSkippableSteps/featureTitle'
import { useSkippableIdentifier } from '~map-creator/core/hooks/useSkippableSteps/identifier'
import { useSkippableVisualisations } from '~map-creator/core/hooks/useSkippableSteps/visualisation'
import { useCurrentWizardStep } from '~map-creator/core/states/map'
import { useStepIndexes } from '~map-creator/core/states/ui'

export const useSkippableSteps = () => {
  const currentWizardStep = useCurrentWizardStep()
  const stepIndexes = useStepIndexes()
  const {
    DEFINE_FEATURE_TITLE,
    SELECT_UNIQUE_IDENTIFIER,
    SELECT_VISUALISATION_PROPERTIES,
    SELECT_DEFAULT_VIEW
  } = stepIndexes

  useSkippableFeatureTitle(currentWizardStep === DEFINE_FEATURE_TITLE)
  useSkippableIdentifier(currentWizardStep === SELECT_UNIQUE_IDENTIFIER)
  useSkippableVisualisations(
    currentWizardStep === SELECT_VISUALISATION_PROPERTIES
  )
  useSkippableDefaultView(currentWizardStep === SELECT_DEFAULT_VIEW)
}
