import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const MapName = forwardRef((props, ref) => {
  const { sx, name, ...rest } = props

  return (
    <Box
      ref={ref}
      component='h1'
      title={name}
      sx={[
        {
          my: 0,
          letterSpacing: 'tight',
          fontWeight: 'extrabold',
          position: 'relative',
          typography: 'h4',
          maxWidth: '24ch'
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...rest}
    >
      {name || 'Untitled Map'}
    </Box>
  )
})

MapName.displayName = 'MapName'

MapName.propTypes = {
  name: PropTypes.string
}

export { MapName }
export default MapName
