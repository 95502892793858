import { get, set } from 'idb-keyval'
import _ from 'lodash'
import fetch from 'map-data-fetch'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MapError } from '~map-viewer/constants/MapError'
import { setError } from '~map-viewer/states/error'
import { setJsonFeatures, useMap } from '~map-viewer/states/map'

export function useInitJsonData() {
  const dispatch = useDispatch()
  const router = useRouter()
  const map = useMap()
  const dataId = map?.dataId

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const loadJson = async () => {
      try {
        const json = await get(`${dataId}:json`)
        if (json) {
          setJsonDataToJsonFeatures(json)
        } else {
          handleFreshData(dataId)
        }
      } catch (error) {
        if (
          // INFO: Problem of indexdb connection need page to be reloaded after clearing cache
          _.isEqual(
            error?.message,
            "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing."
          )
        ) {
          router.reload()
        } else {
          dispatch(setError(MapError.MAP_LOAD_ERROR))
        }
      }
    }
    dataId && loadJson()
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataId])

  const setJsonDataToJsonFeatures = (json) => {
    dispatch(setJsonFeatures(json.features))
    setIsLoaded(true)
  }

  const handleFreshData = async (dataSetId) => {
    await fetchAndStoreData(dataSetId)
  }

  const fetchAndStoreData = async (dataSetId) => {
    const json = await fetch(`public/${dataSetId}`, 'json')
    setJsonDataToJsonFeatures(json)
    await set(`${dataSetId}:json`, json)
  }

  return { isLoaded }
}
