import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import workspaceApi from '~map-creator/core/services/workspace'

const initialState = {
  workspaceId: undefined,
  workspace: undefined
}

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        workspaceApi.endpoints.getWorkspaceById.matchPending,
        (state, action) => {
          state.workspaceId = action.meta.arg.originalArgs
        }
      )
      .addMatcher(
        workspaceApi.endpoints.getWorkspaceById.matchFulfilled,
        (state, action) => {
          state.workspace = action.payload.getWorkspaceById
        }
      )
  }
})

export const {} = workspaceSlice.actions

const selectWorkspace = (state) => state.workspace.workspace

export const useWorkspace = () => useSelector(selectWorkspace)

export default workspaceSlice.reducer
