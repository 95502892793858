import { Box, Divider, SvgIcon, Tab, Tabs } from '@mui/material'
import Grid01 from '@untitled-ui/icons-react/build/esm/Grid01'
import Map01 from '@untitled-ui/icons-react/build/esm/Map01'
import Settings01 from '@untitled-ui/icons-react/build/esm/Settings01'
import NextLink from 'next/link'
import { useQueryParams } from 'ssr/router'

import { getUserLink } from 'utils'
import { Skeleton } from '~ui-components/components/atoms/Skeleton'

export enum USER_PROFILE_TAB {
  MAPS = 'maps',
  WORKSPACES = 'workspaces',
  SETTINGS = 'settings'
}

const UserProfileTabBar = (props) => {
  const {
    canUpdateUserSettings = false,
    totalWorkspaces = 0,
    totalMaps = 0,
    loading = false
  } = props

  const { tab, userId, username } = useQueryParams()

  return loading ? (
    <Box
      display='flex'
      flexDirection='row'
      flexWrap='nowrap'
      gap={1}
      sx={{
        overflowX: 'hidden'
      }}
    >
      {Array.from({ length: 4 }).map((_, index) => {
        return (
          <Skeleton
            loading
            variant='square'
            key={index}
            width={122}
            height={40}
          />
        )
      })}
    </Box>
  ) : (
    <Box>
      <Tabs
        data-testid='UserProfileTabBar'
        indicatorColor='primary'
        scrollButtons='auto'
        sx={{ px: 3 }}
        textColor='primary'
        value={tab || USER_PROFILE_TAB.MAPS}
        variant='scrollable'
      >
        <Tab
          value={USER_PROFILE_TAB.MAPS}
          iconPosition='start'
          label={totalMaps ? `Maps (${totalMaps})` : 'Maps'}
          icon={
            <SvgIcon>
              <Map01 />
            </SvgIcon>
          }
          component={NextLink}
          href={getUserLink({
            userId,
            username,
            tab: USER_PROFILE_TAB.MAPS,
            page: 1
          })}
        />

        <Tab
          value={USER_PROFILE_TAB.WORKSPACES}
          iconPosition='start'
          label={
            totalWorkspaces ? `Workspaces (${totalWorkspaces})` : 'Workspaces'
          }
          icon={
            <SvgIcon>
              <Grid01 />
            </SvgIcon>
          }
          component={NextLink}
          href={getUserLink({
            userId,
            username,
            tab: USER_PROFILE_TAB.WORKSPACES
          })}
        />

        {canUpdateUserSettings && (
          <Tab
            value={USER_PROFILE_TAB.SETTINGS}
            iconPosition='start'
            label='Settings'
            icon={
              <SvgIcon>
                <Settings01 />
              </SvgIcon>
            }
            component={NextLink}
            href={getUserLink({
              userId,
              username,
              tab: USER_PROFILE_TAB.SETTINGS
            })}
          />
        )}
      </Tabs>
      <Divider />
    </Box>
  )
}

export { UserProfileTabBar }
