import { createSlice } from '@reduxjs/toolkit'
import { MapStatus } from '~map-viewer/constants/MapStatus'
import { useMapViewerSelector } from '../context'

const initialState = {
  submitMap: {
    id: undefined,
    mapStatus: MapStatus.IDLE
  }
}

export const mapCreatorSlice = createSlice({
  name: 'mapCreator',
  initialState,
  reducers: {
    setSubmitMap: (state, action) => {
      state.submitMap.id = action.payload.mapId
      state.submitMap.mapStatus = action.payload.mapStatus
    }
  }
})

export const { setSubmitMap: setSubmitMapAction } = mapCreatorSlice.actions

const selectSubmitMap = (state) => state.mapCreator.submitMap

export const useSubmitMap = () => useMapViewerSelector(selectSubmitMap)

export default mapCreatorSlice.reducer
