/* eslint-disable react-hooks/rules-of-hooks */
import { useCurrentWizardStep } from '~map-creator/core/states/map'
import { useStepIndexes } from '~map-creator/core/states/ui'
import { useLayersStyles } from '../../../hooks/useLayersStyles'

export const getColorRampStyle = () => {
  const currentWizardStep = useCurrentWizardStep()
  const stepIndexes = useStepIndexes()
  const { SELECT_VISUALISATION_PROPERTIES, SELECT_DEFAULT_VIEW } = stepIndexes
  const showVisualisationStep =
    SELECT_VISUALISATION_PROPERTIES || SELECT_DEFAULT_VIEW

  const isDeactivated = currentWizardStep < showVisualisationStep
  const quantitiesStyle = useLayersStyles(isDeactivated)

  return {
    visibility: currentWizardStep >= showVisualisationStep ? 'visible' : 'none',
    style: quantitiesStyle
  }
}
