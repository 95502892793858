import { graphierQL } from 'utils'
import {
  LikeMapMutation,
  LikeMapMutationVariables
} from '~map-viewer/types/__generated/gql/graphql'

const likeMap = async (id) => {
  const { likeMap } = await graphierQL<
    LikeMapMutation,
    LikeMapMutationVariables
  >({
    useToken: true,
    query: likeMapMutation,
    variables: { id }
  })

  return likeMap
}

const likeMapMutation = /* GraphQL */ `
  mutation LikeMap($id: ID!) {
    likeMap(id: $id)
  }
`

export { likeMap, likeMapMutation }
export default likeMap
