import { Alert, Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import ToggleChipInput from '~ui-components/components/molecules/ToggleChipInput'

const SelectQuantityProperties = (props) => {
  const { options, selected, onChange } = props

  if (options.length <= 0) return null

  return (
    <Box
      data-testid='CreateMap-SelectQuantityProperties'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        mb: 4,
        margin: '0'
      }}
    >
      <Typography
        variant='h5'
        fontWeight='600'
      >
        Which values contain a quantity?
      </Typography>
      <Alert
        severity='info'
        variant='filled'
      >
        <Typography variant='body2'>
          Examples: Population or Total sales.
        </Typography>
      </Alert>
      <ToggleChipInput
        multiple
        value={selected}
        data={options}
        onChange={onChange}
      />
    </Box>
  )
}

const SelectQuantityPropertiesPropTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.array,
  onChange: PropTypes.func
}

SelectQuantityProperties.propTypes = SelectQuantityPropertiesPropTypes

export { SelectQuantityProperties, SelectQuantityPropertiesPropTypes }
export default SelectQuantityProperties
