import _ from 'lodash'

import {
  ERROR_OBJECT,
  getOverSizeErrorObj
} from '~map-creator/core/assets/constants'

const SUPPORT_FILE_EXTENSIONS = ['geojson']

const getLimitFileSize = (limitSizeAsMb) => limitSizeAsMb * 1024 * 1024

export async function validateGeojsonFile(file, limitSizeAsMb) {
  const { name, size } = file
  const fileNameSplit = name.split('.')
  const response = { data: null, errors: null }

  const unsupportedFile = !_.includes(
    SUPPORT_FILE_EXTENSIONS,
    fileNameSplit[fileNameSplit.length - 1]
  )

  if (unsupportedFile) {
    response.errors = {
      NOT_SUPPORT_FILE: ERROR_OBJECT.NOT_SUPPORT_FILE
    }
    return response
  }

  const overSizeFile = size > getLimitFileSize(limitSizeAsMb)
  if (overSizeFile) {
    response.errors = {
      FILE_ABOVE_LIMIT_SIZE: getOverSizeErrorObj(limitSizeAsMb)
    }
    return response
  }

  try {
    const fileData = await file.text()
    const geojson = JSON.parse(fileData)

    if (geojson) {
      response.data = geojson
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}
