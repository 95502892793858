import { FC } from 'react'
import { useIsMobile } from '~ui-components/hooks'
import { DesktopMapBar } from './DesktopMapBar'
import { MobileMapBar } from './MobileMapBar'

export interface MapBarProps {
  authenticated: boolean
  onClose?: () => void
  slots: Record<string, any>
  isEmbed?: boolean
}

export const MapBar: FC<MapBarProps> = (props) => {
  const isMobile = useIsMobile()

  if (props.isEmbed) {
    return <DesktopMapBar {...props} />
  }

  if (isMobile) {
    return <MobileMapBar {...props} />
  }

  return <DesktopMapBar {...props} />
}
