import { Chip, Stack, Typography } from '@mui/material'

type Event = {
  type: string
}

type Value = string

interface HexagonSizeInputProps {
  value?: Value
  onChange?: (event: Event, value: Value) => void
}

const options = [
  {
    label: 'Large',
    value: 'large'
  },
  {
    label: 'Medium',
    value: 'medium'
  },
  {
    label: 'Small',
    value: 'small'
  }
]

function HexagonSizeInput(props: HexagonSizeInputProps) {
  const { value, onChange } = props

  const selectOption = (option) => {
    onChange?.({ type: 'change' }, option.value)
  }

  const unselectOption = (option) => {
    onChange?.({ type: 'change' }, option.value)
  }

  return (
    <Stack spacing={1}>
      <Typography
        color='text.secondary'
        variant='overline'
      >
        Hexagons size
      </Typography>
      <Stack
        alignItems='center'
        direction='row'
        flexWrap='wrap'
        gap={2}
      >
        {options.map((option) => {
          const active = value === option.value
          return (
            <Chip
              key={`Size:${option.label}`}
              data-testid={`Size-${option.label}`}
              label={option.label}
              onClick={() =>
                active ? unselectOption(option) : selectOption(option)
              }
              onDelete={undefined}
              sx={{
                borderColor: 'transparent',
                borderRadius: 1.5,
                borderStyle: 'solid',
                borderWidth: 2,
                ...(active && {
                  borderColor: 'primary.main'
                })
              }}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}

export { HexagonSizeInput }
