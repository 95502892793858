import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { CountTextInput } from '~ui-components/components/molecules/CountTextInput'

const WorkspaceCreatorDescriptionForm = (props) => {
  const { disabled, value, onChange, onSubmit } = props

  return (
    <Box
      component='form'
      onSubmit={onSubmit}
    >
      <Box
        component={Typography}
        variant='h2'
      >
        What type of maps are you working on right now?
      </Box>
      <Box
        component={Typography}
        color='text.secondary'
        variant='subtitle1'
        my={2}
      >
        This could be anything: a project, a place, a specific subject or theme.
      </Box>
      <Box
        disabled={disabled}
        inputProps={{
          'data-testid': 'DescriptionTextInput'
        }}
        component={CountTextInput}
        limit={80}
        value={value}
        onChange={onChange}
        placeholder='England Territory Maps'
      />
    </Box>
  )
}

const WorkspaceCreatorDescriptionFormPropTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
}

WorkspaceCreatorDescriptionForm.propTypes =
  WorkspaceCreatorDescriptionFormPropTypes

export {
  WorkspaceCreatorDescriptionForm,
  WorkspaceCreatorDescriptionFormPropTypes
}
export default WorkspaceCreatorDescriptionForm
