import { createSlice } from '@reduxjs/toolkit'
import { map, values } from 'lodash'
import { useSelector } from 'react-redux'
import {
  VISUALISATION,
  getAllGeojsonFeatureProperties,
  getTypeOfEachProperties
} from 'utils'
import mapApi from '~map-creator/core/services/map'

import { getPropertyTypeOptions } from '~map-creator/core/utilities/getPropertyTypeOptions'

const initialState = {
  bbox: [],
  centroid: [],
  geojsonFileId: '',
  defaultPropertyConfigs: {},
  uniquePropertiesKeys: [],
  missRows: undefined
}

export const geojsonSlice = createSlice({
  name: 'geojson',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
    setBbox: (state, action) => {
      state.bbox = action.payload
    },
    setCentroid: (state, action) => {
      state.centroid = action.payload
    },
    setGeojsonFileId: (state, action) => {
      state.geojsonFileId = action.payload
    },
    setDefaultPropertyConfigs: (state, action) => {
      state.defaultPropertyConfigs = action.payload
    },
    setUniquePropertiesKeys: (state, action) => {
      state.uniquePropertiesKeys = action.payload
    },
    setMissRows: (state, action) => {
      state.missRows = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(mapApi.endpoints.getMapById.matchPending, () => {})
      .addMatcher(
        mapApi.endpoints.getMapById.matchFulfilled,
        (state, action) => {
          const { geojson, properties, dataId, bbox, centroid } = action.payload
          state.bbox = bbox
          state.centroid = centroid
          state.geojsonFileId = dataId
          state.defaultPropertyConfigs = createDefaultPropertyConfigurations(
            geojson,
            properties
          )
        }
      )
      .addMatcher(mapApi.endpoints.getMapById.matchRejected, () => {})
  }
})

const useGeojsonFileId = () => {
  return useSelector((state) => state.geojson.geojsonFileId)
}

const useBbox = () => {
  return useSelector((state) => state.geojson.bbox)
}

const useCentroid = () => {
  return useSelector((state) => state.geojson.centroid)
}

const useDefaultPropertyConfigs = () => {
  return useSelector((state) => state.geojson.defaultPropertyConfigs)
}

const useUniquePropertiesKeys = () => {
  return useSelector((state) => state.geojson.uniquePropertiesKeys)
}

const useDefaultPropertiesKeys = () => {
  const defaultConfigs = useDefaultPropertyConfigs()
  return Object.keys(defaultConfigs || {})
}

const useDefaultQuantityPropertyKey = () => {
  const defaultConfigs = useDefaultPropertyConfigs()
  return Object.keys(defaultConfigs || {}).filter(
    (k) => defaultConfigs[k].visualisation === VISUALISATION.QUANTITY
  )
}

const useDefaultCategoryPropertyKeys = () => {
  const defaultConfigs = useDefaultPropertyConfigs()
  return Object.keys(defaultConfigs || {}).filter(
    (k) => defaultConfigs[k].visualisation === VISUALISATION.CATEGORY
  )
}

const useMissRows = () => useSelector((state) => state.geojson.missRows)

export {
  useBbox,
  useCentroid,
  useDefaultCategoryPropertyKeys,
  useDefaultPropertiesKeys,
  useDefaultPropertyConfigs,
  useDefaultQuantityPropertyKey,
  useGeojsonFileId,
  useMissRows,
  useUniquePropertiesKeys
}

export const {
  reset,
  setBbox,
  setCentroid,
  setGeojsonFileId,
  setDefaultPropertyConfigs,
  setUniquePropertiesKeys,
  setMissRows
} = geojsonSlice.actions

export default geojsonSlice.reducer

const createDefaultPropertyConfigurations = (geojson, properties) => {
  const propertiesType = getTypeOfEachProperties(
    getAllGeojsonFeatureProperties(geojson)
  )
  const geojsonProperties = values(propertiesType)

  const featureProperties = getAllGeojsonFeatureProperties(geojson)

  const configurations = properties.reduce((pre, cur) => {
    return {
      ...pre,
      [cur.name]: {
        ...cur,
        type: propertiesType[cur.name].type
      }
    }
  }, {})

  const defaultConfigs = Object.fromEntries(
    geojsonProperties.map((property) => {
      const { name, type } = property
      const propertyValues = map(featureProperties, name)
      return [
        name,
        {
          ...configurations[name],
          options: getPropertyTypeOptions(propertyValues, type)
        }
      ]
    })
  )
  return defaultConfigs
}
