import { join, truncate } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { baseUrl, getWorkspaceLink } from 'utils'
import {
  Box,
  Button,
  Image,
  Item,
  Link,
  Typography
} from '~ui-components/emails/components/atoms'

const MemberJoinNow = (props) => {
  const { inviteLink, workspace, owner } = props
  const workspaceUrl = useMemo(
    () =>
      join(
        [
          baseUrl(),
          getWorkspaceLink({
            workspaceId: workspace?.id,
            workspaceName: workspace?.name
          })
        ],
        ''
      ),
    [workspace]
  )
  return (
    <Box
      align='center'
      p={24}
    >
      <Item align='center'>
        <Image
          data-testid='OwnerAvatar'
          src={owner?.avatar}
          alt='Workspace Avatar'
          width={75}
          height={75}
        />
      </Item>
      <Item
        align='center'
        pt={12}
      >
        <Typography
          data-testid='WorkspaceName'
          variant='body1'
          bold
        >
          {workspace?.name}
        </Typography>
      </Item>
      <Item
        align='center'
        pt={8}
      >
        <Link
          data-testid='WorkspaceUrl'
          href={workspaceUrl}
        >
          {truncate(workspaceUrl, {
            length: 45,
            omission: '...'
          })}
        </Link>
      </Item>
      <Item
        align='center'
        p={24}
      >
        <Button
          data-testid='JoinNowButton'
          href={inviteLink}
        >
          JOIN NOW
        </Button>
      </Item>
    </Box>
  )
}

MemberJoinNow.propTypes = {
  inviteLink: PropTypes.string,
  workspace: PropTypes.object,
  owner: PropTypes.object
}

export { MemberJoinNow }
export default MemberJoinNow
