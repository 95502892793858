const withId = (option, index) => ({ ...option, id: index + 1 })

const dataSourceOptions = [
  { label: 'None', url: '' },
  {
    label:
      'Attribution 3.0 Intergovernmental Organization (CC BY 3.0 IGO DEED)',
    url: 'https://creativecommons.org/licenses/by/3.0/igo/deed.en'
  },
  {
    label: 'Open Data Commons Open Database License (ODbL)',
    url: 'https://opendatacommons.org/licenses/odbl/'
  },
  {
    label: 'Open Data Commons Attribution License (ODC-By)',
    url: 'https://opendatacommons.org/licenses/by/'
  },
  {
    label: 'Open Data Commons Public Domain Dedication and License (PDDL)',
    url: 'https://opendatacommons.org/licenses/pddl/'
  },
  {
    label: 'Attribution 4.0 International (CC BY 4.0)',
    url: 'https://creativecommons.org/licenses/by/4.0/'
  },
  {
    label: 'Attribution-ShareAlike 4.0 International (CC BY-SA 4.0)',
    url: 'https://creativecommons.org/licenses/by-sa/4.0/'
  },
  {
    label: 'Attribution-NonCommercial 4.0 International (CC BY-NC 4.0)',
    url: 'https://creativecommons.org/licenses/by-nc/4.0/'
  },
  {
    label:
      'Attribution-NonCommercial-ShareAlike 4.0 International (CC BY-NC-SA 4.0)',
    url: 'https://creativecommons.org/licenses/by-nc-sa/4.0/'
  },
  {
    label: 'Attribution-NoDerivatives 4.0 International (CC BY-ND 4.0)',
    url: 'https://creativecommons.org/licenses/by-nd/4.0/'
  },
  {
    label:
      'Attribution-NonCommercial-NoDerivatives 4.0 International (CC BY-NC-ND 4.0)',
    url: 'https://creativecommons.org/licenses/by-nc-nd/4.0/'
  },
  {
    label: 'UK Open Government License (UK-GOV)',
    url: 'https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/'
  }
].map(withId)

export default dataSourceOptions
