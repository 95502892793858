import { Avatar, Box, ButtonBase, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'

const AvatarButton = ({ sx = {}, tooltip, src = '', onClick, ...props }) => {
  const Content = (
    <Box
      {...props}
      component={ButtonBase}
      data-testid='AvatarButton'
      size='large'
      onClick={onClick}
      sx={{
        borderRadius: '100%'
      }}
    >
      <Avatar
        src={src}
        sx={[
          {
            width: 32,
            height: 32
          },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
      />
    </Box>
  )

  if (!tooltip) {
    return Content
  }

  return (
    <Tooltip
      {...tooltip}
      slotProps={{
        tooltip: {
          sx: {
            typography: 'caption'
          }
        }
      }}
    >
      {Content}
    </Tooltip>
  )
}

AvatarButton.propTypes = {
  onClick: PropTypes.func,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  tooltip: PropTypes.object,
  src: PropTypes.string
}

export { AvatarButton }
export default AvatarButton
