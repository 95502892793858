import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CreateMap } from '~map-creator/core/App/CreateMap'
import { MAP_EDITOR_STEPS } from '~map-creator/core/assets/constants'
import { submitEditMap } from '~map-creator/core/functions/submitEditMap'
import { StoreContext } from '~map-creator/core/states/context'
import {
  setStepIndexes,
  useMapTagCollection
} from '~map-creator/core/states/ui'
import { useExistingMapData } from '~map-creator/map-editor/hooks/useExistingMapData'
import { useSkippableSteps } from '~map-creator/map-editor/hooks/useSkippableSteps'
import { useSteps } from '~map-creator/map-editor/hooks/useSteps'
import {
  getMapPayload,
  saveToLocalStorage
} from '~map-creator/map-editor/utils'

import { MapStatus, setSubmitMap } from 'map-viewer'
import { getMapLink } from 'utils'

const EditMap = () => {
  const store = useContext(StoreContext)
  const dispatch = useDispatch()

  const router = useRouter()

  const { loading: isDataLoading, existingMap, isError } = useExistingMapData()

  useSkippableSteps()

  useEffect(() => {
    dispatch(setStepIndexes(MAP_EDITOR_STEPS))
  }, [dispatch])

  const steps = useSteps()
  const mapTagCollection = useMapTagCollection()

  const handleSubmit = async (context) => {
    const map = getMapPayload(store, context, existingMap)

    try {
      await setSubmitMap(map.id, MapStatus.PENDING)
      router.push(getMapLink({ mapId: map.id, mapName: map.name }))
      await saveToLocalStorage(store, context, existingMap, mapTagCollection)
      await submitEditMap(context, map)
      await setSubmitMap(map.id, MapStatus.READY)
    } catch (error) {
      await setSubmitMap(map.id, MapStatus.FAILED)
      throw error
    }
  }

  return (
    <CreateMap
      steps={steps}
      onSubmit={handleSubmit}
      loading={isDataLoading}
      isError={isError}
    />
  )
}

export { EditMap }
export default EditMap
