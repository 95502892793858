import {
  Divider,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  SvgIcon,
  SvgIconProps,
  Typography
} from '@mui/material'
import { XClose } from '@untitled-ui/icons-react'
import React, { isValidElement } from 'react'

import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'

type SidebarProps = DrawerProps & {
  isEmbed?: boolean
  open: boolean
  icon?: any
  title?: string
  onClose?: () => void
  slots?: {
    HeaderButtons?: React.ElementType
  }
  slotProps?: any
  IconProps?: SvgIconProps
  children?: React.ReactNode
}

const defaultSlots = {}

function Component(props: SidebarProps, ref) {
  const {
    open,
    isEmbed = false,
    icon: Icon,
    title,
    onClose,
    slots,
    slotProps,
    ModalProps,
    PaperProps,
    IconProps,
    children,
    ...rest
  } = props

  const { backdrop: backdropProps, ...restSlotProps } = slotProps ?? {}

  const { sx: modalSx, ...modalProps } = ModalProps ?? {}

  const { sx: paperSx, ...paperProps } = PaperProps ?? {}

  const Slots = {
    ...slots,
    ...defaultSlots
  }

  return (
    <Drawer
      ref={ref}
      disableEnforceFocus
      anchor='right'
      onClose={onClose}
      open={open}
      slotProps={{
        backdrop: {
          invisible: true,
          ...backdropProps
        },
        ...restSlotProps
      }}
      ModalProps={{
        sx: { zIndex: (theme) => theme.zIndex.modal + 3, ...modalSx },
        ...modalProps
      }}
      PaperProps={{
        elevation: 24,
        sx: [
          {
            overflow: 'hidden',
            width: 390,
            maxWidth: '100%',
            left: 'var(--sidebar-offset-left)',
            height: '100%'
          },
          (theme) => ({
            [theme.breakpoints.down('mobile')]: {
              width: isEmbed ? 'calc(100% - 80px)' : '100%',
              height: 'calc(100dvh - var(--sidebar-offset-bottom, 0px))'
            }
          }),
          ...(Array.isArray(paperSx) ? paperSx : [paperSx])
        ],
        ...paperProps
      }}
      {...rest}
    >
      <Scrollbar>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          spacing={3}
          sx={{
            px: 2,
            pt: 1
          }}
        >
          <Stack
            alignItems='inherit'
            direction='row'
            spacing={1}
          >
            {Icon &&
              (isValidElement(Icon) ? (
                Icon
              ) : (
                <SvgIcon
                  {...IconProps}
                  sx={{
                    fontSize: 28
                  }}
                >
                  <Icon />
                </SvgIcon>
              ))}
            <Typography variant='h6'>{title}</Typography>
          </Stack>

          {Slots.HeaderButtons ? (
            <Slots.HeaderButtons {...slotProps.HeaderButtons} />
          ) : (
            onClose && (
              <IconButton
                color='inherit'
                onClick={onClose}
              >
                <SvgIcon>
                  <XClose />
                </SvgIcon>
              </IconButton>
            )
          )}
        </Stack>

        <Divider
          orientation='horizontal'
          sx={{ mt: 1 }}
        />

        {children}
      </Scrollbar>
    </Drawer>
  )
}

const Sidebar = React.forwardRef<unknown, SidebarProps>(Component)

export { Sidebar }
