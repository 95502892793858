import { Box } from '@mui/material'

import { useMemo } from 'react'
import { QuantityColor } from './QuantityColor'

export const QuantityColorList = (props) => {
  const { legend } = props

  const [labels, colors] = useMemo(() => {
    const labels = legend
      .map((value, index) =>
        index === legend.length - 1
          ? value.label.split(' - ')
          : [value.label.split(' - ')[0]]
      )
      .flat()

    const colors = legend.map((item) => item.value)

    return [labels, colors]
  }, [legend])

  return (
    <Box
      component='div'
      sx={[
        (theme) => ({
          mt: '20px',
          mb: 3,
          width: `100%`,
          height: '1.25rem',
          position: 'relative',
          borderRadius: 0.5,
          display: 'flex',
          [theme.breakpoints.down('mobile')]: {
            mt: 1
          }
        })
      ]}
    >
      {labels.map((_, index) => {
        return (
          <QuantityColor
            key={`Legend-${index}`}
            index={index}
            colors={colors}
            labels={labels}
            align={labels.length > colors.length ? 'left' : 'center'}
          />
        )
      })}
    </Box>
  )
}
