const key = 'post-sign-in-redirect'

const save = (pathname) => {
  localStorage.setItem(key, pathname)
}

const get = () => {
  return localStorage.getItem(key)
}

const remove = () => {
  localStorage.removeItem(key)
}

const postSignInRedirect = {
  save,
  get,
  remove
}

export { postSignInRedirect }
