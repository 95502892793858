import _, { includes, isEmpty, merge } from 'lodash'
import { useMemo } from 'react'
import {
  VISUALISATION,
  formatFeaturePropertyValue,
  isMapstackProperty
} from 'utils'

const NumberTypes = ['number', 'percentage', 'integer', 'float']

const { CATEGORY, QUANTITY, NONE } = VISUALISATION

const defaultColumnConfig = {
  minWidth: 150,
  editable: false,
  sortable: true,
  resizable: true,
  flex: 1
}

const prepareColumn = (properties) => {
  if (isEmpty(properties)) return []

  return _.chain(properties)
    .filter((item) => !isMapstackProperty(item.name))
    .sortBy((item) => item.visualisation !== CATEGORY)
    .sortBy((item) => item.visualisation !== NONE)
    .orderBy(['isTitle'], ['desc'])
    .map((item) => {
      return createColumnHeader(item)
    })
    .value()
}

const createColumnHeader = (item) => {
  const { name: key, type, classBreaks, visualisation } = item

  let columnConfig = {}

  if (key === 'msuuid') {
    columnConfig = merge(columnConfig, { hide: true })
  }

  if (includes(NumberTypes, type)) {
    columnConfig = merge(columnConfig, {
      align: 'right'
    })
  }

  return {
    ...defaultColumnConfig,
    ...columnConfig,
    field: key,
    headerName: key,
    type,
    cellClassName: (params) => {
      if (!isEmpty(classBreaks)) {
        const { value, field } = params
        let suffix = ''

        switch (visualisation) {
          case CATEGORY:
            suffix = value
            break

          case QUANTITY:
            for (let i = 0; i < classBreaks.length - 1; i++) {
              const start = classBreaks[i]
              const end = classBreaks[i + 1]
              if (value >= start && value <= end) {
                suffix = `${start}_${end}`
              }
            }
            break
        }

        return `${field}_${suffix}`.replace(/[^\w+$]/gi, '')
      }
    },
    valueFormatter: (item) => {
      const { value } = item
      return formatFeaturePropertyValue({ value, type })
    }
  }
}

const spreadProperties = (item) => {
  const { properties } = item
  const { msuuid: id } = properties
  return {
    id,
    htmlId: `TableRow-${id}`,
    ...properties
  }
}

const useTableResults = ({ filterResults, properties, jsonFeatures }) => {
  const prepareRow = (array) => {
    return _.chain(array).map(spreadProperties).value()
  }

  const prepareRowDataFromFilterResults = useMemo(
    () => prepareRow(filterResults),
    [filterResults]
  )

  const prepareRowDataFromLayerFeatures = useMemo(
    () => prepareRow(jsonFeatures),
    [jsonFeatures]
  )

  const columns = useMemo(() => prepareColumn(properties), [properties])

  const rows = filterResults.length
    ? prepareRowDataFromFilterResults
    : prepareRowDataFromLayerFeatures

  return {
    columns,
    rows
  }
}

export { createColumnHeader, prepareColumn, spreadProperties, useTableResults }
