import _ from 'lodash'
import * as XLSX from 'xlsx'

export const getHeaderRange = (sheet: XLSX.WorkSheet) => {
  // INFO: Get 2D array of sheet data
  const aoa: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 })

  // INFO: Get first 10 rows
  const first10Rows = aoa.slice(0, 10)

  let headerRange = 0
  let headerCount = 0

  first10Rows.forEach((row, index) => {
    const values = _.filter(row, Boolean)
    if (values.length > headerCount) {
      headerCount = values.length
      headerRange = index
    }
  })

  return headerRange
}

export const getCsvFromXlsx = async (file: File) => {
  try {
    const data = await file.arrayBuffer()

    const workbook = XLSX.read(data)
    const sheetName = workbook.SheetNames[0] as string
    const sheet = workbook.Sheets[sheetName] as XLSX.WorkSheet

    const headerRange = getHeaderRange(sheet)

    const jsonData = XLSX.utils.sheet_to_json(sheet, { range: headerRange })

    const csvData = XLSX.utils.sheet_to_csv(XLSX.utils.json_to_sheet(jsonData))

    const csv = new Blob([csvData], { type: 'text/csv' })

    return csv
  } catch (error) {
    throw new Error(
      'We could not read this spreadsheet, please convert it to CSV format and try again.'
    )
  }
}
