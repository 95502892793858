import { graphierQL } from 'utils'
import {
  GetAuthUserMapQuery,
  GetAuthUserMapQueryVariables
} from '~map-viewer/types/__generated/gql/graphql'

const getAuthUserMap = async (mapId) => {
  const { getMapById } = await graphierQL<
    GetAuthUserMapQuery,
    GetAuthUserMapQueryVariables
  >({
    useToken: true,
    query: getAuthUserMapQuery,
    variables: { id: mapId }
  })

  return getMapById
}

const getAuthUserMapQuery = /* GraphQL */ `
  query GetAuthUserMap($id: ID!) {
    getMapById(id: $id) {
      id
      liked
      owned
    }
  }
`

export { getAuthUserMap }
