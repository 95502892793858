import { Box } from '@mui/material'
import { PlateElement } from '@udecode/plate-common'
import clsx from 'clsx'
import React from 'react'

import Paragraph from '~ui-components/components/molecules/TextEditor/components/HTML/Paragraph'
import useIsFirstElement from '~ui-components/components/molecules/TextEditor/hooks/useIsFirstElement'

const ParagraphElement = React.forwardRef((props, ref) => {
  const { className, children, ...rest } = props

  const isFirstElement = useIsFirstElement(props)

  return (
    <PlateElement
      asChild
      ref={ref}
      className={clsx(className)}
      {...rest}
    >
      <Paragraph disabledGutterTop={isFirstElement}>
        <Box component='span'>{children}</Box>
      </Paragraph>
    </PlateElement>
  )
})

ParagraphElement.displayName = 'ParagraphElement'

export { ParagraphElement }
export default ParagraphElement
