import { SvgIcon } from '@mui/material'
import { FilterFunnel01 } from '@untitled-ui/icons-react'
import FloatingToggleButton from '~ui-components/components/molecules/FloatingToggleButton'

const MapActionFilter = (props) => {
  const { filterCounts = 0, active, onClick } = props

  return (
    <FloatingToggleButton
      badge
      data-testid='MapActionFilter'
      active={active}
      options={['filter', '']}
      title='Filters'
      label='Filters'
      icon={
        <SvgIcon>
          <FilterFunnel01 />
        </SvgIcon>
      }
      color={active ? 'secondary' : 'inherit'}
      badgeProps={{
        color: 'error',
        badgeContent: filterCounts
      }}
      onClick={onClick}
    />
  )
}

export { MapActionFilter }
export default MapActionFilter
