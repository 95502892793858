import { Alert, Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import ToggleChipInput from '~ui-components/components/molecules/ToggleChipInput'

const SelectCategoryProperties = (props) => {
  const { options, selected, onChange } = props

  if (options.length <= 0) return null

  return (
    <Box
      data-testid='CreateMap-SelectCategoryProperties'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mb: 2
      }}
    >
      <Typography
        variant='h5'
        fontWeight='600'
      >
        Which values contain a category?
      </Typography>
      <Alert
        severity='info'
        variant='filled'
      >
        <Typography variant='body2'>
          Examples: Sale Status or Winning Party.
        </Typography>
      </Alert>
      <ToggleChipInput
        multiple
        value={selected}
        data={options}
        onChange={onChange}
      />
    </Box>
  )
}

const SelectCategoryPropertiesPropTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.array,
  onChange: PropTypes.func
}

SelectCategoryProperties.propTypes = SelectCategoryPropertiesPropTypes

export { SelectCategoryProperties, SelectCategoryPropertiesPropTypes }
export default SelectCategoryProperties
