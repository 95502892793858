import { Button } from '@mui/material'

const SaveButton = (props) => {
  return (
    <Button
      variant='contained'
      color='primary'
      {...props}
    >
      Save
    </Button>
  )
}

export { SaveButton }
export default SaveButton
