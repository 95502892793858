import { BoundaryNameLookUp } from '../../../get-boundary-lookup'

// Define the individual country-specific objects
const UKBoundaryNames: BoundaryNameLookUp = {
  'MS_uk-regions': {
    singular: 'Region',
    plural: 'Regions'
  },
  'MS_uk-lads': {
    singular: 'Local Council Area',
    plural: 'Local Council Areas'
  },
  'MS_uk-msoas': {
    singular: 'Neighbourhood Zone',
    plural: 'Neighbourhood Zones'
  }
}

export { UKBoundaryNames }
