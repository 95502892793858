import { MARK_BOLD, isMarkActive, toggleMark } from '@udecode/plate'

import { focusEditor } from '@udecode/plate-common'

import { useEditorState } from '@udecode/plate-core'

import Action from '~ui-components/components/molecules/TextEditor/components/Action'

const Bold = (props) => {
  const editor = useEditorState()
  const bold = editor.selection && isMarkActive(editor, MARK_BOLD)

  const handleClick = () => {
    toggleMark(editor, { key: MARK_BOLD })
    focusEditor(editor)
  }

  return (
    <Action
      variant='bold'
      toggled={bold}
      onClick={handleClick}
      {...props}
    />
  )
}

export { Bold }
