import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  WorkspaceCreatorController as Controller,
  WorkspaceCreatorDescriptionForm as Form,
  WorkspaceCreatorStepLayout as Layout
} from '~ui-components/components/organisms/WorkspaceCreatorPanels'

const WorkspaceCreatorStepDescription = (props) => {
  const { value, onChange, onNextClick, onSubmit } = props
  const [loading, setLoading] = useState(false)

  const toggleLoading = async (callback) => {
    setLoading(true)
    await callback()
    setLoading(false)
  }

  const handleNextClick = async () => {
    await toggleLoading(onNextClick)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await toggleLoading(onSubmit)
  }

  return (
    <Layout
      step={4}
      totals={4}
      form={
        <Form
          disabled={loading}
          value={value}
          onChange={onChange}
          onSubmit={handleSubmit}
        />
      }
      controller={
        <Controller
          next
          loadingNext={loading}
          disabledNext={isEmpty(value)}
          onNextClick={handleNextClick}
        />
      }
    />
  )
}

const WorkspaceCreatorStepDescriptionPropTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onNextClick: PropTypes.func,
  onSubmit: PropTypes.func
}

WorkspaceCreatorStepDescription.propTypes =
  WorkspaceCreatorStepDescriptionPropTypes

export {
  WorkspaceCreatorStepDescription,
  WorkspaceCreatorStepDescriptionPropTypes
}
export default WorkspaceCreatorStepDescription
