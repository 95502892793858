import { Box, SvgIcon } from '@mui/material'
import Target05 from '@untitled-ui/icons-react/build/esm/Target05'

const MapCursor = () => {
  return (
    <Box
      data-testid='MapCursor'
      sx={{
        '--map-cursor-size': '24px',
        '--map-cursor-offset': 'calc(var(--map-cursor-size) / 2)',
        position: 'absolute',
        top: 'calc(50% - var(--map-cursor-offset))',
        left: 'calc(50% - var(--map-cursor-offset))',
        userSelect: 'none',
        pointerEvents: 'none'
      }}
    >
      <SvgIcon sx={{ color: 'white', fontSize: 'var(--map-cursor-size)' }}>
        <Target05 />
      </SvgIcon>
    </Box>
  )
}

export { MapCursor }
export default MapCursor
