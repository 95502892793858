import { VISUALISATION } from '../../assets/Constants'
import {
  getCategoryColorRamps,
  getCategoryColorRampsAndValue
} from '../categoryStyle'
import {
  getQuantityColorRamps,
  getQuantityColorRampsAndValue
} from '../quantitiesStyle'

const colorRampsAndValue = {
  [VISUALISATION.CATEGORY]: getCategoryColorRampsAndValue,
  [VISUALISATION.QUANTITY]: getQuantityColorRampsAndValue
}
const colorRamps = {
  [VISUALISATION.CATEGORY]: getCategoryColorRamps,
  [VISUALISATION.QUANTITY]: getQuantityColorRamps
}

export const getColorRampsAndValue = (jenks, type) => {
  return colorRampsAndValue[type]?.(jenks)
}

export const getColorRamps = (jenks, type) => {
  return colorRamps[type]?.(jenks)
}
