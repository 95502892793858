import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon,
  TextField
} from '@mui/material'
import { SearchMd } from '@untitled-ui/icons-react'

// reason `.keyword` is used:
// https://opensearch.org/docs/latest/search-plugins/searching-data/sort/#sort-results
type SortValue = 'name.keyword|asc' | 'createdAt|desc' | 'createdAt|asc'

interface SortOption {
  label: string
  value: SortValue
}

export const mapsSortOptions: SortOption[] = [
  {
    label: 'Name',
    value: 'name.keyword|asc'
  },
  {
    label: 'Newest',
    value: 'createdAt|desc'
  },
  {
    label: 'Oldest',
    value: 'createdAt|asc'
  }
]

const MapsTabTools = ({
  canCreateMap = false,
  onCreateMapClick = () => {},
  SearchMapsInputProps,
  sortValue,
  handleSortChange
}) => {
  return (
    <Stack
      alignItems='center'
      direction='row'
      flexWrap='wrap'
      gap={3}
      sx={{ p: 3 }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <OutlinedInput
          defaultValue=''
          fullWidth
          autoComplete='off'
          placeholder='Search maps'
          startAdornment={
            <InputAdornment position='start'>
              <SvgIcon>
                <SearchMd />
              </SvgIcon>
            </InputAdornment>
          }
          {...SearchMapsInputProps}
        />
      </Box>

      <TextField
        label='Sort By'
        name='sort'
        onChange={(e) => handleSortChange(e.target.value)}
        select
        SelectProps={{ native: true }}
        value={sortValue}
      >
        {mapsSortOptions.map((option) => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </TextField>

      {canCreateMap ? (
        <Button
          data-testid='CreateMapButton'
          onClick={onCreateMapClick}
        >
          Create Map
        </Button>
      ) : null}
    </Stack>
  )
}

export { MapsTabTools }
