import {
  getMapPayload as getMapPayloadCore,
  saveDataToIndexDB
} from '~map-creator/core/functions'

export const saveToLocalStorage = async (
  store,
  context,
  existingMap,
  mapTagCollection
) => {
  const { dataId, json, geojson } = context
  const { id } = existingMap
  const payload = getMapPayloadCore(id, store)
  const { name, properties, license, source } = payload

  await saveDataToIndexDB({
    map: {
      ...existingMap,
      name,
      properties,
      dataId,
      dataReady: false,
      mapTagCollection,
      license,
      source
    },
    json,
    geojson,
    dataIdentifiers: null
  })
}

export const getMapPayload = (store, context, map) => {
  const { dataId } = context

  const { id, mapTagCollection: existingMapTagCollection } = map

  const payload = getMapPayloadCore(id, store)

  const { tagIds, name, properties, license, source, workspaceId } = payload

  const deletedTags = existingMapTagCollection.items
    .map((i) => i.tag)
    .filter((t) => !tagIds.includes(t.id))

  const deletedTagIds = deletedTags.map((t) => t.id)

  const input = {
    id,
    name,
    properties,
    license,
    source,
    dataId,
    workspaceId,
    tagIds,
    deletedTagIds
  }

  return input
}
