import { get, set } from 'idb-keyval'
import { last } from 'lodash'
import { trackPromise } from 'promise-tracker'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { addMapView } from '~map-viewer/api/addMapView'
import { MapError } from '~map-viewer/constants/MapError'
import { MapStatus } from '~map-viewer/constants/MapStatus'
import { useSubmitMap } from '~map-viewer/contexts/map-creator'
import { setSubmitMap } from '~map-viewer/functions/setSubmitMap'
import { setError } from '~map-viewer/states/error'
import {
  setHasDataSet,
  setMapStatus,
  useDataSets,
  useHasDataSet,
  useMapStatus
} from '~map-viewer/states/map'

import {
  getLocalMapById,
  getMapStatus,
  saveDataSet,
  saveMapData,
  subscribeToMapData
} from './utils'

import { useWindowBeforeUnload } from 'ui-components'

export function useInitMapData(mapId, mapData) {
  const dispatch = useDispatch()

  const dataSets = useDataSets()
  const hasDataSet = useHasDataSet()
  const submitMap = useSubmitMap()
  const mapStatus = useMapStatus()

  const mapRef = useRef(null)
  const subscriptionRef = useRef(null)

  const fetchMap = useCallback(async () => {
    // Get the map data from server or indexedDB
    const map = mapData || (await getLocalMapById(mapId))
    mapRef.current = map // cINFO: Break the test if we moved this down

    const mapStatus = await getMapStatus(map)

    if (!map) {
      dispatch(setError(MapError.MAP_NOT_FOUND))
      return
    }

    await saveMapData(map, mapStatus)
    await saveDataSet(map.dataId)

    if (!map.dataReady) return
    await addMapView(mapId)
  }, [dispatch, mapId, mapData])

  const saveMapStatus = useCallback(
    async (mapId, status) => {
      // We prevent saving the map status if the mapId is different (during remix we should not update the mapStatus)
      if (submitMap.id !== mapId) return
      dispatch(setMapStatus(status))
      await set(`${mapId}:map-status`, status)
    },
    [dispatch, submitMap.id]
  )

  const cancelSubscription = () => {
    if (!subscriptionRef.current) return
    subscriptionRef.current.readySubscription.unsubscribe()
    subscriptionRef.current.failSubscription.unsubscribe()
    subscriptionRef.current = null
  }

  const checkIfDataExistLocally = async () => {
    if (hasDataSet) return
    const dataSetId = last(dataSets)
    const dataSet = dataSetId ? await get(dataSetId) : false
    if (dataSet?.features) {
      dispatch(setHasDataSet(true))
    }
  }

  useEffect(() => {
    try {
      trackPromise(fetchMap())
    } catch (error) {
      console.warn(error)
    }
  }, [mapData, fetchMap])

  /**
   * Only subscribe to map data subscription if it is MapStatus.PENDING
   */
  useEffect(() => {
    const currentMap = mapRef.current
    if (!currentMap) return
    if (currentMap.dataReady) return

    switch (submitMap.mapStatus) {
      case MapStatus.IDLE:
        return

      case MapStatus.PENDING:
        subscriptionRef.current = subscribeToMapData(currentMap)
        break

      case MapStatus.FAILED:
        cancelSubscription()
        break
    }

    saveMapStatus(mapId, submitMap.mapStatus)
  }, [mapId, submitMap, saveMapStatus])

  // Force mapStatus to be MapStatus.FAILED
  // if user navigates away from the page during MapStatus.PENDING
  useWindowBeforeUnload(async () => {
    const currentMap = mapRef.current
    if (!currentMap) return
    if (mapStatus === MapStatus.READY) return
    await setSubmitMap(currentMap.id, MapStatus.FAILED)
  })

  return {
    checkIfDataExistLocally
  }
}

export { saveMapData }
