import { css } from '@emotion/react'

const styles = css`
  @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

  .typography,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  td,
  div,
  span,
  p {
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }
`.styles

export { styles }
export default styles
