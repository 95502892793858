import { Box, Slider, SliderProps, TextField } from '@mui/material'
import type { FC } from 'react'
import { useState } from 'react'
import { throttle } from 'utils'

const getStep = (min: number, max: number) => {
  let step = Math.pow(10, Math.floor(Math.log10(Math.ceil((max - min) / 100))))
  if ((min < 1 && max < 1) || (max - min !== 0 && max - min < 1)) {
    const decimalPlaces = Math.max(
      String(min).split('.')[1]?.length || 0,
      String(max).split('.')[1]?.length || 0
    )
    step = 1 / Math.pow(10, decimalPlaces)
  }
  return step
}

const between = (value: number, start: number, end: number) =>
  value >= start && value <= end

const toNumberOrFallback = (value: number, fallback: number) => {
  const string = String(value).replace(/[\s_]/g, '')
  const number = Number(string)
  if (number >= 0 || number <= 0) return number
  return fallback
}

type FilterChangeEvent = {
  type: 'change'
  source: 'input' | 'slider'
}

type ApplyFilterCallback = (arg: FilterChangeEvent, arg1: number[]) => void

interface FilterNumericInputProps {
  defaultProperty: {
    minRange: number
    maxRange: number
  }
  selectedValue: number[]

  onApplyFilter: ApplyFilterCallback
}

const FilterNumericInput: FC<FilterNumericInputProps> = (props) => {
  const { defaultProperty, selectedValue, onApplyFilter } = props
  const { minRange = 0, maxRange = 0 } = defaultProperty

  const [from, setFrom] = useState<number>(selectedValue?.[0] || minRange)
  const [to, setTo] = useState<number>(selectedValue?.[1] || maxRange)

  const step = getStep(defaultProperty?.minRange, defaultProperty?.maxRange)

  const getInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    return value
  }

  const getNextValueComparedToRange = (value: number, fallback: number) => {
    if (between(value, minRange, maxRange)) {
      return value
    } else if (value <= minRange) {
      return minRange
    } else if (value >= maxRange) {
      return maxRange
    }
    return fallback || 0
  }

  const handleFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(getInputValue(event))
    setFrom(value)

    if (between(value, minRange, maxRange)) {
      throttle(() => {
        let nextFrom = toNumberOrFallback(value, minRange)
        nextFrom = Math.min(nextFrom, to)
        nextFrom = getNextValueComparedToRange(nextFrom, value)
        onApplyFilter({ type: 'change', source: 'input' }, [
          toNumberOrFallback(nextFrom, minRange),
          toNumberOrFallback(to, maxRange)
        ])
      }, 200)
    }
  }

  const handleFromBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    const value = Number(getInputValue(event))
    let nextFrom = toNumberOrFallback(value, minRange)
    nextFrom = Math.min(nextFrom, to)
    nextFrom = getNextValueComparedToRange(nextFrom, value)

    setFrom(nextFrom)

    onApplyFilter({ type: 'change', source: 'input' }, [
      toNumberOrFallback(nextFrom, minRange),
      toNumberOrFallback(to, maxRange)
    ])
  }

  const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(getInputValue(event))
    setTo(value)

    if (between(value, minRange, maxRange)) {
      throttle(() => {
        let nextTo = toNumberOrFallback(value, maxRange)
        nextTo = Math.max(nextTo, from)
        nextTo = getNextValueComparedToRange(nextTo, value)

        onApplyFilter({ type: 'change', source: 'input' }, [
          toNumberOrFallback(from, minRange),
          toNumberOrFallback(nextTo, maxRange)
        ])
      }, 200)
    }
  }

  const handleToBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    const value = Number(getInputValue(event))
    let nextTo = toNumberOrFallback(value, maxRange)
    nextTo = Math.max(nextTo, from)
    nextTo = getNextValueComparedToRange(nextTo, value)

    setTo(nextTo)

    onApplyFilter({ type: 'change', source: 'input' }, [
      toNumberOrFallback(from, minRange),
      toNumberOrFallback(nextTo, maxRange)
    ])
  }

  const handleSliderChange: SliderProps['onChange'] = (event, value) => {
    const [fromValue, toValue] = value as number[]

    event.preventDefault()
    setFrom(fromValue!)
    setTo(toValue!)
  }

  const handleSliderChangeCommitted: SliderProps['onChangeCommitted'] = (
    event,
    value
  ) => {
    const [fromValue, toValue] = value as number[]

    event.preventDefault()

    setFrom(fromValue!)
    setTo(toValue!)

    onApplyFilter({ type: 'change', source: 'slider' }, [
      toNumberOrFallback(from, minRange),
      toNumberOrFallback(to, maxRange)
    ])
  }

  return (
    <Box
      className='FilterNumericInput'
      sx={[
        {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          textAlign: 'center'
        }
      ]}
    >
      <Box mx={2}>
        <Slider
          step={step}
          min={minRange}
          max={maxRange}
          value={[
            toNumberOrFallback(from, minRange),
            toNumberOrFallback(to, maxRange)
          ]}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderChangeCommitted}
          disableSwap
          color='success'
          valueLabelDisplay='auto'
          slotProps={{
            thumb: {
              ...{ 'data-testid': 'SliderThumb' } // spread to avoid TS error
            }
          }}
        />
      </Box>

      <TextField
        label='From'
        sx={{ mt: 1 }}
        inputProps={{
          'data-testid': 'FromInput',
          min: minRange,
          max: maxRange,
          autoComplete: 'off'
        }}
        value={from}
        onChange={handleFromChange}
        onBlur={handleFromBlur}
      />
      <TextField
        sx={{ mt: 2 }}
        label='To'
        inputProps={{
          'data-testid': 'ToInput',
          min: minRange,
          max: maxRange,
          autoComplete: 'off'
        }}
        value={to}
        onChange={handleToChange}
        onBlur={handleToBlur}
      />
    </Box>
  )
}

export { FilterNumericInput }
export default FilterNumericInput
