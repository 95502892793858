import { LoadingButton } from '@mui/lab'
import { Box, SvgIcon } from '@mui/material'
import { useEditorState } from '@udecode/plate-core'
import Download01 from '@untitled-ui/icons-react/build/esm/Download01'

const SaveButton = (props) => {
  const { sx, loading = false, onSubmit = () => {} } = props
  const editor = useEditorState()

  const handleClick = () => {
    onSubmit({ type: 'submit' }, editor.children)
  }

  return (
    <LoadingButton
      loading={loading}
      variant='contained'
      size='small'
      sx={[
        { fontWeight: 700, letterSpacing: 'wide' },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      endIcon={
        <SvgIcon sx={{ fontSize: '1.4em' }}>
          <Download01 />
        </SvgIcon>
      }
      onClick={handleClick}
    >
      Save
    </LoadingButton>
  )
}

const Footer = (props) => {
  return (
    <Box
      component='footer'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'flex-end'
        }}
      >
        <SaveButton {...props} />
      </Box>
    </Box>
  )
}

export { Footer }
