import { findNodePath } from '@udecode/plate-common'
import { isCollapsed, isElementEmpty, queryNode } from '@udecode/slate'
import { useFocused, useSelected } from 'slate-react'

export const usePlaceholderState = ({
  hideOnBlur = true,
  query,
  element,
  editor
}) => {
  const focused = useFocused()
  const selected = useSelected()

  const isEmptyBlock = isElementEmpty(editor, element)

  const enabled =
    isEmptyBlock &&
    (!query || queryNode([element, findNodePath(editor, element)], query)) &&
    (!hideOnBlur ||
      (isCollapsed(editor.selection) && hideOnBlur && focused && selected))

  return {
    enabled
  }
}
