// @see https://mui.com/material-ui/customization/default-theme/

export const defaultBorderRadius = 8

const defaultZIndex = {
  mobileStepper: 1000,
  fab: 1050,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500
}

export const fontWeight = {
  hairline: 100,
  extralight: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900
}

export const letterSpacing = {
  tighter: '-0.05em',
  tight: '-0.025em',
  normal: '0em',
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em'
}

export const borderRadius = {
  circular: '50%',
  pilled: '999px',
  squared: '0px',
  xl: '14px',
  lg: '12px',
  md: '8px',
  sm: '4px',
  xs: '2px'
}

export const zIndex = {
  ...defaultZIndex,
  maskLink: 10,
  aboveMaskLink: 11,
  mapCardMenu: 12,
  mapAttribute: defaultZIndex.modal - 1,
  search: defaultZIndex.appBar + 1,
  feedSelector: defaultZIndex.appBar + 2,
  header: defaultZIndex.appBar + 3,
  searchResultsList: defaultZIndex.appBar + 4,
  mapFilterHeader: defaultZIndex.modal + 2,
  mapHeaderModal: defaultZIndex.appBar + 6,
  mapHeader: defaultZIndex.appBar + 7,
  accountMenu: defaultZIndex.tooltip + 8,
  loadingPanel: defaultZIndex.drawer + 1,
  unauthenticatedActionTooltip: defaultZIndex.tooltip + 1,
  mapTable: defaultZIndex.modal - 1,
  mapActions: defaultZIndex.modal + 1,
  sideBar: defaultZIndex.modal + 3,
  visualisationChip: defaultZIndex.modal - 2,
  mobilePanel: defaultZIndex.modal + 10,
  badge: defaultZIndex.fab + 1,
  searchModal: defaultZIndex.modal + 10
}

export const system = {
  borderRadius,
  fontWeight,
  letterSpacing,
  zIndex
}
