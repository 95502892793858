import { Repeat01 } from '@untitled-ui/icons-react'
import { useState } from 'react'

import {
  IconListItem,
  IconListItemProps
} from '~ui-components/components/molecules/IconListItem'
import { UnauthenticatedActionTooltip } from '~ui-components/components/molecules/UnauthenticatedActionTooltip'

type MapRemixButtonProps = IconListItemProps & {
  authenticated: boolean
  onSignInClick: () => void
}

function MapRemixButton(props: MapRemixButtonProps) {
  const { authenticated, onClick, onSignInClick, ...rest } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    if (!authenticated) {
      setAnchorEl(event.currentTarget)
      return
    }

    onClick?.(event)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <UnauthenticatedActionTooltip
        primary='Want to remix this map?'
        secondary='Sign in to get started.'
        anchorEl={anchorEl}
        onClose={handleClose}
        onSignInClick={onSignInClick}
      />

      <IconListItem
        primary='Remix'
        icon={Repeat01}
        onClick={handleClick}
        {...rest}
      />
    </>
  )
}

export { MapRemixButton }
