import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isArray } from 'lodash'
import { Autocomplete, TextField, Chip } from '@mui/material'

/**
 * This component provides an autocomplete chip input field, It is an extended of MUI Autocomplete component.
 * For details of of MUI Autocomplete checking https://mui.com/components/autocomplete/
 */
const ChipInput = (props) => {
  const {
    inputValue: initialInputValue = '',
    value: defaultValue = [],
    options = [],
    onChange,
    onInputChange,
    ...rest
  } = props

  // state
  const value = useMemo(() => defaultValue || [], [defaultValue])
  const [inputValue, setInputValue] = useState(initialInputValue)

  useEffect(() => {
    setInputValue(initialInputValue)
  }, [initialInputValue])

  return (
    <Autocomplete
      data-testid='ChipInput'
      {...rest}
      componentsProps={{
        popper: {
          modifiers: [
            {
              name: 'flip',
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: 'document',
                padding: 8
              }
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'document',
                padding: 8
              }
            }
          ],
          sx: [
            (theme) => ({
              zIndex: theme.zIndex.popper,
              '& .MuiAutocomplete-option': {
                fontSize: '14px'
              },
              '& .MuiAutocomplete-noOptions': {
                color: theme.palette.text.primary
              }
            })
          ]
        }
      }}
      sx={{
        minWidth: '150px',
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          minHeight: '50px'
        }
      }}
      color='light'
      multiple={isArray(value)}
      value={value}
      inputValue={inputValue}
      options={options}
      getOptionLabel={(option) => option}
      renderTags={(value, getTagProps) => {
        return value.map(
          (option, index) =>
            option && (
              <Chip
                key={index}
                data-testid={`chip-value-${index}`}
                color='success'
                label={option}
                {...getTagProps({ index })}
              />
            )
        )
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            color='primary'
            variant='outlined'
          />
        )
      }}
      onChange={(event, nextValue) => {
        if (onChange) {
          onChange(
            event,
            nextValue.filter((value) => value)
          )
        }
      }}
      onInputChange={(event, nextInput) => {
        if (onInputChange) {
          onInputChange(event, nextInput)
        }
        setInputValue(nextInput)
      }}
    />
  )
}

const ChipInputPropTypes = {
  /**
   * A array of default values, only applicable if prop 'controlledState' resolve to true
   */
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  /**
   * Array of options. Mainly array of strings
   */
  options: PropTypes.arrayOf(PropTypes.string),
  /**
   * Callback fired when the value changes.
      Signature:
      function(event, value).
      event: The event source of the callback.
      value: The new value of the component.
   */
  onChange: PropTypes.func
}

ChipInput.propTypes = ChipInputPropTypes

export { ChipInput, ChipInputPropTypes }
export default ChipInput
