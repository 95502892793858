const createErrorClass = (name) => {
  return class CustomError extends Error {
    constructor({ message }) {
      super(message)
      this.name = name
    }
  }
}

export { createErrorClass }
