import { Box, SxProps } from '@mui/material'
import { DeleteButton } from '~ui-components/components/molecules/DeleteButton'
import { DownloadButton } from '~ui-components/components/molecules/DownloadButton'
import { LikeButton } from '~ui-components/components/molecules/LikeButton'
import { RemixIconButton } from '~ui-components/components/molecules/RemixIconButton'
import { SettingButton } from '~ui-components/components/molecules/SettingButton'
import { ShareButton } from '~ui-components/components/molecules/ShareButton'

interface MapButtonsProps {
  sx?: SxProps
  owned?: boolean
  remixable?: boolean
  slots?: {
    LikeButton?: typeof LikeButton
    ShareButton?: typeof ShareButton
    DeleteButton?: typeof DeleteButton
    SettingButton?: typeof SettingButton
    DownloadButton?: typeof DownloadButton
    RemixButton?: typeof RemixIconButton
  }
  slotProps?: {
    LikeButton?: React.ComponentProps<typeof LikeButton>
    ShareButton?: React.ComponentProps<typeof ShareButton>
    DeleteButton?: React.ComponentProps<typeof DeleteButton>
    SettingButton?: React.ComponentProps<typeof SettingButton>
    DownloadButton?: React.ComponentProps<typeof DownloadButton>
    RemixButton?: React.ComponentProps<typeof RemixIconButton>
  }
}

const defaultSlots = {
  LikeButton,
  ShareButton,
  DeleteButton,
  SettingButton,
  DownloadButton,
  RemixButton: RemixIconButton
}

function MapButtons(props: MapButtonsProps) {
  const { sx, owned = false, slots = {}, slotProps = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Box
      sx={[
        {
          width: 'fit-content',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'flex-start',
          flexWrap: 'wrap'
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <Slots.LikeButton
        size='small'
        {...slotProps?.LikeButton}
      />

      <Slots.ShareButton
        size='small'
        {...slotProps?.ShareButton}
      />

      <Slots.RemixButton
        size='small'
        {...slotProps?.RemixButton}
      />

      <Slots.DownloadButton
        size='small'
        {...slotProps?.DownloadButton}
      />

      {owned ? (
        <>
          <Slots.SettingButton size='small' />

          <Slots.DeleteButton
            {...slotProps?.DeleteButton}
            size='small'
          />
        </>
      ) : null}
    </Box>
  )
}

export { MapButtons }
