import { createZustandStore } from '@udecode/plate-common'

/**
 * Creates an origin store using `zustood`.
 *
 * The purpose of this is to keep track of uploads and their progress but only
 * storing the key to the lookup in the Element itself. We do it this way
 * because we don't want to modify the Editor value during the upload or it
 * becomes part of the edit history.
 */
export const createUploadStore = (initialValue = {}) => {
  const { uploads: initialUploads = {} } = initialValue

  return createZustandStore('upload')({ uploads: initialUploads })
    .extendActions((set, get) => ({
      upload: (id, value) => {
        if (value === undefined) {
          const uploads = get.uploads()
          delete uploads[id]
          set.uploads(uploads)
          return
        }

        const uploads = get.uploads()
        const upload = uploads[id] || {}

        set.uploads({
          ...uploads,
          [id]: {
            ...upload,
            ...value
          }
        })
      }
    }))
    .extendSelectors((state, get) => ({
      upload: (id) => {
        const uploads = get.uploads()
        return uploads[id]
      }
    }))
}
