import { SxProps, Avatar, SvgIcon, Fab } from '@mui/material'
import User01Icon from '@untitled-ui/icons-react/build/esm/User01'
import { usePopover } from '~ui-components/hooks/usePopover'
import { User } from '~ui-components/types/__generated/gql/graphql'

import {
  AccountPopover,
  AccountPopoverUser,
  AccountPopoverWorkspace
} from './AccountPopover'

import type { FC } from 'react'

export type AccountButtonUser = Pick<User, 'avatar'> & AccountPopoverUser

interface ProfileButtonsProps {
  sx?: SxProps
  user?: AccountButtonUser
  workspaces?: AccountPopoverWorkspace[]
  onLogout?: () => void
}

export const ProfileButton: FC<ProfileButtonsProps> = (props) => {
  const { sx, user, onLogout, workspaces } = props

  const popover = usePopover<HTMLButtonElement>()

  return (
    <>
      <Fab
        sx={sx}
        aria-label='Profile'
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
      >
        <Avatar
          sx={{ height: 48, width: 48 }}
          src={user?.avatar ?? undefined}
        >
          <SvgIcon>
            <User01Icon />
          </SvgIcon>
        </Avatar>
      </Fab>

      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        user={user}
        workspaces={workspaces}
        onLogout={() => {
          onLogout?.()
          popover.handleClose()
        }}
      />
    </>
  )
}
