import { get } from 'idb-keyval'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useLayerSources } from '~map-viewer/states/layer'
import { useDataSets } from '~map-viewer/states/map'

export const useLayerFeatures = () => {
  const [layerFeatures, setLayerFeatures] = useState(null)
  const storedDataSets = useDataSets()
  const layerSources = useLayerSources()
  const source = _.last(layerSources)

  useEffect(() => {
    async function getFeatureFromGeojson(datasetId) {
      const json = await get(datasetId)
      if (json) {
        const uniqueFeatures = _.chain(json.features)
          .map((val) => {
            const { id, geometry, properties } = val
            return { id, geometry, properties }
          })
          .value()

        const myFeatures = uniqueFeatures.map((feature) => {
          const { msuuid = uuidv4() } = feature.properties
          return {
            ...feature,
            id: msuuid,
            properties: {
              ...feature.properties,
              msuuid
            }
          }
        })
        setLayerFeatures(myFeatures)
      }
    }
    if (storedDataSets.length) getFeatureFromGeojson(_.last(storedDataSets))
  }, [storedDataSets, source])

  return layerFeatures
}
