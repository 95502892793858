import Maximize02 from '@untitled-ui/icons-react/build/esm/Maximize02'
import Minimize02 from '@untitled-ui/icons-react/build/esm/Minimize02'
import FloatingToggleButton from '~ui-components/components/molecules/FloatingToggleButton'

const MapActionFullscreen = (props) => {
  const { active, onToggle, ...rest } = props

  return (
    <FloatingToggleButton
      data-testid='MapActionFullscreen'
      active={active}
      options={[]}
      color={active ? 'primary' : 'inherit'}
      title={active ? 'Exit' : 'Full screen'}
      label={active ? 'Exit' : 'Full screen'}
      icon={active ? <Minimize02 /> : <Maximize02 />}
      onClick={onToggle}
      {...rest}
    />
  )
}

export { MapActionFullscreen }
