import lib from 'slug'

const slug = (str: string): string => {
  return lib(str, {
    replacement: '-', // replace spaces with replacement
    remove: null, // (optional) regex to remove characters
    lower: true, // result in lower case
    charmap: lib.charmap, // replace special characters
    multicharmap: lib.multicharmap, // replace multiple code unit characters
    trim: true, // trim leading and trailing replacement chars
    fallback: true // use base64 to generate slug for empty results
  })
}

export { slug }
