import { createAutoformatPlugin } from '@udecode/plate'
import { autoformatRules } from './autoformatRules'

export default () =>
  createAutoformatPlugin({
    options: {
      rules: [...autoformatRules],
      enableUndoOnDelete: true
    }
  })
