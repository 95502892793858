import {
  OnMapDataReadySubscription,
  OnMapDataReadySubscriptionVariables
} from '~map-viewer/types/__generated/gql/graphql'
import { graphierQLSubscription } from 'utils'

const createDataReadySubscription = (dataId, callback) => {
  const subscription = graphierQLSubscription<
    OnMapDataReadySubscription,
    OnMapDataReadySubscriptionVariables
  >(
    {
      query: ON_MAP_DATA_READY_SUBSCRIPTION,
      variables: { dataId }
    },
    {
      onNext: ({ data }) => {
        if (data && data.onMapDataReady && data.onMapDataReady.dataId) {
          callback()
        }
      },
      onError: console.error
    }
  )
  return subscription
}

const ON_MAP_DATA_READY_SUBSCRIPTION = /* GraphQL */ `
  subscription onMapDataReady($dataId: ID!) {
    onMapDataReady(dataId: $dataId) {
      dataId
    }
  }
`

export { createDataReadySubscription, ON_MAP_DATA_READY_SUBSCRIPTION }
export default createDataReadySubscription
