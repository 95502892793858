import jmespath from 'jmespath'
import { find, isEmpty, isEqual, map } from 'lodash'
import { getBoundingBoxData } from 'map-style'
import { useEffect, useMemo } from 'react'
import { MapType } from '~map-viewer/constants/MapType'

import {
  clearHighlightIds,
  setFilterResults,
  setHighlightIds,
  useFilters
} from '~map-filter-view/states/filter'
import {
  setFitBounds,
  setMapType,
  useJsonFeatures
} from '~map-viewer/states/map'
import { store } from '~map-viewer/states/store'

import { useLayerFeatures } from '~map-viewer/App/hooks/useLayerFeatures'
import { QueryBuilder } from './QueryBuilder'

export const useFilterSelectionSet = () => {
  const jsonFeatures = useJsonFeatures()
  const layerFeatures = useLayerFeatures()
  const filters = useFilters()

  const combinedFeatures = useMemo(() => {
    if (isEmpty(jsonFeatures)) return {}
    return combineJsonAndLayerFeatures(jsonFeatures, layerFeatures)
  }, [jsonFeatures, layerFeatures])

  useEffect(() => {
    if (!isEmpty(combinedFeatures)) {
      performingFiltersOnMapFeatures(filters, combinedFeatures)
    }
  }, [filters, combinedFeatures])

  return []
}

const combineJsonAndLayerFeatures = (jsonFeatures, layerFeatures) => {
  const mergedFeatures = jsonFeatures.map((jsonFeature) => {
    const { msuuid: id } = jsonFeature.properties

    const feature = find(layerFeatures, ['id', id])

    if (!feature)
      return {
        ...jsonFeature,
        id,
        geometry: null
      }

    return {
      ...feature,
      id,
      type: jsonFeature.type
    }
  })

  return mergedFeatures
}

const performingFiltersOnMapFeatures = (filters, features) => {
  const hasFilters = filters.length

  if (!hasFilters) {
    clearFilterResults()
    return
  }

  const filterResults = getFilterResult(features, filters)

  // INFO: Dispatch fitBounds cause map to zooms and pans to the extent of filterResults
  adjustFitBounds(filterResults)
  highlightFilterSelectionSet(filterResults)
  displayFilterResults(filterResults)
}

const highlightFilterSelectionSet = (filterResults) => {
  store.dispatch(setHighlightIds(map(filterResults, 'id')))
}

const displayFilterResults = (filterResults) => {
  store.dispatch(setFilterResults(filterResults))
}

const clearFilterResults = () => {
  store.dispatch(clearHighlightIds())
  store.dispatch(setFilterResults([]))
}

const getFilterResult = (features, filters) => {
  const query = QueryBuilder.create(filters)
  const results = jmespath.search(features, query)
  return results
}

const adjustFitBounds = (results) => {
  const mapType = store.getState().map.mapType
  // Disable fitBounds if there is filter from shared map
  if (isEqual(mapType, MapType.SHARE)) {
    store.dispatch(setMapType(MapType.DEFAULT))
    return
  }

  const bounds = getBoundingBoxData({
    type: 'FeatureCollection',
    features: results
  })

  store.dispatch(setFitBounds(bounds))
}
