import { Button, SvgIcon } from '@mui/material'
import { Link02 } from '@untitled-ui/icons-react'
import { FC, useState } from 'react'

const CopyLinkButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const [copied, setCopied] = useState(false)

  const handleClick = () => {
    setCopied(true)
    onClick()
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <Button
      sx={{ width: 120 }}
      color={copied ? 'inherit' : 'secondary'}
      startIcon={
        <SvgIcon>
          <Link02 />
        </SvgIcon>
      }
      onClick={handleClick}
    >
      {copied ? 'Copied Link' : 'Copy Link'}
    </Button>
  )
}

export { CopyLinkButton }
