import { Visualisations } from './Component'

class VisualisationsPlugin {
  pluginStore

  getPluginName() {
    return 'Visualisations@0.1.0'
  }

  getDependencies() {
    return []
  }

  init(pluginStore) {
    this.pluginStore = pluginStore
  }

  activate() {
    if (typeof window !== 'undefined') {
      // Client-side-only code
      this.pluginStore.executeFunction('Renderer.add', 'visualisations', () => (
        <Visualisations />
      ))
    }
  }

  deactivate() {}
}

export default VisualisationsPlugin
