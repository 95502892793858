import { set } from 'idb-keyval'
import fetch from 'map-data-fetch'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MapError } from '~map-viewer/constants/MapError'
import { setError } from '~map-viewer/states/error'
import { useHasDataSet, useMap } from '~map-viewer/states/map'

export function useInitGeojsonData() {
  const dispatch = useDispatch()
  const map = useMap()
  const dataId = map?.dataId
  const hasDataSet = useHasDataSet()
  const [isSourceLoaded, setIsSourceLoaded] = useState(false)

  useEffect(() => {
    try {
      if (!hasDataSet) handleFreshData(dataId)
    } catch (error) {
      dispatch(setError(MapError.MAP_LOAD_ERROR))
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFreshData = async (dataSetId) => {
    await fetchAndStoreData(dataSetId)
    setIsSourceLoaded(true)
  }

  const fetchAndStoreData = async (dataSetId) => {
    const geojson = await fetch(`public/${dataSetId}`, 'geojson')
    await set(dataSetId, geojson)
  }

  return { isSourceLoaded }
}
