import { isEmpty } from 'lodash'

import { EmptyMemberListFallback } from '~ui-components/components/molecules/EmptyMemberListFallback'
import { MemberListStack } from '~ui-components/components/molecules/MemberLists/MemberListStack'

const MemberListWithFallback = (props) => {
  if (isEmpty(props?.members))
    return (
      <EmptyMemberListFallback
        canInviteMember={props?.canInviteMember}
        onInviteMemberClick={props?.onInviteMemberClick}
      />
    )

  return <MemberListStack {...props} />
}

export { MemberListWithFallback }
