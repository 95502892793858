import { Box, Card, Container, Stack } from '@mui/material'
import { FC } from 'react'

type CategoriesProps = {
  slots: {
    Header: FC
    Action: FC
    List: FC
  }
}

const Categories: FC<CategoriesProps> = ({
  slots: { Header, Action, List }
}) => {
  return (
    <Box
      component='main'
      sx={{ flexGrow: 1, py: 8 }}
    >
      <Container maxWidth='xl'>
        <Stack spacing={4}>
          <Stack
            direction='row'
            justifyContent='space-between'
            spacing={4}
          >
            <Header />
            <Action />
          </Stack>
          <Card>
            <List />
          </Card>
        </Stack>
      </Container>
    </Box>
  )
}

export { Categories }
