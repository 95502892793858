import { castArray } from 'lodash'
import * as qs from 'qs'

interface GetSearchQueryParams {
  sort?: boolean | string
  tags?: string[]
  from?: number
  size?: number
  keywords?: string
}

const getSearchQuery = (params: GetSearchQueryParams) => {
  const {
    sort: unsafeSort,
    tags: unsafeTags,
    keywords,
    from: unsafeFrom,
    size: unsafeSize
  } = params || {}

  const query = getUrlQuery()

  const sort = getSort(query, unsafeSort)

  const tags = castArray(unsafeTags || query?.tags || [])

  const searchParams = qs.stringify(
    {
      ...(sort ? { sort } : {}),
      from: unsafeFrom,
      size: unsafeSize,
      tags,
      keywords
    },
    {
      arrayFormat: 'repeat',
      skipNulls: true
    }
  )

  return `/search?${searchParams}`
}

const getSort = (query, initial) => {
  if (initial === false) return undefined
  return initial || query?.sort || 'all'
}

const getUrlQuery = () => {
  // INFO: prevent error when running on server side
  // INFO: server side run on node hence window/document is undefined
  if (typeof document === 'undefined') return {}
  const url = new URL(String(document.location))
  const query = qs.parse(url.search, {
    ignoreQueryPrefix: true
  })

  return query
}

export { getSearchQuery }
