import { LOCATION_MARKER_IMAGE_ID } from 'map-style'
import { useEffect } from 'react'
import { MapLibreRef } from '~ms-map-gl/components/Map'
import locationIcon from '~ms-map-gl/assets/images/location.png'

const useImageSymbol = (mapGL?: MapLibreRef) => {
  useEffect(() => {
    const addImage = async () => {
      if (!mapGL) return

      if (!mapGL.getMap().hasImage(LOCATION_MARKER_IMAGE_ID)) {
        const image = await mapGL.loadImage(locationIcon.src)
        mapGL.addImage(LOCATION_MARKER_IMAGE_ID, image.data)
      }
    }

    addImage()
  }, [mapGL])
}

export { useImageSymbol }
