import {
  Alert,
  Box,
  Checkbox,
  Stack,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material'
import Check from '@untitled-ui/icons-react/build/esm/Check'
import XClose from '@untitled-ui/icons-react/build/esm/XClose'
import PropTypes from 'prop-types'
import React from 'react'
import SelectInput from '~ui-components/components/molecules/SelectInput'

const IncludeProperties = (props) => {
  const {
    defaultPropertyOptions,
    configurations,
    includedProperties,
    onPropertyNameChange,
    onPropertyTypeChange,
    onPropertyVisibilityChange,
    error
  } = props

  const inputsRef = React.useRef({})

  return (
    <Stack
      gap={3}
      data-testid='CreateMap-IncludeProperties'
    >
      <PropertyNameInfo />

      {error && (
        <Alert
          id='selected-over-limit-alert'
          data-testid='selected-over-limit-alert'
          severity='warning'
        >
          <Typography variant='body2'>{error}</Typography>
        </Alert>
      )}

      <Stack gap={1}>
        {Object.keys(configurations).map((key) => {
          const config = configurations[key]
          const propertyName = config.name
          const included = includedProperties.includes(key)

          return (
            <Box
              key={key}
              display='flex'
              alignItems='center'
              gap={3}
              data-testid={`PropertyOption-${key}`}
            >
              <Checkbox
                data-testid={`PropertyOption-Checkbox-${key}`}
                checked={included}
                onChange={(_e, checked) => {
                  onPropertyVisibilityChange(key, checked)

                  if (checked && inputsRef.current[key]) {
                    // slight delay to allow for UI updates from states to be enabled before focus
                    setTimeout(() => {
                      inputsRef.current[key].focus()
                    }, 10)
                  }
                }}
              />

              <TextField
                variant='outlined'
                data-testid={`PropertyOption-TextField-${key}`}
                inputRef={(ref) => (inputsRef.current[key] = ref)}
                fullWidth
                disabled={!included}
                sx={{ width: { md: '280px' } }}
                value={propertyName || ''}
                onChange={(e) => onPropertyNameChange(key, e.target.value)}
              />
              <SelectInput
                testId={`PropertyOption-SelectInput-${key}`}
                sx={{ width: '120px' }}
                disabled={!included}
                label='Type'
                options={defaultPropertyOptions[key]}
                value={config.type}
                onChange={(value) => onPropertyTypeChange(key, value)}
              />
            </Box>
          )
        })}
      </Stack>
    </Stack>
  )
}

const IncludePropertiesPropTypes = {
  defaultPropertyOptions: PropTypes.object,
  configurations: PropTypes.object,
  includedProperties: PropTypes.arrayOf(PropTypes.string).isRequired,
  onPropertyNameChange: PropTypes.func.isRequired,
  onPropertyVisibilityChange: PropTypes.func,
  onPropertyTypeChange: PropTypes.func.isRequired,
  error: PropTypes.string
}

IncludeProperties.propTypes = IncludePropertiesPropTypes

export { IncludeProperties, IncludePropertiesPropTypes }
export default IncludeProperties

const PropertyNameInfo = () => {
  return (
    <Typography variant='body2'>
      <Box
        component='span'
        display={{ md: 'flex' }}
        alignItems='center'
        gap={2}
      >
        <Box
          mt={{ xs: 1 }}
          component='span'
          display='flex'
          alignItems='center'
          color='primary.main'
        >
          <SvgIcon fontSize='small'>
            <Check />
          </SvgIcon>
          <Typography
            lineHeight={1}
            component='span'
            color='inherit'
            variant='body2'
          >
            &nbsp; Good: Population 0-15 Years
          </Typography>
        </Box>
        <Box
          mt={{ md: 1 }}
          component='span'
          display='flex'
          alignItems='center'
          color='error.main'
        >
          <SvgIcon fontSize='small'>
            <XClose />
          </SvgIcon>
          <Typography
            lineHeight={1}
            color='inherit'
            component='span'
            variant='body2'
          >
            &nbsp; Bad: pop-0-15
          </Typography>
        </Box>
      </Box>
    </Typography>
  )
}
