import { useRouter } from 'next/router'
import { LoginButton as Button } from 'ui-components'
import { getSignInLink } from 'utils'

const LoginButton = (props) => {
  const router = useRouter()
  return <Button onClick={() => router.push(getSignInLink())} />
}

export { LoginButton }
