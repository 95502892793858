import { useEffect } from 'react'
import { batch, useDispatch } from 'react-redux'

import {
  setFeatureTypeLoading,
  setSuggestedFeatureTypes,
  useCoverageArea
} from '~map-creator/core/states/map'

const PREDEFINED_LIST = [
  { name: 'Country' },
  { name: 'State' },
  { name: 'Province' },
  { name: 'Division' },
  { name: 'Region' },
  { name: 'County' },
  { name: 'Area' },
  { name: 'District' },
  { name: 'Commune' },
  { name: 'Village' }
]

const useSuggestedFeatureTypes = () => {
  const dispatch = useDispatch()

  const { selectedSuggestion, name: selectedName } = useCoverageArea()

  useEffect(() => {
    async function start() {
      batch(() => {
        dispatch(setFeatureTypeLoading(true))
        dispatch(setSuggestedFeatureTypes([]))
      })

      const list = PREDEFINED_LIST

      batch(() => {
        dispatch(setFeatureTypeLoading(false))
        dispatch(setSuggestedFeatureTypes(list))
      })
    }

    start()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedName, selectedSuggestion])
}

export { PREDEFINED_LIST, useSuggestedFeatureTypes }
