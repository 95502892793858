import { graphierQL } from 'utils'
import {
  GetMapRemixCollectionQuery,
  GetMapRemixCollectionQueryVariables
} from '~map-viewer/types/__generated/gql/graphql'

const getMapRemixCollection = async (mapId, limit, offset) => {
  const response = await graphierQL<
    GetMapRemixCollectionQuery,
    GetMapRemixCollectionQueryVariables
  >({
    query: getMapRemixCollectionQuery,
    variables: { id: mapId, limit, offset }
  })
  return response
}

const getMapRemixCollectionQuery = /* GraphQL */ `
  query GetMapRemixCollection($id: ID!, $limit: Int, $offset: Int) {
    getMapById(id: $id) {
      id
      mapRemixCollection(limit: $limit, offset: $offset) {
        total
        items {
          childMap {
            id
            name
            views
            createdAt
            workspace {
              id
              name
              description
              logo
            }
          }
        }
      }
    }
  }
`

export { getMapRemixCollection }
