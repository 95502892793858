import { TextEditorFooter as View } from 'ui-components'
import { useMapId } from '~map-viewer/states/map'

import { useSaveReadmeMutation } from '~map-viewer/services/map'

const mappingStatus = {
  fulfilled: 'success',
  pending: 'loading',
  rejected: 'error'
}

const Footer = (props) => {
  const mapId = useMapId()
  const [trigger, { isLoading }] = useSaveReadmeMutation()

  const handleSubmit = (event, nodes) => {
    trigger({ mapId, nodes })
  }

  return (
    <View
      {...props}
      loading={isLoading}
      onSubmit={handleSubmit}
    />
  )
}

export { Footer }
export default Footer
