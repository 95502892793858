import _, { cloneDeep, get, includes, map } from 'lodash'

import { captureMessage } from '@sentry/nextjs'
import {
  VISUALISATION,
  getAllGeojsonFeatureProperties,
  getTypeOfEachProperties,
  isNumericType
} from 'utils'
import { getPropertyTypeOptions } from '~map-creator/core/utilities/getPropertyTypeOptions'

const { CATEGORY, QUANTITY, NONE } = VISUALISATION

const findPropertiesUniqueValues = (
  geojson,
  geojsonProperties,
  allowsUniqueLimit
) => {
  const properties = cloneDeep(geojsonProperties)
  const uniqueCountByPropertyName = countUniqueValuesGeojsonByProperties(
    geojson,
    properties
  )

  const categoryProperties = uniqueCountByPropertyName
    .filter(({ count, type }) => count <= allowsUniqueLimit && type === 'text')
    .map(({ name }) => name)

  const uniqueProperties = uniqueCountByPropertyName
    .filter(({ isUnique }) => isUnique)
    .map(({ name }) => name)

  return {
    categoryProperties,
    uniqueProperties
  }
}

const processGeojsonProperties = (geojson, allowsUniqueLimit) => {
  const featureProperties = getAllGeojsonFeatureProperties(geojson)
  const geojsonProperties = _.values(getTypeOfEachProperties(featureProperties))
    .filter(noMSUUID)
    .map((property) => {
      const { name, type } = property
      const propertyValues = map(featureProperties, name)
      return {
        ...property,
        options: getPropertyTypeOptions(propertyValues, type)
      }
    })

  const { categoryProperties, uniqueProperties } = findPropertiesUniqueValues(
    geojson,
    geojsonProperties,
    allowsUniqueLimit
  )

  return {
    geojsonProperties,
    categoryProperties,
    uniqueProperties
  }
}

const noMSUUID = (obj) => String(obj.name).toLowerCase() !== 'msuuid'

const countUniqueValuesGeojsonByProperties = (geojson, properties) => {
  const features = get(geojson, 'features')

  const uniqueCountByPropertyName = _.chain(properties)
    .map((property) => ({
      count: _.chain(features)
        .map('properties')
        .countBy(property.name)
        .size()
        .value(),
      ...property
    }))
    .map((property) => ({
      isUnique: property.count === features.length,
      ...property
    }))
    .value()

  return uniqueCountByPropertyName
}

const catchErrors = (data, callback) => {
  const { errors } = data
  if (_.isEmpty(errors)) return
  const mappedErrors = _.map(errors, (val, key) => {
    const { type, title, description, errors: trace } = val

    // INFO: Send Validate Error to sentry for tracking
    captureMessage(`${title}: ${description}`)

    const errorTitle = trace.length > 1 ? `${title}: <br>` : `${title}: `
    const errorMessage = errorTitle + _.join(val.errors, '<br>')
    return { id: key, type, message: errorMessage }
  })
  callback(mappedErrors)
  return data
}

const getPropertiesConfigs = (geojsonProperties, categoryProperties) => {
  const numberProperties = geojsonProperties
    .filter((o) => isNumericType(o.type))
    .map(({ name }) => name)

  const defaultConfigs = Object.fromEntries(
    geojsonProperties.map((property) => {
      const { name, options } = property

      let visualisation = NONE
      if (includes(numberProperties, name)) visualisation = QUANTITY
      else if (includes(categoryProperties, name)) visualisation = CATEGORY

      return [
        name,
        {
          isTitle: false,
          isDefaultVisualisation: false,
          name: cleanPropertyName(name),
          isAggregate: false,
          type: property.type,
          isPercentage: false,
          visualisation,
          options
        }
      ]
    })
  )

  const configs = Object.fromEntries(
    geojsonProperties.map((property) => {
      return [
        property.name,
        {
          isTitle: false,
          isDefaultVisualisation: false,
          name: cleanPropertyName(property.name),
          isAggregate: false,
          type: property.type,
          isPercentage: false,
          visualisation: VISUALISATION.NONE
        }
      ]
    })
  )

  return { defaultConfigs, configs }
}

const cleanPropertyName = (string) =>
  String(string)
    .replace(/[`~!@#$%^&*()_|+\-=?;:' ",.]/gi, '_')
    .split('_')
    .filter(Boolean)
    .map((s) => s[0].toUpperCase() + s.slice(1))
    .join(' ')

export const utils = {
  countUniqueValuesGeojsonByProperties,
  processGeojsonProperties,
  catchErrors,
  getPropertiesConfigs
}
