import { useState } from 'react'
import { Link } from 'ui-components'
import { getCreateMapLink } from 'utils'

import {
  IncludeProperties,
  MapSettings,
  SelectDefaultView
} from '~map-creator/core/App/CreateMap/Steps'
import { useWorkspaceId } from '~map-creator/core/hooks/useWorkspaceId'
import { useWorkspaceName } from '~map-creator/core/hooks/useWorkspaceName'

import {
  DataUploadGeojson,
  DefineFeatureTitle,
  DefineMapArea,
  DefineMapFeature,
  SelectUniqueIdentifier,
  SelectVisualisations,
  UploadSuccess
} from '~map-creator/map-creator-geojson/App/CreateMap/Steps'

const useSteps = (params) => {
  const { isSubmitting = false, goToNextStep } = params

  const workspaceName = useWorkspaceName()
  const workspaceId = useWorkspaceId()

  const [isMapAreaValid, setIsMapAreaValid] = useState(false)
  const [isMapFeatureValid, setIsMapFeatureValid] = useState(false)
  const [isFeatureTitleValid, setIsFeatureTitleValid] = useState(false)
  const [isPropertySettingsValidated, setIsPropertySettingsValidated] =
    useState(false)
  const [isIncludedPropertiesValid, setIsIncludedPropertiesValid] =
    useState(false)
  const [isVisualisationValid, setIsVisualisationValid] = useState(false)
  const [isDefaultViewValid, setIsDefaultViewValid] = useState(false)

  const handleMapSettingsValidated = setIsPropertySettingsValidated

  const dataUploadStep = {
    title: "Let's get started by adding some data to your map",
    subtitle: (
      <>
        This is a beta release and the data must be in <b>GeoJSON format</b>.
        The GeoJSON features must be <b>polygons</b> and contain at least{' '}
        <b>one number attribute</b> suitable for a quantity legend. You can also
        try our{' '}
        <Link
          variant='inherit'
          color='inherit'
          sx={{
            textDecorationColor: 'rgba(255, 255, 255, 0.2)'
          }}
          href={getCreateMapLink({ workspaceId, workspaceName })}
          underline='always'
        >
          CSV upload feature
        </Link>
        .
      </>
    ),
    content: <DataUploadGeojson onValidated={goToNextStep} />,
    noBack: true,
    noNext: true,
    showStepCounter: false
  }

  const uploadSuccessStep = {
    title: "Great, we have data. Now let's generate an awesome map!",
    content: <UploadSuccess />,
    showStepCounter: false
  }

  const defineMapAreaStep = {
    title: 'What name best describes the coverage area of this map?',
    subtitle: 'The coverage area of this map is the area highlighted in green.',
    content: <DefineMapArea onValidated={setIsMapAreaValid} />,
    disabledBack: false,
    disabledNext: !isMapAreaValid,
    showStepCounter: true
  }

  const defineMapFeatureStep = {
    title: 'What name best describes the features contained in this map?',
    subtitle: 'The map features are the items outlined in yellow.',
    content: <DefineMapFeature onValidated={setIsMapFeatureValid} />,
    disabledBack: false,
    disabledNext: !isMapFeatureValid,
    showStepCounter: true
  }

  const includePropertiesStep = {
    title: "Let's make the data user friendly",
    subtitle: 'Only include information and provide easy to understand names.',
    content: <IncludeProperties onValidated={setIsIncludedPropertiesValid} />,
    disabledBack: false,
    disabledNext: !isIncludedPropertiesValid,
    showStepCounter: true
  }

  const defineFeatureTitleStep = {
    title: 'Which value should we use as the name/title?',
    subtitle:
      'The title is highlighted in green. You can click on other feature to explore the data.',
    content: <DefineFeatureTitle onValidated={setIsFeatureTitleValid} />,
    disabledBack: false,
    disabledNext: !isFeatureTitleValid,
    showStepCounter: true
  }

  const selectUniqueIdentifierStep = {
    title: 'Is one of these values a unique identifier?',
    subtitle: 'Examples: ID, zipcode, country code, fip code.',
    content: <SelectUniqueIdentifier />,
    disabledBack: false,
    disabledNext: false,
    showStepCounter: true
  }

  const selectVisualisationStep = {
    content: <SelectVisualisations onValidated={setIsVisualisationValid} />,
    disabledBack: false,
    disabledNext: !isVisualisationValid,
    showStepCounter: true
  }

  const selectDefaultViewStep = {
    title: 'Which map view should be the default?',
    subtitle: 'Examples: Population or Total sales.',
    content: <SelectDefaultView onValidated={setIsDefaultViewValid} />,
    disabledBack: false,
    disabledNext: !isDefaultViewValid,
    showStepCounter: true
  }

  const mapSettingsStep = {
    title: 'Name your map',
    subtitle:
      'A good title is descriptive and easy to understand (e.g US County Socioeconomic Map).',
    content: <MapSettings onValidated={handleMapSettingsValidated} />,
    disabledBack: false,
    disabledNext: isSubmitting || !isPropertySettingsValidated,
    showStepCounter: true
  }

  return [
    dataUploadStep,
    uploadSuccessStep,
    defineMapAreaStep,
    defineMapFeatureStep,
    includePropertiesStep,
    defineFeatureTitleStep,
    selectUniqueIdentifierStep,
    selectVisualisationStep,
    selectDefaultViewStep,
    mapSettingsStep
  ]
}

export { useSteps }
