import * as qs from 'qs'

type Form = 'email' | 'register' | 'verify'

function getSignInLink(form?: Form, data?: Record<string, any>) {
  if (!form) return '/sign-in'
  const query = qs.stringify(data)
  return `/sign-in/${form}/${query ? `?${query}` : ''}`
}

export { getSignInLink }
