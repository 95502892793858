import { Box, Container, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const WorkspaceCreatorStepLayout = (props) => {
  const { totals, step, form, controller, ...rest } = props

  return (
    <Container
      maxWidth={false}
      sx={{ maxWidth: '1096px' }}
      {...rest}
    >
      <Stack
        direction='column'
        mb={5}
      >
        <Box
          data-testid='Step'
          component={Typography}
          variant='overline'
          textTransform='uppercase'
        >
          Step {step} of {totals}
        </Box>
        {form && <Box>{form}</Box>}
        {controller}
      </Stack>
    </Container>
  )
}

WorkspaceCreatorStepLayout.propTypes = {
  totals: PropTypes.number,
  step: PropTypes.number,
  form: PropTypes.node,
  controller: PropTypes.node
}

export { WorkspaceCreatorStepLayout }
export default WorkspaceCreatorStepLayout
