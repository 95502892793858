import { useMediaQuery, useTheme } from '@mui/material'
import { useWindowSize } from 'ui-components'

const mapDimensions = {
  browser: { width: 0.6, height: 1 },
  mobile: { width: 1, height: 0.7 }
}

const useMapDimension = () => {
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))
  const { width: fullWidth, height: fullHeight } = useWindowSize()

  const { width, height } = isBrowser
    ? {
        width: fullWidth * mapDimensions.browser.width,
        height: fullHeight * mapDimensions.browser.height
      }
    : {
        width: fullWidth * mapDimensions.mobile.width,
        height:
          fullWidth * mapDimensions.mobile.width * mapDimensions.mobile.height
      }

  return { width, height }
}

export { useMapDimension }
