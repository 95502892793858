import { Button, SvgIcon } from '@mui/material'
import Edit01 from '@untitled-ui/icons-react/build/esm/Edit01'

const EditButton = (props) => {
  return (
    <Button
      disableElevation
      variant='contained'
      color='inherit'
      startIcon={
        <SvgIcon sx={{ fontSize: '1.25em !important' }}>
          <Edit01 />
        </SvgIcon>
      }
      {...props}
    >
      Edit
    </Button>
  )
}

export { EditButton }
export default EditButton
