import { createPluginFactory } from '@udecode/plate'
import { createUploadStore } from './createUploadStore'
import { retryUploadImage } from './retryUploadImage'
import { uploadImage } from './uploadImage'

const KEY_ASSETS = 'cloud'

const withCloudUpload = (editor, plugin) => {
  const { createFileUpload } = plugin.options

  const uploadStore = createUploadStore()
  const uploadEventsDict = {}

  const cloud = {
    createFileUpload,

    uploadStore,

    uploadEvents: {
      set: (id, event) => {
        uploadEventsDict[id] = event
      },

      abort: (id) => {
        if (!uploadEventsDict[id]) return
        console.warn('[CloudUploadPlugin]: aborting request', id)
        uploadEventsDict[id]?.abort()
        delete uploadEventsDict[id]
      }
    },

    uploadImage: (file) => uploadImage(editor, file),
    uploadImages: (files) => uploadImages(editor, files),
    retryUploadImage: (id) => retryUploadImage(editor, id)
  }

  editor.cloud = cloud
  return editor
}

const uploadImages = (editor, files) => {
  for (const file of files) {
    uploadImage(editor, file).catch(console.warn)
  }
}

const CloudUploadPlugin = createPluginFactory({
  key: KEY_ASSETS,
  withOverrides: withCloudUpload
})

export { CloudUploadPlugin }
