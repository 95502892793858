import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Button,
  Chip,
  FormLabel,
  SvgIcon,
  TextField,
  Tooltip
} from '@mui/material'
import Link02 from '@untitled-ui/icons-react/build/esm/Link02'
import { isEmpty } from 'lodash'
import { Fragment, useState } from 'react'
import { getKeyCode } from 'utils'
import Dialog, {
  DialogActions
} from '~ui-components/components/molecules/Dialog'
import SnackAlert from '~ui-components/components/molecules/SnackAlert'

const KEY_BACKSPACE = 8
const KEY_ENTER = 13
const KEY_SPACE = 32
const KEY_COMMA = 188
/* USE FOR MOBILE BROWSER CHAR CODE DETECTION FROM TEXT VALUE */
const KEY_COMMA_DERIVED_FROM_STRING = 44
const KEYS_ALLOWED_TO_ADD = [
  KEY_ENTER,
  KEY_SPACE,
  KEY_COMMA,
  KEY_COMMA_DERIVED_FROM_STRING
]

const InviteMemberDialog = (props) => {
  const {
    open,
    workspace,
    diabledCopiedLinkButton,
    onEmailValidate = () => {},
    onCopiedLinkClick = () => {},
    onSendInvitesClick = () => {},
    onClose = () => {}
  } = props
  const [error, setError] = useState('')
  const [emails, setEmails] = useState([])
  const [linkCopied, setLinkCopied] = useState(false)
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const [currentValue, setCurrentValue] = useState('')

  const addEmail = (unsafeEmail) => {
    const newEmail = unsafeEmail.trim()
    setEmails((arr) => (arr.includes(newEmail) ? arr : [...arr, newEmail]))
    setCurrentValue('')
    setError('')
  }

  const handleInputKeyDown = (event) => {
    const keyCode = event.keyCode
    const value = event.target.value

    if (keyCode === KEY_BACKSPACE && isEmpty(value)) {
      setEmails((emails) => {
        const nextEmails = [...emails]
        nextEmails.pop()
        return nextEmails
      })
    }

    if (!KEYS_ALLOWED_TO_ADD.includes(keyCode)) return event

    event.preventDefault()
    event.stopPropagation()
    if (!isEmail(value)) {
      setError('Email format is incorrect')
      return event
    }
    if (onEmailValidate && onEmailValidate(value)) {
      setError('This email is already a member')
      return event
    }
    addEmail(value)
  }

  const handleTextFieldChange = (_, value) => {
    /* DETECT KEY CODE FROM INPUT VALUE, SINCE MOBILE BROWSER INPUT WONT SEND KEYCODE */
    const keyCode = getKeyCode(value.charAt(value.length - 1))
    if (KEYS_ALLOWED_TO_ADD.includes(keyCode))
      return addEmail(value.replaceAll(/,| /gi, ''))

    setCurrentValue(value)
  }

  const handleEmailChipDeleteClick = (value) => {
    setEmails((emails) => emails.filter((email) => email !== value))
  }

  const toggleLinkCopied = () => {
    setLinkCopied((bool) => !bool)
  }

  const handleCopyInviteLinkClick = async () => {
    toggleLinkCopied()
    onCopiedLinkClick()
    await wait(1000)
    toggleLinkCopied()
  }

  const toggleSent = () => {
    setSent((bool) => !bool)
  }

  const handleNotificationSent = async () => {
    toggleSent()
    onClose()
    await wait(4000)
    toggleSent()
  }

  const toggleSending = () => {
    setSending((bool) => !bool)
  }

  const handleSendInvitesClick = async (event) => {
    toggleSending()
    await onSendInvitesClick(event, emails)
    handleNotificationSent()
    toggleSending()
    setEmails([])
  }

  const disabledSendInvites = isEmpty(emails) || diabledCopiedLinkButton

  return (
    <Fragment>
      <SnackAlert
        severity='success'
        open={sent}
        onClose={toggleSent}
      >
        Invites sent
      </SnackAlert>

      <Dialog
        open={open}
        onClose={onClose}
        title={`Invite member to ${workspace?.name || 'workspace'}`}
        dialogActionsComponent={
          <DialogActions
            sx={{
              justifyContent: 'space-between'
            }}
          >
            <Tooltip
              arrow
              title={linkCopied ? 'Link copied' : 'Copy to clipboard'}
            >
              <Button
                data-testid='CopiedLinkButton'
                disabled={diabledCopiedLinkButton}
                variant='outlined'
                color='primary'
                onClick={handleCopyInviteLinkClick}
              >
                <SvgIcon>
                  <Link02 />
                </SvgIcon>
                &nbsp;&nbsp;Copy invite link
              </Button>
            </Tooltip>

            <LoadingButton
              data-testid='SendInvitesButton'
              variant='contained'
              loading={sending}
              disabled={disabledSendInvites}
              onClick={handleSendInvitesClick}
            >
              Send invites
            </LoadingButton>
          </DialogActions>
        }
      >
        <FormLabel sx={{ typography: 'body2' }}>
          {'Press <space> or <comma> to add an an email'}
        </FormLabel>

        <Autocomplete
          data-testid='InviteMemberAutocomplete'
          sx={{
            mt: 1,
            '.MuiInputBase-root.MuiOutlinedInput-root': {
              alignItems: 'flex-start',
              justifyContent: 'flex-start'
            }
          }}
          multiple
          options={[]}
          value={emails}
          inputValue={currentValue}
          onInputChange={handleTextFieldChange}
          disableClearable
          freeSolo
          renderTags={(values, getTagProps) =>
            values.map((value, index) => (
              <Chip
                {...getTagProps({ index })}
                data-testid={`ChipEmail-${value}`}
                key={index}
                variant='default'
                label={value}
                onDelete={() => handleEmailChipDeleteClick(value)}
              />
            ))
          }
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant='outlined'
                inputProps={{
                  ...params.inputProps,
                  'data-testid': 'InviteMemberInput'
                }}
                FormHelperTextProps={{
                  'data-testid': 'InviteMemberFormHelperText'
                }}
                error={!isEmpty(error)}
                helperText={error}
                onKeyDown={handleInputKeyDown}
                autoFocus
                multiline
                rows={5}
              />
            )
          }}
        />
      </Dialog>
    </Fragment>
  )
}

export { InviteMemberDialog }

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const isEmail = (string) => String(string).match(EMAIL_REGEX)

const wait = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
