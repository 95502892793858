import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import SearchMdIcon from '@untitled-ui/icons-react/build/cjs/SearchMd'

export const SearchIcon = (props) => {
  return (
    <IconButton
      sx={{
        alignItems: 'center',
        display: 'flex',
        flex: 1
      }}
      {...props}
    >
      <SvgIcon>
        <SearchMdIcon />
      </SvgIcon>
    </IconButton>
  )
}
