import { Stack, Switch, SwitchProps, Typography } from '@mui/material'

type ApplyFiltersInputProps = SwitchProps & {
  disabled?: boolean
  checked?: boolean
}

function ApplyFiltersInput(props: ApplyFiltersInputProps): JSX.Element {
  const { disabled, checked = false, onChange, ...rest } = props

  return (
    <Stack spacing={1}>
      <Typography variant='subtitle2'>Apply Filters</Typography>
      <Stack direction='row'>
        <Typography
          color='text.secondary'
          variant='body2'
          sx={{ flex: 2 }}
        >
          Only include records that match the current filter results.
        </Typography>
        <Stack
          flex={1}
          direction='row'
          justifyContent='flex-end'
        >
          <Switch
            disabled={disabled}
            color='secondary'
            checked={checked}
            onChange={onChange}
            {...rest}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export { ApplyFiltersInput }
