import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  Children,
  Fragment,
  cloneElement,
  createElement,
  isValidElement
} from 'react'
import Strong from '~ui-components/components/atoms/Strong'

const lowerCaseCompare = (a, b) =>
  String(a).toLowerCase() === String(b).toLowerCase()

const comparator = (a, b, options) => {
  const { ignoreCase = false } = options || {}
  if (!ignoreCase) return a === b
  return lowerCaseCompare(a, b)
}

const EmphasizeText = (props) => {
  const { children, ...rest } = props

  if (Children.count(children) > 1) {
    return Children.toArray(children).map((child, index) => {
      if (typeof child === 'string')
        return (
          <EmphasizeText
            {...rest}
            key={index}
          >
            {child}
          </EmphasizeText>
        )

      return cloneElement(child, {
        children: (
          <EmphasizeText
            {...rest}
            key={index}
          >
            {child.props?.children}
          </EmphasizeText>
        )
      })
    })
  }

  if (isValidElement(children)) {
    return cloneElement(children, {
      children: (
        <EmphasizeText {...rest}>{children.props?.children}</EmphasizeText>
      )
    })
  }

  const { component = Fragment, highlight, ignoreCase, ...others } = rest
  const options = { ignoreCase }

  if (_.isEmpty(highlight)) return children || null

  const searchRegex = new RegExp(
    `(${highlight.replace(/[.\\+*?[^\]$(){}=!<>|:-]/g, '\\$&')})`,
    'gi'
  )

  const substrings = children.split(searchRegex)

  return createElement(component, {
    ...others,
    children: substrings.map((substring, key) => {
      return !comparator(substring, highlight, options) ? (
        substring
      ) : (
        <Strong key={key}>{substring}</Strong>
      )
    })
  })
}

EmphasizeText.propTypes = {
  ignoreCase: PropTypes.bool,
  highlight: PropTypes.string,
  children: PropTypes.any
}

export { EmphasizeText }
export default EmphasizeText
