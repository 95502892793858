import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import { alpha } from '@mui/system/colorManipulator'
import { Menu01 } from '@untitled-ui/icons-react/build/esm'

import { User } from '~ui-components/types/__generated/gql/graphql'

import { Theme } from '@mui/material'
import type { FC } from 'react'
import { WorkspacesListMenuProps } from '~ui-components/components/molecules'

const TOP_NAV_HEIGHT = 64
const SIDE_NAV_WIDTH = 280

interface TopNavProps {
  user?: User
  workspaces: WorkspacesListMenuProps['workspaces']
  slots?: any
  onMobileNavOpen: any
}

export const TopNav: FC<TopNavProps> = (props) => {
  const { user, workspaces, slots: Slots, onMobileNavOpen, ...other } = props

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  return (
    <Box
      component='header'
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
        },
        zIndex: (theme) => theme.zIndex.appBar
      }}
      {...other}
    >
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2
        }}
      >
        <Stack
          alignItems='center'
          direction='row'
          spacing={2}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01 />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>
        <Stack
          alignItems='center'
          direction='row'
          spacing={2}
        >
          <Slots.NavAvatar />
        </Stack>
      </Stack>
    </Box>
  )
}
