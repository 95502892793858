import { Box, Dialog as MuiDialog, Stack, Typography } from '@mui/material'

import CancelButton from '~ui-components/components/molecules/CancelButton'
import CloseButton from '~ui-components/components/molecules/CloseButton'
import SaveButton from '~ui-components/components/molecules/SaveButton'

const DialogContent = (props) => {
  const { sx, children } = props
  return (
    <Stack
      direction='column'
      sx={[
        {
          m: 2,
          gap: 2
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {children}
    </Stack>
  )
}

const DialogFooter = (props) => {
  const { sx, children } = props
  return (
    <Box
      sx={[
        {
          m: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: 2
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...props}
    >
      {children}
    </Box>
  )
}

const defaultSlots = {
  DialogFooter,
  PrimaryButton: SaveButton,
  SecondaryButton: CancelButton
}

const Dialog = (props) => {
  const {
    title,
    open,
    onClose,
    onSubmit,
    children,
    slots = {},
    slotProps = {}
  } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{
        component: 'form',
        variant: 'outlined',
        elevation: 0,
        sx: {
          display: 'block',
          borderRadius: 2,
          minWidth: '352px',
          maxWidth: '512px',
          width: '100%'
        },
        onSubmit
      }}
    >
      <Stack
        direction='row'
        sx={{ position: 'relative', m: 2 }}
      >
        <Typography
          variant='xl'
          sx={{ flex: 1, mr: '32px', fontWeight: 'bold' }}
        >
          {title || 'Dialog'}
        </Typography>

        <CloseButton
          sx={{
            position: 'absolute',
            top: 0,
            right: 0
          }}
          onClick={onClose}
        />
      </Stack>

      {children}

      <Slots.DialogFooter {...slotProps?.DialogFooter}>
        <Slots.SecondaryButton
          {...slotProps?.SecondaryButton}
          onClick={onClose}
        />
        <Slots.PrimaryButton
          {...slotProps?.PrimaryButton}
          type='submit'
          value='Submit'
        />
      </Slots.DialogFooter>
    </MuiDialog>
  )
}

export { Dialog, DialogContent, DialogFooter }
export default Dialog
