import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useAuth } from 'user-auth'
import { CreateMap } from '~map-creator/core/App/CreateMap'
import { MAP_CREATOR_CSV_STEPS } from '~map-creator/core/assets/constants'
import {
  getMapPayload,
  saveDataToIndexDB,
  submitCreateMap
} from '~map-creator/core/functions'
import { StoreContext } from '~map-creator/core/states/context'
import {
  pushWizardStep,
  setSelectedFeatureTypeName,
  useCurrentWizardStep
} from '~map-creator/core/states/map'
import {
  setStepIndexes,
  useMapTagCollection
} from '~map-creator/core/states/ui'
import { useWorkspace } from '~map-creator/core/states/workspace'
import { useSkippableSteps } from '~map-creator/map-creator-csv/hooks/useSkippableSteps'
import { useSteps } from '~map-creator/map-creator-csv/hooks/useSteps'

import { MapStatus, setSubmitMap } from 'map-viewer'
import { useRouter } from 'next/router'
import { getMapLink } from 'utils'

const CreateMapCSV = () => {
  const dispatch = useDispatch()
  const router = useRouter()

  const store = useContext(StoreContext)
  const workspace = useWorkspace()
  const { user } = useAuth()

  useSkippableSteps()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(setSelectedFeatureTypeName('Country'))
    dispatch(setStepIndexes(MAP_CREATOR_CSV_STEPS))
  }, [dispatch])

  const currentWizardStep = useCurrentWizardStep()
  const mapTagCollection = useMapTagCollection()

  const goToNextStep = () => {
    dispatch(pushWizardStep(currentWizardStep + 1))
  }

  const steps = useSteps({ goToNextStep })

  const handleSubmit = async (context) => {
    if (!user) throw new Error('User not found')

    const { dataId, json, geojson, dataIdentifiers } = context
    const map = { ...getMapPayload(null, store), type: 'polygons' }

    try {
      await setSubmitMap(map.id, MapStatus.PENDING)
      router.push(getMapLink({ mapId: map.id, mapName: map.name }))

      await saveDataToIndexDB({
        map: {
          ...map,
          createdAt: new Date().toISOString(),
          dataId,
          dataReady: false,
          description: null,
          views: 1,
          likes: 0,
          remixes: 0,
          liked: false,
          owned: true,
          workspace,
          creator: user,
          mapTagCollection
        },
        json,
        geojson,
        dataIdentifiers
      })

      await submitCreateMap(context, map)
      await setSubmitMap(map.id, MapStatus.READY)
    } catch (error) {
      await setSubmitMap(map.id, MapStatus.FAILED)
      throw error
    }
  }

  return (
    <CreateMap
      steps={steps}
      onSubmit={handleSubmit}
    />
  )
}

export { CreateMapCSV }
export default CreateMapCSV
