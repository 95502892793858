import _ from 'lodash'
import { v1 as uuidv1 } from 'uuid'
import { VISUALISATION } from '../assets/Constants'
import { getCategoryStyle, getQuantitiesStyle } from '../layerStyle'
import {
  getPayloadByVisualisationType,
  getRoundingValue,
  getStringFeaturePropsByKey,
  getVisualisationPropertyType,
  smallestClassBreakGap
} from '../utils'

const getRoundedJenks = (jenks) => {
  const smallestGap = smallestClassBreakGap([...jenks])
  const roundingValue = getRoundingValue(smallestGap)
  const roundedJenks = jenks.map((jenk, i) => {
    // INFO: return Math.floor for min value
    if (i === 0) {
      return _.floor(jenk, -Math.log10(roundingValue))
    }
    return _.ceil(jenk, -Math.log10(roundingValue))
  })
  return roundedJenks
}

export const getVisualisationStyle = (key, geojsonData) => {
  const { features } = geojsonData
  const type = getVisualisationPropertyType(key, features)

  const { jenks } = getVisualisationPayload(key, features, type)

  if (type === VISUALISATION.CATEGORY) {
    return getCategoryStyle(key, jenks)
  }

  if (type === VISUALISATION.QUANTITY) {
    return getQuantitiesStyle(key, jenks)
  }

  throw new Error(`Unknown visualisation type: ${type}`)
}

export const getVisualisationPayload = (key, features, type) => {
  const payload: Record<string, any> = {
    id: uuidv1(),
    type,
    value: key
  }

  if (type === VISUALISATION.CATEGORY) {
    const intervals = getStringFeaturePropsByKey(key, features)
    payload.jenks = intervals
  }

  if (type === VISUALISATION.QUANTITY) {
    const { jenks, isOverClassBreakLimit } = getPayloadByVisualisationType(
      key,
      features
    )
    payload.jenks = isOverClassBreakLimit ? getRoundedJenks(jenks) : jenks
    payload.isOverClassBreakLimit = isOverClassBreakLimit
  }

  return payload
}
