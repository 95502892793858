import { Box, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { zIndex } from '~ui-components/theme/system'

const MapAttribute = () => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('mobile'))

  const [attributeDetailsClasses, setControlButtonClassName] = useState()
  const [open, setOpen] = useState(!mobile)

  const handleOnClick = (event) => {
    event.preventDefault()
    const classNames = attributeDetailsClasses.split(' ')
    if (open) {
      classNames.pop('maplibregl-compact-show')
      setOpen(false)
    } else {
      classNames.push('maplibregl-compact-show')
      setOpen(true)
    }
    setControlButtonClassName(classNames.join(' '))
  }

  useEffect(() => {
    setControlButtonClassName(
      mobile
        ? 'maplibregl-ctrl maplibregl-ctrl-attrib maplibregl-compact'
        : 'maplibregl-ctrl maplibregl-ctrl-attrib'
    )
    setOpen(!mobile)
  }, [mobile])

  const detailsClassName = [
    'maplibregl-ctrl',
    'maplibregl-ctrl-attrib',
    attributeDetailsClasses
  ].join(' ')

  return (
    <Box
      sx={{
        position: 'absolute',
        boxSizing: 'content-box',
        fontSize: 12,
        top: mobile ? 0 : 'unset',
        bottom: mobile ? 'unset' : 0,
        right: 0,
        zIndex: zIndex.mapAttribute
      }}
    >
      <details
        className={detailsClassName}
        open={open}
      >
        <summary
          onClick={handleOnClick}
          className='maplibregl-ctrl-attrib-button mapboxgl-ctrl-attrib-button'
          title='Toggle Attribution'
          aria-label='Toggle attribution'
        />
        <div
          className='maplibregl-ctrl-attrib-inner'
          role='list'
        >
          <a
            href='https://www.maptiler.com/copyright/'
            target='_blank'
            rel='nofollow noreferrer'
          >
            © MapTiler
          </a>{' '}
          <a
            href='https://www.openstreetmap.org/copyright'
            target='_blank'
            rel='nofollow noreferrer'
          >
            © OpenStreetMap contributors
          </a>
        </div>
      </details>
    </Box>
  )
}

export { MapAttribute }
