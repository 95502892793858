import { useState, useCallback } from 'react'

/**
 * Hook that abstract copy to clipboard logic
 * @returns {UseClipboardReturnType} return state and callback
 *
 * @see Documentation {@link https://usehooks-ts.com/react-hook/use-copy-to-clipboard}
 */
const useClipboard = () => {
  const [copiedText, setCopiedText] = useState('')
  const copy = useCallback(async (text) => {
    // Clipboard not supported 😢
    if (!navigator?.clipboard) {
      return false
    }
    let isSuccessful = false
    let toCopyText = ''
    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      toCopyText = text
      isSuccessful = true
    } catch (error) {
      // Copy failed 🤨
      toCopyText = ''
      isSuccessful = false
    }
    setCopiedText(toCopyText)
    return isSuccessful
  }, [])
  return [copiedText, copy]
}

/**
 * @typedef {[string, function]} UseClipboardReturnType
 */

export { useClipboard }
