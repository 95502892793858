import _, { every, isInteger } from 'lodash'

const isValidDate = (date) => {
  const dateInput = isInteger(date) ? date : date?.toString()
  const parsedDate = new Date(dateInput)
  return isFinite(parsedDate.getTime())
}

export const getPropertyTypeOptions = (propertyValues, type) => {
  let options = every(propertyValues, isValidDate) ? ['date'] : []
  if (type === 'text') options.push('text')
  else options = [...options, 'integer', 'float']
  return options
}
