const isDate = (value) => {
  if (toString.call(value) === '[object Date]') {
    return true
  }

  if (typeof value.replace === 'function') {
    value.replace(/^\s+|\s+$/gm, '')
  }

  const defaultDate =
    /(^\d{1,4}[.|\\/|-]\d{1,2}[.|\\/|-]\d{1,4})(\s*(?:0?[1-9]:[0-5]|1(?=[012])\d:[0-5])\d\s*[ap]m)?$/

  const isoDate =
    /^(\d{4})-?(\d{2})-?(\d{2})(T(\d{2}):?(\d{2}):?(\d{2})(\.\d{1,3})?Z?)?$/

  const dataWitTimeZone = /^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}\+\d{2}$/

  return (
    defaultDate.test(value) ||
    isoDate.test(value) ||
    dataWitTimeZone.test(value)
  )
}

export { isDate }
