import { get } from 'idb-keyval'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Layer, Source } from 'react-map-gl/maplibre'
import {
  BEFORE_ID,
  MAP_SOURCE,
  POPUP_SELECTION_LAYER_ID,
  PROMOTE_ID,
  TYPE
} from '~map-creator/core/assets/constants'
import { useGeojsonFileId } from '~map-creator/core/states/geojson'

import { getDefaultStyle } from '../utils/generateLayerStyle/defaultStyle'
import { getColorRampStyle } from '../utils/generateLayerStyle/getColorRampStyle'
import { getMapAreaLineStyle } from '../utils/generateLayerStyle/getMapAreaLineStyle'
import { getMapAreaFillStyle } from '../utils/generateLayerStyle/mapAreaFillStyle'

const Component = (props) => {
  const { sourceData } = props
  const defaultStyle = getDefaultStyle()
  const mapAreaFillStyle = getMapAreaFillStyle()
  const mapAreaLineStyle = getMapAreaLineStyle()
  const colorRampStyle = getColorRampStyle()
  return (
    <Source
      id={MAP_SOURCE}
      type={TYPE}
      data={sourceData}
      promoteId={PROMOTE_ID}
    >
      <Layer
        id={POPUP_SELECTION_LAYER_ID}
        beforeId={BEFORE_ID}
        type='fill'
        paint={{ 'fill-color': 'transparent' }}
      />
      <Layer
        id='start'
        layout={{ visibility: defaultStyle.visibility }}
        {...defaultStyle.style}
      />
      <Layer
        id='areas'
        beforeId={BEFORE_ID}
        layout={{ visibility: mapAreaFillStyle.visibility }}
        {...mapAreaFillStyle.style}
      />
      <Layer
        id='areas-lines'
        beforeId={BEFORE_ID}
        layout={{ visibility: mapAreaLineStyle.visibility }}
        {...mapAreaLineStyle.style}
      />
      <Layer
        id='quantities'
        beforeId={BEFORE_ID}
        layout={{ visibility: colorRampStyle.visibility }}
        {...colorRampStyle.style}
      />
    </Source>
  )
}

Component.propTypes = {
  sourceData: PropTypes.object
}

const SourceLayers = (props) => {
  const [sourceData, setSourceData] = useState(null)
  const geojsonFileId = useGeojsonFileId()
  useEffect(() => {
    const fetchData = async () => {
      const geojsonData = await get(`${geojsonFileId}:geojson`)
      if (!geojsonData?.features) return
      setSourceData(geojsonData)
    }
    geojsonFileId && fetchData()
  }, [geojsonFileId])

  if (!sourceData) return null

  return (
    <Component
      {...props}
      sourceData={sourceData}
    />
  )
}

export default SourceLayers
export { SourceLayers }
