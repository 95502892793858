import { useEffect } from 'react'

export const useKeypressHandler = () => {
  useEffect(() => {
    document.addEventListener('keydown', keypressHandler)
    return () => {
      document.removeEventListener('keydown', keypressHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return []
}

function keypressHandler(e) {
  const isEnterKey = e.key === 'Enter'
  const isBackButton = e.target.id === 'ButtonBack'
  if (!isEnterKey || isBackButton) return

  const btnContinue = document.getElementById('ButtonContinue')
  const isContinueDisabled = !!btnContinue?.disabled

  const btnNext = document.getElementById('ButtonNext')
  const isNextDisabled = !!btnNext?.disabled

  if (btnContinue && !isContinueDisabled) return btnContinue.click()
  if (btnNext && !isNextDisabled) return btnNext.click()
}
