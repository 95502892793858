import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Unstable_Grid2 as Grid,
  InputBase,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  alpha
} from '@mui/material'
import { Camera01, Image01 } from '@untitled-ui/icons-react/build/esm'
import { useRef } from 'react'

const WorkspaceSettingsTab = ({
  form,
  deleteWorkspaceLoading,
  onImageChange,
  onDeleteWorkspace,
  warningMessage
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  return (
    <Stack spacing={4}>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={3}
            mb={3}
          >
            <Grid
              xs={12}
              md={4}
            >
              <Typography variant='h6'>Basic details</Typography>
            </Grid>
            <Grid
              xs={12}
              md={8}
            >
              <Stack spacing={3}>
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={2}
                >
                  <Box
                    sx={{
                      borderColor: 'neutral.300',
                      borderRadius: '50%',
                      borderStyle: 'dashed',
                      borderWidth: 1,
                      p: '4px'
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: '50%',
                        height: '100%',
                        width: '100%',
                        position: 'relative'
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          backgroundColor: (theme) =>
                            alpha(theme.palette.neutral[700], 0.5),
                          borderRadius: '50%',
                          color: 'common.white',
                          cursor: 'pointer',
                          display: 'flex',
                          height: '100%',
                          justifyContent: 'center',
                          left: 0,
                          opacity: 0,
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                          zIndex: 1,
                          '&:hover': {
                            opacity: 1
                          }
                        }}
                      >
                        <InputBase
                          inputRef={fileInputRef}
                          inputProps={{ accept: 'image/*', multiple: false }}
                          type='file'
                          value=''
                          onChange={(e) => {
                            if (e.target['files']?.length > 0) {
                              onImageChange(e.target['files'][0])
                            }
                          }}
                          hidden
                          sx={{ display: 'none' }}
                        />
                        <Stack
                          alignItems='center'
                          direction='row'
                          spacing={1}
                          onClick={() => fileInputRef.current!.click()}
                        >
                          <SvgIcon color='inherit'>
                            <Camera01 />
                          </SvgIcon>
                          <Typography
                            color='inherit'
                            variant='subtitle2'
                            sx={{ fontWeight: 700 }}
                          >
                            Select
                          </Typography>
                        </Stack>
                      </Box>
                      <Avatar
                        src={form.status.logo}
                        sx={{ height: 100, width: 100 }}
                      >
                        <SvgIcon>
                          <Image01 />
                        </SvgIcon>
                      </Avatar>
                    </Box>
                  </Box>
                  <Button
                    variant='text'
                    color='inherit'
                    size='small'
                    onClick={() => fileInputRef.current!.click()}
                  >
                    Change
                  </Button>
                </Stack>
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={2}
                >
                  <TextField
                    required
                    label='Workspace Name'
                    sx={{ flexGrow: 1 }}
                    name='name'
                    value={form.values.name}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={form.touched.name && Boolean(form.errors.name)}
                    helperText={form.touched.name && form.errors.name}
                  />
                </Stack>
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={2}
                >
                  <TextField
                    label='Workspace Description'
                    sx={{
                      flexGrow: 1,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'dashed'
                      }
                    }}
                    name='description'
                    value={form.values.description}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={
                      form.touched.description &&
                      Boolean(form.errors.description)
                    }
                    helperText={
                      form.touched.description && form.errors.description
                    }
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              md={4}
            >
              <Typography variant='h6'>Community Links</Typography>
            </Grid>
            <Grid
              xs={12}
              md={8}
            >
              <Stack spacing={3}>
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={2}
                >
                  <TextField
                    label='Website URL'
                    sx={{ flexGrow: 1 }}
                    name='websiteUrl'
                    value={form.values.websiteUrl}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={
                      form.touched.websiteUrl && Boolean(form.errors.websiteUrl)
                    }
                    helperText={
                      form.touched.websiteUrl && form.errors.websiteUrl
                    }
                  />
                </Stack>
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={2}
                >
                  <TextField
                    label='Discord / Slack URL'
                    sx={{
                      flexGrow: 1,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'dashed'
                      }
                    }}
                    name='communityUrl'
                    value={form.values.communityUrl}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={
                      form.touched.communityUrl &&
                      Boolean(form.errors.communityUrl)
                    }
                    helperText={
                      form.touched.communityUrl && form.errors.communityUrl
                    }
                  />
                </Stack>
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={2}
                >
                  <TextField
                    label='Twitter Username'
                    sx={{
                      flexGrow: 1,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'dashed'
                      }
                    }}
                    name='twitterUsername'
                    value={form.values.twitterUsername}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={
                      form.touched.twitterUsername &&
                      Boolean(form.errors.twitterUsername)
                    }
                    helperText={
                      form.touched.twitterUsername &&
                      form.errors.twitterUsername
                    }
                  />
                </Stack>
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={2}
                >
                  <TextField
                    label='Email address'
                    sx={{
                      flexGrow: 1,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'dashed'
                      }
                    }}
                    name='email'
                    value={form.values.email}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={form.touched.email && Boolean(form.errors.email)}
                    helperText={form.touched.email && form.errors.email}
                  />
                </Stack>
                <Stack
                  justifyContent='flex-end'
                  alignItems='center'
                  direction='row'
                  spacing={2}
                >
                  <LoadingButton
                    loading={form.isSubmitting}
                    disabled={!form.isValid}
                    variant='contained'
                    onClick={() => form.handleSubmit()}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              md={4}
            >
              <Typography variant='h6'>Delete Workspace</Typography>
            </Grid>
            <Grid
              xs={12}
              md={8}
            >
              <Stack
                alignItems='flex-start'
                spacing={3}
              >
                <Typography variant='subtitle1'>
                  This action is irreversible
                </Typography>
                <Box>
                  <LoadingButton
                    loading={deleteWorkspaceLoading}
                    onClick={onDeleteWorkspace}
                    disabled={!!warningMessage}
                    color='error'
                    variant='outlined'
                  >
                    Delete this workspace
                  </LoadingButton>
                  {!!warningMessage && (
                    <FormHelperText error>{warningMessage}</FormHelperText>
                  )}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Stack>
  )
}

export { WorkspaceSettingsTab }
