import { TablePagination } from '@mui/material'
import { useMemo } from 'react'
import { getPaginationCount } from '~ui-components/utilities/getPaginationCount'

interface RemixPaginationProps {
  page: number | string
  total: number
  limit: number
  onPageChange: React.ComponentProps<typeof TablePagination>['onPageChange']
}

function RemixPagination(props: RemixPaginationProps) {
  const { page: pageProp, total, limit, onPageChange } = props

  const count = useMemo(() => getPaginationCount(total, limit), [total, limit])
  const page = useMemo(() => Number(pageProp), [pageProp])

  return (
    <TablePagination
      component='div'
      labelRowsPerPage={null}
      rowsPerPageOptions={[]}
      count={total}
      page={page - 1} // Zero based index, page start with 1
      rowsPerPage={limit}
      onPageChange={onPageChange}
      slotProps={{
        actions: {
          nextButton: {
            disabled: page === count // opt in for custom behavior
          },
          previousButton: {
            disabled: page === 1 // opt in for custom behavior
          }
        }
      }}
    />
  )
}

export { RemixPagination }
