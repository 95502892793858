import Table from '@untitled-ui/icons-react/build/esm/Table'
import PropTypes from 'prop-types'
import FloatingToggleButton from '~ui-components/components/molecules/FloatingToggleButton'

const MapActionTable = (props) => {
  const { active, onToggle } = props

  const handleClick = (event, value) => onToggle(event, value)

  return (
    <FloatingToggleButton
      data-testid='MapActionTable'
      active={active}
      options={['table', '']}
      title='Table'
      label='Table'
      icon={<Table />}
      color={active ? 'secondary' : 'inherit'}
      onClick={handleClick}
    />
  )
}

MapActionTable.propTypes = {
  view: PropTypes.string,
  onClick: PropTypes.func
}

export { MapActionTable }
export default MapActionTable
