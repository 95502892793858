import { graphierQL } from 'utils'
import {
  AddMapViewMutation,
  AddMapViewMutationVariables
} from '~map-viewer/types/__generated/gql/graphql'

const addMapView = async (mapId) => {
  const data = await graphierQL<
    AddMapViewMutation,
    AddMapViewMutationVariables
  >({
    query: addMapViewMutation,
    variables: { id: mapId }
  })
  return data.addMapView
}

const addMapViewMutation = /* GraphQL */ `
  mutation addMapView($id: ID!) {
    addMapView(id: $id) {
      id
      name
      dataReady
      views
    }
  }
`

export { addMapView, addMapViewMutation }
export default addMapView
