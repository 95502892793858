import {
  Button,
  ButtonBase,
  Popover,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'

import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_HR,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  findNode,
  isBlock,
  toggleNodeType
} from '@udecode/plate'

import { useEditorState } from '@udecode/plate-core'
import { isEditorFocused } from '@udecode/slate-react'

import ChevronDown from '@untitled-ui/icons-react/build/esm/ChevronDown'
import Heading1 from '~ui-components/assets/svg/Heading1'
import Heading2 from '~ui-components/assets/svg/Heading2'
import Heading3 from '~ui-components/assets/svg/Heading3'
import List from '~ui-components/assets/svg/List'
import ListOrdered from '~ui-components/assets/svg/ListOrdered'
import Pilcrow from '~ui-components/assets/svg/Pilcrow'
import Quote from '~ui-components/assets/svg/Quote'
import SeparatorHorizontal from '~ui-components/assets/svg/SeparatorHorizontal'

import { useRef } from 'react'
import { cursorAtSelectionOrEnd } from '~ui-components/components/molecules/TextEditor/plugins/AutofocusPlugin'
import { usePopover } from '~ui-components/hooks/usePopover'

const optionMapping = {
  [ELEMENT_PARAGRAPH]: 'Paragraph',
  [ELEMENT_H1]: 'Heading 1',
  [ELEMENT_H2]: 'Heading 2',
  [ELEMENT_H3]: 'Heading 3',
  [ELEMENT_BLOCKQUOTE]: 'Blockquote',
  [ELEMENT_UL]: 'Unordered List',
  [ELEMENT_OL]: 'Ordered List',
  [ELEMENT_HR]: 'Divider'
}

const options = [
  ELEMENT_PARAGRAPH,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_UL,
  ELEMENT_OL,
  ELEMENT_HR
]

const toParagraph = (editor, selection) => {
  toggleNodeType(
    editor,
    {
      activeType: ELEMENT_PARAGRAPH
    },
    {
      at: selection
    }
  )
}

const toH1 = (editor) => {
  toggleNodeType(editor, {
    activeType: ELEMENT_H1
  })
}

const toH2 = (editor) => {
  toggleNodeType(editor, {
    activeType: ELEMENT_H2
  })
}

const toH3 = (editor) => {
  toggleNodeType(editor, {
    activeType: ELEMENT_H3
  })
}

const toUl = (editor) => {
  toggleNodeType(editor, {
    activeType: ELEMENT_UL
  })
}

const toOl = (editor) => {
  toggleNodeType(editor, {
    activeType: ELEMENT_OL
  })
}

const toBlockquote = (editor) => {
  toggleNodeType(editor, {
    activeType: ELEMENT_BLOCKQUOTE
  })
}

const toHr = (editor) => {
  toggleNodeType(editor, {
    activeType: ELEMENT_HR
  })
}

const TurnInto = () => {
  const popover = usePopover()
  const editor = useEditorState()
  const focused = isEditorFocused(editor)
  const selection = useRef()

  const entry = findNode(editor, {
    match: (node) => {
      return isBlock(editor, node)
    }
  })

  const type = entry[0]?.type
  const value = options.includes(type) ? type : ELEMENT_PARAGRAPH

  const handleClick = () => {
    popover.handleOpen()
  }

  const handleClose = () => {
    popover.handleClose()
    cursorAtSelectionOrEnd(editor, selection.current)
  }

  return (
    <>
      <Button
        size='small'
        color='inherit'
        disableElevation
        endIcon={
          <SvgIcon>
            <ChevronDown />
          </SvgIcon>
        }
        onClick={handleClick}
        ref={popover.anchorRef}
        sx={{
          height: 28,
          px: 1
        }}
      >
        {!focused ? 'Turn into' : optionMapping[value] || 'Turn into'}
      </Button>

      <Popover
        closeAfterTransition
        disableRestoreFocus
        disableEnforceFocus
        open={popover.open}
        anchorEl={popover.anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 4
        }}
      >
        <Stack
          component='div'
          sx={{
            listStyle: 'none',
            m: 0
          }}
        >
          <Typography
            variant='overline'
            sx={{ p: 1, pb: 0 }}
          >
            Turn into
          </Typography>

          <Stack
            component='ul'
            direction='column'
            sx={{ p: 1, m: 0 }}
          >
            <OptionItem
              label='Paragraph'
              icon={<Pilcrow />}
              onClick={() => {
                toParagraph(editor)
                handleClose()
              }}
            />

            <OptionItem
              label='Heading 1'
              icon={<Heading1 />}
              onClick={() => {
                toH1(editor)
                handleClose()
              }}
            />

            <OptionItem
              label='Heading 2'
              icon={<Heading2 />}
              onClick={() => {
                toH2(editor)
                handleClose()
              }}
            />

            <OptionItem
              label='Heading 3'
              icon={<Heading3 />}
              onClick={() => {
                toH3(editor)
                handleClose()
              }}
            />

            <OptionItem
              label='Bulleted List'
              icon={<List />}
              onClick={() => {
                toUl(editor)
                handleClose()
              }}
            />

            <OptionItem
              label='Numbered List'
              icon={<ListOrdered />}
              onClick={() => {
                toOl(editor)
                handleClose()
              }}
            />

            <OptionItem
              label='Quote'
              icon={<Quote />}
              onClick={() => {
                toBlockquote(editor)
                handleClose()
              }}
            />

            <OptionItem
              label='Horizontal Rule'
              icon={<SeparatorHorizontal />}
              onClick={() => {
                toHr(editor)
                handleClose()
              }}
            />
          </Stack>
        </Stack>
      </Popover>
    </>
  )
}

const OptionItem = (props) => {
  const { active, label, icon, onClick } = props
  return (
    <ButtonBase
      disableRipple
      sx={{
        px: 1,
        py: 1,
        borderRadius: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minWidth: 200,
        textAlign: 'left',
        '&:hover': {
          backgroundColor: 'action.hover'
        },
        ...(active && {
          color: 'primary.main'
        })
      }}
      onClick={onClick}
    >
      <SvgIcon>{icon}</SvgIcon>
      <Typography
        component='span'
        variant='subtitle2'
        sx={{ ml: 1 }}
      >
        {label}
      </Typography>
    </ButtonBase>
  )
}
export { TurnInto }
