import { FC, ReactNode } from 'react'
import { MapstackLoadingBackdrop } from 'ui-components'
import { Map } from '~user-auth/types/__generated/gql/graphql'

import { useRouter } from 'next/router'
import { useAuth } from '~user-auth/contexts/auth'

export type PrivateMapGuardProps = {
  children: ReactNode
  map: Map
}

export const PrivateMapGuard: FC<PrivateMapGuardProps> = ({
  children,
  map
}) => {
  const router = useRouter()

  const { initialized, user } = useAuth()

  if (!initialized) return <MapstackLoadingBackdrop text='Loading...' />

  if (!user || !map || map.creator?.id !== user.id) {
    router.replace('/404')
    return null
  }

  return children
}
