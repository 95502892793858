import { FC } from 'react'
import { MapCreateDrawer as View } from 'ui-components'
import { MapCreateForm } from './MapCreateForm'

type MapCreateDrawerProps = {
  open: boolean
  onClose: () => void
}

export const App: FC<MapCreateDrawerProps> = (props) => {
  const { open, onClose } = props
  return (
    <View
      open={open}
      onClose={onClose}
      slots={{
        MapCreateForm
      }}
    />
  )
}
