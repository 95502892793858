import { generateHeightRamp } from "../../../get-3d-heights"
import { generateColourRamp } from "../../../get-colour-ramp"

function generateFill(
  dataClassBreaks,
  colourPalette: string[],
  aggregateBoundary: string,
  source: string,
  sourceLayer?: string
) {
  const colourRamp = generateColourRamp(dataClassBreaks, colourPalette)

  const fill = {
    id: 'Mapstack Visualization Fill',
    type: 'fill',
    source: source,
    paint: {
      'fill-color': ['match', ['get', aggregateBoundary], ...colourRamp],
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.6,
        0.9
      ]
    }
  }
  if (sourceLayer) fill['source-layer'] = sourceLayer
  return fill
}

function generate3DFill(
  dataClassBreaks,
  colourPalette,
  aggregateBoundary,
  source,
  resolution,
  sourceLayer?: any,
  filter?: any
) {
  const colourRamp = generateColourRamp(dataClassBreaks, colourPalette)
  const heightRamp = generateHeightRamp(dataClassBreaks, resolution)

  const fill = {
    id: 'Mapstack Visualization Fill',
    type: 'fill-extrusion',
    source: source,
    paint: {
      'fill-extrusion-color': [
        'match',
        ['get', aggregateBoundary], // Property of the feature that identifies the state
        ...colourRamp
      ],
      'fill-extrusion-height': [
        'match',
        ['get', aggregateBoundary],
        ...heightRamp // Default height if no match is found
      ],
      'fill-extrusion-base': 0,
      'fill-extrusion-opacity': 1
    }
  }
  if (sourceLayer) fill['source-layer'] = sourceLayer
  if (filter)
    fill['filter'] = [
      'in',
      aggregateBoundary,
      ...dataClassBreaks.reduce((acc, val) => [...acc, ...val], [])
    ] // flatten out string[][]
  return fill
}

export { generateFill, generate3DFill }
