import React from 'react'
import { GlobalStyles } from '@mui/material'

const Overrides = (
  <GlobalStyles
    styles={{
      '@media screen and (pointer: coarse)': {
        /* prevent pull-to-refresh for Safari 16+ */
        '@supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none)':
          {
            html: {
              minHeight: '100.3%',
              overscrollBehaviorY: 'none'
            }
          },
        /* prevent pull-to-refresh for Safari 9~15 */
        '@supports (-webkit-backdrop-filter: blur(1px)) and (not (overscroll-behavior-y: none))':
          {
            html: {
              height: '100%',
              overflow: 'hidden'
            },
            body: {
              margin: '0px',
              maxHeight:
                '100%' /* or `height: calc(100% - 16px);` if body has default margin */,
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch'
            }
            /* in this case to disable pinch-zoom, set `touch-action: pan-x pan-y;` on `body` instead of `html` */
          }
      },
      /* prevent pull-to-refresh for Chrome 63+ */
      body: {
        overscrollBehaviorY: 'none'
      }
    }}
  />
)

const PreventPullToRefresh = (props) => {
  return (
    <>
      {Overrides}
      {props.children}
    </>
  )
}

export { PreventPullToRefresh }
export default PreventPullToRefresh
