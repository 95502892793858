import PropTypes from 'prop-types'
import { typography } from '~ui-components/emails/theme/typography'

const underlines = {
  always: {
    textDecoration: 'underline'
  },
  none: {
    textDecoration: 'none'
  }
}

const Link = (props) => {
  const {
    href,
    color,
    variant = 'link',
    underline = 'hover',
    style,
    children,
    ...rest
  } = props
  return (
    <a
      {...rest}
      href={href}
      target='_blank'
      rel='noreferrer'
      style={{
        ...(color ? { color: color || '#211338' } : {}),
        ...underlines[underline],
        ...typography[variant],
        ...style
      }}
    >
      {children}
    </a>
  )
}

export { Link }
export default Link

Link.propTypes = {
  href: PropTypes.string,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'link',
    'button',
    'caption'
  ]),
  color: PropTypes.oneOf([
    'text',
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning'
  ]),
  underline: PropTypes.oneOf(['always', 'none']),
  style: PropTypes.object,
  children: PropTypes.node
}
