import _ from 'lodash'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { throttle } from 'utils'
import {
  setAreLayerSourcesLoaded,
  useLayerSources
} from '~map-viewer/states/layer'
import { useJsonFeatures } from '~map-viewer/states/map'

export const useLayerSourcesStatus = (map) => {
  const layerSources = useLayerSources()
  const dispatch = useDispatch()
  const jsonFeatures = useJsonFeatures()

  useEffect(() => {
    if (_.isEmpty(jsonFeatures)) return

    const handleSourceLoaded = () => {
      const source = [...layerSources].pop()
      if (map.getSource(source) && map.isSourceLoaded(source)) {
        throttle(() => {
          dispatch(setAreLayerSourcesLoaded(true))
        }, 100)
      }
    }

    if (layerSources.length && map) {
      map.on('sourcedata', handleSourceLoaded)
      return () => map.off('sourcedata', handleSourceLoaded)
    }
  }, [dispatch, layerSources, map, jsonFeatures])
}
