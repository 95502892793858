import { SearchLg } from '@untitled-ui/icons-react/build/esm'
import { FC } from 'react'

import {
  IconListItem,
  IconListItemProps
} from '~ui-components/components/molecules/IconListItem'

export type MapSearchButtonProps = IconListItemProps & { active: boolean }

export const MapSearchButton: FC<MapSearchButtonProps> = ({
  active,
  ...props
}) => {
  return (
    <IconListItem
      primary='Search'
      icon={SearchLg}
      iconProps={
        active
          ? {
              sx: {
                color: (theme) => theme.palette.secondary.main
              }
            }
          : undefined
      }
      primaryTypographyProps={
        active
          ? {
              sx: {
                color: (theme) => theme.palette.secondary.main
              }
            }
          : undefined
      }
      {...props}
    />
  )
}
