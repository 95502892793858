import { Chip, Stack, SvgIcon, Typography } from '@mui/material'
import { LayersThree01 } from '@untitled-ui/icons-react'

const BoundaryTypeInput = (props) => {
  const { value, onChange, options = [] } = props

  return (
    <Stack spacing={1}>
      <Typography
        color='text.secondary'
        variant='overline'
      >
        Aggregate Type
      </Typography>
      <Stack
        alignItems='center'
        direction='row'
        flexWrap='wrap'
        gap={2}
      >
        {options.map((option) => {
          const active = value === option.value
          return (
            <Chip
              key={option.value}
              label={option.label}
              icon={
                <SvgIcon sx={{ fontSize: 18, ml: 1 }}>
                  <LayersThree01 />
                </SvgIcon>
              }
              onClick={() => onChange?.(option.value)}
              sx={{
                borderColor: 'transparent',
                borderRadius: 1.5,
                borderStyle: 'solid',
                borderWidth: 2,
                ...(active && {
                  borderColor: 'primary.main'
                })
              }}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}

export { BoundaryTypeInput }
