import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { MapFileInput } from 'ui-components'

import { getAddressGeocoderCreateLink } from 'utils'

import {
  AddressGeocoderVariant,
  ReadyVariant,
  isAddressGeocoderVariant,
  isReadyVariant
} from './variants'

interface MapFileInputProps extends React.ComponentProps<typeof MapFileInput> {
  onUploadStart?: () => void
  onFileInvalidated?: () => void
}

export const accept: MapFileInputProps['accept'] = {
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls', '.xlsx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xls',
    '.xlsx'
  ]
}

let worker
function App(props: MapFileInputProps) {
  const { onUploadStart, onFileInvalidated } = props

  const router = useRouter()

  const handleDropAccepted: MapFileInputProps['onDropAccepted'] = async (
    files
  ) => {
    const [file] = files

    onUploadStart?.()
    worker.postMessage(file)
  }

  const handleDropRejected: MapFileInputProps['onDropRejected'] = () => {
    onFileInvalidated?.()
  }

  useEffect(() => {
    if (typeof window === 'undefined') return

    if (!worker) worker = new Worker(new URL('./worker.ts', import.meta.url))

    const handleMessage = (event) => {
      const { data } = event as {
        data: ReadyVariant | AddressGeocoderVariant | Error
      }

      if (data instanceof Error) {
        return
      }

      if (isReadyVariant(data)) {
        worker?.terminate()
        worker = undefined
        return
      }

      if (isAddressGeocoderVariant(data)) {
        const { payload } = data
        router.push(getAddressGeocoderCreateLink(payload), undefined, {
          shallow: true
        })
        return
      }
    }

    worker?.addEventListener('message', handleMessage)
  }, [])

  return (
    <MapFileInput
      {...props}
      multiple={false}
      accept={accept}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
    />
  )
}

export { App }
