import {
  Alert,
  Box,
  Chip,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { Hexagon02 } from '@untitled-ui/icons-react'

type Event = {
  type: string
}

type Value = string

interface HexagonDisplayInputProps {
  value?: Value
  onChange?: (event: Event, value: Value) => void
}

const options = ['2D', '3D']

function HexagonDisplayInput(props: HexagonDisplayInputProps) {
  const { value, onChange } = props

  const selectOption = (option) => {
    onChange?.({ type: 'change' }, option)
  }

  const unselectOption = (option) => {
    onChange?.({ type: 'change' }, option)
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))

  const alertMessage = isMobile
    ? 'Use two fingers to rotate the map'
    : 'Right click and drag to rotate the map'

  return (
    <Stack spacing={1}>
      <Typography
        color='text.secondary'
        variant='overline'
      >
        Display
      </Typography>
      <Stack
        alignItems='center'
        direction='row'
        flexWrap='wrap'
        gap={2}
      >
        {options.map((option) => {
          const active = value === option
          return (
            <Chip
              key={`Display:${option}`}
              data-testid={`Display-${option}`}
              label={option}
              icon={
                <SvgIcon sx={{ fontSize: 18, ml: 1 }}>
                  <Hexagon02 />
                </SvgIcon>
              }
              onClick={() =>
                active ? unselectOption(option) : selectOption(option)
              }
              onDelete={undefined}
              sx={{
                borderColor: 'transparent',
                borderRadius: 1.5,
                borderStyle: 'solid',
                borderWidth: 2,
                ...(active && {
                  borderColor: 'primary.main'
                })
              }}
            />
          )
        })}
      </Stack>
      {value === '3D' && (
        <Box sx={{ pt: 1 }}>
          <Alert severity='info'>{alertMessage}</Alert>
        </Box>
      )}
    </Stack>
  )
}

export { HexagonDisplayInput }
