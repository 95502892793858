import { withProps } from '@udecode/cn'

import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE
} from '@udecode/plate-basic-marks'

import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote'

import { PlateLeaf } from '@udecode/plate-common'

import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading'

import { MARK_HIGHLIGHT } from '@udecode/plate-highlight'

import { ELEMENT_HR } from '@udecode/plate-horizontal-rule'

import { MARK_KBD } from '@udecode/plate-kbd'

import { ELEMENT_LINK } from '@udecode/plate-link'

import {
  ELEMENT_LI,
  ELEMENT_OL,
  ELEMENT_TODO_LI,
  ELEMENT_UL
} from '@udecode/plate-list'

import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph'

import { ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED } from '@udecode/plate-media'

import BlockquoteElement from '~ui-components/components/molecules/TextEditor/components/Elements/BlockquoteElement'
import HeadingElement from '~ui-components/components/molecules/TextEditor/components/Elements/HeadingElement'
import HrElement from '~ui-components/components/molecules/TextEditor/components/Elements/HrElement'
import ImageElement from '~ui-components/components/molecules/TextEditor/components/Elements/ImageElement'
import LinkElement from '~ui-components/components/molecules/TextEditor/components/Elements/LinkElement'
import ListElement from '~ui-components/components/molecules/TextEditor/components/Elements/ListElement'
import ListItemElement from '~ui-components/components/molecules/TextEditor/components/Elements/ListItemElement'
import MediaEmbedElement from '~ui-components/components/molecules/TextEditor/components/Elements/MediaEmbedElement'
import ParagraphElement from '~ui-components/components/molecules/TextEditor/components/Elements/ParagraphElement'

import HighlightLeaf from '~ui-components/components/molecules/TextEditor/components/Leafs/HighlightLeaf'
import KbdLeaf from '~ui-components/components/molecules/TextEditor/components/Leafs/KbdLeaf'

import TodoListItemElement from '~ui-components/components/molecules/TextEditor/components/Elements/TodoListItemElement'
import { withPlaceholders } from '../PlaceholderPlugin'

const createComponentsPlugin = () => {
  let components = {
    [ELEMENT_H1]: withProps(HeadingElement, { variant: 'h1' }),
    [ELEMENT_H2]: withProps(HeadingElement, { variant: 'h2' }),
    [ELEMENT_H3]: withProps(HeadingElement, { variant: 'h3' }),
    [ELEMENT_PARAGRAPH]: ParagraphElement,
    [ELEMENT_IMAGE]: ImageElement,
    [ELEMENT_MEDIA_EMBED]: MediaEmbedElement,
    [ELEMENT_HR]: HrElement,
    [ELEMENT_BLOCKQUOTE]: BlockquoteElement,
    [ELEMENT_LINK]: LinkElement,
    [ELEMENT_UL]: withProps(ListElement, { variant: 'ul' }),
    [ELEMENT_OL]: withProps(ListElement, { variant: 'ol' }),
    [ELEMENT_LI]: ListItemElement,
    [ELEMENT_TODO_LI]: TodoListItemElement,
    [MARK_KBD]: KbdLeaf,
    [MARK_HIGHLIGHT]: HighlightLeaf,
    [MARK_BOLD]: withProps(PlateLeaf, {
      as: 'strong'
    }),
    [MARK_ITALIC]: withProps(PlateLeaf, { as: 'em' }),
    [MARK_UNDERLINE]: withProps(PlateLeaf, { as: 'u' }),
    [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: 's' })
  }

  components = withPlaceholders(components)

  return components
}

export { createComponentsPlugin }
export default createComponentsPlugin
