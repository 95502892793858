import { useEffect, useState } from 'react'

const base64ToUtf8 = (text) => Buffer.from(text, 'base64').toString('utf-8')

/**
 * Hook to decode query string params, and return decoded object, if string is
 * not valid base64, will return the original string value
 * @returns {object}
 */
const useQueryDecode = (query) => {
  const [value, setValue] = useState({})
  useEffect(() => {
    const obj = Object.keys(query).reduce((p, c) => {
      let value = null
      try {
        value = JSON.parse(base64ToUtf8(query[c]))
      } catch (e) {
        value = query[c]
        console.warn('error decoding query key: ', c, e)
      }
      return { ...p, [c]: value }
    }, {})
    setValue(obj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return value
}

export { useQueryDecode }
