import { Container, Stack } from '@mui/material'

import AppLayout from '~ui-components/components/organisms/AppLayout'
import { UserProfileInfo } from '~ui-components/components/organisms/UserProfilePanels/UserProfileInfo'
import { UserProfileTabBar } from '~ui-components/components/organisms/UserProfilePanels/UserProfileTabBar'

const defaultSlots = {
  Header: () => null,
  ProfileInfo: UserProfileInfo,
  TabBar: UserProfileTabBar,
  Tab: () => null
}

const UserProfile = (props) => {
  const { slots = {}, slotProps = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <AppLayout
      gutterTop
      slots={{
        Header: Slots.Header,
        Main: (
          <Container
            maxWidth='xl'
            sx={{ pb: 8 }}
          >
            <Stack spacing={4}>
              <Slots.ProfileInfo {...slotProps?.ProfileInfo} />
              <Slots.TabBar {...slotProps?.TabBar} />
              <Slots.Tab {...slotProps?.Tab} />
            </Stack>
          </Container>
        )
      }}
    />
  )
}

export { UserProfile }
