/**
 * We use scalar AWSURL
 * URLs must contain a schema (http, mailto) and can't contain two forward slashes (//) in the path part.
 * @see https://docs.aws.amazon.com/appsync/latest/devguide/scalars.html
 */
const isValidUrl = (url) => {
  try {
    const testUrl = new URL(url)
    return Boolean(testUrl)
  } catch (err) {
    return false
  }
}

export { isValidUrl }
