import { useState } from 'react'
import { searchTags } from '~map-creator/core/api'
import { useCurrentBounds } from '~map-creator/core/states/map'
import { getGeoName } from '~map-creator/map-creator-geojson/api'

const useSearchMapAreas = () => {
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const currentBound = useCurrentBounds()

  const search = async (keyword) => {
    try {
      setLoading(true)

      const [geoNames, tags] = await Promise.all([
        getGeoName({
          bounds: currentBound,
          extraParams: { name: keyword }
        }).catch((e) => {
          return []
        }),
        searchTags({
          from: 0,
          size: 10,
          query: keyword,
          types: ['coverageArea']
        }).catch((e) => {
          return []
        })
      ])

      const formattedResults = formatResults([...tags, ...geoNames])

      setResults(formattedResults)
      setLoading(false)

      return results
    } catch (e) {
      setLoading(false)
    }
  }
  return { results, loading, search }
}

function removeDuplicates(array, property) {
  return array.filter(
    (obj, index, self) =>
      index === self.findIndex((o) => o[property] === obj[property])
  )
}

const sanitizeName = (name, countryName) => {
  let sanitized = name.toLowerCase().replace(/\s+/g, '-')
  if (countryName && countryName !== name) {
    sanitized += `[${countryName.toLowerCase()}]`
  }
  return sanitized
}

const formatResults = (results) => {
  const joinedResult = results.map((geo) => ({
    label: sanitizeName(geo.name, geo.countryName),
    value: sanitizeName(geo.name, geo.countryName)
  }))

  return removeDuplicates(joinedResult, 'value')
}

export { formatResults, useSearchMapAreas }
