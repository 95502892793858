import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

import ToggleChipInput from '~ui-components/components/molecules/ToggleChipInput'

const DefineFeatureTitle = (props) => {
  const { selected, properties, onChange } = props

  return (
    <Box data-testid='CreateMap-DefineFeatureTitle'>
      <ToggleChipInput
        data={properties}
        value={selected}
        onChange={onChange}
      />
    </Box>
  )
}

const DefineFeatureTitlePropTypes = {
  selected: PropTypes.string,
  properties: PropTypes.array,
  onChange: PropTypes.func
}

DefineFeatureTitle.propTypes = DefineFeatureTitlePropTypes

export { DefineFeatureTitle, DefineFeatureTitlePropTypes }
export default DefineFeatureTitle
