import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { EmailChipInput } from '~ui-components/components/molecules/EmailChipInput'

const WorkspaceCreatorMembersForm = (props) => {
  const { name, values, disabled, onChange, onValidate, onSubmit } = props

  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit()
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
    >
      <Box
        component={Typography}
        variant='h2'
        mb={2}
      >
        Who else is on the {name || 'someone'} team?
      </Box>
      <Box
        component={EmailChipInput}
        disabled={disabled}
        placeholder='lewis.h@gmail.com'
        values={values}
        onChange={onChange}
        onValidate={onValidate}
      />
    </Box>
  )
}

const WorkspaceCreatorMembersFormPropTypes = {
  name: PropTypes.string,
  values: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onValidate: PropTypes.func,
  onSubmit: PropTypes.func
}

WorkspaceCreatorMembersForm.propTypes = WorkspaceCreatorMembersFormPropTypes

export { WorkspaceCreatorMembersForm }
export default WorkspaceCreatorMembersForm
