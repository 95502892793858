import { Box, Button, Paper, SvgIcon, Typography } from '@mui/material'
import { User01 } from '@untitled-ui/icons-react'
import { AlertRobot } from '~ui-components/components/molecules/AlertRobot'

const EmptyMemberListFallback = (props) => {
  const { canInviteMember = false, onInviteMemberClick } = props
  return (
    <Box data-testid='InviteMemberBox'>
      <Box
        component={Paper}
        variant='outlined'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        minHeight={300}
        sx={{
          borderStyle: 'dashed'
        }}
      >
        <SvgIcon
          fontSize='large'
          sx={{
            opacity: 0.4
          }}
        >
          <User01 />
        </SvgIcon>
        <Box
          component={Typography}
          variant='h5'
          fontWeight={700}
          mt={2}
          sx={{
            opacity: 0.4
          }}
        >
          Share the love!
        </Box>
        {canInviteMember && (
          <Box
            data-testid='InviteButton'
            component={Button}
            mt={3}
            onClick={onInviteMemberClick}
          >
            Invite Member
          </Box>
        )}
      </Box>
      <AlertRobot mt={3}>
        I never map solo, two robot minds are better than one!
      </AlertRobot>
    </Box>
  )
}

export { EmptyMemberListFallback }
