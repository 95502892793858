import bboxPolygon from '@turf/bbox-polygon'
import difference from '@turf/difference'
import area from '@turf/area'
import { has } from 'lodash'

const GEONAMES = {
  username: 'mapstack',
  url: 'https://secure.geonames.net'
}

export const getGeoName = async ({ bounds, extraParams = {} }) => {
  const { s, w, n, e } = bounds

  const params = {
    ...extraParams,
    type: 'json',
    maxRows: 10,
    style: 'FULL',
    lang: 'en',
    username: GEONAMES.username,
    featureCode: ['OCN', 'CONT', 'PCLI', 'ADM1', 'ADM2', 'ADM3'],
    orderby: 'relevance',
    west: w,
    south: s,
    east: e,
    north: n
  }

  const urlParams = Object.keys(params).reduce((pre, cur) => {
    Array.isArray(params[cur])
      ? params[cur].forEach((val) => pre.append(cur, val))
      : pre.append(cur, params[cur])

    return pre
  }, new URLSearchParams())

  const url = `${GEONAMES.url}/search?${urlParams}`

  try {
    const response = await fetch(url)
    const data = await response.json()

    data.geonames = data.geonames.filter((item) => has(item, 'bbox'))

    const areas = data.geonames
      .sort((areaA, areaB) => {
        const nonOverlapForA = getNonOverlapArea(areaA, bounds)
        const nonOverlapForB = getNonOverlapArea(areaB, bounds)
        return nonOverlapForA < nonOverlapForB ? 1 : -1
      })
      .slice(0, 5)

    const isEarthLikeBbox = w < -170 && s < -80 && e > 170 && n > 80
    if (isEarthLikeBbox) {
      areas.unshift({ name: 'Earth' })
    }
    return areas
  } catch (error) {
    console.error('Error fetching geo data:', error)
    return { geonames: [] }
  }
}

export function getNonOverlapArea(geoname, currentBounds) {
  const dataBbox = bboxPolygon(Object.values(currentBounds))
  const resultBbox = bboxPolygon([
    geoname.bbox.south,
    geoname.bbox.west,
    geoname.bbox.north,
    geoname.bbox.east
  ])

  const diffDataToResult = difference(dataBbox, resultBbox)
  const areaDiffDataToResult = diffDataToResult ? area(diffDataToResult) : 0
  const diffResultToData = difference(resultBbox, dataBbox)
  const areadiffResultToData = diffResultToData ? area(diffResultToData) : 0

  return areaDiffDataToResult + areadiffResultToData
}
