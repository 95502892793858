import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  updateDefaultVisualisationPropertyKey,
  useDefaultVisualisationPropertyKey,
  useMapViewOptions
} from '~map-creator/core/states/properties'

import { View } from './view'

const SelectDefaultView = ({ onValidated }) => {
  const dispatch = useDispatch()

  const mapViewOptions = useMapViewOptions()
  const defaultVisualisationPropertyKey = useDefaultVisualisationPropertyKey()

  useEffect(() => {
    onValidated(!!defaultVisualisationPropertyKey)
  }, [defaultVisualisationPropertyKey, onValidated])

  const handleChange = (value) => {
    dispatch(updateDefaultVisualisationPropertyKey(value))
  }

  return (
    <View
      selected={defaultVisualisationPropertyKey}
      properties={mapViewOptions}
      onChange={handleChange}
    />
  )
}

SelectDefaultView.defaultProps = {
  onValidated: () => false
}

SelectDefaultView.propTypes = {
  onValidated: PropTypes.func
}

export { SelectDefaultView }
export default SelectDefaultView
