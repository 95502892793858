import {
  LAYER_OUTLINE_COLORS,
  QUANTITY_SELECTION_MAP_COLORS
} from '../../assets/Colors'
import { getFilterPayload } from '../filterStyle'

const getQuantityColorRampsAndValue = (jenks: number[]) => {
  const values: Array<any> = []
  for (let i = 0; i < jenks.length - 1; i++) {
    values.push(QUANTITY_SELECTION_MAP_COLORS[i])
    values.push(jenks[i])
  }
  values.push(QUANTITY_SELECTION_MAP_COLORS[jenks.length - 1])
  return values
}

const getQuantitiesFillColorValues = (property, jenks) => {
  switch (jenks.length) {
    case 0:
      return LAYER_OUTLINE_COLORS[0]
    case 1:
      return QUANTITY_SELECTION_MAP_COLORS[0]
    default:
      return [
        'step',
        ['get', property],
        ...getQuantityColorRampsAndValue(jenks)
      ]
  }
}

const getQuantitiesStyle = (property, jenks, filterArray?) => {
  const values = getQuantitiesFillColorValues(property, jenks)
  return {
    type: 'fill',
    paint: {
      'fill-color': [
        'case',
        ['!=', ['typeof', ['get', property]], 'number'],
        LAYER_OUTLINE_COLORS[0],
        values
      ],
      'fill-outline-color': LAYER_OUTLINE_COLORS[0]
    },
    ...getFilterPayload(filterArray)
  }
}

const getQuantityColorRamps = (jenks) => {
  const colors: Array<any> = []
  for (let i = 1; i < jenks.length; i++) {
    colors.push(QUANTITY_SELECTION_MAP_COLORS[i])
  }
  return colors
}

export {
  getQuantitiesStyle,
  getQuantityColorRamps,
  getQuantityColorRampsAndValue
}
