import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

import ToggleChipInput from '~ui-components/components/molecules/ToggleChipInput'

const SelectUniqueIdentifier = (props) => {
  const { selected, options, onChange } = props

  return (
    <Box data-testid='CreateMap-SelectUniqueIdentifier'>
      <ToggleChipInput
        multiple
        value={selected}
        data={options}
        onChange={onChange}
      />
    </Box>
  )
}

const SelectUniqueIdentifierPropTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.array,
  onChange: PropTypes.func
}

SelectUniqueIdentifier.propTypes = SelectUniqueIdentifierPropTypes

export { SelectUniqueIdentifier, SelectUniqueIdentifierPropTypes }
export default SelectUniqueIdentifier
