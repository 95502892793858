import { Typography, useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2'
import type { FC } from 'react'

import image1 from '~ui-components/assets/images/homepage/highlights/image-1.png'
import image2 from '~ui-components/assets/images/homepage/highlights/image-2.png'
import image3 from '~ui-components/assets/images/homepage/highlights/image-3.png'

import { placeHolder } from '~ui-components/assets/images'

type HighlightContentProps = {
  title: string
  description: string
}

const HighlightContent: FC<HighlightContentProps> = (props) => {
  return (
    <Stack
      alignItems='center'
      spacing={3}
      sx={{
        p: 3,
        textAlign: 'center',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <Typography
        align='center'
        variant='h3'
      >
        {props.title}
      </Typography>

      <Typography
        align='center'
        sx={{
          color: 'text.secondary',
          fontSize: 20,
          fontWeight: 500
        }}
      >
        {props.description}
      </Typography>
    </Stack>
  )
}

type HighlightMediaProps = {
  src?: string
}

const HighlightMedia: FC<HighlightMediaProps> = (props) => {
  const { src = placeHolder.src } = props
  return (
    <Card
      sx={{
        height: '100%',
        p: 2
      }}
    >
      <Box
        sx={{
          pt: 'calc(100% * 4 / 4)',
          position: 'relative'
        }}
      >
        <CardMedia
          image={src}
          sx={{
            height: '100%',
            position: 'absolute',
            top: 0,
            width: '100%'
          }}
        />
      </Box>
    </Card>
  )
}

const HomeHighlights: FC = () => {
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Stack
      gap={4}
      sx={{
        p: 3
      }}
    >
      <Stack gap={4}>
        <Typography
          variant='h2'
          sx={{
            fontSize: 44
          }}
          textAlign='center'
        >
          Why Visualize Your Data with Mapstack?
        </Typography>

        <Typography
          color='text.secondary'
          variant='subtitle1'
          sx={{
            fontSize: 30,
            letterSpacing: 'tight',
            textAlign: 'center'
          }}
        >
          Transform boring spreadsheets into amazing maps, revealing hidden
          patterns for better decision making.
        </Typography>
      </Stack>

      <Grid
        container
        spacing={8}
        sx={{ justifyContent: 'center' }}
      >
        <Grid
          md={6}
          xs={12}
        >
          <HighlightMedia src={image1.src} />
        </Grid>

        <Grid
          md={6}
          xs={12}
        >
          <HighlightContent
            title='Enhanced Understanding'
            description='Visualizing data on a map helps you see patterns and trends that are not evident in spreadsheets. Quickly identify correlations and outliers to gain a deeper understanding of your data.'
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={8}
        sx={{ justifyContent: 'center' }}
      >
        <Grid
          md={6}
          xs={12}
          sx={{ display: isBrowser ? 'block' : 'none' }}
        >
          <HighlightContent
            title='Improved Decision Making'
            description='Interactive maps provide a visual context, making it easier to analyze and interpret data. Use geographic insights to make informed decisions and drive better outcomes for your projects. '
          />
        </Grid>

        <Grid
          md={6}
          xs={12}
        >
          <HighlightMedia src={image2.src} />
        </Grid>

        <Grid
          sx={{ display: isBrowser ? 'none' : 'block' }}
          md={6}
          xs={12}
        >
          <HighlightContent
            title='Engaging Storytelling'
            description='Transform dry data into compelling stories with interactive maps. Share your visualizations easily with stakeholders, clients, and team members to communicate insights clearly and effectively.'
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={8}
        sx={{ justifyContent: 'center' }}
      >
        <Grid
          md={6}
          xs={12}
        >
          <HighlightMedia src={image3.src} />
        </Grid>

        <Grid
          md={6}
          xs={12}
        >
          <HighlightContent
            title='Discover new insights'
            description='Browse thousands of free data maps in our portal for new insights and inspiration. Plus copy maps and use as a starting point for your projects.'
          />
        </Grid>
      </Grid>
    </Stack>
  )
}

export { HomeHighlights }
