import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { StoreContext } from '~map-creator/core/states/context'
import { store } from '~map-creator/map-creator-csv/states/store'

const StateProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    </Provider>
  )
}

StateProvider.propTypes = {
  children: PropTypes.node
}

export { StateProvider, store }
export default StateProvider
