import { FC } from 'react'
import {
  Box,
  Button,
  Image,
  Item,
  Link,
  Typography
} from '~ui-components/emails/components/atoms'
import { truncate } from 'lodash'

type MapLinkProps = {
  mapName: string
  inviteLink: string
  owner: {
    avatar: string
  }
}

export const MapLink: FC<MapLinkProps> = (props) => {
  const { mapName, inviteLink, owner } = props
  return (
    <Box
      align='center'
      p={24}
    >
      <Item align='center'>
        <Image
          data-testid='OwnerAvatar'
          src={owner?.avatar}
          alt='Owner Avatar'
          width={75}
          height={75}
        />
      </Item>
      <Item
        align='center'
        pt={12}
      >
        <Typography
          data-testid='MapName'
          variant='body1'
          bold
        >
          {mapName}
        </Typography>
      </Item>
      <Item
        align='center'
        pt={8}
      >
        <Link
          data-testid='MapInvitationUrl'
          href={inviteLink}
        >
          {truncate(inviteLink, {
            length: 45,
            omission: '...'
          })}
        </Link>
      </Item>
      <Item
        align='center'
        p={24}
      >
        <Button
          data-testid='ViewMapButton'
          href={inviteLink}
        >
          VIEW THE MAP
        </Button>
      </Item>
    </Box>
  )
}
