import { createApi } from '@reduxjs/toolkit/query/react'
import { set } from 'idb-keyval'
import fetch from 'map-data-fetch'
import { getMapById } from '~map-creator/core/api/getMapById'

const mapApi = createApi({
  reducerPath: 'mapApi',
  baseQuery: Promise.resolve,
  endpoints: (builder) => ({
    getMapById: builder.query({
      queryFn: async (mapId) => {
        try {
          const data = await getMapById(mapId)
          const { dataId } = data

          const geojson = await getMapData(dataId)
          if (!geojson) throw new Error('Map data not found')

          await set(`${dataId}:geojson`, geojson)
          data['geojson'] = geojson
          return { data }
        } catch (error) {
          return { error }
        }
      }
    })
  })
})

const getMapData = async (dataId) => {
  return fetch(`public/${dataId}`, 'geojson')
}

export const { useLazyGetMapByIdQuery } = mapApi
export default mapApi
