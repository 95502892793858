import Box from '@mui/material/Box'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SvgIcon from '@mui/material/SvgIcon'
import HeatMap from '@untitled-ui/icons-react/build/esm/Atom02'
import Hexagon from '@untitled-ui/icons-react/build/esm/Hexagon01'
import Layers from '@untitled-ui/icons-react/build/esm/LayersThree01'
import Layers2 from '@untitled-ui/icons-react/build/esm/LayersThree02'
import Table from '@untitled-ui/icons-react/build/esm/LayoutGrid01'
import Boundary from '@untitled-ui/icons-react/build/esm/Map01'
import Marker from '@untitled-ui/icons-react/build/esm/MarkerPin01'
import { useEffect, useState } from 'react'
import { useIsMobile } from '~ui-components/hooks'
import { blue } from '~ui-components/theme/colors'

export const DialActions = {
  Table: {
    label: 'Table view',
    value: 'table'
  },
  Hexagon: {
    label: 'Hexagon map',
    value: 'h3'
  },
  Heatmap: {
    label: 'Heatmap',
    value: 'heat-map'
  },
  Boundary: {
    label: 'Boundary map',
    value: 'boundaries'
  },
  Marker: {
    label: 'Marker map',
    value: 'marker'
  }
}

type SpeedDialAction = {
  icon: JSX.Element
  label: string
  value: string
}

const speedDialActions: SpeedDialAction[] = [
  {
    icon: (
      <SvgIcon>
        <Table />
      </SvgIcon>
    ),
    ...DialActions.Table
  },
  {
    icon: (
      <SvgIcon>
        <Hexagon />
      </SvgIcon>
    ),
    ...DialActions.Hexagon
  },
  {
    icon: (
      <SvgIcon>
        <HeatMap />
      </SvgIcon>
    ),
    ...DialActions.Heatmap
  },
  {
    icon: (
      <SvgIcon>
        <Boundary />
      </SvgIcon>
    ),
    ...DialActions.Boundary
  },
  {
    icon: (
      <SvgIcon>
        <Marker />
      </SvgIcon>
    ),
    ...DialActions.Marker
  }
]

interface BasicSpeedDialProps {
  active: string
  onChange: (action: string) => void
  showLegend: boolean
}

function BasicSpeedDial(props: BasicSpeedDialProps) {
  const { onChange = () => {}, showLegend = false, active = '' } = props || {}
  const [open, setOpen] = useState(false)
  const [selectedAction, setSelectedAction] = useState<string>(active)
  const isMobile = useIsMobile()

  useEffect(() => {
    // Open the SpeedDial when the component is loaded
    setOpen(true)
    // Close the SpeedDial after 3 seconds
    const timer = setTimeout(() => {
      setOpen(false)
    }, 3000)

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer)
  }, [])

  let timeId

  return (
    <Box
      sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1, zIndex: 2 }}
    >
      <SpeedDial
        ariaLabel='SpeedDial basic example'
        sx={[
          {
            position: 'absolute',
            bottom: isMobile && showLegend ? 104 : -14,
            right: 16
          },
          styles.speedDialButton
        ]}
        onMouseLeave={() => {
          timeId = setTimeout(() => {
            setOpen(false)
          }, 500)
        }}
        onMouseEnter={() => {
          if (timeId) clearTimeout(timeId)
          setOpen(true)
        }}
        icon={
          <SpeedDialIcon
            icon={
              <SvgIcon>
                <Layers2 />
              </SvgIcon>
            }
            openIcon={
              <SvgIcon>
                <Layers />
              </SvgIcon>
            }
          />
        }
        open={open}
        onClick={() => setOpen(!open)}
      >
        {speedDialActions.map((action) => (
          <SpeedDialAction
            key={action.value}
            icon={action.icon}
            tooltipOpen={true}
            tooltipTitle={action.label}
            onClick={(e) => {
              e.stopPropagation()
              onChange(action.value)
              setSelectedAction(action.value)
            }}
            sx={styles.speedDial(selectedAction, action)}
          />
        ))}
      </SpeedDial>
    </Box>
  )
}

const styles = {
  speedDialButton: {
    '& > .MuiFab-circular': {
      width: 56,
      height: 56
    }
  },
  speedDial: (selectedAction: string, action: SpeedDialAction) => {
    return {
      '& > .MuiSpeedDialAction-fab': {
        backgroundColor: selectedAction === action.value ? blue.dark : 'white',
        boxShadow: '0px 4px 10px rgb(17 25 39 / 45%)',
        color: selectedAction === action.value ? 'white' : 'inherit'
      },
      '& > .MuiSpeedDialAction-staticTooltipLabel': {
        width: 'max-content'
      },
      '& > .MuiSpeedDialAction-fab:hover': {
        backgroundColor:
          selectedAction === action.value
            ? blue.dark
            : 'rgb(235 235 235 / 87%)',
        color: selectedAction === action.value ? 'white' : 'inherit'
      }
    }
  }
}

export { BasicSpeedDial }
