import { layerStyles } from 'map-style'
import { useMemo } from 'react'
import { useCurrentFeature, useProperties } from '~map-viewer/states/map'
import { useVisualisation } from '~map-viewer/states/visualisation'
import View from './view'

const MapVisualisation = (props) => {
  const properties = useProperties()
  const {
    value: visualisation,
    type: visualisationType,
    classBreaks
  } = useVisualisation()

  const currentFeature = useCurrentFeature()

  const ramps = useMemo(() => {
    if (!visualisationType || !classBreaks?.length) return []
    return layerStyles.getColorRamps(classBreaks, visualisationType)
  }, [visualisationType, classBreaks])

  return (
    <View
      {...props}
      feature={currentFeature}
      visualisation={visualisation}
      visualisationType={visualisationType}
      properties={properties}
      jenks={classBreaks}
      ramps={ramps}
    />
  )
}

export { MapVisualisation }
export default MapVisualisation
