import axios from 'axios'

const uploadFile = async ({ url, formData, file }) => {
  const form = new FormData()
  form.append('Content-Type', file.type)
  Object.entries(formData).forEach(([k, v]) => form.append(k, v))
  form.append('file', file) // must be the last one
  return await axios.post(url, form)
}

export { uploadFile }
