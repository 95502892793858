import { darken, useMediaQuery, useTheme } from '@mui/material'
import { Fragment } from 'react'
import { VerticalLayout } from '~ui-components/components/organisms/VerticalLayout'
import { TopNav } from '~ui-components/components/organisms/VerticalLayout/TopNav'

interface AddressGeocoderCreateProps {
  slots?: {
    Map?: any
    GeocoderAside?: any
    GeocodeDialog?: any
  }
  slotProps?: any
}

const defaultSlots = {
  Map: Fragment,
  GeocoderAside: Fragment,
  GeocodeDialog: Fragment
}

function AddressGeocoderCreate(props: AddressGeocoderCreateProps) {
  const { slots, slotProps } = props

  const { Map, GeocoderAside, GeocodeDialog } = {
    ...defaultSlots,
    ...slots
  }

  const {} = slotProps || {}

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down(390))

  return (
    <>
      <VerticalLayout
        openMobileNav={mobile}
        navColor='discrete'
        sideNavWidth={390}
        mobileNavWidth={mobile ? '100%' : 390}
        slots={{
          Content: Map,
          TopNav: AddressGeocoderTopNav
        }}
        slotProps={{
          SideNav: {
            disableScrollbar: true,
            slots: {
              Content: GeocoderAside
            }
          },
          MobileNav: {
            disableScrollbar: true,
            slots: {
              Content: GeocoderAside
            }
          }
        }}
      />

      <GeocodeDialog />
    </>
  )
}

function AddressGeocoderTopNav(props) {
  return (
    <TopNav
      {...props}
      variant='fixed'
      background='none'
      slotProps={{
        MenuButton: {
          sx: (theme) => ({
            boxShadow: theme.shadows[5],
            backgroundColor: theme.palette.background.default,
            '&:hover': {
              backgroundColor: darken(
                theme.palette.background.default,
                theme.palette.action.hoverOpacity
              )
            }
          })
        }
      }}
    />
  )
}

export { AddressGeocoderCreate }
