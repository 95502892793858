import { Tab, Tabs } from '@mui/material'
import PropTypes from 'prop-types'

const sizes = {
  small: 42,
  medium: 48,
  large: 54
}

/**
 * Tabs organize and allow navigation between groups of content
 * that are related and at the same level of hierarchy.
 */
const TabBar = (props) => {
  const { size = 'medium', currentTab, items, sx, onTabChange, ...rest } = props
  return (
    <Tabs
      sx={{
        minHeight: sizes[size],
        height: sizes[size],
        '.MuiTab-root': {
          minHeight: sizes[size],
          height: sizes[size]
        },
        ...sx
      }}
      value={currentTab}
      onChange={onTabChange}
      {...rest}
    >
      {items.map((item) => (
        <Tab
          key={item.id}
          {...item}
        />
      ))}
    </Tabs>
  )
}

/**
 * @type {import('@mui/material/Tabs').TabsProps}
 */
const TabBarPropTypes = {
  /**
   * Size of the tab bar
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Current tab value
   */
  currentTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Array of tab children
   */
  items: PropTypes.array,
  /**
   * Callback when the tab value is changed
   */
  onTabChange: PropTypes.func
}

TabBar.propTypes = TabBarPropTypes

export { TabBar, TabBarPropTypes }
