import PropTypes from 'prop-types'
import { typography } from '~ui-components/emails/theme/typography'

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'p',
  subtitle2: 'p',
  body1: 'p',
  body2: 'p',
  link: 'span',
  button: 'span',
  caption: 'span'
}

const Typography = (props) => {
  const {
    as: component,
    color,
    variant = 'body1',
    italic = false,
    bold = false,
    fontWeight,
    style,
    children
  } = props
  const Component = component || variantMapping[variant]
  return (
    <Component
      data-testid={props['data-testid']}
      className='typography'
      style={{
        margin: 0,
        fontFamily: [
          'Inter',
          'Helvetica Neue',
          'Helvetica',
          'Arial',
          'sans-serif'
        ].join(', '),
        fontSize: typography[variant].fontSize,
        lineHeight: typography[variant].lineHeight,
        fontWeight: bold ? 'bold' : typography[variant].fontWeight,
        ...(color ? { color: color || '#211338' } : {}),
        ...(fontWeight ? { fontWeight } : {}),
        ...(italic ? { fontStyle: 'italic' } : {}),
        ...style
      }}
    >
      {children}
    </Component>
  )
}

export { Typography }
export default Typography

Typography.propTypes = {
  as: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'link',
    'button',
    'caption'
  ]),
  italic: PropTypes.bool,
  bold: PropTypes.bool,
  style: PropTypes.object,
  fontWeight: PropTypes.number,
  children: PropTypes.node
}
