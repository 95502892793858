import { VISUALISATION } from '~utils/types/VisualisationType'

const getVisualisationType = (visualisationType) => {
  return {
    isQuantity: visualisationType === VISUALISATION.QUANTITY,
    isCategory: visualisationType === VISUALISATION.CATEGORY
  }
}

export { getVisualisationType }
