import { useRouter } from 'next/router'
import React from 'react'

import {
  Dialog,
  DialogPropTypes
} from '~ui-components/components/molecules/Dialog'

const MapLoadErrorDialog = (props) => {
  const router = useRouter()
  const handleReload = () => {
    router.reload()
  }
  return (
    <Dialog
      title='🤖 Ah-oh, something is wrong 🤖'
      noCancel
      confirmText='Reload'
      onConfirm={handleReload}
      {...props}
    >
      I've informed my human supervisor that this map has malfunctioned.
      <br />
      Please try a reload.
    </Dialog>
  )
}

MapLoadErrorDialog.propTypes = DialogPropTypes

export { MapLoadErrorDialog }
export default MapLoadErrorDialog
