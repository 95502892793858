import {
  GetCsvFileUploadUrlQuery,
  GetCsvFileUploadUrlQueryVariables
} from '~map-creator/types/__generated/gql/graphql'
import { graphierQL } from 'utils'

const getCSVFileUploadUrl = async () => {
  const { getCSVFileUploadUrl } = await graphierQL<
    GetCsvFileUploadUrlQuery,
    GetCsvFileUploadUrlQueryVariables
  >({
    useToken: true,
    query: getCSVFileUploadUrlQuery
  })

  return getCSVFileUploadUrl
}

const getCSVFileUploadUrlQuery = /* GraphQL */ `
  query GetCSVFileUploadUrl {
    getCSVFileUploadUrl {
      dataId
      uploadUrl
      fileName
      formFieldsJson
    }
  }
`

export { getCSVFileUploadUrl, getCSVFileUploadUrlQuery }
export default getCSVFileUploadUrl
