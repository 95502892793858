import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCurrentBounds } from '~map-viewer/states/map'

export const useSetCurrentBounds = (map) => {
  const dispatch = useDispatch()

  useEffect(() => {
    let timeId = null
    if (map) {
      map.on('moveend', () => {
        if (timeId) clearTimeout(timeId)
        timeId = setTimeout(() => {
          const { _ne, _sw } = map.getBounds()
          dispatch(setCurrentBounds([_sw.lng, _sw.lat, _ne.lng, _ne.lat]))
        }, 250)
      })
    }
    return () => {
      if (timeId) clearTimeout(timeId)
    }
  }, [dispatch, map])
}
