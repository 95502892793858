import { Stack } from '@mui/material'
import PropTypes from 'prop-types'

const styles = {
  spacing: {
    sm: {
      button: {
        mb: 1
      },
      label: {
        mt: 0.5
      }
    },
    md: {
      button: {
        mb: 2
      },
      label: {
        mt: 1
      }
    },
    lg: {
      button: {
        mb: 3
      },
      label: {
        mt: 1
      }
    }
  }
}

const Empty = () => null

const defaultSlots = {
  Search: Empty,
  Filter: Empty,
  Table: Empty,
  Legend: Empty
}

const MapActions = (props) => {
  const { sx, spacing = 'md', slots, slotProps } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Stack
      width='fit-content'
      direction='column'
      sx={[
        {
          '& .FloatingButton-Fab': {
            border: (theme) => `1px solid ${theme.palette.grey[400]}`
          },
          '& .FloatingButton, .ToggleViewGroup': {
            mb: styles.spacing[spacing].button.mb
          },
          '& .FloatingButton:last-child': {
            mb: 'unset'
          },
          '& .FloatingButton-Label': {
            mt: styles.spacing[spacing].label.mt
          }
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <Slots.Search {...slotProps?.Search} />
      <Slots.Filter {...slotProps?.Filter} />
      <Slots.Table {...slotProps?.Table} />
      <Slots.Legend {...slotProps?.Legend} />
    </Stack>
  )
}

MapActions.propTypes = {
  spacing: PropTypes.oneOf(['sm', 'md', 'lg']),
  slots: PropTypes.object,
  slotProps: PropTypes.object
}

export { MapActions }
export default MapActions
