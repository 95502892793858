const Status = {
  Idle: 'idle',
  Failed: 'failed',
  Uploading: 'uploading',
  Uploaded: 'uploaded',
  Ready: 'ready'
}

const url = (cloudfront, path) => `https://${cloudfront}/${path}`

const getReadmeAssetsUrl = (element) => {
  switch (element.status) {
    case Status.Ready:
      return url(process.env.MAP_DATA_CLOUDFRONT, element.path)

    case Status.Uploaded:
      return url(process.env.README_ASSETS_TEMP_CLOUDFRONT, element.path)

    default:
      return element.path
  }
}

export { getReadmeAssetsUrl, Status }
