import { Button, SvgIcon, SxProps, Tooltip } from '@mui/material'
import React, { isValidElement } from 'react'

export type IconLabelProps = {
  component?: React.ElementType
  sx?: SxProps
  title?: string
  primaryText: string
  icon: React.ElementType
  IconProps?: React.ComponentProps<typeof SvgIcon>
} & React.ComponentProps<typeof Button>

const IconLabel = (props: IconLabelProps) => {
  const { sx, title, icon: Icon, primaryText, IconProps = {}, ...rest } = props

  const button = (
    <Button
      {...rest}
      variant='text'
      color='inherit'
      data-testid='IconLabel'
      startIcon={
        isValidElement(Icon) ? (
          Icon
        ) : (
          <SvgIcon {...IconProps}>
            <Icon />
          </SvgIcon>
        )
      }
      sx={[
        {
          fontWeight: 'medium',
          fontSize: 14,
          userSelect: 'none',
          color: 'text.secondary',
          whiteSpace: 'nowrap',
          px: 1.25,
          py: 0.5,
          mx: -0.75,
          my: -0.5,
          borderRadius: 1
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      suppressHydrationWarning
    >
      {primaryText}
    </Button>
  )

  if (!title) return button

  return (
    <Tooltip
      title={title}
      placement='top'
    >
      {button}
    </Tooltip>
  )
}

export { IconLabel }
export default IconLabel
