import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { sanitizeNameForTag, throttle } from 'utils'
import { useSuggestedCoverageAreas } from '~map-creator/core/App/BaseMap/hooks/useSuggestedCoverageAreas'
import {
  setSelectedCoverageAreaFromSuggestion,
  setSelectedCoverageAreaName,
  useCoverageArea
} from '~map-creator/core/states/map'
import { setCoverageAreaTag } from '~map-creator/core/states/ui'
import { useSearchMapAreas } from './useSearchMapAreas'
import { View } from './view'

const DefineMapArea = (props) => {
  const { onValidated = () => false } = props

  useSuggestedCoverageAreas()

  const dispatch = useDispatch()

  const {
    results: searchResults,
    loading: inputLoading,
    search
  } = useSearchMapAreas()

  const {
    isLoading,
    suggestionOptions,
    name: coverageAreaName
  } = useCoverageArea()

  const handleInputChange = async (value) => {
    throttle(() => {
      dispatch(setSelectedCoverageAreaName(value))
      onValidated(!!value)

      if (value) {
        search(value)
        dispatch(
          setCoverageAreaTag({
            id: `coverageArea:${sanitizeNameForTag(value)}`,
            name: sanitizeNameForTag(value),
            description: '',
            count: 0,
            type: 'coverageArea'
          })
        )
      }
    }, 250)
  }

  const handleNameChange = (name) => {
    dispatch(setSelectedCoverageAreaName(name))

    const selectedFromSuggestion =
      suggestionOptions.find((geo) => geo.name === name) ||
      searchResults.find((geo) => geo.label === name)

    if (selectedFromSuggestion)
      dispatch(setSelectedCoverageAreaFromSuggestion(selectedFromSuggestion))

    dispatch(
      setCoverageAreaTag({
        id: `coverageArea:${sanitizeNameForTag(name)}`,
        name: sanitizeNameForTag(name),
        description: '',
        count: 0,
        type: 'coverageArea'
      })
    )

    onValidated(!!name)
  }

  const suggestedGeonames = suggestionOptions.map((geo) => ({
    label: geo.name,
    value: geo.name
  }))

  return (
    <View
      name={coverageAreaName}
      isLoading={isLoading}
      suggestedNames={suggestedGeonames}
      inputLoading={inputLoading}
      inputOptions={searchResults}
      onInputChange={handleInputChange}
      onNameChange={handleNameChange}
    />
  )
}

DefineMapArea.propTypes = {
  onValidated: PropTypes.func
}

export { DefineMapArea }
export default DefineMapArea
