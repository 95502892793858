import { GEOJSON_ERRORS } from '../../assets/constants'
import { isPolygonCoor } from '../isPolygonCoor'

/**
 * Determines if an array can be interperted as coordinates for a MultiPolygon
 * @method isMultiPolygonCoor
 * @param coordinates {Array}
 * @return {Boolean}
 */
export const isMultiPolygonCoor = (coordinates) => {
  let errors = []
  if (Array.isArray(coordinates) && coordinates.length > 0) {
    coordinates.forEach((val, index) => {
      const e = isPolygonCoor(val)
      if (e.length) {
        // modify the err msg from 'isPosition' to note the element number
        e[0][1] = 'at ' + index + ': '.concat(e[0][1])
        // build a list of invalid positions
        errors = errors.concat(e)
      }
    })
  } else {
    // 'coordinates must be an array'
    errors.push([
      GEOJSON_ERRORS.COORDINATE_MUST_BE_ARRAY.key,
      GEOJSON_ERRORS.COORDINATE_MUST_BE_ARRAY.description
    ])
  }

  return errors
}
