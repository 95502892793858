import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { sanitizeNameForTag, throttle } from 'utils'
import { useSuggestedFeatureTypes } from '~map-creator/core/App/BaseMap/hooks/useSuggestedFeatureTypes'
import {
  setSelectedFeatureTypeFromSuggestion,
  setSelectedFeatureTypeName,
  useFeatureType
} from '~map-creator/core/states/map'
import { setFeatureTypeTag } from '~map-creator/core/states/ui'
import { useSearchFeatureTypes } from './useSearchFeatureTypes'
import { View } from './view'

const DefineMapFeature = (props) => {
  const { onValidated = () => false } = props
  const dispatch = useDispatch()

  useSuggestedFeatureTypes()

  const {
    results: searchResults,
    loading: inputLoading,
    search
  } = useSearchFeatureTypes()

  const {
    isLoading,
    suggestionOptions,
    name: featureTypeName
  } = useFeatureType()

  const handleInputChange = async (value) => {
    throttle(() => {
      dispatch(setSelectedFeatureTypeName(value))
      onValidated(!!value)

      if (value) {
        search(value)
        dispatch(
          setFeatureTypeTag({
            id: `featureType:${sanitizeNameForTag(value)}`,
            name: sanitizeNameForTag(value),
            description: '',
            count: 0,
            type: 'featureType'
          })
        )
      }
    }, 25)
  }

  const handleNameChange = useCallback(
    (name) => {
      dispatch(setSelectedFeatureTypeName(name))
      dispatch(
        setFeatureTypeTag({
          id: `featureType:${sanitizeNameForTag(name)}`,
          name: sanitizeNameForTag(name),
          description: '',
          count: 0,
          type: 'featureType'
        })
      )
      const selectedFromSuggestion =
        suggestionOptions.find((geo) => geo.name === name) ||
        searchResults.find((geo) => geo.label === name)

      if (selectedFromSuggestion)
        dispatch(setSelectedFeatureTypeFromSuggestion(selectedFromSuggestion))

      onValidated(!!name)
    },
    [dispatch, onValidated, searchResults, suggestionOptions]
  )

  const suggestedNames = suggestionOptions.map((geo) => ({
    label: geo.name,
    value: geo.name
  }))

  return (
    <View
      name={featureTypeName}
      isLoading={isLoading}
      suggestedNames={suggestedNames}
      inputLoading={inputLoading}
      inputOptions={searchResults}
      onInputChange={handleInputChange}
      onNameChange={handleNameChange}
    />
  )
}

DefineMapFeature.propTypes = {
  onValidated: PropTypes.func
}

export { DefineMapFeature }
export default DefineMapFeature
