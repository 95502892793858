import { ThemeOptions } from '@mui/material'
import { ColorPreset, Contrast } from '~ui-components/types'
import { createComponents } from './components'
import { createPalette } from './palette'
import { createShadows } from './shadows'

interface Config {
  colorPreset?: ColorPreset
  contrast?: Contrast
}

const createLightTheme = (config: Config): ThemeOptions => {
  const { colorPreset, contrast } = config
  const palette = createPalette({ colorPreset, contrast })
  const components = createComponents({ palette })
  const shadows = createShadows()

  return {
    palette,
    components,
    shadows
  }
}

export { createLightTheme }
