import AvatarButton from '~ui-components/components/molecules/AvatarButton'
import SignInButton from '~ui-components/components/molecules/SignInButton'

const MapProfile = ({
  variant = 'desktop',
  authenticated,
  slots: Slots,
  onSignInClick
}) => {
  return (
    <>
      {authenticated ? (
        <Slots.NavAvatar />
      ) : (
        <>
          {variant !== 'mobile' ? (
            <SignInButton
              variant='contained'
              onClick={onSignInClick}
            />
          ) : (
            <AvatarButton
              tooltip={{
                title: 'Sign In'
              }}
              onClick={onSignInClick}
            />
          )}
        </>
      )}
    </>
  )
}

export { MapProfile }
