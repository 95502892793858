import {
  Avatar,
  Box,
  Paper,
  Stack,
  SvgIcon,
  SxProps,
  Typography
} from '@mui/material'
import { Upload01 } from '@untitled-ui/icons-react'

import { useDropzone } from 'react-dropzone'

import type { DropzoneOptions } from 'react-dropzone'

interface MapFileInputProps extends DropzoneOptions {
  sx?: SxProps
}

export const MapFileInput = (props: MapFileInputProps) => {
  const { sx, ...options } = props

  const { getRootProps, getInputProps, isDragActive } = useDropzone(options)

  return (
    <Box
      sx={[
        {
          alignItems: 'center',
          border: 1,
          borderRadius: 1,
          borderStyle: 'dashed',
          borderColor: (theme) => theme.palette.primary.main,
          borderWidth: 1,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          outline: 'none',
          p: 4,
          ...(isDragActive && {
            backgroundColor: 'action.active'
          }),
          '&:hover': {
            backgroundColor: 'action.hover',
            cursor: 'pointer'
          }
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <Stack
        minWidth={320}
        component={Paper}
        alignItems='center'
        direction='row'
        spacing={2}
        p={2}
        borderRadius={2}
        elevation={24}
      >
        <Avatar
          sx={{
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            height: 64,
            width: 64
          }}
        >
          <SvgIcon>
            <Upload01 />
          </SvgIcon>
        </Avatar>

        <Stack spacing={1}>
          <Typography
            sx={{
              '& span': {
                textDecoration: 'underline'
              }
            }}
            variant='h6'
          >
            <span>Click to upload</span> address file or drag and drop
          </Typography>

          <Typography
            color='text.secondary'
            variant='body2'
          >
            We support xlsx, xls, and csv
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
