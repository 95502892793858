import React, { forwardRef } from 'react'
import { styled, Box } from '@mui/material'

import { MobilePanelNotch } from '../MobilePanelNotch'
import {
  MobilePanelDefaultHeader,
  MobilePanelFloatingHeader
} from '../MobilePanelHeaders'

const MobilePanelHeaderWrapper = styled(Box)(() => ({
  position: 'relative',
  zIndex: 'var(--mobile-panel-header-zIndex)',
  touchAction: 'none',
  display: 'flex',
  flexDirection: 'column'
}))

const MobilePanelHeader = forwardRef((props, ref) => {
  const { title, header, notchWidth, ...rest } = props
  return (
    <MobilePanelHeaderWrapper
      ref={ref}
      {...rest}
    >
      <MobilePanelNotch
        data-testid='mobile-panel-notch'
        notchWidth={notchWidth}
      />
      {title && !header && (
        <MobilePanelDefaultHeader
          data-testid='mobile-panel-header-default'
          title={title}
        />
      )}
      {!title && header && (
        <MobilePanelFloatingHeader data-testid='mobile-panel-header-custom'>
          {header}
        </MobilePanelFloatingHeader>
      )}
    </MobilePanelHeaderWrapper>
  )
})

MobilePanelHeader.displayName = 'MobilePanelHeader'

export { MobilePanelHeader }
export default MobilePanelHeader
