import { getVisualisationPayload, layerStyles } from 'map-style'
import { useEffect, useMemo, useState } from 'react'

import { get } from 'lodash'
import { useGeojsonData } from '~map-creator/core/App/BaseMap/hooks/useGeojsonData'
import { useDefaultPropertyConfigs } from '~map-creator/core/states/geojson'
import { useActiveVisualisationKey } from '~map-creator/core/states/properties'

const useLayersStyles = (isDeactivated) => {
  const data = useGeojsonData()
  const activeVisualisationKey = useActiveVisualisationKey()
  const configurations = useDefaultPropertyConfigs()
  const [viewStyle, setViewStyle] = useState(layerStyles.getDefaultStyle())
  const geojsonFeatures = useMemo(() => {
    if (!data?.features) return
    const featuresWithoutGeometry = data.features.map(
      // eslint-disable-next-line no-unused-vars
      ({ geometry, ...rest }) => rest
    )
    return featuresWithoutGeometry
  }, [data])

  useEffect(() => {
    const defaultStyleStep =
      !geojsonFeatures || !activeVisualisationKey || !!isDeactivated

    if (defaultStyleStep) {
      return setViewStyle(layerStyles.getDefaultStyle())
    }

    const getVisualisationStyle = () => {
      const { visualisation } = get(configurations, activeVisualisationKey)

      const { jenks } = getVisualisationPayload(
        activeVisualisationKey,
        geojsonFeatures,
        visualisation
      )
      return layerStyles.getViewStyle(
        activeVisualisationKey,
        jenks,
        visualisation
      )
    }
    return setViewStyle(getVisualisationStyle())
  }, [
    data,
    activeVisualisationKey,
    isDeactivated,
    geojsonFeatures,
    configurations
  ])

  return viewStyle
}

export { useLayersStyles }
