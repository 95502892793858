import { set } from 'idb-keyval'

const saveDataToIndexDB = async ({ map, geojson, json, dataIdentifiers }) => {
  if (!map.dataId || !map.id) throw new Error('Map data invalid')

  await set(map.id, map)
  if (geojson) await set(map.dataId, geojson)
  if (json) await set(`${map.dataId}:json`, json)
  if (dataIdentifiers)
    await set(`${map.dataId}:data-identifiers`, dataIdentifiers)
}

export { saveDataToIndexDB }
