import {
  Backdrop,
  Box,
  Stack,
  SxProps,
  Typography,
  styled
} from '@mui/material'
import { keyframes } from '@mui/system'
import { BrandLogo } from '~ui-components/components/atoms/BrandLogo'

const pulse = keyframes`
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
`

const PulseBox = styled(Box)({
  animation: `${pulse} 1s`,
  animationFillMode: 'both',
  animationIterationCount: 'infinite'
})

interface MapstackLoadingProps {
  text?: string
  sx?: SxProps
}

interface MapstackLoadingBackdropProps extends MapstackLoadingProps {
  loading?: boolean
}

function MapstackLoading(props: MapstackLoadingProps) {
  const { text = 'Loading...', ...rest } = props

  return (
    <Stack
      direction='column'
      gap={3}
      justifyContent='center'
      alignItems='center'
      {...rest}
    >
      <PulseBox>
        <BrandLogo size='2xl' />
      </PulseBox>
      <Typography
        variant='overline'
        sx={{
          userSelect: 'none',
          textTransform: 'uppercase'
        }}
      >
        {text}
      </Typography>
    </Stack>
  )
}

function MapstackLoadingBackdrop(props: MapstackLoadingBackdropProps) {
  const { loading = true, text } = props

  return (
    <Backdrop
      open={loading}
      invisible
      data-testid='MapstackLoadingBackdrop'
    >
      <MapstackLoading text={text} />
    </Backdrop>
  )
}

export { MapstackLoading, MapstackLoadingBackdrop }
