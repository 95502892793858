import { useState, useEffect } from 'react'
import { clamp } from 'lodash'
import { useMaxHeight } from './useMaxHeight'
import { useHeightObserver } from './useHeightObserver'

const isNaN = Number.isNaN
const round = Math.round

/**
 * 	Threshold Calculation
 */
const useThresholds = (params) => {
  const { headerRef, contentRef, defaultMaxHeight, thresholds, heightRef } =
    params

  const { minHeight, maxHeight, headerHeight, isDimensionReady } =
    useDimensions({
      headerRef,
      contentRef,
      defaultMaxHeight
    })

  const thresholdsAtReady = isDimensionReady
    ? thresholds({
        height: heightRef.current,
        headerHeight,
        minHeight,
        maxHeight
      })
    : [0]

  const { snapThresholds, minThreshold, maxThreshold } = calculateThresholds(
    thresholdsAtReady,
    maxHeight
  )

  /**
   * A method that take an argument as callback or number and pass the
   * useful parameter back so that the caller can use it to calculate
   * the next threshold, and also snap the threshold in the process.
   */
  const findThreshold = (callbackOrNumber) => {
    const getUnsafeThreshold = (callbackOrNumber) => {
      if (typeof callbackOrNumber === 'function') {
        return callbackOrNumber({
          headerHeight,
          height: heightRef.current,
          minHeight,
          maxHeight,
          snapThresholds
        })
      }
      return callbackOrNumber
    }
    const unsafeThreshold = getUnsafeThreshold(callbackOrNumber)
    const queryThreshold = !isNaN(unsafeThreshold) ? round(unsafeThreshold) : 0
    const outputThreshold = snapThresholds.reduce(
      (currentThreshold, nextThreshold) =>
        Math.abs(nextThreshold - queryThreshold) <
        Math.abs(currentThreshold - queryThreshold)
          ? nextThreshold
          : currentThreshold,
      minThreshold
    )
    return outputThreshold
  }

  return {
    snapThresholds,
    minThreshold,
    maxThreshold,
    findThreshold,
    maxHeight,
    isThresholdReady: isDimensionReady
  }
}

/**
 * Calculate the snap thresholds, minimum threshold, and maximum threshold
 * from snap thresholds that pass into.
 */
const calculateThresholds = (unsafeSnaps, maxHeight) => {
  const safeSnaps = [].concat(unsafeSnaps).map(round)
  // Remove duplicate snap thresholds
  const snapThresholdsSet = safeSnaps.reduce((acc, snapThreshold) => {
    acc.add(clamp(snapThreshold, 0, maxHeight))
    return acc
  }, new Set())
  const snapThresholds = Array.from(snapThresholdsSet)
  const minThreshold = Math.min(...snapThresholds)
  const maxThreshold = Math.max(...snapThresholds)
  return {
    snapThresholds,
    minThreshold,
    maxThreshold
  }
}

/**
 * 	Get Dimensions
 */
const useDimensions = (params) => {
  const { headerRef, contentRef, defaultMaxHeight } = params

  const [ready, setReady] = useState(false)

  const { maxHeight, isMaxHeightReady } = useMaxHeight({
    defaultMaxHeight
  })
  const headerHeight = useHeightObserver(headerRef)
  const contentHeight = useHeightObserver(contentRef)

  const minHeight =
    Math.min(maxHeight - headerHeight, contentHeight) + headerHeight
  const isValid = contentHeight > 0

  useEffect(() => {
    if (isMaxHeightReady && isValid) setReady(true)
  }, [isMaxHeightReady, isValid])

  return {
    minHeight,
    maxHeight,
    headerHeight,
    isDimensionReady: ready
  }
}

export { useThresholds }
