import { ResourcesConfig } from 'aws-amplify'

const isBrowser =
  typeof globalThis.window !== 'undefined' && globalThis.window.location.origin

let host

if (isBrowser) {
  host = window.location.origin
} else if (process.env.NODE_ENV === 'production') {
  host = `https://${process.env.DOMAIN_NAME}`
} else if (process.env.NODE_ENV === 'development') {
  host = `http://localhost:3000`
}

const configure: ResourcesConfig = {
  API: {
    GraphQL: {
      endpoint: process.env.GRAPHQL_APPSYNC_API_URL!,
      apiKey: process.env.GRAPHQL_APPSYNC_API_KEY!,
      region: process.env.REGION,
      defaultAuthMode: 'apiKey'
    }
  },
  Auth: {
    Cognito: {
      userPoolId: process.env.USER_POOL_ID!,
      userPoolClientId: process.env.USER_POOL_WEB_CLIENT_ID!,
      loginWith: {
        oauth: {
          domain:
            process.env.DOMAIN_NAME === 'mapstack.io'
              ? 'auth.mapstack.io'
              : `auth-${process.env.DOMAIN_NAME}`,
          responseType: 'code',
          scopes: ['email', 'openid', 'profile'],
          redirectSignIn: [`${host}/sign-in/callback`],
          redirectSignOut: [`${host}/sign-out`]
        }
      }
    }
  }
}

export { configure }
