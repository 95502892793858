import { NavDrawer as Drawer } from 'ui-components'
import { useAuth } from 'user-auth'

import { CommunityItem } from '../CommunityItem'
import { LoginButton } from '../LoginButton'
import { Logo } from '../Logo'
import { MapCreateButton } from '../MapCreateButton'

export function NavDrawer(props) {
  const { authenticated } = useAuth()
  return (
    <Drawer
      {...props}
      authenticated={authenticated}
      slots={{
        Logo,
        CommunityItem,
        LoginButton,
        MapCreateButton
      }}
    />
  )
}
