import removePrefix from '~ui-components/utilities/removePrefix'

const pathOf = (fileName) =>
  [
    '/public',
    '/assets',
    '/emails',
    '/logos',
    `/${removePrefix(fileName, './')}`
  ].join('')

const mapstack32pxPng = pathOf('./icon-mapstack-32px.png')

const mapstack100pxPng = pathOf('./icon-mapstack-100px.png')

export { mapstack100pxPng, mapstack32pxPng }
