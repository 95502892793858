import jmespath from 'jmespath'
import { isEmpty, size, uniq } from 'lodash'
import { useMemo } from 'react'
import { useJsonFeatures } from '~map-filter-view/states/map'

const query = '[*].properties."$1" | sort_by(@, &to_string(@))'

const useStringOperator = () => {
  const jsonFeatures = useJsonFeatures()

  const features = useMemo(() => {
    if (isEmpty(jsonFeatures)) return []
    return jsonFeatures
  }, [jsonFeatures])

  const getOptions = (property) => {
    let options = jmespath.search(features, query.replaceAll('$1', property))
    options = uniq(options)
    return {
      options
    }
  }

  const countOptions = (property) => {
    const { options } = getOptions(property)
    return size(options)
  }

  return {
    getOptions,
    countOptions
  }
}

export { useStringOperator }
export default useStringOperator
