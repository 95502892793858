import { createContext } from 'react'
import {
  createDispatchHook,
  createSelectorHook,
  createStoreHook
} from 'react-redux'

const mapViewerContext = createContext({})
const useMapViewerStore = createStoreHook(mapViewerContext as any)
const useMapViewerSelector = createSelectorHook(mapViewerContext as any)
const useMapViewerDispatch = createDispatchHook(mapViewerContext as any)

export {
  mapViewerContext,
  useMapViewerDispatch,
  useMapViewerSelector,
  useMapViewerStore
}
