import { Box, Checkbox, checkboxClasses } from '@mui/material'
import { useTodoListElement, useTodoListElementState } from '@udecode/plate'
import { PlateElement } from '@udecode/plate-common'

const TodoListItemElement = (props) => {
  const { element } = props
  const { className, children, ...rest } = props

  const state = useTodoListElementState({ element })

  const {
    checkboxProps: { checked, onCheckedChange }
  } = useTodoListElement(state)

  return (
    <Box
      component={PlateElement}
      {...rest}
      sx={{
        my: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',

        '&:first-of-type': {
          mt: 0
        }
      }}
    >
      <Box
        sx={{
          flex: 1,
          userSelect: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Checkbox
          color='primary'
          size='small'
          sx={{
            userSelect: 'none',
            [`&.${checkboxClasses.root}`]: {
              p: 0,
              mt: '1px'
            }
          }}
          checked={checked}
          onChange={(event, boolean) => onCheckedChange(boolean)}
        />
        <Box
          component='span'
          sx={[
            {
              ml: 1,
              flex: 1,
              display: 'inline-block',
              position: 'relative',
              '&:focus': {
                outline: 'none'
              }
            },
            state.checked && {
              textDecoration: 'line-through'
            }
          ]}
          contentEditable={!state.readOnly}
          suppressContentEditableWarning
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export { TodoListItemElement }
export default TodoListItemElement
