import { Divider, Stack, Tab, Tabs } from '@mui/material'
import { Share07 } from '@untitled-ui/icons-react'
import type { ChangeEvent } from 'react'
import React, { useCallback, useState } from 'react'

import { Sidebar } from '~ui-components/components/molecules/Sidebar'

interface MapShareDrawerProps {
  isEmbed?: boolean
  open: boolean
  onClose: () => void
  onTabChange?: (value: string) => void
  slots?: {
    Share?: React.ComponentType
    Infographic?: React.ComponentType
    ShareMapOptions?: React.ComponentType
    Embed?: React.ComponentType
  }
}

const Empty = () => null

const defaultSlots = {
  Share: Empty,
  Infographic: Empty,
  Embed: Empty
}

function MapShareDrawer(props: MapShareDrawerProps) {
  const { onTabChange, open, onClose, slots, isEmbed = false } = props
  const [currentTab, setCurrentTab] = useState<string>('share')

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const handleTabsChange = useCallback(
    (event: ChangeEvent<any>, value: string): void => {
      setCurrentTab(value)
      if (onTabChange) onTabChange(value)
    },
    []
  )

  if (open && currentTab === 'infographic' && onTabChange)
    onTabChange('infographic')

  return (
    <Sidebar
      isEmbed={isEmbed}
      open={open}
      icon={Share07}
      title='Share map'
      anchor='left'
      onClose={onClose}
      ModalProps={{
        disablePortal: true,
        hideBackdrop: true,
        sx: {
          top: 'unset',
          right: 'unset',
          bottom: 'unset',
          left: 'unset'
        }
      }}
    >
      <Tabs
        sx={{ mx: 2 }}
        indicatorColor='primary'
        onChange={handleTabsChange}
        scrollButtons='auto'
        color='secondary'
        textColor='secondary'
        value={currentTab}
        variant='scrollable'
      >
        <Tab
          label='Share'
          value='share'
        />

        <Tab
          label='Embed'
          value='embed'
        />

        <Tab
          label='Infographic'
          value='infographic'
        />
      </Tabs>

      <Divider />

      <Stack
        spacing={2}
        p={2}
        sx={{ flexGrow: 1 }}
      >
        {currentTab === 'share' && <Slots.Share />}
        {currentTab === 'infographic' && <Slots.Infographic />}
        {currentTab === 'embed' && <Slots.Embed />}
      </Stack>
    </Sidebar>
  )
}

export { MapShareDrawer }
