import { IconButton, IconButtonProps, SvgIcon } from '@mui/material'
import XClose from '@untitled-ui/icons-react/build/esm/XClose'
import React from 'react'

interface CloseButtonProps extends IconButtonProps {}

const Button = (props, ref) => {
  const { sx, onClick, ...rest } = props

  return (
    <IconButton
      {...rest}
      ref={ref}
      color='standard'
      sx={[
        { borderRadius: '99px', width: '32px', height: '32px' },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      onClick={onClick}
    >
      <SvgIcon>
        <XClose />
      </SvgIcon>
    </IconButton>
  )
}

const CloseButton = React.forwardRef<HTMLButtonElement, CloseButtonProps>(
  Button
)

export { CloseButton }
export default CloseButton
