import { Theme, useMediaQuery } from '@mui/material'

import { useFilters } from '~map-filter-view/states/filter'
import { useVisualisations } from '~map-viewer/states/map'

export const MAX_CHIPS_THRESHOLD = 6

export const useWhetherToCollapseChips = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(768))

  const filters = useFilters()
  const visualisations = useVisualisations()

  return (
    isMobile || visualisations.length + filters.length >= MAX_CHIPS_THRESHOLD
  )
}
