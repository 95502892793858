import { Box, Skeleton as MuiSkeleton } from '@mui/material'
import PropTypes from 'prop-types'

const Skeleton = ({
  inferDimension = false,
  loading = false,
  variant = 'rounded',
  width,
  height,
  skeletonProps = {},
  children = null,
  ...rest
}) => {
  if (loading)
    return (
      <Box
        {...rest}
        width={width}
        height={height}
      >
        <MuiSkeleton
          variant={variant}
          width='100%'
          height='100%'
          {...skeletonProps}
        >
          {inferDimension ? <Box>{children}</Box> : null}
        </MuiSkeleton>
      </Box>
    )

  return <>{children}</>
}

const SkeletonPropTypes = {
  loading: PropTypes.bool,
  inferDimension: PropTypes.bool,
  /**
   * @type {import('@mui/material/Skeleton').SkeletonProps}
   */
  skeletonProps: PropTypes.any
}

Skeleton.propTypes = SkeletonPropTypes

export { Skeleton }
export default Skeleton
