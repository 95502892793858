import { get } from 'idb-keyval'
import React, { useEffect, useState } from 'react'
import { GEOJSON_SOURCE } from '~map-viewer/assets/constant/LayerConstant'
import { View } from './view'

const GeojsonLayer = (props) => {
  const [data, setData] = useState(null)
  const { dataId } = props

  useEffect(() => {
    get(dataId).then((value) => {
      setData(value)
    })
  }, [dataId])

  return (
    data && (
      <View
        {...props}
        type='geojson'
        source={GEOJSON_SOURCE}
        data={data}
      />
    )
  )
}

export { GeojsonLayer }
