import {
  SelectQuantityProperties,
  SelectQuantityPropertiesPropTypes,
  SelectCategoryProperties,
  SelectCategoryPropertiesPropTypes
} from 'ui-components'
import PropTypes from 'prop-types'

const View = (props) => {
  const { selectQuantityProps, selectCategoryProps } = props
  return (
    <>
      <SelectQuantityProperties {...selectQuantityProps} />
      <SelectCategoryProperties {...selectCategoryProps} />
    </>
  )
}

View.propTypes = {
  selectQuantityProps: PropTypes.shape(SelectQuantityPropertiesPropTypes),
  selectCategoryProps: PropTypes.shape(SelectCategoryPropertiesPropTypes)
}

export { View }
