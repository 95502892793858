import { FC } from 'react'
import { MapLibreRef } from '~ms-map-gl/components/Map'
import { useImageSymbol } from '~ms-map-gl/hooks/useImageSymbol'
import { Layer, LayerProps } from '..'

type MarkerLayerProps = LayerProps & {
  map?: MapLibreRef
}

export const MarkerLayer: FC<MarkerLayerProps> = (props) => {
  const { map, ...rest } = props

  useImageSymbol(map)

  return <Layer {...rest} />
}
