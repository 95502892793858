import { useEffect, useRef } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const useScrollLock = (params) => {
  const { targetRef, enabled, reserveScrollBarGap } = params

  const ref = useRef({
    activate: () => {},
    deactivate: () => {},
    status: 'deactivate'
  })

  useEffect(() => {
    if (!enabled) {
      ref.current.deactivate()
      ref.current = {
        activate: () => {},
        deactivate: () => {},
        status: 'deactivate'
      }
      return
    }

    const target = targetRef.current
    let active = false

    ref.current = {
      activate: () => {
        if (active) return
        active = true
        ref.current.status = 'activate'
        disableBodyScroll(target, {
          allowTouchMove: (el) => el.closest('[data-body-scroll-lock-ignore]'),
          reserveScrollBarGap
        })
      },
      deactivate: () => {
        if (!active) return
        active = false
        ref.current.status = 'deactivate'
        enableBodyScroll(target)
      },
      status: 'deactivate'
    }
  }, [enabled, targetRef, reserveScrollBarGap])

  return ref
}

export { useScrollLock }
