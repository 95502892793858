import { GEOJSON_ERRORS } from '../../assets/constants'

/**
 * Determines if an object is a valid Bounding Box
 * @method isValidBbox
 * @param bbox {Object}
 * @return {Array}
 */
export const validateBbox = (bbox) => {
  const errors = []
  if (Array.isArray(bbox)) {
    if (bbox.length % 2 !== 0) {
      errors.push([
        GEOJSON_ERRORS.BBOX_IS_NOT_EVEN_ARRAY.key,
        GEOJSON_ERRORS.BBOX_IS_NOT_EVEN_ARRAY.description
      ])
    }
    bbox.forEach((b, index) => {
      if (typeof b !== 'number') {
        errors.push([
          GEOJSON_ERRORS.BBOX_MUST_CONTAIN_NUMBER_ONLY.key,
          GEOJSON_ERRORS.BBOX_MUST_CONTAIN_NUMBER_ONLY.description +
            b +
            ' at index ' +
            index +
            ' is invalid.'
        ])
      }
    })
  } else {
    errors.push([
      GEOJSON_ERRORS.BBOX_IS_NOT_ARRAY.key,
      GEOJSON_ERRORS.BBOX_IS_NOT_ARRAY.description
    ])
  }

  return errors
}
