import { WorkspaceCreatorStepLayout as Layout } from '~ui-components/components/organisms/WorkspaceCreatorPanels'
import { WorkspaceGetStarted } from '~ui-components/components/organisms/WorkspacePanels/WorkspaceGetStarted'

const WorkspaceCreatorStepTerms = (props) => {
  const { onCreateWorkspaceClick } = props
  return (
    <Layout
      step={1}
      totals={4}
      form={
        <WorkspaceGetStarted onCreateWorkspaceClick={onCreateWorkspaceClick} />
      }
    />
  )
}

export { WorkspaceCreatorStepTerms }
export default WorkspaceCreatorStepTerms
