import { Button, Card, Stack, SvgIcon, Typography } from '@mui/material'
import NextLink from 'next/link'
import type { FC } from 'react'
import { getSignInLink } from 'utils'

import { Check } from '@untitled-ui/icons-react'

type Data = {
  title: string
  description: string
  list: string[]
  action: JSX.Element
}

type ItemProps = Data

const Benetfit: FC<ItemProps> = (props) => {
  const { title, description, list, action } = props

  return (
    <Card
      sx={{
        flex: 1,
        position: 'relative',
        height: 'auto'
      }}
    >
      <Stack
        alignItems='center'
        spacing={4}
        sx={{
          p: 3,
          textAlign: 'center',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Typography
          align='center'
          variant='h5'
        >
          {title}
        </Typography>

        <Typography
          align='center'
          sx={{
            fontSize: 18,
            fontWeight: 500
          }}
        >
          {description}
        </Typography>

        <Stack
          spacing={2}
          sx={{ flexGrow: 1 }}
        >
          {list.map((item) => (
            <Stack
              alignItems='center'
              direction='row'
              spacing={1}
              key={item}
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <SvgIcon color='success'>
                <Check />
              </SvgIcon>
              <Typography
                sx={{ fontWeight: 500 }}
                variant='body1'
              >
                {item}
              </Typography>
            </Stack>
          ))}
        </Stack>

        {action}
      </Stack>
    </Card>
  )
}

const HomePricing: FC = (props) => {
  return (
    <Stack spacing={4}>
      <Typography
        variant='h2'
        sx={{
          fontSize: 44
        }}
        textAlign='center'
      >
        Get Started with Mapstack
      </Typography>

      <Stack
        gap={4}
        sx={(theme) => ({
          flexDirection: 'row',
          [theme.breakpoints.down(768)]: {
            flexDirection: 'column'
          }
        })}
      >
        <Benetfit
          title='Mapstack Free'
          description='Create and share stunning interactive maps at no cost. Perfect for individuals and small teams looking to visualize their data effortlessly.'
          list={[
            'Unlimited public maps',
            'Access to all features',
            'Easy data upload and visualization',
            'Export and share maps'
          ]}
          action={
            <Button
              component={NextLink}
              href={getSignInLink()}
              size='large'
            >
              Get Started for Free
            </Button>
          }
        />

        <Benetfit
          title='Paid Plans Coming Soon'
          description='We will soon offer paid plans with advanced features and additional benefits. Stay tuned for more details!'
          list={[
            'Unlimited private maps',
            'Premium datasets',
            'Additional data integrations'
          ]}
          action={
            <Button
              component={NextLink}
              href={getSignInLink()}
              size='large'
            >
              Try Mapstack Free
            </Button>
          }
        />
      </Stack>
    </Stack>
  )
}

export { HomePricing }
