import geojson from './src/geojson-validation'
import csv from './src/csv-validation'
export * from './src/assets/constants'

const validator = {
  geojson,
  csv
}

export { geojson, csv }
export default validator
