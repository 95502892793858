import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  updateTitlePropertyKey,
  useConfigurations,
  useIncludedKeys,
  useTitlePropertyKey
} from '~map-creator/core/states/properties'

import { View } from './view'

const DefineFeatureTitle = (props) => {
  const { onValidated } = props
  const dispatch = useDispatch()

  const configurations = useConfigurations()
  const titleProperty = useTitlePropertyKey()
  const includedProperties = useIncludedKeys()
  const titlePropertiesOptions = includedProperties.map((k) => ({
    label: configurations[k].name,
    value: k
  }))

  useEffect(() => {
    onValidated(!!titleProperty)
  }, [onValidated, titleProperty])

  const handleChange = (value) => {
    dispatch(updateTitlePropertyKey(value))
  }

  return (
    <View
      selected={titleProperty}
      properties={titlePropertiesOptions}
      onChange={handleChange}
    />
  )
}

DefineFeatureTitle.propTypes = {
  onValidated: PropTypes.func.isRequired
}

DefineFeatureTitle.defaultProps = {
  onValidated: () => false
}

export { DefineFeatureTitle }
export default DefineFeatureTitle
