import { MS_CATEGORY_COLOUR_PALETTE } from '../constants'
import { generateCategories } from '../get-class-breaks'
import { generateCategoryColourPalette } from '../get-colour-palette'
import { generateLegend, generateSymbol } from './category-marker-utils'

const DEFAULT_MARKER_OPTIONS = {
  aggregateBoundary: 'MS_UUID',
  aggregateProperty: 'category',
  selected: 'mapstack-no-value'
}

function getCategoryMarkerStyle(geojson, options = DEFAULT_MARKER_OPTIONS) {
  const featureCategoryRecord = generateCategories(
    geojson.features,
    options.aggregateProperty
  )

  const colourPalette: string[] = generateCategoryColourPalette(
    Object.keys(featureCategoryRecord).length,
    MS_CATEGORY_COLOUR_PALETTE
  )

  const symbol = generateSymbol(featureCategoryRecord, colourPalette, {
    selected: options.selected,
    property: options.aggregateBoundary
  })

  const legend = generateLegend(
    Object.keys(featureCategoryRecord),
    colourPalette,
    options.aggregateProperty
  )

  return {
    symbol,
    legend
  }
}

export { getCategoryMarkerStyle }
