import Script from 'next/script'

interface GoogleTagManagerProps {
  gtmId: string
  gaId: string
}

function GoogleTagManager(props: GoogleTagManagerProps): JSX.Element {
  const { gtmId, gaId } = props

  return (
    <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${gtmId}`} />
      <Script
        id='google-analytics'
        strategy='afterInteractive'
      >
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', '${gtmId}');
          gtag('config', '${gaId}');
        `}
      </Script>
    </>
  )
}

export { GoogleTagManager }
