import { graphierQL } from 'utils'
import {
  UpdateMapMutation,
  UpdateMapMutationVariables
} from '~map-creator/types/__generated/gql/graphql'

const updateMap = async (input) => {
  const { updateMap } = await graphierQL<
    UpdateMapMutation,
    UpdateMapMutationVariables
  >({
    useToken: true,
    query: updateMapMutation,
    variables: { input }
  })

  return updateMap
}

const updateMapMutation = /* GraphQL */ `
  mutation UpdateMap($input: UpdateMapInput!) {
    updateMap(input: $input) {
      id
      name
    }
  }
`
export { updateMap, updateMapMutation }
export default updateMap
