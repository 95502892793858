import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'

import { User } from '~ui-components/types/__generated/gql/graphql'
import { DashboardLayoutSection } from '..'
import { MobileNav } from '../mobile-nav'

import { SideNav } from './side-nav'
import { TopNav } from './top-nav'
import { useMobileNav } from './use-mobile-nav'

import type { Theme } from '@mui/material/styles/createTheme'
import type { FC, ReactNode } from 'react'
import { WorkspacesListMenuProps } from '~ui-components/components/molecules'

const SIDE_NAV_WIDTH = 280

const VerticalLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH
  }
}))

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
})

interface VerticalLayoutProps {
  sections?: DashboardLayoutSection[]
  user?: User
  workspaces: WorkspacesListMenuProps['workspaces']
  children?: ReactNode
  slots?: any
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  const { children, sections, user, workspaces, slots } = props
  const mobileNav = useMobileNav()

  return (
    <>
      <TopNav
        user={user}
        workspaces={workspaces}
        onMobileNavOpen={mobileNav.handleOpen}
        slots={slots}
      />
      {lgUp && <SideNav sections={sections} />}
      {!lgUp && (
        <MobileNav
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <VerticalLayoutRoot>
        <VerticalLayoutContainer>{children}</VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  )
}

VerticalLayout.propTypes = {
  children: PropTypes.node,
  sections: PropTypes.array
}
