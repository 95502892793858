import { Box, useTheme } from '@mui/material'
import Container from '@mui/material/Container'
import React, { FC, Fragment } from 'react'

import { ExampleMaps } from '~ui-components/components/organisms/ExampleMaps'
import { Footer } from '~ui-components/components/organisms/Footer'
import { HomeHighlights } from '~ui-components/components/organisms/HomeHighlights'
import { HomeHowItWorks } from '~ui-components/components/organisms/HomeHowItWorks'
import { HomePricing } from '~ui-components/components/organisms/HomePricing'
import { HomeUseCases } from '~ui-components/components/organisms/HomeUseCases'

const Section = (props) => {
  const { component, bgcolor, children, ...rest } = props
  const theme = useTheme()

  return (
    <Box
      component={component || 'section'}
      bgcolor={bgcolor || theme.palette.background.default}
      {...rest}
    >
      {children}
    </Box>
  )
}

const Main = (props) => {
  return (
    <Box
      component='main'
      {...props}
      sx={{
        pt: 'env(safe-area-inset-top)'
      }}
    />
  )
}

const defaultSlots = {
  Header: Fragment,
  ExampleMaps
}

type HomeProps = {
  slots?: {
    Header?: any
    HomeHero?: any
    ExampleMaps?: any
  }
  slotProps?: {
    Header?: any
    HomeHero?: any
    ExampleMaps?: React.ComponentProps<typeof ExampleMaps>
  }
}

const Home: FC<HomeProps> = ({ slots, slotProps }) => {
  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Fragment>
      <Slots.Header {...slotProps?.Header} />

      <Main position='relative'>
        <Container maxWidth='lg'>
          <Section position='relative'>
            <Slots.HomeHero {...slotProps?.HomeHero} />
          </Section>

          <Section sx={{ mt: 10 }}>
            <HomeUseCases />
          </Section>

          <Section sx={{ mt: 10 }}>
            <HomeHighlights />
          </Section>

          <Section sx={{ mt: 10 }}>
            <HomeHowItWorks />
          </Section>

          <Section sx={{ mt: 10 }}>
            <HomePricing />
          </Section>

          <Section sx={{ mt: 10 }}>
            <Slots.ExampleMaps {...slotProps?.ExampleMaps} />
          </Section>
        </Container>
      </Main>
      <Footer />
    </Fragment>
  )
}

export { Home }
