import { Box, Typography } from '@mui/material'

const UploadSuccess = () => {
  return (
    <Box
      data-testid='Step-UploadSuccess'
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <Typography
        variant='body2'
        gutterBottom
      >
        You will be asked a short series of questions about the data.
      </Typography>
      <Box sx={{ '& span': { ml: 2 } }}>
        <Typography
          variant='body2'
          mb={2}
        >
          Your valued contribution will ensure this map is:
        </Typography>

        <Typography variant='body2'>
          <span>&#x2022; Discoverable</span>
          <br />
          <span>&#x2022; Accessbile</span>
          <br />
          <span>&#x2022; Useful</span>
        </Typography>
      </Box>
      <Typography
        variant='body2'
        fontWeight='600'
      >
        For free, for everyone, forever!
      </Typography>
    </Box>
  )
}

export { UploadSuccess }
export default UploadSuccess
