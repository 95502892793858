import {
  LoadingButton,
  LoadingButtonProps as MuiLoadingButtonProps
} from '@mui/lab'
import {
  Button,
  IconButton,
  LinearProgress,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Stack,
  SvgIcon,
  DialogContentProps as MuiDialogContentProps,
  DialogActionsProps as MuiDialogActionsProps
} from '@mui/material'
import { XClose } from '@untitled-ui/icons-react/build/esm'
import PropTypes from 'prop-types'
import { FC, ReactNode } from 'react'

const DialogTitle = ({ title, onClose }) => {
  return (
    <Stack
      data-testid='DialogTitle'
      direction='row'
      color='text.primary'
      borderBottom={(theme) => `solid 1px ${theme.palette.divider}`}
      justifyContent='space-between'
      alignItems='center'
    >
      <MuiDialogTitle>{title}</MuiDialogTitle>
      <IconButton
        onClick={onClose}
        sx={{ mx: 2 }}
      >
        <SvgIcon>
          <XClose />
        </SvgIcon>
      </IconButton>
    </Stack>
  )
}

const DialogContent: FC<MuiDialogContentProps> = ({ sx, ...rest }) => {
  return (
    <MuiDialogContent
      {...rest}
      sx={[
        {
          typography: 'body2'
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    />
  )
}

const DialogActions: FC<MuiDialogActionsProps> = ({ sx, ...rest }) => {
  return (
    <MuiDialogActions
      {...rest}
      sx={[
        {
          p: 3,
          pt: 1
        },
        (theme) => ({
          [theme.breakpoints.down(425)]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: 2
          }
        }),

        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    />
  )
}

export type DialogProps = MuiDialogProps & {
  loading?: boolean
  showProgress?: boolean
  confirmColor?: MuiLoadingButtonProps['color']
  noCancel?: boolean
  noConfirm?: boolean
  cancelText?: string
  confirmText?: string
  onClose: () => void
  onConfirm: () => void
  description?: string
  dialogActionsComponent?: ReactNode
}

const Dialog: FC<DialogProps> = ({
  open,
  loading = false,
  showProgress = false,
  fullWidth = true,
  maxWidth = 'sm',
  title,
  confirmColor = 'primary',
  noCancel = false,
  noConfirm = false,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  onClose,
  onConfirm,
  description = '',
  children,
  dialogActionsComponent,
  ...rest
}) => {
  const hasButtons = (!noCancel || !noConfirm) && !dialogActionsComponent

  return (
    <MuiDialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      className='Dialog-root'
      aria-labelledby='DialogTitle'
      aria-describedby='DialogContent'
      PaperProps={{
        sx: [
          (theme) => ({
            [theme.breakpoints.down(425)]: {
              m: 0,
              borderRadius: 0,
              border: 'none',
              width: '100%',
              height: '100%',
              maxHeight: '100%'
            }
          })
        ]
      }}
      {...rest}
    >
      {showProgress ? <LinearProgress /> : null}

      <DialogTitle
        title={title}
        onClose={onClose}
      />

      <DialogContent>
        {description || children || '<empty description>'}
      </DialogContent>

      {hasButtons ? (
        <DialogActions>
          {!noCancel ? (
            <Button
              disabled={loading}
              onClick={onClose}
              variant='outlined'
            >
              {cancelText}
            </Button>
          ) : null}

          {!noConfirm ? (
            <LoadingButton
              data-testid='DialogConfirmButton'
              variant='contained'
              color={confirmColor}
              loading={loading}
              onClick={onConfirm}
              disableElevation
            >
              {confirmText}
            </LoadingButton>
          ) : null}
        </DialogActions>
      ) : null}

      {dialogActionsComponent ? <>{dialogActionsComponent}</> : null}
    </MuiDialog>
  )
}

const DialogPropTypes = {
  loading: PropTypes.bool,
  /**
   * State of the dialog, if set to true it will open the dialog
   */
  open: PropTypes.bool.isRequired,
  /**
   * Show the progress on the top of dialog
   */
  showProgress: PropTypes.bool,
  /**
   * Set the dialog to be full screen
   */
  fullWidth: PropTypes.bool,
  /**
   * Set width of the dialog
   */
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  /**
   * Title of the dialog
   */
  title: PropTypes.string,
  confirmColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'warning',
    'error',
    'success'
  ]),
  /**
   * Description of the dialog
   */
  description: PropTypes.string,
  /**
   * Customize cancel text
   */
  cancelText: PropTypes.string,
  /**
   * Customize confirm text
   */
  confirmText: PropTypes.string,
  /**
   * If set to true, it will not render the confirm button
   */
  noConfirm: PropTypes.bool,
  /**
   * If set to true, it will not render the cancel button
   */
  noCancel: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  children: PropTypes.any,
  dialogActionsComponent: PropTypes.node
}

Dialog.propTypes = DialogPropTypes as typeof Dialog.propTypes

export { Dialog, DialogActions, DialogContent, DialogPropTypes, DialogTitle }
export default Dialog
