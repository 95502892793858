import { Chip, Stack } from '@mui/material'
import { sortBy } from 'lodash'
import NextLink from 'next/link'
import { getSearchQuery } from 'utils'

import {
  Category_Tag_Type,
  MapTagCollection
} from '~ui-components/types/__generated/gql/graphql'

interface Props {
  items: MapTagCollection['items']
}

const sortOrder = [
  Category_Tag_Type.Primary,
  Category_Tag_Type.Secondary,
  Category_Tag_Type.Featured
]

const sortLast = sortOrder.length

export function TagsStack(props: Props) {
  const { items } = props

  const tags = sortBy(items, (item) =>
    item?.tag?.categoryType
      ? sortOrder.indexOf(item.tag.categoryType)
      : sortLast
  )

  return (
    <Stack
      direction='row'
      gap={1}
    >
      {tags.map((item) => {
        if (!item?.tag) return
        return (
          <Chip
            key={item.tag.id}
            component={NextLink}
            href={getSearchQuery({
              tags: [item.tag.id]
            })}
            label={item.tag.name}
            color={getColor(item.tag.categoryType ?? undefined) as any}
            size='small'
            onClick={() => {}}
          />
        )
      })}
    </Stack>
  )
}

function getColor(categoryType?: Category_Tag_Type) {
  const colors = {
    [Category_Tag_Type.Primary]: 'primary',
    [Category_Tag_Type.Secondary]: 'secondary',
    [Category_Tag_Type.Featured]: 'info'
  }
  return categoryType ? colors[categoryType] : 'default'
}
