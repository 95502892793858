import { Dialog } from '~ui-components/components/molecules/Dialog'

const DeleteMemberDialog = (props) => {
  const { loading, ...rest } = props
  return (
    <Dialog
      loading={loading}
      title='Are you absolutely sure?'
      confirmText='Remove'
      confirmColor='error'
      {...rest}
    >
      This action will remove the user as a member of this workspace.
    </Dialog>
  )
}

export { DeleteMemberDialog }
