import { ELEMENT_BLOCKQUOTE } from '@udecode/plate-block-quote'
import { ELEMENT_DEFAULT, insertNodes, setNodes } from '@udecode/plate-common'
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading'
import { ELEMENT_HR } from '@udecode/plate-horizontal-rule'

import { preFormat } from './autoformatUtils.js'

export const autoformatBlocks = [
  {
    mode: 'block',
    type: ELEMENT_H1,
    match: '# ',
    preFormat
  },
  {
    mode: 'block',
    type: ELEMENT_H2,
    match: '## ',
    preFormat
  },
  {
    mode: 'block',
    type: ELEMENT_H3,
    match: '### ',
    preFormat
  },
  {
    mode: 'block',
    type: ELEMENT_BLOCKQUOTE,
    match: '> ',
    preFormat
  },
  {
    mode: 'block',
    type: ELEMENT_HR,
    match: ['---', '—-', '___ '],
    format: (editor) => {
      setNodes(editor, { type: ELEMENT_HR })
      insertNodes(editor, {
        type: ELEMENT_DEFAULT,
        children: [{ text: '' }]
      })
    }
  }
]
