import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

export const initialMapState = {
  mapData: {},
  jsonFeatures: {}
}

export const mapSlice = createSlice({
  name: 'map',
  initialState: initialMapState,
  reducers: {
    clearMapData: () => {},

    setMapData: (state, action) => {
      state.mapData = action.payload
    }
  }
})

export const { setMapData, clearMapData } = mapSlice.actions

const selectMapData = (state) => state.map.mapData
const selectJsonFeatures = (state) => state.map.jsonFeatures

export const useMap = () => useSelector(selectMapData)
export const useJsonFeatures = () => useSelector(selectJsonFeatures)

export default mapSlice.reducer
