import { createApi } from '@reduxjs/toolkit/query/react'

import { deleteMapById } from '~map-viewer/api/deleteMapById'
import { getMapRemixCollection } from '~map-viewer/api/getMapRemixCollection'
import { saveReadme } from '~map-viewer/api/saveReadme'

const mapApi = createApi({
  reducerPath: 'mapApi',
  baseQuery: Promise.resolve,
  endpoints: (builder) => ({
    deleteMapById: builder.mutation({
      queryFn: async (id) => {
        try {
          const data = await deleteMapById(id)
          return { data }
        } catch (error) {
          return { error }
        }
      }
    }),

    saveReadme: builder.mutation({
      queryFn: async ({ mapId, nodes }) => {
        try {
          const data = await saveReadme({ mapId, nodes })
          return { data }
        } catch (error) {
          return { error }
        }
      }
    }),

    getMapRemixCollection: builder.query({
      queryFn: async ({ mapId, limit, offset }) => {
        try {
          const data = await getMapRemixCollection(mapId, limit, offset)
          return { data }
        } catch (error) {
          return { error }
        }
      }
    })
  })
})

export const {
  useDeleteMapByIdMutation,
  useSaveReadmeMutation,
  useGetMapRemixCollectionQuery
} = mapApi
export default mapApi
