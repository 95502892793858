import _ from 'lodash'
import { SymbolLayer } from 'react-map-gl/maplibre'
import { LOCATION_MARKER_IMAGE_ID } from '../../assets/Constants'
import { getFilterPayload } from '../filterStyle/index'

const getPointStyle = (
  filterArray?: string[]
): Omit<SymbolLayer, 'source' | 'id'> => {
  const filterInput = _.size(filterArray) > 0 ? filterArray : undefined
  return {
    type: 'symbol',
    layout: {
      'icon-image': LOCATION_MARKER_IMAGE_ID,
      'icon-allow-overlap': true,
      'icon-size': 0.05,
      'icon-padding': 0,
      'icon-offset': [0, -220],
      'symbol-placement': 'point'
    },
    ...getFilterPayload(filterInput)
  } as Omit<SymbolLayer, 'source' | 'id'>
}

export { getPointStyle }
