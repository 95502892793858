import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import AlertTriangle from '@untitled-ui/icons-react/build/esm/AlertTriangle'
import { ReactNode, useState, type FC } from 'react'

export const Modal: FC<{
  icon: ReactNode
  title: ReactNode
  content: ReactNode
  cancelButtonProps: LoadingButtonProps
  confirmButtonProps: LoadingButtonProps
}> = ({
  icon,
  title,
  content,
  cancelButtonProps: {
    children: cancelButtonChildren,
    ...restCancelButtonProps
  },
  confirmButtonProps: {
    children: confirmButtonChildren,
    ...restConfirmButtonProps
  }
}) => {
  return (
    <Dialog open>
      <Stack
        direction='row'
        spacing={2}
        sx={{
          display: 'flex',
          p: 3
        }}
      >
        {icon}
        <div>
          <Typography variant='h5'>{title}</Typography>
          <Typography
            color='text.secondary'
            sx={{ mt: 1 }}
            variant='body2'
          >
            {content}
          </Typography>
        </div>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          pb: 3,
          px: 3,
          gap: 2
        }}
      >
        <LoadingButton
          variant='text'
          color='inherit'
          {...restCancelButtonProps}
        >
          {cancelButtonChildren}
        </LoadingButton>
        <LoadingButton
          variant='contained'
          {...restConfirmButtonProps}
        >
          {confirmButtonChildren}
        </LoadingButton>
      </Box>
    </Dialog>
  )
}
