import { Box, Divider as MuiDivider } from '@mui/material'

import { Bold } from '~ui-components/components/molecules/TextEditor/components/Actions/Bold'
import { AddImage } from '~ui-components/components/molecules/TextEditor/components/Actions/Image'
import { Italic } from '~ui-components/components/molecules/TextEditor/components/Actions/Italic'
import { Link } from '~ui-components/components/molecules/TextEditor/components/Actions/Link'
import { OrderedList } from '~ui-components/components/molecules/TextEditor/components/Actions/OrderedList'
import { Quote } from '~ui-components/components/molecules/TextEditor/components/Actions/Quote'
import { UnorderedList } from '~ui-components/components/molecules/TextEditor/components/Actions/UnorderedList'
import { TurnInto } from '~ui-components/components/molecules/TextEditor/components/TurnInto'
import { Strikethrough } from '../Actions/Strikethrough'
import { Underline } from '../Actions/Underline'

const Divider = () => {
  return (
    <MuiDivider
      flexItem
      orientation='vertical'
    />
  )
}

const Group = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1.5,
        flexWrap: 'wrap'
      }}
    >
      {children}
    </Box>
  )
}

const Header = (props) => {
  return (
    <Box
      component='header'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderTopLeftRadius: 1,
        borderTopRightRadius: 1,
        gap: 1.5
      }}
    >
      <Group>
        <TurnInto />
        <Divider />
      </Group>

      <Group>
        <Bold />
        <Italic />
        <Strikethrough />
        <Underline />
        <Quote />
        <Link />
        <AddImage />
        <Divider />
        <UnorderedList />
        <OrderedList />
      </Group>
    </Box>
  )
}

export { Header }
