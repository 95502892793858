import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { last } from 'lodash'
import { useSelector } from 'react-redux'

import { MAP_CREATOR_GEOJSON_STEPS } from '~map-creator/core/assets/constants'
import { getGeoName } from '~map-creator/map-creator-geojson/api/getGeoName'

import mapApi from '~map-creator/core/services/map'

export const getSuggestedCoverageAreas = createAsyncThunk(
  'map/getSuggestedCoverageAreas',
  async (currentBounds) => {
    return await getGeoName({ bounds: currentBounds })
  }
)

export const mapInitialState = {
  name: '',
  wizardSteps: [MAP_CREATOR_GEOJSON_STEPS.DATA_UPLOAD],
  currentBounds: {
    s: null,
    w: null,
    n: null,
    e: null
  },
  coverageArea: {
    isLoading: false,
    suggestionOptions: [],
    selectedSuggestion: null,
    name: ''
  },
  featureType: {
    isLoading: false,
    suggestionOptions: [],
    selectedSuggestion: null,
    name: ''
  },
  license: {
    name: '',
    url: ''
  },
  source: {
    name: '',
    url: ''
  },
  popupSelectedProperty: null
}

export const mapExtraReducers = (builder) => {
  builder
    .addCase(getSuggestedCoverageAreas.pending, (state) => {
      state.coverageArea.isLoading = true
    })
    .addCase(getSuggestedCoverageAreas.fulfilled, (state, action) => {
      state.coverageArea.isLoading = false
      state.coverageArea.suggestionOptions = action.payload
    })
    .addCase(getSuggestedCoverageAreas.rejected, (state) => {
      state.coverageArea.isLoading = false
    })
    .addMatcher(mapApi.endpoints.getMapById.matchPending, () => {})
    .addMatcher(mapApi.endpoints.getMapById.matchFulfilled, (state, action) => {
      const { bbox, source, license, name } = action.payload
      const [w, s, e, n] = bbox
      state.currentBounds = { w, s, e, n }
      state.name = name
      if (source) state.source = source
      if (license) state.license = license
    })
    .addMatcher(mapApi.endpoints.getMapById.matchRejected, () => {})
}

export const mapReducers = {
  reset: () => {
    return mapInitialState
  },
  setPopupSelectedProperty: (state, action) => {
    state.popupSelectedProperty = action.payload
  },
  setCurrentBounds: (state, action) => {
    state.currentBounds = action.payload
  },
  setSelectedCoverageAreaFromSuggestion: (state, action) => {
    state.coverageArea.selectedSuggestion = action.payload
  },
  setSelectedCoverageAreaName: (state, action) => {
    state.coverageArea.name = action.payload
  },
  setFeatureTypeLoading: (state, action) => {
    state.featureType.isLoading = action.payload
  },
  setSuggestedFeatureTypes: (state, action) => {
    state.featureType.suggestionOptions = action.payload
  },
  setSelectedFeatureTypeFromSuggestion: (state, action) => {
    state.featureType.selectedSuggestion = action.payload
  },
  setSelectedFeatureTypeName: (state, action) => {
    state.featureType.name = action.payload
  },
  setMapName: (state, action) => {
    state.name = action.payload
  },
  pushWizardStep: (state, action) => {
    state.wizardSteps.push(action.payload)
  },
  popWizardStep: (state) => {
    state.wizardSteps.pop()
  },
  replaceLastWizardStep: (state, action) => {
    const currents = [...state.wizardSteps]
    currents.pop()
    currents.push(action.payload)
    state.wizardSteps = currents
  },
  setLicense: (state, action) => {
    state.license = action.payload
  },
  setSource: (state, action) => {
    state.source = action.payload
  }
}

export const mapSlice = createSlice({
  name: 'map',
  initialState: mapInitialState,
  reducers: mapReducers,
  extraReducers: mapExtraReducers
})

const popupSelectedProperty = (state) => state.map.popupSelectedProperty
const currentBounds = (state) => state.map.currentBounds
const coverageArea = (state) => state.map.coverageArea
const featureType = (state) => state.map.featureType
const currentWizardStep = (state) => last(state.map.wizardSteps)
const mapName = (state) => state.map.name
const license = (state) => state.map.license
const source = (state) => state.map.source

const usePopupSelectedProperty = () => {
  return useSelector(popupSelectedProperty)
}
const useCurrentBounds = () => {
  return useSelector(currentBounds)
}
const useCoverageArea = () => {
  return useSelector(coverageArea)
}
const useFeatureType = () => {
  return useSelector(featureType)
}
const useCurrentWizardStep = () => {
  return useSelector(currentWizardStep)
}
const useMapName = () => {
  return useSelector(mapName)
}
const useLicense = () => useSelector(license)
const useSource = () => useSelector(source)

export {
  useCoverageArea,
  useCurrentBounds,
  useCurrentWizardStep,
  useFeatureType,
  useLicense,
  useMapName,
  usePopupSelectedProperty,
  useSource
}

export const {
  setFoo,
  reset,
  setPopupSelectedProperty,
  setCurrentBounds,
  setSelectedCoverageAreaFromSuggestion,
  setSelectedCoverageAreaName,
  setFeatureTypeLoading,
  setSuggestedFeatureTypes,
  setSelectedFeatureTypeFromSuggestion,
  setSelectedFeatureTypeName,
  setMapName,
  setLicense,
  setSource,
  popWizardStep,
  pushWizardStep,
  replaceLastWizardStep
} = mapSlice.actions

export default mapSlice.reducer
