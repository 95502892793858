import { FC } from 'react'
import { Popup as MapLibrePopup, PopupProps } from 'react-map-gl/maplibre'

type Props = {
  onClose: () => void
  latitude: number
  longitude: number
}

type CombinedProps = Props & PopupProps

export const Popup: FC<CombinedProps> = (props: CombinedProps) => {
  const { onClose, latitude, longitude, children, ...rest } = props

  return (
    <MapLibrePopup
      anchor='bottom'
      closeOnClick={false}
      onClose={onClose}
      key={longitude + latitude}
      latitude={latitude}
      longitude={longitude}
      {...rest}
    >
      {children}
    </MapLibrePopup>
  )
}
