/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, SvgIcon, Tooltip } from '@mui/material'
import RefreshCcw01 from '@untitled-ui/icons-react/build/esm/RefreshCcw01'
import clsx from 'clsx'
import { useEffect } from 'react'

import { withRef } from '@udecode/cn'
import { Image, PlateElement, isBlock } from '@udecode/plate'
import { captionActions, useCaptionButton } from '@udecode/plate-caption'
import { findNode, findNodePath } from '@udecode/plate-common'
import {
  useEditorReadOnly,
  useEditorState,
  useElement
} from '@udecode/plate-core'
import { setNodes } from 'slate'
import { useFocused, useSelected } from 'slate-react'

import {
  Status,
  getReadmeAssetsUrl
} from '~ui-components/utilities/getReadmeAssetsUrl'

import {
  Caption,
  CaptionTextarea
} from '~ui-components/components/molecules/TextEditor/components/Caption'

const getProgress = (progress = 0) => {
  if (progress === 0) return 'Preparing...'
  if (progress === 100) return 'Processing...'
  return `Uploading ${progress}%`
}

const useUpload = () => {
  const editor = useEditorState()
  const element = useElement()
  const upload = editor?.cloud?.uploadStore.use.upload(element.uid) || {}
  return upload
}

const useImageState = () => {
  const editor = useEditorState()
  const element = useElement()
  const readOnly = useEditorReadOnly()
  const focused = useFocused()
  const selected = useSelected()

  const upload = useUpload()
  const src = getReadmeAssetsUrl(element)

  useEffect(() => {
    if (upload.status === Status.Uploaded && element.path.startsWith('blob:')) {
      const path = findNodePath(editor, element)

      setNodes(
        editor,
        {
          path: upload.path,
          status: Status.Uploaded
        },
        {
          at: path
        }
      )
    }
  }, [editor, element, upload])

  return {
    readOnly,
    focused,
    selected,
    src,
    upload
  }
}

const useOnImageMount = () => {
  const editor = useEditorState()
  const element = useElement()
  const upload = useUpload()

  useEffect(() => {
    if (!editor?.cloud?.uploadStore) return
    if (element.path.startsWith('blob:') && !upload.status) {
      editor.cloud.uploadStore.set.upload(element.uid, {
        status: Status.Failed
      })
    }
  }, [])
}

const useOnImageUnmount = () => {
  const editor = useEditorState()
  const element = useElement()
  const upload = useUpload()

  useEffect(() => {
    return () => {
      if (upload.progress < 100 && upload.status === Status.Uploading) {
        editor.cloud.uploadEvents.abort(element.uid)
      }
    }
  }, [])
}

const ImageElement = withRef((props, ref) => {
  const { editor, element } = props
  const { className, children, nodeProps, ...rest } = props

  const entry = findNode(editor, {
    match: (node) => {
      return isBlock(editor, node)
    }
  })

  const id = entry[0]?.id

  const { readOnly, src, upload } = useImageState()

  useOnImageMount()
  useOnImageUnmount()

  const handleRetryClick = () => {
    if (upload.status === Status.Failed) {
      editor.cloud.retryUploadImage(element.uid)
    }
  }

  const {
    props: { onClick: showCaption }
  } = useCaptionButton({
    editor,
    element,
    setShowCaption: captionActions.showCaptionId
  })

  useEffect(() => {
    if (readOnly) return
    if (id === element.id) showCaption()
  }, [readOnly, id])

  return (
    <PlateElement
      ref={ref}
      className={clsx(className)}
      {...rest}
    >
      <Box
        component='figure'
        sx={{
          m: 0,
          my: 1,
          position: 'relative'
        }}
        contentEditable={false}
      >
        {upload.status === Status.Uploading && (
          <Tooltip
            title={getProgress(upload.progress)}
            placement='left'
          >
            <CircularProgress
              color='primary'
              size={24}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8
              }}
            />
          </Tooltip>
        )}

        {upload.status === Status.Failed && (
          <Button
            variant='outlined'
            size='small'
            color='error'
            startIcon={
              <SvgIcon>
                <RefreshCcw01 />
              </SvgIcon>
            }
            sx={{
              position: 'absolute',
              top: 8,
              right: 8
            }}
            onClick={handleRetryClick}
          >
            Retry
          </Button>
        )}

        <Box
          component={Image}
          src={src}
          sx={[
            {
              borderRadius: 1,
              display: 'block',
              cursor: 'pointer',
              objectFit: 'contain',
              paddingLeft: '0px',
              paddingRight: '0px',
              width: '100%'
            }
          ]}
          alt=''
          {...nodeProps}
        />

        <Caption align='left'>
          <CaptionTextarea
            placeholder='Write a caption...'
            readOnly={readOnly}
          />
        </Caption>
      </Box>

      {children}
    </PlateElement>
  )
})

export { ImageElement }
export default ImageElement
