import axios from 'axios'

export function useOsmSearch() {
  const searchOsm = async (
    queryText: string,
    bounds: [[number, number], [number, number]]
  ) => {
    const viewbox = `${bounds[0][0]},${bounds[1][1]},${bounds[1][0]},${bounds[0][1]}`
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
        queryText
      )}&viewbox=${viewbox}&bounded=1&addressdetails=1`
    )
    return response.data
  }
  return { searchOsm }
}
