import { LoadingButton } from '@mui/lab'
import { Box, Stack } from '@mui/material'
import PropTypes from 'prop-types'

const WorkspaceCreatorController = (props) => {
  const {
    next = false,
    copy = false,
    skip = false,
    disabledNext = false,
    disabledCopy = false,
    disabledSkip = false,
    loadingNext = false,
    onNextClick = () => {},
    onCopyClick = () => {},
    onSkipClick = () => {}
  } = props

  const handleCopyClick = () => {
    onCopyClick()
  }

  return (
    <Box
      component={Stack}
      mt={3}
      direction={{ xs: 'column', sm: 'row' }}
      gap={1}
    >
      {next && (
        <LoadingButton
          size='large'
          type='submit'
          loading={loadingNext}
          data-testid='NextButton'
          disabled={disabledNext}
          variant='contained'
          color='primary'
          onClick={onNextClick}
        >
          Next
        </LoadingButton>
      )}
      {copy && (
        <LoadingButton
          size='large'
          data-testid='CopyButton'
          disabled={disabledCopy}
          variant='outlined'
          color='primary'
          onClick={handleCopyClick}
        >
          Copy Invite Link
        </LoadingButton>
      )}
      {skip && (
        <LoadingButton
          size='large'
          data-testid='SkipButton'
          disabled={disabledSkip}
          variant='outlined'
          color='primary'
          onClick={onSkipClick}
        >
          Skip this step
        </LoadingButton>
      )}
    </Box>
  )
}

WorkspaceCreatorController.propTypes = {
  next: PropTypes.bool,
  copy: PropTypes.bool,
  skip: PropTypes.bool,
  disabledNext: PropTypes.bool,
  disabledCopy: PropTypes.bool,
  disabledSkip: PropTypes.bool,
  loadingNext: PropTypes.bool,
  onNextClick: PropTypes.func,
  onCopyClick: PropTypes.func,
  onSkipClick: PropTypes.func
}

export { WorkspaceCreatorController }
export default WorkspaceCreatorController
