import _ from 'lodash'
import { GEOJSON_ERRORS } from '../../assets/constants'

function mapResultErrors(errors, traceNumber) {
  return _.reduce(
    errors,
    function (result, arr) {
      const key = arr[0]
      const value = arr[1]
      const existingErrors = _.get(result, [key, 'errors'], [])
      if (_.size(existingErrors) >= traceNumber) {
        return result
      }
      existingErrors.push(value)
      result[key] = {
        ...GEOJSON_ERRORS[key],
        errors: existingErrors
      }
      return result
    },
    {}
  )
}

export { mapResultErrors }
