import { Box, useMediaQuery, useTheme } from '@mui/material'
import { SearchIcon } from '~ui-components/components/atoms/SearchIcon'
import { SearchInput } from '~ui-components/components/molecules/SearchInput'

const useResponsive = () => {
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up(758))
  const mobile = !desktop
  return { mobile, desktop }
}

const SearchBarInput = (props) => {
  const { disableMobileButton, onClick } = props
  const { mobile } = useResponsive()

  if (mobile && !disableMobileButton) return <SearchIcon onClick={onClick} />

  return (
    <Box
      sx={{ width: '100%' }}
      onClick={onClick}
    >
      <SearchInput
        cursor='pointer'
        autoFocus={false}
      />
    </Box>
  )
}

export { SearchBarInput }
