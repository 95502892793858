import React from 'react'
import { SelectDefaultView, SelectDefaultViewPropTypes } from 'ui-components'

const View = (props) => {
  return <SelectDefaultView {...props} />
}

View.propTypes = SelectDefaultViewPropTypes

export { View }
