import { createServerRunner } from '@aws-amplify/adapter-nextjs'
import { generateServerClientUsingReqRes } from '@aws-amplify/adapter-nextjs/api'
import { configure } from './configure'

const { runWithAmplifyServerContext } = createServerRunner({
  config: configure
})

const serverClient = generateServerClientUsingReqRes({
  config: configure
})

export { runWithAmplifyServerContext, serverClient }
