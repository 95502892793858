import { useEffect, useRef } from 'react'
import { useLayoutEffect } from '../useLayoutEffect'

const useTimeout = (configs, deps = []) => {
  const { callback, timeout } = configs
  const savedCallback = useRef(callback)
  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])
  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!timeout && timeout !== 0) {
      return
    }
    const id = setTimeout(() => savedCallback.current(), timeout)
    return () => clearTimeout(id)
  }, [...deps])
}

export { useTimeout }
