const typography = {
  h1: {
    fontSize: '44px',
    fontWeight: 'bold'
  },
  h2: {
    fontSize: '38px',
    fontWeight: 'bold'
  },
  h3: {
    fontSize: '32px',
    fontWeight: 'bold'
  },
  h4: {
    fontSize: '28px',
    fontWeight: 'bold'
  },
  h5: {
    fontSize: '24px',
    fontWeight: 'bold'
  },
  h6: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  subtitle1: {
    lineHeight: '24px',
    fontSize: '18px',
    fontWeight: 'normal'
  },
  subtitle2: {
    lineHeight: '24px',
    fontSize: '17px',
    fontWeight: 'normal'
  },
  body1: {
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 'normal'
  },
  body2: {
    lineHeight: '24px',
    fontSize: '15px',
    fontWeight: 'normal'
  },
  link: {
    lineHeight: '24px',
    fontSize: '15px',
    fontWeight: 'normal'
  },
  button: {
    lineHeight: '24px',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.8px'
  },
  caption: {
    lineHeight: '24px',
    fontSize: '12px',
    fontWeight: 'normal'
  }
}

export { typography }
