import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  replaceLastWizardStep,
  useCurrentWizardStep
} from '~map-creator/core/states/map'
import {
  updateDefaultVisualisationPropertyKey,
  useMapViewOptions
} from '~map-creator/core/states/properties'

export const useSkippableDefaultView = (isActivated = false) => {
  const dispatch = useDispatch()

  const currentWizardStep = useCurrentWizardStep()
  const mapViewOptions = useMapViewOptions()

  useEffect(() => {
    if (isActivated && mapViewOptions.length === 1) {
      const nextStep = currentWizardStep + 1
      dispatch(replaceLastWizardStep(nextStep))
      dispatch(updateDefaultVisualisationPropertyKey(mapViewOptions[0].value))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActivated])
}
