import { LayerProps } from 'react-map-gl/maplibre'
import { LAYER_FILL_HIGHLIGHT_COLORS } from '../../assets/Colors'
import { PROMOTED_COLUMN_ID } from '../../assets/Constants'

const getPointHighlightStyle = (filterArray: string[]): LayerProps => {
  filterArray = filterArray.filter((item) => item !== undefined)
  return {
    type: 'circle',
    paint: {
      'circle-radius': 10,
      'circle-color': LAYER_FILL_HIGHLIGHT_COLORS[0]
    },
    filter: ['in', ['get', PROMOTED_COLUMN_ID], ['literal', filterArray]]
  }
}

export { getPointHighlightStyle }
