import { useDispatch } from 'react-redux'
import { closeTable, openTable, useVisibility } from '~map-viewer/states/ui'
import { View } from './view'

const Table = (props) => {
  const dispatch = useDispatch()

  const visibility = useVisibility()

  return (
    <View
      {...props}
      onToggle={() => {
        dispatch(visibility.MapTable ? closeTable() : openTable())
      }}
      active={visibility.MapTable}
    />
  )
}

export { Table }
export default Table
