import { useFeatureIdentify } from 'map-feature-info'
import { memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIsDataLoading } from '~map-viewer/states/map'
import { openFeatureInfo as openFeatureInfoWindow } from '~map-viewer/states/ui'
import { useFeatureHover } from './handlers/featureHoverHandler'
import { useFilterSelectionSet } from './handlers/filterSelectionSetHandler'
import { useInitialMapStyleHandler } from './handlers/initialMapStyleHandler'
import { useResizeCanvas } from './handlers/resizeCanvasHandler'
import { useShareMapHandler } from './handlers/shareMapHandler'
import { useLayerSourcesStatus } from './handlers/useLayerSourcesStatus'
import { useSetCurrentBounds } from './handlers/useSetCurrentBounds'
import { useSetFitBounds } from './handlers/useSetFitBounds'
import { useViewState } from './handlers/viewStateHandler'
import { View } from './view.js'

/**
 * A React map component containing a base map, layers, and other map controls.
 */
const Map = memo(function Component(props) {
  const dispatch = useDispatch()
  const { shareState, checkIfDataExistLocally, visualisation, height } = props

  const [mapGl, setMapGl] = useState(null)

  useInitialMapStyleHandler(visualisation)
  useShareMapHandler(shareState)
  useFilterSelectionSet()
  useSetCurrentBounds(mapGl)
  useSetFitBounds(mapGl)

  const { identify } = useFeatureIdentify()
  const isDataLoading = useIsDataLoading()
  const { viewState } = useViewState(mapGl)

  useFeatureHover(mapGl)
  useResizeCanvas(mapGl, height)
  useLayerSourcesStatus(mapGl)

  const handleMapInstance = (mapInstance) => {
    if (!mapGl) setMapGl(mapInstance)
  }

  const handleClickFeature = (feature) => {
    if (!feature) return
    identify(feature)
    dispatch(openFeatureInfoWindow())
  }

  const handleMapClick = (event) => {
    const [feature] = event.features || []
    if (feature) {
      handleClickFeature(feature)
    }
  }

  return (
    <View
      viewState={viewState}
      loading={isDataLoading}
      onMapInstance={handleMapInstance}
      onClick={handleMapClick}
      checkIfDataExistLocally={checkIfDataExistLocally}
    />
  )
})

export { Map }
export default Map
