import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { DeleteMapDialog as Dialog } from 'ui-components'
import { getWorkspaceLink } from 'utils'
import { useDeleteMapByIdMutation } from '~map-viewer/services/map'
import { useMap } from '~map-viewer/states/map'

const DeleteMapDialog = (props) => {
  const router = useRouter()
  const map = useMap()

  const [trigger, { isLoading, isSuccess: deleteCompleted }] =
    useDeleteMapByIdMutation()

  const handleConfirm = () => {
    if (!map) return
    trigger(map.id)
  }

  useEffect(() => {
    if (deleteCompleted) {
      router.push(
        getWorkspaceLink({
          workspaceId: map.workspace.id,
          workspaceName: map.workspace.name
        }),
        null,
        {
          scroll: true
        }
      )
    }
  }, [deleteCompleted])

  return (
    <Dialog
      {...props}
      loading={isLoading}
      onConfirm={handleConfirm}
    />
  )
}

export { DeleteMapDialog }
export default DeleteMapDialog
