import { Button, SvgIcon } from '@mui/material'
import Heart from '@untitled-ui/icons-react/build/esm/Heart'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import UnauthenticatedActionTooltip from '~ui-components/components/molecules/UnauthenticatedActionTooltip'

const getLikesCopy = (likes) => {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1
  }).format(likes)
}

const LikeButton = (props) => {
  const {
    authenticated = false,
    likes: initialLikes = 0,
    liked: initialLiked = false,
    onLike = () => {},
    onUnlike = () => {},
    onSignInClick = () => {},
    ...rest
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [likes, setLikes] = useState(initialLikes)
  const [liked, setLiked] = useState(initialLiked)

  useEffect(() => {
    setLikes(initialLikes)
  }, [initialLikes])

  useEffect(() => {
    setLiked(initialLiked)
  }, [initialLiked])

  const handleClick = (event) => {
    if (!authenticated) {
      setAnchorEl(event.currentTarget)
      return
    }

    if (!liked) {
      onLike()
      setLikes(likes + 1)
    } else {
      onUnlike()
      setLikes(likes - 1)
    }
    const nextValue = !liked
    setLiked(nextValue)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <UnauthenticatedActionTooltip
        primary='Like this map?'
        secondary='Sign in to make your opinion count.'
        anchorEl={anchorEl}
        onClose={handleClose}
        onSignInClick={onSignInClick}
      />

      <Button
        {...rest}
        data-testid='LikeButton'
        variant={liked ? 'contained' : 'outlined'}
        color='error'
        startIcon={
          <SvgIcon sx={{ fontSize: '1.25em !important' }}>
            <Heart />
          </SvgIcon>
        }
        onClick={handleClick}
      >
        {getLikesCopy(likes)}
      </Button>
    </>
  )
}

export { LikeButton }
export default LikeButton

LikeButton.propTypes = {
  authenticated: PropTypes.bool,
  likes: PropTypes.number,
  liked: PropTypes.bool,
  onLike: PropTypes.func,
  onUnlike: PropTypes.func,
  onSignInClick: PropTypes.func
}
