import { Divider, Stack, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { Sidebar } from '~ui-components/components/molecules/Sidebar'

type Tab = 'map' | 'description'

const Empty = () => null
const Children = ({ children }) => <>{children}</>

const defaultSlots = {
  Map: Empty,
  Description: Empty
}

function MapDataSettingsDrawer(props) {
  const { open, onClose, slots, slotProps } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const [tab, setTab] = useState<Tab>('map')

  return (
    <Sidebar
      anchor='left'
      open={open}
      title='Settings'
      onClose={onClose}
      ModalProps={{
        disablePortal: true,
        hideBackdrop: true,
        sx: {
          top: 'unset',
          right: 'unset',
          bottom: 'unset',
          left: 'unset'
        }
      }}
    >
      <Tabs
        sx={{ mx: 2 }}
        indicatorColor='primary'
        scrollButtons='auto'
        color='secondary'
        textColor='secondary'
        variant='scrollable'
        value={tab}
        onChange={(event, value) => setTab(value)}
      >
        <Tab
          label='Map'
          value='map'
        />

        <Tab
          label='Description'
          value='description'
        />
      </Tabs>

      <Divider orientation='horizontal' />

      <Stack
        flex={1}
        spacing={3}
        p={2}
        direction='column'
      >
        {tab === 'map' && <Slots.Map />}
        {tab === 'description' && <Slots.Description />}
      </Stack>
    </Sidebar>
  )
}

export { MapDataSettingsDrawer }
