import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { Workspace } from '~ui-components/types/__generated/gql/graphql'

export type WorkspaceSelectInputProps = Omit<
  React.ComponentProps<typeof Autocomplete<Partial<Workspace>, false, true>>,
  'renderInput'
> & { hidden?: boolean }

function WorkspaceSaveToInput(props: WorkspaceSelectInputProps): JSX.Element {
  const { hidden = false, value, options = [], onChange, ...rest } = props

  return (
    <Stack
      spacing={1}
      sx={[
        hidden && {
          display: 'none'
        }
      ]}
    >
      <Typography variant='subtitle2'>Save To</Typography>
      <Autocomplete
        {...rest}
        disablePortal
        disableClearable
        getOptionLabel={(option) => option.name as string}
        value={value}
        options={options}
        onChange={onChange}
        renderInput={(params): JSX.Element => (
          <TextField
            {...params}
            fullWidth
            label={isEmpty(options) ? 'No workspaces' : 'Workspace'}
            name='workspace'
          />
        )}
      />
    </Stack>
  )
}

export { WorkspaceSaveToInput }
