import { AddMapAction as View } from 'ui-components'
import { useAuth, useAuthWorkspaces } from 'user-auth'

const AddMapAction = (props) => {
  const { authenticated, initialized, loading } = useAuth()
  const workspaces = useAuthWorkspaces()

  const isLoading = !initialized || loading

  return (
    <View
      {...props}
      authenticated={authenticated}
      loading={isLoading}
      slotProps={{
        AddMapDialog: {
          workspaces
        }
      }}
    />
  )
}

export { AddMapAction }
