import { Box } from '@mui/material'
import { PlateElement } from '@udecode/plate-common'
import clsx from 'clsx'
import { forwardRef } from 'react'
import { useFocused, useSelected } from 'slate-react'
import Hr from '~ui-components/components/molecules/TextEditor/components/HTML/Hr'

import ringEffect from '~ui-components/utilities/ringEffect'

const HrElement = forwardRef((props, ref) => {
  const { children } = props
  const { className, nodeProps, ...rest } = props

  const selected = useSelected()
  const focused = useFocused()

  return (
    <PlateElement
      ref={ref}
      {...rest}
    >
      <Box contentEditable={false}>
        <Hr
          {...nodeProps}
          className={clsx(className)}
          sx={[
            (theme) =>
              selected &&
              focused && {
                ...ringEffect({
                  ringOffsetWidth: '2px',
                  ringOffsetColor: theme.palette.background.paper,
                  ringWidth: '2px',
                  ringColor: theme.palette.primary.main
                })
              }
          ]}
        />
      </Box>
      {children}
    </PlateElement>
  )
})

HrElement.displayName = 'HrElement'

export { HrElement }
export default HrElement
