import React from 'react'

import { MapCursor } from 'ui-components'

const View = (props) => {
  return <MapCursor {...props} />
}

export { View }
export default View
