import { Box, Button, Link, Stack, SvgIcon, Typography } from '@mui/material'
import ArrowRight from '@untitled-ui/icons-react/build/esm/ArrowRight'
import NextImage from 'next/legacy/image'
import NextLink from 'next/link'
import PropTypes from 'prop-types'
import { createWorkspace as createWorkspaceImage } from '~ui-components/assets/images'

const WorkspaceGetStarted = (props) => {
  const { onCreateWorkspaceClick, ...rest } = props
  return (
    <Stack
      direction='row'
      data-testid='WorkspaceGetStarted'
      sx={(theme) => ({
        [theme.breakpoints.down(715)]: {
          flexDirection: 'column-reverse',
          '.CreateWorkspaceCopy': {
            maxWidth: '100%'
          },
          '.CreateWorkspaceButton': {
            width: '100%'
          }
        }
      })}
      {...rest}
    >
      <Stack
        flex={1}
        direction='column'
        gap={0}
      >
        <Typography
          variant='h2'
          mb={2}
        >
          Create a mapstack workspace
        </Typography>
        <Typography
          className='CreateWorkspaceCopy'
          variant='subtitle1'
          component='p'
          maxWidth='70%'
          fontWeight={500}
          textAlign='justify'
          mb={2}
        >
          <b>mapstack</b> gives your maps a home — a place where maps can be
          grouped under a project or theme. To create a new workspace, click the
          button below.
        </Typography>
        <Button
          className='CreateWorkspaceButton'
          data-testid='CreateWorkspaceButton'
          sx={{
            width: '300px'
          }}
          variant='contained'
          size='large'
          endIcon={
            <SvgIcon>
              <ArrowRight />
            </SvgIcon>
          }
          onClick={onCreateWorkspaceClick}
        >
          Create a new workspace
        </Button>
        <Typography
          variant='subtitle1'
          component='p'
          mt={2}
        >
          By continuing, you&apos;re agreeing to our{' '}
          <Link
            component={NextLink}
            href='/terms'
            variant='inherit'
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            component={NextLink}
            href='/privacy'
            variant='inherit'
          >
            Privacy Policy
          </Link>
          .
        </Typography>
      </Stack>
      <Stack>
        <Box
          mt={2}
          width='256px'
          height='256ppx'
        >
          <NextImage
            src={createWorkspaceImage}
            layout='responsive'
            width='100%'
            height='100%'
          />
        </Box>
      </Stack>
    </Stack>
  )
}

WorkspaceGetStarted.propTypes = {
  onCreateWorkspaceClick: PropTypes.func
}

export { WorkspaceGetStarted }
export default WorkspaceGetStarted
