import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { View } from './view'

const MapCursor = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(768))
  if (!isMobile) return null

  return <View />
}

export { MapCursor }
export default MapCursor
