import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { MapstackLoadingBackdrop } from 'ui-components'
import { getSearchLink } from 'utils'

import { useAuth } from '~user-auth/contexts/auth'
import { postSignInRedirect } from '~user-auth/storages/postSignInRedirect'

class UndefinedRedirect extends Error {
  constructor(params?) {
    super(params)
    this.name = 'UndefinedRedirect'
    this.message = 'Could not determine where to redirect user'
  }
}

function AuthCallback() {
  const router = useRouter()
  const { ready } = useAuth()

  const gotoRedirect = () => {
    const redirect = postSignInRedirect.get()
    postSignInRedirect.remove()

    if (!redirect) throw new UndefinedRedirect()

    router.replace(redirect, undefined, { shallow: true })
  }

  const gotoSearch = () => {
    router.replace(getSearchLink(), undefined, { shallow: true })
  }

  useEffect(() => {
    if (!ready) return
    try {
      gotoRedirect()
    } catch (error) {
      gotoSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready])

  return <MapstackLoadingBackdrop text='Redirecting...' />
}

export { AuthCallback }
