import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { map, toNumber } from 'lodash'
import { VISUALISATION } from 'utils'

const initialState = {
  value: '',
  type: '',
  classBreaks: []
}

export const visualisationSlice = createSlice({
  name: 'visualisation',
  initialState,
  reducers: {
    setVisualisation: (state, action) => {
      const { value, type, classBreaks } = action.payload

      // INFO: Class breaks are stored as array of strings in the database
      // so we need to convert them to numbers for MapGL choropleth style
      const mappedClassBreaks =
        type === VISUALISATION.QUANTITY
          ? map(classBreaks, toNumber)
          : classBreaks

      state.value = value
      state.type = type
      state.classBreaks = mappedClassBreaks
    },
    reset: () => initialState
  }
})

export const { setVisualisation, reset } = visualisationSlice.actions

export const useVisualisation = () =>
  useSelector((state) => state.visualisation)

export const useVisualisationValue = () =>
  useSelector((state) => state.visualisation.value)

export default visualisationSlice.reducer
