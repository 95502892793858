import { Box, alpha, colors } from '@mui/material'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { getSuffix } from '~ui-components/utilities/getSuffix'

import MapVisualisationColorRamp from '../MapVisualisationColorRamp'

const Component = (props) => {
  const { variant, property, jenks = [], ramps = [], visualisationType } = props

  return (
    <Box
      component='div'
      data-testid='MapVisualisation-ColorRamps'
      sx={[
        (theme) => ({
          mt: 2,
          mb: 3,
          width: `100%`,
          height: '1.25rem',
          position: 'relative',
          backgroundColor: alpha(colors.grey[800], 0.2),
          display: 'flex',
          [theme.breakpoints.down('mobile')]: {
            mt: 1
          }
        })
      ]}
    >
      {jenks?.map((jenk, index, collection) => {
        return (
          <MapVisualisationColorRamp
            variant={variant}
            key={jenk}
            index={index}
            length={collection.length}
            suffix={getSuffix(property)}
            color={ramps[index]}
            value={jenk}
            visualisationType={visualisationType}
          />
        )
      })}
    </Box>
  )
}

Component.displayName = 'MapVisualisationColorRamps'

Component.propTypes = {
  variant: PropTypes.string,
  hidden: PropTypes.bool,
  property: PropTypes.object,
  jenks: PropTypes.array,
  ramps: PropTypes.array,
  visualisationType: PropTypes.string
}

const MapVisualisationColorRamps = memo(Component)

export { MapVisualisationColorRamps }
export default MapVisualisationColorRamps
