import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Mail01 } from '@untitled-ui/icons-react/build/esm'

const MembersTabTools = ({
  canInviteMember = false,
  form,
  onCopyInviteLinkClick
}) => {
  if (!canInviteMember) return null

  return (
    <Box
      data-testid='MembersTabTools'
      sx={{ px: 3, mt: 3 }}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          xs={12}
          md={4}
        >
          <Stack spacing={1}>
            <Typography variant='h6'>Invite members</Typography>
            <Typography
              color='text.secondary'
              variant='body2'
            >
              Members can view, create, edit and delete maps.
            </Typography>
          </Stack>
        </Grid>

        <Grid
          xs={12}
          md={8}
        >
          <Stack
            component='form'
            onSubmit={form.handleSubmit}
            alignItems='center'
            direction='row'
            flexWrap='wrap'
            gap={3}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SvgIcon>
                      <Mail01 />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              label='Email address'
              name='email'
              sx={{ flexGrow: 1 }}
              type='email'
              value={form.values.email}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={form.touched.email && Boolean(form.errors.email)}
              helperText={form.touched.email && form.errors.email}
            />
            <LoadingButton
              type='submit'
              variant='contained'
              loading={form.isSubmitting}
              data-testid='InviteButton'
              disabled={!form.isValid || form.isSubmitting}
            >
              Send Invite
            </LoadingButton>
            <Button
              variant='outlined'
              data-testid='InviteButton'
              onClick={onCopyInviteLinkClick}
            >
              Copy invite link
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export { MembersTabTools }
