import Dotpoints02 from '@untitled-ui/icons-react/build/esm/Dotpoints02'
import FloatingButton from '~ui-components/components/molecules/FloatingButton'

export const MapActionLegend = (props) => {
  const { onClick, active } = props
  return (
    <FloatingButton
      data-testid='MapActionLegend'
      title='Legend'
      label='Legend'
      icon={<Dotpoints02 />}
      color={active ? 'secondary' : 'inherit'}
      onClick={onClick}
    />
  )
}
