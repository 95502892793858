import { formatFeaturePropertyValue } from 'utils'

const DEFAULT_IGNORES = ['msuuid']

const isNotIgnore = (property) => {
  return !DEFAULT_IGNORES.includes(property.name)
}

const isNotTitle = (title) => (property) => {
  return property.name !== title
}

const getProperties = (properties, title) => {
  if (typeof properties === 'undefined') return []

  return properties
    .filter(isNotIgnore)
    .filter(isNotTitle(title))
    .map((property) => {
      const value = formatFeaturePropertyValue(property)
      return { name: property.name, value }
    })
    .sort((a, b) => a.name.localeCompare(b.name, 'en-US'))
}

export { getProperties }
export default getProperties
