import { baseUrl } from 'utils'

import { mapstack32pxPng } from '~ui-components/assets/emails/logos'
import {
  roundedFacebookPng,
  roundedLinkedinPng,
  roundedTwitterPng,
  roundedYoutubePng
} from '~ui-components/assets/emails/socials'
import {
  Box,
  Column,
  Image,
  Item,
  Link,
  Row,
  Typography
} from '~ui-components/emails/components/atoms'
import { assetUrlOf } from '~ui-components/emails/helpers/assetUrlOf'

const Footer = () => {
  const socials = [
    {
      name: 'Youtube',
      url: 'https://www.youtube.com/channel/UCZE-1UGZKZITlg7D49eMMqA',
      icon: assetUrlOf(roundedYoutubePng)
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/mapstack_io',
      icon: assetUrlOf(roundedTwitterPng)
    },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/mapstack.io',
      icon: assetUrlOf(roundedFacebookPng)
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/company/mapstack/',
      icon: assetUrlOf(roundedLinkedinPng)
    }
  ]

  return (
    <Box fullWidth>
      <Row>
        <Column align='left'>
          <Image
            src={assetUrlOf(mapstack32pxPng)}
            alt='mapstack'
            width='auto'
            height={32}
          />
        </Column>
        <Column align='right'>
          {socials.map((social, index) => {
            return (
              <Link
                key={index}
                href={social.url}
              >
                <Image
                  src={social.icon}
                  alt={social.name}
                  width={30}
                  height={30}
                  style={{ marginLeft: '12px' }}
                />
              </Link>
            )
          })}
        </Column>
      </Row>
      <Item pt={16}>
        <Typography variant='caption'>
          @2022 mapstack 1 Victoria Street, Bristol, England, BS1 6AA, UK
        </Typography>
      </Item>
      <Item pt={4}>
        <Link
          href={`${baseUrl()}/privacy`}
          variant='caption'
          underline='none'
        >
          Privacy
        </Link>
        <Typography variant='caption'>&nbsp;&nbsp;|&nbsp;&nbsp;</Typography>
        <Link
          href={`${baseUrl()}/terms`}
          variant='caption'
          underline='none'
        >
          Terms
        </Link>
      </Item>
    </Box>
  )
}

Footer.propTypes = {}

export { Footer }
export default Footer
