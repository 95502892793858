import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import Markdown from 'markdown-to-jsx'

import { Code } from '../../code'

const components = {
  code: Code
}

const PostContentRoot = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  '& code': {
    color: theme.palette.primary.main,
    fontFamily:
      "Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace",
    fontSize: 14,
    paddingLeft: 2,
    paddingRight: 2
  },
  '& img': {
    maxWidth: '100%'
  },
  '& h2': {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.h5.lineHeight,
    marginBottom: theme.spacing(3)
  },
  '& h3': {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.h3.lineHeight,
    marginBottom: theme.spacing(3)
  },
  '& p': {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    marginBottom: theme.spacing(2)
  },
  '& li': {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    marginBottom: theme.spacing(1)
  }
}))

interface PostContentProps {
  content: string
}

export const PostContent: FC<PostContentProps> = (props) => {
  const { content } = props

  return (
    <PostContentRoot>
      <Markdown
        components={components}
        // rehypePlugins={[rehypeRaw]}
      >
        {content}
      </Markdown>
    </PostContentRoot>
  )
}

PostContent.propTypes = {
  content: PropTypes.string.isRequired
}
