import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { MapstackLoadingBackdrop, Seo } from 'ui-components'
import { getSignInLink } from 'utils'

import { postSignOutRedirect } from '~user-auth/storages/postSignOutRedirect'

const SignOut = () => {
  const router = useRouter()

  useEffect(() => {
    const redirect = postSignOutRedirect.get()
    router.push(redirect || getSignInLink()).then(() => {
      postSignOutRedirect.remove()
    })
  }, [])

  return (
    <>
      <Seo title='Signing out' />
      <MapstackLoadingBackdrop text='Redirecting...' />
    </>
  )
}

export { SignOut }
export default SignOut
