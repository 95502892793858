const List = () => (
  <svg
    data-v-e087307f=''
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <line
      x1='8'
      x2='21'
      y1='6'
      y2='6'
    ></line>
    <line
      x1='8'
      x2='21'
      y1='12'
      y2='12'
    ></line>
    <line
      x1='8'
      x2='21'
      y1='18'
      y2='18'
    ></line>
    <line
      x1='3'
      x2='3.01'
      y1='6'
      y2='6'
    ></line>
    <line
      x1='3'
      x2='3.01'
      y1='12'
      y2='12'
    ></line>
    <line
      x1='3'
      x2='3.01'
      y1='18'
      y2='18'
    ></line>
  </svg>
)

export default List
