/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Badge,
  BadgeProps,
  Box,
  Fab,
  Stack,
  StackProps,
  SvgIcon,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses
} from '@mui/material'
import { TypographyProps } from '@mui/system'
import { LetterSpacing01 } from '@untitled-ui/icons-react'
import { isEmpty } from 'lodash'
import { FC, ReactNode, useEffect, useState } from 'react'
import important from '~ui-components/utilities/important'

const popperSx = (theme) => ({
  [`& .${tooltipClasses.tooltipPlacementLeft}`]: {
    mr: important(theme.spacing(1))
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    ml: important(theme.spacing(1))
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    mt: important(theme.spacing(1))
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    mb: important(theme.spacing(1))
  }
})

const directions = {
  row: {
    stack: {
      direction: 'row',
      alignItems: 'center'
    },
    typography: {
      ml: 4
    }
  },
  column: {
    tooltip: {},
    stack: {
      direction: 'column',
      alignItems: 'center'
    },
    typography: {
      mt: 1
    }
  }
}

export type FloatingButtonProps = StackProps & {
  sx?: any
  tooltip?: boolean
  badge?: boolean
  open?: boolean
  size?: 'small' | 'medium' | 'large'
  direction?: 'column' | 'row'
  icon?: ReactNode
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'inherit'
  title?: string
  label?: string
  tooltipProps?: TooltipProps
  labelProps?: TypographyProps
  badgeProps?: BadgeProps
}
const FloatingButton: FC<FloatingButtonProps> = (props) => {
  const {
    sx = {},
    tooltip = true,
    badge = false,
    open: initialOpen = false,
    size = 'small',
    direction = 'column',
    icon = <LetterSpacing01 />,
    color = 'primary',
    title,
    label,
    tooltipProps = {},
    labelProps = {},
    badgeProps = {},
    ...rest
  } = props

  const [open, setOpen] = useState(initialOpen)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    setOpen(initialOpen)
  }, [initialOpen])

  let floatingButton = (
    <Fab
      className='FloatingButton-Fab'
      size={size}
      color={color}
      sx={[
        {
          zIndex: 0,
          boxShadow: (theme) => theme.shadows[1]
        },

        color === 'inherit' && {
          backgroundColor: (theme) => theme.palette.common.white
        }
      ]}
    >
      <SvgIcon>{icon}</SvgIcon>
    </Fab>
  )

  floatingButton = badge ? (
    <Badge
      {...badgeProps}
      overlap='circular'
    >
      {floatingButton}
    </Badge>
  ) : (
    floatingButton
  )

  return (
    <Stack
      data-testid='FloatingButton'
      className='FloatingButton'
      {...rest}
      {...directions[direction].stack}
      sx={[
        {
          '& .FloatingButton-Label': {
            color: 'text.primary',
            textShadow: (theme) => theme.shadows[3]
          },
          '&:hover .FloatingButton-Label': {
            fontWeight: 'bold'
          }
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {tooltip ? (
        <Tooltip
          open={open}
          title={title}
          placement='left'
          PopperProps={{
            sx: [popperSx]
          }}
          onOpen={handleOpen}
          onClose={handleClose}
          {...tooltipProps}
        >
          {floatingButton}
        </Tooltip>
      ) : (
        floatingButton
      )}

      {!isEmpty(label) ? (
        <Box
          {...directions[direction].typography}
          data-testid='FloatingButton-Label'
          className='FloatingButton-Label'
          component={Typography}
          variantMapping={{
            caption: 'div'
          }}
          sx={[
            {
              fontSize: 12,
              fontWeight: 'semibold',
              userSelect: 'none',
              cursor: 'default',
              textAlign: 'center'
            }
          ]}
          {...labelProps}
        >
          {label}
        </Box>
      ) : null}
    </Stack>
  )
}

export { FloatingButton }
export default FloatingButton
