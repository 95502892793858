import {
  CreateAugmentedCsvMutation,
  CreateAugmentedCsvMutationVariables
} from '~map-creator/types/__generated/gql/graphql'
import { graphierQL } from 'utils'

const createAugmentedCSV = async ({ dataId, boundaryDataFileName }) => {
  const { createAugmentedCSV } = await graphierQL<
    CreateAugmentedCsvMutation,
    CreateAugmentedCsvMutationVariables
  >({
    useToken: true,
    query: createAugmentedCSVMutation,
    variables: {
      input: {
        dataId,
        boundaryDataFileName
      }
    }
  })

  return createAugmentedCSV
}

const createAugmentedCSVMutation = /* GraphQL */ `
  mutation CreateAugmentedCSV($input: CsvAugmentationInput!) {
    createAugmentedCSV(input: $input) {
      missRows
      dataId
      fileName
      fileUrl
      titleColumnName
    }
  }
`

export { createAugmentedCSV, createAugmentedCSVMutation }
export default createAugmentedCSV
