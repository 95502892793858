import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  layerSources: [],
  areSourcesLoaded: false
}

export const layerSlice = createSlice({
  name: 'layer',
  initialState,
  reducers: {
    addLayerSource: (state, action) => {
      const nextLayerSources = state.layerSources
      nextLayerSources.push(action.payload)
      state.layerSources = Array.from(new Set(nextLayerSources))
    },
    setAreLayerSourcesLoaded: (state, action) => {
      state.areSourcesLoaded = action.payload
    },
    reset: () => initialState
  }
})

const layerSources = (state) => state.layer.layerSources
export const { addLayerSource, setAreLayerSourcesLoaded, reset } =
  layerSlice.actions

export const useLayerSources = () => useSelector(layerSources)
export const useAreLayerSourcesLoaded = () =>
  useSelector((state) => state.layer.areSourcesLoaded)

export default layerSlice.reducer
