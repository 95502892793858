import { ELEMENT_IMAGE, insertNodes } from '@udecode/plate'
import { getNanoId } from 'utils'
import { Status } from '~ui-components/utilities/getReadmeAssetsUrl'

const missingCreateUploadFile = `
Can't upload images without createFileUpload.
Must pass createFileUpload to CloudUploadPlugin.options.
`.trim()

const uploadImage = async (editor, file) => {
  const { createFileUpload } = editor.cloud

  if (!createFileUpload) {
    console.error(missingCreateUploadFile)
    return
  }

  const id = getNanoId()
  const image = {
    uid: id,
    path: URL.createObjectURL(file),
    type: ELEMENT_IMAGE,
    children: [{ text: '' }]
  }

  insertNodes(editor, image)

  editor.cloud.uploadStore.set.upload(id, {
    file,
    path: image.path,
    progress: 0,
    status: Status.Uploading
  })

  try {
    const fileUpload = createFileUpload(file)

    editor.cloud.uploadEvents.set(id, {
      abort: fileUpload.abort
    })

    const asset = await fileUpload.upload({
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )

        editor.cloud.uploadStore.set.upload(id, { progress })
      }
    })

    editor.cloud.uploadStore.set.upload(id, {
      path: asset.path,
      status: Status.Uploaded
    })
  } catch (error) {
    console.warn(error)

    editor.cloud.uploadStore.set.upload(id, {
      status: Status.Failed
    })
  }
}

export { uploadImage }
