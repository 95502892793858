import { CircularProgress, Stack } from '@mui/material'
import { GeocoderHeader } from '~ui-components/components/molecules/GeocoderHeader'
import { GeocoderList } from '~ui-components/components/molecules/GeocoderList'
import { LoginForm } from '~ui-components/components/templates/Auth/LoginForm'

const defaultSlots = {
  GeocoderHeader,
  GeocoderList,
  LoginForm
}

interface GeocoderAsideProps {
  variant: 'auth' | 'geocode' | 'loading'
  slots?: {
    GeocoderHeader?: typeof GeocoderHeader
    GeocoderList?: typeof GeocoderList
    LoginForm?: typeof LoginForm
  }
  slotProps?: {
    GeocoderHeader?: any
    GeocoderList?: any
    LoginForm?: any
  }
}

function GeocoderAside(props: GeocoderAsideProps) {
  const { variant, slots, slotProps } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <>
      {variant === 'loading' && (
        <Stack
          sx={{ p: 3, height: '100%' }}
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress />
        </Stack>
      )}

      {variant === 'auth' && (
        <Stack
          sx={{ p: 3, height: '100%' }}
          justifyContent='center'
        >
          <Stack
            spacing={3}
            minHeight='540px'
          >
            <Slots.LoginForm />
          </Stack>
        </Stack>
      )}

      {variant === 'geocode' && (
        <Stack
          sx={{ height: '100%', maxHeight: '100dvh', overflow: 'hidden' }}
          spacing={3}
          direction='column'
        >
          <Slots.GeocoderHeader
            {...slotProps?.GeocoderHeader}
            sx={{ pt: 3, px: 3 }}
          />
          <Slots.GeocoderList
            {...slotProps?.GeocoderList}
            sx={{ pt: 0, px: 3, pb: 3 }}
          />
        </Stack>
      )}
    </>
  )
}

export { GeocoderAside }
