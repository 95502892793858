import PropTypes from 'prop-types'
import { typography } from '~ui-components/emails/theme/typography'

const variants = {
  contained: {
    primary: {
      color: '#ffffff',
      backgroundColor: '#17ab6a',
      border: `solid 1px #17ab6a`
    }
  }
}

const sizes = {
  small: {
    fontSize: 12,
    width: 192,
    paddingTop: 12,
    paddingBottom: 12
  },
  medium: {
    fontSize: 14,
    width: 256,
    paddingTop: 14,
    paddingBottom: 14
  },
  large: {
    fontSize: 16,
    width: 392,
    paddingTop: 16,
    paddingBottom: 16
  }
}

const borderRadiusMap = {
  squared: {},
  rounded: {
    borderRadius: 4
  },
  pilled: {
    borderRadius: 99
  }
}

const Button = (props) => {
  const {
    href,
    variant = 'contained',
    color = 'primary',
    size = 'medium',
    borderRadius = 'rounded',
    style,
    children,
    ...rest
  } = props
  return (
    <a
      {...rest}
      className={`Button-${color}`}
      href={href}
      target='_blank'
      rel='noreferrer'
      style={{
        ...variants[variant][color],
        ...typography.button,
        ...sizes[size],
        ...borderRadiusMap[borderRadius],
        textDecoration: 'none',
        textAlign: 'center',
        display: 'inline-block',
        ...style
      }}
    >
      {children}
    </a>
  )
}

export { Button }
export default Button

Button.propTypes = {
  href: PropTypes.string,
  variant: PropTypes.oneOf(['contained']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning'
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  borderRadius: PropTypes.oneOf(['squared', 'rounded', 'pilled']),
  style: PropTypes.object,
  children: PropTypes.node
}
