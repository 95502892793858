import { remixMap } from '~map-creator/core/api'
import { submitCreateMap } from '../submitCreateMap'

const submitRemixMap = async (geojson, parentMap, childMap) => {
  await submitCreateMap(
    {
      geojson,
      dataId: childMap.dataId
    },
    childMap
  )
  await remixMap(parentMap.id, childMap.id, childMap.workspaceId)
}

export { submitRemixMap }
