import { SvgIcon } from '@mui/material'
import PropTypes from 'prop-types'

const sizes = {
  xs: {
    fontSize: '18px'
  },
  sm: {
    fontSize: '22px'
  },
  md: {
    fontSize: '26px'
  },
  lg: {
    fontSize: '32px'
  },
  xl: {
    fontSize: '40px'
  },
  '2xl': {
    fontSize: '64px'
  }
}

const getSize = (size) => sizes[size] || {}

const MapstackLogo = (props) => {
  const { sx = {}, size = 'medium', ...rest } = props
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 80 80'
      sx={[
        {
          display: 'block'
        },
        getSize(size),
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...rest}
    >
      <path
        d='M39.9682 79.9712C38.6954 79.9712 37.4232 79.6551 36.2629 79.0223L1.99503 60.3273C0.131104 59.3105 -0.547825 56.9891 0.478603 55.1426C1.50539 53.2955 3.84861 52.6244 5.71146 53.6401L37.9486 71.2273C39.2125 71.9166 40.7447 71.9166 42.0082 71.2269L74.2258 53.6401C76.0893 52.6237 78.4325 53.2962 79.4586 55.1426C80.4847 56.9891 79.8061 59.3105 77.9422 60.3273L43.675 79.0219C42.515 79.6548 41.2418 79.9712 39.969 79.9712H39.9682Z'
        fill='#F0B500'
      />
      <path
        d='M39.9682 63.2959C38.6954 63.2959 37.4232 62.9799 36.2629 62.347L1.99503 43.652C0.131104 42.6352 -0.547825 40.3138 0.478603 38.4673C1.50539 36.6202 3.84861 35.9491 5.71146 36.9648L37.9486 54.552C39.2125 55.2413 40.7447 55.2413 42.0082 54.5516L74.2258 36.9648C76.0893 35.9484 78.4325 36.6209 79.4586 38.4673C80.4847 40.3138 79.8061 42.6352 77.9422 43.652L43.675 62.3466C42.515 62.9795 41.2418 63.2959 39.969 63.2959H39.9682Z'
        fill='#39A6F3'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M77.8905 19.6077L43.6555 0.930536C41.3812 -0.310179 38.6241 -0.310179 36.3501 0.930536L2.11514 19.6077C-0.659148 21.1213 -0.659148 25.0709 2.11514 26.5845L37.9734 46.147C39.2369 46.8362 40.7684 46.8362 42.0319 46.147L77.8901 26.5845C80.6644 25.0709 80.6644 21.1213 77.8901 19.6077H77.8905Z'
        fill='#17AB6A'
      />
    </SvgIcon>
  )
}

MapstackLogo.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl'])
}

export { MapstackLogo }
export default MapstackLogo
