import SearchSm from '@untitled-ui/icons-react/build/esm/SearchSm'
import FloatingButton from '~ui-components/components/molecules/FloatingButton'

const MapActionSearch = ({ onClick }) => {
  return (
    <FloatingButton
      data-testid='MapActionSearch'
      title='Search'
      label='Search'
      icon={<SearchSm />}
      color='inherit'
      onClick={onClick}
    />
  )
}

export { MapActionSearch }
export default MapActionSearch
