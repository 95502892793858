import * as qs from 'qs'
import { slug } from '~utils/functions/slug'

enum WORKSPACE_TAB {
  MAPS = 'maps',
  MEMBERS = 'members',
  SETTINGS = 'settings'
}

const noPageTab = (tab) =>
  [WORKSPACE_TAB.MEMBERS, WORKSPACE_TAB.SETTINGS].includes(tab)

const getWorkspaceLink = ({
  workspaceId,
  workspaceName,
  tab,
  page
}: {
  workspaceId: string
  workspaceName: string
  tab?: string
  page?: number
}) => {
  const objectParams: Record<string, unknown> = {}

  if (tab) objectParams.tab = tab
  if (page) objectParams.page = page
  if (noPageTab(tab)) delete objectParams.page

  const query = qs.stringify(objectParams)

  return (
    `/workspace/${workspaceId}/${slug(workspaceName)}` +
    (query ? `?${query}` : '')
  )
}

export { getWorkspaceLink }
