import { Alert, Link } from '@mui/material'
import NextLink from 'next/link'

const unsupportedTypesDoc =
  'https://www.notion.so/mapstack/Supported-data-types-295ca26b551048f3ab4fed02c6d50296'

function MapUnsupportedTypesAlert() {
  return (
    <Alert
      variant='outlined'
      severity='warning'
      sx={{
        alignItems: 'center'
      }}
    >
      We couldn't make a map from this file (
      <Link
        component={NextLink}
        variant='inherit'
        underline='always'
        color='secondary'
        href={unsupportedTypesDoc}
      >
        supported file types
      </Link>
      ).
    </Alert>
  )
}

export { MapUnsupportedTypesAlert }
