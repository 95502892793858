import PropTypes from 'prop-types'
import Map from 'react-map-gl/maplibre'
import { usePluginStore } from 'react-pluggable'
import { lightMap } from '~map-creator/core/assets'
import { POPUP_SELECTION_LAYER_ID } from '~map-creator/core/assets/constants'
import SourceLayers from './SourceLayers'

const View = ({ onMapInstance, onMapClick, dragEnabled }) => {
  const pluginStore = usePluginStore()
  const Renderer = pluginStore.executeFunction('Renderer.getRendererComponent')

  return (
    <Map
      reuseMaps
      ref={(ref) => ref && onMapInstance(ref.getMap())}
      interactiveLayerIds={[POPUP_SELECTION_LAYER_ID]}
      mapStyle={lightMap}
      onClick={onMapClick}
      dragPan={dragEnabled}
    >
      <Renderer placement='visualisations' />
      <Renderer placement='gestureInstruction' />
      <Renderer placement='customNavigationControl' />
      <Renderer placement='popup' />
      <SourceLayers />
    </Map>
  )
}

View.propTypes = {
  onMapInstance: PropTypes.func,
  onMapClick: PropTypes.func,
  dragEnabled: PropTypes.bool
}

export { View }
