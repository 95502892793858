import { useState } from 'react'
import { searchTags } from '~map-creator/core/api'

const useSearchFeatureTypes = () => {
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])

  const search = async (keyword) => {
    try {
      setLoading(true)

      const tags = await searchTags({
        from: 0,
        size: 10,
        query: keyword,
        types: ['featureType']
      }).catch((e) => {
        return []
      })

      const formattedResults = formatTags(tags)

      setResults(formattedResults)
      setLoading(false)

      return results
    } catch (e) {
      setLoading(false)
    }
  }
  return { results, loading, search }
}

const formatTags = (tags) =>
  tags.map((tag) => ({
    label: tag.name,
    value: tag.name
  }))

export { formatTags, useSearchFeatureTypes }
