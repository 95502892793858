import React from 'react'
import PropTypes from 'prop-types'
import { styled, Box, FormLabel } from '@mui/material'
import { ChipInput, ChipInputPropTypes } from '../ChipInput'

const InputRoot = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '12px'
})

const LabelChipInput = (props) => {
  const { label = 'Value', value, options, onChange } = props

  const handleChange = (event, nextValue) => {
    onChange(nextValue)
  }

  return (
    <InputRoot className='LabelChipInput-root'>
      <FormLabel
        component='div'
        sx={{ typography: 'body2' }}
      >
        {label}
      </FormLabel>
      <ChipInput
        data-testid={`LabelChipInput-${label}`}
        value={value}
        options={options}
        onChange={handleChange}
      />
    </InputRoot>
  )
}

const LabelInputPropTypes = ChipInputPropTypes

LabelChipInput.propTypes = {
  /**
   * Set label of LabelChipInput.
   */
  label: PropTypes.string,
  /**
   * A array of default values, only applicable if prop 'controlledState' resolve to true
   */
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  /**
   * Array of options. Mainly array of strings
   */
  options: PropTypes.arrayOf(PropTypes.string),
  /**
		 * Callback fired when the value changes.
				Signature:
				function(event, value).
				event: The event source of the callback.
				value: The new value of the component.
		 */
  onChange: PropTypes.func
}

export { LabelChipInput, LabelInputPropTypes }
export default LabelChipInput
