import { generateFill } from "./fill";
import { generateLegend } from './legend';
import { generateLine } from './line';

export {
  generateFill,
  generateLegend,
  generateLine
};

