import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SelectInput, SelectInputPropTypes } from '../SelectInput'

const UncontrolledSelectInput = (props) => {
  const { defaultValue = '', onChange = () => {}, ...rest } = props

  const [value, setValue] = useState(defaultValue)

  const handleChange = (newValue) => {
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <SelectInput
      {...rest}
      value={value}
      onChange={handleChange}
    />
  )
}

const UncontrolledSelectInputPropTypes = {
  options: SelectInputPropTypes.options,
  onChange: PropTypes.func
}

UncontrolledSelectInput.propTypes = UncontrolledSelectInputPropTypes

export { UncontrolledSelectInput, UncontrolledSelectInputPropTypes }
