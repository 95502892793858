import { FeatureCollection } from 'geojson'
import { set } from 'idb-keyval'
import { getNanoId } from '~utils/functions/getNanoId'
import { hashMD5 } from '~utils/functions/hashMD5'
import { constructMapData } from '~utils/geojson/constructMapData'

type CreateLocalMapOptions = {
  id?: string
  dataId?: string
}

async function createLocalMap(
  geojson: FeatureCollection,
  { id, dataId }: CreateLocalMapOptions
) {
  const mapId = id ?? getNanoId()
  const mapDataId = dataId ?? hashMD5(geojson)
  const map = constructMapData(geojson, {
    id: mapId,
    dataId: mapDataId
  })

  await set(map.id, map)
  await set(mapDataId, geojson)

  return map
}

export { createLocalMap }
