import { graphierQL } from 'utils'
import {
  GetMapsQuery,
  GetMapsQueryVariables
} from '~map-viewer/types/__generated/gql/graphql'

const getMaps = async () => {
  const data = await graphierQL<GetMapsQuery, GetMapsQueryVariables>({
    query: getMapsQuery
  })

  return data.getMaps
}

const getMapsQuery = /* GraphQL */ `
  query getMaps {
    getMaps {
      id
      name
      isPublic
      updatedAt
    }
  }
`

export { getMaps, getMapsQuery }
