import { SvgIcon } from '@mui/material'
import UploadCloud01 from '@untitled-ui/icons-react/build/esm/UploadCloud01'
import IconButtonTooltip from '~ui-components/components/atoms/IconButtonTooltip'

const AddMapIconButton = (props) => {
  const { onClick } = props

  return (
    <IconButtonTooltip
      color='standard'
      className='AddMapIconButton'
      title='Add Map'
      icon={
        <SvgIcon>
          <UploadCloud01 />
        </SvgIcon>
      }
      onClick={onClick}
    />
  )
}

export { AddMapIconButton }
export default AddMapIconButton
