import { client, graphierQL } from 'utils'
import {
  GetReadmeAssetsUploadUrlQuery,
  GetReadmeAssetsUploadUrlQueryVariables
} from '~map-viewer/types/__generated/gql/graphql'

const createGetReadmeAssetsUploadUrl = (params) => {
  const { mapId, contentType } = params

  /**
   * Required to use this form in order to cancel the request
   * if needed
   */
  const promise = graphierQL<
    GetReadmeAssetsUploadUrlQuery,
    GetReadmeAssetsUploadUrlQueryVariables
  >({
    query: getReadmeAssetsUploadUrlQuery,
    variables: {
      mapId,
      contentType
    },
    useToken: true
  })

  return {
    promise: promise.then((response) => response.getReadmeAssetsUploadUrl),
    abort: () => {
      client.cancel(promise)
    }
  }
}

const getReadmeAssetsUploadUrlQuery = /* GraphQL */ `
  query getReadmeAssetsUploadUrl($mapId: ID!, $contentType: String!) {
    getReadmeAssetsUploadUrl(mapId: $mapId, contentType: $contentType) {
      path
      presignedPost
    }
  }
`

export { createGetReadmeAssetsUploadUrl }
