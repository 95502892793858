import { useState } from 'react'
import { MapMetadataRemixes, MapRemixesDrawer } from 'ui-components'
import { getPaginationArgs } from 'utils'
import { useGetMapRemixCollectionQuery } from '~map-viewer/services/map'
import { useMap } from '~map-viewer/states/map'
import { useRemixLimit, useRemixPage } from '~map-viewer/states/remix'
import { RemixMaps } from './RemixMaps'
import { RemixPagination } from './RemixPagination'

function Remixes() {
  const { id, remixes } = useMap()
  const limit = useRemixLimit()
  const page = useRemixPage()

  const { hasMaps, isLoading } = useGetMapRemixCollectionQuery(
    {
      mapId: id,
      ...getPaginationArgs(page, limit)
    },
    {
      skip: remixes && remixes <= 0,
      refetchOnMountOrArgChange: true,
      selectFromResult: (state) => ({
        isLoading: state.isLoading,
        hasMaps: (state.data?.getMapById?.mapRemixCollection?.total || 0) > 0
      })
    }
  )

  const [open, setOpen] = useState(false)
  const handleClick = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <MapRemixesDrawer
        loading={isLoading}
        hasMaps={hasMaps}
        open={open}
        onClose={handleClose}
        slots={{ RemixMaps, RemixPagination }}
      />

      <MapMetadataRemixes
        remixes={remixes ?? 0}
        onClick={handleClick}
      />
    </>
  )
}

export { Remixes }
