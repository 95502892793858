import { MapDescription as Layout } from 'ui-components'
import { useHasReadme, useIsOwner } from '~map-viewer/states/map'
import { TextEditor } from './TextEditor'

const MapDescription = (props) => {
  const isOwner = useIsOwner()
  const hasReadme = useHasReadme()

  return (
    <Layout
      {...props}
      hasReadme={hasReadme}
      owned={isOwner}
      slots={{
        TextEditor
      }}
    />
  )
}

export { MapDescription }
