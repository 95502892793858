import React from 'react'
import PropTypes from 'prop-types'

const borderRadiusMap = {
  squared: {},
  rounded: {
    borderRadius: 4
  },
  circular: {
    borderRadius: 999
  }
}

const Image = (props) => {
  const { style, borderRadius = 'rounded', ...rest } = props
  return (
    <img
      {...rest}
      style={{
        display: 'inline-block',
        outline: 'none',
        border: 'none',
        textDecoration: 'none',
        ...borderRadiusMap[borderRadius],
        ...style
      }}
    />
  )
}

export { Image }

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
  borderRadius: PropTypes.oneOf(['squared', 'rounded', 'circular']),
  style: PropTypes.object
}
