import { createApi } from '@reduxjs/toolkit/query/react'

import searchTags from '~map-creator/core/api/searchTags'

const tagApi = createApi({
  reducerPath: 'tagApi',
  baseQuery: Promise.resolve,
  endpoints: (builder) => ({
    searchTags: builder.query({
      queryFn: async ({ from, size, query, types }) => {
        try {
          const data = await searchTags({ from, size, query, types })
          return { data }
        } catch (error) {
          return { error }
        }
      }
    })
  })
})

export const { useLazySearchTagsQuery } = tagApi
export default tagApi
