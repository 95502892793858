import PropTypes from 'prop-types'
import MapstackLogo from './Logo'
import MapstackLogoCopy from './LogoCopy'

const BrandLogo = (props) => {
  const { variant = 'logo', size = 'md', ...rest } = props

  if (variant === 'logo')
    return (
      <MapstackLogo
        size={size}
        {...rest}
      />
    )

  if (variant === 'logoCopy')
    return (
      <MapstackLogoCopy
        size={size}
        {...rest}
      />
    )

  return null
}

BrandLogo.propTypes = {
  /**
   * Variant of the brand logo
   */
  variant: PropTypes.oneOf(['logo', 'logoCopy']),
  /**
   * Size of the brand logo
   */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl'])
}

export { BrandLogo }
export default BrandLogo
