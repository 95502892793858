import { LoadingButton } from '@mui/lab'
import { SvgIcon } from '@mui/material'
import XClose from '@untitled-ui/icons-react/build/esm/XClose'

const DeleteButton = (props) => {
  return (
    <LoadingButton
      loadingIndicator='Deleting...'
      variant='contained'
      color='error'
      startIcon={
        <SvgIcon sx={{ fontSize: '1.25em !important' }}>
          <XClose />
        </SvgIcon>
      }
      {...props}
    >
      Delete
    </LoadingButton>
  )
}

export { DeleteButton }
export default DeleteButton
