import { Stack } from '@mui/material'
import PropTypes from 'prop-types'

const MapSettingsPanel = (props) => {
  const {
    mapNameInput: MapNameInput,
    mapTagsInput: MapTagsInput,
    mapDataLicenseInput: MapDataLicenseInput,
    mapDataSourceInput: MapDataSourceInput
  } = props

  return (
    <Stack
      width='100%'
      gap={2}
      data-testid='Step-MapSettings'
    >
      <MapNameInput />
      <MapTagsInput />
      <MapDataLicenseInput />
      <MapDataSourceInput />
    </Stack>
  )
}

MapSettingsPanel.propTypes = {
  mapNameInput: PropTypes.any,
  mapTagsInput: PropTypes.any
}

export { MapSettingsPanel }
export default MapSettingsPanel
