import { useDispatch } from 'react-redux'
import {
  replaceLastWizardStep,
  useCurrentWizardStep
} from '~map-creator/core/states/map'
import {
  updateTitlePropertyKey,
  useIncludedKeys
} from '~map-creator/core/states/properties'

export const useSkippableFeatureTitle = (isActivated = false) => {
  const dispatch = useDispatch()

  const currentWizardStep = useCurrentWizardStep()
  const includedKeys = useIncludedKeys()

  if (isActivated && includedKeys.length === 1) {
    const nextStep = currentWizardStep + 1
    dispatch(replaceLastWizardStep(nextStep))
    dispatch(updateTitlePropertyKey(includedKeys[0]))
  }
}
