export const GEOJSON_ERRORS = {
  NOT_JSON_OBJECT: {
    key: 'NOT_JSON_OBJECT',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'must be a JSON Object'
  },
  MISSING_MEMBER_TYPE: {
    key: 'MISSING_MEMBER_TYPE',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'must have a member with the name "type"'
  },
  NOT_SUPPORTING_TYPE: {
    key: 'NOT_SUPPORTING_TYPE',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'type must be one of:  "Polygon" or "MultiPolygon"'
  },
  NO_EXISTING_TYPE: {
    key: 'NO_EXISTING_TYPE',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'Unsupported geometry type in the uploaded geojson'
  },
  BBOX_IS_NOT_ARRAY: {
    key: 'BBOX_IS_NOT_ARRAY',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'bbox must be an array'
  },
  BBOX_IS_NOT_EVEN_ARRAY: {
    key: 'BBOX_IS_NOT_EVEN_ARRAY',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'bbox, must be a 2*n array'
  },
  BBOX_MUST_CONTAIN_NUMBER_ONLY: {
    key: 'BBOX_MUST_CONTAIN_NUMBER_ONLY',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'bbox, must contain number only '
  },
  MUST_BE_TYPE_FEATURE: {
    key: 'MUST_BE_TYPE_FEATURE',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'type must be "Feature"'
  },
  MUST_BE_TYPE_FEATURE_COLLECTION: {
    key: 'MUST_BE_TYPE_FEATURE_COLLECTION',
    title: 'MUST_BE_TYPE_FEATURE_COLLECTION',
    type: 'error',
    description: 'type must be "FeatureCollection"'
  },
  MUST_BE_TYPE_POLYGON: {
    key: 'MUST_BE_TYPE_POLYGON',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'type must be "Polygon"'
  },
  MUST_BE_TYPE_MULTI_POLYGON: {
    key: 'MUST_BE_TYPE_MULTI_POLYGON',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'type must be "MultiPolygon"'
  },
  FEATURE_MUST_BE_ARRAY: {
    key: 'FEATURE_MUST_BE_ARRAY',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: '"Features" must be an array and not empty'
  },
  MISSING_MEMBER_FEATURES: {
    key: 'MISSING_MEMBER_FEATURES',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'must have a member with the name "Features"'
  },
  MISSING_MEMBER_PROPERTIES: {
    key: 'MISSING_MEMBER_PROPERTIES',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'must have a member with the name "properties"'
  },
  MISSING_MEMBER_GEOMETRY: {
    key: 'MISSING_MEMBER_GEOMETRY',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'missing/empty location information (geometry)'
  },
  MISSING_MEMBER_COORDINATE: {
    key: 'MISSING_MEMBER_COORDINATE',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'must have a member with the name "coordinates"'
  },
  COORDINATE_MUST_BE_ARRAY: {
    key: 'COORDINATE_MUST_BE_ARRAY',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'coordinates must be an array'
  },
  COORDINATE_MINIMUM_4_ELEMENTS: {
    key: 'COORDINATE_MINIMUM_4_ELEMENTS',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'coordinates must have at least four positions'
  },
  COORDINATE_MUST_EQUIVALENT: {
    key: 'COORDINATE_MUST_EQUIVALENT',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'The first and last positions must be equivalent'
  },
  POSITION_MUST_BE_ARRAY: {
    key: 'POSITION_MUST_BE_ARRAY',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'Position must be an array'
  },
  POSITION_MINIMUM_2_ELEMENTS: {
    key: 'POSITION_MINIMUM_2_ELEMENTS',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'coordinates must have at least four positions'
  },
  POSITION_MUST_CONTAIN_NUMBER_ONLY: {
    key: 'POSITION_MUST_CONTAIN_NUMBER_ONLY',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'Position must only contain numbers. Item '
  },
  NO_NUMERIC_PROPERTY: {
    key: 'NO_NUMERIC_PROPERTY',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'Feature has no numeric property'
  },
  FILE_CONTAINS_NO_VALID_COLUMN: {
    key: 'FILE_CONTAINS_NO_VALID_COLUMN',
    title: 'GeoJSON Invalid',
    type: 'error',
    description:
      'We currently only support quantity or category data, therefore at least one property field must contain a quantity (numbers) or a categories (between 2 and 10 unique values).'
  }
}
