import { ButtonProps, SvgIcon } from '@mui/material'
import Fab from '@mui/material/Fab'
import SaveIcon from '@untitled-ui/icons-react/build/esm/Save01'
import { FC } from 'react'

const SaveMapButton: FC<ButtonProps> = (props) => {
  const { variant = 'contained', onClick = () => {}, sx, ...rest } = props

  return (
    <Fab
      color='primary'
      variant='extended'
      data-testid='SaveMapButton'
      onClick={onClick}
      {...rest}
      sx={{ height: 40, ...sx }}
    >
      <SvgIcon sx={{ mr: 1 }}>
        <SaveIcon />
      </SvgIcon>
      Save map
    </Fab>
  )
}

export { SaveMapButton }
