import { configureStore } from '@reduxjs/toolkit'
import { withReduxConfigs } from 'utils'

import mapCreatorReducer from './map-creator'

const mapViewerStore = configureStore(
  withReduxConfigs({
    reducer: {
      mapCreator: mapCreatorReducer
    }
  })
)

export { mapViewerStore }
