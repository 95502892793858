import Button from '@mui/material/Button'
import { useRouter } from 'next/router'
import { PageNotFound } from '../index'

const MapNotFound = () => {
  const router = useRouter()
  const GotToResource = () => (
    <Button
      sx={{ fontWeight: 600 }}
      onClick={() => router.push('/search')}
    >
      Maps
    </Button>
  )
  return <PageNotFound slot={{ GoToResource: GotToResource }} />
}

export { MapNotFound }
export default MapNotFound
