import { del } from 'idb-keyval'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useGeojsonFileId } from '~map-creator/core/states/geojson'

const useRefreshHandler = () => {
  const router = useRouter()
  const geojsonFileId = useGeojsonFileId()

  const deleteGeojsonFile = async () => {
    if (geojsonFileId) {
      await del(`${geojsonFileId}:geojson`)
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', deleteGeojsonFile)
    router.events.on('routeChangeStart', deleteGeojsonFile)

    return () => {
      window.removeEventListener('beforeunload', deleteGeojsonFile)
      router.events.off('routeChangeStart', deleteGeojsonFile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geojsonFileId])
}

export { useRefreshHandler }
