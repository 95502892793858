import { FeatureCollection } from 'geojson'
import { Fill, Legend, Line, StyleOptions, StyleOutput } from '../types'
import { MS_COLOUR_PALETTE } from '../constants'
import { generateColourPalette } from '../get-colour-palette'
import { generateResolutions } from '../utils/get-resolutions'
import {
  generate3DFill,
  generateFill,
  generateLegend,
  generateLine
} from './h3-style-utils'
import { generateH3Classes } from './helper'

// boundary shouldn't change for hexagons
const DEFAULT_H3_OPTIONS: StyleOptions = {
  aggregateBoundary: 'hexBoundaryId',
  aggregateProperty: 'count',
  aggregateType: 'SUM',
  source: 'mapstack-hexagon'
}

function getH3Style(
  geojson: FeatureCollection,
  resolution: number = -1,
  options: StyleOptions = DEFAULT_H3_OPTIONS
): StyleOutput {

  if (geojson.features.length < 1) {
    return {
      fill: {
        id: 'Mapstack Visualization Fill',
        "source-layer": options.sourceLayer,
        type: 'fill',
        source: options.source,
        "paint": {
          "fill-color": "#000000",
          "fill-opacity": 0
        }
      }
    }
  }

  if (resolution === -1)
    resolution = generateResolutions(geojson.features).medium

  const { boundaryClassBreaks, jenksBreaks } = generateH3Classes(
    geojson.features,
    options.aggregateBoundary,
    options.aggregateProperty
  )
  const colourPalette = generateColourPalette(
    boundaryClassBreaks?.length,
    MS_COLOUR_PALETTE
  )
  const fill: Fill = generateFill(
    boundaryClassBreaks,
    colourPalette,
    options.aggregateBoundary,
    options.source
  )
  const line: Line = generateLine(options.source, resolution)
  const legend: Legend = generateLegend(
    jenksBreaks,
    colourPalette,
    options.aggregateProperty
  )

  const styleOutput: StyleOutput = {
    fill: fill,
    line: line,
    legend: legend
  }
  return styleOutput
}

// resolution is needed for height gen, is returned alongside h3 geojson generation
// -1 by default and will gen on the fly if not passed
function get3dH3Style(
  geojson: FeatureCollection,
  resolution: number = -1,
  options: StyleOptions = DEFAULT_H3_OPTIONS
): StyleOutput {

  if (geojson.features.length < 1) {
    return {
      fill: {
        id: 'Mapstack Visualization Fill',
        "source-layer": options.sourceLayer,
        type: 'fill',
        source: options.source,
        "paint": {
          "fill-color": "#000000",
          "fill-opacity": 0
        }
      }
    }
  }


  resolution = generateResolutions(geojson.features, true).medium

  const { boundaryClassBreaks, jenksBreaks } = generateH3Classes(
    geojson.features,
    options.aggregateBoundary,
    options.aggregateProperty
  )
  const colourPalette: string[] = generateColourPalette(
    boundaryClassBreaks?.length,
    MS_COLOUR_PALETTE
  )
  const fill: Fill = generate3DFill(
    boundaryClassBreaks,
    colourPalette,
    options.aggregateBoundary,
    options.source,
    resolution
  )
  const legend: Legend = generateLegend(
    jenksBreaks,
    colourPalette,
    options.aggregateProperty
  )

  const styleOutput: StyleOutput = {
    fill: fill,
    legend: legend
  }
  return styleOutput
}

export { DEFAULT_H3_OPTIONS, get3dH3Style, getH3Style }
