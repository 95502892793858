import { CircularProgress } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Edit05 from '@untitled-ui/icons-react/build/esm/Edit05'
import Trash01 from '@untitled-ui/icons-react/build/esm/Trash01'
import PropTypes from 'prop-types'
import { type ChangeEvent, type FC, type MouseEvent } from 'react'

import { RouterLink } from '~ui-components/devias-components/router-link'
import { Tag } from '~ui-components/types/__generated/gql/graphql'

export type CategoriesListTableItem = Pick<
  Tag,
  'id' | 'name' | 'thumbnail' | 'categoryType' | 'updatedAt'
>

interface CategoriesListTableProps {
  isLoading?: boolean
  count?: number
  items?: CategoriesListTableItem[]
  onPageChange?: (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void
  onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onDeleteClick?: (id: string) => void
  page?: number
  rowsPerPage?: number
}

const getThumbnailUrl = (tag: CategoriesListTableItem) =>
  `https://${process.env.TAG_ASSETS_URL}/${tag.thumbnail}?ts=${tag.updatedAt}`

const CategoriesListTable: FC<CategoriesListTableProps> = (props) => {
  const {
    isLoading = false,
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    onDeleteClick = () => {}
  } = props

  return (
    <Box sx={{ position: 'relative' }}>
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Tag</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align='right'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell
                sx={{ border: 0, textAlign: 'center' }}
                colSpan={4}
              >
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
          {items.map((tag) => {
            return (
              <TableRow
                hover
                key={tag.id}
              >
                <TableCell>
                  <Stack
                    alignItems='center'
                    direction='row'
                    spacing={1}
                  >
                    <Avatar
                      src={tag.thumbnail ? getThumbnailUrl(tag) : undefined}
                      sx={{ height: 42, width: 42 }}
                    >
                      {tag.name}
                    </Avatar>
                    <div>
                      <Link
                        color='inherit'
                        component={RouterLink}
                        href={`/staff-room/categories/${tag.id}/edit`}
                        variant='subtitle2'
                      >
                        {tag.name}
                      </Link>
                    </div>
                  </Stack>
                </TableCell>
                <TableCell>{tag.id}</TableCell>
                <TableCell>{tag.categoryType}</TableCell>
                <TableCell align='right'>
                  <IconButton
                    component={RouterLink}
                    href={`/staff-room/categories/${tag.id}/edit`}
                  >
                    <SvgIcon>
                      <Edit05 />
                    </SvgIcon>
                  </IconButton>
                  <IconButton onClick={() => onDeleteClick?.(tag.id)}>
                    <SvgIcon>
                      <Trash01 />
                    </SvgIcon>
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[20]}
      />
    </Box>
  )
}

CategoriesListTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number
}

export { CategoriesListTable }
