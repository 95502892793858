import { FC } from 'react'
import { Box, Item, Typography } from '~ui-components/emails/components/atoms'

type InvitationIntroProps = {
  username: string
  mapName: string
}

export const InvitationIntro: FC<InvitationIntroProps> = (props) => {
  const { username, mapName } = props
  return (
    <Box>
      <Item align='left'>
        <Typography variant='h5'>Access this map on Mapstack</Typography>
      </Item>
      <Item
        align='left'
        pt={16}
      >
        <Typography
          data-testid='InvitationCopy'
          variant='body1'
        >
          <b data-testid='Username'>{username}</b> has invited you to access
          their map called&nbsp;
          <b data-testid='MapName'>{mapName}</b>.
        </Typography>
      </Item>
    </Box>
  )
}
