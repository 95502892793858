import { useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import {
  PluginProvider,
  RendererPlugin,
  createPluginStore
} from 'react-pluggable'

import { useSelectedProperty } from '~map-creator/core/App/BaseMap/hooks/useSelectedProperty'
import { useSetFitBounds } from '~map-creator/core/App/BaseMap/hooks/useSetFitBounds'
import CustomNavigationControlPlugin from '~map-creator/core/App/BaseMap/plugins/CustomNavigationControl'
import GestureInstructionPlugin from '~map-creator/core/App/BaseMap/plugins/GestureInstruction'
import PopupPlugin from '~map-creator/core/App/BaseMap/plugins/Popup'
import VisualisationsPlugin from '~map-creator/core/App/BaseMap/plugins/Visualisations'
import { useCurrentWizardStep } from '~map-creator/core/states/map'

import { useStepIndexes } from '~map-creator/core/states/ui'
import { View } from './view'

const pluginStore = createPluginStore()
pluginStore.install(new RendererPlugin())
pluginStore.install(new VisualisationsPlugin())
pluginStore.install(new GestureInstructionPlugin())
pluginStore.install(new CustomNavigationControlPlugin())

const popupPlugin = new PopupPlugin()
pluginStore.install(popupPlugin)

const BaseMap = () => {
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  const [map, setMap] = useState(null)
  const currentWizardStep = useCurrentWizardStep()
  const stepIndexes = useStepIndexes()

  const { handleMapClick } = useSelectedProperty(
    map,
    currentWizardStep >= stepIndexes.INCLUDE_PROPERTIES
  )

  useSetFitBounds(map)

  /**
   * Storing map-gl instance, helpful when needs to interact with map through its API
   */
  const handleMapInstance = (mapInstance) => {
    if (!map) {
      popupPlugin.setMap(mapInstance)
    }
    setMap(mapInstance)
  }

  return (
    <PluginProvider pluginStore={pluginStore}>
      <View
        dragEnabled={isBrowser}
        onMapInstance={handleMapInstance}
        onMapClick={handleMapClick}
      />
    </PluginProvider>
  )
}

BaseMap.propTypes = {}

export { BaseMap }
