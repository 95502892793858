import { NavigationControl } from 'react-map-gl/maplibre'
import { useTheme, GlobalStyles, lighten } from '@mui/material'

const NavigationControlStylesOverride = () => {
  const theme = useTheme()

  return (
    <GlobalStyles
      styles={{
        '.maplibregl-ctrl-group': {
          backgroundColor: `${lighten(
            theme.palette.background.paper,
            0.1
          )} !important`
        },
        '.maplibregl-ctrl-attrib a:hover': {
          color: `${theme.palette.text.primary} !important`
        },
        '.maplibregl-ctrl-zoom-in .maplibregl-ctrl-icon': {
          filter: theme.palette.text.primary
        },
        '.maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon': {
          filter: theme.palette.text.primary
        }
      }}
    />
  )
}

class CustomNavigationControlPlugin {
  pluginStore
  namespace = 'CustomNavigationControl'

  getPluginName() {
    return `${this.namespace}@1.0.0`
  }

  getDependencies() {
    return []
  }

  init(pluginStore) {
    this.pluginStore = pluginStore
  }

  activate() {
    if (typeof window !== 'undefined') {
      this.pluginStore.executeFunction(
        'Renderer.add',
        'customNavigationControl',
        () => (
          <div>
            <NavigationControl
              position='top-right'
              style={{
                marginTop: '8px',
                marginLeft: '8px'
              }}
              showCompass={false}
            />

            <NavigationControlStylesOverride />
          </div>
        )
      )
    }
  }

  deactivate() {}
}

export default CustomNavigationControlPlugin
