import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  getSuggestedCoverageAreas,
  useCurrentBounds
} from '~map-creator/core/states/map'

const useSuggestedCoverageAreas = () => {
  const dispatch = useDispatch()
  const currentBounds = useCurrentBounds()

  useEffect(() => {
    async function start() {
      dispatch(getSuggestedCoverageAreas(currentBounds))
    }

    const isCurrentBoundsValid = Object.values(currentBounds).every(Boolean)
    isCurrentBoundsValid && start()
  }, [currentBounds, dispatch])
}

export { useSuggestedCoverageAreas }
