import _ from 'lodash'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { useVisualisations } from '~map-viewer/states/map'
import {
  setVisualisation,
  useVisualisationValue
} from '~map-viewer/states/visualisation'

import { MapVisualisationChips as View } from 'ui-components'
import { useWhetherToCollapseChips } from '~map-viewer/App/hooks/useWhetherToCollapseChips'

const updateMapViewRouter = (router, value) => {
  const { slug } = router.query
  // INFO: Add Try Catch block for handling Shared Map
  try {
    router.replace(
      {
        pathname: '/map/[mapId]/[...slug]',
        query: {
          ...router.query,
          slug: [slug[0], _.toLower(value).replace(/\s+/g, '-')]
        }
      },
      undefined,
      { shallow: true }
    )
  } catch (error) {
    console.warn(error)
  }
}

const MapVisualisationChips = (props) => {
  const router = useRouter()
  const dispatch = useDispatch()

  const visualisationValue = useVisualisationValue()
  const visualisations = useVisualisations()
  const shouldUseCollapse = useWhetherToCollapseChips()

  const singleQuantity = visualisations.length === 1

  const visualisationOptions = useMemo(() => {
    return visualisations.map(({ value }) => ({ label: value, value }))
  }, [visualisations])

  const handleChange = (value) => {
    updateMapViewRouter(router, value)
    const visualisation = _.find(visualisations, { value })
    dispatch(setVisualisation(visualisation))
  }

  if (singleQuantity) return null

  return (
    <View
      {...props}
      shouldUseCollapse={shouldUseCollapse}
      options={visualisationOptions}
      value={visualisationValue}
      onChange={handleChange}
    />
  )
}

export { MapVisualisationChips }
