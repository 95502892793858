import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { isValidUrl } from 'utils'
import { usePropertiesClassBreaks } from '~map-creator/core/hooks/usePropertiesClassBreaks'
import { useMapName, useSource } from '~map-creator/core/states/map'
import { useInputTags } from '~map-creator/core/states/ui'
import MapDataLicenseInput from './MapDataLicenseInput'
import MapDataSourceInput from './MapDataSourceInput'
import MapNameInput from './MapNameInput'
import MapTagsInput from './MapTagsInput'
import View from './view'

const isValidMapSource = (source) => {
  const { url } = source

  return isEmpty(url) || isValidUrl(url)
}

const MapSettings = (props) => {
  const { onValidated = () => false } = props

  // call hook to set classbreaks to properties here since it is used in all csv, geojson, and edit map
  usePropertiesClassBreaks()

  const mapName = useMapName()
  const mapSource = useSource()
  const { invalid: invalidTags } = useInputTags()

  useEffect(() => {
    onValidated(
      !isEmpty(mapName) && !invalidTags && isValidMapSource(mapSource)
    )
  }, [mapName, invalidTags, onValidated, mapSource])

  return (
    <View
      mapNameInput={MapNameInput}
      mapTagsInput={MapTagsInput}
      mapDataLicenseInput={MapDataLicenseInput}
      mapDataSourceInput={MapDataSourceInput}
    />
  )
}

MapSettings.propTypes = {
  onValidated: PropTypes.func
}

export { MapSettings }
export default MapSettings
