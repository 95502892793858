import React from 'react'
import {
  Dialog,
  DialogPropTypes
} from '~ui-components/components/molecules/Dialog'

const DeleteMapDialog = (props) => {
  const { loading, ...rest } = props
  return (
    <Dialog
      loading={loading}
      title='Are you absolutely sure?'
      confirmText='Remove'
      confirmColor='error'
      {...rest}
    >
      This action will remove this map from this workspace.
    </Dialog>
  )
}

DeleteMapDialog.propTypes = DialogPropTypes

export { DeleteMapDialog }
export default DeleteMapDialog
