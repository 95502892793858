function getHeatmapStyle() {
  const fill = {
    id: 'Mapstack Visualization Fill',
    type: 'heatmap',
    source: 'mapstack-points',
    paint: {
      // Simple weight, just acknowledges presence of data points
      'heatmap-weight': ['interpolate', ['linear'], ['zoom'], 0, 0.5, 14, 0.3],

      // Heatmap intensity is adjusted by zoom level
      'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1.5, 9, 5],

      // Color ramp for heatmap from low (green) to high (red) density
      'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0, 'rgba(0,0,255,0)',    // Full blue at highest density,
        0.2, 'rgba(0,0,255,0.8)',
        0.4, 'rgba(31,180,120,0.8)', // Lighter blue
        0.95, 'rgba(0,255,0,0.8)',  // Green at middle density
        1, 'rgba(255,0,0,0.8)', // Start transparent with red
      ],

      // Adjust the heatmap point radius based on zoom level
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 10, 9, 25],

      // Decrease the opacity of the heatmap with higher zoom levels
      'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0.65, 9, 0.8]
    }
  }

  return {
    fill
  }
}

export { getHeatmapStyle }
