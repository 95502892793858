import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const StaffRoomHome = () => {
  return (
    <Box
      component='main'
      sx={{ flexGrow: 1, py: 8 }}
    >
      <Container maxWidth='xl'>
        <Stack spacing={4}>
          <Stack
            direction='row'
            justifyContent='space-between'
            spacing={4}
          >
            <Typography variant='h4'>Home</Typography>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export { StaffRoomHome }
