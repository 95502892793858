import { DownloadButton as Button } from 'ui-components'
import { useDownloadGeojson } from '~map-viewer/hooks/useDownloadGeojson'

const DownloadButton = (props) => {
  const { downloadGeojson } = useDownloadGeojson()
  return (
    <Button
      {...props}
      onClick={downloadGeojson}
    />
  )
}

export { DownloadButton }
export default DownloadButton
