import { IconButton, Stack, SvgIcon } from '@mui/material'
import { ChevronLeft } from '@untitled-ui/icons-react'

import { FC } from 'react'
import { Sidebar } from '~ui-components/components/molecules/Sidebar'

type InviteGuestDrawerProps = {
  open: boolean
  onClose: () => void
  slots?: {
    InviteEmailInput?: React.ReactNode
    UninvitedGuestsList?: React.ReactNode
    UsersSearchList?: React.ReactNode
  }
}

export const InviteGuestDrawer: FC<InviteGuestDrawerProps> = (props) => {
  const { open, onClose, slots } = props

  return (
    <Sidebar
      open={open}
      icon={
        <IconButton
          color='inherit'
          onClick={onClose}
          sx={{
            backgroundColor: (theme) => theme.palette.neutral[50]
          }}
        >
          <SvgIcon>
            <ChevronLeft />
          </SvgIcon>
        </IconButton>
      }
      title='Invite Guest'
      anchor='left'
      ModalProps={{
        hideBackdrop: true,
        sx: {
          top: 'unset',
          right: 'unset',
          bottom: 'unset',
          left: 'unset'
        }
      }}
    >
      <Stack
        p={2}
        spacing={5}
      >
        {slots?.InviteEmailInput}
        {slots?.UninvitedGuestsList}
        {slots?.UsersSearchList}
      </Stack>
    </Sidebar>
  )
}
