import { usePreventNavigation } from '~ui-components/hooks/usePreventNavigation'

const PreventNavigateAway = (props) => {
  const { enable, message, children, onRouteChangeStart } = props
  usePreventNavigation({ enable, message, onRouteChangeStart })
  return <>{children}</>
}

export { PreventNavigateAway }
export default PreventNavigateAway
