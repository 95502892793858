
/**
 * @type {import('@mui/material').ThemeOptions['unstable_sxConfig']}
 */
const sxConfig = {
  fontWeight: {
    themeKey: 'fontWeight'
  },
  letterSpacing: {
    themeKey: 'letterSpacing'
  },
}

export { sxConfig }
export default sxConfig
