import {
  Chip,
  ChipProps,
  ToggleButton,
  ToggleButtonProps,
  styled
} from '@mui/material'
import PropTypes from 'prop-types'
import { FC } from 'react'
import { blue } from '~ui-components/theme/colors'

const ToggleChipRoot = styled(ToggleButton)({
  all: 'unset',
  '&.Mui-selected, &.Mui-selected:hover, &:hover': {
    backgroundColor: 'unset'
  }
})

const ToggleChipContent = styled(Chip)({
  fontWeight: 600
})

export type ToggleChipProps = {
  ChipRootProps?: ToggleButtonProps
  ChipContentProps?: ChipProps
  [key: string]: any
}
const ToggleChip: FC<ToggleChipProps> = (props) => {
  const {
    label,
    value,
    currentValue,
    size = 'medium',
    multiple = false,
    ChipContentProps,
    ChipRootProps,
    ...rest
  } = props

  const active = !multiple
    ? currentValue === value
    : currentValue.includes(value)

  const handleClick = () => {}

  return (
    <ToggleChipRoot
      {...rest}
      className='ToggleChip'
      disableRipple
      disableFocusRipple
      value={value}
      {...ChipRootProps}
    >
      <ToggleChipContent
        data-testid='toggle-chip-content'
        size={size}
        label={label}
        color={active ? 'secondary' : 'default'}
        sx={[
          active
            ? {
                '&:hover': {
                  backgroundColor: blue.dark
                }
              }
            : {
                backgroundColor: (theme) => theme.palette.neutral[300],
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.neutral[200]
                }
              },
          { '& .MuiChip-icon': { color: 'inherit', marginRight: 'unset' } }
        ]}
        onClick={handleClick}
        {...ChipContentProps}
      />
    </ToggleChipRoot>
  )
}

ToggleChip.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  multiple: PropTypes.bool,
  /**
   * The content of the chip.
   */
  label: PropTypes.string.isRequired,
  /**
   * The value to associate with the ToggleChip's label.
   */
  value: PropTypes.any.isRequired,
  /**
   * The currentValue to associate with the chip when selected in a
   * ToggleChipInput.
   */
  currentValue: PropTypes.any
}

export { ToggleChip }
export default ToggleChip
