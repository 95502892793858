import { ELEMENT_IMAGE, insertNodes } from '@udecode/plate'
import { getNanoId } from 'utils'

const saveImage = async (editor, file) => {
  const id = getNanoId()
  const image = {
    uid: id,
    path: URL.createObjectURL(file),
    type: ELEMENT_IMAGE,
    children: [{ text: '' }]
  }

  insertNodes(editor, image)
}

const saveImages = async (editor, files) => {
  for (const file of files) {
    await saveImage(editor, file)
  }
}

export { saveImages }
