import React from 'react'
import {
  SelectUniqueIdentifier,
  SelectUniqueIdentifierPropTypes
} from 'ui-components'

const View = (props) => {
  return <SelectUniqueIdentifier {...props} />
}

View.propTypes = SelectUniqueIdentifierPropTypes

export { View }
