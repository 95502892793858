const ringEffect = (config = {}) => {
  const {
    ringOffsetColor = 'transparent',
    ringOffsetWidth = '0px',
    ringColor = 'currentColor',
    ringWidth = '0px',
    ringInset = false
  } = config

  return {
    '--shadow': '0 0 #0000',
    '--ring-offset-shadow': `${
      ringInset ? 'inset' : ''
    } 0 0 0 ${ringOffsetWidth} ${ringOffsetColor}`,
    '--ring-shadow': `${
      ringInset ? 'inset' : ''
    } 0 0 0 calc(${ringWidth} + ${ringOffsetWidth}) ${ringColor}`,
    boxShadow:
      'var(--ring-offset-shadow), var(--ring-shadow), var(--shadow, 0 0 #0000)'
  }
}

export { ringEffect }
export default ringEffect
