import { castArray } from 'lodash'
import { useRouter } from 'next/router'
import { Auth, VerifyForm } from 'ui-components'

function Form() {
  const router = useRouter()

  const email = castArray(router.query.email)[0]
  const firstName = castArray(router.query.firstName)[0]
  const lastName = castArray(router.query.lastName)[0]

  const handleButtonClick = () => {}

  const handleResendClick = (event) => {
    event.preventDefault()
    router.reload()
  }

  return (
    <VerifyForm
      email={email}
      slotProps={{
        Button: {
          onClick: handleButtonClick
        },
        ResendLink: {
          onClick: handleResendClick
        }
      }}
    />
  )
}

function Verify() {
  return <Auth slots={{ Form }} />
}

export { Verify }
