import { isValidUrl } from '../isValidUrl'

export const isRedirectedFromPolygonsMapEditor = (
  mapData: Record<string, any> | null | undefined,
  referer?: string
) => {
  if (!referer) return false
  if (!isValidUrl(referer)) return false

  const type = getRedirectedSourceType(referer)

  if (type === 'creator') return true
  if (type === 'editor') {
    const { mapId, workspaceId } = getMapDataFromUrl(referer)
    if (!mapId || !workspaceId || !mapData) return false

    const { id, workspace } = mapData
    return id === mapId && workspace?.id === workspaceId
  }

  return false
}

const getRedirectedSourceType = (
  refererUrl: string
): 'creator' | 'editor' | null => {
  const { pathname, searchParams } = new URL(refererUrl)
  if (searchParams.get('action') === 'edit') return 'editor'
  if (
    pathname.includes('/create-map/geojson') ||
    pathname.includes('/create-map')
  )
    return 'creator'

  return null
}

const getMapDataFromUrl = (url) => {
  const { pathname, searchParams } = new URL(url)
  if (searchParams.get('action') === 'edit') {
    // INFO: path of edit map
    // /workspace/:workspaceId/workspaceName/map/:mapId
    const [, , workspaceId, , , mapId] = pathname.split('/')
    return { workspaceId, mapId }
  }
  return {}
}

export const isRedirectedFromPointsMapEditor = (referer?: string) => {
  if (!referer) return false
  if (!isValidUrl(referer)) return false
  const { pathname } = new URL(referer)
  return pathname.startsWith(`map/preview/`)
}
