import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import NextImage from 'next/image'
import type { FC } from 'react'
import { getNanoId } from 'utils'

import image1 from '~ui-components/assets/images/homepage/how-it-works/image-1.webp'
import image2 from '~ui-components/assets/images/homepage/how-it-works/image-2.webp'
import image3 from '~ui-components/assets/images/homepage/how-it-works/image-3.webp'

type Data = {
  id: string
  title: string
  description: string
  image: any
}

type ItemProps = {
  data: Data
}

const Item: FC<ItemProps> = (props) => {
  const { data } = props

  return (
    <Card
      sx={{ minHeight: 250, position: 'relative', height: '100%' }}
      {...props}
    >
      <Stack
        alignItems='center'
        spacing={2}
        sx={{
          p: 3,
          textAlign: 'center',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Typography
          align='center'
          variant='h5'
        >
          {data.title}
        </Typography>
        <Typography
          align='center'
          sx={{
            fontSize: 18,
            fontWeight: 500,
            color: 'text.secondary'
          }}
        >
          {data.description}
        </Typography>

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            aspectRatio: 1,
            borderRadius: 4,
            overflow: 'hidden'
          }}
        >
          <NextImage
            src={data.image.src}
            alt=''
            fill
          />
        </Box>
      </Stack>
    </Card>
  )
}

const HomeHowItWorks: FC = (props) => {
  return (
    <Box
      sx={{
        p: 3,
        py: 0
      }}
    >
      <Typography
        variant='h3'
        sx={{ textAlign: 'center', pt: 0, pb: 4 }}
      >
        How Mapstack Works
      </Typography>

      <Grid
        container
        spacing={3}
        sx={{ alignItems: 'flex-start' }}
      >
        {data.map((plan) => {
          return (
            <Grid
              key={plan.id}
              item
              md={4}
              xs={12}
            >
              <Item data={plan} />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

const data: Data[] = [
  {
    id: getNanoId(),
    title: 'Upload Your Data',
    description:
      'Simply upload your spreadsheets containing location data, such as addresses, postcodes, or coordinates. Mapstack supports common formats like CSV and Excel.',
    image: image1
  },
  {
    id: getNanoId(),
    title: 'Use Your Map',
    description:
      'Your map is ready to go instantly. Explore the data visually, apply filters, and interact with the map to uncover insights.',
    image: image2
  },
  {
    id: getNanoId(),
    title: 'Share Your Insights',
    description:
      'Share your interactive maps with colleagues, clients, or the public. Export maps or embed them in reports and presentations to communicate your findings.',
    image: image3
  }
]

export { HomeHowItWorks }
