import React from 'react'
import { PlateLeaf } from '@udecode/plate-common'
import clsx from 'clsx'

const HighlightLeaf = ({ className, children, ...props }) => {
  return (
    <PlateLeaf
      asChild
      className={clsx(className)}
      {...props}
    >
      <mark>{children}</mark>
    </PlateLeaf>
  )
}

export { HighlightLeaf }
export default HighlightLeaf
