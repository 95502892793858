import { GraphQLResult } from '@aws-amplify/api'
import {
  GraphQLOptions,
  GraphqlSubscriptionResult
} from '@aws-amplify/api-graphql'
import { client } from '../client'

type Options<A> = Pick<GraphQLOptions, 'query'> & {
  variables?: A
}

type Callback<R> = {
  onNext: (data: GraphQLResult<R>) => void
  onError: (error) => void
}

export const graphierQLSubscription = <
  R = unknown,
  A extends Record<string, any> = Record<string, any>
>(
  options: Options<A>,
  callback: Callback<R>
) => {
  const { onNext, onError } = callback

  const subscription = client.graphql(
    options
  ) as unknown as GraphqlSubscriptionResult<R>

  return subscription.subscribe({
    next: (event) => {
      onNext(event)
    },
    error: (error) => {
      onError(error)
    }
  })
}
