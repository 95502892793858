import { VISUALISATION } from '../../assets/Constants'
import { getCategoryStyle } from '../categoryStyle'
import { getQuantitiesStyle } from '../quantitiesStyle'

const colorRamps = {
  [VISUALISATION.CATEGORY]: getCategoryStyle,
  [VISUALISATION.QUANTITY]: getQuantitiesStyle
}

export const getViewStyle = (property, jenks, type, filterArray) => {
  if (colorRamps[type] === undefined)
    throw new Error(`Unknown visualisation type: ${type}`)

  return colorRamps[type]?.(property, jenks, filterArray)
}
