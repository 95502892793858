import PropTypes from 'prop-types'
import { Box, Item, Typography } from '~ui-components/emails/components/atoms'

const InvitationCopy = (props) => {
  const { workspace, owner } = props
  const { firstName, lastName, email } = owner
  const fullName = [firstName, lastName].join(' ')
  return (
    <Box>
      <Item align='left'>
        <Typography variant='h5'>Join your team on mapstack</Typography>
      </Item>
      <Item
        align='left'
        pt={16}
      >
        <Typography
          data-testid='InvitationCopy'
          variant='body1'
        >
          <b data-testid='OwnerFullname'>{fullName}</b> has invited you to use
          mapstack with them, in a workspace called&nbsp;
          <b data-testid='WorkspaceName'>{workspace?.name}</b>.
        </Typography>
      </Item>
    </Box>
  )
}

InvitationCopy.propTypes = {
  workspace: PropTypes.object,
  owner: PropTypes.object
}

export { InvitationCopy }
export default InvitationCopy
