import { App as MapFileInput } from 'map-file-upload'
import { useState } from 'react'
import { MapCreateForm as Form } from 'ui-components'

import { MapFileAlert } from '../MapFileAlert'

type FormProps = React.ComponentProps<typeof Form>

function MapCreateForm() {
  const [variant, setVariant] = useState<FormProps['variant']>('form')
  const [error, setError] = useState(false)

  const handleUploadStart = () => {
    setVariant('loading')
  }

  const handleFileInvalidated = () => {
    setError(true)
  }

  return (
    <Form
      variant={variant}
      slots={{
        MapFileInput,
        MapFileAlert
      }}
      slotProps={{
        MapFileAlert: {
          error
        },

        MapFileInput: {
          onUploadStart: handleUploadStart,
          onFileInvalidated: handleFileInvalidated
        }
      }}
    />
  )
}

export { MapCreateForm }
