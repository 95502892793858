import { cloneDeep, get } from 'lodash'
import { getVisualisationPayload } from 'map-style'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useGeojsonData } from '~map-creator/core/App/BaseMap/hooks/useGeojsonData'
import {
  setConfigurations,
  useCategoryKeys,
  useConfigurations,
  useQuantityPropertiesKeys
} from '~map-creator/core/states/properties'

const usePropertiesClassBreaks = () => {
  const dispatch = useDispatch()
  const geojsonData = useGeojsonData()
  const categoryKeys = useCategoryKeys()
  const quantityKeys = useQuantityPropertiesKeys()
  const configurations = useConfigurations()

  const geojsonFeatures = useMemo(() => {
    if (!geojsonData?.features) return []
    const featuresWithoutGeometry = geojsonData.features.map(
      // eslint-disable-next-line no-unused-vars
      ({ geometry, ...rest }) => rest
    )
    return featuresWithoutGeometry
  }, [geojsonData])

  useEffect(() => {
    if (!geojsonFeatures.length) return

    const visualisationKeys = [...categoryKeys, ...quantityKeys]
    const updatedConfigurations = getConfigsWithClassBreaks(visualisationKeys)

    dispatch(setConfigurations(updatedConfigurations))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geojsonFeatures])

  const getConfigsWithClassBreaks = (keys) => {
    const clonedConfigurations = cloneDeep(configurations)
    keys.forEach((key) => {
      const visualisationType = get(configurations, [key, 'visualisation'])
      const { jenks } = getVisualisationPayload(
        key,
        geojsonFeatures,
        visualisationType
      )

      clonedConfigurations[key].classBreaks = jenks
    })

    return clonedConfigurations
  }
}

export { usePropertiesClassBreaks }
