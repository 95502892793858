import { getNanoId } from 'utils'
import { withNodeId, createPluginFactory, KEY_NODE_ID } from '@udecode/plate'

const createNodeIdPlugin = createPluginFactory({
  key: KEY_NODE_ID,
  withOverrides: withNodeId,
  options: {
    idKey: 'id',
    idCreator: () => getNanoId(),
    filterText: true,
    filter: () => true
  }
})

export default () =>
  createNodeIdPlugin({
    options: {
      reuseId: true,
      disableInsertOverrides: true
    }
  })
