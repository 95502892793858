import numberToPx from '~ui-components/utilities/numberToPx'

const paddingOf = ({ p, pt, pr, pb, pl }) => {
  if (p) return { padding: p }
  if (pt || pr || pb || pl)
    return {
      padding: [pt || 0, pr || 0, pb || 0, pl || 0].map(numberToPx).join(' ')
    }
  return {}
}

export { paddingOf }
export default paddingOf
