import { MARK_STRIKETHROUGH, isMarkActive, toggleMark } from '@udecode/plate'

import { focusEditor } from '@udecode/plate-common'
import { useEditorState } from '@udecode/plate-core'

import Action from '~ui-components/components/molecules/TextEditor/components/Action'

const Strikethrough = (props) => {
  const editor = useEditorState()
  const strikethrough =
    editor.selection && isMarkActive(editor, MARK_STRIKETHROUGH)

  const handleClick = () => {
    toggleMark(editor, {
      key: MARK_STRIKETHROUGH
    })
    focusEditor(editor)
  }

  return (
    <Action
      variant='strikethrough'
      toggled={strikethrough}
      onClick={handleClick}
      {...props}
    />
  )
}

export { Strikethrough }
