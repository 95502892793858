import {
  Autocomplete,
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  autocompleteClasses
} from '@mui/material'
import Check from '@untitled-ui/icons-react/build/esm/Check'
import ChevronDown from '@untitled-ui/icons-react/build/esm/ChevronDown'
import XClose from '@untitled-ui/icons-react/build/esm/XClose'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const tagColor = {
  coverageArea: '#17ab6a',
  featureType: '#ee7b42',
  topic: '#39a6f3'
}

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...rest } = props

  return (
    <Box
      sx={(theme) => ({
        width: '100% !important',
        borderRadius: 0,
        [`& .${autocompleteClasses.paper}`]: {
          boxShadow: 'none',
          margin: 0,
          color: 'inherit',
          fontSize: 13,
          borderRadius: 0
        },
        [`& .${autocompleteClasses.listbox}`]: {
          overscrollBehavior: 'contain',
          backgroundColor: theme.palette.background.paper,
          padding: 0,
          [`& .${autocompleteClasses.option}:not(:last-child)`]: {
            borderBottom: `1px solid ${theme.palette.divider}`
          },
          [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: '8px',
            '&[aria-selected="true"]': {
              backgroundColor: 'transparent'
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
              {
                backgroundColor: theme.palette.action.hover
              }
          }
        },
        [`&.${autocompleteClasses.popperDisablePortal}`]: {
          position: 'relative'
        }
      })}
      {...rest}
    />
  )
}

/**
 * @deprecated: New maps no longer have tags
 * */
const TagPicker = (props) => {
  const {
    sx,
    filterOnSearch = false,
    size = 'medium',
    options: optionsProp = [],
    value: valueProp = [],
    inputValue: inputValueProp,
    defaultInputValue: defaultInputValueProp,
    onChange = () => {},
    onInputChange = () => {}
  } = props

  const [inputValue, setInputValue] = useState(() => {
    if (inputValueProp && defaultInputValueProp) {
      throw new Error(
        'TagPicker cannot have both inputValue and defaultInputValue props.'
      )
    }

    return inputValueProp || defaultInputValueProp || ''
  })

  const [anchorEl, setAnchorEl] = useState(null)
  const [options, setOptions] = useState(valueProp)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleClose = () => {
    event.preventDefault()
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const handleOptionRemove = (event, option) => {
    const next = options.filter((current) => current.id !== option.id)
    setOptions(next)
    onChange({ type: 'remove' }, next)
  }

  const handleInputChange = (event, value) => {
    onInputChange(event, value)
    setInputValue(value)
  }

  useEffect(() => {
    setOptions(valueProp)
  }, [valueProp])

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        sx={(theme) => ({
          zIndex: theme.zIndex.modal
        })}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          }
        ]}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            data-testid='TagPickerBody'
            sx={(theme) => ({
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              width: 300,
              fontSize: 13,
              color: 'text.primary',
              backgroundColor: theme.palette.background.paper,
              overflow: 'hidden'
            })}
          >
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{
                padding: '8px 10px',
                fontWeight: 600
              }}
            >
              <Box>Choose tags to apply filter</Box>
              <IconButton
                onClick={handleClose}
                sx={{
                  width: '20px',
                  height: '20px',
                  fontSize: 'inherit'
                }}
              >
                <SvgIcon sx={{ fontSize: 'inherit' }}>
                  <XClose />
                </SvgIcon>
              </IconButton>
            </Stack>

            <Autocomplete
              sx={{
                m: 1
              }}
              open
              multiple
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              value={options}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              onChange={(event, options, reason) => {
                if (
                  event.type === 'keydown' &&
                  event.key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return
                }

                setOptions(options)
                onChange({ type: 'change' }, options)
              }}
              onClose={(event, reason) => {
                if (reason === 'escape') {
                  handleClose()
                }
              }}
              filterOptions={filterOnSearch ? undefined : (option) => option}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              noOptionsText='No tags'
              renderTags={() => null}
              renderOption={(props, option, { selected }) => {
                return (
                  <li
                    {...props}
                    key={option.id}
                  >
                    <SvgIcon
                      sx={{
                        width: 17,
                        height: 17,
                        mr: '5px',
                        ml: '-2px'
                      }}
                      style={{
                        visibility: selected ? 'visible' : 'hidden'
                      }}
                    >
                      <Check />
                    </SvgIcon>

                    <Box
                      component='span'
                      sx={{
                        width: 14,
                        height: 14,
                        flexShrink: 0,
                        borderRadius: '100%',
                        mr: 1,
                        mt: '2px'
                      }}
                      style={{
                        backgroundColor: tagColor[option?.type]
                      }}
                    />

                    <Box
                      sx={{
                        flexGrow: 1,
                        '& span': {
                          color: (theme) => theme.palette.divider
                        }
                      }}
                    >
                      {option?.name} ({option?.count || 0})
                      {options?.description ? (
                        <>
                          <Box component='br' />
                          <Box component='span'>{option.description}</Box>
                        </>
                      ) : null}
                    </Box>

                    <SvgIcon
                      sx={{ opacity: 0.6, width: 18, height: 18 }}
                      style={{
                        visibility: selected ? 'visible' : 'hidden'
                      }}
                    >
                      <XClose />
                    </SvgIcon>
                  </li>
                )
              }}
              options={[...optionsProp].sort((a, b) => {
                // Display the selected labels first.
                let ai = options.indexOf(a)
                ai = ai === -1 ? options.length + optionsProp.indexOf(a) : ai
                let bi = options.indexOf(b)
                bi = bi === -1 ? options.length + optionsProp.indexOf(b) : bi
                return ai - bi
              })}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  variant='outlined'
                  size='medium'
                  fullWidth
                  autoFocus
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  label='Filter tags'
                />
              )}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>

      <Box
        className='TagPicker'
        sx={[
          {
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            alignItems: 'center'
          },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
      >
        <Button
          className='TagPicker-Button TagPicker-TagsButton'
          size={size}
          variant='contained'
          color='primary'
          endIcon={
            <SvgIcon>
              <ChevronDown />
            </SvgIcon>
          }
          sx={{
            whiteSpace: 'nowrap',
            textTransform: 'capitalize',
            '> .MuiButton-endIcon': {
              ml: '4px'
            }
          }}
          onClick={handleClick}
        >
          Tags
        </Button>

        {Array.from(options).map((option, index) => {
          return (
            <Tooltip
              key={`OptionItem-${index}`}
              title={tagTitle[option.type]}
            >
              <Button
                className='TagPicker-Button'
                size={size}
                disableRipple
                disableTouchRipple
                disableFocusRipple
                disableElevation
                color={tagVariant[option?.type]}
                sx={{
                  textTransform: 'none',
                  textAlign: 'left',
                  '> .MuiButton-endIcon': {
                    ml: '4px'
                  }
                }}
                endIcon={
                  <IconButton
                    component='div'
                    aria-label='delete'
                    sx={{ color: 'inherit', padding: '2px' }}
                    onClick={(event) => handleOptionRemove(event, option)}
                  >
                    <SvgIcon sx={{ fontSize: '16px !important' }}>
                      <XClose />
                    </SvgIcon>
                  </IconButton>
                }
              >
                {option?.name}
              </Button>
            </Tooltip>
          )
        })}
      </Box>
    </>
  )
}

const TagPickerPropTypes = {
  filterOnSearch: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.array,
  inputValue: PropTypes.any,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func
}

TagPicker.propTypes = TagPickerPropTypes

export { TagPicker, TagPickerPropTypes }
export default TagPicker

const tagVariant = {
  coverageArea: 'primary',
  featureType: 'warning',
  topic: 'info'
}

const tagTitle = {
  coverageArea: 'Coverage Area',
  featureType: 'Feature Type',
  topic: 'Topic'
}
