import {
  ChipProps,
  ClickAwayListener,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Chip as MuiChip,
  Paper,
  Popper,
  SvgIcon,
  Typography
} from '@mui/material'
import { Box } from '@mui/system'
import {
  ChevronDown,
  ChevronUp,
  FilterFunnel01,
  Map01
} from '@untitled-ui/icons-react/build/esm'
import { FC } from 'react'
import { getFilterLabel } from 'utils'
import { usePopover } from '~ui-components/hooks/usePopover'

export type MapVisualisationChipsProps = {
  shouldUseCollapse: boolean
  options: Array<any>
  value: string
  onChange: (value: string) => void
}
export const MapVisualisationChips: FC<MapVisualisationChipsProps> = ({
  shouldUseCollapse,
  options = [],
  value,
  onChange
}) => {
  const popover = usePopover<SVGSVGElement>()

  const onChipClick = () =>
    popover.open ? popover.handleClose() : popover.handleOpen()

  return (
    <>
      {shouldUseCollapse ? (
        <>
          <LimitedWidthChip
            data-testid='MapVisualisationCollapseChip'
            label={value}
            icon={
              <SvgIcon
                ref={popover.anchorRef} // since giving ref to chip is not working
                fontSize='small'
              >
                <Map01 />
              </SvgIcon>
            }
            onClick={onChipClick}
            onDelete={onChipClick}
            deleteIcon={
              <SvgIcon fontSize='small'>
                {popover.open ? <ChevronUp /> : <ChevronDown />}
              </SvgIcon>
            }
            variant='filled'
            color='secondary'
          />
          {popover.open && (
            <ClickAwayListener onClickAway={popover.handleClose}>
              <Popper
                disablePortal
                anchorEl={popover.anchorRef.current}
                placement='bottom-start'
                open={!!popover.open}
              >
                <Paper sx={{ p: 1, ml: '-5px', mt: '6px' }}>
                  {options
                    .filter((option) => option.value !== value)
                    .map((opt) => (
                      <ListItemButton
                        key={opt.value}
                        onClick={() => {
                          onChange(opt.value)
                          popover.handleClose()
                        }}
                        sx={{ borderRadius: 1, px: 1, py: 0.5 }}
                      >
                        <ListItemIcon>
                          <SvgIcon fontSize='small'>
                            <Map01 />
                          </SvgIcon>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant='body1'>{opt.value}</Typography>
                          }
                        />
                      </ListItemButton>
                    ))}
                </Paper>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      ) : (
        options.map((option) => (
          <LimitedWidthChip
            key={option.value}
            data-testid='MapVisualisationChip'
            label={option.label}
            icon={
              <SvgIcon fontSize='small'>
                <Map01 />
              </SvgIcon>
            }
            variant='filled'
            color={value === option.value ? 'secondary' : 'default'}
            onClick={() => onChange(option.value)}
          />
        ))
      )}
    </>
  )
}

export type MapFilterChipsProps = {
  shouldUseCollapse: boolean
  filters: Array<any>
  onClick: () => void
}
export const MapFilterChips: FC<MapFilterChipsProps> = ({
  shouldUseCollapse,
  filters,
  onClick
}) => {
  return shouldUseCollapse ? (
    <LimitedWidthChip
      data-testid='MapFilterCollapseChip'
      label={`Filters (${filters.length})`}
      icon={
        <SvgIcon fontSize='small'>
          <FilterFunnel01 />
        </SvgIcon>
      }
      onClick={onClick}
      onDelete={onClick}
      deleteIcon={
        <SvgIcon fontSize='small'>
          <ChevronDown />
        </SvgIcon>
      }
      variant='filled'
      color='primary'
    />
  ) : (
    filters?.map((filter) => (
      <LimitedWidthChip
        key={filter.id}
        data-testid='MapFilterChip'
        label={getFilterLabel(filter)}
        icon={
          <SvgIcon fontSize='small'>
            <FilterFunnel01 />
          </SvgIcon>
        }
        onClick={onClick}
        variant='filled'
        color='primary'
      />
    ))
  )
}

type LimitedWidthChipProps = ChipProps
const LimitedWidthChip = (props: LimitedWidthChipProps) => {
  const { label, ...rest } = props

  return (
    <MuiChip
      label={
        <Box
          sx={[
            (theme) => ({
              fontWeight: 600,
              maxWidth: '150px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              [theme.breakpoints.down('mobile')]: {
                maxWidth: 'calc(50dvw - 84px)'
              }
            })
          ]}
        >
          {label}
        </Box>
      }
      {...rest}
    />
  )
}
