import PropTypes from 'prop-types'
import Box from '~ui-components/emails/components/atoms/Box'
import Item from '~ui-components/emails/components/atoms/Item'
import styles from '~ui-components/emails/styles'

/**
 * Inspired by http://htmlemailboilerplate.com
 * Default nested 600px wide outer table container (see http://templates.mailchimp.com/development/html/)
 */
const Email = (props) => {
  const { lang = 'en', title, headCSS, width = '600', style, children } = props

  return (
    <html
      lang={lang}
      xmlns='http://www.w3.org/1999/xhtml'
    >
      <head>
        <meta
          httpEquiv='Content-Type'
          content='text/html; charset=utf-8'
        />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0'
        />
        <title>{title}</title>
        <link
          href='https://fonts.googleapis.com/css2?family=Inter&display=swap'
          rel='stylesheet'
        />
        <style type='text/css'>
          {styles}
          {headCSS}
        </style>
      </head>
      <body
        style={{
          width: '100%',
          margin: 0,
          padding: 0,
          WebkitTextSizeAdjust: '100%',
          MsTextSizeAdjust: '100%'
        }}
      >
        <Box
          width='100%'
          height='100%'
        >
          <Item>
            <Box
              width={width}
              align='center'
              style={style}
            >
              {children}
            </Box>
          </Item>
        </Box>
      </body>
    </html>
  )
}

export { Email }
export default Email

Email.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  style: PropTypes.object,
  headCSS: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node
}
