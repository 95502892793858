import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  /**
   * Error code that happened in map-viewer.
   */
  error: null
}

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload
    },
    clearError: (state) => {
      state.error = null
    }
  }
})

export const error = (state) => state.error.error

/**
 * Hook for retrieving error
 * @returns {string} error
 */
export const useError = () => {
  return useSelector(error)
}

export const { setError, clearError } = errorSlice.actions

export default errorSlice.reducer
