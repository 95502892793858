import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'

import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'

import Typography from '@mui/material/Typography'
import Upload01Icon from '@untitled-ui/icons-react/build/esm/Upload01'

import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'

import type { FC } from 'react'
import type { DropzoneOptions, FileWithPath } from 'react-dropzone'

export type File = FileWithPath

interface ThumbnailUploadProps extends DropzoneOptions {
  caption?: string
  fileUrl?: string
  onRemove?: (file: File) => void
}

export const ThumbnailUpload: FC<ThumbnailUploadProps> = (props) => {
  const { caption, fileUrl, onRemove, ...other } = props
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...other,
    multiple: false
  })

  if (fileUrl)
    return (
      <div>
        <Box
          sx={{
            alignItems: 'center',
            border: 1,
            borderRadius: 1,
            borderStyle: 'dashed',
            borderColor: 'divider',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            outline: 'none',
            ...(isDragActive && {
              backgroundColor: 'action.active',
              opacity: 0.5
            }),
            '&:hover': {
              backgroundColor: 'action.hover',
              cursor: 'pointer',
              opacity: 0.5
            }
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            gap={2}
            position='relative'
            width='100%'
          >
            <Box
              width='162px'
              height='162px'
              sx={{
                backgroundImage: `url(${fileUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                borderRadius: 1
              }}
            >
              <Avatar sx={{ height: 64, width: 64 }}>
                <SvgIcon>
                  <Upload01Icon />
                </SvgIcon>
              </Avatar>
              <Stack spacing={1}>
                <Typography
                  sx={{ '& span': { textDecoration: 'underline' } }}
                  variant='h6'
                >
                  <span>Click to upload</span> or drag and drop
                </Typography>
                {caption && <Typography variant='body2'>{caption}</Typography>}
              </Stack>
            </Box>
          </Box>
        </Box>
      </div>
    )

  return (
    <div>
      <Box
        sx={{
          alignItems: 'center',
          border: 1,
          borderRadius: 1,
          borderStyle: 'dashed',
          borderColor: 'divider',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          outline: 'none',
          p: 6,
          ...(isDragActive && {
            backgroundColor: 'action.active',
            opacity: 0.5
          }),
          '&:hover': {
            backgroundColor: 'action.hover',
            cursor: 'pointer',
            opacity: 0.5
          }
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Stack
          alignItems='center'
          direction='row'
          spacing={2}
        >
          <Avatar
            sx={{
              height: 64,
              width: 64
            }}
          >
            <SvgIcon>
              <Upload01Icon />
            </SvgIcon>
          </Avatar>
          <Stack spacing={1}>
            <Typography
              sx={{
                '& span': {
                  textDecoration: 'underline'
                }
              }}
              variant='h6'
            >
              <span>Click to upload</span> or drag and drop
            </Typography>
            {caption && (
              <Typography
                color='text.secondary'
                variant='body2'
              >
                {caption}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Box>
    </div>
  )
}

ThumbnailUpload.propTypes = {
  caption: PropTypes.string,
  fileUrl: PropTypes.string,
  onRemove: PropTypes.func,
  // From Dropzone
  accept: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  ),
  disabled: PropTypes.bool,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  preventDropOnDocument: PropTypes.bool
}
