import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import SvgIcon from '@mui/material/SvgIcon'
import SearchMdIcon from '@untitled-ui/icons-react/build/cjs/SearchMd'
import type { FC } from 'react'
import React, { useRef } from 'react'

type SearchInputProps = {
  autoFocus?: boolean
  cursor?: string
  onChange?: (value: string) => void
  onSubmit?: () => void
}

export const SearchInput: FC<SearchInputProps> = (props) => {
  const {
    onChange = () => {},
    onSubmit = () => {},
    cursor = 'text',
    autoFocus = true
  } = props
  const inputRef = useRef(null)

  const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    onSubmit()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = event.target.value
    onChange(nextValue)
  }

  return (
    <Box
      component='form'
      sx={{
        alignItems: 'center',
        display: 'flex',
        flex: 1
      }}
      onSubmit={handleSubmit}
    >
      <OutlinedInput
        autoFocus={autoFocus}
        onChange={handleChange}
        inputProps={{
          style: { cursor },
          'data-testid': 'MapSearchModal-FormInput'
        }}
        inputRef={inputRef}
        autoComplete='off'
        fullWidth
        sx={{
          cursor
        }}
        placeholder='Try population map, house price map, anything!'
        startAdornment={
          <InputAdornment position='start'>
            <SvgIcon>
              <SearchMdIcon />
            </SvgIcon>
          </InputAdornment>
        }
      />
    </Box>
  )
}
