import validator from 'data-validator'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { DataUpload as CoreDataUpload } from '~map-creator/core/App/CreateMap/Steps/DataUpload'
import { validateGeojsonFile } from '~map-creator/map-creator-geojson/utilities/geojsonFile'

const DataUploadGeojson = (props) => {
  const { limitSizeAsMb } = props
  const handleFileValidate = async (file, callback) => {
    const fileCheckResponse = await validateGeojsonFile(file, limitSizeAsMb)

    if (!fileCheckResponse.data) return callback(fileCheckResponse)

    const geojsonCheckResponse = validator.geojson.validate(
      fileCheckResponse.data,
      {
        fileName: file.name
      }
    )

    if (!isEmpty(geojsonCheckResponse.errors))
      return callback(geojsonCheckResponse)

    return file
  }

  return (
    <CoreDataUpload
      onValidate={handleFileValidate}
      {...props}
    />
  )
}

DataUploadGeojson.propTypes = {
  limitSizeAsMb: PropTypes.number,
  onValidated: PropTypes.func
}

DataUploadGeojson.defaultProps = {
  limitSizeAsMb: 50
}

export default DataUploadGeojson
export { DataUploadGeojson }
