import bbox from '@turf/bbox'
import centroid from '@turf/centroid'
import { del, set } from 'idb-keyval'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { StoreContext } from '~map-creator/core/states/context'
import {
  reset as resetGeojson,
  setBbox,
  setCentroid,
  setDefaultPropertyConfigs,
  setGeojsonFileId,
  setUniquePropertiesKeys,
  useGeojsonFileId
} from '~map-creator/core/states/geojson'
import {
  reset as resetMap,
  setCurrentBounds
} from '~map-creator/core/states/map'
import {
  reset as resetProperties,
  setConfigurations
} from '~map-creator/core/states/properties'
import { utils } from './utils'
import { View } from './view'

const saveGeojsonToStorage = (store, geojsonObject, allowsUniqueLimit) => {
  const center = centroid(geojsonObject)
  const bboxValue = bbox(geojsonObject)
  store.dispatch(setCentroid(center.geometry.coordinates))
  store.dispatch(setBbox(bboxValue))

  const { geojsonProperties, categoryProperties, uniqueProperties } =
    utils.processGeojsonProperties(geojsonObject, allowsUniqueLimit)

  const { defaultConfigs, configs } = utils.getPropertiesConfigs(
    geojsonProperties,
    categoryProperties
  )

  const [w, s, e, n] = bboxValue
  store.dispatch(setCurrentBounds({ w, s, e, n }))
  store.dispatch(setDefaultPropertyConfigs(defaultConfigs))
  store.dispatch(setConfigurations(configs))
  store.dispatch(setUniquePropertiesKeys(uniqueProperties))
}

const storeGeojsonFile = async (store, geojsonId, file, allowsUniqueLimit) => {
  if (geojsonId) {
    await clearFilesFromIndexedDB(store, geojsonId)
  }
  const id = uuidv4()
  const geojsonFilename = `${id}:geojson`

  // INFO: Replace \\n that cause error in property search
  const geojsonObject = JSON.parse((await file.text()).replace(/\\n/g, ' '))

  await set(geojsonFilename, geojsonObject)
  store.dispatch(setGeojsonFileId(id))
  saveGeojsonToStorage(store, geojsonObject, allowsUniqueLimit)
}

const clearFilesFromIndexedDB = async (store, id) => {
  await del(`${id}:geojson`)
  store.dispatch(resetGeojson())
  store.dispatch(resetProperties())
  store.dispatch(resetMap())
}

const DataUpload = (props) => {
  const store = useContext(StoreContext)
  const { acceptTypes, allowsUniqueLimit, onValidate, onValidated } = props
  const [alerts, setAlerts] = useState([])
  const clearAlerts = () => setAlerts([])
  const geojsonFileId = useGeojsonFileId()

  const validateCallback = (response) => {
    utils.catchErrors(response, setAlerts)
  }

  const handleFileChange = async (file) => {
    await storeGeojsonFile(store, geojsonFileId, file, allowsUniqueLimit)
    onValidated()
  }

  const handleFileValidate = async (files) => {
    clearAlerts()
    const file = await onValidate(files[0], validateCallback)
    return { file }
  }

  const handleFileDelete = (files) => {
    if (isEmpty(files)) {
      clearFilesFromIndexedDB(store, geojsonFileId)
    }
  }

  return (
    <View
      acceptTypes={acceptTypes}
      alerts={alerts}
      onFileChange={handleFileChange}
      onFileDelete={handleFileDelete}
      onFileValidate={handleFileValidate}
    />
  )
}

DataUpload.propTypes = {
  allowsUniqueLimit: PropTypes.number,
  onValidated: PropTypes.func,
  acceptTypes: PropTypes.string,
  onValidate: PropTypes.func
}

DataUpload.defaultProps = {
  acceptTypes: '.geojson',
  allowsUniqueLimit: 10,
  onValidated: () => {
    return { file: null }
  }
}

export { DataUpload }
export default DataUpload
