import { InfoCircle } from '@untitled-ui/icons-react'
import {
  IconListItem,
  IconListItemProps
} from '~ui-components/components/molecules/IconListItem'

function MapAboutButton(props: IconListItemProps) {
  return (
    <IconListItem
      primary='About'
      icon={InfoCircle}
      {...props}
    />
  )
}

export { MapAboutButton }
