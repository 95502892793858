const geometryTypes = [
  'Point',
  'MultiPoint',
  'LineString',
  'MultiLineString',
  'Polygon',
  'MultiPolygon',
  'GeometryCollection'
]

let dim = 2
let e = Math.pow(10, 6)
let keys = []
let values = []
let lengths = null

const init = () => {
  dim = 2
  e = Math.pow(10, 6)
  keys = []
  values = []
  lengths = null
}

const reset = () => {
  keys = null
}

const decode = (pbf) => {
  init()
  const geojson = pbf.readFields(readDataField, {})
  reset()
  return geojson
}

const readDataField = (tag, obj, pbf) => {
  if (tag === 1) keys.push(pbf.readString())
  else if (tag === 2) dim = pbf.readVarint()
  else if (tag === 3) e = Math.pow(10, pbf.readVarint())
  else if (tag === 4) readFeatureCollection(pbf, obj)
  else if (tag === 5) readFeature(pbf, obj)
  else if (tag === 6) readGeometry(pbf, obj)
}

const readFeatureCollection = (pbf, geojson) => {
  geojson.type = 'FeatureCollection'
  geojson.features = []
  return pbf.readMessage(readFeatureCollectionField, geojson)
}

const readFeature = (pbf, feature) => {
  feature.type = 'Feature'
  const f = pbf.readMessage(readFeatureField, feature)
  if (!('geometry' in f)) f.geometry = null
  return f
}

const readGeometry = (pbf, geometry) => {
  geometry.type = 'Point'
  return pbf.readMessage(readGeometryField, geometry)
}

const readFeatureCollectionField = (tag, geojson, pbf) => {
  if (tag === 1) geojson.features.push(readFeature(pbf, {}))
  else if (tag === 13) values.push(readValue(pbf))
  else if (tag === 15) readProps(pbf, geojson)
}

const readFeatureField = (tag, feature, pbf) => {
  if (tag === 1) feature.geometry = readGeometry(pbf, {})
  else if (tag === 11) feature.id = pbf.readString()
  else if (tag === 12) feature.id = pbf.readSVarint()
  else if (tag === 13) values.push(readValue(pbf))
  else if (tag === 14) feature.properties = readProps(pbf, {})
  else if (tag === 15) readProps(pbf, feature)
}

const readGeometryField = (tag, geometry, pbf) => {
  if (tag === 1) geometry.type = geometryTypes[pbf.readVarint()]
  else if (tag === 2) lengths = pbf.readPackedVarint()
  else if (tag === 3) readCoords(geometry, pbf, geometry.type)
  else if (tag === 4) {
    geometry.geometries = geometry.geometries || []
    geometry.geometries.push(readGeometry(pbf, {}))
  } else if (tag === 13) values.push(readValue(pbf))
  else if (tag === 15) readProps(pbf, geometry)
}

const readCoords = (geometry, pbf, type) => {
  if (type === 'Point') geometry.coordinates = readPoint(pbf)
  else if (type === 'MultiPoint') geometry.coordinates = readLine(pbf)
  else if (type === 'LineString') geometry.coordinates = readLine(pbf)
  else if (type === 'MultiLineString') geometry.coordinates = readMultiLine(pbf)
  else if (type === 'Polygon') geometry.coordinates = readMultiLine(pbf, true)
  else if (type === 'MultiPolygon') geometry.coordinates = readMultiPolygon(pbf)
}

const readValue = (pbf) => {
  const end = pbf.readVarint() + pbf.pos
  let value = null

  while (pbf.pos < end) {
    const val = pbf.readVarint()
    const tag = val >> 3

    if (tag === 1) value = pbf.readString()
    else if (tag === 2) value = pbf.readDouble()
    else if (tag === 3) value = pbf.readVarint()
    else if (tag === 4) value = -pbf.readVarint()
    else if (tag === 5) value = pbf.readBoolean()
    else if (tag === 6) value = JSON.parse(pbf.readString())
  }
  return value
}

const readProps = (pbf, props) => {
  const end = pbf.readVarint() + pbf.pos
  while (pbf.pos < end) props[keys[pbf.readVarint()]] = values[pbf.readVarint()]
  values = []
  return props
}

const readPoint = (pbf) => {
  const end = pbf.readVarint() + pbf.pos
  const coords = []
  while (pbf.pos < end) coords.push(pbf.readSVarint() / e)
  return coords
}

const readLinePart = (pbf, end, len, closed) => {
  let i = 0
  let p
  let d

  const coords = []
  const prevP = []
  for (d = 0; d < dim; d++) prevP[d] = 0

  while (len ? i < len : pbf.pos < end) {
    p = []
    for (d = 0; d < dim; d++) {
      prevP[d] += pbf.readSVarint()
      p[d] = prevP[d] / e
    }
    coords.push(p)
    i++
  }
  if (closed) coords.push(coords[0])

  return coords
}

const readLine = (pbf) => {
  return readLinePart(pbf, pbf.readVarint() + pbf.pos)
}

const readMultiLine = (pbf, closed) => {
  const end = pbf.readVarint() + pbf.pos
  if (!lengths) return [readLinePart(pbf, end, null, closed)]

  const coords = []
  for (let i = 0; i < lengths.length; i++)
    coords.push(readLinePart(pbf, end, lengths[i], closed))
  lengths = null
  return coords
}

const readMultiPolygon = (pbf) => {
  const end = pbf.readVarint() + pbf.pos
  if (!lengths) return [[readLinePart(pbf, end, null, true)]]

  const coords = []
  let j = 1
  for (let i = 0; i < lengths[0]; i++) {
    let rings = []
    for (let k = 0; k < lengths[j]; k++)
      rings.push(readLinePart(pbf, end, lengths[j + 1 + k], true))
    j += lengths[j] + 1
    coords.push(rings)
  }
  lengths = null
  return coords
}

const geobuf = { decode }
export { geobuf }
export default geobuf
