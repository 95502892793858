import React from 'react'
import { useDispatch } from 'react-redux'

import { useUniquePropertiesKeys } from '~map-creator/core/states/geojson'
import {
  setUniquePropertyKey,
  useConfigurations,
  useUniquePropertyKey
} from '~map-creator/core/states/properties'

import { View } from './view'

const SelectUniqueIdentifier = () => {
  const dispatch = useDispatch()

  const configurations = useConfigurations()
  const uniqueProperties = useUniquePropertiesKeys()
  const uniquePropertyName = useUniquePropertyKey()
  const uniquePropertiesOptions = uniqueProperties.map((k) => ({
    label: configurations[k].name,
    value: k
  }))

  const handleUniquePropertyChange = (arr) => {
    const value = arr.pop()
    dispatch(setUniquePropertyKey(value))
  }

  // to allow for ToggleChipInput multiple mode, cos we need to be able to deselect
  const selected = uniquePropertyName ? [uniquePropertyName] : []

  return (
    <View
      selected={selected}
      options={uniquePropertiesOptions}
      onChange={handleUniquePropertyChange}
    />
  )
}

export { SelectUniqueIdentifier }
export default SelectUniqueIdentifier
