import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

import ToggleChipInput from '~ui-components/components/molecules/ToggleChipInput'

const SelectDefaultView = (props) => {
  const { selected, properties, onChange } = props

  return (
    <Box data-testid='CreateMap-SelectDefaultView'>
      <ToggleChipInput
        data={properties}
        value={selected}
        onChange={onChange}
      />
    </Box>
  )
}

const SelectDefaultViewPropTypes = {
  properties: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.string
}

SelectDefaultView.propTypes = SelectDefaultViewPropTypes

export { SelectDefaultView, SelectDefaultViewPropTypes }
export default SelectDefaultView
