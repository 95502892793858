import Box from '@mui/material/Box'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import Lightbulb04Icon from '@untitled-ui/icons-react/build/esm/Lightbulb04'
import PropTypes from 'prop-types'
import type { FC } from 'react'

interface TipProps {
  message: string
}

export const Tip: FC<TipProps> = (props) => {
  const { message } = props

  return (
    <Box
      sx={(theme) => ({
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        textAlign: 'baseline'
      })}
    >
      <SvgIcon
        color='action'
        sx={{ mr: 1, ml: 1 }}
      >
        <Lightbulb04Icon />
      </SvgIcon>
      <Typography
        color='text.secondary'
        sx={{
          alignItems: 'center',
          display: 'flex',
          fontWeight: 700
        }}
        variant='caption'
      >
        Tip.
      </Typography>
      <Typography
        color='text.secondary'
        variant='caption'
        sx={{ ml: 1 }}
      >
        {message}
      </Typography>
    </Box>
  )
}

Tip.propTypes = {
  message: PropTypes.string.isRequired
}
