import { MS_UUID } from '../constants'

export const getFilterPayload = (filterIds = []) => {
  return { filter: ['in', ['get', MS_UUID], ['literal', filterIds]] }
}

export const getClusterFilterPayload = (filterIds = []) => {
  return {
    filter: [
      'any',
      ...filterIds.map((uuid) => ['==', ['get', 'MS_uuid'], uuid])
    ]
  }
}
