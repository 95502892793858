import React from 'react'
import { DataUpload, DataUploadPropTypes } from 'ui-components'

const View = (props) => {
  return <DataUpload {...props} />
}

View.propTypes = DataUploadPropTypes

export { View }
