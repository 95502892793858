import { CircularProgress, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { CreateMapLayout } from '~ui-components/components/organisms/MapCreatorPanels/CreateMapLayout'

const MapCreatorStep = (props) => {
  const {
    loading,
    isWorkspaceMember,
    steps,
    currentStep,
    isSubmitting,
    isError = false,
    ...rest
  } = props

  const currentStepProps = useMemo(() => {
    return steps[currentStep] || {}
  }, [steps, currentStep])

  const MapCreatorStepProps = {
    steps,
    currentStep,
    isSubmitting,
    ...currentStepProps,
    ...rest
  }

  if (loading)
    return (
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </Stack>
    )

  if (!isWorkspaceMember) {
    return (
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography>
          You do not have permission to create a map for this workspace
        </Typography>
      </Stack>
    )
  }

  if (isError) {
    return (
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography>Something went wrong</Typography>
      </Stack>
    )
  }

  return <CreateMapLayout {...MapCreatorStepProps} />
}

const MapCreatorStepPropsTypes = {
  missRows: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  isWorkspaceMember: PropTypes.bool,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node
    })
  ),
  currentStep: PropTypes.number,
  isSubmitting: PropTypes.bool,
  disabledBack: PropTypes.bool,
  disabledNext: PropTypes.bool,
  noBack: PropTypes.bool,
  noNext: PropTypes.bool,
  onBackClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  displayStep: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number
  })
}

MapCreatorStep.propTypes = MapCreatorStepPropsTypes

export { MapCreatorStep, MapCreatorStepPropsTypes }
export default MapCreatorStep
