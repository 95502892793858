/* eslint-disable @typescript-eslint/ban-ts-comment */
// TODO: fix TS errors

import { MapMetadata as Layout } from 'ui-components'
import { useHasParentMap } from '~map-viewer/states/map'
import { CreatedAt } from './CreatedAt'
import { License } from './License'
import { Origin } from './Origin'
import { Profile } from './Profile'
import { Remixes } from './Remixes'
import { Source } from './Source'
import { Views } from './Views'

function MapMetadata() {
  const hasParentMap = useHasParentMap()

  return (
    // @ts-ignore
    <Layout
      hasParentMap={hasParentMap}
      slots={{
        Profile,
        Views,
        Remixes,
        CreatedAt,
        License,
        Source,
        Origin
      }}
    />
  )
}

export { MapMetadata }
