import { LAYER_FILL_HIGHLIGHT_COLORS } from '../../assets/Colors'
import { PROMOTED_COLUMN_ID } from '../../assets/Constants'

const getFilterPayload = (filterArray?: Array<string>) => {
  return Array.isArray(filterArray)
    ? { filter: ['in', ['get', PROMOTED_COLUMN_ID], ['literal', filterArray]] }
    : {}
}

const getFilterBorderStyle = (filteredIds) => {
  const filterArray = filteredIds || ['']
  return {
    type: 'line',
    paint: {
      'line-width': 2,
      'line-color': LAYER_FILL_HIGHLIGHT_COLORS[0]
    },
    filter: ['in', ['get', PROMOTED_COLUMN_ID], ['literal', filterArray]]
  }
}

export { getFilterBorderStyle, getFilterPayload }
