const MAP_SOURCE = 'geojson-source'
const BEFORE_ID = 'mapstack-layer'
const TYPE = 'geojson'
const PROMOTE_ID = 'uuid'
const POPUP_SELECTION_LAYER_ID = 'popup-selection'

const MAP_EDITOR_STEPS = {
  INCLUDE_PROPERTIES: 0,
  SELECT_DEFAULT_VIEW: 1,
  MAP_SETTINGS: 2
}

const MAP_CREATOR_GEOJSON_STEPS = {
  DATA_UPLOAD: 0,
  UPLOAD_SUCCESS: 1,
  DEFINE_MAP_AREA: 2,
  DEFINE_MAP_FEATURE: 3,
  INCLUDE_PROPERTIES: 4,
  DEFINE_FEATURE_TITLE: 5,
  SELECT_UNIQUE_IDENTIFIER: 6,
  SELECT_VISUALISATION_PROPERTIES: 7,
  SELECT_DEFAULT_VIEW: 8,
  MAP_SETTINGS: 9
}

const MAP_CREATOR_CSV_STEPS = {
  DATA_UPLOAD: 0,
  INCLUDE_PROPERTIES: 1,
  SELECT_DEFAULT_VIEW: 2,
  MAP_SETTINGS: 3
}

const ERROR_OBJECT = {
  NOT_SUPPORT_FILE: {
    key: 'NOT_SUPPORT_FILE',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: 'You must upload file with .geojson extension!',
    errors: ['You must upload file with .geojson extension!']
  }
}

const getOverSizeErrorObj = (size) => {
  return {
    key: 'FILE_ABOVE_LIMIT_SIZE',
    title: 'GeoJSON Invalid',
    type: 'error',
    description: `You must upload file with size below ${size}Mb!`,
    errors: [`You must upload file with size below ${size}Mb!`]
  }
}

const MAX_PROPERTIES_ALLOWED = 10

const INCLUDE_PROPERTIES_ERRORS = {
  MAX_PROPERTIES_REACHED: `Map properties are limited to ${MAX_PROPERTIES_ALLOWED}.`,
  NO_VISUALISATION_SELECTED:
    'At least one number or category property must be selected.',
  DUPLICATE_PROPERTY_NAME: 'Map properties can not be duplicated.',
  EMPTY_PROPERTY_NAME: 'Map property cannot be empty.'
}

const PROPERTY_TYPE_OPTIONS = {
  text: { label: 'Text', value: 'text' },
  integer: { label: 'Number', value: 'integer' },
  float: { label: 'Decimal', value: 'float' },
  date: { label: 'Date', value: 'date' }
}

const TAG_TYPES = {
  COVERAGE_AREA: 'coverageArea',
  FEATURE_TYPE: 'featureType',
  TOPIC: 'topic'
}

export {
  getOverSizeErrorObj,
  ERROR_OBJECT,
  MAP_SOURCE,
  BEFORE_ID,
  POPUP_SELECTION_LAYER_ID,
  TYPE,
  PROMOTE_ID,
  MAP_CREATOR_CSV_STEPS,
  MAP_CREATOR_GEOJSON_STEPS,
  MAP_EDITOR_STEPS,
  MAX_PROPERTIES_ALLOWED,
  INCLUDE_PROPERTIES_ERRORS,
  PROPERTY_TYPE_OPTIONS,
  TAG_TYPES
}
