import { Box } from '@mui/material'
import { ToggleChipInput } from '~ui-components/components/molecules/ToggleChipInput'

const Visualisations = (props) => {
  const {
    visualisationOptions,
    activeVisualisationKey,
    onVisualisationChange
  } = props

  return (
    <Box
      sx={{ ml: '8px', mt: '8px', maxWidth: 'calc(100% - 64px) !important' }}
    >
      <ToggleChipInput
        data={visualisationOptions}
        value={activeVisualisationKey}
        onChange={onVisualisationChange}
      />
    </Box>
  )
}

export { Visualisations }
export default Visualisations
