import { configureStore } from '@reduxjs/toolkit'
import { withReduxConfigs } from 'utils'

import mapSliceReducer from '~map-feature-info/states/map'

const createStore = () => {
  return configureStore(
    withReduxConfigs({
      reducer: {
        map: mapSliceReducer
      }
    })
  )
}

export { createStore }
