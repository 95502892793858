import { BarChart10 } from '@untitled-ui/icons-react'
import { getViewCopy } from 'utils'
import { IconLabel } from '~ui-components/components/molecules/IconLabel'

interface MapMetadataViewsProps {
  views: number
}

function MapMetadataViews(props: MapMetadataViewsProps) {
  const { views = 0 } = props
  return (
    <IconLabel
      icon={BarChart10}
      primaryText={getViewCopy(views)}
    />
  )
}

export { MapMetadataViews }
