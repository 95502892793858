import React from 'react'
import { useDispatch } from 'react-redux'

import { setLicense, useLicense } from '~map-creator/core/states/map'

import View from './view'

const MapDataLicenseInput = () => {
  const dispatch = useDispatch()

  const license = useLicense()

  const handleChange = (val) => {
    dispatch(setLicense(val))
  }

  return (
    <View
      value={{ label: license.name, url: license.url }}
      onChange={handleChange}
    />
  )
}

export default MapDataLicenseInput
export { MapDataLicenseInput }
