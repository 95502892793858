import { Box, colors } from '@mui/material'
import PropTypes from 'prop-types'

const Slot = (props) => {
  return (
    <Box
      width='100%'
      height='100%'
      borderRadius={1}
      border={
        props.debug ? ['dashed', '1px', colors.grey[600]].join(' ') : 'none'
      }
      fontStyle='italic'
      display='flex'
      justifyContent='center'
      alignItems='center'
      whiteSpace='nowrap'
      {...props}
    >
      {props.text || props.children}
    </Box>
  )
}

Slot.propTypes = {
  debug: PropTypes.bool,
  text: PropTypes.any
}

export { Slot }
export default Slot
