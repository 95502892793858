import { useRef, useState } from 'react'

import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses
} from '@mui/material'

import { Check, ChevronDown, Globe04, Lock01 } from '@untitled-ui/icons-react'

interface AccessLevelSettingProps {
  canUpdateAccess: boolean
  value?: boolean
  onChange?: (event: React.MouseEvent<HTMLElement>, value: boolean) => void
}

function AccessLevelSetting(props: AccessLevelSettingProps) {
  const { canUpdateAccess, value = false, onChange } = props

  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event, target) => {
    if (!canUpdateAccess) return
    setAnchorEl(target)
  }

  const handleChange = async (event, value) => {
    setLoading(true)
    await onChange?.(event, value)
    setAnchorEl(null)
    setLoading(false)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <AccessLevelPopover
        anchorEl={anchorEl}
        value={value}
        open={Boolean(anchorEl)}
        onChange={handleChange}
        onClose={handleClose}
      />

      <Stack
        sx={{ width: '100%' }}
        spacing={1}
      >
        <Typography
          color='text.secondary'
          variant='overline'
        >
          General access
        </Typography>

        {value ? (
          <AccessSettingItem
            key='AccessSettingItem'
            color='primary'
            loading={loading}
            canUpdateAccess={canUpdateAccess}
            icon={Globe04}
            label='Public access'
            description='Anyone can find and access'
            onClick={handleClick}
          />
        ) : (
          <AccessSettingItem
            key='AccessSettingItem'
            color='error'
            loading={loading}
            canUpdateAccess={canUpdateAccess}
            icon={Lock01}
            label='Resticted access'
            description='Only team members and guests'
            onClick={handleClick}
          />
        )}
      </Stack>
    </>
  )
}

function AccessLevelPopover(props) {
  const { canUpdateAccess, value, open, anchorEl, onClose, onChange } = props

  const handleClick = (event, value) => {
    onChange?.({ type: 'change' }, value)
    onClose?.()
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{
        sx: {
          width: 240
        }
      }}
      sx={{
        zIndex: (theme) => theme.zIndex.tooltip + 1
      }}
    >
      <Box sx={{ p: 1 }}>
        <AccessLevelItem
          value={true}
          checked={value}
          canUpdateAccess={canUpdateAccess}
          primaryText='Public access'
          onClick={handleClick}
        />

        <AccessLevelItem
          value={false}
          checked={!value}
          canUpdateAccess={canUpdateAccess}
          primaryText='Restricted access'
          onClick={handleClick}
        />
      </Box>
    </Popover>
  )
}

function AccessLevelItem(props) {
  const { value, checked, primaryText, onClick } = props

  return (
    <ListItemButton
      sx={{
        borderRadius: 1,
        px: 1,
        py: 0.5
      }}
      onClick={(event) => onClick(event, value)}
    >
      <ListItemIcon>
        <SvgIcon
          fontSize='small'
          sx={{ visibility: !checked ? 'hidden' : 'visible' }}
        >
          <Check />
        </SvgIcon>
      </ListItemIcon>

      <ListItemText
        primary={<Typography variant='body1'>{primaryText}</Typography>}
      />
    </ListItemButton>
  )
}

function AccessSettingItem(props) {
  const {
    color,
    icon: Icon,
    loading,
    canUpdateAccess,
    label,
    description,
    onClick
  } = props

  const ref = useRef(null)

  return (
    <Table
      sx={{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: 'none'
        }
      }}
    >
      <TableBody
        onClick={(event) => onClick(event, ref.current)}
        sx={[canUpdateAccess && { cursor: 'pointer' }]}
      >
        <TableRow>
          <TableCell sx={{ px: 0 }}>
            <Stack
              alignItems='center'
              direction='row'
              spacing={1}
            >
              <Avatar
                sx={{
                  height: 40,
                  width: 40,
                  bgcolor: (theme) => theme.palette[color].main
                }}
              >
                <SvgIcon
                  sx={{ color: (theme) => theme.palette[color].contrastText }}
                >
                  <Icon />
                </SvgIcon>
              </Avatar>
              <Box>
                <Typography variant='subtitle2'>{label}</Typography>
                <Typography
                  color='text.secondary'
                  variant='body2'
                >
                  {description}
                </Typography>
              </Box>
            </Stack>
          </TableCell>

          {canUpdateAccess && (
            <TableCell
              sx={{ px: 0 }}
              align='right'
            >
              <IconButton
                ref={ref}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <SvgIcon>
                    <ChevronDown />
                  </SvgIcon>
                )}
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  )
}

export { AccessLevelSetting }
