import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { MapstackLoadingBackdrop } from 'ui-components'
import { getCreateMapLink } from 'utils'
import { useAuth } from '~user-auth/contexts/auth'
import { useGetUserById } from '~user-auth/queries/useGetUserById'

function RedirectCreateMap() {
  const router = useRouter()
  const { user } = useAuth()
  const { data, isSuccess } = useGetUserById(user?.id)

  useEffect(() => {
    if (!isSuccess) return
    if (!data?.defaultWorkspace) return

    router.replace(
      getCreateMapLink({
        workspaceId: data.defaultWorkspace.id,
        workspaceName: data.defaultWorkspace.name
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess])

  return <MapstackLoadingBackdrop text='Redirecting...' />
}

export { RedirectCreateMap }
