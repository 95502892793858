import React from 'react'
import { Popup, PopupPropTypes } from 'ui-components'

const View = (props) => {
  return <Popup {...props} />
}

View.propTypes = PopupPropTypes

export { View }
