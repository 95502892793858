import { createSelector, createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import mapApi from '~map-viewer/services/map'

const initialState = {
  limit: 10,
  page: 1,
  total: 0,

  args: {},
  data: {},
  pagination: {}
}

export const remixSlice = createSlice({
  name: 'remix',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    }
  },

  extraReducers: (builder) => {
    builder
      .addMatcher(
        mapApi.endpoints.getMapRemixCollection.matchPending,
        (state, action) => {
          state.args = action.meta.arg.originalArgs
        }
      )
      .addMatcher(
        mapApi.endpoints.getMapRemixCollection.matchFulfilled,
        (state, action) => {
          if (action.payload) {
            state.data = action.payload
            state.total = action.payload.getMapById.mapRemixCollection.total
            state.pagination = {
              page: state.page,
              limit: state.limit,
              total: state.total
            }
          }
        }
      )
  }
})

const selectLimit = (state) => state.remix.limit
const selectPage = (state) => state.remix.page
const selectArgs = (state) => state.remix.args
const selectPagination = (state) => state.remix.pagination
const selectMapRemixCollection = (state) =>
  state.remix.data?.getMapById?.mapRemixCollection || {}
const selectChildMaps = createSelector(
  [selectMapRemixCollection],
  (mapRemixCollection) => {
    return mapRemixCollection.items?.map((item) => item.childMap) || []
  }
)

export const useRemixLimit = () => useSelector(selectLimit)
export const useRemixPage = () => useSelector(selectPage)
export const useRemixArgs = () => useSelector(selectArgs)
export const useRemixMaps = () => useSelector(selectChildMaps)
export const useRemixPagination = () => useSelector(selectPagination)

export const { setPage } = remixSlice.actions
export default remixSlice.reducer
