import { Box, Button } from '@mui/material'
import { BookOpen01 } from '@untitled-ui/icons-react'
import { useState } from 'react'
import EditButton from '~ui-components/components/molecules/EditButton'
import { EmptySlotFallback } from '~ui-components/components/molecules/EmptySlotFallback'
import Notification from '~ui-components/components/molecules/Notification'
import {
  TextEditor,
  TextEditorFooter,
  TextEditorHeader
} from '~ui-components/components/molecules/TextEditor'

const defaultSlots = {
  TextEditor
}

const MapDescription = (props) => {
  const { hasReadme = false, owned = false, slots = {}, slotProps = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const [action, setAction] = useState('preview')
  const [openReadmeSaved, setOpenReadmeSaved] = useState(false)

  const handleAddReadmeClick = () => {
    setAction('edit')
  }

  const handleEditClick = () => {
    setAction('edit')
  }

  const handleReadmeSnackClose = () => {
    setOpenReadmeSaved(false)
  }

  const {
    slots: TextEditorSlots,
    slotProps: TextEditorSlotProps,
    ...TextEditorProps
  } = slotProps?.TextEditor || {}

  return (
    <>
      <Notification
        variant='filled'
        severity='success'
        autoHideDuration={2500}
        open={openReadmeSaved}
        onClose={handleReadmeSnackClose}
      >
        Readme saved!
      </Notification>

      {action === 'preview' && !hasReadme ? (
        <>
          <EmptySlotFallback
            sx={{ flex: 1 }}
            icon={BookOpen01}
            text='This map has no README'
            button={
              owned ? (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleAddReadmeClick}
                >
                  Add README
                </Button>
              ) : null
            }
          />
        </>
      ) : action === 'preview' && hasReadme ? (
        <>
          <Box sx={{ flex: 1, position: 'relative', display: 'flex' }}>
            <Slots.TextEditor
              sx={{ flex: 1 }}
              readOnly
              {...TextEditorProps}
            />

            {action !== 'edit' && owned ? (
              <>
                <EditButton
                  sx={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px'
                  }}
                  size='small'
                  onClick={handleEditClick}
                />
              </>
            ) : null}
          </Box>
        </>
      ) : (
        <>
          <Slots.TextEditor
            sx={{ flex: 1, minHeight: 600 }}
            slots={{
              Header: TextEditorHeader,
              Footer: TextEditorFooter,
              ...TextEditorSlots
            }}
            slotProps={{
              Footer: {
                onSubmitComplete: () => {
                  setAction('preview')
                  setOpenReadmeSaved(true)
                },
                onSubmit: () => {
                  setAction('preview')
                  setOpenReadmeSaved(true)
                },
                ...TextEditorSlotProps?.Footer
              }
            }}
            {...TextEditorProps}
          />
        </>
      )}
    </>
  )
}

export { MapDescription }
