import { Avatar, Box, Card, Stack, SvgIcon, Typography } from '@mui/material'
import { Check, X } from '@untitled-ui/icons-react'
import React from 'react'

class InvalidStatusError extends Error {
  constructor() {
    super('Invalid status')
    this.name = 'InvalidStatusError'
  }
}

interface GeocoderItemProps extends React.ComponentProps<typeof Card> {
  status: 'success' | 'error'
  primaryText: string
}

const backgroundMapping = {
  success: 'success.main',
  error: 'error.main'
}

function GeocoderItem(props: GeocoderItemProps) {
  const { status, primaryText, ...rest } = props

  if (!status) throw new InvalidStatusError()

  return (
    <Card {...rest}>
      <Stack
        alignItems='center'
        direction='row'
        sx={{ p: 2 }}
        spacing={2}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
            bgcolor: backgroundMapping[status]
          }}
        >
          <SvgIcon sx={{ color: 'white' }}>
            {status === 'success' && <Check />}
            {status === 'error' && <X />}
          </SvgIcon>
        </Avatar>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            color='text.secondary'
            gutterBottom
            variant='body2'
            sx={{ fontWeight: 'medium', mr: 3 }}
          >
            {primaryText}
          </Typography>
        </Box>
      </Stack>
    </Card>
  )
}

export { GeocoderItem }
