import { useRouter } from 'next/router'
import { useState } from 'react'
import { getCreateMapLink } from 'utils'

import Skeleton from '~ui-components/components/atoms/Skeleton'
import { AddMapDialog } from '~ui-components/components/molecules/AddMapDialog'
import { AddMapIconButton } from '~ui-components/components/molecules/AddMapIconButton'

const defaultSlots = {
  AddMapDialog
}

const message =
  "You don't have any workspaces yet. Would you like to create one?"

const AddMapAction = (props) => {
  const { authenticated, loading, slots = {}, slotProps = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const router = useRouter()
  const [open, setOpen] = useState(false)
  const { workspaces } = slotProps?.AddMapDialog || {}

  const handleClick = () => {
    if (!workspaces?.length) {
      router.push('/workspace/create')
      return
    }

    if (workspaces?.length === 1) {
      const [workspace] = workspaces
      router.push(
        getCreateMapLink({
          workspaceId: workspace.id,
          workspaceName: workspace.name
        })
      )
      return
    }

    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (!authenticated) return null

  return (
    <>
      <Skeleton
        className='AddMapIconButton'
        loading={loading}
        width={40}
        height={40}
        variant='circular'
        sx={{ p: '4px' }}
      >
        <AddMapIconButton onClick={handleClick} />
      </Skeleton>

      <Slots.AddMapDialog
        open={open}
        onClose={handleClose}
        {...slotProps?.AddMapDialog}
      />
    </>
  )
}

export { AddMapAction }
export default AddMapAction
