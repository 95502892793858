import { ResizeObserver } from '@juggle/resize-observer'
import { useCallback, useState } from 'react'
import { useLayoutEffect } from '~ui-components/hooks/useLayoutEffect'

const useHeightObserver = (ref) => {
  const [height, setHeight] = useState(0)

  const handleResize = useCallback((entries) => {
    const _height = entries[0].borderBoxSize[0].blockSize
    setHeight(_height)
  }, [])

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }
    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(ref.current, {
      box: 'border-box'
    })

    return () => {
      resizeObserver.disconnect()
    }
  }, [ref, handleResize])

  return height
}

export { useHeightObserver }
