import { Box, Divider } from '@mui/material'
import { Plate } from '@udecode/plate-common'
import clsx from 'clsx'

import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { Main } from './components/Main'

import { usePlugins } from './hooks/usePlugins'
import { AutofocusPlugin } from './plugins/AutofocusPlugin'
import { CloudUploadPlugin } from './plugins/CloudUploadPlugin'

const Empty = () => null

const defaultSlots = {
  Header: Empty,
  Footer: Empty
}

const TextEditor = (props) => {
  const {
    sx,
    autoFocus = false,
    readOnly,
    value,
    initialValue,
    onChange,
    plugins: pluginsProp = [],
    slots = {},
    slotProps = {}
  } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const plugins = usePlugins(pluginsProp)

  return (
    <Plate
      primary
      readOnly={readOnly}
      initialValue={initialValue}
      value={value}
      onChange={onChange}
      plugins={plugins}
    >
      {autoFocus && <AutofocusPlugin />}

      <Box
        sx={[
          { display: 'flex', flexDirection: 'column' },
          ...(Array.isArray(sx) ? sx : [sx])
        ]}
        className={clsx('TextEditor')}
        data-testid='TextEditor'
      >
        {!readOnly ? (
          <>
            <Slots.Header {...slotProps?.Header} />

            <Divider
              sx={{ my: 2, mx: (theme) => `-${theme.spacing(2)} !important` }}
            />
          </>
        ) : null}
        <Main />
        {!readOnly ? <Slots.Footer {...slotProps?.Footer} /> : null}
      </Box>
    </Plate>
  )
}

export {
  CloudUploadPlugin,
  TextEditor,
  Footer as TextEditorFooter,
  Header as TextEditorHeader
}
