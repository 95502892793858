import { generateColourRamp } from '../../../get-colour-ramp';


function generateFill(
  dataClassBreaks,
  colourPalette: string[],
  aggregateBoundary: string,
  source: string,
  sourceLayer?: string
) {
  const colourRamp = generateColourRamp(dataClassBreaks, colourPalette)

  const fill = {
    id: 'Mapstack Visualization Fill',
    type: 'fill',
    source: source,
    paint: {
      'fill-color': [
        'match',
        ['get', aggregateBoundary],
        ...colourRamp],
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.6,
        0.9
      ]
    }
  }
  if (sourceLayer) fill['source-layer'] = sourceLayer
  return fill
}

export { generateFill }
