import {
  Box,
  BoxProps,
  Paper,
  SvgIcon,
  Typography,
  useTheme
} from '@mui/material'
import SmartToy from '~ui-components/assets/svg/SmartToy'

type AlertRobotProps = BoxProps

const AlertRobot = (props: AlertRobotProps) => {
  const { children, ...rest } = props
  const theme = useTheme()

  return (
    <Box
      data-testid='AlertRobot'
      component={Paper}
      variant='outlined'
      {...rest}
      p={2}
      color={theme.palette.common.white}
      backgroundColor={theme.palette.info.main}
      display='flex'
      flexDirection='row'
      alignItems='center'
    >
      <SvgIcon fontSize='large'>
        <SmartToy />
      </SvgIcon>
      <Box
        component={Typography}
        variant='body1'
        ml={2}
      >
        {children}
      </Box>
    </Box>
  )
}

export { AlertRobot }
