import { createWorkerFactory, useWorker } from '@shopify/react-web-worker'

const createMapWorker = createWorkerFactory(() => import('./worker'), {})

const useWebWorker = () => {
  const worker = useWorker(createMapWorker)
  return worker
}

export { useWebWorker }
