import { isPosition } from '../isPosition'
import { GEOJSON_ERRORS } from '../../assets/constants'

/**
 * Determines if an array is a linear Ring String or not
 * @method isMultiLineString
 * @private
 * @param coordinates {Array}
 * @return {Boolean}
 */
function _linearRingCoor(coordinates) {
  let errors = []
  if (Array.isArray(coordinates)) {
    // 4 or more positions
    coordinates.forEach((val, index) => {
      const e = isPosition(val)
      if (e.length) {
        // modify the err msg from 'isPosition' to note the element number
        e[0][1] = 'at ' + index + ': '.concat(e[0][1])
        // build a list of invalide positions
        errors = errors.concat(e)
      }
    })

    // check the first and last positions to see if they are equivalent
    if (
      coordinates[0].toString() !==
      coordinates[coordinates.length - 1].toString()
    ) {
      errors.push([
        GEOJSON_ERRORS.COORDINATE_MUST_EQUIVALENT.key,
        GEOJSON_ERRORS.COORDINATE_MUST_EQUIVALENT.description
      ])
    }

    // coordinates must have at least four positions
    if (coordinates.length < 4) {
      errors.push([
        GEOJSON_ERRORS.COORDINATE_MINIMUM_4_ELEMENTS.key,
        GEOJSON_ERRORS.COORDINATE_MINIMUM_4_ELEMENTS.description
      ])
    }
  } else {
    // 'coordinates must be an array'
    errors.push([
      GEOJSON_ERRORS.COORDINATE_MUST_BE_ARRAY.key,
      GEOJSON_ERRORS.COORDINATE_MUST_BE_ARRAY.description
    ])
  }

  return errors
}

/**
 * Determines if an array is valid Polygon Coordinates or not
 * @method _polygonCoor
 * @private
 * @param coordinates {Array}
 * @return {Boolean}
 */
export const isPolygonCoor = (coordinates) => {
  let errors = []
  if (Array.isArray(coordinates)) {
    coordinates.forEach((val, index) => {
      const e = _linearRingCoor(val)
      if (e.length) {
        // modify the err msg from 'isPosition' to note the element number
        e[0][1] = 'at ' + index + ': '.concat(e[0][1])
        // build a list of invalid positions
        errors = errors.concat(e)
      }
    })
  } else {
    // 'coordinates must be an array'
    errors.push([
      GEOJSON_ERRORS.COORDINATE_MUST_BE_ARRAY.key,
      GEOJSON_ERRORS.COORDINATE_MUST_BE_ARRAY.description
    ])
  }

  return errors
}
