import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { VISUALISATION } from 'utils'
import { useCurrentWizardStep } from '~map-creator/core/states/map'
import {
  setActiveVisualisationKey,
  useActiveVisualisationKey,
  useConfigurations,
  useIncludedKeys
} from '~map-creator/core/states/properties'
import { useStepIndexes } from '~map-creator/core/states/ui'
import { View } from './view'

const { CATEGORY, QUANTITY } = VISUALISATION

const Visualisations = () => {
  const dispatch = useDispatch()

  const currentWizardStep = useCurrentWizardStep()
  const configurations = useConfigurations()
  const activeVisualisationKey = useActiveVisualisationKey()
  const includedKeys = useIncludedKeys()
  const stepIndexes = useStepIndexes()

  const visualisationOptions = includedKeys
    .filter((k) => {
      const { visualisation } = configurations[k]
      return [CATEGORY, QUANTITY].includes(visualisation)
    })
    .map((k) => ({ label: configurations[k].name, value: k }))

  useEffect(() => {
    if (!activeVisualisationKey && visualisationOptions.length) {
      dispatch(setActiveVisualisationKey(visualisationOptions[0].value))
    }
  }, [activeVisualisationKey, dispatch, visualisationOptions])

  const handleVisualisationChange = (value) => {
    dispatch(setActiveVisualisationKey(value))
  }

  const { SELECT_VISUALISATION_PROPERTIES, SELECT_DEFAULT_VIEW } = stepIndexes
  const showVisualisationStep =
    SELECT_VISUALISATION_PROPERTIES || SELECT_DEFAULT_VIEW

  if (currentWizardStep < showVisualisationStep) return null

  return (
    <View
      visualisationOptions={visualisationOptions}
      activeVisualisationKey={activeVisualisationKey}
      onVisualisationChange={handleVisualisationChange}
    />
  )
}

export { Visualisations }
