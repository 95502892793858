import { useEffect, useState } from 'react'

/**
 * To prevent error from happening when using nextjs
 * @see {@link https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85 discussion}
 */
const ClientLayout = ({ children }) => {
  const [showChild, setShowChild] = useState(false)

  // Wait until after client-side hydration to show
  useEffect(() => {
    setShowChild(true)
  }, [])

  if (!showChild) {
    // You can show some kind of placeholder UI here
    return null
  }

  return children
}

export { ClientLayout }
export default ClientLayout
