import { FC } from 'react'
import {
  Box,
  Image,
  Item,
  Typography,
  Link
} from '~ui-components/emails/components/atoms'

type MapInfoProps = {
  mapName: string
  thumbnailUrl: string
  inviteLink: string
}

export const MapInfo: FC<MapInfoProps> = (props) => {
  const { mapName, thumbnailUrl, inviteLink } = props
  return (
    <Box
      align='center'
      p={24}
    >
      <Item>
        <Typography
          data-testid='MapName'
          variant='body1'
          fontWeight={600}
        >
          {mapName}
        </Typography>
      </Item>
      <Item
        align='center'
        pt={12}
      >
        <Link href={inviteLink}>
          <Image
            data-testid='MapThumbnail'
            src={thumbnailUrl}
            alt='Map Thumbnail'
            width={75}
            height={75}
            borderRadius='rounded'
            style={{ margin: 6, marginBottom: 0 }}
          />
        </Link>
      </Item>
    </Box>
  )
}
