import _ from 'lodash'
import { isDateType, isNumericType, isStringType } from 'utils'

const getStringType = ({ type }) => isStringType(type)
const getNumericType = ({ type }) => isNumericType(type)
const getDateType = ({ type }) => isDateType(type)

const createStringQuery = (filters) => {
  return _.chain(filters)
    .filter(getStringType)
    .map((filter) => {
      return _.chain(filter.value)
        .map((item) => {
          const template = 'properties."$PROPERTY"==to_string(`$VALUE`)'
            .replace('$PROPERTY', filter.name)
            .replace('$VALUE', item)
          return template
        })
        .join('||')
        .value()
    })
    .join('&&')
    .value()
}

const createNumberQuery = (filters) => {
  return _.chain(filters)
    .filter(getNumericType)
    .map((filter) => {
      const template =
        'properties."$PROPERTY" != `null` && properties."$PROPERTY">=to_number(`$MIN_VALUE`) && properties."$PROPERTY"<=to_number(`$MAX_VALUE`)'
          .replaceAll('$PROPERTY', filter.name)
          .replaceAll('$MIN_VALUE', filter.value[0])
          .replaceAll('$MAX_VALUE', filter.value[1])
      return template
    })
    .join('&&')
    .value()
}

const createDateQuery = (filters) => {
  return _.chain(filters)
    .filter(getDateType)
    .map((filter) => {
      const template =
        'properties."$PROPERTY" != `null` && properties."$PROPERTY">=`$MIN_VALUE` && properties."$PROPERTY"<=`$MAX_VALUE`'
          .replaceAll('$PROPERTY', filter.name)
          .replaceAll('$MIN_VALUE', filter.value.minDate)
          .replaceAll('$MAX_VALUE', filter.value.maxDate)
      return template
    })
    .join('&&')
    .value()
}

const combineQueries = (...queries) => {
  return queries
    .map((query) => query && '($QUERY)'.replace('$QUERY', query))
    .filter(Boolean)
    .join('&&')
}

const create = (filters) => {
  const stringQuery = createStringQuery(filters)
  const numericQuery = createNumberQuery(filters)
  const dateQuery = createDateQuery(filters)
  const combined = combineQueries(stringQuery, numericQuery, dateQuery)
  const query = '[?$QUERY]'.replace('$QUERY', combined)
  return query
}

const QueryBuilder = {
  create
}

export { QueryBuilder }
export default QueryBuilder
