import { useSpring } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useLayoutEffect } from '~ui-components/hooks/useLayoutEffect'
import { useScrollLock } from '~ui-components/hooks/useScrollLock'
import { useInterpolation } from './useInterpolation'
import { useThresholds } from './useThresholds'

/**
 * Spring Configuration.
 *
 * Playground {@link https://react-spring-visualizer.com/}
 */
const springConfig = {
  mass: 0.1,
  tension: 248,
  friction: 10,
  precision: 0.001
}

/**
 * 	MobilePanel Logics
 */
const useMobilePanel = (params) => {
  const {
    // configs
    open,
    defaultThreshold,
    thresholds,
    closeThreshold,
    // callbacks
    unmount
  } = params

  /**
   * Before any animations can start we need to measure a few things,
   * like the viewport and the dimensions of content, and header if they exist
   */
  const [ready, setReady] = useState(false)

  const containerRef = useRef(null)
  const scrollRef = useRef(null)
  const contentRef = useRef(null)
  const headerRef = useRef(null)
  const modalRef = useRef(null)

  /**
   * Reference for the height of MobilePanel.
   * Useful for calculating of next threshold.
   */
  const heightRef = useRef(0)

  /**
   * Spring Animation Values.
   */
  const [spring, animate] = useSpring(() => ({
    from: {
      ready: 0,
      y: 0,
      minThreshold: 0,
      maxThreshold: 0,
      maxHeight: 0
    }
  }))

  /**
   * ScrollLock Plugin to lock the body.
   */
  const scrollLockRef = useScrollLock({
    targetRef: scrollRef,
    enabled: ready
  })

  const activatePlugins = useCallback(async () => {
    scrollLockRef.current.activate()
  }, [scrollLockRef])

  const deactivatePlugins = useCallback(() => {
    scrollLockRef.current.deactivate()
  }, [scrollLockRef])

  const {
    snapThresholds,
    minThreshold,
    maxThreshold,
    findThreshold,
    maxHeight,
    isThresholdReady
  } = useThresholds({
    headerRef,
    contentRef,
    thresholds,
    heightRef
  })

  useEffect(() => {
    if (isThresholdReady) {
      setReady(true)
    }
  }, [isThresholdReady])

  const snapThresholdsRef = useRef([])
  const minThresholdRef = useRef(minThreshold)
  const maxThresholdRef = useRef(maxThreshold)
  const findThresholdRef = useRef(findThreshold)
  const maxHeightRef = useRef(maxHeight)
  const defaultThresholdRef = useRef(0)
  const closeThresholdRef = useRef(0)
  useLayoutEffect(() => {
    snapThresholdsRef.current = snapThresholds
    minThresholdRef.current = minThreshold
    maxThresholdRef.current = maxThreshold
    maxHeightRef.current = maxHeight
    findThresholdRef.current = findThreshold
    defaultThresholdRef.current = findThreshold(defaultThreshold)
    closeThresholdRef.current = closeThreshold({ maxHeight })
  }, [
    snapThresholds,
    maxHeight,
    minThreshold,
    maxThreshold,
    findThreshold,
    defaultThreshold,
    closeThreshold
  ])

  const asyncAnimate = useCallback(
    async (params) => {
      const { config, ...rest } = params
      return new Promise((resolve) =>
        animate.start({
          ...rest,
          config: {
            ...springConfig,
            ...config
          },
          onResolve: (...args) => {
            resolve(...args)
          }
        })
      )
    },
    [animate]
  )

  const renderMobilePanelHidden = useCallback(async () => {
    /**
     * Using defaultThresholdRef instead of minThresholdRef to avoid animating `height` on open
     */
    await asyncAnimate({
      ready: 0,
      y: defaultThresholdRef.current,
      minThreshold: defaultThresholdRef.current,
      maxThreshold: maxThresholdRef.current,
      maxHeight: maxHeightRef.current,
      immediate: true
    })
  }, [asyncAnimate])

  const animateMobilePanelOpen = useCallback(async () => {
    /**
     * Using defaultThresholdRef instead of minThresholdRef to avoid animating `height` on open
     */
    asyncAnimate({
      ready: 1,
      y: 0,
      minThreshold: defaultThresholdRef.current,
      maxThreshold: maxThresholdRef.current,
      maxHeight: maxHeightRef.current,
      immediate: true
    })

    heightRef.current = defaultThresholdRef.current

    /**
     * Using defaultThresholdRef instead of minThresholdRef to avoid animating `height` on open
     */
    await asyncAnimate({
      ready: 1,
      y: defaultThresholdRef.current,
      minThreshold: defaultThresholdRef.current,
      maxThreshold: maxThresholdRef.current,
      maxHeight: maxHeightRef.current,
      immediate: false
    })
  }, [asyncAnimate])

  const animateMobilePanelClose = useCallback(async () => {
    /**
     * Avoid animating the height property on close and stay within flip bounds by upper the minThreshold
     */
    asyncAnimate({
      minThreshold: heightRef.current,
      immediate: true
    })
    heightRef.current = 0
    await asyncAnimate({
      y: 0,
      maxThreshold: maxThresholdRef.current,
      maxHeight: maxHeightRef.current,
      immediate: false
    })
    await asyncAnimate({
      ready: 0,
      immediate: true
    })
  }, [asyncAnimate])

  const animateMobilePanelResize = useCallback(async () => {
    const snap = findThresholdRef.current(heightRef.current)
    heightRef.current = snap
    await asyncAnimate({
      ready: 1,
      y: snap,
      minThreshold: minThresholdRef.current,
      maxThreshold: maxThresholdRef.current,
      maxHeight: maxHeightRef.current,
      immediate: true
    })
  }, [asyncAnimate])

  const animateMobilePanelSnap = useCallback(
    async (params) => {
      const { y, velocity = 1 } = params
      const snap = findThresholdRef.current(y)
      heightRef.current = snap
      await asyncAnimate({
        ready: 1,
        y: snap,
        minThreshold: minThresholdRef.current,
        maxThreshold: maxThresholdRef.current,
        maxHeight: maxHeightRef.current,
        immediate: false,
        config: {
          velocity
        }
      })
    },
    [asyncAnimate]
  )

  const animateMobilePanelMove = (currentY) => {
    animate.start({
      y: currentY,
      maxHeight: maxHeightRef.current,
      minThreshold: 0,
      maxThreshold: maxThresholdRef.current,
      immediate: true
    })
  }

  const handleMobilePanelOpen = async () => {
    renderMobilePanelHidden()
    activatePlugins()
    await animateMobilePanelOpen()
  }

  const handleMobilePanelClose = async () => {
    deactivatePlugins()
    await animateMobilePanelClose()
    unmount()
  }

  const handleMobilePanelResize = async () => {
    await animateMobilePanelResize()
  }

  const handleMobilePanelSnap = async (params) => {
    await animateMobilePanelSnap(params)
  }

  const emit = (action, payload) => {
    if (action === 'open') {
      handleMobilePanelOpen()
      return
    }
    if (action === 'close') {
      handleMobilePanelClose()
      return
    }
    if (action === 'snap') {
      handleMobilePanelSnap(payload)
    }
  }

  const handleMobilePanelDragStart = (gestureState) => {
    gestureState.memo = {
      initialY: spring.y.get(),
      snapY: 0
    }
  }

  const validateGestureDrag = (gestureState) => {
    const { tap } = gestureState
    if (tap) return false
    return true
  }

  const calculateCurrentY = (gestureState) => {
    const {
      memo,
      movement: [, _my]
    } = gestureState
    const { initialY } = memo
    const my = _my * -1
    const currentY = initialY + my
    return currentY
  }

  const calculateSnapY = (gestureState) => {
    const {
      memo,
      movement: [, _my],
      velocity: [, vy]
    } = gestureState
    const { initialY } = memo
    const my = _my * -1
    const currentY = initialY + my
    const afterSwipeDistance = vy * my
    const nextY = Math.max(
      minThresholdRef.current,
      Math.min(maxHeightRef.current, currentY + afterSwipeDistance)
    )
    return nextY
  }

  const handleGestureDragging = (gestureState) => {
    const { last, memo } = gestureState
    const { initialY } = memo
    const currentY = calculateCurrentY(gestureState)
    const snapY = calculateSnapY(gestureState)
    if (!last) {
      animateMobilePanelMove(
        currentY >= maxHeightRef.current ? maxThresholdRef.current : currentY
      )
    }
    gestureState.memo = {
      initialY,
      snapY
    }
    return memo
  }

  const handleMobilePanelDrag = (gestureState) => {
    const { memo } = gestureState
    const valid = validateGestureDrag(gestureState)
    if (!valid) return memo
    handleGestureDragging(gestureState)
    return memo
  }

  const shouldMobilePanelClose = (gestureState) => {
    const currentY = calculateCurrentY(gestureState)
    return currentY < closeThresholdRef.current
  }

  const handleMobilePanelNearCloseThreshold = () => {
    emit('close')
  }

  const handleMobilePanelDragEnd = (gestureState) => {
    const {
      memo,
      tap,
      velocity: [, vy]
    } = gestureState
    if (tap) return memo
    const { snapY } = memo
    const close = shouldMobilePanelClose(gestureState)
    if (close) {
      handleMobilePanelNearCloseThreshold()
    } else {
      handleMobilePanelSnap({
        y: snapY,
        velocity: vy > 0.05 ? vy : 1
      })
    }
  }

  const bind = useGesture(
    {
      onDragStart: handleMobilePanelDragStart,
      onDrag: handleMobilePanelDrag,
      onDragEnd: handleMobilePanelDragEnd
    },
    {
      drag: {
        filterTaps: true,
        triggerAllEvents: false
      }
    }
  )

  useEffect(() => {
    if (!ready) return
    if (open) {
      emit('open')
    } else {
      emit('close')
    }
    return () => emit('close')
  }, [open, ready])

  /**
   * Deactivate plugins when unmounted
   */
  useEffect(() => {
    return () => {
      deactivatePlugins()
    }
  }, [scrollLockRef])

  /**
   * Side effect before render to adjust height whenever the threshold change due to resize event
   */
  useLayoutEffect(() => {
    if (maxHeight) {
      handleMobilePanelResize()
    }
  }, [maxHeight])

  const interpolations = useInterpolation({ spring })

  return {
    containerRef,
    modalRef,
    headerRef,
    scrollRef,
    contentRef,
    bind,
    interpolations,
    emit,
    heightRef: heightRef,
    snapThresholds: snapThresholdsRef.current
  }
}

export { useMobilePanel }
