import { Autocomplete, Chip, Stack, TextField, Typography } from '@mui/material'
import { MapProperty } from '~ui-components/types/__generated/gql/graphql'

interface Event {
  type: string
}

type PropertiesInputProps = {
  hidden?: boolean
  disabled?: boolean
  error?: React.ComponentProps<typeof TextField>['error']
  helperText?: React.ComponentProps<typeof TextField>['helperText']
  value?: MapProperty[]
  options?: MapProperty[]
  onChange?: (event: Event, value: MapProperty[]) => void
}

function IncludePropertiesInput(props: PropertiesInputProps): JSX.Element {
  const {
    hidden = false,
    error,
    helperText,
    options = [],
    value = [],
    onChange = () => {},
    disabled,
    ...rest
  } = props

  const selectOption = (option: MapProperty) => {
    const next = [...value, option]
    onChange({ type: 'change' }, next)
  }

  const unselectOption = (option: MapProperty) => {
    const next = value.filter((item) => item.name !== option.name)
    onChange({ type: 'change' }, next)
  }

  return (
    <Stack
      spacing={1}
      sx={[
        hidden && {
          display: 'none'
        }
      ]}
    >
      <Typography variant='subtitle2'>Include Properties</Typography>
      <Autocomplete
        disabled={disabled}
        disableClearable
        fullWidth
        multiple
        freeSolo
        options={[]}
        value={options}
        inputValue=''
        onInputChange={() => {}}
        sx={{
          '.MuiInputBase-root.MuiOutlinedInput-root': {
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
          },
          '.MuiInputBase-input': {
            caretColor: 'transparent'
          }
        }}
        renderTags={(options, getTagProps) => {
          return options.map((option, index) => {
            const active = value.find((item) => item.name === option.name)
            return (
              <Chip
                {...getTagProps({ index })}
                key={index}
                data-testid={`Property-${option}`}
                variant='filled'
                color={active ? 'secondary' : 'default'}
                label={option.name}
                onClick={() =>
                  active ? unselectOption(option) : selectOption(option)
                }
                onDelete={undefined}
              />
            )
          })
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              autoComplete='off'
              variant='outlined'
              error={error}
              helperText={helperText}
              {...rest}
            />
          )
        }}
      />
    </Stack>
  )
}

export { IncludePropertiesInput }
