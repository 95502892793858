import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_PARAGRAPH,
  someNode,
  toggleNodeType
} from '@udecode/plate'

import { focusEditor } from '@udecode/plate-common'
import { useEditorState } from '@udecode/plate-core'

import Action from '~ui-components/components/molecules/TextEditor/components/Action'

const Quote = (props) => {
  const editor = useEditorState()
  const quote =
    editor.selection &&
    someNode(editor, {
      match: { type: ELEMENT_BLOCKQUOTE }
    })

  const handleClick = () => {
    toggleNodeType(editor, {
      activeType: ELEMENT_BLOCKQUOTE,
      inactiveType: ELEMENT_PARAGRAPH
    })
    focusEditor(editor)
  }

  return (
    <Action
      variant='quote'
      toggled={quote}
      onClick={handleClick}
      {...props}
    />
  )
}

export { Quote }
