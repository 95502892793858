import { useDispatch } from 'react-redux'
import { MapFilterChips as View } from 'ui-components'
import { useFilters } from '~map-filter-view/states/filter'
import { useWhetherToCollapseChips } from '~map-viewer/App/hooks/useWhetherToCollapseChips'
import { openFilter, setFilterPreview } from '~map-viewer/states/ui'

export const MapFilterChips = (props) => {
  const dispatch = useDispatch()

  const filters = useFilters()
  const shouldUseCollapse = useWhetherToCollapseChips()

  if (filters.length <= 0) return null

  return (
    <View
      {...props}
      shouldUseCollapse={shouldUseCollapse}
      filters={filters}
      onClick={() => {
        dispatch(openFilter())
        dispatch(setFilterPreview())
      }}
    />
  )
}
