import {
  RemixMapMutation,
  RemixMapMutationVariables
} from '~map-creator/types/__generated/gql/graphql'
import { graphierQL } from 'utils'

const remixMap = async (
  parentMapId: string,
  childMapId: string,
  workspaceId: string
) => {
  const data = await graphierQL<RemixMapMutation, RemixMapMutationVariables>({
    useToken: true,
    query: remixMapMutation,
    variables: { parentMapId, childMapId, workspaceId }
  })

  return data
}

const remixMapMutation = /* GraphQL */ `
  mutation RemixMap($parentMapId: ID!, $childMapId: ID!, $workspaceId: ID!) {
    remixMap(
      parentMapId: $parentMapId
      childMapId: $childMapId
      workspaceId: $workspaceId
    )
  }
`

export { remixMap, remixMapMutation }
export default remixMap
