import { Portal } from '@mui/material'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { useLayoutEffect } from '~ui-components/hooks/useLayoutEffect'
import {
  MobilePanel as MobilePanelComponent,
  getCloseThreshold,
  getDefaultThreshold,
  getThresholds
} from './MobilePanel'

const MobilePanel = (props) => {
  const { open, disablePortal = false, onClose, ...rest } = props

  const [mounted, setMounted] = useState(false)

  useLayoutEffect(() => {
    if (open) {
      setMounted(true)
    }
  }, [open])

  const unmount = () => {
    if (onClose) onClose()
    setMounted(false)
  }

  if (!mounted) {
    return null
  }

  return (
    <Fragment>
      <Portal disablePortal={disablePortal}>
        <MobilePanelComponent
          {...{
            open,
            unmount,
            ...rest
          }}
        />
      </Portal>
    </Fragment>
  )
}

export { MobilePanel }

MobilePanel.propTypes = {
  /**
   * The children will be under the DOM hierarchy of the parent component.
   */
  disablePortal: PropTypes.bool,
  /**
   * A string that act as a title for MobilePanel component.
   */
  title: PropTypes.string,
  /**
   * Custom header component that render inside of BottomSheetHeader component.
   */
  header: PropTypes.node,
  /**
   * Boolean state that control the open state of the MobilePanel component.
   */
  open: PropTypes.bool.isRequired,
  /**
   * Callback that invoke when the MobilePanel is closed.
   */
  onClose: PropTypes.func,
  /**
   * Number to set the maximum height of MobilePanel component.
   */
  defaultMaxHeight: PropTypes.number,
  /**
   * A function that set the default threshold of the MobilePanel component.
   */
  defaultThreshold: PropTypes.func,
  /**
   * A function that set the snap threshold of the MobilePanel component.
   */
  thresholds: PropTypes.func.isRequired,
  /**
   * A function that set the close threshold of the MobilePanel component.
   */
  closeThreshold: PropTypes.func,
  /**
   * Element to render inside the MobilePanel component.
   */
  children: PropTypes.node
}

MobilePanel.defaultProps = {
  open: false,
  onClose: () => {},
  defaultThreshold: getDefaultThreshold,
  thresholds: getThresholds,
  closeThreshold: getCloseThreshold
}
