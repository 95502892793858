import { dayjs } from '~utils/libs/dayjs'
import { isDate, isUndefined } from 'lodash'

const isISO8601Format = (value) => {
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?$/
  return isoRegex.test(value)
}

const isISO8601Valid = (value) => {
  return dayjs(value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid()
}

const isISO8601Date = (value) => {
  if (isUndefined(value)) return false

  if (isDate(value)) return isISO8601Valid(value)

  return isISO8601Format(value) && isISO8601Valid(value)
}

export { isISO8601Date }
