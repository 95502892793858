import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useInitGeojsonData } from '~map-viewer/App/hooks/useInitGeojsonData'
import { useInitJsonData } from '~map-viewer/App/hooks/useInitJsonData'
import {
  GEOJSON_SOURCE,
  VT_SERVER_SOURCE
} from '~map-viewer/assets/constant/LayerConstant'
import { addLayerSource } from '~map-viewer/states/layer'
import { useDataSets, useHasDataSet } from '~map-viewer/states/map'
import { GeojsonLayer } from './Geojson'
import useFilterBorderStyle from './LayerUtils/useFilterBorderStyle'
import useLineStyle from './LayerUtils/useLineStyle'
import useVisualisationStyle from './LayerUtils/useVisualisationStyle'
import { VTServerLayer } from './VTServer'

const Component = () => {
  const hasDataSet = useHasDataSet()
  const source = hasDataSet ? GEOJSON_SOURCE : VT_SERVER_SOURCE
  const dispatch = useDispatch()
  const lineLayerStyle = useLineStyle()
  const visualisationStyle = useVisualisationStyle()
  const filterBorderStyle = useFilterBorderStyle()

  useInitJsonData()

  const { isSourceLoaded } = useInitGeojsonData()

  useEffect(() => {
    dispatch(addLayerSource(source))
  }, [dispatch, hasDataSet, source])

  const datasetsData = useDataSets()

  // Return Geojson Source/Layer if there is geojson downloaded
  if (hasDataSet && !isSourceLoaded) {
    return (
      <>
        {datasetsData.map((dataset, key) => (
          <GeojsonLayer
            key={key}
            layerStyle={visualisationStyle}
            lineLayerStyle={lineLayerStyle}
            filterBorderStyle={filterBorderStyle}
            dataId={dataset}
            layout={{ visibility: 'visible' }}
          />
        ))}
      </>
    )
  }

  // Rendering VT Source/Layer while download geojson in background
  return (
    <>
      {!hasDataSet && (
        <VTServerLayer
          layerStyle={visualisationStyle}
          lineLayerStyle={lineLayerStyle}
          filterBorderStyle={filterBorderStyle}
        />
      )}
      {/* When Geojson loaded render Geojson Source/Layers with visibility=none */}
      {isSourceLoaded && (
        <>
          {datasetsData.map((dataset, key) => (
            <GeojsonLayer
              key={key}
              layerStyle={visualisationStyle}
              lineLayerStyle={lineLayerStyle}
              filterBorderStyle={filterBorderStyle}
              dataId={dataset}
              // Rendering Geojson source/layers with none visibility
              layout={{ visibility: hasDataSet ? 'visible' : 'none' }}
            />
          ))}
        </>
      )}
    </>
  )
}

export const Layers = React.memo(Component)
