import _, { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addFilters } from '~map-filter-view/states/filter'
import { MapType } from '~map-viewer/constants/MapType'
import {
  setFitBounds,
  setMapType,
  setSelectedFeature,
  useVisualisations
} from '~map-viewer/states/map'
import { setVisualisation } from '~map-viewer/states/visualisation'

export function useShareMapHandler(shareState = {}) {
  const dispatch = useDispatch()
  const visualisations = useVisualisations()

  useEffect(() => {
    if (isEmpty(shareState)) return

    const { filters, visualisation, fitBounds, selectedFeature } = shareState

    if (!isEmpty(visualisation)) {
      const visualisationObj = visualisations.find(
        ({ value }) => value === visualisation
      )
      dispatch(setVisualisation(visualisationObj))
    }

    if (filters?.length) {
      dispatch(setMapType(MapType.SHARE))
      dispatch(addFilters(filters))
    }

    if (!_.isEmpty(selectedFeature)) {
      dispatch(setSelectedFeature(selectedFeature))
    }

    if (!_.isEmpty(fitBounds)) {
      dispatch(setFitBounds(fitBounds))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareState])
}
