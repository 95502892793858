import { createFormFactory } from '@tanstack/react-form'
import getBbox from '@turf/bbox'
import getCentroid from '@turf/centroid'
import { get } from 'idb-keyval'
import {
  extractGeojson,
  getHashDataId,
  saveDataToIndexDB,
  submitRemixMap,
  useWebWorker
} from 'map-creator'
import { useAuth } from 'user-auth'
import {
  getMapLink,
  getNanoId,
  setRemixedMapIdClientCookie,
  withPostfix
} from 'utils'

import { useFilterResults } from '~map-filter-view/states/filter'
import { MapStatus } from '~map-viewer/constants/MapStatus'
import { setSubmitMap } from '~map-viewer/functions'
import { useMap, useProperties } from '~map-viewer/states/map'
import {
  MapProperty,
  Workspace
} from '~map-viewer/types/__generated/gql/graphql'

interface FormProps {
  children: React.ReactNode
  onSubmit?: (event: { redirect: string }) => void
  onClose?: () => void
}

const { useForm, Field, useField } = createFormFactory<{
  filters: boolean
  workspace: Workspace
  properties: MapProperty[]
}>({})

function Form(props: FormProps) {
  const { onClose, onSubmit } = props

  const worker = useWebWorker()
  const parentMap = useMap()
  const filterResults = useFilterResults()
  const allProperties = useProperties()
  const { user } = useAuth()

  const form = useForm({
    asyncAlways: true,

    onSubmit: async (event) => {
      if (!user) throw new Error('User not found')

      const { value } = event
      const {
        filters,
        workspace,
        properties: includedVisualisationProperties
      } = value

      // for CSV maps could contain none visualised properties, these will be carried over
      const nonVisualisedProperties = allProperties.filter(
        (property) => property.visualisation === 'none'
      )
      const includedProperties = includedVisualisationProperties.concat(
        nonVisualisedProperties
      )
      const mapId = getNanoId()
      const properties = setDefaultVisualisation(
        setClassbreak(includedProperties)
      )

      const mapTagCollection = getMapTagCollection(parentMap.mapTagCollection)
      const tagIds = getTagIds(mapTagCollection)
      const propertyNames = toPropertyNames(properties)

      const currentGeojson = await get(parentMap.dataId)

      if (filters && filterResults.length > 0)
        currentGeojson.features = filterResults

      const dataId = getHashDataId(currentGeojson, propertyNames)
      const { geojson, json } = extractGeojson(currentGeojson, propertyNames)

      const bbox = getBbox(geojson)
      const {
        geometry: { coordinates: centroid }
      } = getCentroid(geojson)

      const childMap = {
        id: mapId,
        name: withPostfix(parentMap.name, ' (remix)'),
        workspaceId: workspace.id,
        ...(parentMap.description
          ? {
              description: parentMap.description
            }
          : {}),
        ...(parentMap.license ? { license: parentMap.license } : {}),
        ...(parentMap.source ? { source: parentMap.source } : {}),
        centroid,
        bbox,
        views: 1,
        likes: 0,
        remixes: 0,
        dataId,
        properties,
        tagIds,
        type: parentMap.type
      }

      const map = {
        id: childMap.id,
        dataId: childMap.dataId,
        name: childMap.name,
        description: childMap.description,
        bbox: childMap.bbox,
        centroid: childMap.centroid,
        license: childMap.license,
        source: childMap.source,
        views: childMap.views,
        likes: childMap.likes,
        remixes: childMap.remixes,
        properties: childMap.properties,
        creator: user,
        mapTagCollection,
        dataReady: false,
        workspace,
        parentMap
      }

      await saveDataToIndexDB(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        {
          geojson,
          json,
          map
        }
      )

      onSubmit?.({ redirect: getMapLink({ mapId, mapName: childMap.name }) })

      worker.background(async () => {
        try {
          setRemixedMapIdClientCookie(childMap.id)
          await setSubmitMap(childMap.id, MapStatus.PENDING)
          await submitRemixMap(geojson, parentMap, childMap)
          await setSubmitMap(childMap.id, MapStatus.READY)
        } catch (error) {
          await setSubmitMap(childMap.id, MapStatus.FAILED)
        }
      })

      onClose?.()
    }
  })

  return (
    <form.Provider>
      <form
        onSubmit={async (event) => {
          event.preventDefault()
          event.stopPropagation()
          await form.handleSubmit()
        }}
      >
        {props.children}
      </form>
    </form.Provider>
  )
}

const toPropertyNames = (properties) => {
  return Object.fromEntries(
    properties.map((property) => [property.name, property.name])
  )
}

const setClassbreak = (properties) => {
  return properties.map((property) => ({
    ...property,
    classBreaks: property.classBreaks ?? undefined
  }))
}

const setDefaultVisualisation = (properties) => {
  if (properties.length === 0) return []
  const hasDefaultVisualisation = properties.some(
    (property) => property.isDefaultVisualisation
  )
  if (hasDefaultVisualisation) return properties
  const [firstProperty, ...restProperties] = [...properties]
  return [
    {
      ...firstProperty,
      isDefaultVisualisation: true
    },
    ...restProperties
  ]
}

const getMapTagCollection = (mapTagCollection) => {
  const items =
    mapTagCollection?.items?.filter(
      (item) => !item.tag.id.startsWith('category')
    ) || []
  return {
    total: items.length,
    items
  }
}

const getTagIds = (mapTagCollection) =>
  mapTagCollection?.items?.map((item) => item.tag.id) || []

export { Field, Form, useField, useForm }
