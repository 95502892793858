import { useEffect, useState } from 'react'
import { useWindowSize } from 'ui-components'

export const useViewState = (map) => {
  const [viewState, setViewState] = useState({})
  const { width, height } = useWindowSize()

  useEffect(() => {
    if (map) {
      map.touchZoomRotate?.disableRotation()
    }
  }, [map])

  useEffect(() => {
    if (!width || !height) return
    const isVertical = width < height
    const padding = isVertical ? 20 : 40
    const tempViewState = {
      ...viewState,
      padding: { top: padding, left: padding, bottom: padding, right: padding }
    }
    setViewState(tempViewState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height])

  return { viewState }
}
