import {
  Alert,
  Box,
  GlobalStyles,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'
import { useState } from 'react'

import BaseCloseButton from '~ui-components/components/molecules/CloseButton'

import { Footer } from './Footer'

const Overrides = (
  <GlobalStyles
    styles={{
      'html, body': {
        overflowY: 'overlay'
      }
    }}
  />
)

const CreateMapLayout = (props) => {
  const {
    missRows,
    showStepCounter,
    title,
    subtitle,
    content,
    steps,
    currentStep,
    isSubmitting,
    disabledBack,
    disabledNext,
    noBack,
    noNext,
    onBackClick,
    onNextClick,
    onSubmitClick,
    displayStep
  } = props

  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  const [dialog, setDialog] = useState(false)
  const { current: currentDisplayStep, total: totalDisplayStep } =
    displayStep || {}

  if (dialog) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto'
        }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
        >
          <Typography
            variant='h5'
            fontWeight='600'
          >
            Unmatched Record
          </Typography>
          <BaseCloseButton onClick={() => setDialog(false)} />
        </Stack>
        <Box mt={1}>
          {missRows.map((row, index) => {
            return <Box key={`missRow-${index}`}>{row}</Box>
          })}
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        p: 3,
        flexDirection: 'column'
      }}
    >
      {isBrowser && <>{Overrides}</>}

      <Box
        component={Paper}
        variant='outlined'
        sx={{
          border: 0,
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: 'min-content',
          backgroundColor: 'transparent'
        }}
      >
        {showStepCounter && (
          <Typography variant='caption'>
            Step {currentDisplayStep} of {totalDisplayStep}
          </Typography>
        )}

        {missRows?.length > 0 && currentStep === 1 && (
          <div>
            <Alert
              severity='warning'
              variant='filled'
              sx={{
                color: 'white !important'
              }}
            >
              {missRows?.length} records were not matched. See{' '}
              <Typography
                component='span'
                sx={{
                  textDecoration: 'underline',
                  color: 'inherit',
                  fontSize: 'inherit',
                  cursor: 'pointer'
                }}
                onClick={() => setDialog(true)}
              >
                unmatched records.
              </Typography>
            </Alert>
          </div>
        )}

        {!!title && (
          <Typography
            data-testid={`Step-title-${title}`}
            variant='h5'
            fontWeight='600'
          >
            {title}
          </Typography>
        )}

        {!!subtitle && (
          <Alert
            severity='info'
            variant='filled'
          >
            <Typography
              variant='body2'
              data-testid={`Step-subtitle-${subtitle}`}
            >
              {subtitle}
            </Typography>
          </Alert>
        )}

        <Stack spacing={2}>{content}</Stack>

        <Footer
          currentStep={currentStep}
          isSubmitting={isSubmitting}
          lastStep={steps.length - 1}
          disabledBack={disabledBack}
          disabledNext={disabledNext}
          noBack={noBack}
          noNext={noNext}
          onBackClick={onBackClick}
          onNextClick={onNextClick}
          onSubmitClick={onSubmitClick}
        />
      </Box>
    </Box>
  )
}

CreateMapLayout.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.node,
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  isSubmitting: PropTypes.bool,
  disabledBack: PropTypes.bool,
  disabledNext: PropTypes.bool,
  onBackClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  displayStep: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number
  })
}

export { CreateMapLayout }
