import { some } from 'lodash'
import { INCLUDE_PROPERTIES_ERRORS } from '~map-creator/core/assets/constants'
import { setIncludePropertyError } from '~map-creator/core/states/properties'

const isContainVisualisationProperties = (store) => {
  const {
    properties: { includedKeys, configurations },
    geojson: { defaultPropertyConfigs }
  } = store.getState()

  const isContainVisualisation = some(includedKeys, (key) => {
    const { visualisation } = defaultPropertyConfigs[key]
    const { type } = configurations[key]
    return visualisation !== 'none' && type !== 'date'
  })

  if (!isContainVisualisation) {
    store.dispatch(
      setIncludePropertyError(
        INCLUDE_PROPERTIES_ERRORS.NO_VISUALISATION_SELECTED
      )
    )
    return false
  }
  return true
}

export { isContainVisualisationProperties }
