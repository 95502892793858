import React from 'react'
import { createNodeHOC, createNodesHOC } from '@udecode/plate-common'

import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6
} from '@udecode/plate-heading'
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph'

import clsx from 'clsx'

import { usePlaceholderState } from './usePlaceholderState'

const Placeholder = (props) => {
  const { children = '', placeholder, nodeProps } = props

  const { enabled } = usePlaceholderState(props)

  return React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      className: child.props.className,
      nodeProps: {
        ...nodeProps,
        className: clsx(enabled && 'TextEditor-ElementPlaceholder'),
        placeholder
      }
    })
  })
}

export const withPlaceholder = createNodeHOC(Placeholder)
export const withPlaceholdersPrimitive = createNodesHOC(Placeholder)

export const withPlaceholders = (components) =>
  withPlaceholdersPrimitive(components, [
    {
      key: ELEMENT_PARAGRAPH,
      placeholder: 'Type a paragraph',
      hideOnBlur: true,
      query: {
        maxLevel: 1
      }
    },
    {
      key: ELEMENT_H1,
      placeholder: 'Heading 1',
      hideOnBlur: false
    },
    {
      key: ELEMENT_H2,
      placeholder: 'Heading 2',
      hideOnBlur: false
    },
    {
      key: ELEMENT_H3,
      placeholder: 'Heading 3',
      hideOnBlur: false
    },
    {
      key: ELEMENT_H4,
      placeholder: 'Heading 4',
      hideOnBlur: false
    },
    {
      key: ELEMENT_H5,
      placeholder: 'Heading 5',
      hideOnBlur: false
    },
    {
      key: ELEMENT_H6,
      placeholder: 'Heading 6',
      hideOnBlur: false
    }
  ])
