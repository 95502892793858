import { styled } from '@mui/material'
import React from 'react'
import SimpleBar from 'simplebar-react'

const StyledScrollbar = styled(SimpleBar)({})

function Scrollbar(props: React.ComponentProps<typeof StyledScrollbar>) {
  const { sx, children, ...rest } = props
  return (
    <StyledScrollbar
      {...rest}
      sx={{ height: '100%', maxHeight: '100%', flex: 1, ...sx }}
    >
      {children}
    </StyledScrollbar>
  )
}

export { Scrollbar }
