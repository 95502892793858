import { PlateElement } from '@udecode/plate-common'
import React from 'react'
import Heading from '~ui-components/components/molecules/TextEditor/components/HTML/Heading/default'

const HeadingElement = (props) => {
  const {
    editor,
    element,
    className,
    variant = 'h1',
    children,
    ...rest
  } = props

  const isFirstElement = element === editor.children[0]

  return (
    <PlateElement
      asChild
      element={element}
      {...rest}
    >
      <Heading
        variant={variant}
        disabledGutterTop={isFirstElement}
      >
        {children}
      </Heading>
    </PlateElement>
  )
}

export { HeadingElement }
export default HeadingElement
