import { createApi } from '@reduxjs/toolkit/query/react'
import { getWorkspaceById } from '~map-creator/core/api/getWorkspaceById'

const workspaceApi = createApi({
  reducerPath: 'workspaceApi',
  baseQuery: Promise.resolve,
  endpoints: (builder) => ({
    getWorkspaceById: builder.query({
      queryFn: async (id) => {
        try {
          const data = await getWorkspaceById(id)
          return {
            data
          }
        } catch (error) {
          return { error }
        }
      }
    })
  })
})

export const { useGetWorkspaceByIdQuery } = workspaceApi
export default workspaceApi
