import * as qs from 'qs'
import { slug } from '~utils/functions/slug'

const getEditMapLink = ({ workspaceId, workspaceName, mapId }) => {
  const query = qs.stringify({ action: 'edit' })
  // prettier-ignore
  return `/workspace/${workspaceId}/${slug(workspaceName)}/map/${mapId}?${query}`
}

export { getEditMapLink }
