import { Button } from '@mui/material'

export const LoginButton = (props) => {
  const { onClick = () => {} } = props

  return (
    <Button
      variant='contained'
      data-testid='SignUpButton'
      onClick={onClick}
      color='inherit'
      sx={{ width: '100%', mt: 1 }}
    >
      Log in
    </Button>
  )
}
