import { Box } from '@mui/material'
import { isEmpty } from 'lodash'
import { FC } from 'react'

import { Pagination } from '~ui-components/components/atoms/Pagination'
import {
  EmptyMapListFallback,
  EmptyMapListFallbackProps
} from '~ui-components/components/molecules/EmptyMapListFallback'
import {
  MapListStack,
  MapListStackProps
} from '~ui-components/components/molecules/MapLists/MapListStack'

export type MapListWithFallbackProps = MapListStackProps &
  EmptyMapListFallbackProps & {
    slots?: any
    slotProps?: any
  }

const defaultSlots = {
  Pagination
}

export const MapListWithFallback: FC<MapListWithFallbackProps> = (props) => {
  const {
    isWorkspaceGuest = false,
    loading,
    maps = [],
    noResultsText,
    defaultMapUrl,
    canCreateMap = false,
    onCreateMapClick,
    getMapAvatar,
    slots: Slots = defaultSlots,
    slotProps
  } = props

  if (isEmpty(maps)) {
    return (
      <EmptyMapListFallback
        isWorkspaceGuest={isWorkspaceGuest}
        noResultsText={noResultsText}
        defaultMapUrl={defaultMapUrl}
        canCreateMap={canCreateMap}
        onCreateMapClick={onCreateMapClick}
      />
    )
  }

  return (
    <Box data-testid='MapList'>
      <MapListStack
        loading={loading}
        maps={maps}
        getMapAvatar={getMapAvatar}
      />
      {!isEmpty(maps) && !loading && (
        <Box
          py={3}
          display='flex'
          justifyContent='center'
        >
          <Slots.Pagination {...slotProps?.Pagination} />
        </Box>
      )}
    </Box>
  )
}
