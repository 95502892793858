import * as qs from 'qs'

export function getTagsQuery(params) {
  const { query, sort, page } = params

  const queryString = qs.stringify({
    ...(query?.length ? { query } : {}),
    ...(page ? { page } : {}),
    sort
  })

  return `/tags?${queryString}`
}
