import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import {
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  TextFieldProps
} from '@mui/material'

import { Mail01 } from '@untitled-ui/icons-react'
import { FC } from 'react'

export type InviteEmailInputProps = TextFieldProps & {
  ButtonProps: LoadingButtonProps
}

const InviteEmailInput: FC<InviteEmailInputProps> = (props) => {
  const { ButtonProps, ...rest } = props

  return (
    <Stack
      alignItems='center'
      direction='row'
      spacing={3}
    >
      <TextField
        autoComplete='off'
        color='secondary'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SvgIcon>
                <Mail01 />
              </SvgIcon>
            </InputAdornment>
          )
        }}
        label='Email address'
        name='email'
        type='email'
        sx={{ flexGrow: 1 }}
        {...rest}
      />

      <LoadingButton
        type='submit'
        color='secondary'
        variant='contained'
        {...ButtonProps}
      >
        Invite
      </LoadingButton>
    </Stack>
  )
}

export { InviteEmailInput }
