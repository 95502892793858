import { getFileUploadUrl, uploadFileToS3 } from '~map-creator/core/api'

async function uploadDataIdentifiers(dataId, dataIdentifiers) {
  if (!hasDataIdentifiers(dataIdentifiers)) return

  const { uploadUrl, formFieldsJson } = await getFileUploadUrl(
    `${dataId}:data-identifiers`
  )

  const file = new Blob([JSON.stringify(dataIdentifiers)], {
    type: 'application/json'
  })

  const uploaded = await uploadFileToS3({
    url: uploadUrl,
    formData: JSON.parse(formFieldsJson),
    file
  })

  if (uploaded) return

  throw new Error('CreateMap: fail to upload data identifiers json')
}

function hasDataIdentifiers(dataIdentifiers) {
  return dataIdentifiers?.length
}

export { uploadDataIdentifiers }
