import { Box, Container, Stack } from '@mui/material'
import { FC } from 'react'

type CreateCategoryProps = {
  slots: {
    Header: FC
    Form: FC
  }
}

const CreateCategory: FC<CreateCategoryProps> = ({
  slots: { Header, Form }
}) => {
  return (
    <Box
      component='main'
      sx={{ flexGrow: 1, py: 8 }}
    >
      <Container maxWidth='xl'>
        <Stack spacing={3}>
          <Header />
          <Form />
        </Stack>
      </Container>
    </Box>
  )
}

export { CreateCategory }
