import { ELEMENT_OL, toggleList } from '@udecode/plate'

import { focusEditor } from '@udecode/plate-common'
import { useEditorState } from '@udecode/plate-core'

import Action from '~ui-components/components/molecules/TextEditor/components/Action'

const OrderedList = (props) => {
  const editor = useEditorState()

  const handleClick = () => {
    toggleList(editor, {
      type: ELEMENT_OL
    })
    focusEditor(editor)
  }

  return (
    <Action
      variant='orderedList'
      onClick={handleClick}
      {...props}
    />
  )
}

export { OrderedList }
