import { SeoProps } from 'ui-components'
import { getMapLink, getMapThumbnailLink } from 'utils'

import { Map } from '~map-viewer/types/__generated/gql/graphql'

const generateOpenGraph = (
  baseUrl: string | undefined,
  map: Map | null,
  visualisation
): SeoProps => {
  try {
    if (!map) throw new Error('Map data is required to generate open graph')

    const httpProtocol =
      process.env.NODE_ENV === 'development' ? 'http://' : 'https://'

    const thumbnailUrl = getMapThumbnailLink(map)

    const { id: mapId, name: mapName, parentMap } = map
    const description = getDescription(map, visualisation)

    const canonical = parentMap
      ? getMapLink({ mapId: parentMap.id, mapName: parentMap.name })
      : undefined

    return {
      url: httpProtocol + baseUrl + getMapLink({ mapId, mapName }),
      title: `${mapName} | Mapstack`,
      description,
      image: {
        width: 1200,
        height: 630,
        url: thumbnailUrl,
        secureUrl: thumbnailUrl,
        type: 'image/png',
        alt: 'Interactive Map from mapstack'
      },
      ...(canonical ? { canonical } : {})
    }
  } catch (error) {
    console.warn(error)
    return {}
  }
}

const getDescription = (map, visualisation) => {
  const {
    mapTagCollection: { items }
  } = map

  const mapTitle = visualisation ? `${map.name} : ${visualisation}` : map.name

  const coverageTag = items.find((item) => item.tag.type === 'coverageArea')

  const featureTypeTag = items.find((item) => item.tag.type === 'featureType')

  const topicTags = items
    .filter((item) => item.tag.type === 'topic')
    .map((item) => item.tag.name)

  if (!coverageTag && !featureTypeTag) {
    return `Explore and interact with the map ${map.name}. Visualize the data, uncover insights, and easily share your findings.`
  }

  return `An interactive map of ${coverageTag?.tag?.name} by ${
    featureTypeTag?.tag?.name
  } that shows ${mapTitle} covering ${topicTags.join(',')}`
}

export { generateOpenGraph }
export default generateOpenGraph
