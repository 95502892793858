const ListOrdered = () => (
  <svg
    data-v-e087307f=''
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <line
      x1='10'
      x2='21'
      y1='6'
      y2='6'
    ></line>
    <line
      x1='10'
      x2='21'
      y1='12'
      y2='12'
    ></line>
    <line
      x1='10'
      x2='21'
      y1='18'
      y2='18'
    ></line>
    <path d='M4 6h1v4'></path>
    <path d='M4 10h2'></path>
    <path d='M6 18H4c0-1 2-2 2-3s-1-1.5-2-1'></path>
  </svg>
)

export default ListOrdered
