import { Box } from '@mui/material'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01'

type NavButtonProps = IconButtonProps

const NavButton = (props: NavButtonProps) => {
  const { onClick } = props

  return (
    <Box sx={{ textAlign: 'center' }}>
      <IconButton onClick={onClick}>
        <SvgIcon>
          <Menu01Icon />
        </SvgIcon>
      </IconButton>
    </Box>
  )
}

export { NavButton }
