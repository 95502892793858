import {
  Avatar,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { Grid01 } from '@untitled-ui/icons-react'
import NextLink from 'next/link'
import { FC } from 'react'
import { getWorkspaceLink } from 'utils'
import { SeverityPill } from '~ui-components/devias-components/severity-pill'
import { Member_Role } from '~ui-components/types/__generated/gql/graphql'

export type WorkspaceListStackProps = {
  memberships?: Array<{
    role: Member_Role
    workspace: {
      id: string
      name: string
      logo: string
    }
  }>
}

const WorkspaceListStack: FC<WorkspaceListStackProps> = ({
  memberships = []
}) => {
  return (
    <Table
      data-testid='WorkspaceListStack'
      sx={{ minWidth: 400 }}
    >
      <TableHead>
        <TableRow>
          <TableCell>Member</TableCell>
          <TableCell>Role</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {memberships.map((membership, index) => (
          <TableRow
            key={index}
            data-testid={`ListItemWorkspace-${index}`}
          >
            <TableCell>
              <Stack
                alignItems='center'
                direction='row'
                spacing={1}
              >
                <Avatar
                  src={membership.workspace.logo}
                  sx={{ height: 40, width: 40 }}
                >
                  <SvgIcon>
                    <Grid01 />
                  </SvgIcon>
                </Avatar>

                <Typography
                  component={NextLink}
                  href={getWorkspaceLink({
                    workspaceId: membership.workspace.id,
                    workspaceName: membership.workspace.name
                  })}
                  variant='subtitle2'
                  sx={{
                    color: 'text.primary',
                    textDecoration: 'none',
                    ':hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  {membership.workspace.name}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell>
              {membership.role === Member_Role.Owner ? (
                <SeverityPill>{membership.role}</SeverityPill>
              ) : (
                membership.role
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export { WorkspaceListStack }
