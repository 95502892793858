import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'

export const getQueryParamsKey = () => ['getQueryParams']

export function useQueryParams<T extends Record<string, any>>() {
  const { query } = useRouter()
  const { data: ssrQuery } = useQuery({
    queryKey: getQueryParamsKey()
  })

  return (query || ssrQuery) as T
}
