import jmespath from 'jmespath'
import * as JsSearch from 'js-search'
import _ from 'lodash'
import { Chance } from 'chance'
import { useEffect, useRef, useState, useMemo } from 'react'

const chance = new Chance()

const useJsSearch = () => {
  const reference = useRef<JsSearch.Search | null>(null)
  const [instance, setInstance] = useState<JsSearch.Search | null>(null)

  useEffect(() => {
    if (reference.current === null) {
      const searchInstance = new JsSearch.Search('id')
      reference.current = searchInstance
      setInstance(searchInstance)
    }
  }, [])

  return instance
}

export function useJsonSearch({ map, layersFeatures }) {
  const jsSearch = useJsSearch()

  useEffect(() => {
    if (jsSearch && map && map.properties) {
      addKeyToSearchIndex(map.properties)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, jsSearch])

  const formatFeatures = (features) => {
    return jmespath.search(features, '[?properties]').map((result) => {
      const {
        geometry,
        properties: { msuuid, MS_uuid, ...rest }
      } = result
      return {
        id: msuuid ?? MS_uuid ?? chance.guid(),
        geometry: geometry,
        properties: rest
      }
    })
  }
  const formattedFeatures = useMemo(() => {
    return layersFeatures ? formatFeatures(layersFeatures) : []
  }, [layersFeatures])

  useEffect(() => {
    if (formattedFeatures.length > 0 && jsSearch) {
      jsSearch.addDocuments(formattedFeatures)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedFeatures.length, jsSearch])

  const searchJson = (queryText: string) => {
    if (!jsSearch) return []

    const results = jsSearch.search(queryText).slice(0, 3)
    return results.map((result) => {
      result['properties'] = _.omitBy(result['properties'], (_, k) =>
        k.startsWith('MS_')
      )
      return result
    })
  }

  const addKeyToSearchIndex = (properties: Array<any> = []) => {
    const propertyNames = properties.map((property) => property.name)
    propertyNames.forEach((name) => jsSearch!.addIndex(['properties', name]))
  }

  return { searchJson }
}
