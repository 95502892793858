import { Box, useTheme } from '@mui/material'
import { PlateContent } from '@udecode/plate-common'

import clsx from 'clsx'

const Main = (props) => {
  const {} = props

  const theme = useTheme()

  return (
    <Box
      component='main'
      sx={[
        {
          flex: 1,
          '.TextEditor-ElementPlaceholder': {
            '&:before': {
              position: 'absolute',
              cursor: 'text',
              opacity: 0.3,
              content: 'attr(placeholder)',
              fontSize: 'inherit'
            }
          }
        }
      ]}
    >
      <PlateContent
        data-testid='TextEditor-ContentEditable'
        className={clsx('TextEditor-ContentEditable')}
        style={theme.unstable_sx({
          outline: 'none',
          minHeight: '140px'
        })}
      />
    </Box>
  )
}

export { Main }
