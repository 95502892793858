import { selectEditor } from '@udecode/plate-common'
import { useEditorRef } from '@udecode/plate-core'
import { useEffect } from 'react'

const cursorAtSelectionOrEnd = (editor, selection) => {
  selectEditor(editor, {
    at: editor.selection || undefined,
    edge: editor.selection ? undefined : 'end',
    focus: true
  })
}

function AutofocusPlugin() {
  const editor = useEditorRef()

  useEffect(() => {
    editor && cursorAtSelectionOrEnd(editor)
  }, [])

  return <></>
}

export { AutofocusPlugin, cursorAtSelectionOrEnd }
