import jmespath from 'jmespath'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useJsonFeatures } from '~map-filter-view/states/map'

const query = `[*].properties."$1" | { min: min(@), max: max(@) }`

const getDateQuery = (property) => {
  return query.replace('$1', property)
}

const useDateOperator = () => {
  const jsonFeatures = useJsonFeatures()
  const features = useMemo(() => {
    if (isEmpty(jsonFeatures)) return {}
    return jsonFeatures
  }, [jsonFeatures])

  const getDateRange = (property) => {
    const dateRange = jmespath.search(features, getDateQuery(property))
    return { minDate: dateRange.min, maxDate: dateRange.max }
  }

  return {
    getDateRange
  }
}
export { useDateOperator }
export default useDateOperator
