import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  WorkspaceCreatorController as Controller,
  WorkspaceCreatorMembersForm as Form,
  WorkspaceCreatorStepLayout as Layout
} from '~ui-components/components/organisms/WorkspaceCreatorPanels'

const WorkspaceCreatorStepMembers = (props) => {
  const {
    name,
    values = [],
    onChange,
    onValidate,
    onNextClick,
    onCopyClick,
    onSkipClick
  } = props

  const [loading, setLoading] = useState(false)

  const handleNextClick = async () => {
    setLoading(true)
    await onNextClick()
    setLoading(false)
  }

  return (
    <Layout
      step={3}
      totals={4}
      form={
        <Form
          disabled={loading}
          name={name}
          values={values}
          onChange={onChange}
          onValidate={onValidate}
        />
      }
      controller={
        <Controller
          next
          copy
          skip
          loadingNext={loading}
          disabledNext={isEmpty(values) || loading}
          disabledCopy={loading}
          disabledSkip={loading}
          onNextClick={handleNextClick}
          onCopyClick={onCopyClick}
          onSkipClick={onSkipClick}
        />
      }
    />
  )
}

const WorkspaceCreatorStepMembersPropTypes = {
  name: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func,
  onValidate: PropTypes.func,
  onNextClick: PropTypes.func,
  onCopyClick: PropTypes.func,
  onSkipClick: PropTypes.func
}

WorkspaceCreatorStepMembers.propTypes = WorkspaceCreatorStepMembersPropTypes

export { WorkspaceCreatorStepMembers }
export default WorkspaceCreatorStepMembers
