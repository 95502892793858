import { isEmpty } from 'lodash'
import { GEOJSON_ERRORS } from '../assets/constants'
import { sanitizeGeojsonFile } from '../geojson-sanitize'
import { isJsonObject, mapResultErrors } from '../helpers/'
import { isContainValidColumn } from './containValidColumn'
import { geoTypes } from './geoTypes'
import { nonGeoTypes } from './nonGeoTypes'

/**
 * Determines if an object is a GeoJSON Object or not
 * @method validate
 * @param geojson {Object}
 * @return {Object}
 */
const validate = (geojson, options) => {
  let errors = []
  if (!isJsonObject(geojson)) {
    errors.push([
      GEOJSON_ERRORS.NOT_JSON_OBJECT.key,
      GEOJSON_ERRORS.NOT_JSON_OBJECT.description
    ])
  } else {
    if ('type' in geojson) {
      if (nonGeoTypes[geojson.type]) {
        errors = nonGeoTypes[geojson.type](geojson)
      } else if (geoTypes[geojson.type]) {
        errors = geoTypes[geojson.type](geojson)
      } else {
        errors.push([
          GEOJSON_ERRORS.NOT_SUPPORTING_TYPE.key,
          GEOJSON_ERRORS.NOT_SUPPORTING_TYPE.description
        ])
      }
    } else {
      errors.push([
        GEOJSON_ERRORS.MISSING_MEMBER_TYPE.key,
        GEOJSON_ERRORS.MISSING_MEMBER_TYPE.description
      ])
    }
    if (isEmpty(errors)) {
      const geojsonPropertyErrors = isContainValidColumn(geojson)
      if (geojsonPropertyErrors.length) errors.push(...geojsonPropertyErrors)
    }
  }

  const { sanitize = false, fileName = '', traceNumber = 5 } = options || {}
  const mappedErrors = mapResultErrors(errors, traceNumber)
  if (!sanitize) {
    return { errors: mappedErrors }
  }
  const sanitizeGeojson = sanitizeGeojsonFile(geojson, fileName, mappedErrors)
  const { data, file } = sanitizeGeojson
  return { data, file, errors: mappedErrors }
}

const geojson = {
  validate,
  geoTypes
}

export { validate }
export default geojson
