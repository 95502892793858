import { isNil } from 'lodash'
import { formatDate } from '../formatDate'
import { formatInteger } from '../formatInteger'
import { formatNumber } from '../formatNumber'

const formatFeaturePropertyValue = ({ value, type, fallback = '~' }) => {
  if (!DEFINED_TYPES.includes(type))
    throw new Error(`Type ${type} is not defined`)

  if (isFalsy(value)) return fallback ?? value

  return formatValue[type](value, fallback)
}

const DEFINED_TYPES = [
  'integer',
  'float',
  'number',
  'date',
  'text',
  'object',
  'boolean'
]

const formatValue = {
  integer: formatInteger,
  float: formatNumber,
  number: formatNumber,
  date: formatDate,
  text: (value) => value,
  object: (value) => value,
  boolean: (value) => value
}

const isFalsy = (value) => {
  return (
    isNil(value) || value === '' || (typeof value === 'number' && isNaN(value))
  )
}

export { formatFeaturePropertyValue }
