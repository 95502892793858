import { graphierQL } from 'utils'
import {
  SearchTagsQuery,
  SearchTagsQueryVariables
} from '~map-creator/types/__generated/gql/graphql'

const searchTags = async ({ from, size = 10, query, types = ['topic'] }) => {
  const response = await graphierQL<SearchTagsQuery, SearchTagsQueryVariables>({
    query: searchTagsQuery,
    variables: {
      from,
      size,
      query,
      types: types as SearchTagsQueryVariables['types']
    }
  })
  return response.searchTags
}

const searchTagsQuery = /* GraphQL */ `
  query SearchTags(
    $from: Int
    $size: Int
    $query: String
    $types: [TAG_TYPES!]
  ) {
    searchTags(from: $from, size: $size, query: $query, types: $types) {
      items {
        id
        name
        count
        description
        type
      }
      total
    }
  }
`
export { searchTags, searchTagsQuery }
export default searchTags
