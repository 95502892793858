import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import User03Icon from '@untitled-ui/icons-react/build/esm/User03'
import { getUserLink } from 'utils'
import { RouterLink } from '~ui-components/devias-components/router-link'
import { User } from '~ui-components/types/__generated/gql/graphql'

import type { FC } from 'react'

type AccountPopoverUser = Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>

interface Props {
  onClose?: () => void
  user?: AccountPopoverUser
}

export const UserProfileLink: FC<Props> = (props) => {
  const { onClose, user } = props

  return (
    <>
      {!!user && (
        <Box>
          <ListItemButton
            component={RouterLink}
            href={getUserLink({
              firstName: user.firstName!,
              lastName: user.lastName!,
              userId: user?.id
            })}
            onClick={onClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize='small'>
                <User03Icon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant='body1'>Profile</Typography>}
            />
          </ListItemButton>
        </Box>
      )}
    </>
  )
}
