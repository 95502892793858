import { useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { current } from 'immer'
import { useImmer } from 'use-immer'
import { dayjs } from 'utils'

import DateTimePicker from '~ui-components/components/atoms/DateTimePicker'

const CustomDateTimePicker = (props) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('mobile'))

  const [open, setOpen] = useState(false)

  return (
    <DateTimePicker
      variant={mobile ? 'mobile' : 'desktop'}
      open={open}
      slotProps={{
        popper: {
          disablePortal: true
        },
        toolbar: {
          hidden: true
        },
        tabs: {
          hidden: true
        },
        textField: {
          readOnly: true,
          sx: {
            '.MuiInputBase-root': {
              cursor: 'pointer'
            },
            ...props.sx
          },
          inputProps: {
            sx: {
              cursor: 'pointer'
            }
          },
          onClick: () => setOpen(true)
        }
      }}
      format='LL'
      views={['year', 'month', 'day']}
      onClose={() => {
        setOpen(false)
      }}
      onOpen={() => {
        setOpen(true)
      }}
      {...props}
    />
  )
}

const DateInput = (props) => {
  const { defaultProperty, onApplyFilter = () => {} } = props

  const [, setState] = useImmer({
    minDate: defaultProperty.minDate,
    maxDate: defaultProperty.maxDate
  })

  const handleMinDateChange = (value) => {
    if (!dayjs(value).isValid()) return

    setState((draft) => {
      draft.minDate = value.toISOString()
      onApplyFilter({ type: 'change' }, current(draft))
    })
  }

  const handleMaxDateChange = (value) => {
    if (!dayjs(value).isValid()) return

    setState((draft) => {
      draft.maxDate = value.toISOString()
      onApplyFilter({ type: 'change' }, current(draft))
    })
  }

  return (
    <>
      <CustomDateTimePicker
        sx={{ width: '100%' }}
        label='From'
        defaultValue={dayjs(
          defaultProperty?.value?.minDate || defaultProperty?.minDate
        )}
        minDate={dayjs(defaultProperty?.minDate)}
        maxDate={dayjs(defaultProperty?.maxDate)}
        onChange={handleMinDateChange}
      />

      <CustomDateTimePicker
        sx={{ width: '100%', mt: 2 }}
        label='To'
        defaultValue={dayjs(
          defaultProperty?.value?.maxDate || defaultProperty?.maxDate
        )}
        minDate={dayjs(defaultProperty?.minDate)}
        maxDate={dayjs(defaultProperty?.maxDate)}
        onChange={handleMaxDateChange}
      />
    </>
  )
}

export { DateInput }
export default DateInput
