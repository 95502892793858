import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import { FC, Fragment } from 'react'
import { PostCard } from '~ui-components/components/molecules/PostCard'
import Footer from '~ui-components/components/organisms/Footer'
import { Post } from '~ui-components/components/pages/PostId/types/blog'

const Main = (props) => {
  return (
    <Box
      component='main'
      {...props}
      sx={{
        pt: 'env(safe-area-inset-top)'
      }}
    />
  )
}

const Blog = ({
  posts = [],
  slots = { header: () => null }
}: {
  posts: Post[]
  slots: { header: FC }
}) => {
  return (
    <Fragment>
      <slots.header />
      <Main position='relative'>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            py: 8
          }}
        >
          <Container
            sx={{ mt: 8 }}
            maxWidth='lg'
          >
            <Typography variant='h4'>Latest Articles: </Typography>
            <Typography
              color='text.secondary'
              sx={{ mt: 2 }}
              variant='body1'
            >
              Discover the latest news, tips and insights at Mapstack.
            </Typography>
            <Divider sx={{ my: 4 }} />
            <Grid
              container
              spacing={4}
            >
              {posts.map((post) => (
                <Grid
                  key={post.title}
                  xs={12}
                  md={6}
                >
                  <PostCard
                    id={post.id}
                    authorAvatar={post.author.avatar}
                    authorName={post.author.name}
                    category={post.category}
                    cover={post.cover}
                    publishedAt={post.publishedAt}
                    readTime={post.readTime}
                    shortDescription={post.shortDescription}
                    title={post.title}
                    sx={{ height: '100%' }}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Main>
      <Footer />
    </Fragment>
  )
}

export { Blog }
