import { Box, Button, SvgIcon, useMediaQuery, useTheme } from '@mui/material'
import { LayersThree01 } from '@untitled-ui/icons-react'
import { AnimationSequence, animate } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

type Varaint = 'icon' | 'iconText'

const MapSettingsButton = (props) => {
  const { sx, ...rest } = props

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down(685))

  const [variant, setVariant] = useState<Varaint>(mobile ? 'icon' : 'iconText')
  const buttonRef = useRef<HTMLElement>(null)
  const textRef = useRef<HTMLElement>(null)

  const toIcon = () => {
    const sequence: AnimationSequence = [
      [buttonRef.current!, { width: [176, 56] }],
      [textRef.current!, { visibility: 'hidden' }]
    ]

    animate(sequence, { duration: 0.4 })
  }

  const toIconText = () => {
    const sequence: AnimationSequence = [
      [buttonRef.current!, { width: [56, 176] }, { at: 0, duration: 0.4 }],
      [
        textRef.current!,
        { visibility: 'visible', y: ['50%', '0%'], opacity: [0.8, 1] },
        { at: 0, ease: 'easeInOut', duration: 0.2 }
      ]
    ]
    animate(sequence, { duration: 0.4 })
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      toIcon()
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    switch (variant) {
      case 'icon': {
        toIcon()
        break
      }
      case 'iconText': {
        toIconText()
        break
      }
    }
  }, [variant])

  return (
    <Button
      ref={buttonRef}
      color='secondary'
      sx={[
        {
          p: 2,
          borderRadius: 4,
          whiteSpace: 'nowrap',
          height: '56px',
          overflow: 'hidden',
          textTransform: 'uppercase',
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      data-testid='MapSettingsButton'
      onMouseOver={() => !mobile && setVariant('iconText')}
      onMouseLeave={() => setVariant('icon')}
      {...rest}
    >
      <SvgIcon>
        <LayersThree01 />
      </SvgIcon>

      <Box
        component='span'
        ref={textRef}
        sx={{ width: '0px', position: 'absolute', left: 48 }}
      >
        Visualize Data
      </Box>
    </Button>
  )
}

export { MapSettingsButton }
