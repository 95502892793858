import { graphierQL } from 'utils'
import {
  UnlikeMapMutation,
  UnlikeMapMutationVariables
} from '~map-viewer/types/__generated/gql/graphql'

const unlikeMap = async (id) => {
  const { unlikeMap } = await graphierQL<
    UnlikeMapMutation,
    UnlikeMapMutationVariables
  >({
    useToken: true,
    query: unlikeMapMutation,
    variables: { id }
  })

  return unlikeMap
}

const unlikeMapMutation = /* GraphQL */ `
  mutation UnlikeMap($id: ID!) {
    unlikeMap(id: $id)
  }
`

export { unlikeMap, unlikeMapMutation }
export default unlikeMap
