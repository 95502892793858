import { dehydrate } from '@tanstack/react-query'
import { getProps } from 'ssr/getProps'
import {
  getMapLink,
  getRemixedMapIdFromRequestCookies,
  isRedirectedFromPointsMapEditor,
  isRedirectedFromPolygonsMapEditor,
  slug
} from 'utils'
import { runWithAmplifyServerContext, serverClient } from 'web-amplify/server'

import { AuthSession } from 'aws-amplify/auth'
import { fetchAuthSession } from 'aws-amplify/auth/server'
import { getMapByIdQuery } from '~map-viewer/api/getMapById'
import { Map } from '~map-viewer/types/__generated/gql/graphql'
import generateOpenGraph from '../generateOpenGraph'

type Params = {
  mapId: string
  rest: string[]
  hashed: string
}

const getServerSideProps = getProps(async (context, client, { userId }) => {
  const { params, req, query } = context || {}
  const { mapId, rest, hashed } = params as Params
  const { referer, host } = req.headers || {}
  const { embedKey } = query

  const [mapName, visualisation] = rest || []

  if (!mapId) return { notFound: true }

  let map: Map | null = null

  try {
    map = await runWithAmplifyServerContext({
      nextServerContext: { response: context.res, request: context.req },
      operation: async (ctx) => {
        let session: AuthSession | null
        let token: string | undefined
        let authenticated: boolean

        try {
          session = await fetchAuthSession(ctx)
          token = session.tokens?.idToken?.toString()
          authenticated = (token?.length ?? 0) > 0
        } catch (error) {
          session = null
          token = undefined
          authenticated = false
        }

        const {
          data: { getMapById }
        } = (await serverClient.graphql(ctx, {
          query: getMapByIdQuery,
          variables: {
            id: mapId,
            embedKey
          },
          ...(authenticated
            ? {
                authMode: 'userPool',
                authToken: token as string
              }
            : {
                authMode: 'apiKey'
              })
        })) as any

        const { readme } = getMapById

        if (readme) readme.nodes = readme.nodes.map((node) => JSON.parse(node))

        return {
          ...getMapById,
          readme
        }
      }
    })
  } catch ({ errors }: any) {
    console.log({ errors })
  }

  if (map && mapName !== slug(map.name)) {
    return {
      redirect: {
        destination: getMapLink({ mapId, mapName: map.name }),
        statusCode: 301
      }
    }
  }

  const isRemixedMap = mapId === getRemixedMapIdFromRequestCookies(req.cookies)
  const isRedirectFromPolygonsMapEditor = isRedirectedFromPolygonsMapEditor(
    map,
    referer
  )
  const isRedirectFromPointsMapEditor = isRedirectedFromPointsMapEditor(referer)

  // point map will always have been saved before redirected
  if (
    !isRemixedMap &&
    !isRedirectFromPolygonsMapEditor &&
    !isRedirectFromPointsMapEditor &&
    !map
  ) {
    return { notFound: true }
  }

  const og = generateOpenGraph(host, map, visualisation)

  const shareState = hashed
    ? JSON.parse(Buffer.from(hashed as string, 'base64').toString('utf-8'))
    : {}

  const props = {
    dehydratedState: dehydrate(client),
    mapId,
    userId,
    shareState,
    og,
    map,
    embedKey: embedKey ?? null
  }

  return {
    props
  }
})

export { getServerSideProps }
