import { useEffect, useRef, useState } from 'react'
import { useLayoutEffect } from '~ui-components/hooks/useLayoutEffect'

const round = (value) => Math.round(value)
const isNaN = (value) => Number.isNaN(value)

const useMaxHeight = (params) => {
  const { defaultMaxHeight } = params || {}
  const [ready, setReady] = useState(false)

  const [maxHeight, setMaxHeight] = useState(() => {
    if (isNaN(defaultMaxHeight) || typeof window !== 'undefined') {
      return window.innerHeight
    }
    return 0
  })

  const isValid = maxHeight > 0

  useEffect(() => {
    if (isValid) {
      setReady(true)
    }
  }, [isValid])

  const requestAnimationFrameRef = useRef(0)

  useLayoutEffect(() => {
    // Check the max height is a controlled prop
    if (defaultMaxHeight && isNaN(defaultMaxHeight)) {
      setMaxHeight(round(defaultMaxHeight))
      return
    }
    const handleResize = () => {
      // Throttle resize changes
      if (requestAnimationFrameRef.current) {
        return
      }
      // Throttle state changes using requestAnimationFrame
      requestAnimationFrameRef.current = requestAnimationFrame(() => {
        setMaxHeight(window.innerHeight)
        requestAnimationFrameRef.current = 0
      })
    }
    setMaxHeight(window.innerHeight)
    setReady(true)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      cancelAnimationFrame(requestAnimationFrameRef.current)
    }
  }, [defaultMaxHeight])

  return { maxHeight, isMaxHeightReady: ready }
}

export { useMaxHeight }
