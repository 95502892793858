import { Button, SvgIcon } from '@mui/material'
import { Repeat01 } from '@untitled-ui/icons-react'
import React, { useState } from 'react'

import { UnauthenticatedActionTooltip } from '~ui-components/components/molecules/UnauthenticatedActionTooltip'

interface RemixIconButtonProps extends React.ComponentProps<typeof Button> {
  disablePortal?: boolean
  remixable?: boolean
  onRemix?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onSignInClick?: () => void
  children?: React.ReactNode
}

const RemixIconButton = (props: RemixIconButtonProps) => {
  const {
    disablePortal,
    remixable,
    onRemix,
    onSignInClick,
    children,
    ...rest
  } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    if (!remixable) {
      setAnchorEl(event.currentTarget)
      return
    }

    onRemix?.(event)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <UnauthenticatedActionTooltip
        disablePortal={disablePortal}
        primary='Want to remix this map?'
        secondary='Sign in to get started.'
        anchorEl={anchorEl}
        onClose={handleClose}
        onSignInClick={onSignInClick}
      />

      <Button
        data-testid='RemixIconButton'
        disableElevation
        variant='contained'
        color='inherit'
        startIcon={
          <SvgIcon sx={{ fontSize: '1.25em !important' }}>
            <Repeat01 />
          </SvgIcon>
        }
        onClick={handleClick}
        {...rest}
      >
        {children || 'Remix'}
      </Button>
    </>
  )
}

export { RemixIconButton }
export default RemixIconButton
