import { Filter } from './Filter'
import { Fullscreen } from '~map-viewer/App/Tools/MapActions/FullScreen'
import { Search } from './Search'
import { Table } from './Table'

import { MapActions as View } from 'ui-components'

const MapActions = (props) => {
  return (
    <View
      {...props}
      slots={{
        Filter,
        Search,
        Table,
        Fullscreen
      }}
    />
  )
}

export { MapActions }
export default MapActions
