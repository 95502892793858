import {
  DeleteMapByIdMutation,
  DeleteMapByIdMutationVariables
} from '~map-viewer/types/__generated/gql/graphql'
import { graphierQL } from 'utils'

const deleteMapById = async (mapId: string) => {
  const response = await graphierQL<
    DeleteMapByIdMutation,
    DeleteMapByIdMutationVariables
  >({
    useToken: true,
    query: DELETE_MAP_BY_ID_MUTATION,
    variables: { id: mapId }
  })
  return response
}

const DELETE_MAP_BY_ID_MUTATION = /* GraphQL */ `
  mutation DeleteMapById($id: ID!) {
    deleteMapById(id: $id) {
      id
    }
  }
`

export { DELETE_MAP_BY_ID_MUTATION, deleteMapById }
export default deleteMapById
