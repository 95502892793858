import { graphierQL } from 'utils'
import {
  GetWorkspaceIdQuery,
  GetWorkspaceIdQueryVariables
} from '~map-creator/types/__generated/gql/graphql'

const getWorkspaceById = async (id: string) => {
  const data = await graphierQL<
    GetWorkspaceIdQuery,
    GetWorkspaceIdQueryVariables
  >({
    useToken: true,
    query: getWorkspaceByIdQuery,
    variables: { id }
  })

  return data
}

const getWorkspaceByIdQuery = /* GraphQL */ `
  query GetWorkspaceId($id: ID!) {
    getWorkspaceById(id: $id) {
      id
      logo
      name
      description
      memberCollection {
        items {
          id
          user {
            id
            firstName
            lastName
            avatar
          }
          role
          createdAt
        }
        total
      }
    }
  }
`

export { getWorkspaceById, getWorkspaceByIdQuery }
export default getWorkspaceById
