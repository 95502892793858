import DotsHorizontal from '@untitled-ui/icons-react/build/esm/DotsHorizontal'
import PropTypes from 'prop-types'
import { useState } from 'react'
import ComingSoonSnack from '~ui-components/components/molecules/ComingSoonSnack'
import FloatingButton from '~ui-components/components/molecules/FloatingButton'

import MapActionMenuMore from '../MapActionMenuMore'

const MapActionMore = (props) => {
  const { onReport = () => {} } = props
  const [open, setOpen] = useState(false)
  const [comingSoon, setComingSoon] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleComingSoonClose = () => {
    setOpen(false)
    setComingSoon(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleReport = () => {
    onReport()
    /**
     * Remove this later when implement
     */
    setComingSoon(true)
    setTimeout(() => {
      setComingSoon(false)
    }, 4000)
  }

  return (
    <>
      <ComingSoonSnack
        open={comingSoon}
        onClose={handleComingSoonClose}
      />
      <MapActionMenuMore
        open={open}
        onClose={handleClose}
        onReport={handleReport}
      />
      <FloatingButton
        data-testid='MapActionMore'
        title='More'
        icon={<DotsHorizontal />}
        color='inherit'
        onClick={handleClick}
      />
    </>
  )
}

MapActionMore.propTypes = {
  onDownloadGeojson: PropTypes.func,
  onReport: PropTypes.func
}

export { MapActionMore }
export default MapActionMore
