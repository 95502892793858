import { MARK_UNDERLINE, isMarkActive, toggleMark } from '@udecode/plate'

import { focusEditor } from '@udecode/plate-common'
import { useEditorState } from '@udecode/plate-core'

import Action from '~ui-components/components/molecules/TextEditor/components/Action'

const Underline = (props) => {
  const editor = useEditorState()
  const underlined = editor.selection && isMarkActive(editor, MARK_UNDERLINE)

  const handleClick = () => {
    toggleMark(editor, {
      key: MARK_UNDERLINE
    })
    focusEditor(editor)
  }

  return (
    <Action
      variant='underline'
      toggled={underlined}
      onClick={handleClick}
      {...props}
    />
  )
}

export { Underline }
