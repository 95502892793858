import { validateBbox } from '../isBbox'
import { isGeometryObject } from '../isGeometryObject'
import { isJsonObject } from '../../helpers/isJsonObject'
import { GEOJSON_ERRORS } from '../../assets/constants'
import _ from 'lodash'

/**
 * Determines if an object is a valid Feature
 * @method isFeature
 * @param feature {Object}
 * @return {Boolean}
 */
export const isFeature = (feature) => {
  if (!isJsonObject(feature)) {
    return [
      [
        GEOJSON_ERRORS.NOT_JSON_OBJECT.key,
        GEOJSON_ERRORS.NOT_JSON_OBJECT.description
      ]
    ]
  }

  let errors = []
  if ('bbox' in feature) {
    const e = validateBbox(feature.bbox)
    if (e.length > 0) {
      errors = e
    }
  }

  if ('type' in feature) {
    if (feature.type !== 'Feature') {
      errors.push([
        GEOJSON_ERRORS.MUST_BE_TYPE_FEATURE.key,
        GEOJSON_ERRORS.MUST_BE_TYPE_FEATURE.description
      ])
    }
  } else {
    errors.push([
      GEOJSON_ERRORS.MISSING_MEMBER_TYPE.key,
      GEOJSON_ERRORS.MISSING_MEMBER_TYPE.description
    ])
  }

  if (!('properties' in feature)) {
    // must have a member with the name "properties"
    errors.push([
      GEOJSON_ERRORS.MISSING_MEMBER_PROPERTIES.key,
      GEOJSON_ERRORS.MISSING_MEMBER_PROPERTIES.description
    ])
  }

  if ('geometry' in feature && !_.isEmpty(feature.geometry)) {
    const e = isGeometryObject(feature.geometry)
    if (e.length) {
      errors = errors.concat(e)
    }
  } else {
    errors.push([
      GEOJSON_ERRORS.MISSING_MEMBER_GEOMETRY.key,
      GEOJSON_ERRORS.MISSING_MEMBER_GEOMETRY.description
    ])
  }

  return errors
}
