const Pilcrow = () => (
  <svg
    data-v-e087307f=''
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M13 4v16'></path>
    <path d='M17 4v16'></path>
    <path d='M19 4H9.5a4.5 4.5 0 0 0 0 9H13'></path>
  </svg>
)

export default Pilcrow
