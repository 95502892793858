import { FC, useEffect, useState } from 'react'
import {
  FloatingButton,
  FloatingButtonProps
} from '~ui-components/components/molecules/FloatingButton'

type FloatingToggleButtonProps = FloatingButtonProps & {
  toggle?: boolean
  value?: any
  active?: boolean
  options?: [any, any]
  onClick?: (event: any, value: any) => void
}

const FloatingToggleButton: FC<FloatingToggleButtonProps> = (props) => {
  const {
    active: initialActive = false,
    color = 'inherit',
    options = [],
    onClick = () => {},
    ...rest
  } = props

  const [on, off] = options || [undefined, undefined]
  const [active, setActive] = useState(initialActive)

  const handleClick = (event) => {
    const next = !active
    setActive(next)
    onClick(event, next ? on : off)
  }

  useEffect(() => {
    setActive(initialActive)
  }, [initialActive])

  return (
    <FloatingButton
      {...rest}
      color={color}
      onClick={handleClick}
      data-active={initialActive}
      data-value={initialActive ? on : off}
    />
  )
}

export { FloatingToggleButton }
export default FloatingToggleButton
