import bbox from '@turf/bbox'
import { get } from 'idb-keyval'
import jmespath from 'jmespath'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { hashMD5 } from '~map-creator/core/utilities/hashMD5'

/**
 * Create a string from an object with the following format:
 *
 * @example
 * const object = {
 *   'Population': 'population',
 *   'Country': 'country',
 * }
 * // return
 * const output = '"population": "Population","country": "Country"'
 */
const toQueryString = (object) => {
  return _.chain(object)
    .map((val, key) => `"${val}": "${key}"`)
    .join(',')
    .value()
}

const getDataIdentifiersFromGeojson = (geojson, dataId, propertyName) => {
  const features = _.get(geojson, 'features', {})
  if (_.isEmpty(features) || !propertyName) return []

  const arr = []
  for (const feature of features) {
    arr.push({
      dataId,
      featureId: feature.properties[propertyName],
      bbox: bbox(feature.geometry)
    })
  }
  return arr
}

const getGeojsonFeatures = (features, propertyNames) => {
  const selectPropertyArgs =
    '[*].{geometry: geometry, type:type, properties: properties.{msuuid: msuuid, ' +
    toQueryString(propertyNames) +
    '}}'
  const selectedFeatures = jmespath.search(features, selectPropertyArgs)
  selectedFeatures.forEach((feature) => {
    feature.properties.msuuid = feature.properties.msuuid ?? uuidv4()
  })
  return selectedFeatures
}

/**
 * Remove geometry from feature
 * @returns {Object[]} feature without geometry
 */
const getJsonFeatures = (features) => {
  const clonedFeatures = _.cloneDeep(features)
  // eslint-disable-next-line no-unused-vars
  return clonedFeatures.map(({ geometry, ...rest }) => ({ ...rest }))
}

const extractGeojson = (geojson, propertyNames) => {
  const { features, ...rest } = geojson
  const geojsonFeatures = getGeojsonFeatures(features, propertyNames)
  const jsonFeatures = getJsonFeatures(geojsonFeatures)

  return {
    geojson: { ...rest, features: geojsonFeatures },
    json: { ...rest, features: jsonFeatures }
  }
}

const getHashDataId = (geojson, propertyNames) => {
  const selectPropertyArgs =
    '[*].{geometry: geometry,type:type, properties: properties.{ ' +
    toQueryString(propertyNames) +
    '}}'
  const selectedFeatures = jmespath.search(geojson.features, selectPropertyArgs)
  const geojsonWithoutUuid = {
    ...geojson,
    features: selectedFeatures
  }
  const hashDataId = hashMD5(geojsonWithoutUuid)
  return hashDataId
}

const getFormContext = async (store) => {
  const {
    geojson: { geojsonFileId },
    properties: { includedKeys, configurations, uniquePropertyKey }
  } = store.getState()

  const propertyNames = Object.fromEntries(
    includedKeys.map((k) => [k, configurations[k].name])
  )

  const geojsonObject = await get(`${geojsonFileId}:geojson`)

  const dataId = getHashDataId(geojsonObject, propertyNames)

  const { geojson, json } = extractGeojson(geojsonObject, propertyNames)

  const dataIdentifiers = getDataIdentifiersFromGeojson(
    geojsonObject,
    dataId,
    uniquePropertyKey
  )

  return {
    geojson,
    json,
    dataId,
    dataIdentifiers
  }
}

export { extractGeojson, getFormContext, getHashDataId }
