import { useState, useEffect } from 'react'

/**
 * Hook for abstract the window size logic, and automatically
 * update the windowSize object on window resizing.
 * @returns {object} return window size object
 *
 * @see learn more at {@link https://joshwcomeau.com/react/the-perils-of-rehydration/}
 */
const useWindowSize = () => {
  /** Initialize state with undefined width/height so server
   * and client renders match */
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  // Handler to call on window resize
  function handleResize() {
    // Set window width/height to state
    setTimeout(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }, 0)
  }
  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

export { useWindowSize }
