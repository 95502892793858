import MessageTextSquare01 from '@untitled-ui/icons-react/build/esm/MessageTextSquare01'
import PropTypes from 'prop-types'
import { useState } from 'react'
import ComingSoonSnack from '~ui-components/components/molecules/ComingSoonSnack'
import FloatingButton from '~ui-components/components/molecules/FloatingButton'

const MapActionComment = (props) => {
  const { comments = 0, onClick = () => {} } = props

  const [comingSoon, setComingSoon] = useState(false)

  const handleClick = () => {
    onClick()
    setComingSoon(true)
  }

  const handleClose = () => {
    setComingSoon(false)
  }

  return (
    <>
      <ComingSoonSnack
        autoHideDuration={4000}
        open={comingSoon}
        onClose={handleClose}
      />
      <FloatingButton
        data-testid='MapActionComment'
        title='Comment'
        label={getCommentsCopy(comments)}
        icon={<MessageTextSquare01 />}
        color='inherit'
        onClick={handleClick}
      />
    </>
  )
}

MapActionComment.propTypes = {
  comments: PropTypes.number,
  onClick: PropTypes.func
}

export { MapActionComment }
export default MapActionComment

const getCommentsCopy = (comments) => {
  return String(comments)
}
