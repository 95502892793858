import fscreen from 'fscreen'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { MapActionFullscreen as View } from 'ui-components'
import {
  enterFullScreen,
  exitFullScreen,
  useIsFullscreenActive
} from '~map-viewer/states/ui'

const Fullscreen = (props) => {
  const dispatch = useDispatch()

  const active = useIsFullscreenActive()

  useEffect(() => {
    if (active) {
      if (fscreen.fullscreenEnabled && !fscreen.fullscreenElement)
        fscreen.requestFullscreen(
          document.querySelector('#MapViewportContainer')
        )
    } else {
      if (fscreen.fullscreenEnabled && !!fscreen.fullscreenElement)
        fscreen.exitFullscreen()
    }
  }, [active])

  const handleToggleFullscreen = () => {
    if (active) dispatch(exitFullScreen())
    else dispatch(enterFullScreen())
  }

  return (
    <View
      {...props}
      active={active}
      onToggle={handleToggleFullscreen}
    />
  )
}

export { Fullscreen }
