import { Box } from '@mui/material'
import { Fragment } from 'react'

const defaultSlots = {
  Map: Fragment,
  Form: Fragment
}

const MapCreator = (props) => {
  const { slots = {}, slotProps = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Box
      sx={{
        width: '100dvw',
        height: { xs: '100%', md: '100dvh' },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingLeft: 'env(safe-area-inset-left)',
        paddingRight: 'env(safe-area-inset-right)'
      }}
    >
      <Box
        sx={{
          flex: { md: 1 },
          width: { md: '60dvw' },
          height: { xs: '70dvw', md: '100%' }
        }}
      >
        <Slots.Map {...slotProps.MapProp} />
      </Box>

      <Box
        sx={{
          flex: 1,
          maxWidth: { md: '500px' },
          minWidth: { md: '350px' },
          backgroundColor: (theme) => theme.palette.background.paper
        }}
      >
        <Slots.Form {...slotProps.StepProp} />
      </Box>
    </Box>
  )
}

export { MapCreator }
export default MapCreator
