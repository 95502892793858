import { BoundaryNameLookUp } from '../../../get-boundary-lookup'

// Define the individual country-specific objects
const USBoundaryNames: BoundaryNameLookUp = {
  'MS_us-states': {
    singular: 'State',
    plural: 'States'
  },
  'MS_us-counties': {
    singular: 'County',
    plural: 'Counties'
  },
  'MS_us-zipcodes': {
    singular: 'Zipcode',
    plural: 'Zipcodes'
  }
}

export { USBoundaryNames }
