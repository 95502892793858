/**
 * A truthy test for objects
 * @method isObject
 * @param {Object}
 * @return {Boolean}
 */
function isJsonObject(object) {
  return object === Object(object)
}

export { isJsonObject }
