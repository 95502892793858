import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import type { FC } from 'react'
import { PostContent } from './components/sections/blog/post-content'
import type { Post } from './types/blog'

type Props = {
  post: Post
}

const PostId: FC<Props> = (props) => {
  const { post } = props
  return (
    <>
      <Box
        component='main'
        style={{ paddingBottom: 'env(safe-area-inset-top)' }}
        sx={{
          flexGrow: 1,
          mt: 7,
          py: 8
        }}
      >
        <Container maxWidth='xl'>
          <Stack spacing={3}>
            <Typography variant='h3'>{post.title}</Typography>
            <Typography
              color='text.secondary'
              variant='subtitle1'
            >
              <PostContent content={post.shortDescription} />
            </Typography>
            <Stack
              alignItems='center'
              direction='row'
              spacing={2}
              sx={{ mt: 3 }}
            >
              <Avatar src={post.author.avatar} />
              <Typography variant='subtitle2'>
                By {post.author.name} • {post.publishedAt}
              </Typography>
              <div>
                <Typography
                  color='text.secondary'
                  variant='body2'
                >
                  {post.readTime} read
                </Typography>
              </div>
            </Stack>
          </Stack>
          <Box
            sx={{
              backgroundImage: `url(${post.cover})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              borderRadius: 1,
              height: post.coverHeight || 380,
              mt: 3
            }}
          />
          {post.content && (
            <Container sx={{ py: 3 }}>
              <PostContent content={post.content} />
            </Container>
          )}
          <Divider sx={{ my: 3 }} />
          <Divider sx={{ my: 3 }} />
        </Container>
      </Box>
    </>
  )
}

export default PostId
