import { InputAdornment, SvgIcon, TextField } from '@mui/material'
import { SearchSm } from '@untitled-ui/icons-react'

const SearchMapsInput = (props) => {
  return (
    <TextField
      variant='outlined'
      fullWidth
      autoComplete='off'
      placeholder='Search maps'
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SvgIcon>
              <SearchSm />
            </SvgIcon>
          </InputAdornment>
        )
      }}
    />
  )
}

export { SearchMapsInput }
export default SearchMapsInput
