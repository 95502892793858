import { get, set } from 'idb-keyval'
import { createDataFailSubscription } from '~map-viewer/api/createDataFailSubscription'
import { createDataReadySubscription } from '~map-viewer/api/createDataReadySubscription'
import { MapStatus } from '~map-viewer/constants/MapStatus'
import { setLikes } from '~map-viewer/states/like'
import {
  addDataset,
  setHasDataSet,
  setIsDataLoading,
  setMapData,
  setMapStatus
} from '~map-viewer/states/map'
import { store } from '~map-viewer/states/store'

const getLocalMapById = async (mapId) => {
  const map = await get(mapId)
  return map
}

const saveMapData = async (map, mapStatus) => {
  const dataReady = mapStatus === MapStatus.READY || map.dataReady
  store.dispatch(setMapData(map))
  store.dispatch(setLikes(map.likes))
  store.dispatch(setMapStatus(mapStatus))
  await set(`${map.id}:map-status`, mapStatus)
  await set(map.id, { ...map, dataReady })
}

const saveDataSet = async (dataId) => {
  const dataSet = await get(dataId)
  if (dataSet?.features) store.dispatch(setHasDataSet(true))
  store.dispatch(addDataset(dataId))
  store.dispatch(setIsDataLoading(false))
}

const markMapDataAsFailed = async (map) => {
  const data = { ...map, dataReady: false }
  await saveMapData(data, MapStatus.FAILED)
}

const markMapDataAsSuccessful = async (map) => {
  const data = { ...map, dataReady: true }
  await saveMapData(data, MapStatus.READY)
}

// Create subscription to onMapDataReady
const subscribeToMapData = (map) => {
  const failSubscription = createDataFailSubscription(map.dataId, async () => {
    await markMapDataAsFailed(map)
  })

  const readySubscription = createDataReadySubscription(
    map.dataId,
    async () => {
      await markMapDataAsSuccessful(map)
      readySubscription.unsubscribe()
      failSubscription.unsubscribe()
    }
  )

  return { readySubscription, failSubscription }
}

const getMapStatus = async (map) => {
  if (map.dataReady) return MapStatus.READY
  const status = await get(`${map.id}:map-status`)
  return status
}

export {
  getLocalMapById,
  getMapStatus,
  saveDataSet,
  saveMapData,
  subscribeToMapData
}
