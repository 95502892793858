import NextLink from 'next/link'
import { useState } from 'react'
import { Link } from 'ui-components'
import { getCreateMapGeojsonLink } from 'utils'

import { MapSettings } from '~map-creator/core/App/CreateMap/Steps'
import { SelectDefaultView } from '~map-creator/core/App/CreateMap/Steps/SelectDefaultView'
import { useWorkspaceId } from '~map-creator/core/hooks/useWorkspaceId'
import { useWorkspaceName } from '~map-creator/core/hooks/useWorkspaceName'
import {
  DataUploadCSV,
  IncludePropertiesCsv
} from '~map-creator/map-creator-csv/App/CreateMap/Steps'

const useSteps = (params) => {
  const { goToNextStep } = params

  const workspaceName = useWorkspaceName()
  const workspaceId = useWorkspaceId()

  const [isPropertySettingsValidated, setIsPropertySettingsValidated] =
    useState(false)
  const [isIncludedPropertiesValid, setIsIncludedPropertiesValid] =
    useState(false)
  const [isDefaultViewValid, setIsDefaultViewValid] = useState(false)

  const dataUploadStep = {
    title: "Let's get started by adding some data to your map",
    subtitle: (
      <>
        In this beta release the data must be in <b>CSV format</b> and must have
        a column that contains <b>country names</b>. For other data types please
        use our{' '}
        <Link
          component={NextLink}
          variant='inherit'
          color='inherit'
          sx={{
            typography: 'inherit',
            textDecorationColor: 'rgba(255, 255, 255, 0.2)'
          }}
          href={getCreateMapGeojsonLink({ workspaceId, workspaceName })}
          underline='always'
        >
          GeoJSON upload feature
        </Link>
        .
      </>
    ),
    content: <DataUploadCSV onValidated={goToNextStep} />,
    noBack: true,
    noNext: true,
    showStepCounter: false
  }

  const includePropertiesStep = {
    title: "Let's make the data user friendly",
    subtitle: 'Only include information and provide easy to understand names.',
    content: (
      <IncludePropertiesCsv onValidated={setIsIncludedPropertiesValid} />
    ),
    disabledBack: false,
    disabledNext: !isIncludedPropertiesValid,
    showStepCounter: true
  }

  const selectDefaultViewStep = {
    title: 'Which map view should be the default?',
    subtitle: 'Examples: Population or Total sales.',
    content: <SelectDefaultView onValidated={setIsDefaultViewValid} />,
    disabledBack: false,
    disabledNext: !isDefaultViewValid,
    showStepCounter: true
  }

  const mapSettingsStep = {
    title: 'Name your map',
    subtitle:
      'A good title is descriptive and easy to understand (e.g US County Socioeconomic Map).',
    content: <MapSettings onValidated={setIsPropertySettingsValidated} />,
    disabledBack: false,
    disabledNext: !isPropertySettingsValidated,
    showStepCounter: true
  }

  return [
    dataUploadStep,
    includePropertiesStep,
    selectDefaultViewStep,
    mapSettingsStep
  ]
}

export { useSteps }
