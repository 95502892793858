import { ApplyFiltersInput as Input } from 'ui-components'
import { useFilters } from '~map-filter-view/states/filter'
import { Field } from '../Form'

const ApplyFiltersInput = () => {
  const filters = useFilters()
  const hasFilters = filters.length > 0

  return (
    <Field
      name='filters'
      defaultValue={hasFilters}
    >
      {(field) => {
        return (
          <Input
            disabled={field.form.state.isSubmitting}
            checked={field.state.value}
            onChange={(event, checked) => {
              field.handleChange(checked)
            }}
          />
        )
      }}
    </Field>
  )
}

export { ApplyFiltersInput }
