import { baseUrl } from '~utils/functions/baseUrl'

export const getEmbedMapLink = (mapData: any) => {
  const { id, embedKey } = mapData
  if (!id) return

  const origin = baseUrl()
  return [`${origin}/map/${id}/embed`, embedKey && `?embedKey=${embedKey}`]
    .filter(Boolean)
    .join('')
}
