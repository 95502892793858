import { Box } from '@mui/material'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { CountTextInput } from '~ui-components/components/molecules/CountTextInput'

const WORKSPACE_ERROR = {
  EMPTY_FIELD: 'Workspace name is required field.',
  INVALID_FIELD:
    'Workspace name may contain only characters A-Z, a-z, 0-9, spaces and dashes, but cannot begin or end with a space or dash.'
}

const WorkspaceName = ({ name, onChange }) => {
  const [error, setError] = useState(() => getInitialError(name))
  const validateOnChange = (value) => {
    const message = getError(value, { allowSpacing: true })
    setError(message)
    return isEmpty(message)
  }

  const handleChange = (event) => {
    const value = event.target.value
    validateOnChange(value)
    onChange('name', event.target.value)
  }

  return (
    <>
      <Box
        error={error}
        mt={2}
        inputProps={{
          'data-testid': 'NameTextInput'
        }}
        component={CountTextInput}
        limit={50}
        value={name}
        onChange={handleChange}
        placeholder='London Demographic Maps'
      />
    </>
  )
}

export { WorkspaceName }
export default WorkspaceName

const NOT_ALLOW_SPACE_WORKSPACE_REGEX =
  /[^\s]*^[a-zA-Z0-9]+([\s-]*[a-zA-Z0-9]+)*$/

const ALLOW_SPACE_WORKSPACE_NAME_REGEX =
  /[^\s]*^[a-zA-Z0-9]+([a-zA-Z0-9\s-]+)*$/

const isValidWorkspaceName = (name, options) => {
  const { allowSpacing = false } = options || {}
  const regex = !allowSpacing
    ? NOT_ALLOW_SPACE_WORKSPACE_REGEX
    : ALLOW_SPACE_WORKSPACE_NAME_REGEX
  return RegExp(regex).test(name)
}

const getInitialError = (name) => {
  if (isEmpty(name)) return ''
  if (!isValidWorkspaceName(name, { allowSpacing: false }))
    return WORKSPACE_ERROR.INVALID_FIELD
  return ''
}

const getError = (name, option) => {
  if (!isValidWorkspaceName(name, option)) return WORKSPACE_ERROR.INVALID_FIELD
  if (isEmpty(name)) return WORKSPACE_ERROR.EMPTY_FIELD
  return ''
}
