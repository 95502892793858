import { useQuery } from '@tanstack/react-query'

export const getIsSSRKey = () => ['getIsSSRKey']

export function useIsSSR() {
  const { data: isSSR } = useQuery({
    queryKey: getIsSSRKey()
  })

  return typeof window === 'undefined' && isSSR
}
