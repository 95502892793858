import { cloneDeep, concat } from 'lodash'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { VISUALISATION } from 'utils'
import {
  useDefaultCategoryPropertyKeys,
  useDefaultQuantityPropertyKey
} from '~map-creator/core/states/geojson'
import {
  replaceLastWizardStep,
  useCurrentWizardStep
} from '~map-creator/core/states/map'
import {
  setActiveVisualisationKey,
  setConfigurations,
  useConfigurations,
  useIncludedKeys
} from '~map-creator/core/states/properties'

export const useSkippableVisualisations = (isActivated = false) => {
  const dispatch = useDispatch()

  const currentWizardStep = useCurrentWizardStep()
  const configurations = useConfigurations()
  const defaultQuantityPropertiesKey = useDefaultQuantityPropertyKey()
  const defaultCategoryPropertiesKey = useDefaultCategoryPropertyKeys()
  const includedKeys = useIncludedKeys()

  const quantityPropertyOptions = useMemo(() => {
    return defaultQuantityPropertiesKey.filter(
      (k) => includedKeys.includes(k) && configurations[k].type !== 'date'
    )
  }, [configurations, defaultQuantityPropertiesKey, includedKeys])

  const categoryPropertyOptions = useMemo(() => {
    return defaultCategoryPropertiesKey.filter((k) => includedKeys.includes(k))
  }, [defaultCategoryPropertiesKey, includedKeys])

  const updateVisualisation = (key, visualisation) => {
    const newConfig = cloneDeep(configurations)

    newConfig[key].visualisation = visualisation

    dispatch(setActiveVisualisationKey(key))
    dispatch(setConfigurations(newConfig))
  }

  if (
    isActivated &&
    concat(quantityPropertyOptions, categoryPropertyOptions).length <= 1
  ) {
    const nextStep = currentWizardStep + 1
    dispatch(replaceLastWizardStep(nextStep))
    if (quantityPropertyOptions.length === 1) {
      updateVisualisation(quantityPropertyOptions[0], VISUALISATION.QUANTITY)
    } else if (categoryPropertyOptions.length === 1) {
      updateVisualisation(categoryPropertyOptions[0], VISUALISATION.CATEGORY)
    }
  }
}
