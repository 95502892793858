import { GEOJSON_ERRORS } from '../../assets/constants'
import { validateBbox } from '../isBbox'
import { isMultiPolygonCoor } from '../isMultiPolygonCoor'
import { isJsonObject } from '../../helpers/isJsonObject'

/**
 * Determines if an object is a valid MultiPolygon
 * @method isMultiPolygon
 * @param multiPolygon {Object}
 * @return {Boolean}
 */
export const isMultiPolygon = (multiPolygon) => {
  if (!isJsonObject(multiPolygon)) {
    return [
      [
        GEOJSON_ERRORS.NOT_JSON_OBJECT.key,
        GEOJSON_ERRORS.NOT_JSON_OBJECT.description
      ]
    ]
  }

  let errors = []
  if ('bbox' in multiPolygon) {
    const e = validateBbox(multiPolygon.bbox)
    if (e.length > 0) {
      errors = e
    }
  }

  if ('type' in multiPolygon) {
    if (multiPolygon.type !== 'MultiPolygon') {
      errors.push([
        GEOJSON_ERRORS.MUST_BE_TYPE_MULTI_POLYGON.key,
        GEOJSON_ERRORS.MUST_BE_TYPE_MULTI_POLYGON.description
      ])
    }
  } else {
    errors.push([
      GEOJSON_ERRORS.MISSING_MEMBER_TYPE.key,
      GEOJSON_ERRORS.MISSING_MEMBER_TYPE.description
    ])
  }

  if ('coordinates' in multiPolygon) {
    const e = isMultiPolygonCoor(multiPolygon.coordinates)
    if (e.length) {
      errors = errors.concat(e)
    }
  } else {
    // must have a member with the name "coordinates"
    errors.push([
      GEOJSON_ERRORS.MISSING_MEMBER_COORDINATE.key,
      GEOJSON_ERRORS.MISSING_MEMBER_COORDINATE.description
    ])
  }

  return errors
}
