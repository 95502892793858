import { Button, SvgIcon } from '@mui/material'
import Download02 from '@untitled-ui/icons-react/build/esm/Download02'

const DownloadButton = (props) => {
  return (
    <Button
      variant='contained'
      color='inherit'
      disableElevation
      startIcon={
        <SvgIcon sx={{ fontSize: '1.25em !important' }}>
          <Download02 />
        </SvgIcon>
      }
      {...props}
    >
      Download
    </Button>
  )
}

export { DownloadButton }
export default DownloadButton
