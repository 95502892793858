import { IconButton, SvgIcon } from '@mui/material'
import Menu01 from '@untitled-ui/icons-react/build/esm/Menu01'
import XClose from '@untitled-ui/icons-react/build/esm/XClose'
import PropTypes from 'prop-types'

const MenuToggleButton = (props) => {
  const { toggled, onClick } = props

  return (
    <IconButton
      sx={{
        borderRadius: '99px',
        width: '32px',
        height: '32px',
        mr: 1
      }}
      onClick={onClick}
    >
      <SvgIcon>{toggled ? <XClose /> : <Menu01 />}</SvgIcon>
    </IconButton>
  )
}

MenuToggleButton.propTypes = {
  toggled: PropTypes.bool,
  onClick: PropTypes.func
}

export { MenuToggleButton }
export default MenuToggleButton
