import Masonry from '@mui/lab/Masonry'
import { Stack, Typography } from '@mui/material'
import { getMapLink, getMapThumbnailLink, getWorkspaceLink } from 'utils'
import { Card } from '~ui-components/components/molecules/Card'
import { CardSkeleton } from '~ui-components/components/molecules/Card/Skeleton'

const ExampleMaps = (props) => {
  const { maps, isLoading } = props
  return (
    <Stack
      direction='column'
      gap={4}
    >
      <Stack gap={4}>
        <Typography
          variant='h2'
          sx={{
            fontSize: 44
          }}
          textAlign='center'
        >
          Get Inspired with Mapstack
        </Typography>

        <Typography
          color='text.secondary'
          variant='subtitle1'
          sx={{
            fontSize: 30,
            letterSpacing: 'tight',
            textAlign: 'center'
          }}
        >
          Explore a gallery of stunning maps created by our users and discover
          the endless possibilities with Mapstack.
        </Typography>
      </Stack>

      <Masonry
        columns={{ xs: 1, md: 2, lg: 3 }}
        spacing={4}
        sx={{ margin: 'unset' }}
      >
        {isLoading
          ? Array(6)
              .fill(null)
              .map((_, index) => <CardSkeleton key={index} />)
          : maps.map((map) => (
              <Card
                key={map.id}
                title={map.name}
                href={`
            ${getMapLink({
              mapId: map.id,
              mapName: map.name
            })!}`}
                cover={getMapThumbnailLink(map)}
                avatar={{
                  image: map.workspace.logo!,
                  name: map.workspace.name!,
                  href: getWorkspaceLink({
                    workspaceId: map.workspace.id!,
                    workspaceName: map.workspace.name!
                  }),
                  updatedAt: map.updatedAt
                }}
                footer={{
                  likes: map.likes!,
                  views: map.views!
                }}
              />
            ))}
      </Masonry>
    </Stack>
  )
}

export { ExampleMaps }
export default ExampleMaps
