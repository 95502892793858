import _ from 'lodash'
import { VT_SERVER_SOURCE } from '~map-viewer/assets/constant/LayerConstant'
import { store } from '~map-viewer/states/store'

const getCurrentLayerSource = () => {
  const layerSources = store.getState().layer.layerSources
  return _.last(layerSources)
}

export const getSourceLayerArgs = (source) => {
  return _.includes(source, VT_SERVER_SOURCE) ? { sourceLayer: 'default' } : {}
}

export const highlightFeature = (map, feature) => {
  const source = getCurrentLayerSource()
  const { id } = feature
  const state = { [feature['feature-state']]: true }

  map.setFeatureState(
    {
      source,
      id,
      ...getSourceLayerArgs(source)
    },
    { ...state }
  )
}

export function clearHighlight(map, feature) {
  const source = getCurrentLayerSource()
  const { id } = feature
  const state = { [feature['feature-state']]: false }

  map.setFeatureState(
    {
      source,
      id,
      ...getSourceLayerArgs(source)
    },
    { ...state }
  )
}
