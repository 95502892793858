import React from 'react'
import { DefineMapFeature, DefineMapFeaturePropTypes } from 'ui-components'

const View = (props) => {
  return <DefineMapFeature {...props} />
}

View.propTypes = DefineMapFeaturePropTypes

export { View }
