import PropTypes from 'prop-types'
import paddingOf from '~ui-components/utilities/paddingOf'

const Box = (props) => {
  const {
    fullWidth = false,
    p,
    pt,
    pb,
    pl,
    pr,
    border,
    borderTop,
    borderBottom,
    borderLeft,
    borderRight,
    borderColor,
    borderRadius,
    style,
    cellPadding,
    cellSpacing,
    align,
    width,
    height,
    backgroundColor,
    children
  } = props
  return (
    <table
      width={width}
      height={height}
      {...(fullWidth ? { width: '100%' } : {})}
      cellPadding={cellPadding}
      cellSpacing={cellSpacing}
      align={align}
      style={{
        border: border || 'none',
        borderColor,
        borderRadius,
        ...(borderTop ? { borderTop } : {}),
        ...(borderBottom ? { borderBottom } : {}),
        ...(borderLeft ? { borderLeft } : {}),
        ...(borderRight ? { borderRight } : {}),
        ...(backgroundColor ? { backgroundColor } : {}),
        ...paddingOf({ p, pt, pr, pb, pl }),
        ...style
      }}
    >
      <tbody>{children}</tbody>
    </table>
  )
}

export { Box }
export default Box

Box.propTypes = {
  fullWidth: PropTypes.bool,
  p: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  border: PropTypes.string,
  borderTop: PropTypes.string,
  borderBottom: PropTypes.string,
  borderLeft: PropTypes.string,
  borderRight: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  style: PropTypes.object,
  cellPadding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cellSpacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backgroundColor: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.any
}

Box.defaultProps = {
  cellPadding: 0,
  cellSpacing: 0,
  border: '0',
  align: 'left',
  valign: 'top'
}
