const isFunction = (obj) => {
  return typeof obj === 'function'
}

const isObject = (obj) => {
  var type = typeof obj
  return type === 'function' || (type === 'object' && !!obj)
}

const hasWindowObject = () => {
  return typeof window !== 'undefined' && window.document
}

/* eslint-disable no-undef */
const ReactDevTools = {
  init: () => {
    // For testing purpose
    if (hasWindowObject()) {
      window.DEBUG_REACT_DEV_TOOLS_ENABLED = false
    }

    const isEnabledDevTools =
      ['development', 'staging'].includes(process.env.NODE_ENV) ||
      process.env.ENABLE_REACT_DEV_TOOLS === 'true'

    if (isEnabledDevTools) {
      // For testing purpose
      if (hasWindowObject()) window.DEBUG_REACT_DEV_TOOLS_ENABLED = true
      return
    }

    if (hasWindowObject()) {
      // Ensure the React Developer Tools global hook exists
      if (!isObject(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
        return
      }

      // Replace all global hook properties with a no-op function or a null value
      for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
        if (prop === 'renderers') {
          // Prevents console error when dev tools try to iterate of renderers
          window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map()
          continue
        }
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = isFunction(
          window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop]
        )
          ? Function.prototype
          : null
      }
    }
  }
}

export { ReactDevTools }
