import _ from 'lodash'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  useDefaultVisualisation,
  useIsDataLoading,
  useVisualisations
} from '~map-viewer/states/map'
import { setVisualisation } from '~map-viewer/states/visualisation'

export function useInitialMapStyleHandler(visualisationValue) {
  const dispatch = useDispatch()

  let defaultVisualisation = useDefaultVisualisation()
  const visualisations = useVisualisations()

  if (visualisationValue) {
    const mapVisualisations = _.map(visualisations, ({ value }) =>
      _.toLower(value).replace(/-/g, ' ')
    )
    const viewVisualisation = _.toLower(visualisationValue).replace(/-/g, ' ')
    if (_.includes(mapVisualisations, viewVisualisation)) {
      const visualisationIndex = _.indexOf(mapVisualisations, viewVisualisation)
      defaultVisualisation = visualisations[visualisationIndex]
    }
  }

  const isLoading = useIsDataLoading()

  useEffect(() => {
    if (defaultVisualisation && !isLoading) {
      dispatch(setVisualisation(defaultVisualisation))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])
}
