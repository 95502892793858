import { Box, GlobalStyles, Stack } from '@mui/material'
import { useEffect, useRef } from 'react'
import useResizeObserver from 'use-resize-observer'

import { PreventPullToRefresh } from '~ui-components/components/atoms/PreventPullToRefresh'

import { MapBar } from '~ui-components/components/molecules/MapBar'
import { useIsMobile } from '~ui-components/hooks/useIsMobile'
import { neutral } from '~ui-components/theme/colors'

interface Slots {
  AccountProfile?: any
  MapBar?: any
  MapRemixDrawer?: any
  MapCreateButton?: any
  MapCreateDrawer?: any
  MapDataSettingsDrawer?: any
  MapDescriptionDrawer?: any
  MapEmbedFullscreenButton?: any
  MapFeatureInfoDrawer?: any
  MapFilterChips?: any
  MapFilterDrawer?: any
  MapGl?: any
  MapLegend?: any
  MapLegendDrawer?: any
  MapName?: any
  MapSaveButton?: any
  MapSaveDrawer?: any
  MapSearch?: any
  MapSettingsDrawer?: any
  MapShareDrawer?: any
  MapTable?: any
  NavDrawer?: any
  SpeedDial?: any
}

type SlotProps = {
  AccountProfile?: any
  MapBar?: any
  MapRemixDrawer?: any
  MapCreateButton?: any
  MapCreateDrawer?: any
  MapDataSettingsDrawer?: any
  MapDescriptionDrawer?: any
  MapEmbedFullscreenButton?: any
  MapFeatureInfoDrawer?: any
  MapFilterChips?: any
  MapFilterDrawer?: any
  MapGl?: any
  MapLegend?: any
  MapLegendDrawer?: any
  MapName?: any
  MapSaveButton?: any
  MapSaveDrawer?: any
  MapSearch?: any
  MapSettingsDrawer?: any
  MapShareDrawer?: any
  MapTable?: any
  NavDrawer?: any
  SpeedDial?: any
}

const Empty = () => null

const defaultSlots: Slots = {
  AccountProfile: Empty,
  MapBar: MapBar,
  MapRemixDrawer: Empty,
  MapCreateButton: Empty,
  MapCreateDrawer: Empty,
  MapDataSettingsDrawer: Empty,
  MapDescriptionDrawer: Empty,
  MapEmbedFullscreenButton: Empty,
  MapFeatureInfoDrawer: Empty,
  MapFilterChips: Empty,
  MapFilterDrawer: Empty,
  MapGl: Empty,
  MapLegend: Empty,
  MapLegendDrawer: Empty,
  MapName: Empty,
  MapSaveButton: Empty,
  MapSaveDrawer: Empty,
  MapSearch: Empty,
  MapSettingsDrawer: Empty,
  MapShareDrawer: Empty,
  MapTable: Empty,
  NavDrawer: Empty,
  SpeedDial: Empty
}

export interface MapPreviewProps {
  isEmbed?: boolean
  slots?: Slots
  slotProps?: SlotProps
}

export const MAP_CONTAINER_ID = 'MapPreviewMain'

function MapPreview(props: MapPreviewProps) {
  return (
    <PreventPullToRefresh>
      <GlobalStyles styles={[styles.global]} />
      <Root {...props} />
    </PreventPullToRefresh>
  )
}

function Root(props: MapPreviewProps) {
  const { slots = {}, slotProps = {}, isEmbed = false } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const mobile = useIsMobile()
  const mainRef = useRef<HTMLDivElement>(null)

  useVirtualKeyboard()

  const { width, height } = useResizeObserver({
    ref: mainRef
  })

  return (
    <Stack
      id={MAP_CONTAINER_ID}
      direction={mobile && !isEmbed ? 'column-reverse' : 'row'}
      flex={1}
    >
      <Slots.MapBar {...slotProps?.MapBar} />

      <Box
        ref={mainRef}
        position='relative'
        component='main'
        flex={1}
        bgcolor={neutral[200]}
      >
        <Stack
          sx={[styles.nav]}
          direction='row'
          gap={1}
        >
          <Slots.MapSaveButton />
          <Slots.MapCreateButton {...slotProps?.MapCreateButton} />
          <Slots.AccountProfile {...slotProps?.AccountProfile} />
        </Stack>

        <Slots.MapGl
          {...slotProps?.MapGl}
          width={width}
          height={height}
        >
          <Slots.MapName sx={styles.mapName} />

          <Box sx={styles.mapLegend}>
            <Slots.MapLegend
              id='MapLegend'
              sx={{
                mx: 1,
                mb: 1
              }}
              {...slotProps?.MapLegend}
            />
          </Box>
        </Slots.MapGl>

        <Slots.MapFilterDrawer {...slotProps.MapFilterDrawer} />

        <Stack
          sx={styles.filterChips}
          alignItems='center'
          direction='row'
          flexWrap='wrap'
          gap={1}
        >
          <Slots.MapFilterChips {...slotProps.MapFilterChips!} />
        </Stack>

        <Slots.MapSearch {...slotProps?.MapSearch} />

        <Slots.MapTable {...slotProps?.MapTable} />

        <Stack sx={styles.speedDial}>
          <Slots.SpeedDial {...slotProps?.SpeedDial} />
        </Stack>

        <Slots.MapSettingsDrawer {...slotProps?.MapSettingsDrawer} />

        <Slots.MapDataSettingsDrawer {...slotProps?.MapDataSettingsDrawer} />

        <Slots.MapSaveDrawer {...slotProps?.MapSaveDrawer} />

        <Slots.MapRemixDrawer {...slotProps?.MapRemixDrawer} />

        <Slots.MapShareDrawer {...slotProps?.MapShareDrawer} />

        <Slots.MapFeatureInfoDrawer {...slotProps?.MapFeatureInfoDrawer} />

        <Slots.MapDescriptionDrawer {...slotProps?.MapDescriptionDrawer} />

        <Slots.MapEmbedFullscreenButton
          {...slotProps?.MapEmbedFullscreenButton}
          sx={[styles.mapEmbedFullscreenButton]}
        />

        <Slots.MapCreateDrawer {...slotProps?.MapCreateDrawer} />

        <Slots.MapLegendDrawer {...slotProps?.MapLegendDrawer} />

        <Slots.NavDrawer {...slotProps?.NavDrawer} />
      </Box>
    </Stack>
  )
}

function useVirtualKeyboard() {
  useEffect(() => {
    if ('virtualKeyboard' in navigator) {
      ;(navigator as any).virtualKeyboard.overlaysContent = true
    }
  }, [])
}

const styles = {
  global: () => ({
    ':root': {
      '--map-search-min-width': '700px',
      '--map-header-height': '80px',
      '--map-content-max-width': '1754px',
      '--sidebar-width': '390px'
    },

    'html, body': {
      position: 'relative',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      scrollbarWidth: 'none'
    },

    '#__next': {
      height: '100dvh'
    }
  }),

  mapName: () => ({
    position: 'absolute',
    top: 0,
    mt: 1,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#fff',
    fontSize: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    zIndex: 10
  }),

  speedDial: () => ({
    position: 'absolute',
    bottom: 26,
    right: 0
  }),

  mapSettingButton: () => ({
    position: 'absolute',
    left: 0,
    bottom: 0,
    ml: 1,
    mb: 1,
    zIndex: 2
  }),

  mapEmbedFullscreenButton: () => ({
    position: 'absolute',
    left: 16,
    top: 16,
    zIndex: 2
  }),

  nav: (theme) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    mt: 1,
    mr: 1,
    zIndex: 2,
    [theme.breakpoints.up('mobile')]: {
      mr: 6
    }
  }),

  filterChips: (theme) => ({
    zIndex: 'visualisationChip',
    position: 'absolute',
    top: 0,
    width: '100%',
    my: '12px',
    ml: '12px',
    [theme.breakpoints.down('mobile')]: {
      mb: 1,
      width: 'calc(100% - 16px)',
      height: 'auto',
      minWidth: 'unset'
    }
  }),

  mapLegend: (theme) => ({
    zIndex: 2,
    position: 'absolute',
    width: '100%',
    maxWidth: '600px',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)'
  })
}

export { MapPreview }
export type { SlotProps }
