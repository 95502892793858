import { graphierQL } from 'utils'
import {
  SaveReadMeMutation,
  SaveReadMeMutationVariables
} from '~map-viewer/types/__generated/gql/graphql'

const toArrayOfAWSJSON = (array) => array.map((item) => JSON.stringify(item))

const saveReadme = async ({ mapId, nodes }) => {
  const data = await graphierQL<
    SaveReadMeMutation,
    SaveReadMeMutationVariables
  >({
    useToken: true,
    query: SAVE_README_MUTATION,
    variables: { mapId, nodes: toArrayOfAWSJSON(nodes) }
  })

  return data
}

const SAVE_README_MUTATION = /* GraphQL */ `
  mutation SaveReadMe($mapId: ID!, $nodes: [AWSJSON!]!) {
    saveReadme(mapId: $mapId, nodes: $nodes) {
      id
      nodes
      createdAt
      updatedAt
    }
  }
`

export { SAVE_README_MUTATION, saveReadme }
export default saveReadme
