const getPaginationArgs = (
  page: number,
  limit: number,
  startPage: 0 | 1 = 1
) => {
  return {
    offset: (startPage === 1 ? page - 1 : page) * limit,
    limit
  }
}

export { getPaginationArgs }
