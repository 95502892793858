import {
  App,
  removeFilter,
  setActiveFilters,
  updateFilter,
  useFilters
} from 'map-filter-view'
import { useDispatch } from 'react-redux'

import { closeFilter, useVisibility } from '~map-viewer/states/ui'

const MapFilter = (props) => {
  const dispatch = useDispatch()

  const filters = useFilters()

  const visibility = useVisibility()

  const handleFilterRemove = (id) => {
    dispatch(removeFilter(id))
  }

  const handleFilterUpdate = (filter) => {
    dispatch(updateFilter(filter))
  }

  const handleSetActiveFilters = (filters) => {
    dispatch(setActiveFilters(filters))
  }

  const handleClose = () => {
    dispatch(closeFilter())
  }

  return (
    <App
      filters={filters}
      open={visibility.MapFilter}
      onClose={handleClose}
      onFilterRemove={handleFilterRemove}
      onFilterUpdate={handleFilterUpdate}
      onSetActiveFilters={handleSetActiveFilters}
      drawerProps={{ anchor: 'right' }}
    />
  )
}

export { MapFilter }
export default MapFilter
