import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Skeleton } from '~ui-components/components/atoms/Skeleton'

const JoinWorkspaceCopy = (props) => {
  const { loading, workspaceName, ...rest } = props
  return (
    <Fragment>
      <Box
        {...rest}
        my={5}
        component={Typography}
        variant='h1'
        fontWeight={800}
        textAlign='center'
      >
        <Skeleton
          loading={loading}
          inferDimension
        >
          Join {workspaceName || 'workspace'} on mapstack
        </Skeleton>
      </Box>
      <Box
        width={{
          xs: '80dvw',
          md: '40dvw'
        }}
        mx='auto'
        mb={4}
        component={Typography}
        textAlign='center'
        color='text.secondary'
      >
        mapstack is a community mapping app, a place you can share and access
        data as colorful interactive maps — so everyone can better understand
        our world.
      </Box>
    </Fragment>
  )
}

JoinWorkspaceCopy.propTypes = {
  loading: PropTypes.bool,
  workspaceName: PropTypes.string
}

export { JoinWorkspaceCopy }
export default JoinWorkspaceCopy
