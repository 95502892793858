import { LAYER_OUTLINE_COLORS } from '../../assets/Colors'

const getDefaultStyle = () => {
  return {
    type: 'fill',
    paint: {
      'fill-color': 'transparent',
      'fill-outline-color': LAYER_OUTLINE_COLORS[0]
    }
  }
}

export { getDefaultStyle }
