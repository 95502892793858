import { useSkippableDefaultView } from '~map-creator/core/hooks/useSkippableSteps/defaultView'
import { useCurrentWizardStep } from '~map-creator/core/states/map'
import { useStepIndexes } from '~map-creator/core/states/ui'

export const useSkippableSteps = () => {
  const currentWizardStep = useCurrentWizardStep()
  const stepIndexes = useStepIndexes()

  useSkippableDefaultView(currentWizardStep === stepIndexes.SELECT_DEFAULT_VIEW)
}
