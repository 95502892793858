import { useDispatch } from 'react-redux'
import { RemixPagination as View } from 'ui-components'
import { setPage, useRemixPagination } from '~map-viewer/states/remix'

function RemixPagination() {
  const dispatch = useDispatch()
  const pagination = useRemixPagination()

  const handlePageChange = (event, page) => {
    dispatch(setPage(page + 1))
  }

  return (
    <View
      {...pagination}
      onPageChange={handlePageChange}
    />
  )
}

export { RemixPagination }
