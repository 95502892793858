import { Box, Button, Container, Stack, Typography } from '@mui/material'

type HomeHeroProps = {
  onClick?: () => void
}

const HomeHero = (props: HomeHeroProps) => {
  const { onClick } = props

  return (
    <Box
      sx={{
        textAlign: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundImage: 'url("/assets/gradient-bg.svg")',
        pt: '120px'
      }}
    >
      <Container maxWidth='lg'>
        <Stack
          gap={4}
          maxWidth='lg'
          alignItems='center'
        >
          <Typography variant='h1'>
            What will you&nbsp;
            <Typography
              component='span'
              color='primary.main'
              variant='inherit'
            >
              map
            </Typography>
            , today?
          </Typography>

          <Typography
            variant='subtitle1'
            color='text.secondary'
            sx={{
              fontSize: 30,
              letterSpacing: 'tight',
              textAlign: 'center'
            }}
          >
            Turn your data into amazing maps, with Mapstack it’s simple.
          </Typography>

          <Button
            sx={{
              width: 200
            }}
            size='large'
            onClick={onClick}
          >
            Start Mapping Now
          </Button>
        </Stack>
      </Container>
    </Box>
  )
}

export { HomeHero }
