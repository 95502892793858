import { createPlugins } from '@udecode/plate'

import AutoformatPlugin from './AutoformatPlugin'
import BlockquotePlugin from './BlockquotePlugin'
import BoldPlugin from './BoldPlugin'
import CaptionPlugin from './CaptionPlugin'
import ComponentsPlugin from './ComponentsPlugin'
import DeserializeMdPlugin from './DeserializeMdPlugin'
import ExitBreakPlugin from './ExitBreakPlugin'
import HeadingPlugin from './HeadingPlugin'
import HighlightPlugin from './HighlightPlugin'
import HorizontalRulePlugin from './HorizontalRulePlugin'
import ImagePlugin from './ImagePlugin'
import IndentListPlugin from './IndentListPlugin'
import IndentPlugin from './IndentPlugin'
import ItalicPlugin from './ItalicPlugin'
import KdbPlugin from './KdbPlugin'
import LinkPlugin from './LinkPlugin'
import ListPlugin from './ListPlugin'
import LocalUploadPlugin from './LocalUploadPlugin'
import MediaEmbedPlugin from './MediaEmbedPlugin'
import NodeIdPlugin from './NodeIdPlugin'
import ParagraphPlugin from './ParagraphPlugin'
import ResetNodePlugin from './ResetNodePlugin'
import SelectOnBackspacePlugin from './SelectOnBackspacePlugin'
import SoftBreakPlugin from './SoftBreakPlugin'
import StrikethroughPlugin from './StrikethroughPlugin'
import SubscriptPlugin from './SubscriptPlugin'
import TablePlugin from './TablePlugin'
import TodoListPlugin from './TodoListPlugin'
import TrailingBlockPlugin from './TrailingBlockPlugin'
import UnderlinePlugin from './UnderlinePlugin'

const corePlugins = (plugins = [], options) => {
  const { overrideByKey = {} } = options || {}
  return createPlugins(
    [
      // Nodes
      ParagraphPlugin(),
      HeadingPlugin(),
      BlockquotePlugin(),
      HorizontalRulePlugin(),
      LinkPlugin(),
      ListPlugin(),
      TodoListPlugin(),
      TablePlugin(),
      ImagePlugin(),
      MediaEmbedPlugin(),
      CaptionPlugin(),

      // Marks
      BoldPlugin(),
      ItalicPlugin(),
      UnderlinePlugin(),
      StrikethroughPlugin(),
      SubscriptPlugin(),
      HighlightPlugin(),
      KdbPlugin(),

      // Block styles
      IndentPlugin(),
      IndentListPlugin(),

      // Functionality
      NodeIdPlugin(),
      AutoformatPlugin(),
      ExitBreakPlugin(),
      ResetNodePlugin(),
      SoftBreakPlugin(),
      TrailingBlockPlugin(),
      SelectOnBackspacePlugin(),
      LocalUploadPlugin(),

      // Deserialzation
      DeserializeMdPlugin(),
      ...plugins
    ],
    {
      components: ComponentsPlugin(),
      overrideByKey
    }
  )
}

export { corePlugins }
export default corePlugins
