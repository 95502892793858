import { Button } from '@mui/material'

function MapCreateButton(props) {
  const { children } = props
  return (
    <Button
      variant='contained'
      color='primary'
      {...props}
    >
      {children || "Create map - it's free"}
    </Button>
  )
}

export { MapCreateButton }
