import React from 'react'
import { Box } from '@mui/material'
import { PlateLeaf } from '@udecode/plate-common'
import clsx from 'clsx'

const KbdLeaf = ({ className, children, ...props }) => {
  return (
    <PlateLeaf
      asChild
      className={clsx(className)}
      {...props}
    >
      <Box
        component='kbd'
        sx={{
          paddingTop: '0.125rem',
          paddingBottom: '0.125rem',
          paddingLeft: '0.375rem',
          paddingRight: '0.375rem',
          borderRadius: '0.25rem',
          borderWidth: '1px',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          boxShadow:
            'rgba(255,255,255,0.1) 0px 0.5px 0px 0px inset, rgb(248, 249, 250) 0px 1px 5px 0px inset, rgb(193, 200, 205) 0px 0px 0px 0.5px, rgb(193, 200, 205) 0px 2px 1px -1px, rgb(193, 200, 205) 0px 1px 0px 0px] dark:shadow-[rgba(255, 255, 255, 0.1) 0px 0.5px 0px 0px inset, rgb(26, 29, 30) 0px 1px 5px 0px inset, rgb(76, 81, 85) 0px 0px 0px 0.5px, rgb(76, 81, 85) 0px 2px 1px -1px, rgb(76, 81, 85) 0px 1px 0px 0px',
          '[data-slate-string=true]': {
            fontFamily:
              'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace'
          }
        }}
      >
        {children}
      </Box>
    </PlateLeaf>
  )
}

export { KbdLeaf }
export default KbdLeaf
