import { ProfileButton } from '../ProfileButton'

const AccountProfile = (props) => {
  const { sx, authenticated, user, workspaces, onSignOutClick } = props

  const handleSignOutClick = () => {
    onSignOutClick()
  }

  if (!authenticated) return null

  return (
    <ProfileButton
      sx={sx}
      user={user}
      workspaces={workspaces}
      onLogout={handleSignOutClick}
    />
  )
}

export { AccountProfile }
