import { Box, Theme, useMediaQuery } from '@mui/material'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { isBrowser } from 'browser-or-node'

import { NavColor } from '~ui-components/types/settings'

import { MobileNav } from './MobileNav'
import { SideNav } from './SideNav'
import { TopNav } from './TopNav'

interface VerticalLayoutProps {
  openMobileNav?: boolean
  open?: boolean
  navColor?: NavColor
  topNavHeight?: number | string
  sideNavWidth?: number | string
  mobileNavWidth?: number | string
  children?: React.ReactNode
  slots?: {
    TopNav?: typeof TopNav
    SideNav?: typeof SideNav
    MobileNav?: typeof MobileNav
    Content?: React.ElementType
  }
  slotProps?: {
    TopNav?: React.ComponentProps<typeof TopNav>
    SideNav?: React.ComponentProps<typeof SideNav>
    MobileNav?: React.ComponentProps<typeof MobileNav>
    Content?: any
  }
}

const defaultSlots = {
  TopNav,
  SideNav,
  MobileNav,
  Content: Fragment
}

function VerticalLayout(props: VerticalLayoutProps) {
  const {
    openMobileNav = false,
    topNavHeight = 64,
    sideNavWidth = 280,
    mobileNavWidth = 280,
    navColor,
    slots,
    slotProps
  } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(390))

  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = useCallback((): void => {
    setOpen(true)
  }, [])

  const handleClose = useCallback((): void => {
    setOpen(false)
  }, [])

  useEffect(() => {
    setOpen(openMobileNav)
  }, [openMobileNav])

  return (
    <>
      <Slots.TopNav
        topNavHeight={topNavHeight}
        sideNavWidth={sideNavWidth}
        onMenuClick={handleOpen}
        {...slotProps?.TopNav}
      />

      {lgUp && (
        <Slots.SideNav
          color={navColor}
          sideNavWidth={sideNavWidth}
          {...slotProps?.SideNav}
        />
      )}

      {!lgUp && (
        <Slots.MobileNav
          color={navColor}
          mobileNavWidth={mobileNavWidth}
          open={open}
          onClose={handleClose}
          {...slotProps?.MobileNav}
        />
      )}

      <Box
        component='main'
        sx={(theme) => ({
          display: 'flex',
          flex: '1 1 auto',
          maxWidth: '100%',
          [theme.breakpoints.up('lg')]: {
            paddingLeft: String(sideNavWidth).endsWith('px')
              ? sideNavWidth
              : `${sideNavWidth}px`
          }
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Slots.Content {...slotProps?.Content} />
        </Box>
      </Box>
    </>
  )
}

export { VerticalLayout }
