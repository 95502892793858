import { Alert, Link, alertClasses } from '@mui/material'
import NextLink from 'next/link'

const SAMPLE_DATA_URL =
  'https://mapstack-prod-public-resources.s3.amazonaws.com/sample-data/point-sample-data.csv'

const MapSampleData = () => {
  return (
    <Alert
      sx={{
        [`& .${alertClasses.message}`]: { flex: 1, color: 'secondary.main' },
        [`& .${alertClasses.icon}`]: { color: 'secondary.main' },
        borderColor: 'secondary.main',
        fontWeight: 'medium',
        textAlign: 'left'
      }}
      variant='outlined'
      severity='info'
    >
      Need data? No problem, use ours:{' '}
      <Link
        component={NextLink}
        href={SAMPLE_DATA_URL}
        color='secondary'
        underline='always'
      >
        try with sample data
      </Link>
    </Alert>
  )
}

export { MapSampleData }
