const formatNumber = (input = 0, fallback) => {
  if (typeof input !== 'number') return fallback
  if (input === 0) return '0'
  let arr = input.toString().split('.')
  const [integer, decimal = 0] = arr
  let processedNumber = input

  const integerLength = integer.length
  let decimalPrecision = integerLength + 4 // Make precision for 3 decimal digits

  if (Number(decimal) > 0) {
    const decimalLength = decimal.length // 0023 is length = 4
    const actualDecimalLength = Math.ceil(Math.log10(Number(decimal))) // 0023 is length = 2

    const differenceGap = decimalLength - actualDecimalLength

    // Handle for float number less than 0. Example: 0.000012
    if (Number(integer) === 0 && differenceGap > 2) {
      decimalPrecision = differenceGap + 4 // round check for next decimal digits
    }

    processedNumber = Number(input.toPrecision(decimalPrecision))
  }

  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: decimalPrecision - integerLength - 1
  }).format(processedNumber)
}

export { formatNumber }
