import { FC, useState } from 'react'

import {
  Avatar,
  Box,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses
} from '@mui/material'
import { User01 } from '@untitled-ui/icons-react'

import { LoadingButton } from '@mui/lab'
import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'
import { Member } from '~ui-components/types/__generated/gql/graphql'

export type UninvitedGuestsListProps = {
  guests: Member[]
  onAddGuestClick: (guest: Member) => Promise<void>
}

export const UninvitedGuestsList: FC<UninvitedGuestsListProps> = ({
  guests,
  onAddGuestClick
}) => {
  const [loading, setLoading] = useState(false)
  const [selectedGuest, setSelectedGuest] = useState<Member>()

  const handleClick = async (guest) => {
    try {
      setLoading(true)
      setSelectedGuest(guest)

      await onAddGuestClick(guest)

      setLoading(false)
      setSelectedGuest(undefined)
    } catch (e) {
      setLoading(false)
      setSelectedGuest(undefined)
    }
  }

  if (!guests?.length) return null

  return (
    <Stack spacing={1}>
      <Typography
        color='text.secondary'
        variant='overline'
      >
        Select a guest
      </Typography>

      <Scrollbar>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none'
            }
          }}
        >
          <TableBody>
            {guests.map((guest) => (
              <TableRow key={guest.user.id}>
                <TableCell sx={{ px: 0 }}>
                  <Stack
                    alignItems='center'
                    direction='row'
                    spacing={1}
                  >
                    <Avatar
                      src={guest.user.avatar ?? undefined}
                      sx={{
                        height: 40,
                        width: 40
                      }}
                    >
                      <SvgIcon>
                        <User01 />
                      </SvgIcon>
                    </Avatar>
                    <Box>
                      <Typography variant='subtitle2'>
                        {guest.user.firstName} {guest.user.lastName}
                      </Typography>
                      <Typography
                        color='text.secondary'
                        variant='body2'
                      >
                        {guest.user.email}
                      </Typography>
                    </Box>
                  </Stack>
                </TableCell>

                <TableCell
                  sx={{ px: 0 }}
                  align='right'
                >
                  <LoadingButton
                    variant='contained'
                    loading={
                      loading && selectedGuest?.user?.id === guest.user.id
                    }
                    onClick={() => handleClick(guest)}
                  >
                    Add
                  </LoadingButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Stack>
  )
}
