import cookie from 'cookie'
import jscookie from 'js-cookie'

const COOKIE_KEY = 'current_workspace'
const SEPERATOR = ','

export const getCurrentWorkspaceClientCookie = () => {
  const cachedWorkspace = jscookie.get(COOKIE_KEY)
  if (!cachedWorkspace) return

  const [id, name] = cachedWorkspace.split(SEPERATOR)
  return { id: id!, name: name! }
}

export const setCurrentWorkspaceClientCookie = ({
  id,
  name
}: {
  id: string
  name: string
}) => {
  jscookie.set(COOKIE_KEY, [id, name].join(SEPERATOR))
}

export const getCurrentWorkspaceFromRequestCookies = (
  obj: Record<string, any>
) => {
  const currentWorkspace = obj[COOKIE_KEY]
  if (!currentWorkspace) return

  const [id, name] = currentWorkspace.split(SEPERATOR)
  return { id: id!, name: name! }
}

export const getSerializedCurrentWorkspaceCookieForResponseHeader = ({
  id,
  name
}: {
  id: string
  name: string
}) => {
  return cookie.serialize(COOKIE_KEY, [id, name].join(SEPERATOR))
}
