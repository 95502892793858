import Typography from '@mui/material/Typography'
import { useRouter } from 'next/router'

export const EditCategoryHeader = () => {
  const router = useRouter()

  return (
    <Typography variant='h4'>Editing Category: {router.query.id}</Typography>
  )
}
