import { ReactNode } from 'react'
import {
  Source as MapLibreSource,
  SourceProps as MapLibreSourceProps
} from 'react-map-gl/maplibre'

export type SourceProps = MapLibreSourceProps & {
  children: ReactNode
}

export const Source = MapLibreSource
