import { Box, Button } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const buttons = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Near Me',
    value: 'near'
  },
  {
    label: 'New',
    value: 'new'
  },
  {
    label: 'Most Popular',
    value: 'popular'
  }
]

const FeedSortInput = (props) => {
  const { value: valueProp, defaultValue, onChange = () => {} } = props

  const [value, setValue] = useState(valueProp || defaultValue || 'all')

  const handleClick = (event, value) => {
    setValue(value)
    onChange(event, value)
  }

  useEffect(() => {
    setValue(valueProp)
  }, [valueProp])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        alignItems: 'center'
      }}
    >
      {buttons.map((button) => {
        return (
          <Button
            disableElevation
            key={button.label}
            variant='contained'
            color={button.value === value ? 'primary' : 'inherit'}
            size='small'
            sx={{
              minWidth: 'unset',
              textTransform: 'none'
            }}
            onClick={(event) => handleClick(event, button.value)}
          >
            {button.label}
          </Button>
        )
      })}
    </Box>
  )
}

FeedSortInput.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
}

export { FeedSortInput }
export default FeedSortInput
