let timerId
const throttle = (cb, delay) => {
  if (timerId) clearTimeout(timerId)

  timerId = setTimeout(function () {
    cb()
    timerId = undefined
  }, delay)
}

export { throttle }
