import { LoadingButton, LoadingButtonProps } from '@mui/lab'

function RemixButton(props: LoadingButtonProps & { text?: string }) {
  const { text, ...rest } = props
  return (
    <LoadingButton
      {...rest}
      disableElevation
      variant='contained'
      color='primary'
    >
      Remix
    </LoadingButton>
  )
}

export { RemixButton }
