import { Button, SvgIcon } from '@mui/material'
import AlertOctagon from '@untitled-ui/icons-react/build/esm/AlertOctagon'

const ReportButton = (props) => {
  return (
    <Button
      disableElevation
      variant='contained'
      color='inherit'
      startIcon={
        <SvgIcon sx={{ fontSize: '1.25em !important' }}>
          <AlertOctagon />
        </SvgIcon>
      }
      {...props}
    >
      Report
    </Button>
  )
}

export { ReportButton }
export default ReportButton
