import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAreLayerSourcesLoaded } from '~map-viewer/states/layer'
import { setCurrentFeature } from '~map-viewer/states/map'
import { clearHighlight, highlightFeature } from '../mapHighlightHandler'

const useFeatureHover = (map) => {
  const dispatch = useDispatch()
  const areLayerSourcesLoaded = useAreLayerSourcesLoaded()
  let featureId = null

  const queryFeatureByPoint = (eventTargetMap, point) => {
    if (featureId) {
      clearHighlight(map, { id: featureId, 'feature-state': 'hover' })
    }

    const [queriedFeature] = eventTargetMap.queryRenderedFeatures(point, {
      layers: ['default']
    })

    if (queriedFeature) {
      const { id, properties, source } = queriedFeature
      featureId = id
      highlightFeature(map, { id: featureId, 'feature-state': 'hover' })
      dispatch(setCurrentFeature({ id, properties, source }))
    } else {
      featureId = null
      dispatch(setCurrentFeature({}))
    }
  }

  const handleMouseMove = (e) => {
    queryFeatureByPoint(e.target, e.point)
  }

  const handleTouchMove = (e) => {
    queryFeatureByPoint(e.target, e.target.project(e.target.getCenter()))
  }

  useEffect(() => {
    if (map && areLayerSourcesLoaded) {
      map.on('mousemove', handleMouseMove)
      map.on('touchmove', handleTouchMove)
    }

    return () => {
      if (map && areLayerSourcesLoaded) {
        map.off('mousemove', handleMouseMove)
        map.off('touchmove', handleTouchMove)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, map, areLayerSourcesLoaded])
}

export { useFeatureHover }
