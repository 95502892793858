import React from 'react'
import PropTypes from 'prop-types'
import { styled, Box, Typography } from '@mui/material'

const Stack = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
})

const Fill = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flex: 1
})

const FloatingHeaderRoot = styled(Box)({
  overflow: 'hidden',
  paddingBottom: 'var(--mobile-panel-shadow-offset, 24px)'
})

const FloatingHeaderContent = styled(Box)(({ theme }) => ({
  padding: '12px',
  paddingTop: '6px',
  boxShadow: theme.shadows[6]
}))

const MobilePanelFloatingHeader = ({ children, ...rest }) => {
  return (
    <FloatingHeaderRoot {...rest}>
      <FloatingHeaderContent>{children}</FloatingHeaderContent>
    </FloatingHeaderRoot>
  )
}

const MobilePanelDefaultHeader = (props) => {
  const { title, onCloseClick, ...rest } = props
  return (
    <MobilePanelFloatingHeader {...rest}>
      <Stack>
        <Fill>
          <Typography
            data-testid='mobile-panel-title'
            variant='h6'
          >
            {title}
          </Typography>
        </Fill>
      </Stack>
    </MobilePanelFloatingHeader>
  )
}

MobilePanelDefaultHeader.propTypes = {
  /**
   * The title of the mobile panel component.
   */
  title: PropTypes.string,
  /**
   * The system prop that allows defining system overrides as well as
   * additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object
  ])
}

MobilePanelDefaultHeader.defaultProps = {}

export { MobilePanelFloatingHeader, MobilePanelDefaultHeader }
