import { Scales02 } from '@untitled-ui/icons-react'
import NextLink from 'next/link'
import { getAcronym } from 'utils'
import { IconLabel } from '~ui-components/components/molecules/IconLabel'

interface MapMetadataLicenseProps {
  license: {
    name: string
    url: string
  }
}

function MapMetadataLicense(props: MapMetadataLicenseProps) {
  const { license } = props
  if (!license) return null
  return (
    <IconLabel
      component={NextLink}
      title={getAcronym(license?.name)}
      primaryText={license?.name}
      icon={Scales02}
      href={license?.url}
    />
  )
}

export { MapMetadataLicense }
