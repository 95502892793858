import { Button } from '@mui/material'

const CancelButton = (props) => {
  return (
    <Button
      variant='outlined'
      color='primary'
      {...props}
    >
      Cancel
    </Button>
  )
}

export { CancelButton }
export default CancelButton
