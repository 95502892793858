import { useMemo } from 'react'
import { Provider } from 'react-redux'
import { createStore } from './store'
import { context } from '.'

const StateProvider = (props) => {
  const { children } = props

  const store = useMemo(() => createStore(), [])

  return (
    <Provider
      store={store}
      context={context as any}
    >
      {children}
    </Provider>
  )
}

export { StateProvider }
