export const MS_DEFAULT_COLOUR = '#ffffff'
export const MS_BASIC_COLOUR = '#2B78E4'
export const MS_COLOUR_PALETTE = 'RdPu'
export const MS_CATEGORY_COLOUR_PALETTE = 'Set3'
export const DEAFULT_OTHER_CATEGORY_COLOUR = '#808080'
export const MS_DEFAULT_HEIGHT = -100000
export const MS_MIN_HEIGHT = 1000
export const MS_MAX_HEIGHT_FORMULA = { M: -198000, C: 1594000 }
export const DEFAULT_JENKS_CLASS_BREAKS = 7
export const DEFAULT_CATEGORY_MAX = 12
export const BASE_MAP_STYLE_URL =
  'https://api.maptiler.com/maps/267bea5c-db1b-4ae2-bf49-b3a20792a2c0/style.json?key=6dkE4jp3UXbczwUW17h4'
export const MAPSTACK_SOURCE_ID = 'mapstack'
export const BOUNDARY_LAYER_BEFORE_ID = 'Residential'
export const MS_UUID = 'MS_uuid'
