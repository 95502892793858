
const DEFAULT_DECIMAL_PLACES = 2

export function prettyNums(value: number): string {
  if (value < 1) {
    return value.toFixed(3).toString().replace(/\.?0+$/, '');
  }
  else if (value < 1000) {
    return value.toFixed(2).toString().replace(/\.?0+$/, '');
  } else if (value >= 1000 && value < 1000000) {
    return (value / 1000).toFixed(1) + 'K'; // 2 dp on thousands looks a bit odd
  } else if (value >= 1000000 && value < 1000000000) {
    return (value / 1000000).toFixed(DEFAULT_DECIMAL_PLACES) + 'M';
  } else if (value >= 1000000000 && value < 1000000000000) {
    return (value / 1000000000).toFixed(DEFAULT_DECIMAL_PLACES) + 'B';
  } else if (value >= 1000000000000 && value < 1000000000000000) {
    return (value / 1000000000000).toFixed(DEFAULT_DECIMAL_PLACES) + 'T';
  } else {
    return value.toExponential(DEFAULT_DECIMAL_PLACES)
  }
}
