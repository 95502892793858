import { Chip, Stack, SvgIcon, Typography } from '@mui/material'
import { Hexagon02 } from '@untitled-ui/icons-react'
import { capitalize, get } from 'lodash'
import { useEffect } from 'react'
import { MapProperty } from '~ui-components/types/__generated/gql/graphql'

type Event = {
  type: string
}

type Value = string

interface HexagonDisplayInputProps {
  properties?: MapProperty[]
  value?: Value
  onChange?: (event: Event, value: Value) => void
}

function AggregateValue(props: HexagonDisplayInputProps) {
  const { value, onChange, properties } = props

  useEffect(() => {
    if (!value && properties?.length) {
      onChange?.({ type: 'change' }, properties[0]?.name || '')
    }
  }, [value, onChange, properties])

  const selectOption = (option) => {
    onChange?.({ type: 'change' }, option)
  }

  const unselectOption = (option) => {
    onChange?.({ type: 'change' }, option)
  }

  return (
    <Stack spacing={1}>
      <Typography
        color='text.secondary'
        variant='overline'
      >
        By
      </Typography>
      <Stack
        alignItems='center'
        direction='row'
        flexWrap='wrap'
        gap={2}
      >
        {properties?.map((property) => {
          const name = property.name
          const active = value === name
          const label = get(property, 'label', name)
          return (
            <Chip
              key={`Display:${name}`}
              data-testid={`Display-${name}`}
              label={capitalize(label)}
              icon={
                <SvgIcon sx={{ fontSize: 18, ml: 1 }}>
                  <Hexagon02 />
                </SvgIcon>
              }
              onClick={() =>
                active ? unselectOption(name) : selectOption(name)
              }
              onDelete={undefined}
              sx={{
                borderColor: 'transparent',
                borderRadius: 1.5,
                borderStyle: 'solid',
                borderWidth: 2,
                ...(active && {
                  borderColor: 'primary.main'
                })
              }}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}

export { AggregateValue }
