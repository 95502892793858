import { Box, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { zIndex } from '~ui-components/theme/system'

import { AppFooter } from '~ui-components/components/organisms/AppFooter'

const GutterTop = ({ children }) => {
  return (
    <Box
      sx={(theme) => ({
        paddingTop: `calc(${theme.spacing(4)} + env(safe-area-inset-top))`
      })}
    >
      {children}
    </Box>
  )
}

const defaultSlots = {
  Header: () => null,
  Main: () => null,
  Footer: () => null,
  SearchModal: () => null
}

const AppLayout = (props) => {
  const { slots = {}, gutterTop = false } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Stack
      direction='column'
      width='100%'
      height='100%'
    >
      <Box sx={{ zIndex: zIndex.searchModal }}>
        <Slots.SearchModal />
      </Box>

      {typeof Slots.Header === 'function' ? <Slots.Header /> : Slots.Header}

      <Box
        component='main'
        sx={{
          flex: 1,
          overflow: 'hidden',
          '&:before': {
            content: "''",
            display: 'block',
            height: '70px'
          }
        }}
      >
        {gutterTop ? (
          <GutterTop>
            {typeof Slots.Main === 'function' ? <Slots.Main /> : Slots.Main}
          </GutterTop>
        ) : (
          <>{typeof Slots.Main === 'function' ? <Slots.Main /> : Slots.Main}</>
        )}
      </Box>

      {!Slots.Footer ? (
        <AppFooter my={5} />
      ) : (
        <>
          {typeof Slots.Footer === 'function' ? <Slots.Footer /> : Slots.Footer}
        </>
      )}
    </Stack>
  )
}

AppLayout.propTypes = {
  slots: PropTypes.object,
  gutterTop: PropTypes.bool
}

export { AppLayout }
export default AppLayout
