import {
  ELEMENT_HR,
  ELEMENT_IMAGE,
  createSelectOnBackspacePlugin
} from '@udecode/plate'

export default () =>
  createSelectOnBackspacePlugin({
    options: {
      query: {
        allow: [ELEMENT_IMAGE, ELEMENT_HR]
      }
    }
  })
