import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { likeMap } from '~map-viewer/api/likeMap'
import { unlikeMap } from '~map-viewer/api/unlikeMap'

const initialState = {
  likes: 0,
  like: false,
  status: 'idle'
}

export const likeMutation = createAsyncThunk(
  'like/likeMutation',
  async (id) => {
    return await likeMap(id)
  }
)

export const unlikeMutation = createAsyncThunk(
  'like/unlikeMutation',
  async (id) => {
    return await unlikeMap(id)
  }
)

export const likeSlice = createSlice({
  name: 'like',
  initialState,
  reducers: {
    setLikes: (state, action) => {
      state.likes = action.payload
    },
    setLike: (state, action) => {
      state.like = action.payload
    },
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(likeMutation.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(likeMutation.fulfilled, (state, action) => {
        state.likes = state.likes + 1
        state.like = action.payload
        state.status = 'successful'
      })
      .addCase(likeMutation.rejected, (state, action) => {
        state.status = 'failed'
      })

    builder
      .addCase(unlikeMutation.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(unlikeMutation.fulfilled, (state, action) => {
        state.likes = state.likes - 1
        state.like = action.payload
        state.status = 'successful'
      })
      .addCase(unlikeMutation.rejected, (state, action) => {
        state.status = 'failed'
      })
  }
})

export const useIsMapLiked = () => useSelector((state) => state.like.like)
export const useMapLikes = () => useSelector((state) => state.like.likes)

export const { setLike, setLikes, reset } = likeSlice.actions

export default likeSlice.reducer
