function getClusterMarkerStyle() {
  const cluster = {
    id: 'Mapstack Visualization Cluster',
    source: 'mapstack-points-cluster',
    type: 'circle',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        '#51bbd6',
        50,
        '#f1f075',
        100,
        '#f28cb1'
      ],
      'circle-radius':
        ['case',
          ['boolean', ['feature-state', 'hover'], false],
          ['step',
            ['get', 'point_count'],
            1.25 * 20, 50,
            1.25 * 30, 100,
            1.25 * 40],
          ['step',
            ['get', 'point_count'],
            20, 50,
            30, 100,
            40]
        ],
      'circle-stroke-width': 2,
      'circle-stroke-color': '#333',
      'circle-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.5,  // Reduced opacity when hovered
        1 // Full opacity normally
      ]
    }
  }

  const label = {
    id: 'Mapstack Visualization Label',
    source: 'mapstack-points-cluster',
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12
    }
  }

  const symbol = {
    id: 'Mapstack Visualization Unclustered Symbol',
    source: 'mapstack-points-cluster',
    type: 'circle',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': '#16B364',
      'circle-radius': 8,
      'circle-stroke-width': 2,
      'circle-stroke-color': '#333',
      'circle-opacity': 0.5
    }
  }

  return {
    symbol,
    label,
    cluster
  }
}

export { getClusterMarkerStyle }
