import { Stack, TextField } from '@mui/material'

import { FC } from 'react'

export type InviteGuestClickableProps = {
  onClick: () => void
}

export const InviteGuestClickable: FC<InviteGuestClickableProps> = ({
  onClick
}) => {
  return (
    <Stack
      alignItems='center'
      direction='row'
      spacing={3}
    >
      <TextField
        fullWidth
        autoComplete='off'
        color='secondary'
        label='Add people by email address'
        inputProps={{
          onClick: (e) => {
            e.currentTarget.blur()
            onClick()
          }
        }}
        focused={false}
        sx={{ '& input': { cursor: 'pointer !important' } }}
      />
    </Stack>
  )
}
