import { Box } from '@mui/material'
import { PlateElement } from '@udecode/plate'

const ListItemElement = (props) => {
  const { nodeProps, children, ...rest } = props

  return (
    <PlateElement
      asChild
      {...rest}
    >
      <Box
        {...nodeProps}
        component='li'
        sx={{
          ml: 3
        }}
      >
        {props.children}
      </Box>
    </PlateElement>
  )
}

export { ListItemElement }
export default ListItemElement
