import {
  Box,
  Container,
  Link,
  Stack,
  Typography,
  alpha,
  useTheme
} from '@mui/material'
import NextLink from 'next/link'
import { Fragment } from 'react'
import { getPrivacyLink, getTermsLink } from 'utils'

const navigations = [
  {
    label: 'Privacy',
    href: getPrivacyLink()
  },
  {
    label: 'Terms',
    href: getTermsLink()
  }
]

const AppFooter = (props) => {
  const theme = useTheme()
  return (
    <Box
      component={Container}
      as='footer'
      {...props}
    >
      <Stack direction='column'>
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          {navigations.map((navigation, index) => {
            return (
              <Fragment key={navigation.label}>
                <Box>
                  <Link
                    component={NextLink}
                    href={navigation.href}
                    underline='hover'
                    variant='subtitle2'
                    color='text.secondary'
                  >
                    {navigation.label}
                  </Link>
                </Box>
                {index < navigations.length - 1 && (
                  <Box
                    sx={{
                      width: '1px',
                      height: '1rem',
                      mx: 1.5,
                      backgroundColor: alpha(
                        theme.palette.secondary.contrastText,
                        0.2
                      )
                    }}
                  />
                )}
              </Fragment>
            )
          })}
        </Stack>
        <Box sx={{ width: '100%', padding: '5px' }}>
          <Typography
            component='div'
            align='center'
            variant='subtitle2'
            color='text.secondary'
            gutterBottom
          >
            ©2022 mapstack
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}

export { AppFooter }
