import React from 'react'
import { DefineMapArea, DefineMapAreaPropTypes } from 'ui-components'

const View = (props) => {
  return <DefineMapArea {...props} />
}

View.propTypes = DefineMapAreaPropTypes

export { View }
