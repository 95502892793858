import { useRouter } from 'next/router'
import { useState } from 'react'
import { Auth, RegisterForm } from 'ui-components'
import { getSignInLink } from 'utils'

function Form() {
  const router = useRouter()
  const email = router.query.email as string
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value)
  }

  const handleLastNameChange = (event) => {
    setLastName(event.target.value)
  }

  const handleClick = () => {
    router.push(getSignInLink('verify', { email, firstName, lastName }))
  }

  return (
    <RegisterForm
      email={email}
      slotProps={{
        FirstNameInput: {
          value: firstName,
          onChange: handleFirstNameChange
        },
        LastNameInput: {
          value: lastName,
          onChange: handleLastNameChange
        },
        Button: {
          onClick: handleClick
        }
      }}
    />
  )
}

function Register() {
  return <Auth slots={{ Form }} />
}

export { Register }
