import { StyledComponent } from '@emotion/styled'
import { Theme, styled } from '@mui/material/styles'
import { MUIStyledCommonProps } from '@mui/system'
import { RefAttributes } from 'react'
import SimpleBarCore from 'simplebar-core'
import SimpleBar, { Props } from 'simplebar-react'

export const Scrollbar: StyledComponent<
  Props & RefAttributes<SimpleBarCore | null> & MUIStyledCommonProps<Theme>,
  NonNullable<unknown>,
  NonNullable<unknown>
> = styled(SimpleBar)``
