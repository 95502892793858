import { MARK_ITALIC, isMarkActive, toggleMark } from '@udecode/plate'

import { focusEditor } from '@udecode/plate-common'
import { useEditorState } from '@udecode/plate-core'

import Action from '~ui-components/components/molecules/TextEditor/components/Action'

const Italic = (props) => {
  const editor = useEditorState()
  const italic = editor.selection && isMarkActive(editor, MARK_ITALIC)

  const handleClick = () => {
    toggleMark(editor, { key: MARK_ITALIC })
    focusEditor(editor)
  }

  return (
    <Action
      variant='italic'
      toggled={italic}
      onClick={handleClick}
      {...props}
    />
  )
}

export { Italic }
