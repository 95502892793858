import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { fetchAuthSession } from 'aws-amplify/auth'
import { graphierQL } from 'utils'
import { QueryGetUserByIdArgs } from '~user-auth/types/__generated/gql/graphql'

export const getUserByIdQuery = /**GraphQl */ `
  query GetUserById($id: ID!) {
    getUserById(id: $id) {
      id
      uid
      firstName
      lastName
      email
      avatar
      createdAt
      defaultWorkspaceId
      defaultWorkspace {
        id
        name
      }
      membershipCollection {
        total
        items {
          role
          workspace {
            id
            logo
            name
            description
            createdAt
            memberCollection {
              total
              items {
                role
                user {
                  id
                  firstName
                  lastName
                  email
                  avatar
                }
              }
            }
          }
        }
      }
    }
  }
`

export type GetUserByIdResult = any

export const getUserByIdQueryKey = (id: string) => {
  return ['getUserById', id]
}

export const getUserById = async (id: string) => {
  const data = await graphierQL<GetUserByIdResult, QueryGetUserByIdArgs>({
    query: getUserByIdQuery,
    variables: { id }
  })
  return data?.getUserById
}

export const getCurrentUserIdKey = () => ['getCurrentUserId']

export const getCurrentUserTokenKey = () => ['getCurrentUserToken']

export const useGetCurrentUserId = () => {
  return useQuery({
    queryKey: getCurrentUserIdKey(),
    refetchOnMount: true,
    queryFn: async () => {
      const session = await fetchAuthSession()
      const userId = session?.tokens?.idToken?.payload?.['custom:id'] as unknown
      if (!userId) return null
      return userId as string
    }
  })
}

export const useGetUserById = (
  id?: string | null
): UseQueryResult<GetUserByIdResult> => {
  return useQuery({
    refetchOnMount: true,
    queryKey: getUserByIdQueryKey(id!),
    queryFn: () => getUserById(id!)
  })
}

export const useGetCurrentUserToken = () => {
  return useQuery({
    refetchOnMount: true,
    queryKey: getCurrentUserTokenKey(),
    queryFn: async () => {
      const session = await fetchAuthSession()
      return session.tokens?.idToken?.toString()
    }
  })
}
