import {
  DataExistsQuery,
  DataExistsQueryVariables
} from '~map-creator/types/__generated/gql/graphql'
import { graphierQL } from 'utils'

const dataExists = async (dataId) => {
  const data = await graphierQL<DataExistsQuery, DataExistsQueryVariables>({
    query: dataExistsQuery,
    variables: {
      dataId
    }
  })
  return data.dataExists
}

const dataExistsQuery = /* GraphQL */ `
  query DataExists($dataId: ID!) {
    dataExists(dataId: $dataId)
  }
`

export { dataExists, dataExistsQuery }
export default dataExists
