import PropTypes from 'prop-types'
import {
  Box,
  Image,
  Item,
  Typography
} from '~ui-components/emails/components/atoms'

const LIMIT = 4

const MembersList = (props) => {
  const { owner = {}, members = [] } = props
  return (
    <Box
      align='center'
      p={24}
    >
      <Item>
        <Typography
          data-testid='MembersInfoCopy'
          variant='body1'
          fontWeight={600}
        >
          {owner?.firstName} and {members?.length} others have already joined
        </Typography>
      </Item>
      <Item
        align='center'
        pt={12}
      >
        <Image
          data-testid='OwnerAvatar'
          src={owner?.avatar}
          alt='Owner'
          width={50}
          height={50}
          borderRadius='rounded'
          style={{ margin: 6, marginBottom: 0 }}
        />
        {members.slice(0, LIMIT).map((member, index) => {
          return (
            <Image
              data-testid='MemberAvatar'
              key={index}
              src={member?.avatar}
              alt='member'
              width={50}
              height={50}
              borderRadius='rounded'
              style={{ margin: 6, marginBottom: 0 }}
            />
          )
        })}
      </Item>
    </Box>
  )
}

MembersList.propTypes = {
  owner: PropTypes.object,
  members: PropTypes.array
}

export { MembersList }
export default MembersList
