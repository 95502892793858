import { ToggleButton, ToggleButtonGroup, Divider } from '@mui/material'
import { FC } from 'react'

type ToggleShareOptionsProps = {
  value: string
  onChange: (value: string) => void
}

export const ToggleShareOptions: FC<ToggleShareOptionsProps> = (props) => {
  const { value, onChange } = props
  return (
    <>
      <Divider />
      <ToggleButtonGroup
        exclusive
        onChange={(_, value) => onChange(value)}
        value={value}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <ToggleButton
          value='Share'
          sx={{
            width: '33.33%',
            border: 0,
            borderRadius: 0,
            borderBottom: '2px solid #E5E7EB',
            '&.Mui-selected, &.Mui-selected:hover, &:hover': {
              backgroundColor: 'unset',
              color: '#2970FF',
              borderBottom: '2px solid #2970FF'
            }
          }}
        >
          Share
        </ToggleButton>
        <ToggleButton
          value='Embed'
          sx={{
            width: '33.34%',
            border: 0,
            borderRadius: 0,
            borderBottom: '2px solid #E5E7EB',
            '&.Mui-selected, &.Mui-selected:hover, &:hover': {
              backgroundColor: 'unset',
              color: '#2970FF',
              borderBottom: '2px solid #2970FF'
            }
          }}
        >
          Embed
        </ToggleButton>
        <ToggleButton
          value='Infographic'
          sx={{
            width: '33.33%',
            border: 0,
            borderRadius: 0,
            borderBottom: '2px solid #E5E7EB',
            '&.Mui-selected, &.Mui-selected:hover, &:hover': {
              backgroundColor: 'unset',
              color: '#2970FF',
              borderBottom: '2px solid #2970FF'
            }
          }}
        >
          Infographic
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}
