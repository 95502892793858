import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { CountTextInput } from '~ui-components/components/molecules/CountTextInput'

const WorkspaceCreatorNameForm = (props) => {
  const { error, disabled, value, onChange, onSubmit } = props

  return (
    <Box
      data-testid='NameForm'
      component='form'
      onSubmit={onSubmit}
    >
      <Box
        component={Typography}
        variant='h2'
        mb={2}
      >
        What's the name of your project, team or company?
      </Box>
      <Box
        error={error}
        disabled={disabled}
        inputProps={{
          'data-testid': 'NameTextInput'
        }}
        component={CountTextInput}
        limit={50}
        value={value}
        onChange={onChange}
        placeholder='London Demographic Maps'
      />
    </Box>
  )
}

const WorkspaceCreatorNameFormPropTypes = {
  error: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onValidate: PropTypes.func,
  onSubmit: PropTypes.func
}

WorkspaceCreatorNameForm.propTypes = WorkspaceCreatorNameFormPropTypes

export { WorkspaceCreatorNameForm, WorkspaceCreatorNameFormPropTypes }
export default WorkspaceCreatorNameForm

const noSpace = (string = '') => string.replaceAll(/\s/g, '')
