import { Box } from '@mui/material'
import { forwardRef } from 'react'

const Blockquote = forwardRef((props, ref) => {
  const { children } = props
  return (
    <Box
      ref={ref}
      component='blockquote'
      sx={{
        m: 0,
        my: 2,
        pl: 2,
        py: 2,
        typography: 'body1',
        borderRadius: 2,
        borderStyle: 'solid',
        border: (theme) => `0 solid ${theme.palette.divider}`,
        backgroundColor: (theme) => theme.palette.grey[200],
        borderLeftWidth: '3px',
        '& > p': {
          my: 0
        }
      }}
    >
      {children}
    </Box>
  )
})

Blockquote.displayName = 'Blockquote'

export { Blockquote }
export default Blockquote
