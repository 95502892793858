import LoadingButton from '@mui/lab/LoadingButton'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/system/Box'
import Share03 from '@untitled-ui/icons-react/build/esm/Share03'
import Trash01 from '@untitled-ui/icons-react/build/esm/Trash01'
import { useFormik } from 'formik'
import { useCallback, useMemo } from 'react'
import { sanitizeNameForTag } from 'utils'

import type { File } from '~ui-components/components/organisms/ThumbnailUpload'
import {
  Category_Tag_Type,
  Map
} from '~ui-components/types/__generated/gql/graphql'

import type { FC } from 'react'
import { ThumbnailUpload } from '~ui-components/components/organisms/ThumbnailUpload'

const categoryTypeOptions = [
  {
    label: 'Primary',
    value: Category_Tag_Type.Primary
  },
  {
    label: 'Secondary',
    value: Category_Tag_Type.Secondary
  },
  {
    label: 'Featured',
    value: Category_Tag_Type.Featured
  }
]

type PartialMap = Pick<Map, 'id' | 'name'>

const getMapLink = (map: PartialMap) =>
  `${window.location.origin}/map/${map.id}/${sanitizeNameForTag(map.name)}`

type CategoryForm = {
  id: string
  name: string
  thumbnail: string
  categoryType: Category_Tag_Type
  mapIdInput: string
}

type CategoryFormProps = {
  isFormLoading?: boolean
  isActionLoading?: boolean
  actionButtonText: string
  form: ReturnType<typeof useFormik<CategoryForm>>
  onFileDrop: (file: File) => void
  onFileRemove: (file: File) => void
  maps: PartialMap[]
  isRefetchingMap: boolean
  onMapIdAdd: (id: string) => void
  onMapRemove: (id: string) => void
}

const CategoryForm: FC<CategoryFormProps> = ({
  isFormLoading = false,
  isActionLoading = false,
  actionButtonText,
  form,
  onFileDrop,
  onFileRemove,
  maps,
  isRefetchingMap,
  onMapIdAdd,
  onMapRemove
}) => {
  const fileUrl = useMemo(() => {
    if (!form.values.thumbnail) return ''

    return form.values.thumbnail.startsWith('blob:')
      ? form.values.thumbnail
      : `https://${process.env.TAG_ASSETS_URL}/${
          form.values.thumbnail
        }?ts=${new Date().getTime()}`
  }, [form.values.thumbnail])

  const handleFilesDrop = useCallback(
    (newFiles: File[]): void => {
      if (!newFiles[0]) return

      onFileDrop(newFiles[0])
    },
    [onFileDrop]
  )

  const handleFileRemove = useCallback(
    (file: File): void => {
      onFileRemove(file)
    },
    [onFileRemove]
  )

  if (isFormLoading) return <CircularProgress />

  return (
    <form onSubmit={form.handleSubmit}>
      <Stack spacing={4}>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={4}
              >
                <Typography variant='h6'>Basic details</Typography>
              </Grid>
              <Grid
                xs={12}
                md={8}
              >
                <Stack spacing={3}>
                  <TextField
                    autoFocus
                    error={!!form.errors.name}
                    fullWidth
                    helperText={form.errors.name}
                    label='Name'
                    name='name'
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    value={form.values.name}
                  />
                  <TextField
                    disabled
                    fullWidth
                    label='Tag'
                    name='tag'
                    value={form.values.id}
                  />
                  <TextField
                    error={!!form.errors.categoryType}
                    fullWidth
                    label='Type'
                    name='categoryType'
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    select
                    value={form.values.categoryType}
                  >
                    {categoryTypeOptions.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={4}
              >
                <Stack spacing={1}>
                  <Typography variant='h6'>Thumbnail</Typography>
                  <Typography
                    color='text.secondary'
                    variant='body2'
                  >
                    Positive and colorful
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                xs={12}
                md={8}
              >
                <ThumbnailUpload
                  accept={{ 'image/webp': [] }}
                  caption='Webp 200x200'
                  fileUrl={fileUrl}
                  onDrop={handleFilesDrop}
                  onRemove={handleFileRemove}
                />
                {!!form.errors.thumbnail && (
                  <FormHelperText sx={{ color: (t) => t.palette.error.main }}>
                    {form.errors.thumbnail}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={4}
              >
                <Typography variant='h6'>Tagged Maps</Typography>
              </Grid>
              <Grid
                xs={12}
                md={8}
              >
                <Stack spacing={3}>
                  <Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <TextField
                        value={form.values.mapIdInput}
                        error={!!form.errors.mapIdInput}
                        label='Map Id'
                        name='mapIdInput'
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        disabled={isRefetchingMap}
                      />
                      <LoadingButton
                        sx={{ ml: 1 }}
                        loading={isRefetchingMap}
                        disabled={isRefetchingMap}
                        color='inherit'
                        variant='contained'
                        onClick={() => onMapIdAdd(form.values.mapIdInput)}
                      >
                        Add
                      </LoadingButton>
                    </Box>
                    {!!form.errors.mapIdInput && (
                      <FormHelperText
                        sx={{ color: (t) => t.palette.error.main }}
                      >
                        {form.errors.mapIdInput}
                      </FormHelperText>
                    )}
                  </Box>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Map name</TableCell>
                        <TableCell align='right'>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {maps.map((map) => (
                        <TableRow
                          key={map.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 }
                          }}
                        >
                          <TableCell>
                            <Typography variant='subtitle2'>
                              {map.name}
                            </Typography>
                          </TableCell>
                          <TableCell align='right'>
                            <IconButton
                              component='a'
                              href={getMapLink(map)}
                              target='_blank'
                            >
                              <SvgIcon>
                                <Share03 />
                              </SvgIcon>
                            </IconButton>
                            <IconButton onClick={() => onMapRemove(map.id)}>
                              <SvgIcon>
                                <Trash01 />
                              </SvgIcon>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='flex-end'
          spacing={1}
        >
          <LoadingButton
            loading={isActionLoading}
            type='submit'
            variant='contained'
          >
            {actionButtonText}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  )
}

export { CategoryForm }
