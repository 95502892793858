import { Trophy01 } from '@untitled-ui/icons-react'
import { isEmpty } from 'lodash'
import NextLink from 'next/link'
import { getAcronym } from 'utils'
import { IconLabel } from '~ui-components/components/molecules/IconLabel'

const DEFAULT_DATA_SOURCE_PLACEHOLDER = 'Data Source'

interface MapMetadataSourceProps {
  source: {
    name: string
    url: string
  }
}

function MapMetadataSource(props: MapMetadataSourceProps) {
  const { source } = props
  return (
    <>
      {!isEmpty(source?.name) && isEmpty(source?.url) ? (
        <IconLabel
          icon={Trophy01}
          primaryText={source?.name}
        />
      ) : null}

      {!isEmpty(source?.url) ? (
        <IconLabel
          component={NextLink}
          icon={Trophy01}
          title={getAcronym(source?.name)}
          primaryText={source?.name ?? DEFAULT_DATA_SOURCE_PLACEHOLDER}
          href={source?.url}
        />
      ) : null}
    </>
  )
}

export { MapMetadataSource }
