import { Auth, Seo } from 'ui-components'

import { Login } from './Login'

function SignIn() {
  return (
    <>
      <Seo title='Sign In' />
      <Auth slots={{ Form: Login }} />
    </>
  )
}

export { SignIn }
