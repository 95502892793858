import {
  Alert,
  AlertTitle,
  Box,
  Portal,
  Snackbar,
  SnackbarProps
} from '@mui/material'
import _ from 'lodash'
import React from 'react'

const positions = {
  'top-right': {
    vertical: 'top',
    horizontal: 'right'
  },
  'top-center': {
    vertical: 'top',
    horizontal: 'center'
  },
  'top-left': {
    vertical: 'top',
    horizontal: 'left'
  },
  'bottom-right': {
    vertical: 'bottom',
    horizontal: 'right'
  },
  'bottom-center': {
    vertical: 'bottom',
    horizontal: 'center'
  },
  'bottom-left': {
    vertical: 'bottom',
    horizontal: 'left'
  }
}

interface NotificationProps extends SnackbarProps {
  open: boolean
  position?: keyof typeof positions
  variant: 'filled' | 'outlined' | 'standard'
  severity: 'success' | 'warning' | 'error' | 'info'
  action?: React.ReactNode
  children?: any
}

const Component = (props, ref) => {
  const {
    position = 'top-right',
    component: Component = Snackbar,
    autoHideDuration,
    resumeHideDuration,
    open,
    title,
    action,
    variant,
    severity,
    children,
    disablePortal,
    onClose,
    ...rest
  } = props

  const hasTitle = !_.isEmpty(title)

  return (
    <Portal disablePortal={disablePortal}>
      <Component
        {...(resumeHideDuration && { resumeHideDuration })}
        {...(autoHideDuration && { autoHideDuration })}
        open={open}
        anchorOrigin={positions[position]}
        onClose={onClose}
      >
        <Alert
          ref={ref}
          action={action}
          variant={variant}
          severity={severity}
          onClose={onClose}
          {...rest}
        >
          {hasTitle ? <AlertTitle>{title}</AlertTitle> : null}
          <Box>{children}</Box>
        </Alert>
      </Component>
    </Portal>
  )
}

const Notification = React.forwardRef<unknown, NotificationProps>(Component)

export { Notification }
export default Notification
