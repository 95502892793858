import { Box, ClickAwayListener, Tooltip, tooltipClasses } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'

import { formatNumberCompact, getVisualisationType } from 'utils'
import { getVariant } from '../getVariant'

const LegendColorTooltip = (props) => {
  const { value, open, onClose, children } = props

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Tooltip
        arrow
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement='top'
        title={value}
        open={open}
        onClose={onClose}
        PopperProps={{
          disablePortal: true,
          sx: {
            [`& .${tooltipClasses.tooltipPlacementTop}`]: {
              mb: '16px !important'
            }
          }
        }}
      >
        {children}
      </Tooltip>
    </ClickAwayListener>
  )
}

let timeout
const MapVisualisationColorRamp = (props) => {
  const { variant, value, suffix, color, index, length, visualisationType } =
    props

  const { mobile, desktop } = getVariant(variant)
  const { isQuantity, isCategory } = getVisualisationType(visualisationType)
  const lengthOffset = !isQuantity ? length : length - 1

  const left = `calc((100% / ${lengthOffset}) * ${index})`
  const width = `calc(100% / ${lengthOffset})`
  const first = index === 0
  const only = length === 1
  const last = index === lengthOffset
  const lastColor = index === lengthOffset - 1

  const [open, setOpen] = useState(false)

  const hideDelay = (duration) => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      setOpen(false)
    }, duration)
  }

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
    hideDelay(2000)
  }

  return (
    <Fragment>
      {!isEmpty(color) ? (
        <LegendColorTooltip
          value={value}
          open={open && isCategory}
          onClose={handleTooltipClose}
        >
          <Box
            onClick={handleTooltipOpen}
            data-testid='MapVisualisation-ColorRamp'
            data-color={color}
            sx={[
              {
                backgroundColor: color,
                height: '100%',
                width
              },
              !only &&
                first && {
                  borderTopLeftRadius: 0.5,
                  borderBottomLeftRadius: 0.5
                },
              lastColor && {
                borderTopRightRadius: 0.5,
                borderBottomRightRadius: 0.5
              }
            ]}
          />
        </LegendColorTooltip>
      ) : null}

      <Box
        data-testid='MapVisualisation-ColorRampCopy'
        data-value={value}
        sx={[
          mobile && {
            fontSize: '0.75rem'
          },
          desktop && {
            fontSize: '0.875rem'
          },
          {
            fontWeight: 'semibold',
            zIndex: 1,
            textAlign: 'center',
            position: 'absolute',
            top: 'calc(100% + 8px)',
            left,
            userSelect: 'text'
          },

          isQuantity &&
            !first && {
              transform: 'translateX(-50%)'
            },

          isQuantity &&
            last && {
              transform: 'translateX(-100%)'
            },

          isCategory && {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width,
            textAlign: 'center',
            paddingRight: 'unset',
            px: '1rem'
          }
        ]}
        onMouseDown={(event) => {
          if (event.detail === 2) {
            event.stopPropagation()
            event.preventDefault()
            window
              .getSelection()
              .setBaseAndExtent(event.target, 0, event.target, 1)
          }
        }}
      >
        {isQuantity ? formatNumberCompact(value) : value}

        <Box
          component='span'
          fontSize='80%'
        >
          {suffix}
        </Box>
      </Box>
    </Fragment>
  )
}

MapVisualisationColorRamp.propTypes = {
  variant: PropTypes.string,
  value: PropTypes.any,
  suffix: PropTypes.string,
  color: PropTypes.string,
  index: PropTypes.number,
  length: PropTypes.number,
  visualisationType: PropTypes.string
}

export { MapVisualisationColorRamp }
export default MapVisualisationColorRamp
