import Router from 'next/router'
import NProgress from 'nprogress'
import { useEffect } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'

NProgress.configure({
  showSpinner: false
})

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const useLoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker()

  useEffect(() => {
    if (promiseInProgress) {
      NProgress.start()
    } else {
      NProgress.done()
    }
  }, [promiseInProgress])

  return {}
}

export { useLoadingIndicator }
