import { Provider } from 'react-redux'
import { mapViewerContext } from './context'
import { mapViewerStore } from './store'

const MapViewerProvider = ({ children }) => {
  return (
    <Provider
      store={mapViewerStore}
      context={mapViewerContext as any}
    >
      {children}
    </Provider>
  )
}

export { MapViewerProvider }
