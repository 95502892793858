import React from 'react'
import { styled, Box, Paper } from '@mui/material'
import { animated } from '@react-spring/web'

import { useMobilePanel } from '../hooks/useMobilePanel'
import { MobilePanelHeader } from '../MobilePanelHeader'

const MobilePanelRoot = styled(animated(Box))(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  position: 'fixed',
  width: 0,
  height: 0,
  bottom: 0,
  left: 0,
  overscrollBehavior: 'none',
  touchAction: 'none',
  userSelect: 'none',
  '&[data-mobile-panel-root="true"]': {
    '--mobile-panel-bg': theme.palette.background.default,
    '--mobile-panel-root-zIndex': theme.zIndex.mobilePanel,
    '--mobile-panel-header-zIndex': theme.zIndex.mobilePanel + 2,
    '--mobile-panel-content-zIndex': theme.zIndex.mobilePanel + 1,
    '--mobile-panel-shadow-offset': '8px',
    '--mobile-panel-padding-top': '0px',
    '--mobile-panel-padding-left': '0px',
    '--mobile-panel-padding-right': '0px'
  },
  '&[data-mobile-panel-root="true"]:after': {
    content: '""',
    pointerEvents: 'none',
    backgroundColor: 'var(--mobile-panel-bg, #333)',
    height: '1px',
    transformOrigin: 'bottom',
    transform: 'scaleY(var(--mobile-panel-overdrag-gap, 0))',
    willChange: 'transform'
  },

  '&[data-mobile-panel-fixed="true"]:after': {
    zIndex: 'var(--mobile-panel-root-zIndex)',
    position: 'fixed',
    bottom: 0,
    left: 0,
    overscrollBehavior: 'none',
    touchAction: 'none',
    userSelect: 'none',
    WebkitTouchCallout: 'none'
  },
  '&[data-mobile-panel-bounding="true"]:after': {
    width: 'var(--mobile-panel-width, 100dvw)',
    minWidth: 'var(--mobile-panel-min-width)',
    marginTop: 'env(safe-area-inset-top)',
    marginLeft: 'var(--mobile-panel-margin-left, env(safe-area-inset-left))',
    marginRight: 'var(--mobile-panel-margin-right, env(safe-area-inset-right))'
  }
}))

const MobilePanelModal = styled(Paper)(({ theme }) => ({
  outline: 'none',
  zIndex: theme.zIndex.mobilePanel,
  position: 'fixed',
  left: 0,
  bottom: 0,
  touchAction: 'none',
  overscrollBehavior: 'none',
  '&[data-mobile-panel-modal="true"]': {
    width: 'var(--mobile-panel-width, 100dvw)',
    minWidth: 'var(--mobile-panel-min-width)',
    height: 'var(--mobile-panel-modal-height, 0)',
    backgroundColor: 'var(--mobile-panel-bg, #333)',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate3d(0, var(--mobile-panel-modal-translate-y, 0), 0)',
    willChange: 'height'
  },
  '&[data-mobile-panel-rounded="true"]': {
    borderTopLeftRadius: 'var(--mobile-panel-modal-border-radius, 16px)',
    borderTopRightRadius: 'var(--mobile-panel-modal-border-radius, 16px)'
  },
  '&[data-mobile-panel-safe-area="true"]': {
    marginBottom: 'calc(env(safe-area-inset-bottom) * -1)',
    marginLeft: 'var(--mobile-panel-margin-left, env(safe-area-inset-left))',
    marginRight: 'var(--mobile-panel-margin-right, env(safe-area-inset-right))'
  }
}))

const MobilePanelScroll = styled(Box)({
  '&[data-mobile-panel-has-header="true"]': {
    marginTop: 'calc(var(--mobile-panel-shadow-offset) * -1)'
  },
  '&[data-mobile-panel-scroll="true"]': {
    flexShrink: 1,
    flexGrow: 1,
    WebkitTapHighlightColor: 'revert',
    WebkitTouchCallout: 'revert',
    WebkitUserSelect: 'auto',
    MsUserSelect: 'auto',
    WebkitOverflowScrolling: 'touch',
    userSelect: 'auto',
    overflow: 'auto',
    overflowY: 'auto',
    overscrollBehavior: 'auto',
    '&::-webkit-scrollbar': {
      width: '18px'
    },
    '&::-webkit-scrollbar-thumb': {
      border: '4px solid rgba(255, 255, 255, 0)',
      backgroundClip: 'padding-box',
      borderRadius: '99px',
      backgroundColor: 'rgba(255, 255, 255, 0.3)'
    },
    '&::-webkit-scrollbar-thumb:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.8)'
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: 'rgba(255, 255, 255, 1)'
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: '#333'
    }
  }
})

const MobilePanelContent = styled(Box)({
  overflow: 'hidden',
  position: 'relative',
  zIndex: 'var(--mobile-panel-content-zIndex)',
  '&[data-mobile-panel-content="true"]': {
    paddingBottom: 'env(safe-area-inset-bottom)'
  }
})

const MobilePanel = (props) => {
  const {
    // props
    open = false,
    title = null,
    header = null,
    notchWidth = 10,
    /**
     * A callback that the set the defaultThreshold.
     * Default value will be the minimum of the `thresholds` options or
     * the previous threshold.
     */
    defaultThreshold = getDefaultThreshold,
    /**
     * A callback that set the snap threshold for MobilePanel.
     * Default value will be the minimum height of viewport which is 0 or closed.
     */
    thresholds = getThresholds,
    /**
     * A callback that set the close threshold for MobilePanel.
     * Default value will be maxHeight * 0.2.
     */
    closeThreshold = getCloseThreshold,
    children,
    // callbacks
    unmount,
    ...rest
  } = props

  const mobilePanelParams = {
    // configs
    open,
    defaultThreshold,
    thresholds,
    closeThreshold,
    // callbacks
    unmount
  }

  const {
    containerRef,
    modalRef,
    headerRef,
    scrollRef,
    contentRef,
    bind,
    interpolations,
    emit,
    heightRef,
    snapThresholds
  } = useMobilePanel(mobilePanelParams)

  /**
   * On notch click or press, the mobile-panel will move the higher threshold or
   * it will stay the same when the currentThreshold is the highest.
   */
  const handleHeaderClick = () => {
    const currentIndex = snapThresholds.indexOf(heightRef.current)
    const nextThreshold =
      snapThresholds[currentIndex + 1] || snapThresholds[currentIndex]
    emit('snap', {
      y: nextThreshold
    })
  }

  return (
    <MobilePanelRoot
      data-testid='mobile-panel-root'
      className='MuiMobilePanel-root'
      ref={containerRef}
      {...rest}
      style={{
        ...interpolations
      }}
      data-mobile-panel-root
      data-mobile-panel-fixed
      data-mobile-panel-bounding
    >
      <MobilePanelModal
        data-testid='mobile-panel-modal'
        ref={modalRef}
        aria-modal='true'
        role='dialog'
        square
        className='MuiMobilePanelModal-root'
        data-mobile-panel-modal
        data-mobile-panel-rounded
        data-mobile-panel-safe-area
        tabIndex={-1}
      >
        <MobilePanelHeader
          data-testid='mobile-panel-header'
          className='MuiMobilePanelHeader-root'
          ref={headerRef}
          title={title}
          header={header}
          notchWidth={notchWidth}
          onClick={handleHeaderClick}
          {...bind({})}
        />
        <MobilePanelScroll
          data-body-scroll-lock-ignore
          data-testid='mobile-panel-scroll'
          className='MuiMobilePanelScroll-root'
          data-mobile-panel-scroll
          data-mobile-panel-has-header={!!title || !!header}
          ref={scrollRef}
        >
          <MobilePanelContent
            data-testid='mobile-panel-content'
            ref={contentRef}
            data-mobile-panel-content
            className='MuiMobilePanelContent-root'
          >
            {children}
          </MobilePanelContent>
        </MobilePanelScroll>
      </MobilePanelModal>
    </MobilePanelRoot>
  )
}

const getDefaultThreshold = (params) => {
  const { snapThresholds } = params
  return Math.min(...snapThresholds)
}

const getThresholds = (params) => {
  const { minHeight } = params
  return minHeight
}

const getCloseThreshold = (params) => {
  const { maxHeight } = params
  return maxHeight * 0.2
}

export { MobilePanel, getDefaultThreshold, getThresholds, getCloseThreshold }
