import {
  ELEMENT_H1,
  ELEMENT_PARAGRAPH,
  createIndentListPlugin
} from '@udecode/plate'

export default () =>
  createIndentListPlugin({
    inject: {
      props: {
        validTypes: [ELEMENT_PARAGRAPH, ELEMENT_H1]
      }
    }
  })
