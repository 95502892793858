import jscookie from 'js-cookie'

const COOKIE_KEY = 'remixed_map_id'

export const getRemixedMapIdFromRequestCookies = (obj: Record<string, any>) => {
  return obj[COOKIE_KEY]
}

export const setRemixedMapIdClientCookie = (id: string) => {
  jscookie.set(COOKIE_KEY, id)
}
