import { Feature, GeometryObject } from 'geojson'
import { getBoundingBoxData, getPointBoundingBoxData } from 'map-style'
import { useState } from 'react'
import { MapSearchModal as Modal } from 'ui-components'
import { useJsonSearch } from '~map-search-view/hooks/useJsonSearch'
import { useOsmSearch } from '~map-search-view/hooks/useOsmSearch'
import { useMap } from 'ms-map-gl'
import { Map } from '~map-search-view/types/__generated/gql/graphql'
import { throttle } from 'utils'

type AppProps = {
  map: Map
  layersFeatures: Feature<GeometryObject>[]
  title: string
  onClose: () => void
  onFeatureItemClick: (
    bounds: number[],
    feature: Feature<GeometryObject>
  ) => void
  onLocationItemClick: (bounds: string[]) => void
}

type OsmResult = {
  display_name: string
  boundingbox: string[]
}

const App = (props: AppProps) => {
  const {
    map,
    layersFeatures,
    title,
    onClose,
    onFeatureItemClick,
    onLocationItemClick
  } = props
  const [value, setValue] = useState<string>('')

  const [jsonResults, setJsonResults] = useState<Feature<GeometryObject>[]>([])
  const [osmResults, setOsmResults] = useState<OsmResult[]>([])
  const { searchJson } = useJsonSearch({
    map,
    layersFeatures
  })
  const { searchOsm } = useOsmSearch()

  const currentBounds = useMap().default?.getBounds().toArray() as [
    [number, number],
    [number, number]
  ]

  const handleChange = (_, value: string) => {
    setValue(value)
    /**
     * We should consider cache osm search results
     */
    throttle(() => {
      searchOsm(value, currentBounds).then((results: OsmResult[]) =>
        setOsmResults(results.slice(0, 3))
      )

      setJsonResults(searchJson(value) as Feature<GeometryObject>[])
    }, 300)
  }

  const handleFeatureItemClick = (_: any, feature: Feature<GeometryObject>) => {
    let bounds
    if (feature?.geometry?.type === 'Point') {
      bounds = getPointBoundingBoxData(feature.geometry)
    } else {
      bounds = getBoundingBoxData(feature.geometry)
    }
    setValue(feature.properties ? feature.properties[title] : '')
    onFeatureItemClick(bounds, feature)
  }

  const handleLocationItemClick = (_: any, location: OsmResult) => {
    const bounds = location.boundingbox
    setValue(location.display_name)
    onLocationItemClick(bounds)
  }

  return (
    <Modal
      {...props}
      title={title}
      jsonResults={jsonResults}
      osmResults={osmResults}
      value={value}
      onClose={onClose}
      onChange={handleChange}
      onFeatureItemClick={handleFeatureItemClick}
      onLocationItemClick={handleLocationItemClick}
    />
  )
}

export { App }
