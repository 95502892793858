import { Stack, TextField } from '@mui/material'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { useImmer } from 'use-immer'

import {
  ELEMENT_LINK,
  getFragment,
  getNodeString,
  getSelectionText,
  insertLink,
  someNode,
  upsertLink
} from '@udecode/plate'

import { focusEditor } from '@udecode/plate-common'
import { useEditorState } from '@udecode/plate-core'

import Action from '~ui-components/components/molecules/TextEditor/components/Action'
import Dialog, {
  DialogContent
} from '~ui-components/components/molecules/TextEditor/components/Dialog'

const ACTION = {
  CREATE: 'create',
  EDIT: 'edit'
}

const Link = (props) => {
  const editor = useEditorState()

  const [{ open, text, url, action }, setState] = useImmer({
    open: false,
    text: '',
    url: '',
    action: ACTION.CREATE
  })

  const node = getLink(editor)
  const isEditing = open && action === ACTION.EDIT

  useEffect(() => {
    if (open) return
    if (node) {
      setState((draft) => {
        draft.text = node?.text || ''
        draft.url = node?.url || ''
        draft.action = ACTION.EDIT
      })
    } else {
      setState((draft) => {
        draft.text = ''
        draft.url = ''
        draft.action = ACTION.CREATE
      })
    }
  }, [setState, open, node])

  const openDialog = () => {
    setState((draft) => {
      draft.open = true
    })
  }

  const closeDialog = () => {
    setState((draft) => {
      draft.open = false
    })
    setTimeout(() => {
      focusEditor(editor)
    }, 0)
  }

  const handlSubmit = (event) => {
    event.preventDefault()

    switch (true) {
      case isEditing: {
        upsertLink(editor, {
          url,
          text,
          skipValidation: true
        })
        break
      }

      default: {
        insertLink(editor, {
          url: url,
          text
        })
      }
    }

    closeDialog()
  }

  const handleTextChange = (event) => {
    const { value } = event.target
    setState((draft) => {
      draft.text = value
    })
  }

  const handleUrlChange = (event) => {
    const { value } = event.target
    setState((draft) => {
      draft.url = value
    })
  }

  const getTitle = () => {
    switch (action) {
      case ACTION.EDIT:
        return 'Edit link'
      default:
        return 'Add link'
    }
  }

  return (
    <>
      <Action
        data-testid='LinkAction'
        variant='link'
        toggled={node.link}
        onClick={openDialog}
        {...props}
      />

      <Dialog
        title={getTitle()}
        open={open}
        onClose={closeDialog}
        onSubmit={handlSubmit}
        slotProps={{
          PrimaryButton: {
            'data-testid': 'LinkEdit-Save',
            disabled: validate({ text })
          }
        }}
      >
        <DialogContent>
          <Stack
            direction='column'
            gap={2}
          >
            <TextField
              autoComplete='off'
              name='text'
              label='Text'
              fullWidth
              value={text}
              onChange={handleTextChange}
              inputProps={{
                'data-testid': 'LinkEdit-Text'
              }}
            />

            <TextField
              autoComplete='off'
              name='url'
              label='Link'
              fullWidth
              value={url}
              onChange={handleUrlChange}
              inputProps={{
                'data-testid': 'LinkEdit-Url'
              }}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

const getLink = (editor) => {
  const selection = editor.selection

  const link = someNode(editor, {
    at: selection,
    match: { type: ELEMENT_LINK }
  })

  if (!selection) {
    return {
      isLink: false,
      text: '',
      url: ''
    }
  }

  const selectionText = getSelectionText(editor)
  const selecting = !isEmpty(selectionText)
  const selectionFragment = getFragment(editor, selection)[0] || []
  const anchor = selectionFragment.children.findLast(
    (node) => node.type === ELEMENT_LINK
  )

  const focusedFragment = getFragment(editor, selection.focus.path)[0] || {}
  const focusedText = getNodeString(focusedFragment)

  let text = ''
  if (selecting) {
    text = selectionText
  } else if (link) {
    text = focusedText
  } else {
    text = !selecting ? '' : focusedText
  }

  return {
    link,
    text,
    url: anchor?.url || ''
  }
}

const validate = ({ text }) => {
  return isEmpty(text)
}

export { Link }
