import { App as AppTableView } from 'map-table'
import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { useFilterResults, useFilters } from '~map-filter-view/states/filter'
import { useWhetherToCollapseChips } from '~map-viewer/App/hooks/useWhetherToCollapseChips'
import { useJsonFeatures, useProperties } from '~map-viewer/states/map'
import { closeTable, openFilter, setFilterPreview } from '~map-viewer/states/ui'

const Component = (props) => {
  const dispatch = useDispatch()

  const filters = useFilters()
  const shouldUseCollapse = useWhetherToCollapseChips()
  const filterResults = useFilterResults()
  const properties = useProperties()
  const jsonFeatures = useJsonFeatures()

  return (
    <>
      <AppTableView
        {...props}
        shouldUseCollapse={shouldUseCollapse}
        filters={filters}
        filterResults={filterResults}
        properties={properties}
        jsonFeatures={jsonFeatures}
        onFilterChipClick={() => {
          dispatch(openFilter())
          dispatch(setFilterPreview())
        }}
      />
    </>
  )
}

const MapTable = memo(function MapTable(props) {
  const dispatch = useDispatch()

  return (
    <Component
      {...props}
      onClose={() => dispatch(closeTable())}
    />
  )
})

MapTable.displayName = 'MapTable'

export { MapTable }
export default MapTable
