import { isEmpty } from 'lodash'
import { layerStyles } from 'map-style'
import { useEffect, useState } from 'react'
import { useFilters, useHighlightIds } from '~map-filter-view/states/filter'
import { useVisualisation } from '~map-viewer/states/visualisation'

export default function useVisualisationStyle() {
  const highlightIds = useHighlightIds()
  const filters = useFilters()
  const [style, setStyle] = useState(layerStyles.getDefaultStyle())
  const visualisation = useVisualisation()

  useEffect(() => {
    const filterArray = filters?.length > 0 && highlightIds
    const { value, type, classBreaks } = visualisation

    if (isEmpty(classBreaks)) return

    setStyle(layerStyles.getViewStyle(value, classBreaks, type, filterArray))
  }, [highlightIds, filters, visualisation])

  return style
}
