import { Box } from '@mui/material'
import { forwardRef } from 'react'

const Paragraph = forwardRef((props, ref) => {
  const { sx, disabledGutterTop, children, ...rest } = props

  return (
    <Box
      ref={ref}
      component='p'
      typography='body1'
      sx={[
        {
          a: {
            fontSize: 'inherit'
          },
          '&:first-of-type': {
            mt: 0
          }
        },
        disabledGutterTop && {
          mt: 0
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...rest}
    >
      {children}
    </Box>
  )
})

Paragraph.displayName = 'Paragraph'

export { Paragraph }
export default Paragraph
