import { Box, Paper, SvgIcon, Typography } from '@mui/material'
import { isValidElement } from 'react'

const EmptySlotFallback = (props) => {
  const {
    variant = 'outlined',
    elevation = 4,
    sx,
    icon: Icon,
    text,
    button,
    IconProps = {},
    ...rest
  } = props
  return (
    <Box
      data-testid='EmptySlotFallback'
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...rest}
    >
      <Box
        component={Paper}
        variant={variant}
        {...(variant === 'elevation' && { elevation })}
        sx={[
          {
            minHeight: '300px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1
          },
          variant === 'elevation' && {},
          variant === 'outlined' && {
            borderStyle: 'dashed',
            borderColor: (theme) => theme.palette.neutral[200]
          }
        ]}
      >
        {isValidElement(Icon) ? (
          Icon
        ) : (
          <SvgIcon
            {...IconProps}
            sx={{
              opacity: 0.4
            }}
          >
            <Icon />
          </SvgIcon>
        )}
        <Box
          component={Typography}
          variant='h6'
          fontWeight={700}
          mt={2}
          sx={{
            opacity: 0.4
          }}
        >
          {text}
        </Box>
        <Box mt={2}>{button}</Box>
      </Box>
    </Box>
  )
}

export { EmptySlotFallback }
