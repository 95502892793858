import { configureStore } from '@reduxjs/toolkit'
import { withReduxConfigs } from 'utils'

import mapApi from '~map-creator/core/services/map'
import tagApi from '~map-creator/core/services/tag'
import workspaceApi from '~map-creator/core/services/workspace'

import geojsonReducer from '~map-creator/core/states/geojson'
import mapReducer from '~map-creator/core/states/map'
import propertiesReducer from '~map-creator/core/states/properties'
import uiReducer from '~map-creator/core/states/ui'
import workspaceReducer from '~map-creator/core/states/workspace'

export const store = configureStore(
  withReduxConfigs({
    reducer: {
      workspace: workspaceReducer,
      geojson: geojsonReducer,
      properties: propertiesReducer,
      map: mapReducer,
      ui: uiReducer,
      [tagApi.reducerPath]: tagApi.reducer,
      [mapApi.reducerPath]: mapApi.reducer,
      [workspaceApi.reducerPath]: workspaceApi.reducer
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat([
        tagApi.middleware,
        mapApi.middleware,
        workspaceApi.middleware
      ])
    }
  })
)
