import { GlobalStyles } from '@mui/material'

const Overrides = (
  <GlobalStyles
    styles={{
      'html, body': {
        padding: 0,
        margin: 0,
        width: '100%',
        height: '100%'
      },
      '#__next': {}
    }}
  />
)

const Website = ({ children }) => {
  return (
    <>
      {Overrides}
      {children}
    </>
  )
}

export { Website }
export default Website
