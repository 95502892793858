import { graphierQLSubscription } from 'utils'
import {
  OnMapDataFailSubscription,
  OnMapDataFailSubscriptionVariables
} from '~map-viewer/types/__generated/gql/graphql'

const createDataFailSubscription = (dataId, callback) => {
  const subscription = graphierQLSubscription<
    OnMapDataFailSubscription,
    OnMapDataFailSubscriptionVariables
  >(
    {
      query: ON_MAP_DATA_FAIL_SUBSCRIPTION,
      variables: { dataId }
    },
    {
      onNext: ({ data }) => {
        if (data && data.onMapDataFail && data.onMapDataFail.dataId) {
          callback()
        }
      },
      onError: console.error
    }
  )
  return subscription
}

const ON_MAP_DATA_FAIL_SUBSCRIPTION = /* GraphQL */ `
  subscription onMapDataFail($dataId: ID!) {
    onMapDataFail(dataId: $dataId) {
      dataId
    }
  }
`

export { ON_MAP_DATA_FAIL_SUBSCRIPTION, createDataFailSubscription }
export default createDataFailSubscription
