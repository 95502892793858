import { Autocomplete, TextField } from '@mui/material'
import PropTypes from 'prop-types'

import dataSourceOptions from './dataSourceOptions'

const MapDataLicenseInput = (props) => {
  const { value, onChange, options = dataSourceOptions } = props

  const handleChange = (_, newValue) => {
    onChange({
      name: newValue.label,
      url: newValue.url
    })
  }

  return (
    <Autocomplete
      disablePortal
      id='map-data-source-auto-complete'
      value={value || ''}
      isOptionEqualToValue={(option, value) => option.url === value?.url}
      getOptionLabel={(option) => option.label}
      options={options}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Choose License'
          variant='outlined'
          placeholder='License'
          helperText="A license tells others what they can do and can't do with our data."
        />
      )}
    />
  )
}

const SelectOptionType = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
}

const SelectInputPropTypes = {
  value: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.shape(SelectOptionType)),
  onChange: PropTypes.func
}

MapDataLicenseInput.propTypes = SelectInputPropTypes

export { MapDataLicenseInput }
export default MapDataLicenseInput
