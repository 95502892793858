import { Repeat01 } from '@untitled-ui/icons-react'
import { getRemixCopy } from 'utils'
import {
  IconLabel,
  IconLabelProps
} from '~ui-components/components/molecules/IconLabel'

type MapMetadataRemixesProps = Omit<IconLabelProps, 'icon' | 'primaryText'> & {
  remixes: number
}

function MapMetadataRemixes(props: MapMetadataRemixesProps) {
  const { remixes, ...rest } = props

  return (
    <IconLabel
      {...rest}
      icon={Repeat01}
      primaryText={getRemixCopy(remixes)}
    />
  )
}

export { MapMetadataRemixes }
