import { useDispatch } from 'react-redux'
import { MapActionSearch as View } from 'ui-components'
import { openSearch } from '~map-viewer/states/ui'

const Search = (props) => {
  const dispatch = useDispatch()
  return (
    <View
      {...props}
      onClick={() => dispatch(openSearch())}
    />
  )
}

export { Search }
export default Search
