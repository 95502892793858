import { Stack } from '@mui/material'
import { MapButtons } from '~ui-components/components/molecules/MapButtons'
import { MapMetadata } from '~ui-components/components/molecules/MapMetadata'
import { MapName } from '~ui-components/components/molecules/MapName'
import { TagsList } from '~ui-components/components/molecules/TagsList'
import { horizontalScroll } from '~ui-components/utilities/horizontalScroll'

const defaultSlots = {
  MapName,
  MapButtons,
  TagsList,
  MapMetadata
}

function MapHeader(props) {
  const { slots = {}, slotProps = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Stack
      direction='column'
      gap={2}
    >
      <Slots.MapName
        {...slotProps?.MapName}
        sx={{ maxWidth: '100%', mr: 4 }}
      />

      <Stack
        gap={2}
        direction={{
          lg: 'row',
          sm: 'column'
        }}
      >
        <Stack
          direction='column'
          gap={2}
          sx={{ flex: 1, justifyContent: 'space-between' }}
        >
          <Slots.MapMetadata {...slotProps?.MapMetadata} />
          <Slots.TagsList {...slotProps?.TagsList} />
        </Stack>

        <Slots.MapButtons
          {...slotProps?.MapButtons}
          sx={[
            (theme) => ({
              [theme.breakpoints.up('lg')]: {
                ml: '120px'
              }
            })
          ]}
        />
      </Stack>
    </Stack>
  )
}

export { MapHeader }
