import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  WorkspaceCreatorController as Controller,
  WorkspaceCreatorNameForm as Form,
  WorkspaceCreatorStepLayout as Layout
} from '~ui-components/components/organisms/WorkspaceCreatorPanels'

const WORKSPACE_ERROR = {
  EMPTY_FIELD: 'Workspace name is required field.',
  INVALID_FIELD:
    'Workspace name may contain only characters A-Z, a-z, 0-9, spaces and dashes, but cannot begin or end with a space or dash.'
}

const WorkspaceCreatorStepName = (props) => {
  const { value, onChange, onNextClick, onSubmit } = props
  const [error, setError] = useState(() => getInitialError(value))
  const [loading, setLoading] = useState(false)

  const toggleLoading = async (callback) => {
    setLoading(true)
    await callback()
    setLoading(false)
  }

  const validateOnChange = (value) => {
    if (isEmpty(value)) return false
    const message = getError(value, { allowSpacing: true })
    setError(message)
    return isEmpty(message)
  }

  const validateOnSubmit = () => {
    let message
    if (isEmpty(value)) message = WORKSPACE_ERROR.EMPTY_FIELD
    else message = getError(value, { allowSpacing: false })
    setError(message)
    return isEmpty(message)
  }

  const handleNextClick = () => {
    if (validateOnSubmit()) toggleLoading(onNextClick)
  }

  const handleChange = (event) => {
    const value = event.target.value
    validateOnChange(value)
    onChange(event, value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (validateOnSubmit()) toggleLoading(onSubmit)
  }

  return (
    <Layout
      step={2}
      totals={4}
      form={
        <Form
          error={error}
          disabled={loading}
          value={value}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
      }
      controller={
        <Controller
          next
          loadingNext={loading}
          disabledNext={!isEmpty(error) || isEmpty(value)}
          onNextClick={handleNextClick}
        />
      }
    />
  )
}

WorkspaceCreatorStepName.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onNextClick: PropTypes.func,
  onSubmit: PropTypes.func
}

export { WorkspaceCreatorStepName }
export default WorkspaceCreatorStepName

/**
 * Regex Features:
 * - allow alphabets [A-Za-z], spaces and dash
 * - allow number [0-9]
 * - doesn't allow front space/dash
 * - doesn't allow tail space/dash
 */
const NOT_ALLOW_SPACE_WORKSPACE_REGEX =
  /[^\s]*^[a-zA-Z0-9]+([\s-]*[a-zA-Z0-9]+)*$/

/**
 * Regex Features:
 * - allow alphabets [A-Za-z], space and dash
 * - allow number [0-9]
 * - doesn't allow front space/dash
 * - allow tail space/dash
 */
const ALLOW_SPACE_WORKSPACE_NAME_REGEX =
  /[^\s]*^[a-zA-Z0-9]+([a-zA-Z0-9\s-]+)*$/

const isValidWorkspaceName = (name, options) => {
  const { allowSpacing = false } = options || {}
  const regex = !allowSpacing
    ? NOT_ALLOW_SPACE_WORKSPACE_REGEX
    : ALLOW_SPACE_WORKSPACE_NAME_REGEX
  return RegExp(regex).test(name)
}

const getInitialError = (name) => {
  if (isEmpty(name)) return ''
  if (!isValidWorkspaceName(name, { allowSpacing: false }))
    return WORKSPACE_ERROR.INVALID_FIELD
  return ''
}

const getError = (name, option) => {
  if (!isValidWorkspaceName(name, option)) return WORKSPACE_ERROR.INVALID_FIELD
  return ''
}
