import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const DateTimePicker = forwardRef(function DateTimePicker(props, ref) {
  const { variant = 'desktop', ...rest } = props

  if (variant === 'desktop') {
    return (
      <DesktopDateTimePicker
        label='Date'
        ref={ref}
        {...rest}
      />
    )
  }

  if (variant === 'mobile') {
    return (
      <MobileDateTimePicker
        label='Date'
        ref={ref}
        {...rest}
      />
    )
  }

  return null
})

DateTimePicker.displayName = 'DateTimePicker'

DateTimePicker.propTypes = {
  variant: PropTypes.oneOf(['desktop', 'mobile'])
}

export { DateTimePicker }
export default DateTimePicker
