import Button from '@mui/material/Button'
import SvgIcon from '@mui/material/SvgIcon'
import Plus from '@untitled-ui/icons-react/build/esm/Plus'

import { useRouter } from 'next/router'

export const CategoriesAction = () => {
	const router = useRouter()

	return (
		<Button
			onClick={() => router.push('/staff-room/categories/create')}
			startIcon={
				<SvgIcon>
					<Plus />
				</SvgIcon>
			}
			variant='contained'
		>
			Create
		</Button>
	)
}
