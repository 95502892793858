import { LAYER_OUTLINE_SELECTION_COLORS } from '../../assets/Colors'
import { PROMOTED_COLUMN_ID } from '../../assets/Constants'

const getLineLayerStyle = (selectedId = '') => {
  return {
    type: 'line',
    paint: {
      'line-width': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        3,
        ['==', ['get', PROMOTED_COLUMN_ID], selectedId],
        2,
        0
      ],
      'line-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        LAYER_OUTLINE_SELECTION_COLORS[0],
        ['==', ['get', PROMOTED_COLUMN_ID], selectedId],
        LAYER_OUTLINE_SELECTION_COLORS[0],
        'transparent'
      ]
    }
  }
}

export { getLineLayerStyle }
