import { graphierQL } from 'utils'
import {
  CreateMapMutation,
  CreateMapMutationVariables
} from '~map-creator/types/__generated/gql/graphql'

const createMap = async (input: CreateMapMutationVariables['input']) => {
  const { createMap } = await graphierQL<
    CreateMapMutation,
    CreateMapMutationVariables
  >({
    useToken: true,
    query: createMapMutation,
    variables: { input }
  })

  return createMap
}

const createMapMutation = /* GraphQL */ `
  mutation CreateMap($input: CreateMapInput!) {
    createMap(input: $input) {
      id
      createdAt
      dataId
      dataReady
      name
      description
      views
      likes
      remixes
      bbox
      centroid
      isPublic
      properties {
        name
        type
        visualisation
        isAggregate
        isPercentage
        isDefaultVisualisation
        isTitle
        classBreaks
      }
      license {
        name
        url
      }
      source {
        name
        url
      }
      readme {
        nodes
        createdAt
        updatedAt
      }
      workspace {
        id
        inviteId
        name
        description
        logo
        createdAt
        memberCollection {
          total
          items {
            user {
              id
              firstName
              lastName
              avatar
            }
          }
        }
      }
      creator {
        id
        firstName
        lastName
        email
        avatar
        createdAt
      }
      parentMap {
        id
        name
        workspace {
          id
          name
        }
        creator {
          id
          firstName
          lastName
        }
      }
      mapTagCollection {
        total
        items {
          tag {
            name
            id
            type
            description
            count
          }
        }
      }
      mapRemixCollection {
        total
      }
      type
      mapGuestAccessCollection {
        total
        items {
          id
          workspaceId
          userId
          mapId
          createdAt
          user {
            id
            firstName
            lastName
            email
            avatar
            createdAt
          }
          invitedUser {
            id
            email
            createdAt
          }
        }
      }
    }
  }
`
export { createMap, createMapMutation }
export default createMap
