import { FC, ReactNode, useEffect } from 'react'
import { MapstackLoadingBackdrop } from 'ui-components'
import { useFederatedAuth } from '~user-auth/hooks/useFederatedAuth'

import { useAuth } from '~user-auth/contexts/auth'

const LoginRequired: FC<{ children: ReactNode }> = ({ children }) => {
  const { user, authenticated, initialized } = useAuth()
  const { gotoSignInPage } = useFederatedAuth()

  const isLoggedIn = initialized && authenticated && user

  useEffect(() => {
    if (initialized && (!authenticated || !user)) gotoSignInPage()
  }, [initialized, user, authenticated, gotoSignInPage])

  if (!isLoggedIn) {
    return <MapstackLoadingBackdrop text='Redirecting...' />
  }

  return children
}

export { LoginRequired }
export default LoginRequired
