import jmespath from 'jmespath'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useJsonFeatures } from '~map-filter-view/states/map'

const query = `
[*].properties."$1".to_number(@) | { min: min(@), max: max(@) } 
`.trim()

const getNumericQuery = (property) => {
  return query.replace('$1', property)
}

const useNumericOperator = () => {
  const jsonFeatures = useJsonFeatures()

  const features = useMemo(() => {
    if (isEmpty(jsonFeatures)) return []
    return jsonFeatures
  }, [jsonFeatures])

  const getRange = (property) => {
    const numberRange = jmespath.search(features, getNumericQuery(property))
    return { minRange: numberRange?.min, maxRange: numberRange?.max }
  }

  return {
    getRange
  }
}
export { useNumericOperator }
export default useNumericOperator
