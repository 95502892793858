import { filter, map, uniq } from 'lodash'
import { getAllGeojsonFeatureProperties, getTypeOfEachProperties } from 'utils'
import { GEOJSON_ERRORS } from '../../assets/constants'

const containValidColumn = (geojson) => {
  const arrayOfProperties = getAllGeojsonFeatureProperties(geojson)
  const geojsonProperties = getTypeOfEachProperties(arrayOfProperties)
  const isNumeric = filter(geojsonProperties, function (o) {
    return o.type === 'integer' || o.type === 'float'
  }).length
  if (isNumeric > 0) {
    return true
  }
  const filteredTextProperties = filter(
    geojsonProperties,
    (o) => o.type === 'text' && o.name !== 'msuuid'
  )
  let isCategory = false
  for (const item of filteredTextProperties) {
    const { name } = item
    const propertyArray = uniq(map(arrayOfProperties, name).filter(Boolean))
    if (propertyArray.length > 1 && propertyArray.length < 10) {
      isCategory = true
      break
    }
  }
  return isCategory
}

export const isContainValidColumn = (geojson) => {
  const errors = []
  if (!containValidColumn(geojson)) {
    errors.push([
      GEOJSON_ERRORS.FILE_CONTAINS_NO_VALID_COLUMN.key,
      GEOJSON_ERRORS.FILE_CONTAINS_NO_VALID_COLUMN.description
    ])
  }

  return errors
}
