import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const Container = (props) => {
  const { children, noPadding, ...rest } = props
  const padding = !noPadding
    ? {
        paddingX: 2,
        paddingY: { xs: 8, sm: 10, md: 12 }
      }
    : {}

  return (
    <Box
      position='relative'
      maxWidth={{ sm: 720, md: 1236 }}
      width='100%'
      margin='0 auto'
      {...padding}
      {...rest}
    >
      {children}
    </Box>
  )
}

/**
 * @type {import('@mui/material/Box').BoxProps}
 */
const ContainerPropTypes = {
  noPadding: PropTypes.bool
}

Container.propTypes = ContainerPropTypes

export { Container }
export default Container
