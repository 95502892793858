import { Box, ButtonBase, SvgIcon, Tooltip } from '@mui/material'
import { Repeat01 } from '@untitled-ui/icons-react'

interface RemixButtonProps {
  onClick: () => void
}

function RemixFloatingButton(props: RemixButtonProps): JSX.Element {
  return (
    <Tooltip title='Remix'>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: '50%',
          bottom: 0,
          boxShadow: 16,
          margin: (theme) => theme.spacing(4),
          position: 'fixed',
          right: 0,
          zIndex: (theme) => theme.zIndex.speedDial
        }}
        {...props}
      >
        <ButtonBase
          sx={{
            backgroundColor: 'primary.main',
            borderRadius: '50%',
            color: 'primary.contrastText',
            p: '10px'
          }}
        >
          <SvgIcon>
            <Repeat01 />
          </SvgIcon>
        </ButtonBase>
      </Box>
    </Tooltip>
  )
}

export { RemixFloatingButton }
