import { createSelector, createSlice } from '@reduxjs/toolkit'
import { mapValues, merge } from 'lodash'
import { useSelector } from 'react-redux'

export const MAP_VIEWS = {
  FEATURE_INFO: 'featureInfo',
  TABLE: 'table',
  FILTER: 'filter',
  SEARCH: 'search',
  CLOSE: ''
}

export const FILTER_ACTIONS = {
  PREVIEW: 'preview',
  SELECT: 'select',
  EDIT: 'edit'
}

const allSlots = (boolean) =>
  mapValues(
    {
      MapNav: undefined,
      MapVisualisationChips: undefined,
      MapVisualisation: undefined,
      MapActions: undefined,
      MapFilter: undefined,
      MapTable: undefined,
      MapFeatureInfo: undefined,
      MapDescription: undefined,
      MapSearchModal: undefined
    },
    () => boolean
  )

const turnVisible = (object) => merge(allSlots(false), object)

const defaultVisibility = turnVisible({
  MapNav: true,
  MapVisualisationChips: true,
  MapVisualisation: true,
  MapActions: true,
  MapDescription: true
})

const visibility = {
  [MAP_VIEWS.TABLE]: turnVisible({
    MapTable: true
  }),

  [MAP_VIEWS.FEATURE_INFO]: turnVisible({
    MapFeatureInfo: true,
    MapActions: true
  }),
  [MAP_VIEWS.SEARCH]: turnVisible({
    MapSearchModal: true
  })
}

export const initialUIState = {
  href: '',

  /**
   * View that being shown on the map viewer screen
   */
  view: '',

  visibility: defaultVisibility,

  filter: {
    action: 'preview'
  },

  isFullscreenActive: false
}

const setViewState = (state, view) => {
  state.view = view
  state.visibility = {
    ...(visibility[view] ?? defaultVisibility),
    MapNav: state.visibility.MapNav ?? defaultVisibility.MapNav,
    MapActions: state.visibility.MapActions ?? defaultVisibility.MapActions,
    MapDescription:
      state.visibility.MapDescription ?? defaultVisibility.MapDescription
  }
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initialUIState,
  reducers: {
    setView: (state, action) => {
      setViewState(state, action.payload)
    },
    setFilterPreview: (state) => {
      state.filter.action = FILTER_ACTIONS.PREVIEW
    },
    setFilterSelect: (state) => {
      state.filter.action = FILTER_ACTIONS.SELECT
    },
    setFilterEdit: (state) => {
      state.filter.action = FILTER_ACTIONS.EDIT
    },
    openFeatureInfo: (state) => {
      setViewState(state, MAP_VIEWS.FEATURE_INFO)
    },
    openFilter: (state) => {
      state.view = ''
      state.visibility = turnVisible({
        ...defaultVisibility,
        MapFilter: true,
        MapVisualisationChips: true,
        MapNav: state.visibility.MapNav ?? defaultVisibility.MapNav,
        MapActions: state.visibility.MapActions ?? defaultVisibility.MapActions,
        MapDescription:
          state.visibility.MapDescription ?? defaultVisibility.MapDescription,
        MapTable: state.visibility.MapTable ?? defaultVisibility.MapTable
      })
    },
    closeFeatureInfo: (state, _) => {
      setViewState(state, MAP_VIEWS.CLOSE)
    },
    closeFilter: (state) => {
      state.visibility = turnVisible({
        ...defaultVisibility,
        MapFilter: false,
        MapNav: state.visibility.MapNav ?? defaultVisibility.MapNav,
        MapActions: state.visibility.MapActions ?? defaultVisibility.MapActions,
        MapDescription:
          state.visibility.MapDescription ?? defaultVisibility.MapDescription,
        MapTable: state.visibility.MapTable ?? defaultVisibility.MapTable
      })
    },
    closeTable: (state) => {
      setViewState(state, MAP_VIEWS.CLOSE)
    },
    openSearch: (state) => {
      setViewState(state, MAP_VIEWS.SEARCH)
    },
    closeSearch: (state) => {
      setViewState(state, MAP_VIEWS.CLOSE)
    },
    openTable: (state) => {
      setViewState(state, MAP_VIEWS.TABLE)
    },
    enterFullScreen: (state) => {
      state.view = ''
      state.visibility = turnVisible({
        MapNav: false,
        MapVisualisationChips: true,
        MapVisualisation: true,
        MapActions: true,
        MapDescription: false
      })
      state.isFullscreenActive = true
    },
    exitFullScreen: (state) => {
      state.view = ''
      state.visibility = defaultVisibility
      state.isFullscreenActive = false
    }
  }
})

export const {
  setView,
  setFilterPreview,
  setFilterEdit,
  setFilterSelect,
  openFeatureInfo,
  openFilter,
  closeFeatureInfo,
  closeFilter,
  closeTable,
  openTable,
  openSearch,
  closeSearch,
  enterFullScreen,
  exitFullScreen
} = uiSlice.actions

const selectView = (state) => state.ui.view
const selectVisiblity = (state) => state.ui.visibility
const selectIsFullScreenActive = (state) => state.ui.isFullscreenActive
const selectHref = (state) => state.ui.href
const selectIsFeatureInfo = createSelector(
  (state) => state.ui.view,
  (view) => view === MAP_VIEWS.FEATURE_INFO
)

export const useView = () => {
  return useSelector(selectView)
}

export const useVisibility = () => {
  return useSelector(selectVisiblity)
}

export const useIsFullscreenActive = () => {
  return useSelector(selectIsFullScreenActive)
}

export const useHref = () => useSelector(selectHref)

export const useIsFeatureInfo = () => useSelector(selectIsFeatureInfo)

export default uiSlice.reducer
