import type { ConfigureStoreOptions } from '@reduxjs/toolkit'

const withReduxConfigs = (
  options: ConfigureStoreOptions
): ConfigureStoreOptions => {
  const isEnabledDevTools =
    ['development', 'staging'].includes(process.env.NODE_ENV ?? '') ||
    process.env.ENABLE_REDUX_DEV_TOOLS === 'true'

  return {
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
    ...options,
    devTools: isEnabledDevTools
  }
}

export { withReduxConfigs }
